import { Button, Drawer, Dropdown, Menu, message } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import MusicPlayer from './Songs/MusicPlayer'
import { DeleteFilled, EllipsisOutlined } from '@ant-design/icons'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useSelector } from 'react-redux'
import { getAllSongs, updateplaylist } from '../../AadhanApi'
import SongModal from './Songs/SongModal'

const DrawerView = ({
    getAllPlaylistsfunction,
    isDrawerVisible,
    data,
    language,
    setIsDrawerVisible,
    currentPlaylist,
}) => {
    const closeDrawer = () => {
        setIsDrawerVisible(false)
        Object.values(audioRefs.current).forEach((audio, idx) => {
            if (audio) audio.pause()
        })
    }
    const [songsList, setSongsList] = useState([])
    const [songs, setSongs] = useState(currentPlaylist?.songs)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)
    const audioRefs = useRef({})
    const accessToken = useSelector((state) => state.app.accessToken)

    const handleRemoveSong = (index) => {
        const updatedSongs = songs.filter((_, songIndex) => songIndex !== index)
        setSongs(updatedSongs)
        setSongsList(updatedSongs)
    }
    const handleDragEnd = (result) => {
        if (!result.destination) return 
        const updatedSongs = Array.from(songsList)
        const [reorderedSong] = updatedSongs.splice(result.source.index, 1)
        updatedSongs.splice(result.destination.index, 0, reorderedSong) 
        setSongs(updatedSongs)
        setSongsList(updatedSongs)
    }
    useEffect(()=>{
        if(isDrawerVisible){

            getAllPlaylistsfunction()
        }
    },[isDrawerVisible])
    async function getPlaylistfunction() {
        const res = await getAllSongs(
            accessToken,
            language,
            'approved',
            currentPlaylist?.playlist_id
        )
        setSongs(res?.data)
        setSongsList(res?.data)
    }
    async function updatePlaylistfuction() {
        let temp = currentPlaylist
        temp.song_ids = songsList.map((e) => e.song_id)
        const res = await updateplaylist(
            accessToken,
            temp,
            currentPlaylist?.playlist_id
        )
        if (res?.status === 200) {
            message.success('playlist updated successfully')
            getAllPlaylistsfunction()
            closeDrawer()
        } else {
            message.error('failed update playlist')
        }
    }
    const handleAudioPlay = (index, audioUrl) => {
        Object.values(audioRefs.current).forEach((audio, idx) => {
            if (idx !== index && audio) audio.pause()
        })
        if (audioRefs.current[index]) {
            audioRefs.current[index].play()
        }
    }
    useEffect(() => {
      if(isDrawerVisible){

        getPlaylistfunction()
      }
    }, [currentPlaylist])
    return (
        <Drawer
            title="Songs"
            placement="right"
            width="74%"
            onClose={closeDrawer}
            open={isDrawerVisible}
            style={{
                padding: 0,
                backgroundColor: 'white',
                overflow: 'hidden',
            }}
        >
            <div
                style={{ padding: '16px', height: '100%', overflow: 'hidden' }}
            >
                <div
                    style={{
                        marginBottom: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <div>
                        <label
                            style={{ fontWeight: 'bold', marginRight: '8px' }}
                        >
                            Playlist Name:
                        </label>
                        <span
                            style={{
                                fontSize: '16px',
                                fontWeight: 500,
                                color: 'gray',
                            }}
                        >
                            {currentPlaylist?.title}
                        </span>
                    </div>
                    <SongModal
                        language={language}
                        data={data}
                        setSongsList={setSongsList}
                        exitSong={songsList}
                    />
                </div>
                <div style={{ height: 'calc(100% - 80px)', overflowY: 'auto' }}>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="songs">
                            {(provided) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{
                                        height: '100%',
                                        maxHeight: '80%',
                                        paddingRight: '8px',
                                    }}
                                >
                                    {songsList?.map((song, index) => {
                                        if (
                                            song?.content_status === 'approved'
                                        ) {
                                            return (
                                                <Draggable
                                                    key={index}
                                                    draggableId={`${index}`}
                                                    index={index}
                                                >
                                                    {(provided) => (
                                                        <div
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                gap: 10,
                                                                padding:
                                                                    '2px 16px',
                                                                marginBottom: 8,
                                                                backgroundColor:
                                                                    'rgba(243,243,234,1)',
                                                                borderRadius:
                                                                    '8px',
                                                                boxShadow:
                                                                    '0 1px 3px rgba(0,0,0,0.1)',
                                                                ...provided
                                                                    .draggableProps
                                                                    .style,
                                                            }}
                                                        >
                                                            <div
                                                                onClick={() => {
                                                                    setCurrentIndex(
                                                                        index
                                                                    )
                                                                    setSongs(
                                                                        songsList
                                                                    )
                                                                    setIsModalOpen(
                                                                        true
                                                                    )
                                                                }}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    fontSize: 26,
                                                                    paddingRight: 0,
                                                                }}
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width={25}
                                                                    height={25}
                                                                    style={{
                                                                        marginRight: 10,
                                                                    }}
                                                                    color={
                                                                        'orange'
                                                                    }
                                                                    viewBox="0 0 24 24"
                                                                    fill="currentColor"
                                                                    class="size-6"
                                                                >
                                                                    <path
                                                                        fill-rule="evenodd"
                                                                        d="M19.952 1.651a.75.75 0 0 1 .298.599V16.303a3 3 0 0 1-2.176 2.884l-1.32.377a2.553 2.553 0 1 1-1.403-4.909l2.311-.66a1.5 1.5 0 0 0 1.088-1.442V6.994l-9 2.572v9.737a3 3 0 0 1-2.176 2.884l-1.32.377a2.553 2.553 0 1 1-1.402-4.909l2.31-.66a1.5 1.5 0 0 0 1.088-1.442V5.25a.75.75 0 0 1 .544-.721l10.5-3a.75.75 0 0 1 .658.122Z"
                                                                        clip-rule="evenodd"
                                                                    />
                                                                </svg>
                                                            </div>
                                                            <div>
                                                                <div
                                                                    style={{
                                                                        fontSize:
                                                                            '14px',
                                                                        fontWeight:
                                                                            '500',
                                                                        color: 'black',
                                                                        paddingLeft:
                                                                            '7%',
                                                                    }}
                                                                >
                                                                    {
                                                                        song?.title
                                                                    }
                                                                </div>
                                                                <audio
                                                                    src={song?.metadata?.[
                                                                        language
                                                                    ]?.audio_url?.replace(
                                                                        '.m3u8',
                                                                        '/audio.m4a'
                                                                    )}
                                                                    ref={(el) =>
                                                                        (audioRefs.current[
                                                                            index
                                                                        ] = el)
                                                                    }
                                                                    controls
                                                                    style={{
                                                                        height: '1.5rem',
                                                                        backgroundColor:
                                                                            'transparent',
                                                                    }}
                                                                    onPlay={() =>
                                                                        handleAudioPlay(
                                                                            index,
                                                                            songs
                                                                                ?.metadata?.[
                                                                                language
                                                                            ]
                                                                                ?.audio_url
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    marginLeft:
                                                                        'auto',
                                                                    gap: '8px',
                                                                    alignItems:
                                                                        'center',
                                                                }}
                                                            >
                                                                <Dropdown
                                                                    overlay={
                                                                        <Menu>
                                                                            <Menu.Item
                                                                                key="remove"
                                                                                icon={
                                                                                    <DeleteFilled />
                                                                                }
                                                                                onClick={() =>
                                                                                    handleRemoveSong(
                                                                                        index
                                                                                    )
                                                                                }
                                                                            >
                                                                                Remove
                                                                                from
                                                                                Playlist
                                                                            </Menu.Item>
                                                                        </Menu>
                                                                    }
                                                                    trigger={[
                                                                        'click',
                                                                    ]}
                                                                >
                                                                    <Button
                                                                        type="text"
                                                                        icon={
                                                                            <EllipsisOutlined />
                                                                        }
                                                                    />
                                                                </Dropdown>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )
                                        }
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
                <MusicPlayer
                    language={language}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    playList={songs}
                    setPlayList={setSongs}
                    currentIndex={currentIndex}
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', gap: 20 }}>
                <Button onClick={() => closeDrawer()} danger>
                    Cancel
                </Button>
                <Button onClick={() => updatePlaylistfuction()} type="primary">
                    Save
                </Button>
            </div>
        </Drawer>
    )
}

export default DrawerView

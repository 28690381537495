import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Card, Row, Col, message, Button, Spin, Modal } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import HoroscopeModal from './HoroscopeModal'
import { useSelector } from 'react-redux'
import './HoroscopeMain.scss'
import DropdownsForGrid from './DropdownsForGrid'
import HoroscopeCanvas from './HoroscopeCanvas'
import {
    getHoroscopeCards,
    deleteHoroscopeCard,
    createCard,
    updateCard,
    getPanchangamCard,
    deletePanchangamCard,
    getCardByContentId,
    deleteCardRow,
} from '../../AadhanApi'
import dayjs from 'dayjs'
import Panchangam from '../Daily Panchangam/Panchangam'

const { Meta } = Card

function GridHoroPage() {
    const location = useLocation()
    const navigate = useNavigate()
    const {
        language: initialLanguage,
        month: initialMonth,
        day: initialDay,
        uploadedDates: initialUploadedDates,
        zodiacData: zodiacData,
    } = location.state || {}
    const languageData = useSelector((state) => state.app.languageData)
    const accessToken = useSelector((state) => state.app.accessToken)
    const [language, setLanguage] = useState(
        // initialLanguage ||
        languageData?.[0]?.language_id
    )
    const [month, setMonth] = useState(initialMonth || null)
    const [day, setDay] = useState(initialDay || null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isPanchangamEditModalOpen, setIsPanchangamEditModalOpen] =
        useState(false)
    const [currentHoroscope, setCurrentHoroscope] = useState('')
    const [modalText, setModalText] = useState('')
    const [savedImages, setSavedImages] = useState([])
    const [currentHoroId, setCurrentHoroId] = useState()
    const [fullDescText, setFullDescText] = useState('')
    const [uploadedDates, setUploadedDates] = useState(
        initialUploadedDates || {}
    )
    const [cardDate, setCardDate] = useState()
    const [isOverlayVisible, setIsOverlayVisible] = useState(false)
    const [entryId, setEntryId] = useState(null)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [currentPanchangamCode, setCurrentPanchangamCode] = useState(null)
    const [isEditMode, setIsEditMode] = useState(false)
    const [uploadedDate, setUploadedDate] = useState(null)
    const [cardExists, setCardExists] = useState(false)
    const [lastUpdateTime, setLastUpdateTime] = useState(null)
    const [submitButtonText, setSubmitButtonText] = useState('Save to Cards')
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false)

    useEffect(() => {
        if (isSubmitted && entryId) {
            setSubmitButtonText('Update Card')
            setSubmitButtonDisabled(false)
        } else if (cardExists) {
            setSubmitButtonText('Already Up to Date')
            setSubmitButtonDisabled(true)
        } else {
            setSubmitButtonText('Save to Cards')
            setSubmitButtonDisabled(false)
        }
    }, [isSubmitted, entryId, cardExists])

    useEffect(() => {
        const fetchData = async () => {
            await fetchHoroscopes()
            const submissionKey = `${language}-${month}-${day}`
            const lastUpdate = localStorage.getItem(
                `${submissionKey}-lastUpdate`
            )
            setLastUpdateTime(lastUpdate)
        }
        if ((language, month, day)) {
            fetchData()
        }
    }, [language, month, day])

    const handleClick = () => {
        navigate('/home/daily-horoscope', {
            state: {
                gridLanguage: language,
                gridMonth: month,
                gridDay: day,
            },
        })
    }

    useEffect(() => {
        checkSubmissionStatus()
    }, [language, month, day])

    useEffect(() => {
        if (entryId) {
            checkCardExists(entryId)
        }
    }, [entryId, language, month, day])

    const checkCardExists = async (cardId) => {
        try {
            await getCardByContentId(cardId, accessToken)
            setCardExists(true)
            return true
        } catch (error) {
            setCardExists(false)
            return false
        }
    }

    const checkSubmissionStatus = () => {
        const submissionKey = `${language}-${month}-${day}`
        const status = localStorage.getItem(submissionKey)
        setIsSubmitted(status === 'submitted')
        if (status === 'submitted') {
            const storedEntryId = localStorage.getItem(
                `${submissionKey}-entryId`
            )
            setEntryId(storedEntryId)
        }
    }
    const resetSubmissionState = () => {
        const submissionKey = `${language}-${month}-${day}`
        localStorage.removeItem(submissionKey)
        localStorage.removeItem(`${submissionKey}-entryId`)
        setIsSubmitted(false)
        setEntryId(null)
    }

    const showPanchangamEditModal = () => {
        setIsPanchangamEditModalOpen(true)
        setIsEditMode(true)
    }

    const handleClosePanchangamModal = () => {
        setIsPanchangamEditModalOpen(false)
    }

    const fetchHoroscopes = async () => {
        function formatDateForBackend(monthName, day) {
            const monthMap = {
                january: 0,
                february: 1,
                march: 2,
                april: 3,
                may: 4,
                june: 5,
                july: 6,
                august: 7,
                september: 8,
                october: 9,
                november: 10,
                december: 11,
            }
            let monthIndex

            if (typeof monthName === 'string') {
                const lowercaseMonth = monthName.toLowerCase()
                monthIndex = monthMap[lowercaseMonth]
            } else if (typeof monthName === 'number') {
                monthIndex = monthName
            } else {
                return 'Invalid Date'
            }

            if (monthIndex === undefined || monthIndex < 0 || monthIndex > 11) {
                return 'Invalid Date'
            }
            let currentYear = new Date().getFullYear()
            const currentMonth = new Date().getMonth()
            currentYear =
                monthIndex < currentMonth ? currentYear + 1 : currentYear

            const parsedDay = parseInt(day, 10)
            if (isNaN(parsedDay) || parsedDay < 1 || parsedDay > 31) {
                return 'Invalid Date'
            }

            const formattedDate = dayjs()
                .year(currentYear)
                .month(monthIndex)
                .date(parsedDay)
                .format('YYYY-MM-DD')

            return formattedDate
        }
        const monthValue = month
        const dayValue = day
        const result = formatDateForBackend(monthValue, dayValue)

        try {
            if (language) {
                const panchangamResponse = await getPanchangamCard(
                    accessToken,
                    language,
                    result
                )

                const panchangamData = panchangamResponse.data.data[0]
                setCurrentPanchangamCode(panchangamData.code)
                setUploadedDate(panchangamData.uploaded_date)
                const response = await getHoroscopeCards(
                    accessToken,
                    language,
                    result
                )

                if (
                    response.data.success &&
                    response.data.horoscopes.length > 0
                ) {
                    const fetchedHoroscopes = response.data.horoscopes
                    const newUploadedDates = {}

                    const allSavedImages = [
                        {
                            id: panchangamData.code,
                            title: 'Panchangam',
                            src: panchangamData.image_url,
                            fullDesc: 'No full description for cover page',
                            shortDesc: 'No short description for cover page',
                            date: panchangamData.date,
                            uploadedDate: panchangamData.uploaded_date,
                        },
                        ...fetchedHoroscopes.map((horoscope) => {
                            newUploadedDates[horoscope.zodiac_sign.name] =
                                horoscope.uploaded_date
                            return {
                                id: horoscope.code,
                                title: horoscope.zodiac_sign.name,
                                src: horoscope.zodiac_sign.url,
                                fullDesc: horoscope.zodiac_sign.full_desc,
                                shortDesc: horoscope.zodiac_sign.short_desc,
                                date: horoscope.date,
                                uploadedDate: horoscope.uploaded_date,
                            }
                        }),
                    ]
                    setSavedImages(allSavedImages)
                    setUploadedDates(newUploadedDates)
                } else {
                    setSavedImages([])
                }
            }
        } catch (error) {
            console.error('Failed to fetch horoscopes:', error)
            setSavedImages([])
        }
    }

    const showEditModal = (image) => {
        try {
            setCurrentHoroId(image.id)
            setCurrentHoroscope(image.title)
            setModalText(image.shortDesc)
            setFullDescText(image.fullDesc)
            setCardDate(image.date)
            setIsModalOpen(true)
        } catch (error) {
            console.error('Error opening edit modal:', error)
            message.error('Failed to open edit modal. Please try again.')
        }
    }

    const date = dayjs(cardDate)
    const monthName = date.format('MMMM')
    const dayName = date.format('DD')

    const handleDeleteAll = async () => {
        setIsOverlayVisible(true)
        try {
            // Deleting card if it exists
            if (entryId) {
                try {
                    await deleteCardRow(entryId, accessToken)
                    console.log('Card deleted successfully')
                } catch (error) {
                    console.error('Failed to delete card:', error)
                }
            }

            // Then deleting horoscope and panchangam data
            for (const image of savedImages) {
                if (image.title === 'Panchangam') {
                    await deletePanchangamCard(image.id, accessToken)
                } else {
                    await deleteHoroscopeCard(image.id, accessToken)
                }
            }

            message.success('All data deleted successfully')
            resetSubmissionState()
            setCardExists(false)

            // Clearing submission data
            const submissionKey = `${language}-${month}-${day}`
            localStorage.removeItem(`${submissionKey}-lastUpdate`)
            setLastUpdateTime(null)
            localStorage.removeItem(submissionKey)
            localStorage.removeItem(`${submissionKey}-entryId`)
            localStorage.removeItem(`${submissionKey}-uploadedDate`)
            setIsSubmitted(false)
            setEntryId(null)

            // Refreshing the horoscopes display
            fetchHoroscopes()
        } catch (error) {
            console.error('Failed to delete data:', error)
            message.error('Failed to delete all data. Please try again.')
        }
        setIsOverlayVisible(false)
    }

    const LoadingOverlay = () => (
        <div className="loading-overlay">
            <div className="loading-content">
                <Spin size="large" />
                <p>Deleting Horoscope Cards...</p>
            </div>
        </div>
    )

    const handleImageExport = useCallback(() => {
        fetchHoroscopes()
        setIsModalOpen(false)
    }, [])

    const handleSubmitBtn = async () => {
        const months = {
            january: 0,
            february: 1,
            march: 2,
            april: 3,
            may: 4,
            june: 5,
            july: 6,
            august: 7,
            september: 8,
            october: 9,
            november: 10,
            december: 11,
        }

        const getMonthIndex = (month) => {
            if (!isNaN(month)) {
                return parseInt(month, 10)
            } else {
                return months[month.toLowerCase()]
            }
        }

        const selectedDate = dayjs()
            .year(new Date().getFullYear())
            .month(getMonthIndex(month))
            .date(day)
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
        const publishedDate = selectedDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        const currentDate = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ')

        let categoryName

        if (language === 2) {
            categoryName = 'భక్తి'
        } else if (language === 3) {
            categoryName = 'भक्ति'
        } else if (language === 4) {
            categoryName = 'ஆன்மிகம்'
        } else {
            categoryName = 'Devotion'
        }

        const cardData = {
            title: 'Horoscope Card',
            source_id: 0,
            source_url: '',
            category_id: 144,
            content_type: 'card',
            expiry_hours: 24,
            published_date: publishedDate,
            popular_card: false,
            dynamic_url: '',
            is_active: true,
            content_status: 'approved',
            language_id: language,
            image_urls: savedImages.map((image) => ({
                uid: '',
                lastModified: {},
                lastModifiedDate: currentDate,
                name: '',
                size: null,
                type: 'image/jpeg',
                percent: null,
                originFileObj: {},
                status: '',
                response: image.src,
                thumbUrl: '',
            })),
            thumbnailimage_url: '',
            code: '',
            state: '',
            district: '',
            city: '',
            keywords: '',
            uploaded_by_name: 'Aadhan Bot',
            proof_read_by_name: 'Aadhan Bot',
            category_name: categoryName,
            proof_read_by: '437',
            proof_read_date: publishedDate,
            is_proof_read: true,
            uploaded_by: '437',
            updated_by: 0,
            uploaded_date: currentDate,
        }

        try {
            let response

            if (isSubmitted && entryId) {
                const cardExists = await checkCardExists(entryId)

                if (!cardExists) {
                    // Card was deleted, create new one
                    response = await createCard(cardData, accessToken)
                    if (
                        response.data.message === 'Card inserted successfully'
                    ) {
                        setEntryId(response.data.entry_id)
                        message.success('New card created successfully!')
                        const submissionKey = `${language}-${month}-${day}`
                        localStorage.setItem(submissionKey, 'submitted')
                        localStorage.setItem(
                            `${submissionKey}-entryId`,
                            response.data.entry_id
                        )
                        localStorage.setItem(
                            `${submissionKey}-uploadedDate`,
                            currentDate
                        )
                        setIsSubmitted(true)
                        setEntryId(response.data.entry_id)
                        setCardExists(true)
                    }
                    return
                }

                const originalUploadedDate = localStorage.getItem(
                    `${language}-${month}-${day}-uploadedDate`
                )
                cardData.uploaded_date = originalUploadedDate || currentDate

                response = await updateCard(cardData, entryId, accessToken)
                if (response.data === 'Card updated successfully') {
                    const submissionKey = `${language}-${month}-${day}`
                    const currentTime = new Date().toISOString()
                    localStorage.setItem(
                        `${submissionKey}-lastUpdate`,
                        currentTime
                    )
                    setLastUpdateTime(currentTime)
                    message.success('Card updated successfully!')
                } else {
                    message.error('Failed to update card. Please try again.')
                }
            } else {
                response = await createCard(cardData, accessToken)
                if (response.data.message === 'Card inserted successfully') {
                    setEntryId(response.data.entry_id)
                    localStorage.setItem(
                        `${language}-${month}-${day}-uploadedDate`,
                        currentDate
                    )
                    message.success('Card saved successfully!')
                    setCardExists(true)
                } else {
                    message.error('Failed to save card. Please try again.')
                }
            }

            if (
                response.data === 'Card updated successfully' ||
                response.data.message === 'Card inserted successfully'
            ) {
                const submissionKey = `${language}-${month}-${day}`
                localStorage.setItem(submissionKey, 'submitted')
                localStorage.setItem(
                    `${submissionKey}-entryId`,
                    response.data.entry_id || entryId
                )
                setIsSubmitted(true)
                setEntryId(response.data.entry_id || entryId)
                setCardExists(true)
            }
        } catch (error) {
            console.error('Error saving or updating card:', error)
            if (error.response?.status === 404) {
                try {
                    const response = await createCard(cardData, accessToken)
                    if (
                        response.data.message === 'Card inserted successfully'
                    ) {
                        message.success('New card created successfully!')
                        const submissionKey = `${language}-${month}-${day}`
                        localStorage.setItem(submissionKey, 'submitted')
                        localStorage.setItem(
                            `${submissionKey}-entryId`,
                            response.data.entry_id
                        )
                        setIsSubmitted(true)
                        setEntryId(response.data.entry_id)
                        setCardExists(true)
                    }
                } catch (createError) {
                    message.error(
                        'Failed to create new card. Please try again.'
                    )
                }
            } else {
                message.error('An error occurred. Please try again.')
            }
        }
    }

    const monthNameToNumber = (monthName) => {
        const months = {
            january: 0,
            february: 1,
            march: 2,
            april: 3,
            may: 4,
            june: 5,
            july: 6,
            august: 7,
            september: 8,
            october: 9,
            november: 10,
            december: 11,
        }
        return months[monthName.toLowerCase()]
    }

    return (
        <div className="container_main">
            {isOverlayVisible && <LoadingOverlay />}
            <div className="arrowandtitle">
                <svg
                    className="svg-arrow"
                    height="35px"
                    id="Layer_1"
                    style={{
                        enableBackground: 'new 0 0 512 512',
                        marginRight: '0px',
                        marginLeft: '-8px',
                        cursor: 'pointer',
                    }}
                    onClick={handleClick}
                    version="1.1"
                    viewBox="0 0 512 512"
                    width="35px"
                    xmlSpace="preserve"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                    <polygon
                        points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 "
                        fill="black"
                    />
                </svg>
                <h1 className="horo-title">Horoscope Carousel</h1>
            </div>
            <hr className="line-grid" aria-hidden="true"></hr>
            <div className="gridDropdownsAndSumbitBtn">
                <DropdownsForGrid
                    language={language}
                    setLanguage={setLanguage}
                    month={month}
                    setMonth={setMonth}
                    day={day}
                    setDay={setDay}
                />
                <div>
                    <Button
                        type="primary"
                        className="submitBtn"
                        onClick={handleSubmitBtn}
                        disabled={submitButtonDisabled}
                    >
                        {submitButtonText}
                    </Button>
                    <Button
                        type="primary"
                        className="deleteAllBtn"
                        onClick={handleDeleteAll}
                    >
                        Delete Card
                    </Button>
                </div>
            </div>
            <Row gutter={[16, 16]} className="grid-content">
                {savedImages.map((image) => (
                    <Col xs={24} sm={12} md={8} lg={6} key={image.id}>
                        <Card
                            hoverable
                            className="card-el"
                            cover={
                                <img
                                    key={image.id}
                                    alt={image.title}
                                    src={image.src}
                                />
                            }
                        >
                            <div className="titleAndEdit">
                                <Meta
                                    title={image.title}
                                    className="titleInGrid"
                                />
                                <EditOutlined
                                    key="edit"
                                    onClick={() => {
                                        if (image.title === 'Panchangam') {
                                            showPanchangamEditModal()
                                        } else {
                                            showEditModal(image)
                                        }
                                    }}
                                />
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Modal
                open={isPanchangamEditModalOpen}
                onCancel={handleClosePanchangamModal}
                width={1100}
                footer={null}
                style={{ top: 20 }}
            >
                <Panchangam
                    language={language}
                    month={
                        typeof month === 'string'
                            ? monthNameToNumber(month)
                            : month
                    }
                    day={day}
                    isEditMode={isEditMode}
                    currentPanchangamId={currentPanchangamCode}
                    existingPanchangamData={savedImages.find(
                        (img) => img.title === 'Panchangam'
                    )}
                    onEditSuccess={() => {
                        fetchHoroscopes()
                        handleClosePanchangamModal()
                    }}
                    uploadedDate={uploadedDate}
                />
            </Modal>
            <HoroscopeModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                currentHoroscope={currentHoroscope}
                modalText={modalText}
                setModalText={setModalText}
                onImageExport={handleImageExport}
                currentHoroId={currentHoroId}
                fullDescText={fullDescText}
                uploadedDate={uploadedDates[currentHoroscope]}
                fetchHoroscopes={fetchHoroscopes}
                month={month}
                day={day}
                cardDate={cardDate}
                language={language}
                zodiacData={zodiacData}
            >
                <HoroscopeCanvas
                    modalText={modalText}
                    month={monthName}
                    day={dayName}
                    currentHoroscope={currentHoroscope}
                    onImageExport={handleImageExport}
                    zodiacData={zodiacData}
                />
            </HoroscopeModal>
        </div>
    )
}

export default GridHoroPage

import React, { useEffect, useRef, useState } from 'react'
import { Table, Image, Button, Popconfirm, message } from 'antd'
import '../devotional.scss'
import DynamicFormModal from './DynamicFormModal'
import { SelectComponent } from '../../AntdComponent/SelectComponent'
import { SelectComponentWithSearch } from '../../AntdComponent/SelectComponentWithSearch'
import {
    CheckCircleOutlined,
    DeleteFilled,
    RightOutlined,
} from '@ant-design/icons'
import MusicPlayer from './MusicPlayer'
import AddPlaylistModal from './AddPlaylistModal'
import DrawerView from '../DrawerView'
import {
    addSongsToPlaylist,
    deletePlaylist,
    deleteSong,
    getAllPlaylists,
    getAllSongs,
} from '../../../AadhanApi'
import { useSelector } from 'react-redux'
import AddToPlaylist from './AddToPlaylist'
import { useLocation } from 'react-router'
const Songs = ({ languageData }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isDrawerVisible, setIsDrawerVisible] = useState(false)
    const [playList, setPlayList] = useState([])
    const [playlistdata, setplaylistdata] = useState([])
    const [songs, setSongs] = useState([])
    const location = useLocation()
    const [status, setStatus] = useState('all')
    const [currentTrackIndex, setCurrentTrackIndex] = useState(null) // Updated to be null initially
    const audioRefs = useRef({})
    const accessToken = useSelector((state) => state.app.accessToken)
    const statusOptions = [
        {
            value: 'all',
            label: '--All--',
        },
        {
            value: 'approved',
            label: 'Published',
        },
        {
            value: 'draft',
            label: 'Draft',
        },
    ]
    const [language, setLanguage] = useState(languageData?.[0]?.language_id) // Default language
    const [currentPlayList, setCurrentPlaylist] = useState([])
    const [selectedPlaylist, setSelectedPlaylist] = useState(undefined)
    const CastList = ({ cast }) => {
        const { names, roles } = cast
        const nameList = names?.replaceAll(',', ', ')
        const roleList = roles?.replaceAll(',', ', ')

        const styles = {
            container: {
                display: 'flex',
                flexDirection: 'column',
            },
            names: {
                color: 'plum',
                fontSize: '14px',
                fontStyle: 'italic',
            },
            roles: {
                color: '#666',
                fontStyle: 'italic',
                fontSize: '14px',
            },
        }

        return (
            <div style={styles.container}>
                <div style={styles.roles}>{roleList}</div>
                <div style={styles.names}>{nameList}</div>
            </div>
        )
    }
    const handleAddSongToPlaylist = async (playlist, id) => {
        const res = await addSongsToPlaylist(
            accessToken,
            [id],
            playlist?.playlist_id
        )
        if (res?.status === 200) {
            message.success('song added to selected playlist')
        } else {
            message.error('unable to add song')
        }
    }
    const handleAudioPlay = (index, audioUrl) => {
        Object.values(audioRefs.current).forEach((audio, idx) => {
            if (idx !== index && audio) audio.pause()
        })
        if (audioRefs.current[index]) {
            audioRefs.current[index].play()
        }

        setCurrentTrackIndex(index)
    }
    async function deleteSongfunc(id) {
        const res = await deleteSong(accessToken, id)
        if (res?.status === 200) {
            message.success('playlist deleted successfully')
        } else {
            message.error('unable to deleted playlist')
        }
        getAllSongsfuction()
    }
    async function deletePlaylistfunc(id) {
        const res = await deletePlaylist(accessToken, id)
        if (res?.status === 200) {
            message.success('playlist deleted successfully')
        } else {
            message.error('unable to deleted playlist')
        }
        getAllPlaylistsfunction()
    }
    const columns = [
        {
            title: 'Song Info',
            dataIndex: 'Song Info',
            key: 'Song Info',
            render: (text, record, index) => (
                <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                    <div
                        style={{
                            display: 'flex',
                            gap: 10,
                            alignItems: 'center',
                            width: '70%',
                            paddingRight: 10,
                        }}
                    >
                        <Image
                            src={record?.metadata?.[language]?.image_url}
                            alt="Artwork"
                            width={65}
                            height={65}
                            style={{
                                borderRadius: 10,
                                boxShadow: '2px 2px 2px lightgray',
                            }}
                            preview={false}
                        />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'start',
                            }}
                        >
                            <div style={{ fontWeight: 500 }}>
                                {record?.title}{' '}
                                <span
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        marginLeft: 3,
                                        color: 'green',
                                    }}
                                >
                                    {record?.content_status === 'approved' &&
                                        status === 'all' && (
                                            <CheckCircleOutlined />
                                        )}
                                </span>
                            </div>

                            {record?.metadata?.[language]?.cast && (
                                <CastList
                                    cast={record?.metadata?.[language]?.cast}
                                />
                            )}
                        </div>
                    </div>
                    <audio
                        src={record?.metadata?.[language]?.audio_url?.replace(
                            '.m3u8',
                            '/audio.m4a'
                        )}
                        ref={(el) => (audioRefs.current[index] = el)}
                        controls
                        style={{
                            height: '1.5rem',
                            backgroundColor: 'transparent',
                        }}
                        onPlay={() =>
                            handleAudioPlay(
                                index,
                                record?.metadata?.[language]?.audio_url
                            )
                        }
                    />
                </div>
            ),
        },
        {
            title: 'Actions',
            render: (text, record, index) => (
                <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
                    <DynamicFormModal
                        language={language}
                        getAllSongsfuction={getAllSongsfuction}
                        record={record}
                        mode={'edit'}
                        languageData={languageData}
                    />
                    <Popconfirm
                        title={
                            <div>
                                Are you sure you want to delete this song?
                                <br />
                                It will also be removed from the playlist.
                            </div>
                        }
                        onConfirm={() => deleteSongfunc(record?.song_id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="primary"
                            danger
                            icon={<DeleteFilled />}
                        ></Button>
                    </Popconfirm>
                    {record?.content_status === 'approved' && (
                        <AddToPlaylist
                            playlists={playlistdata}
                            songId={record?.song_id}
                            onAddSongToPlaylist={handleAddSongToPlaylist}
                        />
                    )}
                </div>
            ),
        },
    ]
    const columnsPlaylist = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: '80%',
            render: (text, record) => (
                <div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={15}
                        height={15}
                        style={{ marginRight: 10 }}
                        color={'#ff4d4f'}
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        class="size-6"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M19.952 1.651a.75.75 0 0 1 .298.599V16.303a3 3 0 0 1-2.176 2.884l-1.32.377a2.553 2.553 0 1 1-1.403-4.909l2.311-.66a1.5 1.5 0 0 0 1.088-1.442V6.994l-9 2.572v9.737a3 3 0 0 1-2.176 2.884l-1.32.377a2.553 2.553 0 1 1-1.402-4.909l2.31-.66a1.5 1.5 0 0 0 1.088-1.442V5.25a.75.75 0 0 1 .544-.721l10.5-3a.75.75 0 0 1 .658.122Z"
                            clip-rule="evenodd"
                        />
                    </svg>
                    <span style={{ color: 'blue' }}>{record?.title}</span>
                </div>
            ),
        },
        {
            title: 'Actions',
            render: (_, record) => (
                <div style={{ display: 'flex', gap: 10 }}>
                    <AddPlaylistModal
                        getAllPlaylistsfunction={getAllPlaylistsfunction}
                        record={record}
                        mode={'edit'}
                        languageData={languageData}
                        languageId={language}
                    />
                    <Button
                        type="primary"
                        onClick={() => deletePlaylistfunc(record?.playlist_id)}
                        danger
                        icon={<DeleteFilled />}
                    />{' '}
                    <Button
                        onClick={() => {
                            setIsDrawerVisible(true)
                            setCurrentPlaylist(record)
                        }}
                        type="primary"
                        icon={<RightOutlined />}
                    />
                </div>
            ),
        },
    ]
    async function getAllSongsfuction() {
        setLoading(true)
        if (language) {
            const res = await getAllSongs(
                accessToken,
                language,
                status,
                selectedPlaylist
            )
            setSongs(res?.data)
        }
        setLoading(false)
    }
    async function getAllPlaylistsfunction() {
        setLoading(true)
        const res = await getAllPlaylists(accessToken, language, status)
        setplaylistdata(res?.data)
        setLoading(false)
    }
    useEffect(() => {
        if(language){

            getAllSongsfuction()
            getAllPlaylistsfunction()
        }
        
    }, [language, status, selectedPlaylist])

    return (
        <div className="songs-container">
            <div>
                {location?.pathname?.includes('songs') ? (
                    <h4>Manage Songs</h4>
                ) : (
                    <h4>Manage Playlists</h4>
                )}
            </div>
            <hr></hr>
            <div className="songs-header">
                <div>
                    <SelectComponent
                        data={statusOptions}
                        value={status}
                        onChange={(e) => setStatus(e)}
                        key_value={'value'}
                        display={'label'}
                        className={''}
                        style={{ width: 130 }}
                        placeholder={''}
                    />
                </div>
                <div>
                    <SelectComponent
                        data={languageData}
                        value={language}
                        onChange={(e) => {
                            setLanguage(e)
                            setSelectedPlaylist(undefined)
                        }}
                        key_value={'language_id'}
                        display={'language_name'}
                        className={''}
                        style={{ width: 130 }}
                        placeholder={''}
                    />
                </div>
                {location?.pathname?.includes('songs') && (
                    <SelectComponentWithSearch
                        data={playlistdata}
                        value={selectedPlaylist}
                        onChange={(e) => setSelectedPlaylist(e)}
                        key_value={'playlist_id'}
                        display={'title'}
                        className={''}
                        style={{ width: 220 }}
                        placeholder={'Select Playlist'}
                    />
                )}
            </div>
            <div style={{ position: 'absolute', right: '1rem', top: 0 }}>
                {location?.pathname?.includes('playlists') ? (
                    <AddPlaylistModal
                        getAllPlaylistsfunction={getAllPlaylistsfunction}
                        isDrawerVisible={isDrawerVisible}
                        setIsDrawerVisible={setIsDrawerVisible}
                        data={songs}
                        languageId={language}
                        languageData={languageData}
                    />
                ) : (
                    <DynamicFormModal
                        getAllSongsfuction={getAllSongsfuction}
                        languageData={languageData}
                    />
                )}
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '2px',
                }}
            ></div>
            {location?.pathname?.includes('playlists') ? (
                <Table
                    columns={columnsPlaylist}
                    loading={loading}
                    className="custom-songs-table"
                    dataSource={playlistdata}
                />
            ) : (
                <Table
                    className="custom-songs-table"
                    dataSource={songs}
                    pagination={{
                        pageSize: 5,
                        showSizeChanger: false,
                    }}
                    loading={loading}
                    columns={columns}
                />
            )} 
            <MusicPlayer
                isModalOpen={isModalOpen}
                playList={playList}
                language={language}
                setPlayList={setPlayList}
                currentIndex={currentTrackIndex}
                setIsModalOpen={setIsModalOpen}
            />
            <DrawerView
                isDrawerVisible={isDrawerVisible}
                setIsDrawerVisible={setIsDrawerVisible}
                data={songs}
                language={language}
                getAllPlaylistsfunction={getAllPlaylistsfunction}
                currentPlaylist={currentPlayList}
            />
        </div>
    )
}

export default Songs

import { Button, Form, Input, Space } from 'antd'
import React from 'react'

const BasicInfo = ({ form, handleSaveForm, setCurrentForm }) => {
    return (
        <div style={{ height: '80%' }}>
            <Form
                layout="vertical"
                style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '10px',
                }}
                form={form}
                onFinish={handleSaveForm}
            >
                <Form.Item
                    name="title"
                    label="Title"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: 'Please enter title',
                        },
                    ]}
                >
                    <Input placeholder="Enter title" />
                </Form.Item>

                <Form.Item
                    name="category"
                    label="Category"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter category',
                        },
                    ]}
                >
                    <Input disabled placeholder="Enter category" />
                </Form.Item>

                <Space
                    style={{
                        margin: 'auto',
                        justifyContent: 'center',
                        gap: '20px',
                    }}
                >
                    <Button
                        type="primary"
                        onClick={() => setCurrentForm(null)}
                        danger
                        style={{
                            padding: '8px 16px',
                            borderRadius: '4px',
                            fontSize: '14px',
                            width: 120,
                        }}
                    >
                        Cancel
                    </Button>

                    <Button
                        type="primary"
                        onClick={handleSaveForm}
                        style={{
                            width: 120,
                            padding: '8px 16px',
                            borderRadius: '4px',
                            fontSize: '14px',
                            border: 'none',
                        }}
                    >
                        Save
                    </Button>
                </Space>
            </Form>
        </div>
    )
}

export default BasicInfo

import React, { useState } from 'react'
import {
    Table,
    Button,
    Modal,
    Form,
    Input,
    InputNumber,
    message,
    Row,
    Col,
    Select,
} from 'antd'
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'

const { Option } = Select

const RevenueManagement = () => {
    // State management for all entities
    const [platforms, setPlatforms] = useState([])
    const [adTypes, setAdTypes] = useState([])
    const [packages, setPackages] = useState([
        {
            id: 1,
            name: 'Package 1',
            platform: 'App',
            adType: 'PreRoll Ad',
            price: 100,
        },
        {
            id: 2,
            name: 'Package 2',
            platform: 'App',
            adType: 'Sponsorship',
            price: 200,
        },
        {
            id: 3,
            name: 'Package 3',
            platform: 'YouTube',
            adType: 'L Band',
            price: 100,
        },
        {
            id: 4,
            name: 'Package 4',
            platform: 'YouTube',
            adType: 'Strip Ad',
            price: 100,
        },
        {
            id: 5,
            name: 'Package 5',
            platform: 'YouTube',
            adType: 'PreRoll Ad',
            price: 200,
        },
        {
            id: 6,
            name: 'Package 6',
            platform: 'YouTube',
            adType: 'Sponsorship',
            price: 100,
        },
        {
            id: 7,
            name: 'Package 7',
            platform: 'Instagram',
            adType: 'PreRoll Ad',
            price: 100,
        },
        {
            id: 8,
            name: 'Package 8',
            platform: 'Instagram',
            adType: 'Sponsorship',
            price: 200,
        },
        {
            id: 9,
            name: 'Package 9',
            platform: 'Live TV',
            adType: 'PreRoll Ad',
            price: 100,
        },
        {
            id: 10,
            name: 'Package 10',
            platform: 'Live TV',
            adType: 'Sponsorship',
            price: 200,
        },
        {
            id: 11,
            name: 'Package 11',
            platform: 'Facebook',
            adType: 'PreRoll Ad',
            price: 100,
        },
        {
            id: 12,
            name: 'Package 12',
            platform: 'Facebook',
            adType: 'Sponsorship',
            price: 200,
        },
    ])

    // Modal states
    const [modalType, setModalType] = useState(null)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [editingId, setEditingId] = useState(null)

    // Forms
    const [platformForm] = Form.useForm()
    const [adTypeForm] = Form.useForm()
    const [packageForm] = Form.useForm()

    // Platform table columns
    const platformColumns = [
        {
            title: 'Platform Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <div className="flex gap-2">
                    <Button
                        type="primary"
                        icon={<EditOutlined />}
                        onClick={() => handleEdit('platform', record)}
                    />
                    <Button
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => handleDelete('platform', record.id)}
                    />
                </div>
            ),
        },
    ]

    // Ad Type table columns
    const adTypeColumns = [
        {
            title: 'Ad Type',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <div className="flex gap-2">
                    <Button
                        type="primary"
                        icon={<EditOutlined />}
                        onClick={() => handleEdit('adType', record)}
                    />
                    <Button
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => handleDelete('adType', record.id)}
                    />
                </div>
            ),
        },
    ]

    // Product table columns
    const productColumns = [
        {
            title: 'Package Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Platform',
            dataIndex: 'platform',
            key: 'platform',
        },
        {
            title: 'Ad Type',
            dataIndex: 'adType',
            key: 'adType',
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (price) => `₹${price}`,
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <div className="flex gap-2">
                    <Button
                        type="primary"
                        icon={<EditOutlined />}
                        onClick={() => handleEdit('product', record)}
                    />
                    <Button
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => handleDelete('product', record.id)}
                    />
                </div>
            ),
        },
    ]

    // Generic handlers
    const handleAdd = (type) => {
        setModalType(type)
        setEditingId(null)
        getFormByType(type).resetFields()
        setIsModalVisible(true)
    }

    const handleEdit = (type, record) => {
        setModalType(type)
        setEditingId(record.id)
        getFormByType(type).setFieldsValue(record)
        setIsModalVisible(true)
    }

    const handleDelete = (type, id) => {
        if (type === 'platform') {
            setPlatforms((prev) => prev.filter((item) => item.id !== id))
        } else if (type === 'adType') {
            setAdTypes((prev) => prev.filter((item) => item.id !== id))
        } else if (type === 'product') {
            setPackages((prev) => prev.filter((item) => item.id !== id))
        }
        message.success(
            `${type.charAt(0).toUpperCase() + type.slice(1)} deleted successfully`
        )
    }

    const getFormByType = (type) => {
        if (type === 'platform') return platformForm
        if (type === 'adType') return adTypeForm
        if (type === 'product') return packageForm
    }

    const handleSubmit = (values) => {
        if (modalType === 'platform') {
            if (editingId) {
                setPlatforms((prev) =>
                    prev.map((item) =>
                        item.id === editingId
                            ? { ...values, id: editingId }
                            : item
                    )
                )
            } else {
                setPlatforms((prev) => [...prev, { ...values, id: Date.now() }])
            }
        } else if (modalType === 'adType') {
            if (editingId) {
                setAdTypes((prev) =>
                    prev.map((item) =>
                        item.id === editingId
                            ? { ...values, id: editingId }
                            : item
                    )
                )
            } else {
                setAdTypes((prev) => [...prev, { ...values, id: Date.now() }])
            }
        } else if (modalType === 'product') {
            if (editingId) {
                setPackages((prev) =>
                    prev.map((item) =>
                        item.id === editingId
                            ? { ...values, id: editingId }
                            : item
                    )
                )
            } else {
                setPackages((prev) => [...prev, { ...values, id: Date.now() }])
            }
        }
        setIsModalVisible(false)
        message.success(
            `${modalType.charAt(0).toUpperCase() + modalType.slice(1)} ${editingId ? 'updated' : 'added'} successfully`
        )
    }

    // Modal content based on type
    const getModalContent = () => {
        if (modalType === 'platform') {
            return (
                <Form
                    form={platformForm}
                    onFinish={handleSubmit}
                    layout="vertical"
                >
                    <Form.Item
                        name="name"
                        label="Platform Name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter platform name',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            )
        } else if (modalType === 'adType') {
            return (
                <Form
                    form={adTypeForm}
                    onFinish={handleSubmit}
                    layout="vertical"
                >
                    <Form.Item
                        name="name"
                        label="Ad Type Name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter ad type name',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            )
        } else if (modalType === 'product') {
            return (
                <Form
                    form={packageForm}
                    onFinish={handleSubmit}
                    layout="vertical"
                >
                    <Form.Item
                        name="name"
                        label="Package Name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter package name',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="platform"
                        label="Platform"
                        rules={[
                            {
                                required: true,
                                message: 'Please select platform',
                            },
                        ]}
                    >
                        <Select placeholder="Select Platform">
                            {platforms.map((platform) => (
                                <Option key={platform.id} value={platform.name}>
                                    {platform.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="adType"
                        label="Ad Type"
                        rules={[
                            {
                                required: true,
                                message: 'Please select ad type',
                            },
                        ]}
                    >
                        <Select placeholder="Select Ad Type">
                            {adTypes.map((adType) => (
                                <Option key={adType.id} value={adType.name}>
                                    {adType.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="price"
                        label="Price"
                        rules={[
                            { required: true, message: 'Please enter price' },
                        ]}
                    >
                        <InputNumber
                            prefix="₹"
                            min={0}
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </Form>
            )
        }
    }

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4">Revenue Management</h1>

            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <h2>Platforms</h2>
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => handleAdd('platform')}
                        style={{ marginBottom: '16px' }}
                    >
                        Add Platform
                    </Button>
                    <Table
                        columns={platformColumns}
                        dataSource={platforms}
                        rowKey="id"
                        pagination={false}
                        scroll={{ y: 300 }}
                    />
                </Col>

                <Col span={12}>
                    <h2>Ad Types</h2>
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => handleAdd('adType')}
                        style={{ marginBottom: '16px' }}
                    >
                        Add Ad Type
                    </Button>
                    <Table
                        columns={adTypeColumns}
                        dataSource={adTypes}
                        rowKey="id"
                        pagination={false}
                        scroll={{ y: 300 }}
                    />
                </Col>
            </Row>

            <Row style={{ marginTop: '32px' }}>
                <Col span={24}>
                    <h2>Products</h2>
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => handleAdd('product')}
                        style={{ marginBottom: '16px' }}
                    >
                        Add Product
                    </Button>
                    <Table
                        columns={productColumns}
                        dataSource={packages}
                        rowKey="id"
                        pagination={false}
                        scroll={{ y: 400 }}
                    />
                </Col>
            </Row>

            <Modal
                title={`${editingId ? 'Edit' : 'Add'} ${modalType?.charAt(0).toUpperCase()}${modalType?.slice(1)}`}
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                onOk={() => getFormByType(modalType).submit()}
            >
                {getModalContent()}
            </Modal>
        </div>
    )
}

export default RevenueManagement

import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import SponsoredReport from './SponsoredReport';
import SponsoredContent from './SponsoredContent';

const SponsoredPage = () => {
  const location = useLocation(); 
  return (
    <div>
      {location?.pathname?.includes("/home/editorDashboard/sponsored-report") ? <SponsoredReport/> : <SponsoredContent/> }
    </div>
  )
}

export default SponsoredPage

import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { Table, Button, Popconfirm, message } from 'antd'
import { CheckCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import MusicPlayer from '../Songs/MusicPlayer'
import CreateEpisode from './CreateEpisode'
import { updatePodcast } from '../../../AadhanApi'

const EpisodesList = forwardRef(
    (
        {
            language,
            languageData,
            accessToken,
            data,
            onPlay,
            getdata,
            audioRefs,
        },
        ref
    ) => {
        const [isModalOpen, setIsModalOpen] = useState(false)
        const [currentTrackIndex, setCurrentTrackIndex] = useState(undefined)
        const employeeId = localStorage.getItem('EMPLOYEE_ID')

        const childFunction = () => {
            setCurrentTrackIndex(undefined)
            setIsModalOpen(true)
        }

        useImperativeHandle(ref, () => ({
            childFunction,
        }))
        async function handleDelete(id) {
            let temp = data
            const ep = data?.episodes?.filter((e) => e?.sequence !== id)
            temp['episodes'] = ep
            temp['updated_by'] = employeeId

            const res = await updatePodcast(accessToken, temp, data?.podcast_id)
            if (res?.status === 200) {
                message.success('Episode deleted successfully')
                getdata()
            } else {
                message.error('Unable to delete episode')
            }
        }
        const handleAudioPlay = (index, audioUrl) => {
            onPlay(0)

            Object.values(audioRefs.current).forEach((audio, idx) => {
                if (idx !== index && audio) audio.pause()
            })
            if (audioRefs?.current?.[index]) {
                audioRefs?.current?.[index]?.play()
            }

            setCurrentTrackIndex(index)
        }

        const columns = [
            {
                title: 'Podcasts',
                key: 'details',
                width: '78%',
                render: (_, record, index) => (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '16px',
                        }}
                    >
                        <div>
                            <img
                                src={record?.image_url}
                                alt={record?.title}
                                style={{
                                    width: '90px',
                                    height: '90px',
                                    objectFit: 'cover',
                                    borderRadius: '8px',
                                    border: '2px solid #ddd',
                                }}
                            />
                        </div>
                        <div style={{ flex: 1 }}>
                            <span
                                style={{
                                    cursor: 'pointer',
                                    color: '#1a73e8',
                                    fontWeight: '500',
                                    fontSize: '16px',
                                }}
                            >
                                {record.title}{' '}
                                <span
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        marginLeft: 3,
                                        color: 'green',
                                    }}
                                >
                                    {record?.content_status === 'approved' && (
                                        <CheckCircleOutlined />
                                    )}
                                </span>
                            </span>
                            <p
                                style={{
                                    margin: '0px 0',
                                    color: '#666',
                                    fontSize: '14px',
                                }}
                            >
                                {record.description}
                            </p>
                            <p
                                style={{
                                    margin: '2px 0',
                                    color: '#444',
                                    fontSize: '14px',
                                    display: 'flex',
                                    gap: 20,
                                }}
                            >
                                <div>
                                    <strong>Episode:</strong> {record?.sequence}
                                </div>
                                <div>
                                    <audio
                                        src={record?.audio_url?.replace(
                                            '.m3u8',
                                            '/audio.m4a'
                                        )}
                                        ref={(el) =>
                                            (audioRefs.current[index] = el)
                                        }
                                        controls
                                        style={{
                                            height: '1.5rem',
                                            backgroundColor: 'transparent',
                                        }}
                                        onPlay={() =>
                                            handleAudioPlay(
                                                index,
                                                record?.audio_url
                                            )
                                        }
                                    />
                                </div>
                            </p>
                        </div>
                    </div>
                ),
            },
            {
                title: (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div>Actions</div>
                    </div>
                ),
                key: 'actions',
                render: (_, record) => (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: '16px',
                            padding: '16px',
                        }}
                    >
                        <CreateEpisode
                            getdata={getdata}
                            languageData={languageData}
                            language={language}
                            accessToken={accessToken}
                            data={data}
                            record={record}
                            mode={'edit'}
                        />
                        <Popconfirm
                            title={
                                <div>
                                    Are you sure you want to delete this
                                    episode?
                                </div>
                            }
                            onConfirm={() => handleDelete(record?.sequence)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                type="primary"
                                danger
                                icon={<DeleteOutlined />}
                                style={{
                                    backgroundColor: '#d32f2f',
                                    borderColor: '#d32f2f',
                                    color: '#fff',
                                    borderRadius: '20px',
                                    padding: '0 20px',
                                    fontWeight: 'bold',
                                    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
                                }}
                            ></Button>
                        </Popconfirm>
                    </div>
                ),
            },
        ]

        return (
            <div>
                <Table
                    className="custom-songs-table"
                    dataSource={data?.episodes}
                    columns={columns}
                    pagination={{
                        pageSize: 5, 
                        showSizeChanger: false, 
                    }}
                    scroll={{
                        y: 'calc(100vh - 200px)',
                    }}
                />

                <MusicPlayer
                    isModalOpen={isModalOpen}
                    currentIndex={currentTrackIndex}
                    playList={[]}
                    setIsModalOpen={setIsModalOpen}
                />
            </div>
        )
    }
)

export default EpisodesList

import React, { useEffect, useState } from 'react'
import { Button, Col, message, Row, Spin } from 'antd'
import './GoogleImageSearch.scss'
import Search from 'antd/es/input/Search'
import ShortNewsImageUpload from '../AddShortNews/ShortNewsImageUpload'
import { getImagesFromGoogleApi, createFileObject } from '../../AadhanApi'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router'

const GoogleImageSearch = ({
    accessToken,
    languageOption,
    videoPath,
    setImagePath,
    imagePath,
    isOriginal,
    setIsOriginal,
    isSensitive,
    setIsSensitive,
    setIsImageAdding,
    image,
}) => {
    const [query, setQuery] = useState('')
    const [imageLoading, setImageLoading] = useState(false)
    const [images, setImages] = useState([])
    const location = useLocation()
    const [loading, setLoading] = useState(false)
    const [file, setFile] = useState()
    const [start, setStart] = useState(1)
    // const [title, setTitle] = useState('')

    useEffect(() => {
        return () => {
            localStorage.removeItem('googleSearchImages')
        }
    }, [])

    const handleSearch = async () => {
        if (!query) {
            message.warning('Please enter a search term')
            return
        }

        try {
            setLoading(true)
            setStart(1)
            const response = await getImagesFromGoogleApi(query, 1, accessToken)
            setImages(response.data)
            localStorage.setItem(
                'googleSearchImages',
                JSON.stringify(response.data)
            )
            setLoading(false)
        } catch (error) {
            message.error('Failed to fetch images. Please try again.')
            console.error('Error fetching images', error)
        } finally {
            setLoading(false)
        }
    }

    const handleLoadMore = async () => {
        try {
            const nextStart = start + 10
            const response = await getImagesFromGoogleApi(
                query,
                nextStart,
                accessToken
            )
            setImages((prevImages) => [...prevImages, ...response.data])
            setStart(nextStart)
            localStorage.setItem(
                'googleSearchImages',
                JSON.stringify([...images, ...response.data])
            )
        } catch (error) {
            console.error('Error loading more images', error)
            setLoading(false)
        }
    }

    async function urlToFile(url, fileName, mimeType = 'image/jpeg') {
        try {
            const response = await fetch(url, { mode: 'cors' })
            const blob = await response.blob()
            return new File([blob], fileName, { type: mimeType })
        } catch (error) {
            console.error('Error converting image URL to file:', error)
            return null
        }
    }
    const handleImageSelect = async (img, index) => {
        if (
            (location.pathname.includes('home/reporter-dashboard') ||
                videoPath?.stream_url ||
                videoPath?.yt_url) &&
            image?.length >= 1
        ) {
            message.error('Image limit exceeded. Maximum allowed is 1.')
            return
        } else if (image?.length >= 5) {
            message.error('Image limit exceeded. Maximum allowed is 5.')
            return
        }
        if (imageLoading) {
            return
        }
        let file = await urlToFile(img.link, `image_${index}.jpg`)

        if (!file) {
            try {
                setImageLoading(true)
                let response = await createFileObject(img.link, accessToken)
                // Convert blob to File object
                if (response.data) {
                    file = new File([response.data], `image_${index}.jpg`, {
                        type: response.data.type || 'image/jpeg',
                        lastModified: new Date().getTime(),
                    })
                }
            } catch (error) {
                console.error('Error converting blob to file:', error)
                setImageLoading(false)
            }
        }

        if (file) {
            setFile(file)
            setImageLoading(false)
        } else {
            console.log('Failed to create file from both URLs')
        }
        // setTitle(image.title)
    }
    return (
        <div className={`outer_div_ ${file ? 'has-image' : ''}`}>
            <div>
                <div style={{ width: '99.9%', marginLeft: 'auto' }}>
                    <Search
                        className="search-bar"
                        placeholder="Search for images..."
                        enterButton="Search"
                        size="medium"
                        onSearch={handleSearch}
                        onChange={(e) => setQuery(e.target.value)}
                        value={query}
                    />
                </div>
                <div className="google-search-container">
                    <div className="content-wrapper">
                        {imageLoading && (
                            <Spin
                                size="large"
                                style={{
                                    position: 'absolute',
                                    zIndex: 10,
                                    left: '50%',
                                    right: '50%',
                                    top: '40%',
                                }}
                            />
                        )}
                        <div
                            className="image-grid-modal"
                            style={{ position: 'relative' }}
                        >
                            {!loading && images.length === 0 && (
                                <div className="loading">No Data</div>
                            )}
                            {loading ? (
                                <div className="loading">
                                    <Spin size="large" />
                                </div>
                            ) : (
                                <Row gutter={[10, 10]} className="image-grid">
                                    {images.map((image, index) => (
                                        <Col
                                            key={index}
                                            style={{
                                                margin: 4,
                                                opacity: imageLoading ? 0.3 : 1,
                                            }}
                                        >
                                            <div
                                                className="image-container"
                                                onClick={() =>
                                                    handleImageSelect(
                                                        image,
                                                        index
                                                    )
                                                }
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <img
                                                    src={image.link}
                                                    alt={image.title || ''}
                                                    className="grid-image"
                                                />
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            )}
                        </div>
                        {images.length > 0 && (
                            <div className="loadMoreBtn">
                                <Button
                                    type="link"
                                    onClick={handleLoadMore}
                                    loading={loading}
                                    className="load-imgs-button"
                                >
                                    Load more images
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div>
                {file && !imageLoading && (
                    <ShortNewsImageUpload
                        fromGoogleSearch={true}
                        setFile={setFile}
                        fileFromGoogleSearch={file}
                        accessToken={accessToken}
                        languageOption={languageOption}
                        setImagePath={setImagePath}
                        setIsImageAdding={setIsImageAdding}
                        isOriginal={isOriginal}
                        setIsOriginal={setIsOriginal}
                        isSensitive={isSensitive}
                        setIsSensitive={setIsSensitive}
                    />
                )}
            </div>
        </div>
    )
}

GoogleImageSearch.propTypes = {
    accessToken: PropTypes.string.isRequired,
    languageOption: PropTypes.number.isRequired,
    setImagePath: PropTypes.func.isRequired,
    imagePath: PropTypes.string,
    isOriginal: PropTypes.bool.isRequired,
    setIsOriginal: PropTypes.func.isRequired,
    isSensitive: PropTypes.bool.isRequired,
    setIsSensitive: PropTypes.func.isRequired,
    setIsImageAdding: PropTypes.func.isRequired,
}
export default GoogleImageSearch

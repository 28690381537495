import React, { useEffect, useState } from 'react'
import { Modal, Form, Input, Radio, message } from 'antd'
import { createAdvertiser, updateAdvertiser } from '../../../AadhanApi'

const AddAdvertiserModal = ({
    isModalOpen,
    handleCancel,
    token,
    editingAdvertiser,
    onSuccess,
    fromstagetable,
}) => {
    const [form] = Form.useForm()
    const [isEditing, setIsEditing] = useState(false)

    useEffect(() => {
        if (editingAdvertiser) {
            form.setFieldsValue({
                name: editingAdvertiser.advertiser_name,
                type: editingAdvertiser.advertiser_type,
                mobileNumber: editingAdvertiser.phone_number,
                email: editingAdvertiser.email_id,
                address: editingAdvertiser.address,
                contactName: editingAdvertiser.contact_person_name,
                gst: editingAdvertiser.gst_number,
            })
            setIsEditing(true)
        } else {
            form.resetFields()
            setIsEditing(false)
        }
    }, [editingAdvertiser, isModalOpen])

    const gstValidationRule = {
        validator: (_, value) => {
            if (
                !value ||
                /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}[Z]{1}[A-Z0-9]{1}$/.test(
                    value
                )
            ) {
                return Promise.resolve()
            }
            return Promise.reject(
                new Error(
                    'Please enter a valid GSTIN (GST Identification Number)'
                )
            )
        },
    }

    const handleSave = async () => {
        try {
            await form.validateFields()

            const values = form.getFieldValue()

            const request = {
                advertiser_name: values.name,
                advertiser_type: values.type,
                phone_number: values.mobileNumber,
                email_id: values.email,
                address: values.address,
                contact_person_name: values.contactName,
                gst_number: values.gst,

                created_at: isEditing
                    ? editingAdvertiser.created_at
                    : undefined,
            }

            if (isEditing && !fromstagetable) {
                await updateAdvertiser(
                    request,
                    editingAdvertiser.advertiser_id,
                    token
                )
                message.success('Advertiser updated successfully')
            } else {
                await createAdvertiser(request, token)
                message.success('Advertiser added successfully')
            }
            form.resetFields()
            onSuccess()
            handleCancel()
        } catch (error) {
            if (error.errorFields) {
                return
            }
            message.error(
                error.response?.data?.message ||
                    (isEditing
                        ? 'Failed to update advertiser'
                        : 'Failed to add advertiser')
            )
        }
    }

    return (
        <Modal
            title={
                <h3 style={{ fontWeight: '500' }}>
                    {isEditing ? 'Edit Advertiser' : 'Add Advertiser'}
                </h3>
            }
            style={{ fontWeight: 550 }}
            open={isModalOpen}
            onCancel={handleCancel}
            width={500}
            centered
            okText={isEditing ? 'Update' : 'Save'}
            onOk={handleSave}
            okButtonProps={{
                style: {
                    backgroundColor: '#1890ff',
                    borderColor: '#1890ff',
                },
            }}
        >
            <Form form={form} layout="vertical" name="add_advertiser_form">
                <hr></hr>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1.7vh',
                    }}
                >
                    <div>
                        <Form.Item
                            name="name"
                            label="Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input advertiser name',
                                },
                            ]}
                        >
                            <Input placeholder="Enter advertiser name" />
                        </Form.Item>
                    </div>

                    <div>
                        <Form.Item
                            name="type"
                            label="Advertiser Type"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select advertiser type',
                                },
                            ]}
                        >
                            <Radio.Group style={{ fontWeight: 450 }}>
                                <Radio value="agency">Agency</Radio>
                                <Radio value="direct">Direct</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>

                    <div>
                        <Form.Item
                            name="contactName"
                            label="Contact Person Name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input contact person name',
                                },
                            ]}
                        >
                            <Input placeholder="Enter contact person name" />
                        </Form.Item>
                    </div>

                    <div>
                        <Form.Item
                            name="mobileNumber"
                            label="Mobile Number"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input mobile number',
                                },
                                {
                                    pattern: /^[0-9]{10}$/,
                                    message:
                                        'Please enter a valid 10-digit mobile number',
                                },
                            ]}
                        >
                            <Input
                                placeholder="Enter 10-digit mobile number"
                                type="tel"
                            />
                        </Form.Item>
                    </div>

                    <div>
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input email address',
                                },
                                {
                                    type: 'email',
                                    message:
                                        'Please enter a valid email address',
                                },
                            ]}
                        >
                            <Input
                                placeholder="Enter email address"
                                type="email"
                            />
                        </Form.Item>
                    </div>

                    <div>
                        <Form.Item
                            name="gst"
                            label="GSTIN"
                            rules={[gstValidationRule]}
                            tooltip="Format: StateCode+PAN+Entity+Code"
                        >
                            <Input placeholder="e.g., 22AAAAA0000A1Z5" />
                        </Form.Item>
                    </div>

                    <div>
                        <Form.Item
                            name="address"
                            label="Address"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input address',
                                },
                            ]}
                        >
                            <Input.TextArea
                                placeholder="Enter full address"
                                rows={2}
                            />
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </Modal>
    )
}
export default AddAdvertiserModal

import React, { useEffect, useState } from 'react'
import { CloseSquareFilled } from '@ant-design/icons'
import { useNavigate } from 'react-router'
import { Button, Form, Select, Input, message } from 'antd'
import { createLead } from '../../AadhanApi'
import AddAdvertiserModal from '../Master/Advertisers/AddAdvertiserModal'

const formItemLayout = { labelCol: { span: 6 }, wrapperCol: { span: 16 } }
const { Option } = Select

const Lead = () => {
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const [token, setToken] = useState('')
    const [isLost, setIsLost] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isAdvertiserModalOpen, setIsAdvertiserModalOpen] = useState(false)
    const [advertiserData, setAdvertiserData] = useState(null)

    const onStatuschange = (value) => {
        setIsLost(value === 'lost')
    }

    useEffect(() => {
        const authToken = localStorage.getItem('ACCESS_TOKEN')
        if (!authToken) {
            message.error('Authentication token not found')
            navigate('/login')
            return
        }
        setToken(authToken)
    }, [navigate])

    const handleAdvertiserModalSuccess = () => {
        setIsAdvertiserModalOpen(false)
        setAdvertiserData(null)
        form.resetFields()
        navigate('/home/stagetable')
    }

    const prepareAdvertiserData = (values) => {
        return {
            advertiser_name: values.name,
            contact_person_name: values.contact_person,
            phone_number: values.mobile_num,
            email_id: values.email,
            address: values.address,
            advertiser_type: '', // Will be filled in modal
            gst_number: '', // Will be filled in modal
            created_at: new Date().toISOString(),
        }
    }

    const handleSubmit = async (values) => {
        try {
            setIsSubmitting(true)

            const formData = {
                name: values.name,
                contact_person: values.contact_person,
                mobile_num: String(values.mobile_num),
                email: values.email,
                address: values.address,
                lead_type: values.lead_type.toLowerCase(),
                lead_owner: values.lead_owner || null,
                lead_generatedby: values.lead_generatedby || null,
                lead_status: values.lead_status.toLowerCase(),
            }

            if (values.lead_status === 'lost' && values.reason) {
                formData.reason = values.reason
            }

            const response = await createLead(formData, token)

            if (response?.data) {
                message.success('Lead Added Successfully')

                // If lead status is won, open advertiser modal
                if (values.lead_status.toLowerCase() === 'won') {
                    const advertiserFormData = prepareAdvertiserData(values)
                    setAdvertiserData(advertiserFormData)
                    setIsAdvertiserModalOpen(true)
                } else {
                    form.resetFields()
                    navigate('/home/stagetable', {
                        state: { leadData: response.data },
                    })
                }
            }
        } catch (error) {
            console.error('Submission error:', error)
            message.error(
                error.response?.data?.detail?.[0]?.msg ||
                    'Failed to add lead. Please try again.'
            )
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <div className="lead-container" style={{ padding: '1rem' }}>
            <div className="main-container">
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <h2 className="headig">Lead Details</h2>
                    <Button
                        onClick={() => navigate(-1)}
                        ghost
                        style={{ border: 'none' }}
                        type="primary"
                        icon={
                            <CloseSquareFilled
                                style={{
                                    color: 'LightCoral',
                                    fontSize: '1.8rem',
                                }}
                            />
                        }
                    />
                </div>
                <hr />
                <Form
                    {...formItemLayout}
                    form={form}
                    onFinish={handleSubmit}
                    style={{ maxWidth: 600, margin: '0 auto' }}
                >
                    {/* Existing form items remain the same */}
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter the Name!',
                            },
                        ]}
                    >
                        <Input placeholder="Enter the Name!" />
                    </Form.Item>
                    <Form.Item
                        label="Contact Person"
                        name="contact_person"
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter the Contact Person!',
                            },
                        ]}
                    >
                        <Input placeholder="Enter the Contact person" />
                    </Form.Item>
                    <Form.Item
                        name="mobile_num"
                        label="Mobile Number"
                        rules={[
                            {
                                required: true,
                                message: 'Please input mobile number',
                            },
                            {
                                pattern: /^[0-9]{10}$/,
                                message:
                                    'Please enter a valid 10-digit mobile number',
                            },
                        ]}
                    >
                        <Input
                            placeholder="Enter 10-digit mobile number"
                            type="tel"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please Enter the Email!',
                            },
                            {
                                type: 'email',
                                message: 'Please enter a valid email address',
                            },
                        ]}
                    >
                        <Input placeholder="Enter email" type="email" />
                    </Form.Item>
                    <Form.Item
                        name="address"
                        label="Address"
                        rules={[
                            { required: true, message: 'Please input address' },
                        ]}
                    >
                        <Input.TextArea
                            placeholder="Enter full address"
                            rows={2}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Lead Type"
                        name="lead_type"
                        rules={[
                            {
                                required: true,
                                message: 'Please select Lead Type!',
                            },
                        ]}
                    >
                        <Select placeholder="Select Lead Type">
                            <Option value="Inbound">Inbound</Option>
                            <Option value="Outbound">Outbound</Option>
                            <Option value="Referral">Referral</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Lead Owner"
                        name="lead_owner"
                        rules={[{ message: 'Please select an Lead Owner!' }]}
                    >
                        <Select
                            showSearch
                            placeholder="Select Lead Owner"
                            filterOption={(input, option) =>
                                (option?.children ?? '')
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            optionFilterProp="children"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Lead Generated By"
                        name="lead_generatedby"
                        rules={[
                            { message: 'Please select an Lead Generated By!' },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select Lead Generated By"
                            filterOption={(input, option) =>
                                (option?.children ?? '')
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            optionFilterProp="children"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Select Lead Status"
                        name="lead_status"
                        rules={[
                            {
                                required: true,
                                message: 'Please select a lead status!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select Lead Status"
                            onChange={onStatuschange}
                        >
                            <Option value="fresh">Fresh</Option>
                            <Option value="contacted">Contacted</Option>
                            <Option value="followup">Follow-up</Option>
                            <Option value="negotiation">Negotiation</Option>
                            <Option value="lost">Lost</Option>
                            <Option value="won">Won</Option>
                        </Select>
                    </Form.Item>
                    {isLost && (
                        <Form.Item
                            label="Reason for Loss"
                            name="reason"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Please provide the reason for the loss!',
                                },
                            ]}
                        >
                            <Input.TextArea
                                placeholder="Enter the reason for the loss"
                                rows={4}
                            />
                        </Form.Item>
                    )}
                    <Form.Item
                        wrapperCol={{
                            xs: { span: 24 },
                            sm: { offset: 8, span: 16 },
                        }}
                    >
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={isSubmitting}
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>

                <AddAdvertiserModal
                    isModalOpen={isAdvertiserModalOpen}
                    handleCancel={() => {
                        setIsAdvertiserModalOpen(false)
                        setAdvertiserData(null)
                    }}
                    token={token}
                    editingAdvertiser={advertiserData}
                    onSuccess={handleAdvertiserModalSuccess}
                    fromstagetable={true}
                />
            </div>
        </div>
    )
}

export default Lead

import React from 'react'
import { breakingNewsImages } from '../BreakingNewsImage'
import './AddShortNewsForm.scss'
import { message } from 'antd'
import { useLocation } from 'react-router'

export const BreakingNews = ({
    imagePath,
    language_id,
    setImagePath,
    videoPath,
    test,
    isOriginal,
    setIsImageAdding,
    setIsOriginal,
    isSensitive,
    images,
    setIsSensitive,
}) => {
    const image = breakingNewsImages
    const location = useLocation()
    function handleSelectImage(url) {
        if (
            ((videoPath?.stream_url || videoPath?.yt_url) &&
                images?.length >= 1) ||
            (images?.length >= 1 &&
                location?.pathname?.includes('home/reporter-dashboard'))
        ) {
            message.error('Image limit exceeded. Maximum allowed is 1.')
            return
        } else if (images?.length >= 5) {
            message.error('Image limit exceeded. Maximum allowed is 5.')
            return
        }
        setImagePath(url)
        message.success('Image added')
    }
    return (
        <div
            style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: 10,
                justifyContent: 'flex-start',
            }}
        >
            {image?.[language_id]?.map((e) => (
                <img
                    className="breaking_news_hover"
                    alt="image"
                    src={`${e}?v=${new Date().getTime()}`}
                    style={{
                        weight: 150,
                        height: 150,
                        borderRadius: 5,
                        overflow: 'hidden',
                        cursor: 'pointer',
                    }}
                    onClick={() => handleSelectImage(e)}
                />
            ))}
        </div>
    )
}

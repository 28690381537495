import { FontSizeOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Form, Input, Select, Upload, message } from 'antd'
import { useEffect, useRef, useState } from 'react'
import './AddDevotionalVideoForm.scss'
import {
    isSubmitAndDraft
} from '../../App.constants'
import { getImageUrl, getVideoUrl } from '../../../AadhanApi'
import { useSelector } from 'react-redux'
import { daysOptions } from './AddDevotionalVideoConstant'
import Speaker from '../../Speaker'
const AddDevotionalVideoForm = ({
    initialFormData,
    languageData,
    onSave,
    setVideoPath,
    accessToken,
    setImagePath,
    isLoading,
    setIsLoading,
}) => {
    const userPermissions = useSelector((state) => state.app.userPermissions)
    const [form] = Form.useForm()
    const loading = false
    const [thumbnailImageLoading, setThumbnailImageLoading] = useState()
    const [titleError, setTitleError] = useState()
    const [languageOption, setLanguageOption] = useState(
        languageData?.[0]?.language_id
    )
    const inputVideoRef = useRef(null)
    const { Option } = Select
    const [isVideoUploading, setIsVideoUploading] = useState(false)
    const [imagePathUrl, setImagePathUrl] = useState(
        initialFormData?.thumbnailImage
    )
    const [messageApi, contextHolder] = message.useMessage()
    const [selectedDays, setSelectedDays] = useState([])
    const [title, setTitle] = useState('')
    useEffect(() => {
        form.setFieldsValue(initialFormData)
        setImagePathUrl(form.getFieldValue('thumbnailImage'))
        setTitle(initialFormData?.title)
        handleLanguageChange(initialFormData.language)
        handleTitleChange(initialFormData?.title)
        handleEnglishTitleChange(initialFormData?.englishTitle)
    }, [form, initialFormData])

    const handleLanguageChange = (id) => {
        setLanguageOption(id)
    }
    const handleChange = (selectedValues) => {
        setSelectedDays(selectedValues)
    }

    const getImagePath = async (path) => {
        setIsLoading(true)
        setThumbnailImageLoading(true)
        try {
            const pathUriArr = await getImageUrl(
                path?.originFileObj,
                'shortvideo',
                accessToken,
                languageOption
            )
            setIsLoading(false)
            if (pathUriArr?.data) {
                setImagePath(
                    pathUriArr?.data
                )
                setImagePathUrl(
                    pathUriArr?.data
                )
                setThumbnailImageLoading(false)
            }
        } catch (err) {
            setIsLoading(false)
            setThumbnailImageLoading(false)
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to upload image',
                duration: 5,
            })
        }
    }

    const onFinish = (values) => {
        handleSave(values)
    }

    const onFinishFailed = (errorInfo) => {
        errorInfo?.errorFields?.length &&
            errorInfo.errorFields.forEach((ele) => {
                if (ele?.name === 'title' || ele?.name === 'video') {
                    window.scrollTo(0, 0)
                }
            })
        console.log('Failed:', errorInfo)
    }

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    )
    

    const getVideoPath = async (path) => {
        setIsLoading(true)
        setIsVideoUploading(true)
        try {
            const pathUri = await getVideoUrl(path,undefined, accessToken)
            if (pathUri?.data) {
                setIsLoading(false)
                setVideoPath(pathUri?.data)
                setIsVideoUploading(false)
                form.setFieldValue('video', pathUri?.data)
            }
        } catch (err) {
            setIsLoading(false)
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to upload Video, Please try again',
                duration: 5,
            })
            setIsVideoUploading(false)
            setVideoPath('')
        }
    }

    const handleTitleChange = (value) => {
        form.setFieldValue('title', value)
        setTitle(value)
        setTitleError(value?.length > 75)
    }

    const handleEnglishTitleChange = (value) => {
        form.setFieldValue('englishTitle', value)
    }
    
    const handleSave = async (type) => {
        try {
            const validation = await form.validateFields([
                'title',
                'video',
                'language',
                'thumbnailImage',
                'days',
            
                
            ])
            if (validation?.errorFields?.length > 0) {
                return
            }
        } catch (e) {
            e?.errorFields?.length &&
                e.errorFields.forEach((ele) => {
                    if (ele?.name == 'title' || ele?.name == 'video') {
                        window.scrollTo(0, 0)
                    }
                })
            return
        }
        onSave({ ...form.getFieldsValue() }, type)
    }
    return (
        <div id="form" style={{ width: '100%' }}>
            {contextHolder}
            <Form
                form={form}
                name="basic"
                colon={false}
                scrollToFirstError
                labelWrap
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 18 }}
                initialValues={initialFormData}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Language"
                    name="language"
                    rules={[
                        { required: true, message: 'Please select language' },
                    ]}
                >
                    <Select onChange={handleLanguageChange}>
                        {languageData?.map((option) => (
                            <Select.Option
                                key={option?.language_id}
                                value={option?.language_id}
                            >
                                {option?.language_name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Title"
                    name="title"
                    rules={[{ required: true, message: 'please input title' }]}
                >
                    <Input.TextArea
                        className={titleError ? 'error' : ''}
                        maxLength={75}
                        value={title}
                        onChange={(e) => handleTitleChange(e.target.value)}
                        placeholder="Title of the article (max 75 chars) in regional language (ex:tamil,telugu)"
                    />
                    <div style={{display:"flex",justifyContent:"space-between"}}>
                    <span className="description-subtext">
                        <span
                            style={{
                                color: title?.length > 75 ? 'red' : null,
                            }}
                        >
                            {' '}
                            {75 - (title?.length ?? 0)} characters
                        </span>
                    </span>
                    <span
                            style={{
                                display: 'flex',
                                
                                fontSize: '1rem',
                                fontWeight: '500',
                                alignItems: 'center',
                            }}
                        >
                        
                            <Button type='primary' ghost={true} className='format-button' onClick={() => {
                                   
                                    if (title) {
                                        let cleanedValue = title
                                        ?.replace(/\s+/g, ' ') // Replace multiple spaces with a single space
                                        .replace(
                                            /(?<!['"“‘])\s*([,.!?;:])(?!\d|['"”’])\s*(?=\S)/g,
                                            '$1 '
                                        ) // Add space after punctuation if not preceded by quote and not followed by a digit or quote
                                        .replace(/(?<!['"“‘])\s*([,.!?;:])(?!\d|['"”’])$/, '$1') // Remove space before punctuation at the end if not preceded by quote and not followed by a digit or quote
                                        .replace(/([₹$])\s*(\d)/g, '$1$2') // Remove space between currency symbol and number
                                        .replace(/(\d)\s+(\d)/g, '$1$2') // Remove space between digits
                                        .replace(/(?<!\.)\.\s*(?=\.)/g, '.') // Ensure continuous dots remain intact
                                        .replace(/(\.{2,})(?=\S)/g, '$1') // Leave continuous dots intact
                                        .trim(); // Trim leading and trailing spaces

                                        cleanedValue = cleanedValue.trimStart()
                                        if (/[.!?]$/.test(cleanedValue)) {
                                            cleanedValue =
                                                cleanedValue.trimEnd()
                                        }

                                        setTitle(cleanedValue)
                                       
                                        form.setFieldValue(
                                            'title',
                                            cleanedValue
                                        )
                                        setTitleError(cleanedValue.length > 75)
                                    } else {
                                        setTitle('')
                                        form.setFieldValue('title', '')
                                       
                                        setTitleError(false)
                                    }
                                }} >
                            <FontSizeOutlined/>
                            </Button>
                            
                        <span style={{marginTop:"4px",marginLeft:"5px"}}><Speaker text={title} language={languageOption===1 ? "en-US" : languageOption===4 ? "ta-IN" : "hi-IN"} /></span>
                        </span>
   
                        
                        </div>
                        
                </Form.Item>
               
                <Form.Item
                    name="video"
                    label="Video"
                    rules={[{ required: true, message: 'please input Video' }]}
                >
                    <div style={{ display: 'flex', gap: '5px' }}>
                        <input
                            accept="video/mp4"
                            ref={inputVideoRef}
                            onChange={(e) => {
                                getVideoPath(e.target.files?.[0])
                            }}
                            type="file"
                        />
                        <div>{isVideoUploading && <LoadingOutlined />}</div>
                    </div>
                </Form.Item>
                <Form.Item
                    label="Days"
                    name="days"
                    rules={[
                        { required: false, message: 'please select the day' },
                    ]}
                >
                    <Select
                        mode="multiple"
                        allowClear
                        style={{
                            width: '100%',
                        }}
                        placeholder="Please select"
                        value={selectedDays}
                        onChange={handleChange}
                    >
                        {daysOptions.map((day) => (
                            <Option key={day.value} value={day.value}>
                                {day.label}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Thumbnail Image"
                    name="thumbnailImage"
                    rules={[
                        { required: true, message: 'please select an image' },
                    ]}
                >
                    <Upload
                        name="avatar"
                        style={{ width: '100%' }}
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        accept="image/jpeg,image/png"
                        onChange={(e) => {
                            getImagePath(e?.file)
                        }}
                    >
                        {imagePathUrl ? (
                            <img
                                src={imagePathUrl}
                                alt="avatar"
                                style={{ width: '100%', height: '100%' }}
                            />
                        ) : thumbnailImageLoading === true ? (
                            <LoadingOutlined />
                        ) : (
                            uploadButton
                        )}
                    </Upload>
                </Form.Item>

                <Form.Item wrapperCol={{ span: 48 }}>
                    {isSubmitAndDraft(
                        userPermissions,
                        'Content',
                        'Devotional Video'
                    ) && (
                        <Button
                            className="bg-color width-150 submit-buttons"
                            type="primary"
                            onClick={() => handleSave('submit')}
                            disabled={isLoading}
                        >
                            Submit
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </div>
    )
}
export default AddDevotionalVideoForm

import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Preview from './../../PreviewCorousel/Preview/Preview'
import {
    LeftCircleFilled,
    RightCircleFilled,
    EditOutlined,
} from '@ant-design/icons'
import './../../PreviewCorousel/PreviewCorousel.scss'
import { Modal } from 'antd'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router'
import { changePopup } from '../../../store/slice/AppSlice'

const SnippetsPreviewCorousel = ({ data, contentId }) => {
    const index = data.findIndex((obj) => obj.content_id === contentId)
    const [isModalOpen, setIsModalOpen] = useState(true)
    const [currentDataIndex, setCurrentDataIndex] = useState(index)
    const [contentData, setContentData] = useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        const modalMask = document.querySelector('.ant-modal-mask')
        const modalWrap = document.querySelector('.ant-modal-wrap')
        if (modalMask) {
            modalMask.style.display = 'none'
        }
        if (modalWrap) {
            modalWrap.style.width = 'calc(100% - 256px)'
            modalWrap.style.maxWidth = 'calc(100% - 256px)'
            modalWrap.style.marginLeft = '256px'
        }
    }, [])

    useEffect(() => {
        //  using the data directly
        setContentData(data[currentDataIndex])
    }, [currentDataIndex, data])

    const handleNext = () => {
        if (currentDataIndex === data?.length - 1) return
        setCurrentDataIndex((prevIndex) => (prevIndex + 1) % data.length)
    }

    const handlePrevious = () => {
        if (currentDataIndex === 0) return
        setCurrentDataIndex((prevIndex) =>
            prevIndex === 0 ? data.length - 1 : prevIndex - 1
        )
    }

    const closePopup = () => {
        setIsModalOpen(false)
        dispatch(changePopup(false))
    }

    const onEditClicked = () => {
        navigate('/home/addsnippet-form', {
            state: {
                editingSnippet: data[currentDataIndex],
                selectedLanguage: data[currentDataIndex]?.language_id,
                selectedCategory: data[currentDataIndex]?.snippet_category_id,
            },
        })
        dispatch(changePopup(false))
    }

    return (
        <Modal
            open={isModalOpen}
            width={'60%'}
            onCancel={() => closePopup()}
            className="carousel-preview-block"
        >
            <div className="preview-controls">
                <div className="back-btn" onClick={() => closePopup()}>
                    Back
                </div>
                <div className="edit-btn" onClick={() => onEditClicked()}>
                    <EditOutlined />
                    Edit
                </div>
            </div>
            <div className="posted-details">
                <div>
                    <div className="carousel-preview-header">Preview</div>
                    <div>Posted By: {contentData?.uploaded_by}</div>
                </div>
                <div className="preview-corousel-container">
                    <LeftCircleFilled
                        style={{
                            fontSize: '50px',
                            cursor:
                                currentDataIndex > 0
                                    ? 'pointer'
                                    : 'not-allowed',
                        }}
                        onClick={handlePrevious}
                    ></LeftCircleFilled>
                    <Preview
                        title={contentData?.title}
                        description={contentData?.description}
                        cardImages={contentData?.image_urls}
                        image={contentData?.image_url}
                        weburl={contentData?.web_url}
                    />
                    <RightCircleFilled
                        style={{
                            fontSize: '50px',
                            cursor:
                                currentDataIndex === data?.length - 1
                                    ? 'not-allowed'
                                    : 'pointer',
                        }}
                        onClick={handleNext}
                    ></RightCircleFilled>
                </div>
                <div style={{ marginTop: '14px' }}>
                    <div>
                        Posted:{' '}
                        {dayjs(contentData?.uploaded_date).format(
                            'DD-MMM-YYYY h:mm A'
                        )}
                    </div>
                    <div>Category: {contentData?.snippet_category_name}</div>
                </div>
            </div>
        </Modal>
    )
}

export default SnippetsPreviewCorousel

// Axios Response Interceptor to catch signature errors and prompt re-login
import { message } from 'antd'
import axios from 'axios'
export function setupNetworkMonitor(navigate) {
    console.log('Network monitor initialized for Axios')
    axios.interceptors.response.use(
        (response) => {
            console.log('Intercepted Axios Response : API call successful')
            return response
        },
        (error) => {
            console.error('Axios Response Error:', {
                url: error.config?.url,
                status: error.response?.status,
                statusText: error.response?.statusText,
                message: error.message,
                data: error.response?.data,
            })
            if (error.response) {
                const errorDetail = error.response?.data?.detail
                if (
                    errorDetail?.msg === 'token expired. please login!' ||
                    errorDetail?.msg ===
                        'invalid token, please send a valid token in Authorization header!'
                ) {
                    message.error(
                        'Your session has expired or the token is invalid. Please log in again.'
                    )
                    setTimeout(() => {
                        navigate('/')
                    }, 2000)
                }
            }
            return Promise.reject(error)
        }
    )
}

import React, { useEffect, useRef, useState } from 'react'
import CropperModal from './cropperModal'
import ImageCompressor from './ImageCompressor'
import { useLocation } from 'react-router'
function ImageUploader({
    getImagePath,
    onChange,
    value,
    images,
    isImageUploading,
    inHouseAd,
    setFiles,
    modal,
    act,
    setAct,
    fileFromGoogleSearch,
    fromGoogleSearch,
    setIsImageAdding,
    videoPath,
}) {
    // act indicates image upload or processing action.
    const [isCompress, setIsCompress] = useState('false')
    const [file, setFile] = useState(null)
    const [isValidFile, setisValidFile] = useState(false)
    const inputRef = useRef(null)
    const location = useLocation()
    useEffect(()=>{
        if(inputRef.current){
            inputRef.current.value=null
        }
    },[act])
    const onFileInputChange = (e) => {
        setAct?.(true)
        if (e?.target?.files && e?.target?.files?.length > 0) {
            const fileInput = e?.target?.files[0]
            const maxSize = inHouseAd ? 1000 : 100
            if (fileInput?.size / 1024 <= maxSize) {
                setFile(e?.target?.files[0])
                setisValidFile(true)
                setIsCompress(false)
            } else {
                setisValidFile(false)
                setFile(e?.target?.files[0])
                setIsCompress(true)
            }
        }
    }

    useEffect(() => {
        if (
            location.pathname.includes('addsnippet-form') |
            location.pathname.includes('writer-dashboard') |
            location.pathname.includes('reporter-dashboard') |
            location.pathname.includes('shortNews')
        ) {
            const maxSize = 100
            if (fileFromGoogleSearch instanceof Blob) {
                if (fileFromGoogleSearch?.size / 1024 <= maxSize) {
                    setisValidFile(true)
                    setIsCompress(false)
                    setFile(fileFromGoogleSearch)
                } else {
                    setisValidFile(false)
                    setIsCompress(true)
                    setFile(fileFromGoogleSearch)
                }
            } else {
                console.log('fileFromGoogleSearch is not a valid Blob/File.')
            }
        }
    }, [fileFromGoogleSearch])
    return (
        <div>
            <div style={{ display: 'flex' }}>
                {((!videoPath?.stream_url && !fromGoogleSearch && images?.length<5) | images===undefined) ? (
                    <div>
                        <div>
                            <input
                                onClick={() => {
                                    inputRef.current.value = null
                                }}
                                ref={inputRef}
                                accept="image/jpeg,image/png"
                                type="file"
                                placeholder="happy"
                                onChange={(e) => {
                                    onFileInputChange(e)
                                }}
                            />
                        </div>
                    </div>
                ):''}
                {images>=5 && 
                <div>Image </div>
                }
                {isCompress === true && !isValidFile ? (
                    <ImageCompressor
                        file={file}
                        modal={modal}
                        onConfirm={(ImageCompressor) => {
                            getImagePath(ImageCompressor)
                            if (typeof setAct === 'function') {
                                setAct(false)
                            }
                            
                        }}
                        onDiscard={() => {
                            // inputRef.current.value = null
                            if (!fromGoogleSearch && inputRef.current) {
                                inputRef.current.value = null
                            }
                            if (typeof setAct === 'function') {
                                setAct(false)
                            }
                            setFile()
                            if (typeof setFiles === 'function') {
                                setFiles()
                            }
                        }}
                        onCompleted={() => setFile(null)}
                    />
                ) : (
                    <CropperModal
                        modal={modal}
                        file={file}
                        onConfirm={(croppedFile) => {
                            if (typeof setAct === 'function') {
                                setAct(false)
                            }
                            getImagePath(croppedFile)
                           
                        }}
                        onDiscard={() => {
                            // inputRef.current.value = null
                            if (!fromGoogleSearch && inputRef.current) {
                                inputRef.current.value = null
                            }
                            if (typeof setAct === 'function') {
                                setAct(false)
                            }
                            setFile()
                            if (typeof setFiles === 'function') {
                                setFiles()
                            }
                        }}
                        onCompleted={() => setFile(null)}
                    />
                )}

                {/* <a href="https://squoosh.app/" target="_blank">
                Open Squoosh to Format Image
            </a> */}
            </div>
            <div>
                {!location.pathname.includes('shortNews') &&
                    !location.pathname.includes('addsnippet-form') &&
                    !location.pathname.includes('writer-dashboard') &&
                    !location.pathname.includes('reporter-dashboard') && (
                        <div>
                            <div
                                style={{
                                    color: '#199be9',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <div>
                                    {' '}
                                    <span>Image size 540</span>{' '}
                                    <span style={{ padding: '0 2px' }}>x</span>{' '}
                                    <span>525</span>
                                </div>
                            </div>
                            <div
                                style={{
                                    color: '#199be9',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <span>Max size 100kb</span>
                            </div>
                        </div>
                    )}
            </div>
        </div>
    )
}

export default ImageUploader

import React, { useEffect, useState } from 'react'
import { Button, Upload, message, Form, Radio, Card, Space, Spin } from 'antd'
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { getImageUrl } from '../../../AadhanApi'

const Artwork = ({
    songData,
    setSongData,
    currentForm,
    setCurrentForm,
    languageData,
}) => {
    let temp = []
    songData?.map((song) => {
        if (!temp.includes(song?.image_url)) {
            temp.push(song?.image_url)
        }
    })
    function areURLsUniform(array) {
        if (array.length === 0) return true // Return true for an empty array

        const firstUrl = array[0]?.image_url || null

        const allHaveSameUrl = array.every(
            (item) => item.image_url === firstUrl
        )
        const allEmpty = array.every((item) => !item.image_url)

        return allHaveSameUrl || allEmpty
    }
    const [uploadOption, setUploadOption] = useState(
        areURLsUniform(songData) ? 'same' : 'specific'
    )
    const [art, setArt] = useState(songData)
    const [loading, setLoading] = useState(false)
    const accessToken = useSelector((state) => state.app.accessToken)
    useEffect(() => {
        if (areURLsUniform(songData)) {
            setArt(songData?.map((e) => ({ ...e, image_url: temp[0] })))
        } else {
            setArt(songData)
        }
        setUploadOption((pre) =>
            areURLsUniform(songData) ? 'same' : 'specific'
        )
    }, [songData, currentForm])
    const handleUpload = async (file, languageOption) => {
        setLoading(true)
        try {
            const pathUri = await getImageUrl(
                file,
                'devotions/songs',
                accessToken,
                0
            )
            if (languageOption === 'all') {
                setArt((pre) =>
                    pre?.map((e) => {
                        return { ...e, image_url: pathUri?.data }
                    })
                )
            } else {
                setArt((pre) =>
                    pre?.map((e) => {
                        if (e.language_id === languageOption) {
                            return { ...e, image_url: pathUri?.data }
                        } else {
                            return e
                        }
                    })
                )
            }
            message.success('Image uploaded successfully!')
        } catch (error) {
            message.error('Failed to upload image.')
        } finally {
            setLoading(false)
        }
        return false
    }

    const handleRemoveImage = (languageId) => {
        if (languageId === 'all') {
            setArt((pre) =>
                pre?.map((e) => {
                    return { ...e, image_url: '' }
                })
            )
        } else {
            setArt((prev) =>
                prev?.map((e) => {
                    if (e.language_id === languageId) {
                        return { ...e, image_url: '' }
                    }
                    return e
                })
            )
        }
        message.success('Image removed successfully!')
    }

    return (
        <div style={{ height: '85%', overflowY: 'auto' }}>
            <Card
                title="Add Thumbnail Images for the Song"
                style={{
                    maxWidth: 800,
                    margin: 'auto',
                    borderRadius: '12px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    padding: '20px',
                }}
            >
                <Form layout="vertical">
                    {/* Upload Option */}
                    <Form.Item
                        name="uploadOption"
                        label="Select Upload Option"
                        initialValue={uploadOption}
                    >
                        <Radio.Group
                            value={uploadOption}
                            onChange={(e) => {
                                setUploadOption(e.target.value)
                                setArt((pre) =>
                                    pre?.map((e) => {
                                        return { ...e, image_url: '' }
                                    })
                                )
                            }}
                        >
                            <Radio value="same">
                                Same Image for All Languages
                            </Radio>
                            <Radio value="specific">
                                Language-Specific Images
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                </Form>

                {uploadOption === 'specific' && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                        }}
                    >
                        {art?.map((e) => (
                            <div
                                key={e.language_id}
                                style={{
                                    border: '1px dashed lightgray',
                                    padding: '15px',
                                    borderRadius: '10px',
                                    background: '#fff',
                                }}
                            >
                                <div
                                    style={{
                                        fontWeight: 'bold',
                                        marginBottom: '10px',
                                        fontSize: '14px',
                                    }}
                                >
                                    {
                                        languageData?.filter(
                                            (l) =>
                                                l.language_id === e?.language_id
                                        )?.[0]?.language_name
                                    }
                                </div>

                                {loading ? (
                                    <div
                                        style={{
                                            marginTop: '20px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Spin size="large" />
                                    </div>
                                ) : e?.image_url ? (
                                    <div
                                        style={{
                                            marginTop: '20px',
                                            width: 200,
                                            position: 'relative',
                                        }}
                                    >
                                        <img
                                            src={e?.image_url}
                                            alt="Uploaded"
                                            style={{
                                                width: 200,
                                                height: 200,
                                                borderRadius: '8px',
                                            }}
                                        />
                                        <Button
                                            onClick={() =>
                                                handleRemoveImage(e.language_id)
                                            }
                                            icon={<DeleteOutlined />}
                                            style={{
                                                position: 'absolute',
                                                right: 0,
                                                backgroundColor: '#ff4d4f',
                                                color: '#fff',
                                                borderColor: '#ff4d4f',
                                            }}
                                        ></Button>
                                    </div>
                                ) : (
                                    <Upload
                                        accept="image/*"
                                        beforeUpload={(event) =>
                                            handleUpload(event, e.language_id)
                                        }
                                        showUploadList={false}
                                    >
                                        <Button
                                            icon={<UploadOutlined />}
                                            style={{
                                                backgroundColor: '#1890ff',
                                                color: '#fff',
                                                borderColor: '#1890ff',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            Upload Image
                                        </Button>
                                    </Upload>
                                )}
                            </div>
                        ))}
                    </div>
                )}

                {uploadOption === 'same' && (
                    <div>
                        {loading ? (
                            <div
                                style={{
                                    marginTop: '20px',
                                    textAlign: 'center',
                                }}
                            >
                                <Spin size="large" />
                            </div>
                        ) : art?.[0]?.image_url ? (
                            <div
                                style={{
                                    marginTop: '20px',
                                    width: 200,
                                    position: 'relative',
                                }}
                            >
                                <img
                                    src={art?.[0]?.image_url}
                                    alt="Uploaded"
                                    style={{
                                        width: 200,
                                        height: 200,
                                        borderRadius: '8px',
                                    }}
                                />
                                <Button
                                    onClick={() => handleRemoveImage('all')}
                                    icon={<DeleteOutlined />}
                                    style={{
                                        position: 'absolute',
                                        right: 0,
                                        backgroundColor: '#ff4d4f',
                                        color: '#fff',
                                        borderColor: '#ff4d4f',
                                    }}
                                ></Button>
                            </div>
                        ) : (
                            <Upload
                                beforeUpload={(e) => handleUpload(e, 'all')}
                                showUploadList={false}
                                accept="image/*"
                            >
                                <Button
                                    icon={<UploadOutlined />}
                                    style={{
                                        backgroundColor: '#1890ff',
                                        color: '#fff',
                                        borderColor: '#1890ff',
                                        marginBottom: '10px',
                                    }}
                                >
                                    Upload Image
                                </Button>
                            </Upload>
                        )}
                    </div>
                )}
            </Card>

            <Space
                style={{
                    marginTop: '30px',
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '20px',
                }}
            >
                <Button
                    onClick={() => setCurrentForm(null)}
                    type="primary"
                    danger
                    style={{ width: 120 }}
                >
                    Cancel
                </Button>
                <Button
                    type="primary"
                    onClick={() => {
                        setSongData(art)
                        setCurrentForm(null)
                    }}
                    style={{
                        width: 120,
                    }}
                >
                    Save
                </Button>
            </Space>
        </div>
    )
}

export default Artwork

import React, { useEffect } from 'react'
import './Dashboard.scss'
import ManageContent from '../ManageContent/ManageContent'
import { useSelector } from 'react-redux'
import { getNewsSections, getRejectedReasons } from '../../AadhanApi'
import {
    addNewsSectionData,
    addNewsSectionData2,
    addRejectedReasonData,
} from '../../store/slice/AppSlice'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

const Dashboard = ({ showAddButton = true }) => {
    const languageData = useSelector((state) => state.app.languageData)
    const location = useLocation()
    const accessToken = useSelector((state) => state.app.accessToken)
    const rejectedReasonData = useSelector(
        (state) => state.app.rejectedReasonData
    )
    const newsSectionData = useSelector((state) => state.app.newsSectionData)
    const dispatch = useDispatch()
    const fetchRejectedReasons = async () => {
        try {
            const rejectedDataResponse = await getRejectedReasons(
                languageData?.[0]?.language_id,
                accessToken
            )
            dispatch(
                addRejectedReasonData(
                    rejectedDataResponse?.data?.data?.rejected_reasons
                )
            )
        } catch (error) {
            console.error('Error fetching rejected reasons:', error)
        }
    }
    const fetchNewsSection = async () => {
        try {
            const rejectedDataResponse = await getNewsSections(
                languageData?.[0]?.language_id,
                'all',
                accessToken
            )
            dispatch(addNewsSectionData(rejectedDataResponse?.data?.data))
            dispatch(addNewsSectionData2(rejectedDataResponse?.data?.data))
        } catch (error) {
            console.error('Error fetching news reasons:', error)
        }
    }
    useEffect(() => {
        if (
            languageData?.length !== 0 &&
            accessToken &&
            rejectedReasonData?.length === 0
        ) {
            fetchRejectedReasons()
        }
        if (
            languageData?.length !== 0 &&
            accessToken &&
            newsSectionData?.length === 0
        ) {
            fetchNewsSection()
        }
    }, [languageData])
    return (
        <div className="dashboard-container" style={{padding:location?.pathname?.includes("/home/editorDashboard/tv") ? 0 :""}}>
            <ManageContent showAddButton={showAddButton} />
        </div>
    )
}
export default Dashboard

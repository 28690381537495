import React, { useEffect, useState } from 'react'
import {
    languageContent,
    textStartX,
    TEXT_END_X,
    LINE_HEIGHT,
    TIME_BASED_INDICES,
    TEXT_BASED_INDICES,
    FIXED_GAP_BETWEEN_ENTRIES,
} from './Constants'
import PanchangamTemplate from '../../assets/Panchangam/Astrology Thumb Empty (1).jpg'
import PanchangamTemplateTelugu from '../../assets/Panchangam/Astrology Thumb New.jpg'
import { dayNamesData } from '../Daily Horoscope/LanguageSpecificDays'
import { monthsData } from '../Daily Horoscope/LanguageSpecificMonths.js'
import PropTypes from 'prop-types'

function PanchangamCanvas({
    selectedLanguage,
    selectedMonth,
    selectedDay,
    inputValues,
}) {
    const [canvasRef, setCanvasRef] = useState(null)
    const [ctx, setCtx] = useState(null)
    const [fontsLoaded, setFontsLoaded] = useState(false)
    const TEXT_START_X = textStartX[selectedLanguage] || textStartX.default

    useEffect(() => {
        const initializeCanvas = async () => {
            try {
                const fontFamilies = Object.values(languageContent)
                    .map((content) => [
                        `${content.fontProperties.heading.fontSize} ${content.fontProperties.heading.fontFamily}`,
                        `${content.fontProperties.labels.fontSize} ${content.fontProperties.labels.fontFamily}`,
                        `${content.fontProperties.values.timeBasedFont.fontSize} ${content.fontProperties.values.timeBasedFont.fontFamily}`,
                        `${content.fontProperties.values.textBasedFont.fontSize} ${content.fontProperties.values.textBasedFont.fontFamily}`,
                    ])
                    .flat()

                await Promise.all(
                    fontFamilies.map((font) => document.fonts.load(font))
                )

                setFontsLoaded(true)

                if (canvasRef) {
                    const context = canvasRef.getContext('2d')
                    setCtx(context)

                    const img = new Image()
                    img.src =
                        selectedLanguage === 2
                            ? PanchangamTemplateTelugu
                            : PanchangamTemplate

                    img.onload = () => {
                        canvasRef.width = 288
                        canvasRef.height = 624
                        context.drawImage(
                            img,
                            0,
                            0,
                            canvasRef.width,
                            canvasRef.height
                        )
                        renderCanvasContent()
                    }
                }
            } catch (error) {
                console.error('Error loading fonts:', error)
            }
        }

        initializeCanvas()
    }, [])

    useEffect(() => {
        if (canvasRef && fontsLoaded) {
            const context = canvasRef.getContext('2d')
            context.imageSmoothingEnabled = true
            context.imageSmoothingQuality = 'high'
            setCtx(context)
            renderCanvasContent()
        }
    }, [
        canvasRef,
        selectedLanguage,
        selectedMonth,
        selectedDay,
        inputValues,
        fontsLoaded,
    ])

    const renderCanvasContent = () => {
        if (!ctx || !canvasRef || !fontsLoaded) return

        const img = new Image()
        img.src =
            selectedLanguage === 2
                ? PanchangamTemplateTelugu
                : PanchangamTemplate

        img.onload = () => {
            canvasRef.width = 288
            canvasRef.height = 624
            ctx.drawImage(img, 0, 0, canvasRef.width, canvasRef.height)
            renderInputValues()
        }
    }

    const renderInputValues = () => {
        if (!ctx || !canvasRef) return

        const content = languageContent[selectedLanguage]

        // temporary canvas to draw our text
        const tempCanvas = document.createElement('canvas')
        tempCanvas.width = canvasRef.width
        tempCanvas.height = canvasRef.height
        const tempCtx = tempCanvas.getContext('2d')
        const canvasWidth2 = tempCanvas.width

        // heading
        // tempCtx.fillStyle = '#F7AB4B'
        tempCtx.fillStyle = '#FEBB58'

        const headingFontSize = content.fontProperties.heading.fontSize
        tempCtx.font = `bold ${headingFontSize} ${content.fontProperties.heading.fontFamily}`

        const combinedHeading = `${content.heading} - ${content.heading2}`

        // Center position for the combined heading
        if (selectedLanguage !== 2) {
            const combinedHeadingWidth =
                tempCtx.measureText(combinedHeading).width
            const combinedHeadingX = (canvasWidth2 - combinedHeadingWidth) / 2
            const combinedHeadingY = 76 //  Y position to center the heading

            selectedLanguage === 3
                ? tempCtx.fillText(combinedHeading, 15, 74)
                : tempCtx.fillText(
                      combinedHeading,
                      combinedHeadingX,
                      combinedHeadingY
                  )
        }
        //  date
        // language string from number
        const getLanguageString = (langNumber) => {
            switch (langNumber) {
                case 1:
                    return 'english'
                case 2:
                    return 'telugu'
                case 3:
                    return 'hindi'
                case 4:
                    return 'tamil'
                default:
                    return 'english'
            }
        }

        // date
        const formatDate = (day, month, languageNumber) => {
            const language = getLanguageString(languageNumber)
            const currentYear = new Date().getFullYear()
            const monthName = monthsData[language][month]
            const dayName =
                dayNamesData[language][
                    new Date(currentYear, month, day).getDay()
                ]

            return `${monthName} ${day}, ${dayName}`
        }

        const formattedDate = formatDate(
            selectedDay,
            selectedMonth,
            selectedLanguage
        )

        let x
        if (selectedLanguage === 2) {
            x = 15
        } else if (selectedLanguage === 4) {
            x = 9
        } else {
            x = 17
        }

        tempCtx.font = '13.5px Sree Krushnadevaraya'

        tempCtx.fillStyle = '#FFFFFF'
        tempCtx.fillText(formattedDate, x, 105)

        let currentY = 147 // Starting Y position
        const lineHeight = LINE_HEIGHT[selectedLanguage] || LINE_HEIGHT.default

        Object.entries(inputValues).forEach(([index, value]) => {
            const numericIndex = parseInt(index)

            if (selectedLanguage === 2 && numericIndex === 8) {
                currentY += lineHeight //  extra line gap
            }
            const labelY = currentY

            let useTextBasedFont = false
            if (selectedLanguage === 2) {
                useTextBasedFont = TEXT_BASED_INDICES[2].includes(numericIndex)
            } else {
                useTextBasedFont = numericIndex > TIME_BASED_INDICES.default
            }

            // label
            // tempCtx.fillStyle = '#F7AB4B'
            tempCtx.fillStyle = '#FEBB58'

            tempCtx.font = `bold ${content.fontProperties.labels.fontSize} ${content.fontProperties.labels.fontFamily}`

            if (selectedLanguage === 3) {
                tempCtx.fillText(content.labels[index], 15, labelY)
            } else if (selectedLanguage === 2) {
                tempCtx.fillText(content.labels[index], 15, labelY)

                // For the 9th label in Telugu, extra space before the value
                if (numericIndex === 8) {
                    currentY += lineHeight // next line for value
                }
            } else {
                tempCtx.fillText(content.labels[index], 11, labelY)
            }
            const fontProperties = useTextBasedFont
                ? content.fontProperties.values.timeBasedFont
                : content.fontProperties.values.textBasedFont

            // input value
            tempCtx.fillStyle = '#FFFFFF'
            tempCtx.font = `${fontProperties.fontSize} ${fontProperties.fontFamily}`

            const isTeluguNinthLabel =
                selectedLanguage === 2 && numericIndex === 8

            // manual line breaks
            const manualLines = value.split('\\')
            let lastLineY = currentY

            manualLines.forEach((manualLine, lineIndex) => {
                const words = manualLine.trim().split(' ')
                let line = ''
                let testLine = ''

                words.forEach((word) => {
                    testLine = line + word + ' '
                    const metrics = tempCtx.measureText(testLine)
                    const effectiveTextEndX = isTeluguNinthLabel
                        ? TEXT_END_X - 20
                        : TEXT_END_X - TEXT_START_X

                    if (metrics.width > effectiveTextEndX && line !== '') {
                        // different x-position for 9th Telugu label's value
                        const xPosition = isTeluguNinthLabel
                            ? 15
                            : selectedLanguage === 3
                            ? TEXT_START_X - 10
                            : TEXT_START_X

                        tempCtx.fillText(line, xPosition, currentY)
                        currentY += lineHeight
                        line = word + ' '
                    } else {
                        line = testLine
                    }
                })

                if (line !== '') {
                    // different x-position for 9th Telugu label's value
                    const xPosition = isTeluguNinthLabel
                        ? 15
                        : selectedLanguage === 3
                        ? TEXT_START_X - 10
                        : TEXT_START_X

                    tempCtx.fillText(line, xPosition, currentY)
                    currentY += lineHeight
                }

                if (lineIndex < manualLines.length - 1) {
                    currentY += lineHeight * 0.01
                }
            })

            lastLineY = currentY - lineHeight
            currentY = lastLineY + FIXED_GAP_BETWEEN_ENTRIES
        })

        ctx.clearRect(0, 150, canvasRef.width, canvasRef.height - 150)

        const img = new Image()
        img.src =
            selectedLanguage === 2
                ? PanchangamTemplateTelugu
                : PanchangamTemplate

        ctx.drawImage(img, 0, 0, canvasRef.width, canvasRef.height)

        ctx.drawImage(tempCanvas, 0, 0)
    }

    return (
        <canvas
            ref={(canvas) => {
                setCanvasRef(canvas)
                if (canvas && fontsLoaded) {
                    const context = canvas.getContext('2d')
                    setCtx(context)
                    renderCanvasContent()
                }
            }}
            style={{
                width: '18rem',
                height: '39rem',
            }}
        />
    )
}

PanchangamCanvas.propTypes = {
    selectedLanguage: PropTypes.oneOf([1, 2, 3, 4]).isRequired,
    selectedMonth: PropTypes.number.isRequired,
    selectedDay: PropTypes.number.isRequired,
    inputValues: PropTypes.objectOf(PropTypes.string).isRequired,
}

export default PanchangamCanvas

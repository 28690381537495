import { Form, Input } from 'antd'
import { useEffect } from 'react'
import {
    createState,
    createDistrict,
    createMandal,
    createArea,
    createAssemblyConstituency,
    getStates,
    CreateCity,
} from '../../AadhanApi'
import { addLocations } from '../../store/slice/AppSlice'
import { useDispatch } from 'react-redux'

const AddLocation = ({
    selectedRole,
    getTableData,
    setShowModal,
    getCities,
    accessToken,
    selectedLocation,
    selectedDistrictKey,
    selectedMandalKey,
    selectedStateKey,
    selectedAssemblyConstituencyKey,
}) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const onFinish = async (values) => {
        if (selectedRole) {
            // await updateRole("obj", accessToken, selectedRole.id)
        } else {
            if (selectedLocation == 'State') {
                await createState(values.name, accessToken)
            } else if (selectedLocation == 'District') {
                const nameList = values.name.split(',')
                await createDistrict(nameList, selectedStateKey, accessToken)
            } else if (selectedLocation == 'Mandal') {
                const nameList = values.name.split(',')
                await createMandal(
                    nameList,
                    selectedStateKey,
                    selectedDistrictKey,
                    accessToken
                )
            } else if (selectedLocation === 'Area') {
                const nameList = values.name.split(',')
                await createArea(nameList, selectedMandalKey, accessToken)
            }
            else if (selectedLocation === 'City') {
                const nameList = values.name.includes(',') ? values.name.split(',') : [values.name];
                await CreateCity(accessToken, selectedStateKey, nameList);
                getCities()
                
            }
             else if (selectedLocation === 'Assembly Constituencies') {
                const nameList = values.name.split(',')
                await createAssemblyConstituency(
                    nameList,
                    selectedStateKey,
                    accessToken
                )
            }
           
        }
        const allLocationResponse = await getStates(accessToken)
        await dispatch(addLocations(allLocationResponse?.data))
        getTableData()
        setShowModal(false)
        form.resetFields()
    }

    useEffect(() => {
        if (selectedRole) {
            form.setFieldsValue({
                name: selectedRole?.role,
            })
        } else {
            form.resetFields()
        }
    }, [selectedRole])

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    return (
        <Form
            id="roleForm"
            form={form}
            name="roleForm"
            scrollToFirstError
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                labelCol={{ span: 24 }}
                label={selectedLocation}
                name="name"
                rules={[{ required: true, message: 'Please enter name' }]}
            >
                <Input />
            </Form.Item>
        </Form>
    )
}

export default AddLocation

import React, { useEffect, useState } from 'react'
import { Table, Button, Popconfirm, message } from 'antd'
import { CheckCircleOutlined, DeleteOutlined, } from '@ant-design/icons'
import '../devotional.scss'
import { useLocation, useNavigate } from 'react-router'
// import AddPodcastModal from './AddPodcastModal'
import { PodcastView } from './PodcastView'
import { deletePodcast, getAllPodcasts } from '../../../AadhanApi'
import { useSelector } from 'react-redux'
import { SelectComponent } from '../../AntdComponent/SelectComponent'
import CreatePodcastForm from './CreatePodcastForm'
import CreateEpisode from './CreateEpisode'
const Podcast = () => {
    const languageData = useSelector((state) => state.app.languageData)
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get('id')
    const [status, setStatus] = useState('all')
    const accessToken = useSelector((state) => state.app.accessToken)
    const statusOptions = [
        {
            value: 'all',
            label: '--All--',
        },
        {
            value: 'approved',
            label: 'Published',
        },
        {
            value: 'draft',
            label: 'Draft',
        },
       
    ]
    const [language, setLanguage] = useState(languageData?.[0]?.language_id) // Default language
    const [podcasts, setPodcasts] = useState([])
    const navigate = useNavigate();

    async function getPodcast() {
        try {
            const res = await getAllPodcasts(accessToken, language, status);
            if (res?.data?.detail?.error === "Signature has expired" || res?.data?.detail?.msg === "token expired. please login!") {
                navigate('/login');
                return;
            }
            setPodcasts(res?.data);
    
        } catch (error) {
            console.error("Error fetching podcasts:", error);
            
        }
    }
            
    useEffect(() => {
        if (language) {
            getPodcast()
        }
    }, [language, status])

    const handleDelete = async (id) => {
        const res = await deletePodcast(accessToken, id)
        if (res?.status === 200) {
            message.success('Podcast deleted successfully')
            getPodcast()
        } else {
            message.error('Unable to delete podcast')
        }
    }

    const columns = [
        {
            title: 'Podcasts',
            key: 'details',
            render: (record) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '16px',
                    }}
                >
                    <img
                        src={record?.image_url}
                        alt={record?.title}
                        style={{
                            width: '90px',
                            height: '90px',
                            objectFit: 'cover',
                            borderRadius: '8px',
                            border: '2px solid #ddd',
                        }}
                    />
                    <div style={{ flex: 1 }}>
                        <span
                            className="hyper-link"
                            onClick={() =>
                                navigate(
                                    `/home/podcasts?id=${record.podcast_id}`
                                )
                            }
                            style={{
                                cursor: 'pointer',
                                color: '#1a73e8',
                                fontWeight: '500',
                                fontSize: '16px',
                                textDecoration: 'underline',
                            }}
                        >
                            {record.title} <span style={{fontSize:14,fontWeight:500,marginLeft:3,color:"green"}}>{record?.content_status==='approved' && status==='all' && <CheckCircleOutlined/>}</span>
                        </span>
                        <p
                            style={{
                                margin: '0px 0',
                                color: '#666',
                                fontSize: '14px',
                            }}
                        >
                            {record.description}
                        </p>

                        {record?.cast?.names && (
                            <div
                                style={{
                                    marginTop: '0px',
                                    fontSize: '14px',
                                    color: '#555',
                                }}
                            >
                                <strong>Cast:</strong>
                                <span
                                    style={{ marginLeft: '8px', color: '#444' }}
                                >
                                    {record?.cast?.names
                                        .split(',')
                                        .map((artist, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    display: 'inline-flex',
                                                    marginRight: '8px',
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        color: 'gray', 
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    {artist}
                                                </span>
                                                <span
                                                    style={{
                                                        color: '#888', 
                                                        fontStyle: 'italic',
                                                        marginLeft: '4px',
                                                    }}
                                                >
                                                    (
                                                    {
                                                        record?.cast?.roles.split(
                                                            ','
                                                        )[index]
                                                    }
                                                    )
                                                </span>
                                            </span>
                                        ))
                                        .reduce((prev, curr) => [
                                            prev,
                                            ', ',
                                            curr,
                                        ])}
                                </span>
                            </div>
                        )}
                        <p
                            style={{
                                margin: '2px 0',
                                color: '#444',
                                fontSize: '14px',
                                display: 'flex',
                                alignItems: 'center', 
                                gap: '4px', 
                            }}
                        >
                            <strong>Episodes:</strong>{' '}
                            <span style={{ marginTop: '.36%' ,marginRight:5}}>
                                {record?.episodes?.length ?? 'N/A'} 
                            </span>
                            <CreateEpisode ui={true} getdata={getPodcast} data={record} languageData={languageData} language={language} accessToken={accessToken} />
                        </p>
                    </div>
                </div>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: '16px',
                        padding: '16px',
                    }}
                >
                    <CreatePodcastForm
                        getPodcast={getPodcast}
                        languageData={languageData}
                        language={language}
                        accessToken={accessToken}
                        record={record}
                        mode={'edit'}
                    />
                    <Popconfirm
                        title={
                            <div>
                                Are you sure you want to delete this podcast ?
                            </div>
                        }
                        onConfirm={() => handleDelete(record?.podcast_id)} 
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button
                            type="primary"
                            danger
                            icon={<DeleteOutlined />}
                            style={{
                                backgroundColor: '#d32f2f',
                                borderColor: '#d32f2f',
                                color: '#fff',
                                borderRadius: '20px',
                                padding: '0 20px',
                                fontWeight: 'bold',
                                boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
                            }}
                        ></Button>
                    </Popconfirm>
                </div>
            ),
        },
    ]

    return (
        <div>
            {id ? (
                <PodcastView languageData={languageData} language={language} />
            ) : (
                <div style={{ padding: '0 1rem' }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <h4>Manage Podcast</h4>
                        <CreatePodcastForm
                             getPodcast={getPodcast}
                            languageData={languageData}
                            language={language}
                            accessToken={accessToken}
                        />
                    </div>
                    <hr></hr>
                    <div
                        className="songs-header"
                        style={{ marginBottom: '1rem' }}
                    >
                        <div>
                            <SelectComponent
                                data={statusOptions}
                                value={status}
                                onChange={(e) => setStatus(e)}
                                key_value={'value'}
                                display={'label'}
                                className={''}
                                style={{ width: 130 }}
                                placeholder={''}
                            />
                        </div>
                        <div>
                            <SelectComponent
                                data={languageData}
                                value={language}
                                onChange={(e) => {
                                    setLanguage(e)
                                }}
                                key_value={'language_id'}
                                display={'language_name'}
                                className={''}
                                style={{ width: 130 }}
                                placeholder={''}
                            />
                        </div>
                    </div>
                    <div
                        style={{ position: 'absolute', right: '1rem', top: 0 }}
                    >
                        {/* <AddPodcastModal languageData={languageData} /> */}
                    </div>
                    <Table
                        rowKey="id"
                        dataSource={podcasts}
                        columns={columns}
                        pagination={{ pageSize: 3 }}
                    />
                </div>
            )}
        </div>
    )
}

export default Podcast

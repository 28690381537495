import { Form, Input, message, Select } from 'antd'
import { useEffect, useState } from 'react'
import { createRole, updateRole } from '../../AadhanApi'
import { useSelector } from 'react-redux'

const AddRole = ({
    selectedRole,
    getTableData,
    setShowModal,
    accessToken,
    departmentsData,
    selectedDepartmentMain,
    onDepartmentChange,
    setSelectedDepartment,
}) => {
    const [form] = Form.useForm()
    const employeeId = useSelector((state) => state.app.employeeId)
    const { Option } = Select
    // const [selectedDepartment, setSelectedDepartment] = useState()
    const [messageApi, contextHolder] = message.useMessage()
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        if (selectedRole) {
            form.setFieldsValue({
                roleName: selectedRole?.role,
                departmentName: selectedRole
                    ? selectedRole.departmentsId
                    : undefined,
            })
        } else {
            form.resetFields(['roleName', 'departmentName'])
            form.setFieldsValue({
                departmentName: selectedDepartmentMain,
            })
        }
    }, [selectedRole, selectedDepartmentMain, form])

    const onFinish = async (values) => {
        if (isSubmitting) return

        try {
            setIsSubmitting(true)

            const obj = {
                role_name: values.roleName,
                status: 'active',
                created_by: employeeId,
                created_date: new Date().toISOString(),
                updated_by: employeeId,
                updated_date: new Date().toISOString(),
                employee_department_id: values.departmentName,
            }
            if (selectedRole) {
                await updateRole(obj, accessToken, selectedRole.id)
            } else {
                await createRole(obj, accessToken)
            }
            onDepartmentChange(values.departmentName)
            await getTableData(values.departmentName)

            messageApi.success('Role saved successfully')
            setShowModal(false)
            form.resetFields()
        } catch (error) {
            if (error.response.data.detail) {
                messageApi.error(error.response.data.detail)
            } else {
                messageApi.error('Failed to save role')
            }
            console.error(error)
        } finally {
            setIsSubmitting(false)
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }
    return (
        <>
            {contextHolder}

            <Form
                id="roleForm"
                form={form}
                name="roleForm"
                scrollToFirstError
                // labelCol={{ span: 5 }}
                // wrapperCol={{ span: 24 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    labelCol={{ span: 24 }}
                    label="Department Name"
                    name="departmentName"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter department name',
                        },
                    ]}
                >
                    <Select
                        // onChange={handleDepartmentChange}
                        style={{ width: '100%' }}
                        value={selectedDepartmentMain}
                        placeholder="Select department"
                    >
                        {departmentsData
                            ? departmentsData.map((department) => (
                                  <Option
                                      key={department.employee_department_id}
                                      value={department.employee_department_id}
                                  >
                                      {department.employee_department_name}
                                  </Option>
                              ))
                            : undefined}
                    </Select>
                </Form.Item>
                <Form.Item
                    labelCol={{ span: 24 }}
                    label="Role Name"
                    name="roleName"
                    rules={[
                        { required: true, message: 'Please enter role name' },
                    ]}
                >
                    <Input
                        style={{
                            width: '100%',
                            minWidth: '100%',
                            maxWidth: '100%',
                        }}
                        placeholder="Please enter role name"
                    />
                </Form.Item>
                <br></br>
            </Form>
        </>
    )
}

export default AddRole

import React, { useEffect, useState } from 'react'
import {
    Modal,
    Button,
    Form,
    Input,
    Select,
    Radio,
} from 'antd'
import { useSelector } from 'react-redux'
import { updatePanchangam } from '../../AadhanApi'

const { Option } = Select

const AddPanchangamModal = ({
    title,
    Date,
    language,
    token,
    panchangamData,
    setRender,
}) => {
    const panchangamYears = useSelector(
        (state) => state.panchangam.panchangamYears
    )
    const panchangamMonths = useSelector(
        (state) => state.panchangam.panchangamMonths
    )
    const panchangamSeasons = useSelector(
        (state) => state.panchangam.panchangamSeasons
    )
    const panchangamPaksha = useSelector(
        (state) => state.panchangam.panchangamPaksha
    )
    const panchangamSolistices = useSelector(
        (state) => state.panchangam.panchangamSolistices
    )
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [year, setYear] = useState()
    let panchangamPermission = localStorage.getItem('USER_PERMISSIONS')
    panchangamPermission =
        JSON.parse(panchangamPermission)?.Astrology?.Horoscope

    const [initialFormData, setInitialFormData] = useState({
        Year: panchangamData.data[language]?.hindu_year,
        Solistice: panchangamData.data[language]?.solstice,
        Season: panchangamData.data[language]?.season,
        Month: panchangamData.data[language]?.hindu_month,
        Paksha: panchangamData.data[language]?.paksh,
        Tithi: panchangamData.data[language]?.tithi,
        Nakshatram: panchangamData.data[language]?.star,
        Amruthaghadiyalu: panchangamData.data[language]?.amruth_ghadiya,
        Vargyam: panchangamData.data[language]?.varjyam,
        Durmuhurtham: panchangamData.data[language]?.durmuhurtham,
        Rahukalam: panchangamData.data[language]?.rahukalam,
        Yamagandam: panchangamData.data[language]?.yamagandam,
        AbhijitMuhurtham: panchangamData.data[language]?.abhijit_muhurtham,
        BramhaMuhurtham: panchangamData.data[language]?.brahma_muhurtham,
    })

    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 8,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 14,
            },
        },
    }

    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleOk = () => {
        setIsModalOpen(false)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    function handleYearChange(e) {
        setYear(e)
    }
    function createRequest(value) {
        return {
            hindu_year: value?.Year,
            solstice: value?.Solistice,
            season: value?.Season,
            hindu_month: value?.Month,
            paksh: value?.Paksha,
            tithi: value?.Tithi,
            star: value?.Nakshatram,
            amruth_ghadiya: value?.Amruthaghadiyalu,
            varjyam: value?.Vargyam,
            durmuhurtham: value?.Durmuhurtham,
            rahukalam: value?.Rahukalam,
            language_id: language,
            yamagandam: value?.Yamagandam,
            abhijit_muhurtham: value?.AbhijitMuhurtham,
            brahma_muhurtham: value?.BramhaMuhurtham,
        }
    }
    async function onFinish(values) {
        const req = await createRequest(values)
        const res = await updatePanchangam(
            panchangamData.panchangam_id,
            req,
            token
        )
        setRender(1)
        handleOk()
    }

    useEffect(() => {
        if (panchangamYears?.length !== 0) {
            setYear(panchangamYears[0][language])
        }

        setInitialFormData({
            Year: panchangamData.data[language]?.hindu_year,
            Solistice: panchangamData.data[language]?.solstice,
            Season: panchangamData.data[language]?.season,
            Month: panchangamData.data[language]?.hindu_month,
            Paksha: panchangamData.data[language]?.paksh,
            Tithi: panchangamData.data[language]?.tithi,
            Nakshatram: panchangamData.data[language]?.star,
            Amruthaghadiyalu: panchangamData.data[language]?.amruth_ghadiya,
            Vargyam: panchangamData.data[language]?.varjyam,
            Durmuhurtham: panchangamData.data[language]?.durmuhurtham,
            Rahukalam: panchangamData.data[language]?.rahukalam,
            Yamagandam: panchangamData.data[language]?.yamagandam,
            AbhijitMuhurtham: panchangamData.data[language]?.abhijit_muhurtham,
            BramhaMuhurtham: panchangamData.data[language]?.brahma_muhurtham,
        })
    }, [language, panchangamYears])

    return (
        <>
            <div
                style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    color: 'blue',
                    cursor: 'pointer',
                }}
                onClick={showModal}
            >
                {panchangamData?.data[language]?.hindu_year !== undefined &&
                    panchangamData?.data[language]?.hindu_year + ' : '}
                {panchangamData?.data[language]?.solstice !== undefined &&
                    panchangamData?.data[language]?.solstice + ' ; '}
                {panchangamData?.data[language]?.hindu_month !== undefined &&
                    panchangamData?.data[language]?.hindu_month + ' , '}
                {panchangamData?.data[language]?.tithi !== undefined &&
                    panchangamData?.data[language]?.tithi + ' , '}
                {panchangamData?.data[language]?.star !== undefined &&
                    panchangamData?.data[language]?.star + ' , '}
                {panchangamData?.data[language]?.solstice === undefined &&
                    panchangamData?.data[language]?.hindu_year === undefined &&
                    panchangamData?.data[language]?.hindu_month === undefined &&
                    panchangamData?.data[language]?.tithi === undefined &&
                    panchangamData?.data[language]?.star === undefined &&
                    'Add'}
            </div>
            <Modal
                open={isModalOpen}
                onOk={handleOk}
                style={{ top: 20 }}
                footer={null}
                onCancel={handleCancel}
                width="45%"
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0rem 1rem 0rem 0',
                    }}
                >
                    <h5> {title}</h5>
                    <div style={{ marginLeft: '4rem' }}>
                        <label>Date : </label>
                        <span style={{ fontWeight: '500' }}>{' ' + Date}</span>
                    </div>
                </div>
                {/* <Layout style={{height:"80%" ,backgroundColor:"transparent"}} > */}
                {/* <Content style={{ height: '500px', overflowY: 'auto' }}> */}
                <Form
                    {...formItemLayout}
                    initialValues={initialFormData}
                    variant="filled"
                    labelAlign="left"
                    style={{
                        marginTop: '1rem',
                        maxWidth: 600,
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Year"
                        name="Year"
                        rules={[
                            {
                                required: true,
                                message: 'Please select year',
                            },
                        ]}
                    >
                        <Select
                            value={year}
                            style={{ width: '10rem' }}
                            placeholder="- select year -"
                            onChange={handleYearChange}
                        >
                            {panchangamYears.map((e, index) => (
                                <Option key={index} value={e[language]}>
                                    {e[language]}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Solistice"
                        name="Solistice"
                        rules={[
                            {
                                required: true,
                                message: 'Please select any one',
                            },
                        ]}
                    >
                        <Radio.Group>
                            {panchangamSolistices.map((e) => (
                                <Radio key={e[language]} value={e[language]}>
                                    {e[language]}{' '}
                                </Radio>
                            ))}
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="Season"
                        name="Season"
                        rules={[
                            {
                                required: true,
                                message: 'Please select season',
                            },
                        ]}
                    >
                        <Select
                            value={year}
                            style={{ width: '12rem' }}
                            placeholder="- select season -"
                            onChange={handleYearChange}
                        >
                            {panchangamSeasons.map((e, index) => (
                                <Option key={index} value={e[language]}>
                                    {e[language]}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Month"
                        name="Month"
                        rules={[
                            {
                                required: true,
                                message: 'Please select month',
                            },
                        ]}
                    >
                        <Select
                            value={year}
                            style={{ width: '10rem' }}
                            placeholder="- select month -"
                            onChange={handleYearChange}
                        >
                            {panchangamMonths.map((e, index) => (
                                <Option key={index} value={e[language]}>
                                    {e[language]}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Paksha"
                        name="Paksha"
                        rules={[
                            {
                                required: true,
                                message: 'Please select any one',
                            },
                        ]}
                    >
                        <Radio.Group>
                            {panchangamPaksha.map((e) => (
                                <Radio key={e[language]} value={e[language]}>
                                    {e[language]}
                                </Radio>
                            ))}
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        label="Tithi"
                        name="Tithi"
                        rules={[
                            {
                                required: true,
                                message: 'Please input!',
                            },
                        ]}
                    >
                        <Input placeholder="Tithi" />
                    </Form.Item>
                    <Form.Item
                        label="Nakshatram"
                        name="Nakshatram"
                        placeholder="Nakshatram"
                        rules={[
                            {
                                required: true,
                                message: 'Please input!',
                            },
                        ]}
                    >
                        <Input placeholder="Nakshatram" />
                    </Form.Item>
                    <Form.Item
                        label="Amrutha ghadiyalu"
                        name="Amruthaghadiyalu"
                        rules={[
                            {
                                required: true,
                                message: 'Please input!',
                            },
                        ]}
                    >
                        <Input placeholder="Amrutha ghadiyalu" />
                    </Form.Item>
                    <Form.Item
                        label="Vargyam"
                        name="Vargyam"
                        rules={[
                            {
                                required: true,
                                message: 'Please input!',
                            },
                        ]}
                    >
                        <Input placeholder="Vargyam" />
                    </Form.Item>
                    <Form.Item
                        label="Durmuhurtham"
                        name="Durmuhurtham"
                        rules={[
                            {
                                required: true,
                                message: 'Please input!',
                            },
                        ]}
                    >
                        <Input placeholder="Durmuhurtham" />
                    </Form.Item>
                    <Form.Item
                        label="Bramha Muhurtham"
                        name="BramhaMuhurtham"
                        rules={[
                            {
                                required: true,
                                message: 'Please input!',
                            },
                        ]}
                    >
                        <Input placeholder="Bramha Muhurtham" />
                    </Form.Item>
                    <Form.Item
                        label="Abhijit Muhurtham"
                        name="AbhijitMuhurtham"
                        rules={[
                            {
                                required: true,
                                message: 'Please input!',
                            },
                        ]}
                    >
                        <Input placeholder="Abhijit Muhurtham" />
                    </Form.Item>
                    <Form.Item
                        label="Yamagandam"
                        name="Yamagandam"
                        rules={[
                            {
                                required: true,
                                message: 'Please input!',
                            },
                        ]}
                    >
                        <Input placeholder="Yamagandam" />
                    </Form.Item>

                    <Form.Item
                        label="Rahukalam"
                        name="Rahukalam"
                        rules={[
                            {
                                required: true,
                                message: 'Please input!',
                            },
                        ]}
                    >
                        <Input placeholder="Rahukalam" />
                    </Form.Item>
                    <Button
                        disabled={
                            panchangamPermission?.includes('Can Add')
                                ? false
                                : true
                        }
                        type="primary"
                        style={{
                            margin: '1rem 0 0 35%',
                            width: '30%',
                            padding: '0 3rem 0 3rem',
                        }}
                        htmlType="submit"
                    >
                        Submit
                    </Button>
                </Form>
            </Modal>
        </>
    )
}

export default AddPanchangamModal

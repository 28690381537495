import React, { useEffect } from 'react'
import { Button, Form, Input, Select, Space, Card, Popconfirm } from 'antd'
import {
    DeleteOutlined,
    MinusOutlined,
    PlusOutlined,
} from '@ant-design/icons'
const Metadata = ({
    languageData,
    currentForm,
    setCurrentForm,
    songData,
    setSongData,
}) => {
    const [form] = Form.useForm()

    useEffect(() => {
        form.setFieldsValue({ languages: songData })
    }, [songData, currentForm])

    const handleSubmit = (values) => {
        if (!values?.languages) {
            return
        }
        setSongData(values?.languages)
        setCurrentForm(null)
    }

    return (
        <div
            style={{
                maxWidth: 900,
                height: '85%',
                margin: 'auto',
                overflowY: 'auto',
                borderRadius: '12px',
            }}
        >
            <Form
                style={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
                form={form}
                onFinish={handleSubmit}
                layout="vertical"
                autoComplete="off"
            >
                <Form.List name="languages">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, fieldKey }) => (
                                <Card
                                    key={key}
                                    style={{
                                        marginBottom: 16,
                                        borderRadius: '8px',
                                        boxShadow:
                                            '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                        backgroundColor: '#fafafa',
                                    }}
                                >
                                    <Space
                                        direction="vertical"
                                        style={{ width: '100%' }}
                                    >
                                        {/* Language Selection */}
                                        <Form.Item
                                            name={[name, 'language_id']}
                                            fieldKey={[fieldKey, 'language_id']}
                                            label="Select Language"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please select language!',
                                                },
                                            ]}
                                        >
                                            <Select
                                                placeholder="Select language"
                                                style={{
                                                    width: '100%',
                                                    borderRadius: '8px',
                                                    borderColor: '#d9d9d9',
                                                }}
                                                options={languageData
                                                    .filter(
                                                        (lang) =>
                                                            !form
                                                                .getFieldValue(
                                                                    'languages'
                                                                )
                                                                ?.map(
                                                                    (l) =>
                                                                        l?.language_id
                                                                )
                                                                ?.includes(
                                                                    lang.language_id
                                                                ) ||
                                                            lang.language_id ===
                                                                form.getFieldValue(
                                                                    [
                                                                        'languages',
                                                                        name,
                                                                        'language_id',
                                                                    ]
                                                                )
                                                    )
                                                    .map((lang) => ({
                                                        label: lang.language_name,
                                                        value: lang.language_id,
                                                    }))}
                                            />
                                        </Form.Item>

                                        {/* Song Title */}
                                        <Form.Item
                                            name={[name, 'title']}
                                            fieldKey={[fieldKey, 'title']}
                                            label="Song Title"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please provide the song title!',
                                                },
                                            ]}
                                        >
                                            <Input style={{minWidth:"100%"}} placeholder="Enter song title" />
                                        </Form.Item>
                                        <Form.List name={[name, 'cast']}>
                                            {(
                                                castFields,
                                                {
                                                    add: addCast,
                                                    remove: removeCast,
                                                }
                                            ) => (
                                                <>
                                                    {castFields.map(
                                                        (
                                                            {
                                                                key: castKey,
                                                                name: castName,
                                                                fieldKey:
                                                                    castFieldKey,
                                                            },
                                                            index
                                                        ) => (
                                                            <Space
                                                                key={castKey}
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    marginBottom: 8,
                                                                }}
                                                                align="baseline"
                                                            >
                                                                <Form.Item
                                                                    name={[
                                                                        castName,
                                                                        'name',
                                                                    ]}
                                                                    fieldKey={[
                                                                        castFieldKey,
                                                                        'name',
                                                                    ]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                'Please provide artist name!',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        placeholder="Artist Name"
                                                                        style={{
                                                                            width: '220px',
                                                                        }}
                                                                    />
                                                                </Form.Item>
                                                                <Form.Item
                                                                    name={[
                                                                        castName,
                                                                        'role',
                                                                    ]}
                                                                    fieldKey={[
                                                                        castFieldKey,
                                                                        'role',
                                                                    ]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                'Please provide cast role!',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        placeholder="Role"
                                                                        style={{
                                                                            width: '220px',
                                                                        }}
                                                                    />
                                                                </Form.Item>
                                                                <Button
                                                                    type="dashed"
                                                                    block
                                                                    style={{
                                                                        fontSize:
                                                                            '14px',
                                                                        fontWeight:
                                                                            '500',
                                                                        backgroundColor:
                                                                            '#f0f0f0',
                                                                        borderRadius:
                                                                            '8px',
                                                                        padding:
                                                                            '8px',
                                                                    }}
                                                                    icon={
                                                                        <MinusOutlined />
                                                                    }
                                                                    onClick={() =>
                                                                        removeCast(
                                                                            castName
                                                                        )
                                                                    }
                                                                />
                                                                {castFields?.length >
                                                                    0 &&
                                                                    castFields?.length -
                                                                        1 ===
                                                                        index && (
                                                                        <Button
                                                                            type="dashed"
                                                                            onClick={() =>
                                                                                addCast()
                                                                            }
                                                                            icon={
                                                                                <PlusOutlined />
                                                                            }
                                                                            block
                                                                            style={{
                                                                                fontSize:
                                                                                    '14px',
                                                                                fontWeight:
                                                                                    '500',
                                                                                backgroundColor:
                                                                                    '#f0f0f0',
                                                                                borderRadius:
                                                                                    '8px',
                                                                                padding:
                                                                                    '8px',
                                                                            }}
                                                                        ></Button>
                                                                    )}
                                                            </Space>
                                                        )
                                                    )}
                                                    {castFields.length <= 0 && (
                                                        <Form.Item
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'end',
                                                            }}
                                                        >
                                                            <Button
                                                                type="dashed"
                                                                onClick={() =>
                                                                    addCast()
                                                                }
                                                                icon={
                                                                    <PlusOutlined />
                                                                }
                                                                block
                                                                style={{
                                                                    fontSize:
                                                                        '14px',
                                                                    fontWeight:
                                                                        '600',
                                                                    backgroundColor:
                                                                        '#28a745',
                                                                    color: '#ffffff',
                                                                    borderRadius:
                                                                        '50px',
                                                                    padding:
                                                                        '12px 24px',
                                                                    border: 'none',
                                                                    boxShadow:
                                                                        '0px 4px 10px rgba(0, 0, 0, 0.1)',
                                                                    transition:
                                                                        'all 0.3s ease-in-out',
                                                                }}
                                                                onMouseEnter={(
                                                                    e
                                                                ) => {
                                                                    e.target.style.backgroundColor =
                                                                        '#218838'
                                                                }}
                                                                onMouseLeave={(
                                                                    e
                                                                ) => {
                                                                    e.target.style.backgroundColor =
                                                                        '#28a745'
                                                                }}
                                                            >
                                                                Add Casting
                                                            </Button>
                                                        </Form.Item>
                                                    )}
                                                </>
                                            )}
                                        </Form.List>

                                        {/* Add & Remove Buttons */}
                                        <Space
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-end',
                                                gap: '8px',
                                                marginTop: '16px',
                                            }}
                                        >
                                            <Button
                                                onClick={() => add()}
                                                icon={<PlusOutlined />}
                                                type="primary"
                                            ></Button>
                                            <Popconfirm
                                                title="Are you sure you want to delete this song?"
                                                onConfirm={() => remove(name)} 
                                                okText="Yes"
                                                cancelText="No"
                                            >
                                                <Button
                                                    type="primary"
                                                    danger
                                                    icon={<DeleteOutlined />}
                                                ></Button>
                                            </Popconfirm>
                                        </Space>
                                    </Space>
                                </Card>
                            ))}
                            {fields.length === 0 && (
                                <Form.Item
                                    style={{
                                        display: 'flex',
                                        height: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        icon={<PlusOutlined />}
                                        style={{
                                            fontSize: '16px',
                                            fontWeight: '500',
                                            borderRadius: '8px',
                                            padding: '20px 18px',
                                        }}
                                    >
                                        Add Language
                                    </Button>
                                </Form.Item>
                            )}
                        </>
                    )}
                </Form.List>
                <Space
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 20,
                        marginTop: 'auto',
                    }}
                >
                    <Button
                        type='primary'
                        danger
                        onClick={() => setCurrentForm(null)}
                        style={{
                            fontSize: '16px',
                            padding: '8px 16px',
                            width:120
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                            fontSize: '16px',
                            padding: '8px 16px',
                            width:120
                        }}
                    >
                        Save
                    </Button>
                </Space>
            </Form>
        </div>
    )
}

export default Metadata

import React, { useEffect, useRef, useState } from "react";
import Hls from "hls.js";
import { Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

export default function Player({ podcast ,currentIndex}) {
  const [trackIndex, setTrackIndex] = useState(currentIndex); // Track navigation state
  useEffect(()=>{
    setTrackIndex(currentIndex)
  },[currentIndex])

  const handlePrevious = () => {
    setTrackIndex((currentIndex) =>
      currentIndex === 0 ? podcast.length - 1 : currentIndex - 1
    );
  };

  const handleNext = () => {
    setTrackIndex((currentIndex) =>
      currentIndex < podcast.length - 1 ? currentIndex + 1 : 0
    );
  };

  return (
    <div style={{ textAlign: "center", flex:1, margin: "auto" }}>
      <span style={{color:"plum",fontFamily:"cursive"}} >{podcast?.[trackIndex]?.title}</span>
      <audio autoPlay src={podcast?.[trackIndex]?.audio_url?.replace(".m3u8","/audio.m4a")} controls style={{ width: "100%",backgroundColor:"transparent" }} />
      <div style={{ marginTop: "5px", display: "flex", justifyContent: "center", gap: "10px" }}>
        <Button icon={<LeftOutlined/>} onClick={handlePrevious} style={buttonStyle}>
        
        </Button>
        <Button icon={<RightOutlined/>} onClick={handleNext} style={buttonStyle}>
          
        </Button>
      </div>
    </div>
  );
}

const buttonStyle = {
  backgroundColor: "orange",
  color: "white",
  border: "none",
};


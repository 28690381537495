import React, { useEffect, useState } from 'react'
import {
    Form,
    Input,
    Modal,
    Select,
    Switch,
    TimePicker,
    DatePicker,
} from 'antd'
import moment from 'moment'
import {
    FREQUENCY_OPTIONS,
    WEEKDAY_OPTIONS,
    DATE_OPTIONS,
} from './SnippetCategoryConstants'
import {
    createSnippetCategory,
    updateSnippetCategory,
} from '../../../AadhanApi'

function AddSnippetCategory({
    isModalOpen,
    handleCancel,
    languageData,
    token,
    onSuccess,
    editingCategory,
    selectedLanguage,
    existingCategories,
}) {
    const [form] = Form.useForm()
    const [isRecurring, setIsRecurring] = React.useState('no')
    const [selectedFrequency, setSelectedFrequency] = React.useState()
    const [categoryName, setCategoryName] = useState('')
    // const [isCategoryNameValid, setIsCategoryNameValid] = useState(true)
    const { RangePicker } = DatePicker

    const handleFrequencyChange = (value) => {
        setSelectedFrequency(value)
    }

    const handleLanguageChange = (value) => {
        form.setFieldsValue({ language_id: value })
    }

    const validateEndTime = (_, value) => {
        const startTime = form.getFieldValue('start_time_of_day')
        if (!startTime || !value) {
            return Promise.resolve()
        }
        if (value.isAfter(startTime)) {
            return Promise.resolve()
        }
        return Promise.reject(
            new Error('End time should be greater than start time')
        )
    }

    useEffect(() => {
        if (editingCategory) {
            // Set basic fields
            form.setFieldsValue({
                language_id: editingCategory.language_id,
                category_name: editingCategory.snippet_category_name,
                local_name: editingCategory.local_name,
            })

            // Determine if it's recurring and set the switch
            const isRecurringCategory =
                editingCategory.is_daily ||
                editingCategory.is_weekly ||
                editingCategory.is_monthly ||
                editingCategory.is_date_specific ||
                editingCategory.is_date_range

            setIsRecurring(isRecurringCategory ? 'yes' : 'no')

            // Set the frequency and related fields
            if (isRecurringCategory) {
                let frequency
                if (editingCategory.is_daily) frequency = 'daily'
                else if (editingCategory.is_weekly) frequency = 'weekly'
                else if (editingCategory.is_monthly) frequency = 'monthly'
                else if (editingCategory.is_date_specific)
                    frequency = 'date_specific'
                else if (editingCategory.is_date_range) frequency = 'date_range'

                setSelectedFrequency(frequency)
                form.setFieldsValue({ type: frequency })

                // Set time-related fields based on the frequency
                if (
                    frequency === 'daily' ||
                    frequency === 'weekly' ||
                    frequency === 'monthly'
                ) {
                    form.setFieldsValue({
                        start_time_of_day: moment(editingCategory.start_time),
                        end_time_of_day: moment(editingCategory.end_time),
                    })
                }
                if (frequency === 'weekly') {
                    const dayValue = editingCategory.day_of_week

                    const dayOption = WEEKDAY_OPTIONS.find(
                        (day) => day.value === parseInt(dayValue)
                    )
                    if (dayOption) {
                        form.setFieldsValue({
                            sday: dayValue,
                        })
                    }
                }

                if (frequency === 'monthly') {
                    form.setFieldsValue({
                        date: editingCategory.day_of_month.toString(),
                    })
                }

                if (frequency === 'date_specific') {
                    form.setFieldsValue({
                        specificdate: moment(editingCategory.start_time),
                        start_time_of_day: moment(editingCategory.start_time),
                        end_time_of_day: moment(editingCategory.end_time),
                    })
                }

                if (frequency === 'date_range') {
                    form.setFieldsValue({
                        daterange: [
                            moment(editingCategory.start_date_range),
                            moment(editingCategory.end_date_range),
                        ],
                        start_time_of_day: moment(editingCategory.start_time),
                        end_time_of_day: moment(editingCategory.end_time),
                    })
                }
            } else {
                setSelectedFrequency(undefined)
            }
        } else {
            form.resetFields()
            form.setFieldsValue({ language_id: selectedLanguage })
            setIsRecurring('no')
            setSelectedFrequency(undefined)
        }
    }, [editingCategory, form, selectedLanguage])

    const validateCategoryName = (name, languageId) => {
        if (editingCategory) return true

        if (existingCategories[languageId]) {
            return !existingCategories[languageId].includes(name.toLowerCase())
        }
        return true
    }

    const handleSubmit = async () => {
        try {
            const values = await form.validateFields()

            if (
                !editingCategory &&
                !validateCategoryName(values.category_name, values.language_id)
            ) {
                // setIsCategoryNameValid(false)
                return
            }

            const payload = {
                language_id: values.language_id,
                snippet_category_name: values.category_name,
                local_name: values.local_name || '',
                is_daily:
                    selectedFrequency === 'daily' && isRecurring === 'yes',
                is_weekly:
                    selectedFrequency === 'weekly' && isRecurring === 'yes',
                is_monthly:
                    selectedFrequency === 'monthly' && isRecurring === 'yes',
                is_date_specific:
                    selectedFrequency === 'date_specific' &&
                    isRecurring === 'yes',
                is_date_range:
                    selectedFrequency === 'date_range' && isRecurring === 'yes',
                day_of_week: 0,
                day_of_month: 0,
                month_of_year: 0,
                start_time: null,
                end_time: null,
                start_date_range: null,
                end_date_range: null,
            }

            if (selectedFrequency === 'daily' && isRecurring === 'yes') {
                payload.start_time = values.start_time_of_day.format(
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                )
                payload.end_time = values.end_time_of_day.format(
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                )
            } else if (
                selectedFrequency === 'weekly' &&
                isRecurring === 'yes'
            ) {
                payload.day_of_week = parseInt(values.sday)
                payload.start_time = values.start_time_of_day.format(
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                )
                payload.end_time = values.end_time_of_day.format(
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                )
            } else if (
                selectedFrequency === 'monthly' &&
                isRecurring === 'yes'
            ) {
                payload.day_of_month = parseInt(values.date)
                payload.start_time = values.start_time_of_day.format(
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                )
                payload.end_time = values.end_time_of_day.format(
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                )
            } else if (
                selectedFrequency === 'date_specific' &&
                isRecurring === 'yes'
            ) {
                const dateFormat = 'YYYY-MM-DD'
                const timeFormat = 'HH:mm:ss.SSSZ'
                payload.start_time = `${values.specificdate.format(
                    dateFormat
                )}T${values.start_time_of_day.format(timeFormat)}`
                payload.end_time = `${values.specificdate.format(
                    dateFormat
                )}T${values.end_time_of_day.format(timeFormat)}`
                payload.day_of_month = values.specificdate.date()
                payload.month_of_year = values.specificdate.month() + 1
            } else if (
                selectedFrequency === 'date_range' &&
                isRecurring === 'yes'
            ) {
                const dateFormat = 'YYYY-MM-DD'
                const timeFormat = 'HH:mm:ss.SSSZ'

                payload.start_time = `${values.daterange[0].format(
                    dateFormat
                )}T${values.start_time_of_day.format(timeFormat)}`
                payload.end_time = `${values.daterange[1].format(
                    dateFormat
                )}T${values.end_time_of_day.format(timeFormat)}`
                payload.start_date_range = values.daterange[0].format(
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                )
                payload.end_date_range = values.daterange[1].format(
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                )
            }

            if (editingCategory) {
                const updatePayload = {
                    ...payload,
                    created_at: editingCategory.created_at,
                    snippet_category_id: editingCategory.snippet_category_id,
                }
                await updateSnippetCategory(
                    editingCategory.snippet_category_id,
                    updatePayload,
                    token
                )
            } else {
                await createSnippetCategory(payload, token)
            }

            if (values.type) {
                const recurrenceMap = {
                    daily: 'is_daily',
                    weekly: 'is_weekly',
                    monthly: 'is_monthly',
                    date_specific: 'is_date_specific',
                    date_range: 'is_date_range',
                }
                const recurrenceType = recurrenceMap[values.type]
                onSuccess(values.language_id, recurrenceType)
            }
            handleCancel()
            form.resetFields()
        } catch (error) {
            console.error('Error creating/updating snippet category:', error)
        }
    }

    return (
        <Modal
            open={isModalOpen}
            onCancel={handleCancel}
            onOk={handleSubmit}
            centered
            width={500}
            title={
                <h2 className="modal-title">
                    {editingCategory ? 'Edit Category' : 'Add Category'}
                </h2>
            }
            okText={editingCategory ? 'Update' : 'Save'}
            okButtonProps={{
                style: {
                    backgroundColor: '#1890ff',
                    borderColor: '#1890ff',
                    marginTop: '15px',
                },
            }}
        >
            <hr />
            <Form form={form}>
                <Form.Item
                    label={<span style={{ fontWeight: 550 }}>Language</span>}
                    name="language_id"
                    rules={[
                        {
                            required: true,
                            message: 'Please select language',
                        },
                    ]}
                    labelCol={{ span: 24 }}
                >
                    <Select
                        onChange={handleLanguageChange}
                        style={{ width: '100%' }}
                    >
                        {languageData?.map((option) => (
                            <Select.Option
                                key={option?.language_id}
                                value={option?.language_id}
                            >
                                {option?.language_name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={
                        <span style={{ fontWeight: 550 }}>Category Name</span>
                    }
                    name="category_name"
                    style={{ width: '100%' }}
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message: ' Please enter the Category Name',
                        },
                        {
                            validator: (_, value) => {
                                if (
                                    value &&
                                    !validateCategoryName(
                                        value,
                                        form.getFieldValue('language_id')
                                    )
                                ) {
                                    return Promise.reject(
                                        'Category name already exists for this language'
                                    )
                                }
                                return Promise.resolve()
                            },
                        },
                    ]}
                    labelCol={{ span: 24 }}
                >
                    <Input
                        placeholder="Enter Category Name"
                        onChange={(e) => {
                            setCategoryName(e.target.value)
                        }}
                        style={{ minWidth: '100%' }}
                    />
                </Form.Item>
                <Form.Item
                    label={<span style={{ fontWeight: 550 }}>Local Name</span>}
                    name="local_name"
                    rules={[
                        {
                            required: false,
                            message: ' Please enter the Local Name',
                        },
                    ]}
                    labelCol={{ span: 24 }}
                >
                    <Input
                        placeholder="Enter Local Name"
                        style={{ minWidth: '100%' }}
                    />
                </Form.Item>
                <Form.Item
                    label="Is Recurring"
                    key="is_recurring"
                    style={{ fontWeight: 550 }}
                >
                    <Switch
                        onChange={() =>
                            setIsRecurring((pre) =>
                                pre === 'yes' ? 'no' : 'yes'
                            )
                        }
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        value={isRecurring === 'yes' ? true : false}
                        className="active_status"
                        style={{
                            backgroundColor:
                                isRecurring === 'yes' ? '#1890ff' : undefined,
                        }}
                    />
                </Form.Item>
                {isRecurring === 'yes' && (
                    <>
                        <div>
                            <Form.Item
                                label={
                                    <span style={{ fontWeight: 550 }}>
                                        Recurring Type
                                    </span>
                                }
                                name="type"
                                rules={[
                                    {
                                        message: 'Enter Recurring Type',
                                    },
                                ]}
                                labelCol={{ span: 24 }}
                            >
                                <Select
                                    onChange={handleFrequencyChange}
                                    value={selectedFrequency}
                                    placeholder="Select Frequency"
                                    style={{ minWidth: '100%' }}
                                >
                                    {FREQUENCY_OPTIONS.map((option) => (
                                        <Select.Option
                                            key={option.key}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>

                        {selectedFrequency === 'daily' && (
                            <>
                                <Form.Item
                                    label="Start Time of Day"
                                    name="start_time_of_day"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Select Time',
                                        },
                                    ]}
                                >
                                    <TimePicker
                                        format="HH:mm"
                                        style={{ minWidth: '100%' }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="End Time of Day"
                                    name="end_time_of_day"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Select Time',
                                        },
                                        {
                                            validator: validateEndTime,
                                        },
                                    ]}
                                >
                                    <TimePicker
                                        format="HH:mm"
                                        style={{ minWidth: '100%' }}
                                    />
                                </Form.Item>
                            </>
                        )}

                        {selectedFrequency === 'weekly' && (
                            <>
                                <Form.Item
                                    label="Select Day"
                                    name="sday"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Select Time',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select Day"
                                        style={{ minWidth: '100%' }}
                                    >
                                        {WEEKDAY_OPTIONS.map((option) => (
                                            <Select.Option
                                                key={option.key}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Start Time of Day"
                                    name="start_time_of_day"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Select Time',
                                        },
                                    ]}
                                >
                                    <TimePicker
                                        format="HH:mm"
                                        style={{ minWidth: '100%' }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="End Time of Day"
                                    name="end_time_of_day"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Select Time',
                                        },
                                    ]}
                                >
                                    <TimePicker
                                        format="HH:mm"
                                        style={{ minWidth: '100%' }}
                                    />
                                </Form.Item>
                            </>
                        )}

                        {selectedFrequency === 'monthly' && (
                            <>
                                <Form.Item
                                    label="Select a Date"
                                    name="date"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a date',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="Select Day"
                                        style={{ minWidth: '100%' }}
                                    >
                                        {DATE_OPTIONS.map((option) => (
                                            <Select.Option
                                                key={option.key}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Start Time of Day"
                                    name="start_time_of_day"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Select Time',
                                        },
                                    ]}
                                >
                                    <TimePicker
                                        format="HH:mm"
                                        style={{ minWidth: '100%' }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="End Time of Day"
                                    name="end_time_of_day"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Select Time',
                                        },
                                    ]}
                                >
                                    <TimePicker
                                        format="HH:mm"
                                        style={{ minWidth: '100%' }}
                                    />
                                </Form.Item>
                            </>
                        )}

                        {selectedFrequency === 'date_specific' && (
                            <>
                                <Form.Item
                                    label="Select Specific date"
                                    name="specificdate"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Select Date',
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        format="DD/MM/YYYY"
                                        disabledDate={(currentDate) =>
                                            currentDate &&
                                            currentDate <
                                                moment().startOf('day')
                                        }
                                        style={{ minWidth: '100%' }}
                                    />
                                </Form.Item>
                                {isRecurring && (
                                    <>
                                        <Form.Item
                                            label="Start Time of Day"
                                            name="start_time_of_day"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please Select Time',
                                                },
                                            ]}
                                        >
                                            <TimePicker
                                                format="HH:mm"
                                                style={{ minWidth: '100%' }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="End Time of Day"
                                            name="end_time_of_day"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please Select Time',
                                                },
                                            ]}
                                        >
                                            <TimePicker
                                                format="HH:mm"
                                                style={{ minWidth: '100%' }}
                                            />
                                        </Form.Item>
                                    </>
                                )}
                            </>
                        )}
                        {selectedFrequency === 'date_range' && (
                            <>
                                <Form.Item
                                    label="Select Date range"
                                    name="daterange"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select date range',
                                        },
                                    ]}
                                >
                                    <RangePicker
                                        size="middle"
                                        style={{ minWidth: '100%' }}
                                    />
                                </Form.Item>
                                {isRecurring && (
                                    <>
                                        <Form.Item
                                            label="Start Time of Day"
                                            name="start_time_of_day"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please Select Time',
                                                },
                                            ]}
                                        >
                                            <TimePicker
                                                format="HH:mm"
                                                style={{ minWidth: '100%' }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="End Time of Day"
                                            name="end_time_of_day"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please Select Time',
                                                },
                                            ]}
                                        >
                                            <TimePicker
                                                format="HH:mm"
                                                style={{ minWidth: '100%' }}
                                            />
                                        </Form.Item>
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </Form>
        </Modal>
    )
}
export default AddSnippetCategory

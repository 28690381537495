import { Select, message } from 'antd'
import './NotificationReport.scss'
import DateRange from './DateRange'
import CommonTable from '../CommonTable/CommonTable'
import { getNotificationReport } from '../../AadhanApi'
import Viewicon from '../../assets/view_icon.svg'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'

const NotificationReport = () => {
    const [reportsData, setReportsData] = useState([])
    const [dataLoading, setDataLoading] = useState(false)
    const languageData = useSelector((state) => state.app.languageData)
    const accessToken = useSelector((state) => state.app.accessToken)
    const [languageOption, setLanguageOption] = useState(
        languageData?.[0]?.language_id
    )
    const [messageApi, contextHolder] = message.useMessage()
    const [dates, setDates] = useState({
        startDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        endDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    })
    const addDates = (startDate, endDate) => {
        setDates({ startDate: startDate, endDate: endDate })
    }
    const clearDates = () => {
        setDates({
            startDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            endDat: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        })
    }
    const notificationTableColumns = [
        {
            title: 'TITLE',
            dataIndex: 'custom_title',
            key: 'custom_title',
        },
        {
            title: 'POSTED BY',
            dataIndex: 'post_by',
            key: 'post_by',
        },
        {
            title: 'POSTED DATE',
            dataIndex: 'posted_date',
            key: 'posted_date',
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                    }}
                >
                    {dayjs(record?.posted_date).format('DD MMM YYYY, hh:mm a')}
                </div>
            ),
        },
        {
            title: 'SENT BY',
            dataIndex: 'sent_by',
            key: 'sent_by',
        },
        {
            title: 'SENT DATE',
            dataIndex: 'sent_date',
            key: 'sent_date',
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                    }}
                >
                    {dayjs(record?.sent_date).format('DD MMM YYYY, hh:mm a')}
                </div>
            ),
        },
        {
            title: 'VIEW',
            dataIndex: 'dynamic_url',
            key: 'dynamic_url',
            render: (_, record) => (
                <a
                    href={record.dynamic_url}
                    target="blank"
                    className="view-icon"
                >
                    <img
                        style={{ width: '1rem' }}
                        alt="ViewIcon"
                        src={Viewicon}
                    />
                </a>
            ),
        },
    ]
    const { Option } = Select

    useEffect(() => {
        if (languageOption) {
            getNotificationReportDetails(languageOption, 1, 500)
        }
    }, [languageOption, dates])

    const getNotificationReportDetails = async (
        languageOption,
        page,
        limit
    ) => {
        const startDateFormat = dayjs(dates?.startDate).format('YYYY-MM-DD')
        const endDateFormat = dayjs(dates?.endDate).format('YYYY-MM-DD')
        setDataLoading(true)
        try {
            const response = await getNotificationReport(
                languageOption,
                page,
                limit,
                startDateFormat,
                endDateFormat,
                accessToken
            )
            setReportsData(response.data.notification_report)
            setDataLoading(false)
        } catch (err) {
            setReportsData([])
            setDataLoading(false)
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to get reports',
                duration: 5,
            })
        }
    }

    return (
        <div className="report-container">
            <div className="content-header">
                <h2>Notification Report</h2>
                <div className="date-picker-container">
                    <span style={{ marginRight: '5px' }}>Date Range :</span>
                    <DateRange addDates={addDates} clearDates={clearDates} />
                </div>
            </div>
            <div className="manage-content-inputs">
                <Select
                    value={languageOption}
                    onChange={(val) => setLanguageOption(val)}
                    style={{ width: 200 }}
                >
                    {languageData.map((option) => {
                        return (
                            <Option
                                key={option?.language_id}
                                value={option?.language_id}
                            >
                                {option?.language_name}
                            </Option>
                        )
                    })}
                </Select>
            </div>
            <CommonTable
                columns={notificationTableColumns}
                data={reportsData}
                dataLoading={dataLoading}
                disablePagination={true}
            />
        </div>
    )
}

export default NotificationReport

import React, { useEffect, useState } from 'react'
import { Button, Image, Select, Table } from 'antd'
import { EditOutlined, EnvironmentOutlined, LeftOutlined, ReloadOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router'
import DateRangePicker from './DateRangePicker'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { getUgcContent } from '../../AadhanApi'

const SponsoredReport = () => {
    const navigate = useNavigate()
    let optionsString = localStorage.getItem('options')
    let options = JSON.parse(optionsString)
    const accessToken = useSelector((state) => state.app.accessToken)
    const locations = useSelector((state) => state.app.locations)

    const defaultFilters = {
        stringerType: 3,
        status: 'approved',
        paymentStatus: 'paid',
        state: undefined,
    }

    const storedFilters = JSON.parse(localStorage.getItem('ugcFilters')) || defaultFilters
    const storedDates = JSON.parse(localStorage.getItem('ugcDates')) || {
        startDate: dayjs().format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD'),
    }

    const [loading, setLoading] = useState(false)
    const [filters, setFilters] = useState(storedFilters)
    const [dates, setDates] = useState(storedDates)
    const [data, setData] = useState([])

    const setAndStoreFilters = (newFilters) => {
        setFilters(newFilters)
        localStorage.setItem('ugcFilters', JSON.stringify(newFilters))
    }

    const setAndStoreDates = (newDates) => {
        setDates(newDates)
        localStorage.setItem('ugcDates', JSON.stringify(newDates))
    }

    const handleFilterChange = (key, value) => {
        setAndStoreFilters({ ...filters, [key]: value })
    }

    const addDates = (startDate, endDate) => {
        setAndStoreDates({ startDate, endDate })
    }

    const clearDates = () => {
        const updatedDates = {
            startDate: dayjs().format('YYYY-MM-DD'),
            endDate: dayjs().format('YYYY-MM-DD'),
        }
        setAndStoreDates(updatedDates)
    }

    const getData = async () => {
        try {
            setLoading(true)
            const response = await getUgcContent(
                options?.language,
                filters.status,
                true,
                dates.startDate,
                dates.endDate,
                filters.state,
                accessToken,
                '',
                '',
                false,
                filters.stringerType
            )
            setData(response.data)
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getData()
    }, [filters, dates])

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <div
                    onClick={() => navigate(-1)}
                    style={{
                        color: '#007BFF',
                        fontSize: '18px',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        transition: 'color 0.3s ease',
                    }}
                    onMouseEnter={(e) => {
                        e.target.style.color = '#0056b3'
                    }}
                    onMouseLeave={(e) => {
                        e.target.style.color = '#007BFF'
                    }}
                >
                    <LeftOutlined />
                </div>

                <Select
                    placeholder="Status"
                    style={{ width: 130 }}
                    value={filters.status}
                    onChange={(value) => handleFilterChange('status', value)}
                >
                    <Select.Option value="approved">Approved</Select.Option>
                    <Select.Option value="submit">Submitted</Select.Option>
                </Select>

                {filters?.status==="approved" && <Select
                    placeholder="Payment Status"
                    style={{ width: 130 }}
                    value={filters.paymentStatus}
                    onChange={(value) =>
                        handleFilterChange('paymentStatus', value)
                    }
                >
                    <Select.Option value="paid">Paid</Select.Option>
                    <Select.Option value="pending">Pending</Select.Option>
                    <Select.Option value="overdue">Overdue</Select.Option>
                </Select>}

                <Select
                    value={filters.stringerType}
                    placeholder="Stringer Type"
                    style={{ width: 130 }}
                    onChange={(value) => handleFilterChange('stringerType', value)}
                >
                    <Select.Option value={3}>Staff Reporter</Select.Option>
                    <Select.Option value={1}>App Stringer</Select.Option>
                    <Select.Option value={4}>Citizen Stringer</Select.Option>
                </Select>

                <Select
                    allowClear
                    placeholder="Select State"
                    value={filters.state}
                    showSearch
                    onChange={(value) => handleFilterChange('state', value)}
                    style={{ width: 170 }}
                >
                    {locations.map((location) => (
                        <Select.Option key={location.state_id} value={location.state_id}>
                            {location.name}
                        </Select.Option>
                    ))}
                </Select>

                <Button
                    onClick={getData}
                    icon={<ReloadOutlined />}
                    type="primary"
                    ghost
                    style={{ marginLeft: 'auto' }}
                >
                    Refresh
                </Button>

                <div style={{ width: 200 }}>
                    <DateRangePicker
                        addDates={addDates}
                        clearDates={clearDates}
                        dates={dates}
                    />
                </div>
            </div>
            <hr />
            <Table
                columns={[
                    {
                        title: 'Title',
                        dataIndex: 'title',
                        key: 'title',
                        width: '50%',
                        render: (_, record) => (
                            <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
                                <Image
                                    src={record?.image_url?.[0]}
                                    style={{
                                        width: '57px',
                                        height: '57px',
                                        borderRadius: '5px',
                                        objectFit: 'cover',
                                        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
                                        transition: 'transform 0.2s',
                                    }}
                                    onMouseOver={(e) =>
                                        (e.currentTarget.style.transform = 'scale(1.05)')
                                    }
                                    onMouseOut={(e) =>
                                        (e.currentTarget.style.transform = 'scale(1)')
                                    }
                                />
                                <p>{record?.title}</p>
                            </div>
                        ),
                    },
                    {
                        title: 'Stringer Name',
                        dataIndex: 'uploaded_by',
                        key: 'uploaded_by',
                    },
                    {
                        title: 'Location',
                        key: 'location',
                        render: (_, record) => (
                          <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                            <EnvironmentOutlined style={{ color: '#1890ff', fontSize: '16px' }} />
                            <div><div>
                              {record?.state_name ? `${record.state_name}` : ''}
                             
                            </div>
                            <div> {record?.district_name ? ` ${record.district_name}` : ''}</div></div>
                          </div>
                        )
                      },
                    {
                        title: 'Action',
                        key: 'action',
                        render: (_, record) => (
                            <Button
                                type="primary"
                                onClick={() =>
                                    navigate(
                                        `/home/editorDashboard/sponsored-content/${record?.content_id}`
                                    )
                                }
                                icon={<EditOutlined />}
                            ></Button>
                        ),
                    },
                ]}
                dataSource={data}
                loading={loading}
                pagination={false}
            />
        </div>
    )
}

export default SponsoredReport

import {
    Button,
    Form,
    Input,
    message,
    Select,
} from 'antd'
import { useSelector } from 'react-redux'
import { createReporter, getReporterDetails, updateReporter } from '../../AadhanApi'
import { useLocation, useNavigate, useParams } from 'react-router'
import { useEffect, useState } from 'react'
import './StaffStringers.scss'
import { LeftOutlined } from '@ant-design/icons'
const StaffStringersForm = () => {
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const location = useLocation()
    const { id, emp } = useParams()
    const language = {
        '654b0c51d3be6cda3e0e7e96': 4,
        '654b0c4ed3be6cda3e0e7e79': 2,
        '654b0c51d3be6cda3e0e7e97': 2,
    }
    const accessToken = useSelector((state) => state.app.accessToken)
    const locationsData = useSelector((state) => state.app.locations)
    const allDistricts = useSelector((state) => state.app.districts)
    const [selectedState, setSelectedState] = useState(undefined)
    const [data, setData] = useState({})
    const onFinish = async (values) => {
        try {
            let req = {
                ...data,
                full_name: values?.fullName,
                languages: [
                    language[values?.state] !== undefined
                        ? language[values?.state]
                        : 3,
                ],
                stringer_type: 3,
                on_board_date: undefined,
                inactive_date: undefined,
                is_reporter:true,
                reporter_information: {
                    ...data?.reporter_information,
                    state: locationsData?.filter(
                        (e) => e.state_id === values?.state
                    )?.[0]?.name,
                    district: allDistricts?.[values?.state]?.filter(
                        (e) => e.district_id === values?.district
                    )?.[0]?.name,
                    compensation: false,
                    pen_name: values?.penName,
                    state_id: values?.state,
                    district_id: values?.district,
                },
            };
    
            if (id === "onboard-employee-to-stringer" || id==="onboard-new-stringer") {
                const stringerResponse = await createReporter(
                    req,
                    accessToken
                );
                if (stringerResponse.status === 200) {
                    navigate(-1);
                    form.resetFields();
                    message.success("Reporter created successfully!");
                } else {
                    message.error("Failed to create reporter. Please try again.");
                }
            } else {
                const stringerResponse = await updateReporter(
                    req,
                    req?.user_id,
                    accessToken
                );
                if (stringerResponse.status === 200) {
                    navigate(-1);
                    form.resetFields();
                    message.success("Reporter updated successfully!");
                } else {
                    message.error("Failed to update reporter. Please try again.");
                }
            }
        } catch (error) {
            console.error("Error:", error);
            message.error("An error occurred while updating the reporter. Please try again.");
        }
    };
    const allEmployees = useSelector((state) => state.app.employees)
    const updateFormData = async (contentId, accessToken) => {
        let response = {}
        if (id === 'onboard-employee-to-stringer') {
            const val = allEmployees?.find(
                (e) => e?.employee_id === parseInt(emp)
            )
            if (val) {
                const { employee_name, email_id, mobile_number } = val

                response.data = {
                    full_name: employee_name,
                    email_id,
                    mobile_number,
                }
            }
            
        }
        else if(id==="onboard-new-stringer"){
            response.data = {
                mobile_number:emp
            }
        }
         else {
            response = await getReporterDetails(contentId, accessToken)
           
        }
        if (response?.data) {
            setData(response?.data)
            setSelectedState(response?.data?.reporter_information?.state_id)
            form.setFieldsValue({
                fullName: response.data?.full_name,
                penName: response?.data?.reporter_information?.pen_name,
                phoneNumber: response.data?.mobile_number,
                state: response?.data?.reporter_information?.state_id
                    ? response?.data?.reporter_information?.state_id
                    : undefined,
                district: response.data?.reporter_information?.district
                    ? response.data?.reporter_information?.district
                    : undefined,
            })
        }
    }

    useEffect(() => {
        if (id) {
            updateFormData(id, accessToken)
        } else {
        }
    }, [id])

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }
    const onStateChange = (value, option) => {
        setSelectedState(value)
        form.setFieldValue('district', undefined)
    }
    return (
        <div className="staffStringers_container" style={{ padding: '0 1rem', backgroundColor: '#f9f9f9', borderRadius: '8px', margin: '0' }}>
        <h5 style={{ display: 'flex', alignItems: 'center', marginBottom: '.5rem', fontSize: '20px' }}>
            <span 
                className="navigate_back" 
                onClick={() => navigate(-1)} 
                style={{ marginRight: '10px', cursor: 'pointer', color: '#1890ff' }}
            >
                <LeftOutlined />
            </span>
            {(location.pathname.includes('onboard-employee-to-stringer') || location.pathname.includes('onboard-new-stringer'))
                ? 'Add Staff Stringer'
                : 'Edit Staff Stringer'}
        </h5>
        <hr style={{ marginBottom: '1.5rem' }} />
        <div style={{ padding: '0 1rem' }}>
            <Form
                id="form"
                form={form}
                name="employeeForm"
                scrollToFirstError
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 18 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Full Name"
                    name="fullName"
                    rules={[{ required: true, message: 'Please enter value' }]}
                    style={{ marginBottom: '1rem' }}
                >
                    <Input maxLength={25} placeholder="Enter full name" style={{ width: '100%' }} />
                </Form.Item>
    
                <Form.Item
                    label="Nick Name"
                    name="penName"
                    style={{ marginBottom: '1rem' }}
                >
                    <Input placeholder="Enter nickname" style={{ width: '100%' }} />
                </Form.Item>
    
                <Form.Item 
                    label="Phone Number" 
                    name="phoneNumber" 
                    style={{ marginBottom: '1rem' }}
                >
                    <Input disabled style={{ width: '100%' }} />
                </Form.Item>
    
                <Form.Item name="stringer_type" label="Stringer Type" style={{ marginBottom: '1rem' }}>
                    <Input defaultValue={"Staff Stringer"} style={{width:200}}
                    disabled
                    />
                        
                    
                </Form.Item>
    
                <Form.Item rules={[{ required: true, message: 'Please select state' }]} name="state" label="State" style={{ marginBottom: '1rem' }}>
                    {locationsData && (
                        <Select
                            style={{ width: '200px' }}
                            placeholder="Select State"
                            onChange={(value, option) => onStateChange(value, option)}
                            showSearch
                            filterOption={(input, option) =>
                                option?.children?.toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {locationsData?.map((option) => (
                                <Select.Option key={option?.state_id} value={option?.state_id}>
                                    {option?.name}
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                </Form.Item>
    
                <Form.Item label="District" name="district" style={{ marginBottom: '1rem' }}>
                    <Select
                        showSearch
                        filterOption={(input, option) =>
                            option?.children?.toLowerCase().includes(input.toLowerCase())
                        }
                        placeholder="Select District"
                        style={{ width: '200px' }}
                    >
                        {allDistricts?.[selectedState]?.map((option) => (
                            <Select.Option key={option?.district_id} value={option?.district_id}>
                                {option?.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
    
                <Form.Item
                    wrapperCol={{
                        span: 24,
                        offset: 0,
                        style: { marginTop: '1%' },
                    }}
                >
                    <Button
                        className="bg-color width-150"
                        style={{ marginRight: '1.5rem' }}
                        ghost
                        type="primary"
                        onClick={() => navigate(-1)}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="bg-color width-150"
                        type="primary"
                        htmlType="submit"
                    >
                       {(id==="onboard-new-stringer" || id==="onboard-employee-to-stringer") ? "Save" : "Update"}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    </div>
    
    )
}
export default StaffStringersForm

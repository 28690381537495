import {
    DeleteFilled,
    PictureFilled,
    PlusOutlined,
    VideoCameraFilled,
} from '@ant-design/icons'
import {
    AutoComplete,
    Button,
    Form,
    Input,
    message,
    Radio,
    Select,
    Spin,
    Tag,
} from 'antd'
import dayjs from 'dayjs'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useNavigate } from 'react-router'
import {
    createShortNews,
    getHashtagTableData,
    updateShortNews,
    getSubCategories,
    getSimilarContent,
    createReporterContent,
} from '../../../AadhanApi'
import './AddShortNewsForm.scss'
import {
    expiryHoursOptions,
    isPublish,
    isSubmitAndDraft,
} from '../../App.constants'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import generateKeyWord from '../../AIResponseFilter/KeywordsResponse'
import { checkContentForDay } from '../../CheckContentForDay/checkContentForDay'
import Speaker from '../../Speaker'
import { Option } from 'antd/es/mentions'
import { useDispatch } from 'react-redux'
import { addHashtags } from '../../../store/slice/AppSlice'
import EditCropper from '../EditCropper'
import SpellCheckBox from '../../SpellCheckBox'
const AddShortNewsForm = forwardRef(
    (
        {
            setIsVideoAdding,
            handleDeleteImage,
            handleUpdateImage,
            images,
            setIsImageAdding,
            imagePath,
            constituency,
            setConstituency,
            generate,
            isLoading,
            setIsLoading,
            setGenerate,
            setIsImageAddin,
            titleChange,
            descriptionChange,
            initialFormData,
            languageData,
            categories,
            accessToken,
            mode,
            hyperlinks,
            employeeLanguage,
            contentId,
            hashTagId,
            setHashTagId,
            code,
            contentStatus,
            languageOption,
            setLanguageOption,
            pro,
            anti,
            videoPath,
            resetWriterDashboard,
            selectedCategory,
            setSelectedCategory,
        },
        ref
    ) => {
        const [form] = Form.useForm()
        const dispatch = useDispatch()
        const userPermissions = useSelector(
            (state) => state.app.userPermissions
        )
        const [selectedStates, setSelectedStates] = useState([])
        const allHashTags = useSelector((state) => state.app.hashtags)
        const employeeId = useSelector((state) => state.app.employeeId)
        const roleId = localStorage.getItem('ROLE_ID')
        const employeeName = useSelector((state) => state.app.employeeName)
        const [shortDescription, setShortDescription] = useState(
            initialFormData?.shortDescription
        )
        const [allState, setAllState] = useState(false)
        const [remainingCharCount, setRemaingCharCount] = useState(
            languageOption === 3 ? 500 : 400
        )
        const [hashTagOptions, setHashtagOptions] = useState([])
        const [allHashTagOptions, setAllHashtagOptions] = useState([])
        const [keywords, setKeywords] = useState([])
        const [keywords_g, setKeywords_g] = useState(false)
        const [keywordInput, setKeywordInput] = useState('')
        const [hashData, setHashData] = useState([])
        const [reviewTime, setReviewTime] = useState(undefined)
        const allDistricts = useSelector((state) => state.app.districts)
        const allConstituencies = useSelector(
            (state) => state.app.constituencies
        )
        const [loading, setLoading] = useState(false)
        const [similarContents, setSimilarContents] = useState({
            data: [],
            api: false,
        })
        const [hashTagValue, setHashTagValue] = useState('')
        const navigate = useNavigate()
        const [titleError, setTitleError] = useState()
        const [title, setTitle] = useState('')
        const location = useLocation()
        const locationsData = useSelector((state) => state.app.locations)
        const [selectedStateId, setSelectedStateId] = useState(
            initialFormData?.state_id
        )
        const [subCategory, setSubCategory] = useState([])
        const [originalSubcategory, setOriginalSubcategory] = useState([])

        useEffect(() => {
            handleCategory(initialFormData?.category)
        }, [locationsData])
        useEffect(() => {
            const currentTimestampMilliseconds = Date.now()
            const istTimestampMilliseconds = currentTimestampMilliseconds
            const istTimestamp = Math.floor(istTimestampMilliseconds / 1000)
            setReviewTime(istTimestamp)
        }, [location?.pathname])
        const stateShowId = [
            20, 26, 30, 31, 28, 8, 10, 11, 12, 13, 33, 43, 34, 35, 32,
        ]
        async function generateKeyWords(title, description) {
            if (title !== '' || description !== '') {
                setGenerate(true)
                const data = await generateKeyWord(
                    title,
                    description,
                    languageOption
                )
                let newKeywords = []
                newKeywords = data?.keywords?.split(',')
                const uniqueArray = [
                    ...new Set([
                        ...newKeywords?.map((keyword) => keyword?.trim()), // Trim each keyword
                    ]),
                ]
                const cleanKeywords = uniqueArray.filter(
                    (keyword) => keyword !== '' && !keyword?.includes('\n')
                )
                setGenerate(false)
                if (cleanKeywords.length === 0) {
                    setKeywords([])
                    setKeywords_g(true)
                    return { data: [], entity: '', iab_: '' }
                } else {
                    setKeywords_g(false)
                    setKeywords(cleanKeywords)
                    return {
                        data: cleanKeywords,
                        entity: data?.entities,
                        iab_: data?.iab,
                    }
                }
            }
        }
        useEffect(() => {
            form.setFieldsValue(initialFormData)
            changeDescriptionValue(initialFormData?.shortDescription ?? '')
            handleTitleChange(initialFormData?.title)
            handleFindSimilar(
                initialFormData?.title,
                initialFormData?.shortDescription
            )
            handleEnglishTitleChange(initialFormData?.englishTitle)

            if (
                initialFormData?.keywords !== '' &&
                initialFormData?.keywords?.split(',')?.length > 0
            ) {
            }
            if (
                initialFormData.category &&
                initialFormData.category !== undefined
            ) {
                handleCategory(initialFormData?.category)
            }
            setKeywords(
                initialFormData?.keywords !== '' &&
                    initialFormData?.keywords !== undefined
                    ? initialFormData?.keywords.split(',')
                    : []
            )
        }, [form, initialFormData, locationsData])
        useEffect(() => {
            setSelectedStateId(initialFormData?.state_id)
            if (
                initialFormData?.state?.length === 0 &&
                initialFormData?.state_id?.length === 0
            ) {
                setAllState(true)
                handleGroupChange(['all'])
                return
            }
            setSelectedStates(
                initialFormData?.state_id?.map((e, index) => {
                    return { id: e, name: initialFormData?.state?.[index] }
                })
            )
        }, [initialFormData, locationsData, true])
        useEffect(() => {
            handleLanguageChange(
                initialFormData?.language !== '' ? initialFormData?.language : 2
            )
        }, [location.pathname])
        useEffect(() => {
            updateHashtag()
        }, [languageOption])
        const createRequest = (values, type) => {
            let categoryName = categories
                ?.map((ele) => {
                    if (ele.category_id === values.category) {
                        return ele.category_name
                    }
                })
                .filter((Undefined) => Undefined !== undefined)?.[0]

            if (selectedStateId?.length === 0 && !selectedStateId) {
                setSelectedStateId(initialFormData.state_id)
            }
            const checkforday = checkContentForDay(title, languageOption)
            const resposeObject = {
                title: values?.title
                    ?.replace(/\s+/g, ' ') // Replace multiple spaces with a single space
                    .replace(
                        /(?<!['"ââ])\s*([,.!?;:])(?!\d|['"ââ])\s*(?=\S)/g,
                        '$1 '
                    ) // Add space after punctuation if not preceded by quote and not followed by a digit or quote
                    .replace(/(?<!['"ââ])\s*([,.!?;:])(?!\d|['"ââ])$/, '$1') // Remove space before punctuation at the end if not preceded by quote and not followed by a digit or quote
                    .replace(/([â¹$])\s*(\d)/g, '$1$2') // Remove space between currency symbol and number
                    .replace(/(\d)\s+(\d)/g, '$1$2') // Remove space between digits
                    .replace(/(?<!\.)\.\s*(?=\.)/g, '.') // Ensure continuous dots remain intact
                    .replace(/(\.{2,})(?=\S)/g, '$1') // Leave continuous dots intact
                    .trim(), // Trim leading and trailing spaces
                category_id: values?.category,
                expiry_hours:
                    (values?.category === 8) |
                    (values?.category === 20) |
                    (values?.category === 32)
                        ? 6
                        : 0,
                content_type: 'shortnews',
                published_date: dayjs(new Date()).format(
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                ),
                dynamic_url: '',
                is_active: true,
                content_status: type,
                language_id: languageOption,
                description:
                    !values?.shortDescription.includes('*') &&
                    !values?.shortDescription.includes('**')
                        ? (() => {
                              // Step 1: Capture the URLs before applying any formatting
                              const urls =
                                  values?.shortDescription.match(
                                      /\bhttps?:\/\/\S+/g
                                  ) || []

                              // Step 2: Replace the URLs with a temporary unique marker, while keeping them intact
                              let formattedDescription =
                                  values?.shortDescription
                                      .replace(
                                          /\bhttps?:\/\/\S+/g,
                                          (url, index) => {
                                              return `{{undefined}}` // Replace each URL with a unique placeholder
                                          }
                                      )
                                      // Step 3: Apply space and punctuation formatting to the rest of the text
                                      .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
                                      .replace(
                                          /(?<!['"ââ])\s*([,.!?;:])(?!\d|['"ââ])\s*(?=\S)/g,
                                          '$1 '
                                      ) // Add space after punctuation if not preceded by quote and not followed by a digit or quote
                                      .replace(
                                          /(?<!['"ââ])\s*([,.!?;:])(?!\d|['"ââ])$/,
                                          '$1'
                                      ) // Remove space before punctuation at the end if not preceded by quote and not followed by a digit or quote
                                      .replace(/([â¹$])\s*(\d)/g, '$1$2') // Remove space between currency symbol and number
                                      .replace(/(\d)\s+(\d)/g, '$1$2') // Remove space between digits
                                      .replace(/(?<!\.)\.\s*(?=\.)/g, '.') // Ensure continuous dots remain intact
                                      .replace(/(\.{2,})(?=\S)/g, '$1') // Leave continuous dots intact

                              // Step 4: Restore the URLs back to their original positions from the placeholders
                              urls.forEach((url, index) => {
                                  formattedDescription =
                                      formattedDescription.replace(
                                          `{{undefined}}`,
                                          url
                                      )
                              })

                              // Step 5: Trim leading and trailing spaces
                              return formattedDescription.trim()
                          })()
                        : values?.shortDescription,
                stringer_type: 0,
                code,
                yt_video_url: videoPath?.yt_url,
                state:
                    allState === true
                        ? ''
                        : selectedStates?.map((e) => e.id)?.join(','),
                constituency_ids: initialFormData?.constituency_ids,
                constituency_names: initialFormData?.assemblyconstituencies,
                district: initialFormData?.district_id,
                mandal: initialFormData?.mandal_id,
                district_name: initialFormData?.districts,
                mandal_name: initialFormData?.mandal,
                state_name:
                    allState === true ? [] : selectedStates?.map((e) => e.name),
                gender: values?.gender,
                city: '',
                stream_video_url: location?.pathname?.includes(
                    'home/reporter-dashboard'
                )
                    ? videoPath?.stream_url
                        ? [videoPath.stream_url]
                        : []
                    : videoPath?.stream_url,

                mp4_video_url:
                    values.media === 'otherLink' ? values?.otherLink : '',
                image_url_16_9: '',
                image_url_3_4: '',
                thumbnailimage_url: '',
                hashtag_id: hashTagId,
                hashtag_name: hashTagValue,
                keywords: '',
                entities: '',
                iab_category: '',
                disturbing_visuals: values?.disturbingVisuals,
                left_color: '',
                right_color: '',
                upvote: 0,
                linked_content_id: '',
                uploaded_by_name:
                    initialFormData?.uploadedByName || employeeName,
                proof_read_by_name: employeeName,
                assigned_to: initialFormData?.assigned_to,
                assigned_at: initialFormData?.assigned_at,
                category_name: categoryName,
                hyper_links: hyperlinks,
            }

            if (location?.pathname?.includes('home/reporter-dashboard')) {
                let temp = allConstituencies?.[selectedStateId]?.filter(
                    (e) => e.assembly_constituency_id === constituency
                )?.[0]?.district_id
                let temp2 = allDistricts?.[selectedStateId]?.find(
                    (e) => e.district_id === temp
                )
                resposeObject['district_name'] = temp2?.name
                    ? [temp2?.name]
                    : []
                resposeObject['district'] = temp2?.district_id
                resposeObject['image_url'] = images
                resposeObject['created_type'] = 'reporter'
                resposeObject['stringer_type'] = JSON.parse(
                    localStorage.getItem('STRINGER_TYPE')
                )
                resposeObject['constituency_ids'] = [constituency]
                resposeObject['constituency_names'] = allConstituencies?.[
                    selectedStateId
                ]?.filter(
                    (e) => e.assembly_constituency_id === constituency
                )?.[0]?.name
                    ? [
                          allConstituencies[selectedStateId]?.filter(
                              (e) => e.assembly_constituency_id === constituency
                          )?.[0]?.name,
                      ]
                    : []
            } else {
                resposeObject['image_urls'] = images
            }
            if (checkforday?.today || checkforday?.tomorrow) {
                resposeObject['expiry_hours'] = checkforday?.expiry
            }
            if (!subCategory.find((item) => item?.pro === true)) {
                resposeObject['sub_category_id'] = null

                resposeObject['sub_category_name'] = null
            } else {
                if (!subCategory.find((item) => item?.pro === true)) {
                    resposeObject['sub_category_id'] = null

                    resposeObject['sub_category_name'] = null
                } else {
                    const selectedSubcategory = subCategory.find(
                        (item) => item?.pro === true
                    )
                    resposeObject['sub_category_id'] = selectedSubcategory
                        ? selectedSubcategory.sub_category_id
                        : ''

                    resposeObject['sub_category_name'] =
                        selectedSubcategory.sub_category_name
                            ? selectedSubcategory?.sub_category_name
                            : ''
                }
            }
            if (!subCategory.find((item) => item?.anti === true)) {
                resposeObject['anti_sub_category_id'] = null
                resposeObject['anti_sub_category_name'] = null
                return resposeObject
            } else {
                if (!subCategory.find((item) => item?.anti === true)) {
                    resposeObject['anti_sub_category_id'] = null
                    resposeObject['anti_sub_category_name'] = null
                    return resposeObject
                } else {
                    const selectedSubcategory = subCategory.find(
                        (item) => item?.anti === true
                    )
                    resposeObject['anti_sub_category_id'] = selectedSubcategory
                        ? selectedSubcategory.sub_category_id
                        : ''

                    resposeObject['anti_sub_category_name'] =
                        selectedSubcategory.sub_category_name
                            ? selectedSubcategory?.sub_category_name
                            : ''
                    return resposeObject
                }
            }
        }
        const handleSave = async (type) => {
            setIsLoading(true)
            try {
                const validation = await form.validateFields([
                    'title',
                    'language',
                    'category',
                    'expiryHours',
                    'state',
                    'shortDescription',
                    'image',
                    'constituency',
                    'multimedia',
                    'state',
                    'stateValue',
                ])

                if (validation?.errorFields?.length > 0) {
                    setIsLoading(false)
                    return
                }
            } catch (e) {
                setIsLoading(false)
                return
            }
            const request = await createRequest(
                { ...form.getFieldsValue() },
                type
            )
            if (mode === 'edit') {
                request.updated_by = employeeId
                request.updated_date = dayjs().format(
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                )
                request.unix_uploaded_date = initialFormData?.unix_uploaded_date
                request.unix_expiry_date = initialFormData?.unix_expiry_date
                request.uploaded_by = initialFormData?.uploadedBy
                request.uploaded_date = initialFormData?.uploadedDate
                try {
                    const shortNewsReponse = await updateShortNews(
                        request,
                        contentId,
                        accessToken
                    )
                    if (shortNewsReponse?.status === 200) {
                        setIsLoading(false)
                        if (
                            location?.pathname?.includes('writer-dashboard') ||
                            location?.pathname?.includes('reporter-dashboard')
                        ) {
                            resetWriterDashboard()
                            message.success('short news updated successfully')
                            return
                        }
                        message.success('short news updated successfully')
                        navigate('/home/shortNews')
                    }
                } catch {
                    message.error('failed to update short news')
                    setIsLoading(false)
                }
            } else if (mode === 'add') {
                request.uploaded_by = location?.pathname?.includes(
                    'home/reporter-dashboard'
                )
                    ? localStorage?.getItem('USER_ID').trim()
                    : employeeId
                request.updated_by = 0
                request.uploaded_date = dayjs().format(
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                )
                try {
                    const shortNewsReponse = location?.pathname?.includes(
                        'home/reporter-dashboard'
                    )
                        ? await createReporterContent(request, accessToken)
                        : await createShortNews(request, accessToken)
                    if (shortNewsReponse?.status === 200) {
                        setIsLoading(false)
                        if (
                            location?.pathname?.includes('writer-dashboard') ||
                            location?.pathname?.includes('reporter-dashboard')
                        ) {
                            resetWriterDashboard()
                            message.success('short news added successfully')
                            return
                        }
                        navigate('/home/shortNews')
                        message.success('short news added successfully')
                    }
                } catch {
                    message.error('failed to create short news')
                    setIsLoading(false)
                }
                setIsLoading(false)
            }
        }
        const onFinish = async (values, publish) => {
            setIsLoading(true)
            const request = await createRequest(values)
            if (true) {
                request.proof_read_by = employeeId
                request.proof_read_date = dayjs(values?.publish).format(
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                )
                request.is_proof_read = true
            }

            if (mode === 'edit' || mode === 'review') {
                request.content_status = 'approved'
                if (
                    initialFormData?.review_start_time !== '' &&
                    initialFormData?.review_start_time
                ) {
                    // Check if review_start_time is an integer
                    const isTimestamp =
                        Number.isInteger(initialFormData?.review_start_time) &&
                        initialFormData?.review_start_time > 0
                    if (isTimestamp) {
                        request.review_start_time =
                            initialFormData?.review_start_time
                    }
                }
                if (mode === 'review') {
                    request['review_start_time'] = reviewTime
                }
                request.updated_by = employeeId
                request.updated_date = dayjs().format(
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                )
                request.uploaded_by = initialFormData?.uploadedBy
                request.uploaded_date = initialFormData?.uploadedDate
                // if (
                //     publish &&
                //     keywords.length === 0 &&
                //     (!initialFormData?.keywords ||
                //         !initialFormData?.entities ||
                //         !initialFormData?.iab_category)
                // ) {
                //     const { data, entity, iab_ } = await generateKeyWords(
                //         title,
                //         shortDescription
                //     )
                //     if (data.length === 0) {
                //         setIsLoading(false)
                //         message.error(
                //             'Keyword generation failed. Please try regenerating or enter the keywords manually.'
                //         )
                //         return
                //     }
                //     request.keywords = data.toString()
                //     request.entites = entity
                //     request.iab_category = iab_
                // } else {
                request.entites = initialFormData?.entities
                request.keywords =
                    initialFormData?.keywords === '' ||
                    initialFormData?.keywords === undefined
                        ? keywords.toString()
                        : initialFormData?.keywords
                request.iab_category = initialFormData?.iab_category
                // }
                try {
                    const shortNewsResponse = await updateShortNews(
                        request,
                        contentId,
                        accessToken
                    )

                    if (
                        shortNewsResponse?.data ===
                        'Short news updated successfully'
                    ) {
                        setIsLoading(false)

                        if (
                            location?.pathname?.includes('writer-dashboard') ||
                            location?.pathname?.includes('reporter-dashboard')
                        ) {
                            resetWriterDashboard()
                        }

                        message.success('Short news updated successfully')

                        if (mode === 'review') {
                            navigate('/home/review')
                        } else {
                            navigate('/home/shortNews')
                        }
                    }
                } catch (error) {
                    console.error('Error updating short news:', error)
                    setIsLoading(false)
                    message.error('Failed to update short news')
                }

                setTimeout(() => console.log('Initial timeout!'), 1000)
            } else if (mode === 'add') {
                // if (publish && keywords.length === 0) {
                //     const { data, entity, iab_ } = await generateKeyWords(
                //         title,
                //         shortDescription
                //     )
                //     if (data.length === 0) {
                //         setIsLoading(false)
                //         message.error(
                //             'Keyword generation failed. Please try regenerating or enter the keywords manually.'
                //         )
                //         return
                //     }
                //     request.keywords = data.toString()
                //     request.entites = entity
                //     request.iab_category = iab_
                // } else {
                //     request.entites = initialFormData?.entities
                //     request.keywords =
                //         initialFormData?.keywords === '' ||
                //         initialFormData?.keywords === undefined
                //             ? keywords.toString()
                //             : initialFormData?.keywords
                //     request.iab_category = initialFormData?.iab_category
                // }
                request.content_status = 'approved'
                request.uploaded_by = employeeId
                request.updated_by = 0
                request.uploaded_date = dayjs().format(
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                )
                try {
                    const shortNewsReponse = await createShortNews(
                        request,
                        accessToken
                    )
                    if (shortNewsReponse?.status === 200) {
                        setIsLoading(false)

                        message.success('short news added successfully')
                        navigate('/home/shortNews')
                    }
                } catch {
                    message.error('failed to create short news')
                }
            }
        }
        const getImage = (url, index) => {
            return (
                <div
                    style={{
                        position: 'relative',
                        display: 'inline-block',
                        margin: '4px',
                        boxShadow: '2px 2px 2px lightgray',
                        borderRadius: '5px',
                    }}
                >
                    <img
                        alt="news"
                        src={`${url}?${new Date().getTime()}`}
                        style={{
                            objectFit: 'cover',
                            height: 100,
                            width: 100,
                            borderRadius: 5,
                            padding: 0,
                        }}
                    />
                    {!location.pathname.includes('addsnippet-form') &&
                        !videoPath?.stream_url &&
                        mode !== 'review' && (
                            <DeleteFilled
                                onClick={() => handleDeleteImage(url)}
                                className="icon-hover"
                                style={{
                                    position: 'absolute',
                                    cursor: 'pointer',
                                    top: 0,
                                    right: 0,
                                    border: 'none',
                                    color: 'white',
                                    fontSize: '16px',
                                    padding: 4,
                                }}
                            />
                        )}
                    {!location.pathname.includes('addsnippet-form') &&
                        !videoPath?.stream_url &&
                        mode !== 'review' && (
                            <div
                                style={{
                                    position: 'absolute',
                                    cursor: 'pointer',
                                    top: '75%',
                                    right: 0,
                                    border: 'none',
                                    color: 'white',
                                    opacity: 0.9,
                                    textShadow: '2px 2px 2px red',
                                    fontSize: '16px',
                                    padding: 4,
                                }}
                            >
                                <EditCropper
                                    imageUrls={images}
                                    index={index}
                                    accessToken={accessToken}
                                    languageOption={languageOption}
                                    onUpdate={handleUpdateImage}
                                />
                            </div>
                        )}
                </div>
            )
        }
        useEffect(() => {
            if (hashTagId) {
                setHashTagValue(
                    hashData?.filter((h) => h.hashtag_id === hashTagId)?.[0]
                        ?.hashtag_name ?? ''
                )
            }
        }, [hashData, hashTagId])
        const updateHashtag = async () => {
            if (languageOption) {
                if (allHashTags?.[languageOption] === undefined) {
                    const hashtagDataResponse = await getHashtagTableData(
                        languageOption,
                        '',
                        accessToken
                    )
                    dispatch(
                        addHashtags({
                            id: languageOption,
                            data: hashtagDataResponse?.data,
                        })
                    )
                    setHashData(hashtagDataResponse?.data)

                    setHashtagOptions(
                        hashtagDataResponse?.data?.map((option) => ({
                            value: option.hashtag_name,
                        })) ?? []
                    )
                    setAllHashtagOptions(
                        hashtagDataResponse?.data?.map((option) => ({
                            value: option.hashtag_name,
                        })) ?? []
                    )
                } else {
                    setHashData(allHashTags?.[languageOption])

                    setHashtagOptions(
                        allHashTags?.[languageOption]?.map((option) => ({
                            value: option.hashtag_name,
                        })) ?? []
                    )
                    setAllHashtagOptions(
                        allHashTags?.[languageOption]?.map((option) => ({
                            value: option.hashtag_name,
                        })) ?? []
                    )
                }
            }
        }
        const handleLanguageChange = (id) => {
            setLanguageOption(id)
            setOriginalSubcategory([])
            setSubCategory([])
            setAllState(false)
        }
        const onFinishFailed = (errorInfo) => {
            console.log('Failed:', errorInfo)
        }
        const onHashTagSelect = (val) => {
            setHashTagValue(val)
            setHashTagId(
                hashData?.filter((h) => h.hashtag_name === val)?.[0]?.hashtag_id
            )
        }
        const getRemainingWords = (value, type, max) => {
            if (type === 'words') {
                const remaingWords = max - value?.split(' ')?.length
                return remaingWords
            } else {
                const remainingChar = max - value.length
                return remainingChar
            }
        }
        const tempfunction = (selectedStateId) => {
            let descriptionValue = form
                .getFieldValue('shortDescription')
                .trimStart()
            if (selectedStateId?.includes('654b0c4ed3be6cda3e0e7e79')) {
                const shortDescription = descriptionValue
                    .replace('TG:', '')
                    .replace('AP:', '')
                descriptionValue = `AP: ${shortDescription.trimStart()}`
            } else if (selectedStateId?.includes('654b0c51d3be6cda3e0e7e97')) {
                const shortDescription = descriptionValue
                    .replace('AP:', '')
                    .replace('TG:', '')
                descriptionValue = `TG: ${shortDescription.trimStart()}`
            } else {
                descriptionValue = descriptionValue
                    .replace(/TG:|AP:/g, '')
                    .trimStart()
            }
            changeDescriptionValue(descriptionValue)
        }

        const changeDescriptionValue = (descriptionValue) => {
            let cleanedValue = descriptionValue
            if (cleanedValue?.split('\n')?.length <= 9) {
                form.setFieldValue('shortDescription', cleanedValue)
                descriptionChange(cleanedValue)
                const remainingWords = getRemainingWords(
                    cleanedValue,
                    'words',
                    languageOption === 3 ? 70 : 60
                )
                const remainingChars = getRemainingWords(
                    cleanedValue,
                    'char',
                    languageOption === 3 ? 500 : 400
                )
                if (remainingWords > -1 && remainingChars > -1) {
                    form.setFieldValue('shortDescription', cleanedValue)
                    setShortDescription(cleanedValue)
                    setRemaingCharCount(remainingChars)
                } else if (cleanedValue === '') {
                    setRemaingCharCount(languageOption === 3 ? 500 : 400)
                } else {
                    if (remainingWords < 0) {
                        const newDescription = cleanedValue
                            .split(' ')
                            .slice(0, remainingWords)
                            .join(' ')
                        changeDescriptionValue(newDescription)
                    } else if (remainingChars < 0) {
                        const newDescription = cleanedValue
                            .split('')
                            .slice(0, remainingChars)
                            .join('')
                        changeDescriptionValue(newDescription)
                    }
                }
            }
        }

        const handleClose = (index) => {
            const key = [...keywords]
            key.splice(index, 1)
            setKeywords(key)
        }

        const addKeyword = (keyword) => {
            setKeywords([...keywords, keyword])
            setKeywords_g(true)
        }
        const handleTitleChange = (value) => {
            if (value) {
                let cleanedValue = value
                setTitle(cleanedValue)
                titleChange(cleanedValue)
                form.setFieldValue('title', cleanedValue)
                setTitleError(cleanedValue.length > 75)
            } else {
                setTitle('')
                form.setFieldValue('title', '')
                titleChange('')
                setTitleError(false)
            }
        }

        const handleEnglishTitleChange = (value) => {
            form.setFieldValue('englishTitle', value)
        }

        const handleFindSimilar = async (title, shortDescription) => {
            setLoading(true)
            if (!title || title === '' || title.trim() === '') {
                return
            }

            try {
                const response = await getSimilarContent(
                    languageOption,
                    `${title}`,
                    accessToken
                )
                setSimilarContents({ data: response?.data?.data, api: true })
            } catch (error) {
                console.error('Error fetching similar content:', error)
            } finally {
                setLoading(false)
            }
        }
        useImperativeHandle(ref, () => ({
            publish_review() {
                form.validateFields()?.then(
                    (res) => {
                        onFinish(res, true)
                    },
                    (error) => {
                        console.log(error)
                    }
                )
            },
        }))
        const handlePublish = () => {
            form.validateFields()?.then(
                (res) => {
                    onFinish(res, true)
                },
                (error) => {
                    console.log(error)
                }
            )
        }
        async function handleCategory(e) {
            setSelectedCategory(e)
            if (
                !location?.pathname?.includes('home/reporter-dashboard') &&
                !(
                    mode === 'review' &&
                    initialFormData?.created_type === 'reporter'
                )
            ) {
                setAllState(false)
                setSelectedStateId([])
                setSelectedStates([])
            }
        }
        async function subCategoryfunc(e, first) {
            if (e !== null && e !== undefined) {
                await getSubCategories(accessToken, languageOption, e).then(
                    (resp) => {
                        let updatedSubCategories = resp?.data?.data
                        setOriginalSubcategory(
                            updatedSubCategories?.map((subCat) => ({
                                ...subCat,
                                anti: false,
                                pro: false,
                            }))
                        )
                        if (
                            selectedCategory === 11 &&
                            ((initialFormData?.state_id !== undefined &&
                                initialFormData?.state_id?.length !== 0) ||
                                selectedStateId?.length > 0)
                        ) {
                            updatedSubCategories = updatedSubCategories?.filter(
                                (e) =>
                                    initialFormData?.state_id?.includes(
                                        e.state_id
                                    ) || selectedStateId?.includes(e.state_id)
                            )
                        }
                        const data = updatedSubCategories?.map((subCat) => ({
                            ...subCat,
                            anti: false,
                            pro: false,
                        }))
                        setSubCategory(data)
                    }
                )
            }
            if (first) {
                updateProStatus(pro)
                updateAntiStatus(anti)
            }
        }
        const updateProStatus = (id) => {
            setSubCategory((prevSubCategory) =>
                prevSubCategory.map((subCat) =>
                    subCat.sub_category_id === parseInt(id)
                        ? { ...subCat, pro: !subCat?.pro, anti: false }
                        : { ...subCat, pro: false }
                )
            )
        }
        const updateAntiStatus = (id) => {
            setSubCategory((prevSubCategory) =>
                prevSubCategory.map((subCat) =>
                    subCat.sub_category_id === parseInt(id)
                        ? { ...subCat, anti: !subCat?.anti, pro: false }
                        : { ...subCat, anti: false }
                )
            )
        }

        const handleGroupChange = (checkedIds) => {
            if (!checkedIds.includes('all')) {
                setAllState(false)
            }
            let temp = []
            const selectedStates = languageData
                ?.filter((item) => item?.language_id === languageOption)
                ?.flatMap((item) => {
                    const statesToSelect = checkedIds.includes('All')
                        ? item?.states
                        : item?.states?.filter((state) =>
                              checkedIds.includes(state?.state_id)
                          )

                    statesToSelect?.forEach((state) => {
                        temp.push(state?.state_id)
                    })

                    return statesToSelect?.map((state) => ({
                        id: state?.state_id,
                        name: state?.name,
                    }))
                })
            if (selectedCategory === 11 && temp.length !== 0) {
                const updatedSubCategories = originalSubcategory?.filter((e) =>
                    temp?.includes(e.state_id)
                )
                setSubCategory(updatedSubCategories)
            } else {
                setSubCategory(originalSubcategory)
            }
            setSelectedStateId(temp)
            tempfunction(temp)
            setSelectedStates(selectedStates)
            form.setFieldValue('constituency', undefined)
            setConstituency('')
        }

        useEffect(() => {
            setSelectedCategory(undefined)
            form.setFieldValue('category', undefined)
        }, [languageOption !== initialFormData?.language])
        useEffect(() => {
            if (
                languageOption !== undefined &&
                selectedCategory !== undefined &&
                selectedCategory
            ) {
                subCategoryfunc(selectedCategory, true)
            }
        }, [selectedCategory])
        return (
            <div id="form" style={{ width: '100%', position: 'relative' }}>
                <Form
                    form={form}
                    name="basic"
                    colon={false}
                    scrollToFirstError
                    labelWrap
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 18 }}
                    onFinish={(values) => onFinish(values, false)}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    {employeeLanguage.length > 1 && (
                        <Form.Item
                            label="Language"
                            name="language"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select language',
                                },
                            ]}
                        >
                            <Select onChange={handleLanguageChange}>
                                {languageData?.map((option) => {
                                    if (
                                        employeeLanguage?.includes(
                                            option?.language_id
                                        )
                                    ) {
                                        return (
                                            <Select.Option
                                                key={option?.language_id}
                                                value={option?.language_id}
                                            >
                                                {option?.language_name}
                                            </Select.Option>
                                        )
                                    }
                                })}
                            </Select>
                        </Form.Item>
                    )}
                    <Form.Item
                        label="News Section"
                        name="category"
                        rules={[
                            {
                                required: true,
                                message: 'Please select category',
                            },
                        ]}
                    >
                        <Select
                            placeholder="--Select news sections--"
                            onChange={(e) => handleCategory(e)}
                        >
                            {categories?.map((option) => {
                                if (
                                    (location?.pathname?.includes(
                                        'reporter-dashboard'
                                    ) ||
                                        (mode === 'review' &&
                                            initialFormData?.created_type ===
                                                'reporter')) &&
                                    stateShowId?.includes(option?.category_id)
                                ) {
                                    return (
                                        <Select.Option
                                            key={option?.category_id}
                                            value={option?.category_id}
                                        >
                                            <div>
                                                {option?.category_name}
                                                <span
                                                    style={{
                                                        color: 'gray',
                                                    }}
                                                >{` ( ${option?.display_name} )`}</span>
                                            </div>
                                        </Select.Option>
                                    )
                                } else if (
                                    !location?.pathname?.includes(
                                        'reporter-dashboard'
                                    ) &&
                                    !(
                                        mode === 'review' &&
                                        initialFormData?.created_type ===
                                            'reporter'
                                    )
                                ) {
                                    return (
                                        <Select.Option
                                            key={option?.category_id}
                                            value={option?.category_id}
                                        >
                                            <div>
                                                {option?.category_name}
                                                <span
                                                    style={{
                                                        color: 'gray',
                                                    }}
                                                >{` ( ${option?.display_name} )`}</span>
                                            </div>
                                        </Select.Option>
                                    )
                                }
                            })}
                        </Select>
                    </Form.Item>
                    {false ? (
                        <Form.Item
                            name="expiryHours"
                            label="Expiry Hours"
                            rules={[{ required: true }]}
                        >
                            <Select style={{ width: 200 }}>
                                {expiryHoursOptions?.map((option, index) => (
                                    <Select.Option key={index} value={option}>
                                        {option}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    ) : null}

                    {(stateShowId?.includes(selectedCategory) ||
                        location.pathname.includes(
                            'home/reporter-dashboard'
                        )) && (
                        <Form.Item
                            rules={[
                                {
                                    required: location?.pathname?.includes(
                                        'reporter-dashboard'
                                    )
                                        ? true
                                        : false,
                                    message: 'State is required',
                                },
                            ]}
                            required={
                                location?.pathname?.includes(
                                    'reporter-dashboard'
                                )
                                    ? true
                                    : false
                            }
                            label="State Specific"
                            name="stateValue"
                            value="state"
                        >
                            {!location?.pathname?.includes(
                                'home/reporter-dashboard'
                            ) && (
                                <Radio
                                    style={{
                                        marginRight: '8px',
                                        color: 'blue',
                                        fontWeight: 500,
                                    }}
                                    disabled={
                                        mode === 'review' &&
                                        initialFormData?.created_type ===
                                            'reporter'
                                            ? true
                                            : false
                                    }
                                    onClick={() => {
                                        handleGroupChange(['all']) // Select 'All'
                                        setAllState(true)
                                    }}
                                    checked={allState}
                                >
                                    {'All'}
                                </Radio>
                            )}
                            <Radio.Group
                                disabled={
                                    mode === 'review' &&
                                    initialFormData?.created_type === 'reporter'
                                        ? true
                                        : false
                                }
                                value={selectedStates?.[0]?.id || null}
                                onChange={(e) => {
                                    const newValue = e.target.value

                                    handleGroupChange([newValue])
                                }}
                            >
                                {languageData
                                    ?.filter(
                                        (item) =>
                                            item?.language_id === languageOption
                                    )
                                    ?.flatMap((item) =>
                                        item?.states?.map((state) => (
                                            <Radio
                                                key={state?.state_id}
                                                value={state?.state_id}
                                                checked={
                                                    selectedStates?.[0]?.id ===
                                                    state?.state_id
                                                } // Check if this is the selected state
                                            >
                                                {state?.name}
                                            </Radio>
                                        ))
                                    )}
                            </Radio.Group>
                        </Form.Item>
                    )}
                    {location?.pathname?.includes('home/reporter-dashboard') &&
                        selectedStateId && (
                            <Form.Item
                                rules={[
                                    {
                                        required: !constituency,
                                        message: 'Constituency is required',
                                    },
                                ]}
                                required
                                label="Constituency"
                                name="constituency"
                            >
                                <Select
                                    value={
                                        constituency ? constituency : undefined
                                    }
                                    allowClear
                                    placeholder="Select Constituency"
                                    onChange={(e) => setConstituency(e)}
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.children
                                            ?.toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    style={{ width: 180 }}
                                >
                                    {allConstituencies?.[selectedStateId]?.map(
                                        (option, index) => {
                                            return (
                                                <Option
                                                    key={
                                                        option?.assembly_constituency_id
                                                    }
                                                    value={
                                                        option?.assembly_constituency_id
                                                    }
                                                >
                                                    {option?.name}
                                                </Option>
                                            )
                                        }
                                    )}
                                </Select>
                            </Form.Item>
                        )}
                    {selectedCategory &&
                        roleId !== 5 &&
                        roleId !== 3 &&
                        subCategory?.length !== 0 && (
                            <Form.Item name="subcategory" label=" ">
                                {subCategory?.map((subcat) => {
                                    return (
                                        <div
                                            style={{
                                                display: 'flex',
                                                width: '100%',
                                                alignItems: 'center',
                                                margin: '1% 0 1% 0',
                                            }}
                                            key={subcat.sub_category_id}
                                        >
                                            <span
                                                style={{
                                                    marginRight: '1rem',
                                                    maxWidth: '10rem',
                                                }}
                                            >
                                                {subcat.sub_category_name}
                                            </span>
                                            <div
                                                style={{
                                                    diplay: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Radio
                                                    onClick={(e) =>
                                                        updateProStatus(
                                                            e.target.value
                                                        )
                                                    }
                                                    checked={subcat.pro}
                                                    type="radio"
                                                    name="pro"
                                                    value={
                                                        subcat.sub_category_id
                                                    }
                                                />
                                                <label
                                                    style={{
                                                        margin: '0 .5rem 0 0',
                                                        color: 'gray',
                                                    }}
                                                >
                                                    pro
                                                </label>

                                                <Radio
                                                    onClick={(e) =>
                                                        updateAntiStatus(
                                                            e.target.value
                                                        )
                                                    }
                                                    checked={subcat.anti}
                                                    type="radio"
                                                    name="anti"
                                                    value={
                                                        subcat.sub_category_id
                                                    }
                                                />
                                                <label
                                                    style={{
                                                        margin: '0 0 0 0',
                                                        color: 'gray',
                                                    }}
                                                >
                                                    anti
                                                </label>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Form.Item>
                        )}
                    <Form.Item
                        label="Headline"
                        name="title"
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message:
                                    'Value is required and should be less than 75 characters',
                            },
                        ]}
                    >
                        <div style={{ position: 'relative' }}>
                            {/* <Input.TextArea
                                className={titleError ? 'error' : ''}
                                maxLength={75}
                                value={title}
                                onChange={(e) => {
                                    titleChange(e.target.value)
                                    handleTitleChange(e.target.value)
                                }}
                                placeholder="Title of the article (max 75 chars) in regional language (ex:tamil,telugu)"
                            /> */}
                            <SpellCheckBox
                                minHeight={'3rem'}
                                mode={mode}
                                location={location?.pathname}
                                length={75}
                                onChange={(e) => {
                                    titleChange(e.target.value)
                                    handleTitleChange(e.target.value)
                                }}
                                languageOption={languageOption}
                                text={title}
                                placeholder={
                                    'Title of the article (max 75 chars) in regional language (ex:tamil,telugu)'
                                }
                            />
                            <span
                                style={{
                                    position: 'absolute',
                                    marginLeft: '4px',
                                    bottom: 6,
                                    right: -37,
                                }}
                            >
                                {mode === 'review' && (
                                    <Speaker
                                        text={title}
                                        language={
                                            languageOption === 1
                                                ? 'en-US'
                                                : languageOption === 2
                                                  ? 'te'
                                                  : languageOption === 4
                                                    ? 'ta-IN'
                                                    : 'hi-IN'
                                        }
                                    />
                                )}
                            </span>
                        </div>

                        <span
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                            className="description-subtext"
                        >
                            <span
                                style={{
                                    color:
                                        title?.length > 75 ? 'red' : '#555555',
                                    fontWeight: '500',
                                    fontSize: '14px',
                                }}
                            >
                                {75 - (title?.length ?? 0)} characters
                            </span>
                        </span>
                    </Form.Item>

                    <Form.Item
                        label="Story Details"
                        name="shortDescription"
                        rules={[
                            {
                                required: true,
                                message:
                                    'Value is required and should be less than 400 characters',
                                whitespace: true,
                            },
                        ]}
                    >
                        <div style={{ position: 'relative' }}>
                            {/* <TextArea
                                value={shortDescription}
                            
                                autoSize
                                spellCheck={false}
                                data-gramm="false"
                                data-gramm_editor="false"
                                style={{ minHeight: '5rem' }}
                                onChange={(e) => {
                                    descriptionChange(e.target.value)
                                    changeDescriptionValue(e.target.value)
                                }}
                                placeholder="Up to 60 words"
                            /> */}
                            <SpellCheckBox
                                mode={mode}
                                location={location?.pathname}
                                minHeight={'5rem'}
                                length={languageOption === 3 ? 500 : 400}
                                onChange={(e) => {
                                    descriptionChange(e.target.value)
                                    changeDescriptionValue(e.target.value)
                                }}
                                text={shortDescription}
                                languageOption={languageOption}
                                placeholder={
                                    languageOption === 3
                                        ? 'Up to 70 words'
                                        : 'Up to 60 words'
                                }
                            />

                            <span
                                style={{
                                    position: 'absolute',
                                    marginLeft: '4px',
                                    bottom: 6,
                                    right: -37,
                                }}
                            >
                                {mode === 'review' && (
                                    <Speaker
                                        text={shortDescription}
                                        language={
                                            languageOption === 1
                                                ? 'en-US'
                                                : languageOption === 2
                                                  ? 'te'
                                                  : languageOption === 4
                                                    ? 'ta-IN'
                                                    : 'hi-IN'
                                        }
                                    />
                                )}
                            </span>
                        </div>

                        <span
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                            className="description-subtext"
                        >
                            <span
                                style={{
                                    color:
                                        remainingCharCount === 0
                                            ? 'red'
                                            : '#555555',
                                }}
                            >
                                {(languageOption === 3 ? 500 : 400) -
                                    shortDescription?.length}{' '}
                                characters
                            </span>
                        </span>

                        <span
                            style={{
                                display: 'flex',
                                justifyContent: 'end',
                                fontSize: '1rem',
                                fontWeight: '500',
                                alignItems: 'center',
                            }}
                        ></span>
                    </Form.Item>
                    {mode === 'review' && (
                        <Form.Item label={'Similar Content'}>
                            <div className="similar-content-wrapper">
                                <div className="get-similar-btn">
                                    {loading && (
                                        <div className="loading-spinner">
                                            <Spin size="small" />
                                        </div>
                                    )}
                                </div>

                                {similarContents?.data?.length === 0 &&
                                    !loading &&
                                    similarContents?.api && (
                                        <div className="no-content-message">
                                            No similar content found
                                        </div>
                                    )}
                                {similarContents?.data?.length > 0 &&
                                    !loading && (
                                        <div>
                                            {similarContents?.data?.map((e) => (
                                                <div
                                                    key={e?.dynamic_url}
                                                    style={{
                                                        fontSize: '12px',
                                                        marginBottom: '.5rem',
                                                    }}
                                                >
                                                    <a
                                                        href={e?.dynamic_url}
                                                        target="blank"
                                                        style={{ fontSize: 12 }}
                                                    >
                                                        {e?.title}
                                                    </a>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                            </div>
                        </Form.Item>
                    )}
                    {keywords_g && (
                        <Form.Item
                            label="Keywords"
                            name="keywords"
                            required={true}
                        >
                            <div
                                className="col-md"
                                style={{ background: 'none' }}
                            >
                                {keywords?.map((keyword, index) => (
                                    <Tag
                                        key={index}
                                        closable
                                        style={{
                                            userSelect: 'none',
                                            marginBottom: '5px',
                                        }}
                                        onClose={(e) => {
                                            e.preventDefault()
                                            handleClose(index)
                                        }}
                                    >
                                        <span>{keyword}</span>
                                    </Tag>
                                ))}
                                <Input
                                    onChange={(e) =>
                                        setKeywordInput(e.target.value)
                                    }
                                    value={keywordInput}
                                    onKeyPress={(event) => {
                                        if (event.key === 'Enter') {
                                            event.preventDefault()
                                            addKeyword(event?.target?.value)
                                            setKeywordInput('')
                                        }
                                    }}
                                    placeholder="Type words and press enter after each word or click generate keywords"
                                />
                                {generate ? (
                                    <Button
                                        style={{ margin: '.5rem 0' }}
                                        loading
                                    >
                                        Generating...
                                    </Button>
                                ) : (
                                    <Button
                                        type="primary"
                                        ghost
                                        style={{ margin: '.5rem 0' }}
                                        onClick={() => {
                                            generateKeyWords(
                                                title,
                                                form.getFieldValue(
                                                    'shortDescription'
                                                )
                                            )
                                        }}
                                    >
                                        Generate Keywords
                                    </Button>
                                )}
                            </div>
                        </Form.Item>
                    )}
                    {mode !== 'review' && (
                        <Form.Item
                            required
                            value={imagePath}
                            label="Multimedia"
                            name="multimedia"
                            rules={[
                                {
                                    required: !imagePath,
                                    message: 'Image is required',
                                },
                            ]}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        marginRight: '3rem',
                                    }}
                                >
                                    <span
                                        onClick={() => setIsImageAdding(true)}
                                        style={{ marginRight: 10 }}
                                        className="multimedia"
                                    >
                                        <PictureFilled
                                            style={{ color: 'gray' }}
                                        />{' '}
                                        Image
                                    </span>
                                </div>
                                <span
                                    onClick={() => setIsVideoAdding(true)}
                                    className="multimedia"
                                >
                                    <VideoCameraFilled
                                        style={{ color: 'gray' }}
                                    />{' '}
                                    Video
                                </span>
                            </div>
                        </Form.Item>
                    )}
                    <Form.Item label=" ">
                        {images?.length > 0 && (
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        marginTop: '-1.5%',
                                    }}
                                >
                                    {images?.map((e, index) => {
                                        return (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                                key={index}
                                            >
                                                {getImage(e, index)}{' '}
                                                <div>
                                                    {index ===
                                                        images.length - 1 &&
                                                        !videoPath?.stream_url &&
                                                        mode !== 'review' && (
                                                            <div>
                                                                <Button
                                                                    type="primary"
                                                                    onClick={() =>
                                                                        setIsImageAddin(
                                                                            true
                                                                        )
                                                                    }
                                                                    shape="circle"
                                                                    style={{
                                                                        marginLeft: 5,
                                                                    }}
                                                                    icon={
                                                                        <PlusOutlined />
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )}
                    </Form.Item>
                    <Form.Item label="Hashtag">
                        <AutoComplete
                            options={hashTagOptions}
                            value={hashTagValue}
                            onSearch={(text) => {
                                setHashTagValue(text)
                                if (text === '') {
                                    setHashTagId()
                                }
                                setHashtagOptions(
                                    allHashTagOptions?.filter(({ value }) =>
                                        value
                                            ?.toLowerCase()
                                            ?.includes(text?.toLowerCase())
                                    )
                                )
                            }}
                            onSelect={onHashTagSelect}
                            placeholder="select hashtag"
                        />
                    </Form.Item>
                    <Form.Item label=" ">
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'center',
                            }}
                            className="buttons_shortnews"
                        >
                            {isSubmitAndDraft(
                                userPermissions,
                                'Content',
                                'Short News'
                            ) &&
                                mode !== 'review' &&
                                (roleId !== '8' || roleId !== '25') && (
                                    <Button
                                        className="width-100"
                                        type="primary"
                                        onClick={() => handleSave('draft')}
                                        disabled={
                                            isLoading ||
                                            contentStatus === 'approved' ||
                                            contentStatus === 'submit'
                                        }
                                    >
                                        Save
                                    </Button>
                                )}
                            {isSubmitAndDraft(
                                userPermissions,
                                location?.pathname?.includes(
                                    'home/reporter-dashboard'
                                )
                                    ? 'Reporter Dashboard'
                                    : 'Content',
                                location?.pathname?.includes(
                                    'home/reporter-dashboard'
                                )
                                    ? 'Reporter Dashboard'
                                    : 'Short News'
                            ) &&
                                mode !== 'review' &&
                                (mode === 'edit' &&
                                location?.pathname?.includes(
                                    'home/reporter-dashboard'
                                )
                                    ? false
                                    : true) &&
                                (roleId !== '8' || roleId !== '25') &&
                                (!initialFormData?.assigned_to ||
                                    initialFormData?.assigned_to === -1) && (
                                    <Button
                                        className="submit-buttons width-120"
                                        type="primary"
                                        onClick={() => handleSave('submit')}
                                        disabled={
                                            isLoading ||
                                            contentStatus === 'approved'
                                        }
                                    >
                                        Submit
                                    </Button>
                                )}
                            {isPublish(
                                userPermissions,
                                'Content',
                                'Short News'
                            ) &&
                                (roleId === '8' || roleId === '25') &&
                                mode !== 'review' && (
                                    <Button
                                        className="width-120"
                                        type="primary"
                                        onClick={() => handlePublish()}
                                        disabled={isLoading}
                                    >
                                        {mode === 'review'
                                            ? 'Approve'
                                            : 'Publish'}
                                    </Button>
                                )}
                        </div>
                    </Form.Item>
                </Form>
            </div>
        )
    }
)
export default AddShortNewsForm

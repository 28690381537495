import React, { useState } from 'react'
import { Button, notification, Popover, Switch } from 'antd'
import { BellOutlined} from '@ant-design/icons'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { isAvailableApi, logoutEmployee } from '../../AadhanApi'
import { useDispatch } from 'react-redux'
import {
    addNewsSectionData,
    addRejectedReasonData,
    userLogin,
} from '../../store/slice/AppSlice'

const Availability = () => {
    const employeeId = useSelector((state) => state.app.employeeId)
    const accessToken = useSelector((state) => state.app.accessToken)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isAvailable, setIsAvailabel] = useState(
        localStorage?.getItem('is_Available') === undefined &&
            localStorage?.getItem('is_Available') === null
            ? false
            : localStorage?.getItem('is_Available')
    )
    const [showPopover, setShowPopover] = useState(false)
    const onLogout = async (status) => {
        try {
            await logoutEmployee(accessToken, employeeId, status)
            localStorage.removeItem('ACCESS_TOKEN')
            localStorage.removeItem('USER_PERMISSIONS')
            localStorage.removeItem('EMPLOYEE_ID')
            localStorage.removeItem('EMPLOYEE_NAME')
            localStorage.removeItem('USER_NAME')
            localStorage.removeItem('LANGUAGE')
            localStorage.removeItem('options')
            dispatch(addRejectedReasonData([]))
            dispatch(addNewsSectionData([]))
            dispatch(userLogin({}))
            notification.success({
                message: 'Logout Successful',
                description: 'You have been logged out successfully.',
            })
            navigate('/')
        } catch (error) {
            console.error('Error during logout:', error)
            notification.error({
                message: 'Logout Failed',
                description:
                    'There was an error during logout. Please try again.',
            })
        }
    }

    const handleConfirm = async () => {
        notification.open({
            message: 'Availability Update',
            description:
                'Your tasks for today have been reassigned. Please take a break."',
            icon: <BellOutlined style={{ color: '#1890ff' }} />,
            style: {
                borderRadius: '8px',
                backgroundColor: '#f0f9ff',
            },
        })
        setIsAvailabel('')
        localStorage.setItem('is_Available', '')
        await isAvailableApi(accessToken, false, true, 'day_off')
        setShowPopover(false)
        onLogout('day_off')
    }

    const handleCancel = async () => {
        notification.open({
            message: 'Availability Update',
            description: 'Short break time! Take a moment to recharge.',
            icon: <BellOutlined style={{ color: '#1890ff' }} />,
            style: {
                borderRadius: '8px',
                backgroundColor: '#f0f9ff',
            },
        })
        // message.info("Short break time! Take a moment to recharge.");
        setIsAvailabel('')
        localStorage.setItem('is_Available', '')
        await isAvailableApi(accessToken, false, true, 'short_break')
        setShowPopover(false)
    }
    const handleSwitchChange = async (checked) => {
        if (!checked) {
            setShowPopover(true)
        } else {
            setIsAvailabel(true)
            localStorage.setItem('is_Available', true)
            await isAvailableApi(accessToken, true, false, 'active')
        }
    }

    return (
        <div
            style={{
                marginLeft: 'auto',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <label
                style={{
                    marginRight: '1rem',
                    fontSize: '1rem',
                    fontWeight: 500,
                }}
            >
                Availability
            </label>
            <Popover
                content={
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '0.5rem',
                        }}
                    >
                        <Button
                            className="dayOff"
                            style={{
                                width: '100%',
                                marginBottom: '0.5rem',
                                background: 'blue',
                                color: 'white',
                                borderRadius: '5px',
                            }}
                            onClick={handleCancel}
                        >
                            Short Break
                        </Button>
                        <Button
                            className="dayOff"
                            style={{
                                width: '100%',
                                background: 'red',
                                color: 'white',
                                borderRadius: '5px',
                            }}
                            onClick={handleConfirm}
                        >
                            Day Off
                        </Button>
                    </div>
                }
                title="You are about to set"
                trigger="click"
                open={showPopover}
                onOpenChange={(visible) => !visible && setShowPopover(false)}
            >
                <Switch
                    checked={isAvailable}
                    onChange={handleSwitchChange}
                    checkedChildren="Active"
                    unCheckedChildren="Inactive"
                    className="active_status"
                />
            </Popover>
        </div>
    )
}
export default Availability

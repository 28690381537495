import React, { useEffect, useState } from 'react'
import { Input, Select, Table, Button, message } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { updateReporter } from '../../AadhanApi'
import { useLocation, useNavigate } from 'react-router'
import {
    CloseOutlined,
    EditOutlined,
    RightOutlined,
    SaveOutlined,
} from '@ant-design/icons'
const { Option } = Select

const EditableTable = ({
    data,
    dataLoading,
    locations,
    accessToken,
    allDistricts,
}) => {
    const [rowObj, setRowObj] = useState({
        name: '',
        id: '',
        state_id: '',
        state_name: '',
        district_id: '',
        district_name: '',
        mobile: '',
        type: '',
    })
    const [dataSource, setDataSource] = useState(data)
    const navigate = useNavigate()
    const [messageApi, contextHolder] = message.useMessage()
    useEffect(() => {
        setDataSource(data)
    }, [data])
    const handleInputChange = (value, option, column) => {
        if (column === 'type') {
            setRowObj((pre) => ({ ...pre, type: value }))
            return
        }
        if (column === 'state_id') {
            setRowObj((prev) => ({
                ...prev,
                [column]: value,
                state_name: option?.children,
                district_id: undefined,
                district_name: undefined,
                mandal_id: undefined,
                mandal_name: undefined,
                constituency_id: [],
                constituency_name: [],
            }))
        } else if (column === 'district_id') {
            setRowObj((prev) => ({
                ...prev,
                [column]: value,
                district_name: option?.children,
                mandal_id: undefined,
                mandal_name: undefined,
            }))
        } else if (column === 'mobile') {
            setRowObj((prev) => ({
                ...prev,
                [column]: value,
            }))
        } else {
            setRowObj((prev) => ({
                ...prev,
                [column]: value,
            }))
        }
    }
    const handleCancel = (record) => {
        const key = record.user_id
        let newData = [...dataSource]
        newData = newData.map((item) => {
            if (item.user_id === key) {
                return { ...item, isEditing: false }
            } else {
                return item
            }
        })
        setDataSource(newData)
    }
    const handleEdit = (record) => {
        const key = record.user_id
        let newData = [...dataSource]
        newData = newData.map((item) => {
            if (item.user_id === key) {
                return { ...item, isEditing: true }
            } else {
                return { ...item, isEditing: false }
            }
        })
        setRowObj({
            name: record?.full_name,
            id: record?.user_id,
            state_id: record?.reporter_information?.state_id,
            state_name: record?.reporter_information?.state,
            district_id: record?.reporter_information?.district_id
                ? record?.reporter_information?.district_id
                : undefined,
            district_name: record?.reporter_information?.district,
            mobile: record?.mobile_number,
            type: record?.stringer_type,
        })

        setDataSource(newData)
    }
    const handleSave = async (key) => {
        let obj = key
        obj.full_name = rowObj?.name
        obj.reporter_information.state = rowObj?.state_name
        obj.reporter_information.state_id = rowObj?.state_id
        obj.reporter_information.district = rowObj?.district_name
        obj.reporter_information.district_id = rowObj?.district_id
        obj.stringer_type = rowObj?.type
        obj.mobile_number = rowObj?.mobile
        const stringerResponse = await updateReporter(
            obj,
            obj.user_id,
            accessToken
        )
        let newData = [...dataSource]
        newData = newData.map((item) => {
            return { ...item, isEditing: false }
        })
        setDataSource(newData)

        if (stringerResponse.data.status === 'success') {
            messageApi.open({
                type: 'success',
                content: 'Reporter information updated successfully',
                duration: 5,
            })
        } else {
            messageApi.open({
                type: 'error',
                content: 'Failed to update reporter',
                duration: 5,
            })
        }
    }
    const handleRowDoubleClick = (key) => {
        handleEdit(key)
    }
    const columns = [
        {
            title: 'Full Name',
            dataIndex: 'full_name',
            render: (_, record) => (
                <div>
                    {record?.isEditing === true ? (
                        <TextArea
                            autoSize
                            value={rowObj?.name}
                            onChange={(e) =>
                                handleInputChange(e.target.value, '', 'name')
                            }
                            disabled
                        />
                    ) : (
                        <div
                            style={{
                                padding: '3px 4px',
                                color: 'Blue',
                                fontSize: '14px',
                                fontWeight: '500',
                            }}
                        >
                            {record?.full_name}
                        </div>
                    )}
                </div>
            ),
        },
        {
            title: 'State',
            dataIndex: 'state',
            render: (_, record) => (
                <div>
                    {record?.isEditing === true ? (
                        <Select
                            placeholder="Select State"
                            value={rowObj?.state_id}
                            onChange={(value, Option) =>
                                handleInputChange(value, Option, 'state_id')
                            }
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option?.children
                                    ?.toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: 150 }}
                        >
                            {locations?.map((e) => (
                                <Option value={e.state_id} key={e.state_id}>
                                    {e.name}
                                </Option>
                            ))}
                        </Select>
                    ) : (
                        <div>
                            {record?.reporter_information?.state_id && (
                                <div
                                    style={{
                                        padding: '3px 6px',
                                        color: '#333',
                                        fontSize: '14px',
                                        margin: '0 0 5px 0',
                                    }}
                                >
                                    {record?.reporter_information?.state}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            ),
        },
        {
            title: 'District',
            dataIndex: 'district',
            render: (_, record) => (
                <div>
                    {record?.isEditing === true ? (
                        <Select
                            // disabled
                            placeholder="select district"
                            value={
                                rowObj?.district_id === undefined
                                    ? undefined
                                    : rowObj?.district_id
                            }
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option?.children
                                    ?.toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(value, Option) =>
                                handleInputChange(value, Option, 'district_id')
                            }
                            style={{ width: 170 }}
                        >
                            {allDistricts?.[rowObj?.state_id]?.map((e) => (
                                <Option
                                    key={e.district_id}
                                    value={e.district_id}
                                >
                                    {e.name}
                                </Option>
                            ))}
                        </Select>
                    ) : (
                        <div>
                            {record?.reporter_information?.district_id && (
                                <div
                                    style={{
                                        padding: '3px 6px',
                                        color: '#333',
                                        fontSize: '14px',
                                        margin: '0 0 5px 0',
                                    }}
                                >
                                    {record?.reporter_information?.district?.replaceAll(
                                        ',',
                                        ', '
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            ),
        },
        {
            title: 'Phone Number',
            dataIndex: 'phoneNumber',
            render: (_, record) => (
                <div>
                    {record?.isEditing === true ? (
                        <Input
                            style={{ width: 110 }}
                            value={rowObj?.mobile}
                            disabled
                            onChange={(e) => {
                                const value = e.target.value.slice(0, 10) // Ensure only 10 digits
                                handleInputChange(value, '', 'mobile')
                            }}
                        />
                    ) : (
                        <div
                            style={{
                                padding: '3px 6px',
                                color: 'green',
                                fontSize: '14px',
                                fontWeight: '500',
                                margin: '0 0 5px 0',
                            }}
                        >
                            {record?.mobile_number}
                        </div>
                    )}
                </div>
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, record) => (
                <Button
                    type="primary"
                    onClick={() =>
                        navigate(
                            `/home/manage-staff-stringers/${record?.user_id}`
                        )
                    }
                    icon={<EditOutlined />}
                ></Button>
            ),
        },
    ]
    return (
        <>
            {contextHolder}
            <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                loading={dataLoading}
                bordered
                style={{ marginTop: '1rem' }}
                scroll={{ x: 'max-content' }}
                
            />
        </>
    )
}

export default EditableTable

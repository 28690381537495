import React, { useRef, useState } from 'react';
import { Upload, Button, Spin, message, Card} from 'antd';
import { DeleteOutlined, UploadOutlined} from '@ant-design/icons';
import { getImageUrl, getVideoUrl } from '../../../AadhanApi';
const ShortNewsVideoUpload = ({ setVideoPath,accessToken,handleCancel,languageOption,setImages,videoPath,setImagePath }) => {
    const videoRef = useRef(null);
      const canvasRef = useRef(null);
    const [isVideoUploading, setIsVideoUploading] = useState(false);
    const beforeUpload = (file) => {
        const isMp4 = file.type === 'video/mp4';
        const isLt50MB = file.size / 1024 / 1024 <= 50;
        const maxDuration = 60; // Maximum duration in seconds
    
        if (!isMp4) {
            message.error('You can only upload MP4 video files!');
            return false;
        }
        if (!isLt50MB) {
            message.error('Video must be smaller than 50MB!');
            return false;
        }
        // Check video duration <= 60s
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');
            video.preload = 'metadata';
            video.onloadedmetadata = () => {
                window.URL.revokeObjectURL(video.src);
                const duration = video.duration;
                if (duration > maxDuration) {
                    message.error(`The video exceeds the allowed duration of ${maxDuration} seconds.`);
                    reject(false);
                } else {
                    resolve(true); 
                }
            };
            video.onerror = () => {
                message.error('Failed to load video metadata.');
                reject(false)
            };
    
            video.src = URL.createObjectURL(file);
        });
    };
    const getImagePath = async (path) => {
            const pathUriArr = await getImageUrl(
                path,
                'shortnews',
                accessToken,
                languageOption
            )
    
            if (pathUriArr?.data) {
               setImages([pathUriArr?.data])
               setImagePath(pathUriArr?.data)
                message.success('Image created from video successfully')
               
            }
            else{
                message.error('Unable to create image from video')
            }
           
        }
    const captureFrame = (time) => {
        // const video = videoRef.current;
        // const canvas = canvasRef.current;
    
        // if (video && canvas) {
        //   video.currentTime = time;
    
        //   video.onseeked = () => {
        //     const context = canvas.getContext("2d");
        //     canvas.width = 540;
        //     canvas.height = 525;
        //     context.drawImage(video, 0, 0, canvas.width, canvas.height);
        //     canvas.toBlob(
        //       (blob) => {
        //         if (blob) {
        //           const file = new File([blob], "captured-frame.jpg", {
        //             type: "image/jpeg",
        //           });
        //           getImagePath(file)
        //         //   setImageFile(file);
        //         //   setImagePreview(URL.createObjectURL(file));
        //         }
        //       },
        //       "image/jpeg",
        //       0.9 
        //     );
        //   };
        // }
        const video = videoRef.current;
    const canvas = canvasRef.current;

    if (video && canvas) {
      video.currentTime = time;

      video.onseeked = () => {
        const context = canvas.getContext("2d");
        const targetWidth = 540;
        const targetHeight = 525;
        const videoAspectRatio = video.videoWidth / video.videoHeight;
        const targetAspectRatio = targetWidth / targetHeight;

        let cropWidth, cropHeight, offsetX, offsetY;

        if (videoAspectRatio > targetAspectRatio) {
          cropHeight = video.videoHeight;
          cropWidth = cropHeight * targetAspectRatio;
          offsetX = (video.videoWidth - cropWidth) / 2;
          offsetY = 0;
        } else {
          cropWidth = video.videoWidth;
          cropHeight = cropWidth / targetAspectRatio;
          offsetX = 0;
          offsetY = (video.videoHeight - cropHeight) / 2;
        }
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        context.drawImage(
          video,
          offsetX,
          offsetY,
          cropWidth,
          cropHeight,
          0,
          0,
          targetWidth,
          targetHeight
        );
        canvas.toBlob(
          (blob) => {
            if (blob) {
              const file = new File([blob], "cropped-frame.jpg", {
                type: "image/jpeg",
              });
              getImagePath(file);

            }
          },
          "image/jpeg",
          0.9 
        );
      };
    }
      };
    

    const handleVideoUpload = async ({ file, onProgress }) => {
        if (file) {
            const videoURL = URL.createObjectURL(file);
            videoRef.current.src = videoURL;
            videoRef.current.onloadedmetadata = () => {
              captureFrame(0);
            };
          }
        if (beforeUpload(file)) {
            setIsVideoUploading(true);
            try {
                const pathUri = await getVideoUrl(file,undefined, accessToken);

                if (pathUri?.data) {
                    setVideoPath({ stream_url: pathUri?.data, yt_url: "" });
                    handleCancel()
                    message.success('Video uploaded successfully!');

                }
            } catch (error) {
                message.error('Error uploading video!');
                console.error('Error uploading video:', error);
            } finally {
                setIsVideoUploading(false);
            }
        }
    };

    return (
        <Card
            title="Upload Your Video"
            bordered={true}
            style={{ maxWidth: 500, margin: '0 auto', textAlign: 'center' }}
        >
            {!videoPath?.stream_url && 
            <Upload
            accept="video/mp4"
            customRequest={handleVideoUpload}
            showUploadList={false}
            beforeUpload={beforeUpload}
        >
            <Button 
                icon={<UploadOutlined />} 
                disabled={isVideoUploading} 
                style={{ marginBottom:16 }}
            >
                {isVideoUploading ? 'Uploading...' : 'Upload Video'}
            </Button>
        </Upload>
            }
            <video ref={videoRef} style={{ display: "none" }} />
      <canvas ref={canvasRef} style={{ display: "none" }} />
      {videoPath?.stream_url && (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent:"center",
          gap:10,
          marginBottom: '10px',
          color:"blue"
        }}
      >
        <div
          
        >
          Current Video
        </div>
        <Button
          danger
          onClick={()=>{setVideoPath({...videoPath,stream_url:""});setImages([])}}
          icon={<DeleteOutlined />}
          
        >
    
        </Button>
      </div>)}
      <div></div>

            {isVideoUploading && (
                <div style={{}}>
                    <Spin />
                    {/* <Progress percent={uploadPercent} /> */}
                </div>
                
            )}
        </Card>
    );
};

export default ShortNewsVideoUpload;

import {
    Card,
    Col,
    Row,
    Select,
    Statistic,
    Input,
    List,
    Popconfirm,
    Button,
} from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import './ReportersLeadDashboard.scss'
import {
    getAppAndCitizenStringers,
    getInactiveStringers,
    getReporterStatusChanges,
    getStringerCounts,
    updateStringerType,
} from '../../AadhanApi'
import { SelectComponent } from '../AntdComponent/SelectComponent'
import { useNavigate } from 'react-router'

function ReportersDashboard() {
    const navigate = useNavigate()
    const { Option } = Select
    const { Search } = Input
    const languageData = useSelector((state) => state.app.languageData)
    const accessToken = useSelector((state) => state.app.accessToken)
    const employee_id = localStorage.getItem('EMPLOYEE_ID')

    const [selectedLanguage, setSelectedLanguage] = useState(
        languageData?.[0]?.language_id
    )
    const [state, setState] = useState(undefined)
    const [availableStates, setAvailableStates] = useState([])

    const [searchTerm, setSearchTerm] = useState('')
    const [completeMobileNumber, setCompleteMobileNumber] = useState('')
    const [searchCounter, setSearchCounter] = useState(0)

    const [stringerCounts, setStringerCounts] = useState({
        app_stringers: 0,
        citizen_reporters: 0,
    })

    const [statusChanges, setStatusChanges] = useState({
        app_stringers: { count: 0, reporters: [] },
        citizen_stringers: { count: 0, reporters: [] },
        downgraded_reporters: { count: 0, reporters: [] },
    })

    const [inactiveStringers, setInactiveStringers] = useState({
        count: 0,
        reporters: [],
    })

    const [reporterData, setReporterData] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (selectedLanguage && languageData) {
            const selectedLanguageData = languageData.find(
                (lang) => lang.language_id === selectedLanguage
            )
            if (selectedLanguageData?.states?.length) {
                setAvailableStates(selectedLanguageData.states)
                setState(selectedLanguageData.states[0]?.state_id)
            }
        }
    }, [selectedLanguage, languageData])

    useEffect(() => {
        if (selectedLanguage && state) {
            fetchStringerCounts()
        }
    }, [selectedLanguage, state])

    useEffect(() => {
        if (selectedLanguage && state) {
            fetchStatusChanges()
            fetchInactiveStringers()
        }
    }, [selectedLanguage, state])

    useEffect(() => {
        if (selectedLanguage && completeMobileNumber) {
            fetchReporterData(selectedLanguage, completeMobileNumber)
        }
    }, [completeMobileNumber, selectedLanguage, searchCounter])

    const handleEditClick = (reporterId) => {
        navigate(`/home/stringersContent?mode=edit&reporterId=${reporterId}`)
    }

    const fetchStringerCounts = async () => {
        try {
            const response = await getStringerCounts(
                selectedLanguage,
                state,
                accessToken
            )
            if (response?.data) {
                setStringerCounts(response.data)
            }
        } catch (error) {
            console.error('Error fetching stringer counts:', error)
        }
    }

    const fetchStatusChanges = async () => {
        try {
            const response = await getReporterStatusChanges(
                selectedLanguage,
                state,
                accessToken
            )
            if (response?.data) {
                setStatusChanges(response.data)
            }
        } catch (error) {
            console.error('Error fetching status changes:', error)
        }
    }

    const fetchInactiveStringers = async () => {
        try {
            const response = await getInactiveStringers(
                selectedLanguage,
                state,
                accessToken
            )
            if (response?.data?.inactive_stringers) {
                setInactiveStringers(response.data.inactive_stringers)
            }
        } catch (error) {
            console.error('Error fetching inactive stringers:', error)
        }
    }

    const handleLanguageChange = (value) => {
        setSelectedLanguage(value)
        setCompleteMobileNumber('')
        setSearchTerm('')
        setReporterData([])

        const selectedLanguageData = languageData.find(
            (lang) => lang.language_id === value
        )
        if (selectedLanguageData && selectedLanguageData.states) {
            setAvailableStates(selectedLanguageData.states)
            setState(selectedLanguageData.states[0]?.state_id)
        }
    }

    const handleStateChange = (value) => {
        setState(value)
    }

    const inactiveEmployees = useMemo(() => {
        return inactiveStringers.reporters
    }, [inactiveStringers])

    const handleUpgrade = async (userId) => {
        try {
            await updateStringerType(4, userId, employee_id, accessToken)
            await Promise.all([
                fetchStringerCounts(),
                fetchStatusChanges(),
                fetchInactiveStringers(),
                completeMobileNumber &&
                    fetchReporterData(selectedLanguage, completeMobileNumber),
            ])
        } catch (error) {
            console.error('Error upgrading stringer:', error)
        }
    }

    const handleDowngrade = async (userId) => {
        try {
            await updateStringerType(1, userId, employee_id, accessToken)
            await Promise.all([
                fetchStringerCounts(),
                fetchStatusChanges(),
                fetchInactiveStringers(),
                completeMobileNumber &&
                    fetchReporterData(selectedLanguage, completeMobileNumber),
            ])
        } catch (error) {
            console.error('Error downgrading stringer:', error)
        }
    }

    const cardTitleStyle = {
        fontSize: '1rem',
        fontWeight: '450',
        marginBottom: '0.8rem',
        color: '#1a3353',
        position: 'relative',
        paddingBottom: '0.5rem',
        borderBottom: '1px solid #e6e9ef',
    }

    const fetchReporterData = async (language_id, mobile_number) => {
        if (mobile_number && mobile_number.length !== 10) return
        try {
            setLoading(true)
            const response = await getAppAndCitizenStringers(
                language_id,
                mobile_number || '',
                accessToken
            )

            if (response?.data) {
                setReporterData(response.data)
            }
        } catch (error) {
            console.error('Error fetching reporter data:', error)
        } finally {
            setLoading(false)
        }
    }

    const renderReporterItem = (item, isSearchResult = false) => (
        <List.Item style={{ flexDirection: 'column', alignItems: 'stretch' }}>
            {isSearchResult && (
                <List.Item.Meta
                    title={item.full_name}
                    description={
                        <div style={{ marginBottom: '1rem' }}>
                            <div style={{ color: '#594e4e' }}>
                                {item.mobile_number}
                            </div>
                            <div>
                                Constituency:{' '}
                                <span style={{ color: '#594e4e' }}>
                                    {item.reporter_information?.constituencies?.join(
                                        ', '
                                    ) ||
                                        item?.constituencies?.join(', ') ||
                                        'N/A'}
                                </span>
                            </div>
                            <div>
                                District:{' '}
                                <span style={{ color: '#594e4e' }}>
                                    {item.reporter_information?.district ||
                                        item?.district ||
                                        'N/A'}
                                </span>
                            </div>
                            <div>
                                State:{' '}
                                <span style={{ color: '#594e4e' }}>
                                    {item.reporter_information?.state ||
                                        item?.state ||
                                        'N/A'}
                                </span>
                            </div>
                        </div>
                    }
                />
            )}
            {!isSearchResult && (
                <List.Item.Meta
                    title={item.full_name}
                    description={
                        <div style={{ marginBottom: '0.5rem' }}>
                            <div style={{ color: '#594e4e' }}>
                                {getStringerTypeLabel(item.stringer_type)}
                            </div>
                            <div style={{ color: '#594e4e' }}>
                                {item.mobile_number}
                            </div>
                            <div>
                                <span style={{ color: '#594e4e' }}>
                                    {item.reporter_information?.constituencies?.join(
                                        ', '
                                    ) ||
                                        item?.constituencies?.join(', ') ||
                                        'N/A'}
                                </span>
                            </div>
                        </div>
                    }
                />
            )}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignSelf: 'bottom',
                    marginRight: '0.625rem',
                    marginTop: '0.3rem',
                }}
            >
                {isSearchResult && (
                    <>
                        {item.stringer_type === 4 && (
                            <Popconfirm
                                title="Upgrade Stringer"
                                description="Are you sure to upgrade this Stringer to App Stringer?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => handleUpgrade(item.user_id)}
                            >
                                <Button
                                    style={{
                                        color: '#3f8600',
                                        marginRight: '1vw',
                                    }}
                                >
                                    Upgrade
                                </Button>
                            </Popconfirm>
                        )}
                        {item.stringer_type === 1 && (
                            <Popconfirm
                                title="Downgrade Stringer"
                                description="Are you sure to downgrade this Stringer to Citizen Stringer?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => handleDowngrade(item.user_id)}
                            >
                                <Button
                                    style={{
                                        color: '#cf1322',
                                        marginRight: '1vw',
                                    }}
                                >
                                    Downgrade
                                </Button>
                            </Popconfirm>
                        )}

                        <Button onClick={() => handleEditClick(item.user_id)}>
                            Edit
                        </Button>
                    </>
                )}
            </div>
        </List.Item>
    )

    const getStringerTypeLabel = (type) => {
        switch (type) {
            case 4:
                return 'Citizen Stringer'
            case 1:
                return 'App Stringer'
            default:
                return 'Unknown'
        }
    }

    return (
        <div
            style={{
                marginLeft: '1.3vw',
                marginTop: '1vh',
                marginRight: '1.3vw',
                backgroundColor: '#f5f7fb',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '1.7vh',
                    flexWrap: 'wrap',
                    gap: '1rem',
                }}
            >
                <h2>Reporter Lead Dashboard</h2>
                <div>
                    <Select
                        className="lang-dropdown-reportersDashboard"
                        onChange={handleLanguageChange}
                        value={selectedLanguage}
                        placeholder="Select Language"
                    >
                        {languageData.map((arr) => (
                            <Option
                                key={arr.language_id}
                                value={arr.language_id}
                            >
                                {arr.language_name}
                            </Option>
                        ))}
                    </Select>
                    <SelectComponent
                        data={availableStates}
                        value={state}
                        onChange={handleStateChange}
                        key_value={'state_id'}
                        display={'name'}
                        placeholder={'Select State'}
                        className="state-dropdown-reportersDashboard"
                    />
                </div>
            </div>
            <hr></hr>
            <div
                style={{
                    marginLeft: '1.7vw',
                    marginRight: '1.7vw',
                    marginTop: '4vh',
                }}
            >
                <Row gutter={[24, 24]}>
                    <Col span={5}>
                        <Card bordered={false} className="statistics-card">
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <div style={cardTitleStyle}>
                                    Total App Stringers
                                </div>
                                <Statistic
                                    value={stringerCounts.app_stringers}
                                    valueStyle={{
                                        fontWeight: '600',
                                        fontSize: '2rem',
                                    }}
                                />
                            </div>
                        </Card>
                    </Col>
                    <Col span={7}>
                        <Card bordered={false} className="statistics-card">
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <div style={cardTitleStyle}>
                                    Total Citizen Stringers
                                </div>
                                <Statistic
                                    value={stringerCounts.citizen_reporters}
                                    valueStyle={{
                                        fontWeight: '600',
                                        fontSize: '2rem',
                                    }}
                                />
                            </div>
                        </Card>
                    </Col>
                    <Col span={7}>
                        <Card
                            bordered={false}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                            className="statistics-card"
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <div style={cardTitleStyle}>
                                    Added this month
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        gap: '5vw',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '1vw',
                                        }}
                                    >
                                        <Statistic
                                            value={
                                                statusChanges.app_stringers
                                                    .count
                                            }
                                            valueStyle={{
                                                color: '#3f8600',
                                                fontWeight: '600',
                                                fontSize: '2rem',
                                            }}
                                        />
                                        <div>App</div>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '1vw',
                                        }}
                                    >
                                        <Statistic
                                            value={
                                                statusChanges.citizen_stringers
                                                    .count
                                            }
                                            valueStyle={{
                                                color: '#3f8600',
                                                fontWeight: '600',
                                                fontSize: '2rem',
                                            }}
                                        />
                                        <div>Citizen</div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col span={5}>
                        <Card bordered={false} className="statistics-card">
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <div style={cardTitleStyle}>
                                    Downgraded this month
                                </div>
                                <Statistic
                                    value={
                                        statusChanges.downgraded_reporters.count
                                    }
                                    valueStyle={{
                                        color: '#cf1322',
                                        fontWeight: '600',
                                        fontSize: '2rem',
                                    }}
                                />
                            </div>
                        </Card>
                    </Col>
                </Row>

                <Row gutter={[24, 24]} style={{ marginTop: '0.3vh' }}>
                    <Col span={8}>
                        <Card
                            bordered={false}
                            style={{ height: '50vh' }}
                            className="statistics-card"
                        >
                            <h3
                                style={{
                                    fontSize: '1.125rem',
                                    fontWeight: '500',
                                    marginBottom: '1rem',
                                }}
                            >
                                Search Stringer
                            </h3>
                            <Search
                                placeholder="Enter 10-digit mobile number"
                                value={searchTerm}
                                onChange={(e) => {
                                    const value = e.target.value
                                        .replace(/\D/g, '')
                                        .slice(0, 10)
                                    setSearchTerm(value)
                                    if (value.length !== 10) {
                                        setReporterData([])
                                    }
                                }}
                                onSearch={(value) => {
                                    if (value.length === 10) {
                                        setCompleteMobileNumber(value)
                                        setSearchCounter((prev) => prev + 1)
                                    }
                                }}
                                enterButton
                                className="reporter-search-input"
                            />
                            <div
                                style={{
                                    height: '30vh',
                                    overflowY: 'auto',
                                }}
                            >
                                <List
                                    loading={loading}
                                    dataSource={reporterData}
                                    renderItem={(item) =>
                                        renderReporterItem(item, true)
                                    }
                                />
                            </div>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card
                            bordered={false}
                            style={{ height: '50vh' }}
                            className="statistics-card"
                        >
                            <div style={{ display: 'flex', gap: '0.625rem' }}>
                                <h3
                                    style={{
                                        fontSize: '1.125rem',
                                        fontWeight: '500',
                                    }}
                                >
                                    Newly Added
                                </h3>
                                <p>({statusChanges.app_stringers.count})</p>
                            </div>
                            <div className="newly-added-list-container">
                                <List
                                    style={{ overflowY: 'auto' }}
                                    dataSource={
                                        statusChanges.app_stringers.reporters
                                    }
                                    renderItem={(item) => (
                                        <List.Item className="stringer-item">
                                            <div>
                                                <div className="stringer-name">
                                                    {item.full_name}
                                                </div>
                                                <div className="stringer-mobile">
                                                    {item.mobile_number}
                                                </div>
                                                <div className="stringer-date">
                                                    {new Date(
                                                        item.updated_date
                                                    ).toLocaleDateString()}
                                                </div>
                                            </div>
                                        </List.Item>
                                    )}
                                />
                            </div>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card
                            bordered={false}
                            style={{ height: '50vh' }}
                            className="statistics-card"
                        >
                            <div style={{ display: 'flex', gap: '0.625rem' }}>
                                <h3
                                    style={{
                                        fontSize: '1.125rem',
                                        fontWeight: '500',
                                        marginBottom: '1rem',
                                    }}
                                >
                                    Inactive App Stringers
                                </h3>
                            </div>
                            <div
                                style={{
                                    height: '40vh',
                                    overflowY: 'auto',
                                }}
                            >
                                <List
                                    dataSource={inactiveEmployees}
                                    renderItem={(item) =>
                                        renderReporterItem(item, false)
                                    }
                                />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default ReportersDashboard

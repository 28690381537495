import React, { useEffect, useState } from 'react'
import { Button, Modal, Image } from 'antd'
import '../devotional.scss'
import {
    CloseSquareFilled,
    LeftOutlined,
    RightOutlined,
} from '@ant-design/icons'
import MuxPlayer from '@mux/mux-player-react'

const MusicPlayer = ({
    isModalOpen,
    setIsModalOpen,
    playList,
    setPlayList,
    currentIndex,
    language
}) => {
    const [currentTrackIndex, setCurrentTrackIndex] = useState(0)

    // URL validation regex
    const tokenRegex = /token=([^&]*)/
    const playbackIdRegex = /\/([^\.]*)\.m3u8/
    const youtubeRegex = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/

    let token
    let playbackId

    useEffect(() => {
        if (isModalOpen) {
            if (currentIndex) {
                setCurrentTrackIndex(currentIndex)
            } else {
                setCurrentTrackIndex(0)
            }
        }
    }, [isModalOpen])

    useEffect(() => {
        if (currentTrackIndex === -1) {
            setIsModalOpen(false)
        }
    }, [currentTrackIndex, setIsModalOpen])

    // Validate the video URL
    const validateURL = (videoPath) => {
        const isYoutube = youtubeRegex.test(videoPath)
        if (
            videoPath &&
            !isYoutube &&
            videoPath.includes('token') &&
            videoPath.includes('.m3u8')
        ) {
            token = videoPath.match(tokenRegex)[1]
            playbackId = videoPath.match(playbackIdRegex)[1]
        }
        return playbackId && token
    }

    const handleOk = () => {
        setIsModalOpen(false)
    }

    const handleCancel = () => {
        setCurrentTrackIndex(-1)
        setPlayList([{ audio: '' }])
    }

    const handleClickPrevious = () => {
        setCurrentTrackIndex((currentTrack) =>
            currentTrack === 0 ? playList.length - 1 : currentTrack - 1
        )
    }

    const handleClickNext = () => {
        setCurrentTrackIndex((currentTrack) =>
            currentTrack < playList.length - 1 ? currentTrack + 1 : 0
        )
    }
    return (
        <Modal
            footer={null}
            width={350}
            closable={false}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            closeIcon={<Button type="text">X</Button>}
        >
            {isModalOpen && (
                <div>
                    <div
                        onClick={handleCancel}
                        style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            top: -2,
                            borderTopRightRadius: 10,
                            overflow: 'hidden',
                            zIndex: 10,
                            right: -2,
                        }}
                    >
                        <CloseSquareFilled
                            style={{ color: 'red', fontSize: 24 }}
                        />
                    </div>
                    <div
                        style={{
                            textAlign: 'center',
                            marginBottom: 10,
                            position: 'relative',
                        }}
                    >
                        <Image
                            src={playList?.[currentTrackIndex]?.['metadata']?.[language]?.image_url}
                            alt="Album Art"
                            width={'100%'}
                            preview={false}
                            style={{
                                borderRadius: '10px',
                                boxShadow: '1px 1px 2px lightgray',
                                overflow: 'hidden',
                            }}
                        />
                    </div>

                    <div>
                        {/* Check if the URL is valid and render the MuxPlayer */}
                        {validateURL(playList?.[currentTrackIndex]?.['metadata']?.[language]?.audio_url) &&
                        playbackId !== '' &&
                        token !== undefined ? (
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        backgroundColor: 'black',
                                        borderRadius: 5,
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Button
                                        onClick={handleClickPrevious}
                                        type="primary"
                                        style={{
                                            height: 38,
                                            backgroundColor: 'black',
                                        }}
                                        icon={<LeftOutlined />}
                                    />{' '}
                                    <MuxPlayer
                                    
                                        audio
                                        autoPlay
                                        style={{ height: '100%' ,backgroundColor:"red"}}
                                        playbackId={playbackId}
                                        tokens={{ playback: token }}
                                    />{' '}
                                    <Button
                                        type="primary"
                                        style={{
                                            height: 38,
                                            backgroundColor: 'black',
                                        }}
                                        onClick={handleClickNext}
                                        icon={<RightOutlined />}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div>
                                {/* You can add an alternative content here if the URL is not valid */}
                                <span>Invalid video URL</span>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </Modal>
    )
}

export default MusicPlayer

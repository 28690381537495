import './App.scss'
import AppRoute from './components/AppRoute/AppRoute'

const App = () => {
    return (
        <div className="app-container">
            <AppRoute />
        </div>
    )
}

export default App

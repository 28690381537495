import { Select, message, Space, Tooltip, Modal, Button } from 'antd'
import CommonTable from '../CommonTable/CommonTable'
import { InfoCircleOutlined, EditOutlined } from '@ant-design/icons'
import {
    getReporterInvoices,
    generateInvoices,
    updateInvoiceStatus,
    updatePayRollStatus,
    downloadStatement,
} from '../../AadhanApi'
// import * as XLSX from 'xlsx';
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { invoiceStatusOptions } from './Billing.constant'
import Invoice from './Invoice'
import AddBonusForm from './AddBonusForm'
import './Billing.scss'
const Billing = () => {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    const [reportsData, setReportsData] = useState([])
    const currentMonth = currentDate.getMonth()
    const [selectedRecord, setSelectedRecord] = useState(null)
    const [dataLoading, setDataLoading] = useState(false)
    const accessToken = useSelector((state) => state.app.accessToken)
    const [messageApi, contextHolder] = message.useMessage()
    const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1
    const previousMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear
    const [selectedYear, setSelectedYear] = useState(previousMonthYear)
    const [selectedMonth, setSelectedMonth] = useState(previousMonth)
    const [user, setUser] = useState(null)
    const [showRoleModal, setShowRoleModal] = useState(false)
    const [selectedInvoiceStatus, setSelectedInvoiceStatus] = useState(null)
    const [selectedPayRollStatus, setSelectedPayRollStatus] = useState(null)
    const allStates = useSelector((state) => state.app.locations)
    const [allrecord, setallrecord] = useState([])
    const [state, setState] = useState('all')
    const openModal = (record) => {
        setShowRoleModal(true)
        setSelectedRecord(record)
    }
    const handleInvoiceStatusChange = (value, record) => {
        setSelectedInvoiceStatus(value)
        setSelectedRecord(record)

        setUser([record?.user_id])

        if (record?.user_id) {
            updateStatus(value, [record?.user_id])
        }
    }

    const reporterInvoiceTableColumns = [
        {
            title: 'Statement',
            dataIndex: 'invoice_id',
            key: 'invoice_id',
        },
        {
            title: 'Name',
            dataIndex: 'full_name',
            key: 'full_name',
            render: (text, record) => {
                return (
                    <Space>
                        {text}
                        <Tooltip
                            title={
                                (record?.payement_info?.account_number
                                    ? `Account Number: ${record.payement_info.account_number}, `
                                    : '') +
                                (record?.payement_info?.ifsc_code
                                    ? `IFSC Code: ${record.payement_info.ifsc_code}, `
                                    : '') +
                                (record?.payement_info?.pan_number
                                    ? `PAN Number: ${record.payement_info.pan_number}, `
                                    : '') +
                                (record?.payement_info?.gst_number
                                    ? `GST Number: ${record.payement_info.gst_number}`
                                    : '')
                            }
                        >
                            <InfoCircleOutlined />
                        </Tooltip>
                    </Space>
                )
            },
        },
        {
            title: 'Phone',
            dataIndex: 'mobile_number',
            key: 'mobile_number',
        },
        {
            title: 'Image',
            dataIndex: 'content_with_images',
            key: 'content_with_images',
        },
        {
            title: 'Video',
            dataIndex: 'content_with_videos',
            key: 'content_with_videos',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            defaultSortOrder: 'descend',
            sorter: (a, b) => Number(a.amount) - Number(b.amount),
            sortDirections: ['descend', 'ascend'],
        },

        {
            title: 'Bonus',
            dataIndex: 'bonus',
            key: 'bonus',
            render: (_, record) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <p style={{ marginBottom: '0px' }}>
                            {record?.bonus_amount}
                        </p>
                        <div
                            style={{
                                display: 'flex',
                                gap: '5px',
                                alignItems: 'center',
                            }}
                        >
                            {selectedPayRollStatus !== 'processed' &&
                                selectedPayRollStatus !== 'paid' && (
                                    <EditOutlined
                                        style={{ paddingRight: '5px' }}
                                        onClick={() => {
                                            if (
                                                selectedPayRollStatus !==
                                                    'processed' &&
                                                selectedPayRollStatus !== 'paid'
                                            ) {
                                                openModal(record)
                                            }
                                        }}
                                        disabled={
                                            selectedPayRollStatus === 'paid' ||
                                            selectedPayRollStatus ===
                                                'processed'
                                        }
                                    />
                                )}
                        </div>
                    </div>
                )
            },
        },
        {
            title: 'Status',
            dataIndex: 'invoice_status',
            key: 'invoice_status',
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        gap: '5px',
                        alignItems: 'center',
                    }}
                >
                    <Select
                        value={record?.invoice_status}
                        style={{ width: 120 }}
                        onChange={(value) =>
                            handleInvoiceStatusChange(value, record)
                        }
                    >
                        {invoiceStatusOptions.map((invoiceStatus) => {
                            if (
                                selectedPayRollStatus === 'processed' ||
                                selectedPayRollStatus === 'paid'
                            ) {
                                if (
                                    (selectedPayRollStatus === 'processed' &&
                                        invoiceStatus.label !== 'Inprocess') ||
                                    (selectedPayRollStatus === 'paid' &&
                                        invoiceStatus.label !== 'Inprocess' &&
                                        invoiceStatus.label !== 'Processed')
                                ) {
                                    return (
                                        <Select.Option
                                            key={invoiceStatus.value}
                                            value={invoiceStatus.value}
                                        >
                                            {invoiceStatus.label}
                                        </Select.Option>
                                    )
                                }
                            } else {
                                return (
                                    <Select.Option
                                        key={invoiceStatus.value}
                                        value={invoiceStatus.value}
                                    >
                                        {invoiceStatus.label}
                                    </Select.Option>
                                )
                            }
                        })}
                    </Select>
                </div>
            ),
        },
    ]

    useEffect(() => {
        getBillingDetails()
    }, [selectedMonth,selectedYear, state])

    useEffect(() => {
        if (selectedPayRollStatus) {
            updateStatusPayroll(selectedPayRollStatus)
        }
    }, [selectedPayRollStatus])

    const statementDownload = () => {
        downloadStatements()
    }

    const handleStatus = (status) => {
        const allUserIds = reportsData.map((record) => record.user_id)
        updateStatus(status, allUserIds)
        setUser(allUserIds)
        setSelectedInvoiceStatus(status)
    }

    const updateStatusPayroll = async (status) => {
        setDataLoading(true)
        try {
            await updatePayRollStatus(selectedMonth+1, selectedYear, status, accessToken)
            getBillingDetails(true)
        } catch (err) {
            setDataLoading(false)
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to update status',
                duration: 5,
            })
        }
    }

    const downloadStatements = async () => {
        setDataLoading(true)
        try {
            await downloadStatement(
                selectedMonth+1,
                selectedYear,
                state === 'all' ? '' : state,
                accessToken
            )
            getBillingDetails()
        } catch (err) {
            setDataLoading(false)
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to update status',
                duration: 5,
            })
        }
    }
    const updateStatus = async (invoice_status, id) => {
        setDataLoading(true)
        try {
            await updateInvoiceStatus(
                selectedMonth+1,
                selectedYear,
                invoice_status,
                id,
                accessToken
            )
            getBillingDetails()
            setDataLoading(false)
        } catch (err) {
            setDataLoading(false)
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to update status',
                duration: 5,
            })
        }
    }
    const getBillingDetails = async (generate = false) => {
        setDataLoading(true)
        try {
            const response = await getReporterInvoices(
                selectedMonth+1,
                selectedYear,
                generate,
                state,
                accessToken
            )

            setReportsData(Object.values(response.data.data.invoices))
            if (state === 'all') {
                setallrecord(Object.values(response.data.data.invoices))
            }
            setSelectedPayRollStatus(response.data.data.payroll_status)
            setDataLoading(false)
        } catch (err) {
            setReportsData([])
            setDataLoading(false)
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to get reports',
                duration: 5,
            })
        }
    }

    const generateStatement = async (generate = true) => {
        setState('all')
        setDataLoading(true)
        try {
            const response = await generateInvoices(
                selectedMonth+1,
                selectedYear,
                generate,
                accessToken
            )
            setReportsData(Object.values(response.data.data.invoices))
            setSelectedPayRollStatus(response.data.data.payroll_status)
            setDataLoading(false)
        } catch (err) {
            setReportsData([])
            setSelectedPayRollStatus('inprocess')
            setDataLoading(false)
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to get reports',
                duration: 5,
            })
        }
    }
    const bonusModal = (
        <Modal
            open={showRoleModal}
            title="Edit Bonus"
            onCancel={() => setShowRoleModal(false)}
            footer={[
                <Button
                    form="roleForm"
                    key="submit"
                    htmlType="submit"
                    type="primary"
                >
                    Add
                </Button>,
            ]}
        >
            <AddBonusForm
                selectedRecord={selectedRecord}
                getTableData={getBillingDetails}
                month={selectedMonth}
                year={selectedYear}
                selectedRole={false}
                setShowModal={setShowRoleModal}
                accessToken={accessToken}
            />
        </Modal>
    )

    // const exportToExcel = () => {
    //     const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    //     const fileExtension = '.xlsx';
    //     const fileName = 'data';

    //     const ws = XLSX.utils.json_to_sheet(reportsData);
    //     const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    //     const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    //     const dataExcel = new Blob([excelBuffer], {type: fileType});
    //     const url = URL.createObjectURL(dataExcel);
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', fileName + fileExtension);
    //     document.body.appendChild(link);
    //     link.click();
    // }

    const handleDownload = () => {
        // Data to be included in the HTML file
        const name = 'John' // Replace with the dynamic value you want to pass

        const dynamicContent = Invoice(name)

        // Create a Blob with the HTML content
        const blob = new Blob([dynamicContent], { type: 'text/html' })

        // Create a download link
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'generated_file.html'

        // Append the link to the document
        document.body.appendChild(link)

        // Simulate a click on the link to trigger the download
        link.click()

        // Remove the link from the document
        document.body.removeChild(link)
    }

    

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]

   

    const handleYearChange = (value) => {
        setSelectedYear(value)
        setSelectedMonth(0)
    }

    const handleMonthChange = (value) => {
        setSelectedMonth(value)
    }
    // console.log(new Date().getFullYear(),currentYear)
    const { Option } = Select
    return (
        <div className="report-container">
            <div className="content-header">
                <h2>Billing</h2>
            </div>
            <div
                style={{ display: 'flex', alignItems: 'center' }}
                className="manage-content-inputs"
            >
                <Select
                    value={selectedYear}
                    style={{ width: '100%' }}
                    onChange={handleYearChange}
                >
                    <Option value={currentYear - 1}>{currentYear - 1}</Option>
                    <Option value={currentYear}>{currentYear}</Option>
                </Select>
                <Select
                    value={selectedMonth}
                    style={{ width: '100%' }}
                    onChange={handleMonthChange}
                >
                    {selectedYear === currentYear
                        ? months
                              .slice(0, currentMonth + 1)
                              .map((month, index) => (
                                  <Option key={index} value={index}>
                                      {month}
                                  </Option>
                              ))
                        : months.map((month, index) => (
                              <Option key={index} value={index}>
                                  {month}
                              </Option>
                          ))}
                </Select>

                <Select
                    disabled={allrecord.length !== 0 ? false : true}
                    value={state}
                    onChange={(e) => setState(e)}
                    style={{ width: '200px' }}
                >
                    <Select key={'all'} value={'all'}>
                        {'All'}
                    </Select>
                    {allStates.map((e) => (
                        <Select.Option value={e.state_id} key={e.state_id}>
                            {e.name}
                        </Select.Option>
                    ))}
                </Select>
                <button
                    className={`generate-statement ${
                        allrecord.length !== 0 ? 'disabled-statement' : ''
                    }`}
                    onClick={() => generateStatement()}
                >
                    <span>Generate Statement</span>
                </button>
                <button
                    className={`run-payroll ${
                        selectedPayRollStatus === 'processed' ||
                        selectedPayRollStatus === 'paid' ||
                        reportsData.length === 0
                            ? 'disabled-statement'
                            : ''
                    }`}
                    onClick={() => {
                        setSelectedPayRollStatus('processed')
                        handleStatus('processed')
                    }}
                >
                    <span>Run payroll</span>
                </button>
                <button
                    className={`close-payroll ${
                        selectedPayRollStatus === 'paid' ||
                        selectedPayRollStatus === 'inprocess' ||
                        reportsData.length === 0
                            ? 'disabled-statement'
                            : ''
                    }`}
                    onClick={() => {
                        setSelectedPayRollStatus('paid')
                        handleStatus('paid')
                    }}
                >
                    <span>Close payroll</span>
                </button>
                <button
                    className={`download ${
                        reportsData.length === 0 ? 'disabled-statement' : ''
                    }`}
                    onClick={() => statementDownload()}
                >
                    <span>Download</span>
                </button>

                {/* <button onClick={handleDownload}>Download HTML</button> */}
            </div>
            <CommonTable
                columns={reporterInvoiceTableColumns}
                data={reportsData}
                dataLoading={dataLoading}
                disablePagination={true}
            />
            {bonusModal}
            {/* {reportsData?.length > 0 &&
                <button className='export-to-csv' onClick={exportToExcel}>
                    <span>Export to Excel</span>
                </button>
            } */}
        </div>
    )
}

export default Billing

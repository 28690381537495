import React, { useEffect, useState } from "react";
import { Modal, Button } from "antd";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { getImageUrl } from "../../AadhanApi";
import { EditFilled } from "@ant-design/icons";
import './AddShortNewsForm/AddShortNewsForm.scss'

const EditCropper = ({ imageUrls, index, accessToken, languageOption, onUpdate }) => {
  const [isCropperOpen, setIsCropperOpen] = useState(false);
  const [cropperInstance, setCropperInstance] = useState(null);
  const [croppingImage, setCroppingImage] = useState(imageUrls[index]);
  useEffect(()=>{
    setCroppingImage(imageUrls[index])
  },[isCropperOpen])

  const handleSave = async () => {
    if (cropperInstance) {
      const croppedCanvas = cropperInstance.getCroppedCanvas();
      const file = await new Promise((resolve) =>
        croppedCanvas.toBlob((blob) => resolve(new File([blob], "cropped.jpg", { type: "image/jpeg" })))
      );

      try {
        const pathUriArr = await getImageUrl(file, "shortnews", accessToken, languageOption);
        const updatedUrls = [...imageUrls];
        updatedUrls[index] = pathUriArr?.data;
        onUpdate(updatedUrls); // Callback to update the parent component state
        setIsCropperOpen(false); // Close the modal after saving
      } catch (error) {
        console.error("Error uploading cropped image:", error);
      }
    }
  };

  return (
    <div>
      {/* Button to open the cropper modal */}
      <div type="primary" onClick={() => setIsCropperOpen(true)}>
        < EditFilled  className='icon-hover'  />
      </div>

      {/* Modal containing the cropper */}
      <Modal
        title="Edit Image"
        open={isCropperOpen}
        onCancel={() => setIsCropperOpen(false)}
        footer={null}
         // Adjust width as needed
      >
        <Cropper
          src={croppingImage}
          style={{ height: 400, width: "100%" }}
          initialAspectRatio={540 / 525}
          width={320}
          height={220}
          aspectRatio={540 / 525}
          viewMode={1}
          dragMode="move"
          cropBoxResizable={false}
          cropBoxMovable={false}
          center={true}
          toggleDragModeOnDblclick={false}
          checkOrientation={true}
          onInitialized={(instance) => setCropperInstance(instance)}
          maxCropBoxWidth={540}
          maxCropBoxHeight={525}
          minCropBoxWidth={320}
          minCropBoxHeight={220}
          
        />
        <div style={{ marginTop: "10px", textAlign: "right" }}>
          <Button onClick={() => setIsCropperOpen(false)} style={{ marginRight: "10px" }}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default EditCropper;

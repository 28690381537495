export const textStartX = {
    1: 113,
    2: 110,
    3: 103,
    4: 120,
    default: 113,
}

export const TEXT_END_X = 290

export const LINE_HEIGHT = {
    4: 18.5,
    default: 26,
}

export const TEXT_BASED_INDICES = {
    2: [0, 1, 8],
}

export const TIME_BASED_INDICES = {
    default: 3,
}

export const FIXED_GAP_BETWEEN_ENTRIES = 25.8

export const languageContent = {
    1: {
        heading: 'Horoscope',
        heading2: 'Panchangam',
        labels: [
            'Aries',
            'Taurus',
            'Gemini',
            'Cancer',
            'Leo',
            'Virgo',
            'Libra',
            'Scorpio',
            'Sagittarius',
            'Capricorn',
            'Aquarius',
            'Pisces',
        ],
        fontProperties: {
            heading: {
                fontFamily: 'Ponnala',
                fontSize: '1.74rem',
            },
            labels: {
                fontFamily: 'Mandali',
                fontSize: '0.87rem',
            },
            values: {
                timeBasedFont: {
                    fontFamily: 'KohinoorLatin2',
                    fontSize: '0.75rem',
                },
                textBasedFont: {
                    fontFamily: 'KohinoorLatin2',
                    fontSize: '0.75rem',
                },
            },
        },
    },
    2: {
        heading: 'రాశిఫలాలు',
        heading2: 'పంచాంగం',
        labels: [
            'తిథి',
            'నక్షత్రం',
            'వర్జ్యం',
            'బ్రహ్మ ముహూర్తం',
            'రాహు కాలం',
            'యమగండం',
            'అమృత కాలం',
            'దుర్ముహుర్తం',
            'ఈరోజు ప్రత్యేకం',
        ],
        fontProperties: {
            heading: {
                fontFamily: 'Ponnala',
                fontSize: '1.74rem',
            },
            labels: {
                fontFamily: 'Mandali',
                fontSize: '0.87rem',
            },
            values: {
                timeBasedFont: {
                    fontFamily: 'KohinoorLatin2',
                    fontSize: '0.75rem',
                },
                textBasedFont: {
                    fontFamily: 'KohinoorLatin2',
                    fontSize: '0.75rem',
                },
            },
        },
    },

    3: {
        heading: 'राशिफल',
        heading2: 'पंचांग ',
        labels: [
            'शुभ समय',
            'राहु काल',
            'शूल',
            'योग',
            'यमगंड',
            'कुलिक',
            'नक्षत्र',
            'तिथि',
            'चंद्राष्टम',
        ],
        fontProperties: {
            heading: {
                fontFamily: 'Yatra One',
                fontSize: '1.95rem',
            },
            labels: {
                fontFamily: 'Devanagari Sangam MN',
                fontSize: '0.87rem',
            },

            values: {
                timeBasedFont: { fontFamily: 'Mallanna', fontSize: '0.87rem' },
                textBasedFont: { fontFamily: 'Mallanna', fontSize: '0.87rem' },
            },
        },
    },
    4: {
        heading: 'ராசிபலன்',
        heading2: 'பஞ்சாங்கம்',

        labels: [
            'நல்ல நேரம்',
            'இராகு காலம்',
            'எமகண்டம்',
            'குளிகை',
            'சூலம்',
            'நட்சத்திரம்',
            'திதி',
            'யோகம்',
            'சந்திராஷ்டமம்',
        ],
        fontProperties: {
            heading: {
                fontFamily: 'Tamil Sangam MN',
                fontSize: '1.25rem',
            },
            labels: {
                fontFamily: 'Roboto Condensed',
                fontSize: '0.73rem',
            },
            values: {
                timeBasedFont: {
                    fontFamily: 'Mallanna',
                    fontSize: '0.75rem',
                },
                textBasedFont: {
                    fontFamily: 'Mallanna',
                    fontSize: '0.85rem',
                },
            },
        },
    },
}

export const allMonths = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]

const OpenAI = require('openai');
const openai = new OpenAI({
    apiKey: 'sk-proj-vxH2FBo5vM79zKwK1RQ7T3BlbkFJUu8EaHRK5T7toUypZxsN',
    dangerouslyAllowBrowser: true,
});

export default async function getApiResponse(message) {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 30000); // Abort after 30 seconds

    try {
        const response = await openai.chat.completions.create(message, {
            signal: controller.signal,
        });

        clearTimeout(timeoutId); // Clear timeout if the API call succeeds

        if (response.choices && response.choices.length > 0) {
            return response?.choices[0]?.message?.content;
        }
    } catch (error) {
        if (error.name === 'AbortError') {
            console.error('Request aborted due to timeout.');
        } else {
            console.error('Error:', error);
        }
        return "";
    }
}

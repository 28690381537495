import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import './ShortNewsToSnippets.scss'
import { message, Select } from 'antd'
import dayjs from 'dayjs'
import Viewicon from '../../../assets/view_icon.svg'
import Uploadedicon from '../../../assets/uploaded_icon.svg'
import { PlayCircleOutlined, YoutubeFilled } from '@ant-design/icons'
import { getSnippetCategories, getUnsyncedShortNews } from '../../../AadhanApi'
import PreviewCorousel from '../../PreviewCorousel/PreviewCorousel'
import CommonTable from '../../CommonTable/CommonTable'
import ManageContentDateRange from '../../ManageContent/ManageContentDateRange'
import ShortNewsToSnippetsFunc from './ShortNewsToSnippetsFunc'

function ShortNewsToSnippets() {
    const languageData = useSelector((state) => state.app.languageData)
    const token = useSelector((state) => state.app.accessToken)
    const { Option } = Select
    const [selectedLanguage, setSelectedLanguage] = useState(
        languageData?.[0]?.language_id
    )
    const [categoryOption, setCategoryOption] = useState(99999)
    const [categories, setCategories] = useState([])
    const [categoriesLoading, setCategoriesLoading] = useState(false)
    const [contentId, setContentId] = useState('')
    const [dataLoading, setDataLoading] = useState(false)

    const [dates, setDates] = useState({
        startDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        endDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    })

    const [shortNewsData, setShortNewsData] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false)

    const addDates = (startDate, endDate) => {
        let updatedDates = { startDate: startDate, endDate: endDate }
        setDates(updatedDates)
    }

    const clearDates = () => {
        const updatedDates = {
            startDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            endDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        }
        setDates(updatedDates)
    }

    const handleLanguageChange = (value) => {
        setSelectedLanguage(value)
    }

    const handleCategoryChange = (event) => {
        setCategoryOption(event)
    }

    const viewPopup = (id) => {
        setContentId(id)
        setIsModalOpen(true)
    }

    const fetchCategories = async (languageId) => {
        if (!languageId) return

        try {
            setCategoriesLoading(true)
            const response = await getSnippetCategories(
                languageId,
                'all',
                token
            )
            console.log(response.data.data)
            if (response.data.success) {
                setCategories(response.data.data)
            }
        } catch (error) {
            console.error('Error fetching categories:', error)
            message.error('Failed to fetch categories')
            setCategories([])
        } finally {
            setCategoriesLoading(false)
        }
    }

    useEffect(() => {
        fetchCategories(selectedLanguage)
    }, [selectedLanguage])

    const getShortNewsData = async () => {
        const startDateFormat = dayjs(dates?.startDate).format('YYYY-MM-DD')
        const endDateFormat = dayjs(dates?.endDate).format('YYYY-MM-DD')
        setDataLoading(true)

        try {
            const shortNewsDataResponse = await getUnsyncedShortNews(
                selectedLanguage,
                140,
                startDateFormat,
                endDateFormat,
                1,
                2000,
                token,
                ''
            )

            setShortNewsData(
                shortNewsDataResponse?.data?.sort((a, b) =>
                    dayjs(b?.uploaded_date).isAfter(dayjs(a?.uploaded_date))
                        ? 1
                        : -1
                )
            )
            setDataLoading(false)
        } catch (err) {
            console.log('error is ::::', err)
            setDataLoading(false)
            if (err?.response?.status === 401) {
                message.error('You are unauthorised, Please login again')
            } else {
                message.error('Failed to get short news data')
            }
        }
    }

    useEffect(() => {
        getShortNewsData()
    }, [selectedLanguage, categoryOption, dates])

    const ShortNewsColumns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => a.title.localeCompare(b.title),
            sortDirections: ['ascend', 'descend'],
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                        wordBreak: 'break-all',
                    }}
                >
                    <p
                        style={{ color: 'blue', margin: 0, cursor: 'pointer' }}
                        href="/"
                        onClick={() => viewPopup(record.content_id)}
                    >
                        {record?.title}
                    </p>
                    <span style={{ fontSize: '1.5rem' }}>
                        {record?.stream_video_url ? (
                            <PlayCircleOutlined style={{ color: 'blue' }} />
                        ) : record?.yt_video_url ? (
                            <YoutubeFilled />
                        ) : (
                            ''
                        )}
                    </span>
                </div>
            ),
            width: '28%',
        },
        {
            title: 'Uploaded By',
            dataIndex: 'uploaded_by',
            key: 'uploaded_by',
            width: '14%',
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                    }}
                >
                    <p style={{ margin: '0px' }}>
                        {record?.created_type === 'reporter' && (
                            <img
                                style={{ width: '1rem', marginRight: '9px' }}
                                alt="ViewIcon"
                                src={Uploadedicon}
                            />
                        )}
                        {record?.uploaded_by}
                    </p>
                </div>
            ),
        },
        {
            title: 'Category',
            dataIndex: 'category_name',
            key: 'category_name',
            width: '13%',
        },
        {
            title: 'Uploaded Date',
            dataIndex: 'uploaded_date',
            key: 'uploaded_date',
            width: '17%',
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                    }}
                >
                    {dayjs(record?.uploaded_date).format(
                        'DD MMM YYYY, hh:mm a'
                    )}
                    {dayjs(record?.published_date).diff(dayjs(), 'min') > 0 && (
                        <div
                            style={{
                                backgroundColor: '#a4c9a4',
                                border: '0.5px solid black',
                                borderRadius: '5px',
                                paddingLeft: '4px',
                                width: '80%',
                            }}
                        >
                            Scheduled :{' '}
                            {dayjs(record?.published_date).format(
                                'DD MMM YYYY, hh:mm a'
                            )}
                        </div>
                    )}
                </div>
            ),
        },
        {
            title: 'Proof Read By',
            key: 'proof_read_by',
            dataIndex: 'proof_read_by',
            width: '14%',
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            width: '14%',
            render: (_, record) => (
                <div
                    size="middle"
                    style={{
                        display: 'flex',
                        fontSize: '1.2rem',
                        gap: '10px',
                        justifyContent: 'center',
                    }}
                >
                    <a
                        href={record.dynamic_url}
                        target="blank"
                        className="view-icon"
                    >
                        <img
                            style={{ width: '1rem' }}
                            alt="ViewIcon"
                            src={Viewicon}
                        />
                    </a>
                    <ShortNewsToSnippetsFunc
                        record={record}
                        language={record.language_name}
                        categories={categories}
                        categoriesLoading={categoriesLoading}
                        onMoveSuccess={() => {
                            message.success('Successfully moved to snippets')
                            getShortNewsData()
                        }}
                        onMoveError={() =>
                            message.error('Failed to move to snippets')
                        }
                    />
                </div>
            ),
        },
    ]

    return (
        <div className="moveShortnews-container">
            <h2>Move Short News</h2>
            <hr></hr>
            <div className="moveShortnews-dropdowns">
                <div>
                    <Select
                        className="moveShortnews-dropdown-1"
                        onChange={handleLanguageChange}
                        value={selectedLanguage}
                        placeholder="Select Language"
                    >
                        {languageData.map((arr) => (
                            <Option
                                key={arr.language_id}
                                value={arr.language_id}
                            >
                                {arr.language_name}
                            </Option>
                        ))}
                    </Select>
                    <Select
                        value={categoryOption}
                        onChange={handleCategoryChange}
                        className="moveShortnews-dropdown-2"
                        placeholder="Select category"
                    >
                        <Option key={'health'} value={99999}>
                            Health
                        </Option>
                    </Select>
                </div>
                <div className="moveShortnews-date-picker">
                    <span style={{ marginRight: '5px' }}>Date Range :</span>
                    <ManageContentDateRange
                        dates={dates}
                        addDates={addDates}
                        clearDates={clearDates}
                    />
                </div>
            </div>
            <CommonTable
                data={shortNewsData}
                columns={ShortNewsColumns}
                dataLoading={dataLoading}
            ></CommonTable>
            {isModalOpen && (
                <PreviewCorousel
                    key={contentId}
                    contentId={contentId}
                    tableType="shortnews"
                    data={shortNewsData}
                />
            )}
        </div>
    )
}

export default ShortNewsToSnippets

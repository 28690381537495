import React, { useEffect, useState } from 'react'
import { Button, Modal, Form, Input, Select, Input as AntInput } from 'antd'
import { createPlaylist, updateplaylist } from '../../../AadhanApi'
import { useSelector } from 'react-redux'
import { EditFilled } from '@ant-design/icons'

const AddPlaylistModal = ({
    getAllPlaylistsfunction,
    languageData,
    record,
    mode,
    languageId,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const accessToken = useSelector((state) => state.app.accessToken)
    const [form] = Form.useForm()

    const showModal = () => {
        setIsModalOpen(true)
    }
    useEffect(() => {
        form.setFieldsValue({
            title: record?.title,
            display_name: record?.display_name,
            language_id: languageId,
        })
    }, [record, languageId])

    const handleOk = (type) => {
        form.validateFields()
            .then(async (values) => {
                values['content_status'] = type
                if (mode === 'edit') {
                    values['playlist_id'] = record?.playlist_id
                    values['created_at'] = record?.created_at
                    values['song_ids'] = record?.song_ids
                    const res = await updateplaylist(
                        accessToken,
                        values,
                        record?.playlist_id
                    )
                } else {
                    values['song_ids'] = []

                    const res = await createPlaylist(accessToken, values)
                }
                getAllPlaylistsfunction()
                setIsModalOpen(false)
            })
            .catch((info) => {
                console.log('Validate Failed:', info)
            })
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }
    return (
        <>
            {mode === 'edit' ? (
                <Button
                    icon={<EditFilled />}
                    type="primary"
                    onClick={showModal}
                ></Button>
            ) : (
                <Button type="primary" onClick={showModal}>
                    Add Playlist
                </Button>
            )}
            <Modal
                title="Add Playlist"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: 10,
                            marginTop: 30,
                        }}
                    >
                        <Button
                            type="primary"
                            danger
                            style={{ width: 100 }}
                            key="cancel"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={record?.content_status === 'approved'}
                            style={{
                                width: 100,
                                background:
                                    record?.content_status === 'approved'
                                        ? 'lightgray'
                                        : '',
                            }}
                            key="draft"
                            type="primary"
                            onClick={() => handleOk('draft')}
                        >
                            Save
                        </Button>
                        <Button
                            style={{ width: 100, backgroundColor: 'blue' }}
                            key="approved"
                            type="primary"
                            onClick={() => handleOk('approved')}
                        >
                            Publish
                        </Button>
                    </div>,
                ]}
            >
                <Form
                    form={form}
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    layout="horizontal"
                    name="add_playlist"
                >
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter a playlist title',
                            },
                        ]}
                    >
                        <Input placeholder="Enter playlist title" />
                    </Form.Item>
                    <Form.Item
                        label="Display Name"
                        name="display_name"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter a display name',
                            },
                        ]}
                    >
                        <Input placeholder="Enter display name" />
                    </Form.Item>

                    <Form.Item
                        label="Language"
                        name="language_id"
                        rules={[
                            {
                                required: true,
                                message: 'Please select a language',
                            },
                        ]}
                    >
                        <Select>
                            {languageData?.map((e) => (
                                <Select.Option
                                    value={e?.language_id}
                                    key={e?.language_id}
                                >
                                    {e.language_name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default AddPlaylistModal

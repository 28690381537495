import React, { useEffect, useState } from 'react'
import { getReporterTableData } from '../../AadhanApi'
import { useSelector } from 'react-redux'
import { Button, Input, message, Modal, Popover, Select, Switch, Tabs } from 'antd'
import { useLocation, useNavigate } from 'react-router'
import { Option } from 'antd/es/mentions'
import './StaffStringers.scss'
import Search from 'antd/es/input/Search'
import EditableTable from './EditableTable'
import TabPane from 'antd/es/tabs/TabPane'
const StaffStringers = () => {
    const accessToken = useSelector((state) => state.app.accessToken)
    const location = useLocation()
    const [dataLoading, setDataLoading] = useState(false)
    const [useMobileNumber, setUseMobileNumber] = useState(true)
    const [mobileNumber, setMobileNumber] = useState('')
    const locations = useSelector((state) => state.app.locations)
    const allDistricts = useSelector((state) => state.app.districts)
    const [data, setData] = useState([])
    const navigate = useNavigate()
    const [districtData, setDistrictData] = useState([])
    const [typeOption, setTypeOption] = useState('all')
    const [state, setState] = useState(undefined)
    const [district, setDistrict] = useState(undefined)
    const [mobile, setMobile] = useState('')
    const [name, setName] = useState('')
    const [visible, setVisible] = useState(false)
    const getStringerDetail = async () => {
        const data = await getReporterTableData(
            'all',
            mobileNumber,
            '',
            accessToken,
            '',
            '',
            undefined
        )
        if (data.data.length === 0) {
            return 'Stringer not found'
        } else if (
            data.data[0] &&
            data?.data?.[0]?.stringer_type === 1 &&
            data?.data?.[0]?.reporter_information?.compensation === true
        ) {
            return 'User is already assigned to the app stringers'
        } else {
            return data?.data?.[0]
        }
    }
    const handleStateChange = (event) => {
        setDistrict(undefined)
        const districts = allDistricts?.[event]
        setDistrictData(districts)
        setState(event)
    }
    async function getData() {
        setDataLoading(true)
        const data = await getReporterTableData(
            typeOption,
            mobile,
            name,
            accessToken,
            state,
            district,
            3
        )
        setData(data?.data)
        setDataLoading(false)
    }
    useEffect(() => {
        getData()
    }, [location.pathname, state, district])

    const handleNext = async () => {
        if (!useMobileNumber) {
            message.success('Employee verified succefully')
            navigate(
                `/home/manage-staff-stringers/onboard-employee-to-stringer/${selectedEmployee}`
            )
            return
        }
        if (mobileNumber.length === 10) {
            const res = await getStringerDetail()
            if (res === 'Stringer not found') {
                navigate(
                    `/home/manage-staff-stringers/onboard-new-stringer/${mobileNumber}`
                )
            } else {
                if (res?.stringer_type !== 3) {
                    Modal.confirm({
                        title: 'Stringer Already Mapped',
                        content: `The stringer is already mapped to the ${
                            res?.stringer_type === 1
                                ? 'App'
                                : res?.stringer_type === 2
                                ? 'TV'
                                : 'Citizen'
                        } stringer. Do you want to proceed?`,
                        okText: 'Yes',
                        cancelText: 'No',
                        onOk: () => {
                            message.success('Stringer found succefully')
                            navigate(
                                `/home/manage-staff-stringers/${res?.user_id}`
                            )
                        },
                        onCancel: () => {
                            return
                        },
                    })
                } else {
                    message.success('Stringer found succefully')
                    navigate(`/home/manage-staff-stringers/${res?.user_id}`)
                }
            }
        } else {
            message.error('Mobile number must be 10 digits')
        }
    }

    const handleCancel = () => {
        setVisible(false)
        setMobileNumber('')
    }
    const [selectedEmployee, setSelectedEmployee] = useState(null)
    const allEmployees = useSelector((state) => state.app.employees)
    const popoverContent = (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: 270,
                padding:0,
                borderRadius: '8px',
            }}
        >
            <Tabs
                defaultActiveKey={useMobileNumber ? "mobile" : "employee"}
                onChange={(key) => setUseMobileNumber(key === "mobile")}
                centered
                style={{width:"100%"}}
                
            >
                <TabPane tab="Mobile" key="mobile">
                    <Input
                        placeholder="Enter mobile number"
                        value={mobileNumber}
                        onChange={(e) => {
                            const value = `${e.target.value}`.slice(0, 10);
                            setMobileNumber(value);
                        }}
                        onPressEnter={handleNext}
                        type="number"
                        style={{ minWidth: '100%', marginBottom: '10px' }}
                    />
                </TabPane>
                <TabPane tab="Employee" key="employee">
                    <Select
                        allowClear
                        placeholder="Select Employee"
                        value={selectedEmployee}
                        onChange={(value) => {
                            setSelectedEmployee(value);
                        }}
                        style={{ width: '100%', marginBottom: '10px' }}
                        showSearch
                        filterOption={(input, option) =>
                            option?.children?.toLowerCase().includes(input.toLowerCase())
                        }
                    >
                        {allEmployees.map((employee, index) => (
                            employee?.mobile_number && (
                                <Select.Option key={index} value={employee.employee_id}>
                                    {employee.employee_name}
                                </Select.Option>
                            )
                        ))}
                    </Select>
                </TabPane>
            </Tabs>
            {/* Buttons */}
            <div
                style={{
                    marginTop: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 10,
                    width: '100%',
                }}
            >
                <Button
                    onClick={handleCancel}
                    style={{
                        marginRight: '10px',
                        backgroundColor: '#f4f4f4',
                        borderColor: '#d9d9d9',
                    }}
                >
                    Cancel
                </Button>
                <Button
                    type="primary"
                    onClick={handleNext}
                    disabled={!mobileNumber && !selectedEmployee}
                >
                    Next
                </Button>
            </div>
        </div>
    );

    return (
        <div className="staffStringers_container">
            <div className="staffStringers_header">
                <h5>Manage Staff Stringers</h5>
                <Popover
                    content={popoverContent}
                    title=""
                    trigger="click"
                    open={visible}
                    onOpenChange={(visible) => setVisible(visible)}
                >
                    <Button type="primary">Add/Edit Staff Stringers</Button>
                </Popover>
            </div>
            <hr style={{ color: 'gray', marginTop: 2 }}></hr>
            <div className="">
                <div style={{ display: 'flex' }}>
                    <Select
                        allowClear
                        placeholder="Select State"
                        value={state}
                        showSearch
                        onChange={(e) => handleStateChange(e)}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option?.children
                                ?.toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: 150, marginRight: '2rem' }}
                    >
                        {locations.map((option, index) => {
                            return (
                                <Option
                                    key={option.state_id}
                                    value={option.state_id}
                                >
                                    {option?.name}
                                </Option>
                            )
                        })}
                    </Select>
                    <Select
                        value={district === undefined ? undefined : district}
                        allowClear
                        placeholder="Select District"
                        onChange={(e) => setDistrict(e)}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option?.children
                                ?.toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: 170, marginRight: '1rem' }}
                    >
                        {districtData?.map((option, index) => {
                            return (
                                <Option
                                    key={option?.district_id}
                                    value={option?.district_id}
                                >
                                    {option?.name}
                                </Option>
                            )
                        })}
                    </Select>
                    <Search
                        className="custom-search"
                        placeholder="search by name"
                        enterButton="Search"
                        size="middle"
                        allowClear
                        onChange={(e) => setName(e.target.value)}
                        onSearch={() => getData()}
                        value={name}
                        style={{ width: 250 }}
                    />

                    <Search
                        placeholder="search by mobile number"
                        enterButton="Search"
                        size="middle"
                        allowClear
                        onChange={(e) => {
                            const value = e.target.value.slice(0, 10) // Ensure only 10 digits

                            setMobile(value)
                        }}
                        onSearch={() => getData()}
                        value={mobile}
                        style={{ width: 250 }}
                        type="number"
                    />
                </div>
                <EditableTable
                    data={data}
                    dataLoading={dataLoading}
                    allConstituencies={[]}
                    locations={locations}
                    accessToken={accessToken}
                    allDistricts={allDistricts}
                    allMandals={[]}
                />
            </div>
        </div>
    )
}

export default StaffStringers

import React, { useEffect, useState } from 'react'
import './tv.scss'
import { Button, Input, message, Modal, Pagination, Table, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { getUgcContent, updateUgcContent } from '../../AadhanApi'
import { useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import VideoReview from './VideoReview'
import {
    FieldTimeOutlined,
    LeftOutlined,
    LoadingOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons'
import relativeTime from 'dayjs/plugin/relativeTime'
import RejectModal from '../ReviewContent/ReviewContentForm/RejectModal'
dayjs.extend(relativeTime)

export const TVContent = () => {
    const languageData = useSelector((state) => state.app.languageData)
    const rejectionReasonData = useSelector(
        (state) => state.app.rejectedReasonData
    )
    const [content, setContent] = useState([])
    const [dataLoading, setDataLoading] = useState(false)
    const languageOption = languageData?.[0]?.language_id
    const [rejectionReason, setRejectionReason] = useState('')
    const handleRejectionChanges = (value) => {
        setShowCustomReasonInput(value === 'Custom Reason')
        setRejectionReason(value)
    }
    const [reason, setReason] = useState('')
    const employeeName = useSelector((state) => state.app.employeeName)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [showCustomReasonInput, setShowCustomReasonInput] = useState(false)
    const navigate = useNavigate()
    const [record, setRecord] = useState()
    const [messageApi, contextHolder] = message.useMessage()
    const location = useLocation()
    const accessToken = useSelector((state) => state.app.accessToken)
    const displayAuthenticationError = (errMessage) => {
        messageApi.open({
            type: 'error',
            content: (
                <div style={{ display: 'flex', gap: '5px' }}>
                    {errMessage}
                    <Link to="/">
                        Click here to login or will be redirected to Login page
                        in 5 seconds
                    </Link>
                </div>
            ),
            duration: 10,
        })
        setTimeout(() => {
            navigate('/')
        }, 5000)
    }
    const displayError = (errMessage) => {
        messageApi.open({
            type: 'error',
            content: errMessage,
            duration: 5,
        })
    }
    const filterLast24Hours = (data) => {
        const now = new Date()
        return data?.filter((item) => {
            const uploadedDate = new Date(item?.uploaded_date)
            const timeDifference = now - uploadedDate // Difference in milliseconds
            return timeDifference <= 24 * 60 * 60 * 1000 // Check if within 24 hours
        })
    }
    const getTVcontent = async () => {
        const startDateFormat = dayjs().subtract(6, 'day').format('YYYY-MM-DD')
        const endDateFormat = dayjs().format('YYYY-MM-DD')
        setDataLoading(true)
        try {
            const response = await getUgcContent(
                languageOption,
                'submit',
                true,
                startDateFormat,
                endDateFormat,
                'All',
                accessToken,
                '',
                '',
                true,
                2
            )
            const filteredData = filterLast24Hours(response?.data)
            setContent(response?.data)
            setAllVideoReviewed(false)
            setDataLoading(false)
        } catch (err) {
            console.log('error is ::::', err)
            setDataLoading(false)
            if (err?.response?.status === 401) {
                displayAuthenticationError(
                    'You are unauthorised, Please login again'
                )
            } else {
                displayError('Failed to get UG Content')
            }
        }
    }
    const [allVideoReviewed, setAllVideoReviewed] = useState({})
    useEffect(() => {
        getTVcontent()
    }, [])

    function navigateToTvContent(record) {
        navigate(
            `${location?.pathname}Content?mode=edit&contentId=${record.content_id}`
        )
    }
    const [currentPage, setCurrentPage] = useState(1)
    const videosPerPage = 9
    const indexOfLastVideo = currentPage * videosPerPage
    const indexOfFirstVideo = indexOfLastVideo - videosPerPage
    const currentVideos = content.slice(indexOfFirstVideo, indexOfLastVideo)

    // Handle page change
    const onPageChange = (page) => {
        setDataLoading(true)
        setCurrentPage(page)
        setDataLoading(false)
    }
    const employeeId = useSelector((state) => state.app.employeeId)
    async function handleSave(record, status) {
        const currentTimestampMilliseconds = Date.now()
        const istTimestampMilliseconds = currentTimestampMilliseconds
        const istTimestamp = Math.floor(istTimestampMilliseconds / 1000)
        const request = {
            ...record,
            category_id: record?.category_id ? record?.category_id : 0,
            category_name: record?.category_name ? record?.category_name : '',
            assigned_to: undefined,
            assigned_at: undefined,
            mux_upload_ids: undefined,
            employee_data: undefined,
            original_image_url: undefined,
            expiry_hours: 0,
            published_date: dayjs(Date.now()).format(
                'YYYY-MM-DDTHH:mm:ss.SSSZ'
            ),
            updated_by: employeeId,
            rejected_reason: showCustomReasonInput ? reason : rejectionReason,
            review_start_time: istTimestamp,
            is_active: true,
            content_status: status,
            rejected_image_urls: [],
            code: record?.code,
            updated_date: dayjs(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            rejected_stream_video_urls: [],
            mp4_video_url: '',
            image_url_16_9: '',
            image_url_3_4: '',
            thumbnailimage_url: '',
            left_color: '',
            right_color: '',
            upvote: 0,
            linked_content_id: '',
            is_proof_read: true,
            proof_read_date: dayjs(Date.now()).format(
                'YYYY-MM-DDTHH:mm:ss.SSSZ'
            ),
            proof_read_by: employeeId,
            uploaded_by_name: record?.uploaded,
            proof_read_by_name: employeeName,
            compensation_amount: 78,
            sub_category_id: null,
            sub_category_name: null,
            anti_sub_category_id: null,
            anti_sub_category_name: null,
        }
        if (amount && status === 'approved') {
            request['compensation_amount'] = parseInt(amount)
        }
        try {
            const ugcContentResponse = await updateUgcContent(
                request,
                record?.content_id,
                accessToken
            )
            if (ugcContentResponse?.status === 200) {
                if (status === 'approved') {
                    message.success('TV content approved successfully')
                } else {
                    message.success('TV content rejected successfully')
                    setIsModalOpen(false)
                }
                setContent(
                    content?.filter((e) => e?.content_id !== record?.content_id)
                )
                setAllVideoReviewed(false)
                setIsCompModal(false)
            } else {
                message.error('unable update TV content')
            }
        } catch (error) {
            message.error('unable update TV content')
            console.log('error', error.message)
        }
    }
    const [isCompModal, setIsCompModal] = useState(false)
    const [isCompensated, setIsCompensated] = useState(false)
    const [amountError, setAmountError] = useState(false)
    const handleYesClick = () => {
        setIsCompensated(true)
    }
    const [amount, setAmount] = useState()
    const handleNoClick = () => {
        handleSave(record, 'approved')
        setIsCompModal(false)
    }
    return (
        <div className="tv_container">
            <div className="tv-header">
                <h5 style={{ display: 'flex', alignItems: 'center' }}>
                    Manage TV Content{' '}
                    <div
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            gap: '5px',
                            padding: '4.5px 0 0 20px',
                            fontSize: '1rem',
                            fontWeight: '500',
                            color: '#1890ff', // Matches Ant Design primary color
                            backgroundColor: 'transparent',
                            cursor: 'default', // Prevents the pointer cursor
                        }}
                    >
                        <FieldTimeOutlined
                            style={{ fontSize: '1.2rem', color: '#1890ff' }}
                        />
                        Last 7 days
                    </div>
                </h5>

                <Button
                    icon={dataLoading ? <LoadingOutlined /> : ''}
                    onClick={() => getTVcontent()}
                    type="primary"
                    ghost
                    style={{ marginLeft: 'auto' }}
                >
                    Refresh
                </Button>
                {currentVideos?.length !== 0 && (
                    <Pagination
                        // style={{ position: 'absolute', right: 0, top: 145 }}
                        current={currentPage}
                        total={content.length}
                        pageSize={videosPerPage}
                        onChange={onPageChange}
                        className="pagination_class"
                        showSizeChanger={false}
                    />
                )}
            </div>
            <hr style={{ margin: '10px 0' }}></hr>
            <div className="table-tv">
                {currentVideos?.map((e, index) => (
                    <div className="video_content">
                        <h6
                            style={{
                                width: '90%',
                                textAlign: 'left',
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                lineHeight: '1.5',
                                maxHeight: '3em',
                            }}
                        >
                            {e?.title}
                        </h6>

                        <div
                            style={{
                                width: '90%',
                            }}
                        >
                            {e?.stream_video_url?.length !== 0 ? (
                                <VideoReview
                                    data={content}
                                    videoUrls={e?.stream_video_url || []}
                                    onAllVideosWatched={() => {
                                        setAllVideoReviewed({
                                            ...allVideoReviewed,
                                            [index]: true,
                                        })
                                    }}
                                />
                            ) : (
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '100%',
                                        height: 170,
                                        border: '1px solid #ddd',
                                        borderRadius: '6px',
                                        backgroundColor: '#fafafa',
                                        color: '#999',
                                        fontSize: '14px',
                                        gap: '8px',
                                    }}
                                >
                                    <VideoCameraOutlined
                                        style={{
                                            fontSize: '16px',
                                            color: '#bbb',
                                        }}
                                    />
                                    No Video
                                </div>
                            )}
                        </div>
                        <div className="date-time" style={{ marginTop: 10 }}>
                            {dayjs(e?.uploaded_date).format('DD MMM YYYY') +
                                ' ' +
                                dayjs(e?.uploaded_date).format('hh:mm A')}
                        </div>
                        <div className="date-time">
                            {e?.constituency_names?.join(', ')}
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                gap: '10px',
                                justifyContent: 'center',
                            }}
                        >
                            <Tooltip
                                title={
                                    !allVideoReviewed?.[index]
                                        ? 'Complete reviewing the video to enable this action'
                                        : ''
                                }
                            >
                                <Button
                                    disabled={!allVideoReviewed?.[index]}
                                    type="primary"
                                    onClick={() => {
                                        setRecord(e)
                                        setIsCompModal(true)
                                    }}
                                >
                                    Approve
                                </Button>
                            </Tooltip>
                            <Tooltip
                                title={
                                    !allVideoReviewed?.[index]
                                        ? 'Complete reviewing the video to enable this action'
                                        : ''
                                }
                            >
                                <Button
                                    onClick={() => {
                                        setRecord(e)
                                        setIsModalOpen(true)
                                    }}
                                    disabled={!allVideoReviewed?.[index]}
                                    type="primary"
                                    danger
                                >
                                    Reject
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                ))}
            </div>
            <RejectModal
                setReason={setReason}
                reason={reason}
                setShowCustomReasonInput={setShowCustomReasonInput}
                setRejectionReason={setRejectionReason}
                handleSave={() => handleSave(record, 'rejected')}
                handleRejectionChanges={handleRejectionChanges}
                rejectionReasonData={rejectionReasonData}
                showCustomReasonInput={showCustomReasonInput}
                accessToken={accessToken}
                setIsDisabled={() => {}}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
            />
            <Modal
                title=""
                centered
                open={isCompModal}
                onOk={() => setIsCompModal(false)}
                onCancel={() => {
                    setIsCompModal(false)
                    // setPaymentType(undefined)
                }}
                footer={null}
                style={{
                    textAlign: 'center',
                }}
            >
                <div>
                    {!isCompensated ? (
                        <div
                            style={{
                                textAlign: 'center',
                                padding: '20px',
                            }}
                        >
                            <h5
                                style={{
                                    fontSize: '20px',
                                    fontWeight: '600',
                                    marginBottom: '20px',
                                }}
                            >
                                Do you want to add compensation?
                            </h5>
                            <Button
                                type="primary"
                                style={{
                                    marginRight: '10px',
                                    backgroundColor: '#4CAF50',
                                    borderColor: '#4CAF50',
                                    borderRadius: '8px',
                                    padding: '12px 24px',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}
                                onClick={handleYesClick}
                            >
                                Yes
                            </Button>
                            <Button
                                type="default"
                                style={{
                                    borderRadius: '8px',
                                    padding: '12px 24px',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    borderColor: '#ccc',
                                }}
                                onClick={handleNoClick}
                            >
                                No
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <Button
                                onClick={() => setIsCompensated(false)}
                                type="primary"
                                ghost
                                icon={<LeftOutlined />}
                                style={{
                                    position: 'absolute',
                                    top: '20px',
                                    left: '20px',
                                    borderRadius: '50%',
                                    height: '40px',
                                    width: '40px',
                                }}
                            />
                            <h5
                                style={{
                                    fontSize: '18px',
                                    margin: '20px 0',
                                }}
                            >
                                Enter the amount:
                            </h5>
                            <Input
                                value={amount}
                                onChange={(e) => {
                                    setAmount(e?.target?.value)
                                    setAmountError(false)
                                }}
                                type="number"
                                placeholder="amount"
                                style={{
                                    width: '250px',

                                    marginBottom: '20px',
                                    borderRadius: '5px',
                                }}
                            />
                            {amountError && (
                                <div
                                    style={{
                                        color: 'red',
                                        fontSize: '14px',
                                        marginTop: '10px',
                                        textAlign: 'center',
                                    }}
                                >
                                    Amount is required. Please provide a valid
                                    input.
                                </div>
                            )}
                            <br />
                            <Button
                                type="primary"
                                style={{
                                    backgroundColor: '#28a745',
                                    borderColor: '#28a745',
                                    borderRadius: '5px',
                                    padding: '10px 20px',
                                    fontWeight: 'bold',
                                }}
                                onClick={() => {
                                    if (
                                        !amount ||
                                        amount === null ||
                                        amount === 0
                                    ) {
                                        setAmountError(true)
                                        return
                                    }

                                    setIsCompModal(false)
                                    setIsCompensated(false)
                                    handleSave(record, 'approved')
                                }}
                            >
                                Confirm
                            </Button>
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    )
}

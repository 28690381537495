import React, { useState } from 'react'
import ImageUploader from '../image-uploader-crop/ImageUploader'
import { getImageUrl } from '../../AadhanApi'
import { Button, message, Spin } from 'antd'
import { useLocation } from 'react-router'
import SmartphonePreviewUploadLibrary from '../SmartphonePreview/SmartPhonePreviewUploadLibray'
import { DeleteFilled } from '@ant-design/icons'

const ImageUploadShortnews = ({
    accessToken,
    images,
    setFile,
    handleDeleteImage,
    languageOption,
    setImagePath,
    setIsImageAdding,
    imagePath,
    setIsSensitive,
    isSensitive,
    isOriginal,
    setIsOriginal,
    fromGoogleSearch,
    fileFromGoogleSearch,
    videoPath,
}) => {
    const [isImageUploading, setIsImageUploading] = useState(false)
    const [act, setAct] = useState(false)
    const location = useLocation()

    const getImagePath = async (path) => {
        setIsImageUploading(true)
        const file = path

        const pathUriArr = await getImageUrl(
            file,
            'shortnews',
            accessToken,
            languageOption
        )

        if (pathUriArr?.data) {
            setImagePath(pathUriArr?.data)
            // setIsImageAdding(false)
            message.success('Image added')
            setIsImageUploading(false)
            setIsImageAdding(false)
        }
        if (typeof setFile === 'function') {
            setFile()
        }
    }
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '402px',
            }}
        >
            {images?.length >= 5 && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: 'blue',
                        fontWeight: '500',
                        fontSize: '1rem',
                        textAlign: 'center',
                    }}
                >
                    Image capacity full (5/5). Remove one to upload another.
                </div>
            )}
            <div className="video_upload_shortnews">
                {
                    <ImageUploader
                        act={act}
                        isImageUploading={isImageUploading}
                        modal={true}
                        videoPath={videoPath}
                        getImagePath={getImagePath}
                        setAct={setAct}
                        images={images}
                        setFiles={setFile}
                        fileFromGoogleSearch={fileFromGoogleSearch}
                        fromGoogleSearch={fromGoogleSearch}
                        setIsImageAdding={setIsImageAdding}
                    />
                }
                {videoPath?.stream_url || videoPath?.yt_url
                    ? images?.length >= 1 && (
                          <div
                              style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  color: 'blue',
                                  fontWeight: '500',
                                  fontSize: '1rem',
                                  textAlign: 'center',
                              }}
                          >
                              Image capacity full (1/1). Remove one to upload
                              another.
                          </div>
                      )
                    : images?.length >= 5 && (
                          //   !location.pathname.includes('addsnippet-form') &&
                          <div
                              style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  color: 'blue',
                                  fontWeight: '500',
                                  fontSize: '1rem',
                                  textAlign: 'center',
                              }}
                          ></div>
                      )}

                {isImageUploading && (
                    <div className="upload_image_shortnews">
                        <Spin></Spin>
                    </div>
                )}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {imagePath &&
                        !isImageUploading &&
                        !act &&
                        !location.pathname.includes('home/shortNews') &&
                        !location.pathname.includes('home/addsnippet-form') &&
                        !location.pathname.includes(
                            'home/writer-dashboard'
                        ) && (
                            <SmartphonePreviewUploadLibrary
                                isOriginal={isOriginal}
                                handleDeleteImage={handleDeleteImage}
                                setIsOriginal={setIsOriginal}
                                isSensitive={isSensitive}
                                setIsSensitive={setIsSensitive}
                                images={images}
                            />
                        )}
                </div>
            </div>
        </div>
    )
}

export default ImageUploadShortnews

// @flow
import React from 'react'
import * as PropTypes from 'prop-types'
import ReactCropper from 'react-cropper'
import { Button, Modal, Row, Col } from 'react-bootstrap'
import Slider from 'rc-slider'

// Utils
import { getFileInfo } from './utils'

// Styles
import 'cropperjs/dist/cropper.css'
import 'rc-slider/assets/index.css'
import { Checkbox } from 'antd'

/** CropperModel functional component */
function CropperModal(props) {
    const { labels, file, modal } = props
    const [cropper, setCropper] = React.useState<Cropper>(null)
    const [image, setImage] = React.useState<string>(null)
    const [zoom, setZoom] = React.useState<number>(props.initialZoom)
    const [rotate, setRotate] = React.useState<number>(props.initialRotate)

    React.useEffect(() => {
        if (file !== null) {
            console.log('from CropperModal')
            const reader = new FileReader()
            reader.addEventListener('load', () => {
                setImage(reader.result)
                cropper && cropper.zoomTo(zoom).rotateTo(rotate)
            })
            reader.readAsDataURL(file)
        } else {
            setImage(null)
            setCropper(null)
        }
    }, [props, file, cropper])

    /**
     * Crop image
     * @returns {void}
     * @event {Props:onConfirm}
     */
    const onConfirm = () => {
        if (!cropper) {
            return
        }
        // Get cropping coordinates
        // const cropData = cropper.getData();
        // const coordinates = {
        //     left: cropData.x,
        //     top: cropData.y,
        //     right: cropData.x + cropData.width,
        //     bottom: cropData.y + cropData.height,
        // };

        const croppedCanvas = {
            width: 540,
            height: 525,
            imageSmoothingQuality: 'medium',
            ...props.croppedCanvasProps,
        }

        const canvasData = cropper.getCroppedCanvas(croppedCanvas)

        const fileInfo = getFileInfo(file, 'image/jpeg')
        canvasData.toBlob(
            (blob) => {
                const croppedFile = new File([blob], fileInfo.filename, {
                    type: 'image/jpeg',
                    lastModified: new Date(),
                })
                typeof props.onConfirm === 'function' &&
                    props.onConfirm(croppedFile)
                typeof props.onCompleted === 'function' && props.onCompleted()
                console.log(croppedFile)
                setImage(null)
                setCropper(null)
            },
            'image/jpeg',
            props.quality
        )
    }

    const handleClose = () => {
        setCropper(false)
        setImage(null)
        typeof props.onDiscard === 'function' && props.onDiscard(file)
        typeof props.onCompleted === 'function' && props.onCompleted()
    }

    return (
        <div>
            {modal === true ? (
                <div
                    style={{ width: 300, marginTop: '.5rem' }}
                    {...props.containerProps}
                >
                    {image && (
                        <div
                            style={{
                                diaplay: 'flex',
                                width: '100%',
                                justifyContent: 'center',
                            }}
                        >
                            <div style={{ boxShadow: '2px 2px 2px gray' }}>
                                <ReactCropper
                                    src={image}
                                    width={320}
                                    height={220}
                                    aspectRatio={540 / 525}
                                    viewMode={1}
                                    dragMode="move"
                                    cropBoxResizable={false}
                                    cropBoxMovable={false}
                                    center={true}
                                    toggleDragModeOnDblclick={false}
                                    checkOrientation={true}
                                    onInitialized={(instance) =>
                                        setCropper(instance)
                                    }
                                    maxCropBoxWidth={540}
                                    maxCropBoxHeight={525}
                                    minCropBoxWidth={540}
                                    minCropBoxHeight={525}
                                    {...props.cropperProps}
                                />
                            </div>
                            <div className="cropper-footer">
                                <Row>
                                    <Col xs={6}>
                                        <div
                                            className="zoom-slider mb-4 d-block"
                                            style={{
                                                width: 200,
                                                marginRight: '65px',
                                            }}
                                        >
                                            <small>{labels.zoom}</small>{' '}
                                            <Slider
                                                min={1}
                                                step={0.1}
                                                max={4}
                                                marks={{
                                                    '1': '1x',
                                                    '2': '2x',
                                                    '3': '3x',
                                                    '4': '4x',
                                                }}
                                                value={zoom}
                                                onChange={(value) => {
                                                    setZoom(value)
                                                    cropper.zoomTo(value)
                                                }}
                                            />
                                        </div>
                                        <div
                                            className="rotate-slider mb-3 d-block"
                                            style={{ width: 200 }}
                                        >
                                            <small>{labels.rotate}</small>{' '}
                                            <Slider
                                                min={-180}
                                                max={180}
                                                marks={{
                                                    '-180': '-180°',
                                                    '0': '0°',
                                                    '180': '180°',
                                                }}
                                                value={rotate}
                                                onChange={(value) => {
                                                    setRotate(value)
                                                    cropper.rotateTo(value)
                                                }}
                                            />
                                        </div>
                                        <div className="clearfix" />
                                    </Col>
                                </Row>
                                <Row>
                                    <div
                                        className="content_image_type_"
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            marginTop: '1rem',
                                        }}
                                    >
                                        <Checkbox
                                            className="check_box_preview_"
                                            // checked={isOriginal}
                                            // onChange={(e) => setIsOriginal(e.target.checked)}
                                        >
                                            Original Image
                                        </Checkbox>
                                        <Checkbox
                                            className="check_box_preview_"
                                            // checked={isSensitive}
                                            // onChange={(e) => setIsSensitive(e.target.checked)}
                                        >
                                            Sensitive Content
                                        </Checkbox>
                                    </div>
                                </Row>
                                <Row>
                                    <Col
                                        xs={6}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-around',
                                            gap: '30px',
                                            marginTop: '1rem',
                                        }}
                                    >
                                        <Button
                                            variant="primary"
                                            className="mr-1"
                                            onClick={onConfirm}
                                        >
                                            {labels.confirm}
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            onClick={handleClose}
                                        >
                                            {labels.discard}
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <Modal
                    show={!!file && !!image}
                    onHide={handleClose}
                    animation={false}
                    size="l"
                    {...props.modalProps}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{labels.heading}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        {image && (
                            <ReactCropper
                                src={image}
                                style={{ height: 500, width: '100%' }}
                                aspectRatio={540 / 525}
                                viewMode={1}
                                dragMode="move"
                                cropBoxResizable={false}
                                cropBoxMovable={false}
                                center={true}
                                toggleDragModeOnDblclick={false}
                                checkOrientation={true}
                                onInitialized={(instance) =>
                                    setCropper(instance)
                                }
                                maxCropBoxWidth={540}
                                maxCropBoxHeight={525}
                                minCropBoxWidth={540}
                                minCropBoxHeight={525}
                                {...props.cropperProps}
                            />
                        )}
                    </Modal.Body>
                    <Modal.Footer className="d-block">
                        <Row>
                            <Col xs={6}>
                                <div
                                    className="float-left mb-4 d-block"
                                    style={{ width: 200, marginRight: '65px' }}
                                >
                                    <small>{labels.zoom}</small>{' '}
                                    <Slider
                                        min={1}
                                        step={0.1}
                                        max={4}
                                        marks={{
                                            '1': '1x',
                                            '2': '2x',
                                            '3': '3x',
                                            '4': '4x',
                                        }}
                                        value={zoom}
                                        onChange={(value) => {
                                            setZoom(value)
                                            cropper.zoomTo(value)
                                        }}
                                    />
                                </div>
                                <div
                                    className="float-left mb-3 d-block"
                                    style={{ width: 200 }}
                                >
                                    <small>{labels.rotate}</small>{' '}
                                    <Slider
                                        min={-180}
                                        max={180}
                                        marks={{
                                            '-180': '-180°',
                                            '0': '0°',
                                            '180': '180°',
                                        }}
                                        value={rotate}
                                        onChange={(value) => {
                                            setRotate(value)
                                            cropper.rotateTo(value)
                                        }}
                                    />
                                </div>
                                <div className="clearfix" />
                            </Col>
                            <Col
                                xs={6}
                                style={{
                                    display: 'flex',
                                    alignSelf: 'center',
                                    justifyContent: 'flex-end',
                                    gap: '20px',
                                }}
                            >
                                <Button
                                    variant="primary"
                                    className="mr-1"
                                    onClick={onConfirm}
                                >
                                    {labels.confirm}
                                </Button>{' '}
                                <Button
                                    variant="secondary"
                                    onClick={handleClose}
                                >
                                    {labels.discard}
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    )
}

CropperModal.propTypes = {
    initialZoom: PropTypes.number,
    initialRotate: PropTypes.number,
    mime: PropTypes.string,
    quality: PropTypes.number,
    file: PropTypes.object,
    labels: PropTypes.shape({
        heading: PropTypes.string,
        confirm: PropTypes.string,
        discard: PropTypes.string,
        zoom: PropTypes.string,
        rotate: PropTypes.string,
    }),
    cropperProps: PropTypes.object,
    modalProps: PropTypes.object,
    croppedCanvasProps: PropTypes.object,
    onDiscard: PropTypes.func,
    onCompleted: PropTypes.func,
}

CropperModal.defaultProps = {
    initialZoom: 1,
    initialRotate: 0,
    mime: null,
    quality: 70,
    labels: {
        heading: 'Crop Image',
        confirm: 'Confirm',
        discard: 'Discard',
        zoom: 'Zoom',
        rotate: 'Rotate',
    },
    modalProps: {},
    cropperProps: {},
    croppedCanvasProps: {},
    onDiscard: () => {},
    onCompleted: () => {},
}

export default CropperModal

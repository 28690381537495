import React from 'react'

function GenerateImage() {
    return (
        <div
            style={{
                height: '400px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <h5>Coming Soon...</h5>
        </div>
    )
}

export default GenerateImage

import './devotional.scss'
import Songs from './Songs/Songs'
import { useSelector } from 'react-redux'
export const Devotional = () => {
    const languageData = useSelector((state) => state.app.languageData)
    return (
        <div style={{ position: 'relative', backgroundColor: 'white' }}>
            {<Songs languageData={languageData} />}
        </div>
    )
}

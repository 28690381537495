import React, { useEffect, useRef, useState } from 'react'
import './NumberedBulletList.scss'
import { Button, Input, Modal, Switch } from 'antd'

const NumberedBulletList = ({ text, hyperlinks, setHyperlinks }) => {
    const [showModal, setShowModal] = useState(false)
    const [urlInput, setUrlInput] = useState('')
    const [selectedText, setSelectedText] = useState('')
    const [openInApp, setOpenInApp] = useState(false)
    const [isValidUrl, setIsValidUrl] = useState(true)
    const ref = useRef(null)
    function containsWordOrPhrase(description, searchTerm) {
        const escapedTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
        const regex = new RegExp(
            `(?:^|[\\s.,;!?(){}\\[\\]<>\"'])${escapedTerm}(?:$|[\\s.,;!?(){}\\[\\]<>\"'])`,
            'u' // Unicode flag for handling multilingual content
        )
        return regex.test(description)
    }
    useEffect(() => {
        let contentHTML = ref?.current?.innerHTML
        hyperlinks?.forEach((link) => {
            const selectedWord = link?.['link_name']
            const anchor = document.createElement('a')
            anchor.href = link?.url
            anchor.target = '_blank'
            anchor.style.color = 'blue'
            anchor.style.textDecoration = 'underline'
            anchor.textContent = selectedWord
            const escapedWord = selectedWord.replace(
                /[.*+?^${}()|[\]\\]/gu,
                '\\$&'
            )
            const regex = new RegExp(
                `(?:^|[\\s.,;!?(){}\\[\\]<>\"'।।॥])(${escapedWord})(?=[\\s.,;!?(){}\\[\\]<>\"'॥।]|$)`,
                'gu'
            )
            contentHTML = ref.current.innerHTML // Update with current innerHTML
            contentHTML = contentHTML.replace(regex, (match, p1) => {
                return match.replace(p1, anchor.outerHTML)
            })

            // Update the innerHTML of ref.current with modified contentHTML
            ref.current.innerHTML = contentHTML
        })
    }, [hyperlinks])
    useEffect(() => {
        if (hyperlinks) {
            setHyperlinks(
                hyperlinks.filter((e) =>
                    containsWordOrPhrase(text, e.link_name)
                )
            )
        }
    }, [text])

    const processText = (text) => {
        const lines = text?.split('\n');
        let numberCounter = 0;
        let contentHTML = '';
    
        // Process each line and build the HTML string
        lines.forEach((line) => {
            const cleanedLine = line.trim();
            if (cleanedLine.startsWith('**')) {
                numberCounter++;
                contentHTML += `${numberCounter}. ${cleanedLine.slice(2).trim()}<br/>`;
            } else if (cleanedLine.startsWith('*')) {
                contentHTML += `<div class="bullet-point-item">${cleanedLine.slice(1).trim()}</div>`;
            } else {
                contentHTML += `<div class="newLine">${cleanedLine}</div>`;
            }
        });
        if(ref?.current){
            ref.current.innerHTML=contentHTML
            hyperlinks?.forEach((link) => {
                const selectedWord = link?.['link_name']
                const anchor = document.createElement('a')
                anchor.href = link?.url
                anchor.target = '_blank'
                anchor.style.color = 'blue'
                anchor.style.textDecoration = 'underline'
                anchor.textContent = selectedWord
                const escapedWord = selectedWord.replace(
                    /[.*+?^${}()|[\]\\]/gu,
                    '\\$&'
                )
                const regex = new RegExp(
                    `(?:^|[\\s.,;!?(){}\\[\\]<>\"'।।॥])(${escapedWord})(?=[\\s.,;!?(){}\\[\\]<>\"'॥।]|$)`,
                    'gu'
                )
                contentHTML = ref.current.innerHTML // Update with current innerHTML
                contentHTML = contentHTML.replace(regex, (match, p1) => {
                    return match.replace(p1, anchor.outerHTML)
                })
    
                // Update the innerHTML of ref.current with modified contentHTML
                ref.current.innerHTML = contentHTML
            })
        }
        
    }
    const handleRightClick = (e) => {
        e.preventDefault()
        const urlPattern = /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,}(\/[\w-]*)*\/?$/
        if (!hyperlinks || !setHyperlinks ) {
            return
        }
        const selection = window.getSelection()
        const selectedText = selection
            .toString()
            .replace(/^[\s.,]+|[\s.,]+$/g, '')
            .trim()
        if (selectedText) {
            if (urlPattern.test(selectedText)) {
                alert(
                    'This content is already a hyperlink and cannot be sent as one.'
                )
                return
            }
            if (!containsWordOrPhrase(ref.current.innerText, selectedText)) {
                return
            }
            const range = selection.getRangeAt(0)
            const nodes = range.cloneContents().querySelectorAll('a')
            const conflictingLinks = hyperlinks?.some(
                (e) => e.link_name === selectedText
            )
            if (nodes.length > 0 && !conflictingLinks) {
                alert(
                    "You can't hyperlink already linked text or mix links with plain text."
                )
                return
            }
            setSelectedText(selectedText)
            setShowModal(true)
            const existingLink = hyperlinks?.find(
                (link) => link?.['link_name'] === selectedText
            )
            if (existingLink) {
                setUrlInput(existingLink?.url)
                setOpenInApp(existingLink?.target === 1 ? true : false)
            } else {
                setUrlInput('')
                setOpenInApp(false)
            }
        }
    }
    const applyHyperlink = () => {
        const urlPattern = /^(https?:\/\/)?(([\w-]+\.)+[\w-]+|localhost)(:[0-9]{1,5})?(\/[\w\-._~:/?#[\]@!$&'()*+,;%=]*)?$/i;
        setIsValidUrl(urlPattern.test(urlInput))
        if (!urlInput || !selectedText || !urlPattern.test(urlInput)) return

        if (ref.current) {
            const contentElement = ref.current
            const escapedWord = selectedText.replace(
                /[.*+?^${}()|[\]\\]/gu,
                '\\$&'
            )
            const regex = new RegExp(
                `(?:^|[\\s.,;!?(){}\\[\\]<>\"'])(${escapedWord})(?=[\\s.,;!?(){}\\[\\]<>\"']|$)`,
                'gu'
            )

            const anchorHTML = `<a href="${urlInput}" target="_blank" style="color: blue; text-decoration: underline;">${selectedText}</a>`
            let contentHTML = contentElement.innerHTML
            contentHTML = contentHTML.replace(regex, (match, p1) => {
                return match.replace(p1, anchorHTML)
            })

            contentElement.innerHTML = contentHTML
            const updatedHyperlinks = hyperlinks?.filter(
                (link) => link?.['link_name'] !== selectedText
            )
            updatedHyperlinks?.push({
                link_name: selectedText,
                url: urlInput,
                target: openInApp ? 1 : 2,
            })

            setHyperlinks(updatedHyperlinks)
            setUrlInput('')
            setSelectedText('')
            setShowModal(false)
        }
    }
    const removeHyperlink = () => {
        const updatedHyperlinks = hyperlinks.filter(
            (link) => link?.['link_name'] !== selectedText
        )
        setHyperlinks(updatedHyperlinks)

        if (ref.current) {
            const innerHTML = ref.current.innerHTML
            const escapedText = selectedText.replace(
                /[.*+?^${}()|[\]\\]/gu,
                '\\$&'
            )
            const regex = new RegExp(`<a[^>]*>${escapedText}</a>`, 'gu')
            ref.current.innerHTML = innerHTML.replace(regex, selectedText)
        }

        setShowModal(false)
    }
    const handleSwitchChange = (checked) => {
        setOpenInApp(checked)
    }

    const handleUrlChange = (e) => {
        const input = e.target.value
        // URL validation using a regular expression
        setUrlInput(input)
    }

    return (
        <div>
            <div ref={ref} onContextMenu={handleRightClick}>
                {text.startsWith('*') || text.startsWith('**')
                    ? processText(text)
                    : text}
            </div>
            <Modal
                title={
                    hyperlinks?.find(
                        (link) => link?.['link_name'] === selectedText
                    )
                        ? 'Edit Hyperlink'
                        : 'Add Hyperlink'
                }
                open={showModal}
                onOk={applyHyperlink}
                onCancel={() => setShowModal(false)}
                footer={[
                    <Button
                        key="remove"
                        type="primary"
                        danger
                        className="hyper_link_footer"
                        onClick={removeHyperlink}
                        hidden={
                            !hyperlinks?.find(
                                (link) => link?.['link_name'] === selectedText
                            )
                        }
                    >
                        Remove
                    </Button>,
                    <Button
                        key="submit"
                        type="primary"
                        onClick={applyHyperlink}
                    >
                        {hyperlinks?.find(
                            (link) => link?.['link_name'] === selectedText
                        )
                            ? 'Save'
                            : 'Add Hyperlink'}
                    </Button>,
                ]}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                    }}
                >
                    <hr></hr>
                    <div style={{display:"flex",gap:10,alignItems:"center"}}>
                    <label>Selected Word</label>
                    <Input disabled value={selectedText} />
                    </div>

                    <div style={{display:"flex",gap:10,alignItems:"center"}}>
                        <label>URL</label>
                        <Input
                            placeholder="Enter URL"
                            value={urlInput}
                            onChange={handleUrlChange}
                            status={!isValidUrl ? 'error' : ''}
                        />
                        {!isValidUrl && (
                            <div style={{ color: 'red' }}>
                                Please enter a valid URL.
                            </div>
                        )}
                    </div>

                    <label>Open Link Inside App</label>
                    <Switch
                        checked={openInApp}
                        onChange={handleSwitchChange}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        style={{ width: 50 }}
                        className="active_status"
                    />
                </div>
            </Modal>
        </div>
    )
}

export default NumberedBulletList

// import React from 'react'
// import AadhanIcon from '../../assets/Aadhan.svg'
// import { Button, Form, Input } from 'antd'
// import './loginWithMobile.scss'

// export const LoginWithMobile = () => {
//   return (
//     <div
//     style={{
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         height: '100%',
//         width: '100%',
//     }}
// >
//     <div className="container-login-with-mobile">
//         <img
//             style={{ width: '7rem', marginBottom: '2rem' }}
//             src={AadhanIcon}
//             alt="login logo"
//         />
//         <div>
//         <Form>
//             <Form.Item label="Mobile">
//                 <Input size='large' type='number'/>
//             </Form.Item>
//         </Form>
//         <div className='footer'>
//         <Button>get OTP</Button>
//         </div>
//         </div>
//     </div>
// </div>
//   )
// }
import React, { useState, useEffect } from 'react'
import { Input, Button, message, Spin } from 'antd'
import './loginWithMobile.scss'
import AadhanIcon from '../../assets/Aadhan.svg'
import { useNavigate } from 'react-router'
import { LoadingOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { addEmployeeLanguages, addlanguage, userLogin } from '../../store/slice/AppSlice'
import { getOTP, verifyOTP } from '../../AadhanApi'

const LoginWithMobile = () => {
    const [step, setStep] = useState(1) // 1 for mobile input, 2 for OTP input
    const [mobile, setMobile] = useState('')
    const [loading, setLoading] = useState(false)
    const [otp, setOtp] = useState(['', '', '', '', '', ''])
    const [resendTimer, setResendTimer] = useState(30)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
        let timer
        if (step === 2 && resendTimer > 0) {
            timer = setInterval(() => {
                setResendTimer((prev) => prev - 1)
            }, 1000)
        }
        return () => clearInterval(timer)
    }, [step, resendTimer])

    const handleSendOtp = async () => {
        if (mobile.length === 10) {
            setLoading(true)
            const res = await getOTP(mobile)
            if (res?.data?.status === 'success') {
                message.success('OTP sent to your mobile number!')
                setStep(2)
                setResendTimer(30) // Reset timer when OTP is sent
            } else {
                message.error('Enter valid mobile number!')
            }
            setLoading(false)
        } else {
            message.error('Please enter a valid 10-digit mobile number')
        }
    }

    const handleResendOtp = async () => {
        if (resendTimer === 0) {
            const res = await getOTP(mobile)
            setResendTimer(30)
            message.success('OTP resent to your mobile number!')
        }
    }

    const handleOtpChange = (value, index) => {
        const newOtp = [...otp]
        newOtp[index] = value
        setOtp(newOtp)
        if (value && index < 5) {
            document.getElementById(`otp-input-${index + 1}`).focus()
        }
    }

    const handleOtpBackspace = (e, index) => {
        if (e.key === 'Backspace' && otp[index] === '') {
            if (index > 0) {
                document.getElementById(`otp-input-${index - 1}`).focus()
            }
        }
    }

    const handleLogin = async () => {
        if (otp.every((digit) => digit !== '')) {
            setLoading(true)
            try {
                const res = await verifyOTP(mobile, otp?.join(''))
                if (res?.data?.msg === 'OTP verified successfully') {
                    localStorage.setItem(
                        'LANGUAGE',
                        res?.data?.user_data?.language_id,
                    )
                    localStorage.setItem(
                        'STRINGER_TYPE',
                        res?.data?.user_data?.stringer_type,
                    )
                    localStorage.setItem(
                        'USER_NAME',
                        res?.data?.user_data?.full_name,
                    )
                    localStorage.setItem(
                        'ACCESS_TOKEN',
                        res?.data?.token
                    )
                    localStorage.setItem(
                        'USER_ID',
                        res?.data?.user_data?.user_id
                    )
                    localStorage.setItem(
                        'USER_PERMISSIONS',
                        JSON.stringify({
                            'Reporter Dashboard': {
                                'Reporter Dashboard': [
                                    'Can Add',
                                    'Can Edit',
                                    'Can View',
                                    'Can Delete',
                                    'Can Approve',
                                ],
                            },
                        })
                    )
                    dispatch(addlanguage(res?.data?.user_data?.language_id===2 ? [ {"language_id": 2, "language_name": "Telugu"}] : res?.data?.user_data?.language_id===3 ? [ {"language_id": 3, "language_name": "Hindi"}] : [ {"language_id": 4, "language_name": "Tamil"}]  ))
                    dispatch(addEmployeeLanguages(res?.data?.user_data?.language_id===2 ? [ {"language_id": 2, "language_name": "Telugu"}] : res?.data?.user_data?.language_id===3 ? [ {"language_id": 3, "language_name": "Hindi"}] : [ {"language_id": 4, "language_name": "Tamil"}]  ))
                    // dispatch(addlanguage())
                    dispatch(
                        userLogin({
                            isUserLoggedIn: true,
                            accessToken:
                                res?.data?.token,
                            permissions: {
                                'Reporter Dashboard': {
                                    'Reporter Dashboard': [
                                        'Can Add',
                                        'Can Edit',
                                        'Can View',
                                        'Can Delete',
                                        'Can Approve',
                                    ],
                                },
                            },
                            employeeId: 0,
                            employeeName:res?.data?.user_data?.full_name,
                            role_id: 0,
                        })
                    )
                    message.success('Logged in successfully!')
                    navigate('/home/reporter-dashboard')
                    setLoading(false)
                } else {
                    message.error('Invalid OTP')
                    setLoading(false)
                }
            } catch {
                message.error('Invalid OTP')
                setLoading(false)
            }
        } else {
            message.error('Please enter a valid 6-digit OTP')
            setLoading(false)
        }
    }

    const handleGoBack = () => {
        setStep(1)
        setMobile('')
        setOtp(['', '', '', '', '', ''])
        setResendTimer(30) // Reset resend timer
    }

    return (
        <div className="login-container">
            {loading && (
                <Spin
                    size="medium"
                    indicator={<LoadingOutlined spin />}
                    style={{
                        zIndex: 2,
                        opacity: 1,
                        position: 'absolute',
                        color: 'gray',
                        left: '50%',
                        right: '50%',
                        top: '50%',
                        bottom: '50%',
                    }}
                ></Spin>
            )}
            <div
                className="login-box-wrapper"
                style={{ position: 'relative', opacity: loading ? 0.3 : 1 }}
            >
                <img
                    src={AadhanIcon}
                    alt="Company Logo"
                    className="company-logo"
                />

                {/* Step 1: Mobile Number Input */}
                {step === 1 && (
                    <div className="mobile-input-step" style={styles.container}>
                        {/* <h5 style={styles.header}>Login with Mobile</h5> */}
                        <div style={styles.inputContainer}>
                            <Input
                                placeholder="Enter your mobile number"
                                value={mobile}
                                onChange={(e) =>
                                    setMobile(e.target.value.replace(/\D/g, ''))
                                }
                                maxLength={10}
                                size="large"
                                style={styles.input}
                                onPressEnter={handleSendOtp}
                            />
                            <Button
                                type="primary"
                                onClick={handleSendOtp}
                                style={styles.button}
                            >
                                Send OTP
                            </Button>
                        </div>
                        <Button
                            type="link"
                            style={{ marginTop: '1rem' }}
                            onClick={() => navigate(-1)}
                            className="go-back-to-login"
                        >
                            Go Back
                        </Button>
                    </div>
                )}

                {/* Step 2: OTP Input */}
                {step === 2 && (
                    <div className="otp-input-step" >
                        <h3>Enter OTP</h3>
                        <div className="otp-input-container">
                            {otp.map((digit, index) => (
                                <Input
                                    key={index}
                                    id={`otp-input-${index}`}
                                    maxLength={1}
                                    value={digit}
                                    onChange={(e) =>
                                        handleOtpChange(e.target.value, index)
                                    }
                                    onKeyDown={(e) =>
                                        handleOtpBackspace(e, index)
                                    }
                                    onPressEnter={handleLogin}
                                    className="otp-digit-input"
                                />
                            ))}
                        </div>
                        <Button
                            type="primary"
                            style={{ width: '120px' }}
                            onClick={handleLogin}
                            className="login-button"
                        >
                            Login
                        </Button>
                        <div className="resend-container">
                            <Button
                                type="link"
                                disabled={resendTimer > 0}
                                onClick={handleResendOtp}
                                className="resend-otp-button"
                            >
                                Resend OTP
                            </Button>
                            {resendTimer > 0 && (
                                <span className="resend-timer">
                                    in {resendTimer} seconds
                                </span>
                            )}
                        </div>
                        {/* Go back button to re-enter mobile number */}
                        <Button
                            type="link"
                            onClick={handleGoBack}
                            className="go-back-button"
                        >
                            Go Back
                        </Button>
                    </div>
                )}
            </div>
        </div>
    )
}

const styles = {
    container: {
        padding: '20px',
        maxWidth: '400px',
        margin: '0 auto',
        textAlign: 'center',
        backgroundColor: '#fff',
    },
    header: {
        color: 'gray',
        fontSize: '18px',
        marginBottom: '15px',
    },
    inputContainer: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    input: {
        flex: 1,
        height: '40px',
        borderRadius: '5px',
    },
    button: {
        width: '120px',
        height: '40px',
        borderRadius: '5px',
        fontWeight: 'bold',
    },
}

export default LoginWithMobile

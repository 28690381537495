import { Button, message, Popconfirm, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import CommonTable from '../../CommonTable/CommonTable'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import AddMagazineModal from './AddMagazineModal'
import {
    deleteMagazineById,
    getMagazineById,
    getMagazines,
    getVisualsCategory,
} from '../../../AadhanApi'

function MagazinesMain() {
    const languageData = useSelector((state) => state.app.languageData)
    const [language, setLanguage] = useState(languageData?.[0]?.language_id)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [categoryOptions, setCategoryOptions] = useState([])
    const [category, setCategory] = useState('')
    const [magazines, setMagazines] = useState([])
    const [loading, setLoading] = useState(false)
    const [editingMagazine, setEditingMagazine] = useState(null)
    const accessToken = useSelector((state) => state.app.accessToken)

    const handleCancel = async (newLanguage, newCategory) => {
        if (newLanguage !== undefined) {
            setLanguage(newLanguage)
        }
        if (newCategory !== undefined) {
            setCategory(newCategory)
        }
        setIsModalOpen(false)
        setEditingMagazine(null)
        await fetchMagazines()
    }

    const handleLanguageChange = (value) => {
        setLanguage(value)
        setCategory('')
    }

    const handleAddClick = () => {
        setIsModalOpen(true)
    }

    useEffect(() => {
        if (accessToken && language) {
            fetchCategories(language)
        }
    }, [accessToken, language])

    useEffect(() => {
        if (accessToken && language) {
            fetchMagazines()
        }
    }, [accessToken, language, category])

    const fetchCategories = async (languageId) => {
        try {
            const response = await getVisualsCategory(
                accessToken,
                languageId,
                'magazine'
            )
            const categories = response.data.data.map((category) => ({
                value: category.category_id,
                label: category.category_name,
            }))
            setCategoryOptions([{ value: '', label: '--All--' }, ...categories])
        } catch (error) {
            console.error('Failed to fetch categories:', error)
            message.error('Failed to fetch categories')
        }
    }

    const fetchMagazines = async () => {
        try {
            setLoading(true)
            const response = await getMagazines(accessToken, language, category)
            setMagazines(response.data.data || [])
        } catch (error) {
            console.error('Failed to fetch magazines:', error)
            message.error('Failed to fetch magazines')
        } finally {
            setLoading(false)
        }
    }

    const handleEdit = async (record) => {
        try {
            const response = await getMagazineById(
                record.magazine_id,
                accessToken
            )
            if (response.data.success) {
                setEditingMagazine(response.data.data)
                setIsModalOpen(true)
            } else {
                message.error('Failed to fetch magazine details')
            }
        } catch (error) {
            console.error('Failed to fetch magazine details:', error)
            message.error('Failed to fetch magazine details')
        }
    }

    const deleteRow = async (record) => {
        try {
            const response = await deleteMagazineById(
                record.magazine_id,
                accessToken
            )
            if (response.data.success) {
                message.success('Magazine deleted successfully')
                await fetchMagazines()
            } else {
                message.error(
                    response.data.message || 'Failed to delete magazine'
                )
            }
        } catch (error) {
            console.error('Failed to delete magazine:', error)
            message.error('Failed to delete magazine')
        }
    }

    const columns = [
        {
            title: 'Magazine',
            dataIndex: 'card',
            key: 'card',
            width: '26%',
            render: (_, record) => (
                <img
                    className="visual_card"
                    style={{
                        borderRadius: '2px',
                        border: 'solid 1px white',
                        boxShadow: '2px 2px 2px gray',
                    }}
                    width={70}
                    height={100}
                    src={
                        record?.image_urls?.[0] || record?.original_images?.[0]
                    }
                />
            ),
        },
        {
            title: 'Category Name',
            dataIndex: 'visuals_category_name',
            key: 'visuals_category_name',
            width: '32%',
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            width: '21%',
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '10px',
                        fontSize: '1.2rem',
                    }}
                >
                    <EditOutlined onClick={() => handleEdit(record)} />
                    <Popconfirm
                        title="Delete Magazine"
                        description="Are you sure to delete this magazine?"
                        onConfirm={() => deleteRow(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <DeleteOutlined style={{ paddingLeft: '5px' }} />
                    </Popconfirm>
                </div>
            ),
        },
    ]
    return (
        <div
            style={{
                marginLeft: '1vw',
                marginTop: '1.2vh',
                marginRight: '1vw',
            }}
        >
            <h2>Manage Magazines</h2>
            <hr></hr>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '12px',
                }}
            >
                <div>
                    <Select
                        value={language}
                        onChange={handleLanguageChange}
                        placeholder="Select Language"
                        style={{ width: '14vw' }}
                    >
                        {languageData?.map((option) => (
                            <Select.Option
                                key={option.language_id}
                                value={option.language_id}
                            >
                                {option.language_name}
                            </Select.Option>
                        ))}
                    </Select>
                    <Select
                        onChange={(e) => setCategory(e)}
                        value={category === '' ? '--All--' : category}
                        placeholder="Select Category"
                        className="visuals-lang-dropdown-2"
                    >
                        {categoryOptions.map((category) => (
                            <Select.Option
                                key={category.value}
                                value={category.value}
                            >
                                {category.label}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <Button type="primary" onClick={handleAddClick}>
                    Add Magazines
                </Button>
            </div>
            <CommonTable columns={columns} data={magazines} loading={loading} />
            {isModalOpen && (
                <AddMagazineModal
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    handleCancel={handleCancel}
                    editingMagazine={editingMagazine}
                />
            )}
        </div>
    )
}

export default MagazinesMain

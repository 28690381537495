import React, { useEffect, useState } from 'react';
import { message, Tabs } from 'antd';
import EmployeeTable from './EmployeeTable';
import { useSelector } from 'react-redux';
import RuleForDay from './RuleForDay';
import { ExpiryKeywords } from './ExpiryKeywords';
import SponsoredContentForm from '../SponsoredContent/SponsoredContentForm';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SponsoredPage from '../SponsoredContent/SponsoredPage';
import Availability from './Availability';

const EditorDashboard = () => {
  const { key, id } = useParams();
  const [currentKey, setCurrentKey] = useState(key);
  const [currentId, setCurrentId] = useState(id);
  const navigate = useNavigate();
  const locationHook = useLocation();
  const location = useSelector((state) => state.app.locations);
  const language = useSelector((state) => state.app.languageData);
  const accessToken = useSelector((state) => state.app.accessToken);

  const handleTabChange = (activeKey) => {
    setCurrentKey(activeKey);
    navigate(`/home/editorDashboard/${activeKey}`);
  };

  useEffect(() => {
    const availabilityStatus = localStorage.getItem('is_Available');
    if (!availabilityStatus) {
      message.info(
        'Please set the availability status to "Active" before starting to review the content.'
      );
    }
  }, []);

  useEffect(() => {
    if (key !== "sponsored-report") {
      setCurrentId(id);
      setCurrentKey(key);
    }
  }, [locationHook?.pathname, id, key]);

  const tabItems = [
    {
      label: <span style={{ color: 'gray', fontWeight: 'bold' }}>Employee</span>,
      key: 'employee',
      children: <EmployeeTable token={accessToken} language={language} locations={location} />,
    },
    {
      label: <span style={{ fontWeight: 'bold', color: 'gray' }}>Day Rules</span>,
      key: 'day-rules',
      children: <RuleForDay />,
    },
    {
      label: <span style={{ fontWeight: 'bold', color: 'gray' }}>Expiry Keywords</span>,
      key: 'expiry-keywords',
      children: <ExpiryKeywords />,
    },
    {
      label: <span style={{ fontWeight: 'bold', color: 'gray' }}>Sponsored</span>,
      key: 'sponsored-content',
      children: currentId && locationHook?.pathname?.includes('sponsored-content') ? (
        <SponsoredContentForm />
      ) : (
        <SponsoredPage />
      ),
    },
    {
      label: <span style={{ fontWeight: 'bold', color: 'gray' }}>TV</span>,
      key: 'tv',
      children: (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '70vh',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <h5>Coming Soon ...</h5>
        </div>
      ),
    },
    {
      label: <span style={{ fontWeight: 'bold', color: 'gray' }}>Trends</span>,
      key: 'trends',
      children: (
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '70vh',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <h5>Coming Soon ...</h5>
        </div>
      ),
    },
  ];

  return (
    <div style={{ position: 'relative' }}>
      <Tabs
        onChange={handleTabChange}
        activeKey={currentKey}
        style={{ height: '3rem', padding: '0 1px', margin: '0 1rem' }}
        className="editor_tab"
        items={tabItems}
      />
      <div style={{ position: 'absolute', top: '.5rem', right: '1rem' }}>
        <Availability />
      </div>
    </div>
  );
};

export default EditorDashboard;

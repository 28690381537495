import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
    PlusOutlined,
    DeleteOutlined,
    CaretDownOutlined,
    CaretRightOutlined,
} from '@ant-design/icons'
import {
    getPermissions,
    createPermissions,
    deletePermissions,
} from '../../AadhanApi'
import { Modal, Input, Button, Popconfirm, notification } from 'antd'
import './permission.css'

const TreeNode = ({
    label,
    children,
    level = 0,
    parentLabel = null,
    fetchPermissions,
}) => {
    const [isOpen, setIsOpen] = useState(level === 0) // Expand top-level nodes by default
    const accessToken = useSelector((state) => state.app.accessToken)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [newChild, setNewChild] = useState('')
    const [newParent, setNewParent] = useState('')

    const hasChildren = children && Object.keys(children).length > 0

    const showModal = () => {
        setIsModalVisible(true)
    }

    const handleOk = async () => {
        if (newChild) {
            try {
                if (level === 0) {
                    await createPermissions(accessToken, newParent, newChild)
                    notification.success({
                        message: 'Success',
                        description: `Permission "${newChild}" added under "${newParent}" successfully!`,
                    })
                    setNewParent('')
                } else {
                    await createPermissions(accessToken, label, newChild)
                    notification.success({
                        message: 'Success',
                        description: `Permission "${newChild}" added under "${label}" successfully!`,
                    })
                }
                setNewChild('')
                fetchPermissions()
            } catch (error) {
                console.error('Failed to create permissions:', error)
                notification.error({
                    message: 'Error',
                    description: 'Failed to add permission!',
                })
            }
        }
        setIsModalVisible(false) // Close modal after adding
    }

    const handleDeleteNode = async (label, parentLabel = null) => {
        try {
            if (level === 0) {
                await deletePermissions(accessToken, label)
                notification.success({
                    message: 'Success',
                    description: `Permission "${label}" deleted successfully!`,
                })
            } else {
                await deletePermissions(accessToken, parentLabel, label)
                notification.success({
                    message: 'Success',
                    description: `Permission "${label}" under "${parentLabel}" deleted successfully!`,
                })
            }
            fetchPermissions()
        } catch (error) {
            console.error(`Failed to delete permission for ${label}:`, error)
            notification.error({
                message: 'Error',
                description: 'Failed to delete permission!',
            })
        }
    }

    const handleCancel = () => {
        setIsModalVisible(false)
        setNewChild('')
        setNewParent('')
    }

    // Function to check if the delete icon should be hidden
    const shouldHideDeleteIcon = () => {
        // Hide delete icon for 'Permission Menu'
        if (label === 'Permission Menu') return true
        // Hide delete icon for parents with children
        return hasChildren
    }

    return (
        <div className="tree-node">
            <div className="node-content">
                {level > 0 && <div className="node-vertical-line"></div>}
                <div
                    className={`node-wrapper ${
                        level > 0 ? 'node-wrapper-indented' : ''
                    }`}
                >
                    {hasChildren && (
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className="node-toggle"
                            aria-label={`${
                                isOpen ? 'Collapse' : 'Expand'
                            } ${label}`}
                        >
                            {isOpen ? (
                                <CaretDownOutlined />
                            ) : (
                                <CaretRightOutlined />
                            )}
                        </button>
                    )}
                    {hasChildren && (
                        <div className="node-horizontal-line"></div>
                    )}
                    <div className="node-label">{label}</div>
                    {(level === 0 || level === 1) && (
                        <Button
                            onClick={showModal}
                            icon={<PlusOutlined />}
                            className="add-child-button"
                            type="primary"
                            shape="circle"
                            size="small"
                            title="Add Child"
                            aria-label={`Add child to ${label}`}
                        />
                    )}
                    {!shouldHideDeleteIcon() && (
                        <Popconfirm
                            title={`Are you sure you want to delete "${label}"?`}
                            onConfirm={() =>
                                handleDeleteNode(label, parentLabel)
                            }
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                icon={<DeleteOutlined />}
                                className="delete-child-button"
                                type="danger"
                                shape="circle"
                                size="small"
                                title="Delete"
                                aria-label={`Delete ${label}`}
                            />
                        </Popconfirm>
                    )}
                </div>
            </div>
            {isOpen && hasChildren && (
                <div className="children-container">
                    {Object.entries(children).map(
                        ([childLabel, grandchildren]) => (
                            <TreeNode
                                key={childLabel}
                                label={childLabel}
                                children={grandchildren}
                                level={level + 1}
                                parentLabel={label}
                                fetchPermissions={fetchPermissions} // Pass function directly
                            />
                        )
                    )}
                </div>
            )}
            <Modal
                title={`Add Child to "${label}"`}
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Add"
                cancelText="Cancel"
            >
                <div className="modal-inputs">
                    {level === 0 ? (
                        <>
                            <Input
                                value={newParent}
                                onChange={(e) => setNewParent(e.target.value)}
                                placeholder="Enter Parent Name"
                                className="parent-input"
                                allowClear
                            />
                            <Input
                                value={newChild}
                                onChange={(e) => setNewChild(e.target.value)}
                                placeholder="Enter Child Name"
                                className="child-input"
                                allowClear
                            />
                        </>
                    ) : (
                        <>
                            <Input
                                value={label}
                                disabled
                                placeholder="Current Parent"
                                className="parent-input"
                            />
                            <Input
                                value={newChild}
                                onChange={(e) => setNewChild(e.target.value)}
                                placeholder="Enter Child Name"
                                className="child-input"
                                allowClear
                            />
                        </>
                    )}
                </div>
            </Modal>
        </div>
    )
}

const TreeStructure = () => {
    const accessToken = useSelector((state) => state.app.accessToken)
    const [treeData, setTreeData] = useState({ permission_menu: {} })

    const fetchPermissions = async () => {
        try {
            const PermissionData = await getPermissions(accessToken)
            setTreeData({ permission_menu: PermissionData?.data || {} })
        } catch (error) {
            console.error('Failed to fetch permissions:', error)
            notification.error({
                message: 'Error',
                description: 'Failed to fetch permissions!',
            })
        }
    }

    useEffect(() => {
        if (accessToken) {
            fetchPermissions()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken])

    return (
        <div>
            <h2 className="tree-title">Manage Permissions</h2>
            <div className="tree-structure">
                <div className="tree-container">
                    <TreeNode
                        label="Permission Menu" // Top-level node
                        children={treeData.permission_menu} // Permissions data
                        level={0} // Top level
                        parentLabel={null}
                        fetchPermissions={fetchPermissions} // Pass function directly
                    />
                </div>
            </div>
        </div>
    )
}

export default TreeStructure

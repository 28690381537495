import React, { useEffect, useState } from 'react'
import { Select, Button, message } from 'antd'
import './Permissions.scss'
import {
    getEmployeeDepartments,
    getPermissionWithRoleId,
    getRolesByDepartment,
    updatePermission,
} from '../../AadhanApi'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import PermissionTable from './PermissionTable'

const ManagePermissions = () => {
    const [selectedOption, setSelectedOption] = useState('')
    const employeeId = useSelector((state) => state.app.employeeId)
    const accessToken = useSelector((state) => state.app.accessToken)
    const [messageApi, contextHolder] = message.useMessage()
    const [loading, setLoading] = useState(false)
    const { Option } = Select
    const [data, setData] = useState([])
    const [selectedDepartment, setSelectedDepartment] = useState('--All--')
    const [departmentsData, setDepartmentsData] = useState()
    const [departmentRoles, setDepartmentsRoles] = useState([])

    useEffect(() => {
        fetchDepartments()
    }, [])

    useEffect(() => {
        if (departmentRoles.length > 0) {
            setSelectedOption(departmentRoles[0].value)
        }
    }, [departmentRoles])

    useEffect(() => {
        try {
            getPermissionTableData()
        } catch (err) {
            console.log('err')
        }
    }, [selectedOption])

    const handleDepartmentChange = (value) => {
        setSelectedDepartment(value)
        setSelectedOption('')
        fetchRolesByDepartment(value)
    }

    const fetchDepartments = async () => {
        try {
            const response = await getEmployeeDepartments(accessToken)
            setDepartmentsData(response.data.data)
        } catch (error) {
            message.error('Failed to fetch departments data')
        }
    }

    useEffect(() => {
        const fetchInitialDepartments = async () => {
            try {
                const response = await getEmployeeDepartments(accessToken)
                const departments = response.data.data
                const allDepartmentsOption = {
                    employee_department_id: 'all',
                    employee_department_name: '--All--',
                }
                // setDepartmentsData(departments)
                // setDepartmentsData([
                //     {
                //         employee_department_id: 'all',
                //         employee_department_name: '--All--',
                //     },

                //     // ...departments,
                // ])
                // setSelectedDepartment('all')
                // fetchRolesByDepartment('all')

                setDepartmentsData([allDepartmentsOption, ...departments])
                setSelectedDepartment(
                    allDepartmentsOption.employee_department_id
                ) 
                fetchRolesByDepartment(
                    allDepartmentsOption.employee_department_id
                )

                // Select the first department
                // if (departments && departments.length > 0) {
                //     const firstDepartmentId =
                //         departments[0].employee_department_id
                //     setSelectedDepartment(firstDepartmentId)
                //     fetchRolesByDepartment(firstDepartmentId)
                // }
            } catch (error) {
                message.error('Failed to fetch departments data')
            }
        }

        fetchInitialDepartments()
    }, [])

    const fetchRolesByDepartment = async (departmentId) => {
        try {
            if (departmentId === 'all') {
                const allRolesResponse = await getRolesByDepartment(
                    accessToken,
                    'all'
                )
                const mappedRoles = allRolesResponse.data
                    ?.filter((role) => role.status !== 'deleted')
                    .map((role) => ({
                        value: role.role_id,
                        label: role.role_name,
                    }))
                setDepartmentsRoles(mappedRoles)
                return
            }
            const roleListResponse = await getRolesByDepartment(
                accessToken,
                departmentId
            )
            const mappedRoles = roleListResponse.data
                ?.filter((role) => role.status !== 'deleted')
                .map((role) => ({
                    value: role.role_id,
                    label: role.role_name,
                }))
            setDepartmentsRoles(mappedRoles)
        } catch (error) {
            message.error('Failed to fetch roles for selected department')
        }
    }

    const getPermissionTableData = async () => {
        setLoading(true)
        if (selectedOption && accessToken) {
            const response = await getPermissionWithRoleId(
                selectedOption,
                accessToken
            )
            let arr = []
            Object.keys(response.data.permission_menu).forEach(
                (menuSection) => {
                    let temp = []
                    Object.keys(
                        response?.data?.permission_menu?.[menuSection]
                    ).forEach((menu, index) => {
                        const menuSectionObj =
                            response?.data?.permission_menu?.[menuSection]
                        temp.push({
                            key: menu,
                            menu: menuSection,
                            ...getPermissionObject(menuSectionObj?.[menu]),
                        })
                    })
                    arr.push({
                        key: menuSection,
                        menu: menuSection,
                        title: true,
                        permissions: temp,
                    })
                }
            )
            setData(arr)
            setLoading(false)
        }
    }
    const getPermissionObject = (permissionArr) => {
        const permissions = Array.isArray(permissionArr) ? permissionArr : []
        return {
            canAdd: permissions.includes('Can Add'),
            canEdit: permissions.includes('Can Edit'),
            canView: permissions.includes('Can View'),
            canDelete: permissions.includes('Can Delete'),
            canApprove: permissions.includes('Can Approve'),
        }
    }

    const getPermissionsForPayload = () => {
        let obj = {}
        data.map((e) => {
            obj[e?.menu] = {}
            e?.permissions?.map((row) => {
                let arr = []
                row.canAdd && arr.push('Can Add')
                row.canEdit && arr.push('Can Edit')
                row.canView && arr.push('Can View')
                row.canDelete && arr.push('Can Delete')
                row.canApprove && arr.push('Can Approve')
                obj[e?.menu][row?.key] = arr
            })
        })
        return obj
    }

    const createobj = () => {
        return {
            permission_menu: getPermissionsForPayload(),
            role_id: selectedOption,
            created_by: employeeId,
            created_date: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            updated_by: employeeId,
            updated_date: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        }
    }

    const handleSavePermission = async () => {
        try {
            const response = await updatePermission(
                createobj(),
                accessToken,
                selectedOption
            )
            if (response.status === 200) {
                getPermissionTableData()
                messageApi.open({
                    type: 'success',
                    content: 'Permissions Saved Succesfully',
                    duration: 5,
                })
            }
        } catch (err) {
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to Save Permissions',
                duration: 5,
            })
        }
    }

    const handleCheckboxChange = (menu, key, columnName, checked) => {
        setData((prevData) =>
            prevData.map((record) => {
                if (record.menu === menu) {
                    const updatedPermissions = record.permissions.map(
                        (perm) => {
                            if (perm.key === key && columnName !== 'all') {
                                return { ...perm, [columnName]: checked }
                            }
                            if (columnName === 'all' && perm.key === key) {
                                return {
                                    ...perm,
                                    canAdd: checked,
                                    canEdit: checked,
                                    canView: checked,
                                    canDelete: checked,
                                    canApprove: checked,
                                }
                            }
                            return perm
                        }
                    )
                    return { ...record, permissions: updatedPermissions }
                }
                return record
            })
        )
    }

    const handleSelectChange = (value) => {
        setSelectedOption(value)
    }

    return (
        <div className="manage-permissions-container">
            <div className="header_permission">
                <h2>Manage Permissions</h2>
                {contextHolder}
                <div>
                    <Select
                        onChange={(value) => {
                            handleDepartmentChange(value)
                        }}
                        value={selectedDepartment}
                        placeholder="Select department"
                        style={{ width: '14vw', marginRight: '10px' }}
                    >
                        {departmentsData
                            ? departmentsData.map((department) => (
                                  <Option
                                      key={department.employee_department_id}
                                      value={department.employee_department_id}
                                  >
                                      {department.employee_department_name}
                                  </Option>
                              ))
                            : undefined}
                    </Select>
                    <Select
                        value={selectedOption}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option?.children
                                ?.toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={handleSelectChange}
                        style={{ width: '14vw' }}
                    >
                        {departmentRoles.map((role) => (
                            <Option key={role.value} value={role.value}>
                                {role.label}
                            </Option>
                        ))}
                    </Select>
                </div>
            </div>
            <PermissionTable
                loading={loading}
                data={data}
                handleCheckboxChange={handleCheckboxChange}
            />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    style={{ margin: '1.5rem 0 1.5rem 0' }}
                    onClick={handleSavePermission}
                    type="primary"
                >
                    Save Permissions
                </Button>
            </div>
        </div>
    )
}
export default ManagePermissions

import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import { useState, useEffect } from 'react'
const { RangePicker } = DatePicker

const DateRangePicker = (props) => {
    const { addDates, clearDates, dates } = props
    const [datevalues, setDateValues] = useState([dayjs(), dayjs()])

    useEffect(() => {
        if (dates) {
            setDateValues([dayjs(dates.startDate), dayjs(dates.endDate)])
        }
    }, [dates])

    const onRangeChange = (dates, dateStrings) => {
        if (dates) {
            const startDate = dayjs(dates[0]).format('YYYY-MM-DD')
            const endDate = dayjs(dates[1]).format('YYYY-MM-DD')
            setDateValues(dates)
            addDates(startDate, endDate)
        } else {
            clearDates()
            setDateValues([])
        }
    }
    const rangePresets = [
        {
            label: 'Today',
            value: [dayjs(), dayjs()],
        },
        {
            label: 'Yesterday',
            value: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')],
        },
        {
            label: 'Last 7 Days',
            value: [dayjs().add(-7, 'd'), dayjs()],
        },
        {
            label: 'Last 30 Days',
            value: [dayjs().add(-30, 'd'), dayjs()],
        },
        {
            label: 'Last 90 Days',
            value: [dayjs().add(-90, 'd'), dayjs()],
        },
    ]

    return (
        <RangePicker
            value={datevalues}
            presets={rangePresets}
            format="YYYY/MM/DD"
            disabledDate={(currentDate) => currentDate > new Date()}
            onChange={onRangeChange}
        />
    )
}
export default DateRangePicker

import React, { useEffect, useState } from 'react'
import {
    CloseSquareFilled,
    PlusCircleOutlined,
    MinusCircleOutlined,
} from '@ant-design/icons'
import { useLocation, useNavigate } from 'react-router'
import { Button, DatePicker, Form, Select, Input, message, Space } from 'antd'
import {
    getAdvertisers,
    createRevenue,
    updateRevenue,
    getEmployeesByRoles,
} from '../../AadhanApi'
import AddAdvertiserModal from '../Master/Advertisers/AddAdvertiserModal'
import dayjs from 'dayjs'
import { Option } from 'antd/es/mentions'
import { useSelector } from 'react-redux'

const formItemLayout = { labelCol: { span: 6 }, wrapperCol: { span: 16 } }

const languageToChannelsMap = {
    Telugu: [
        'Aadhan Telugu',
        'Aadhan Talkies',
        'Aadhan Food & Travels',
        'Aadhan Adhyatmika',
        'Aadhan Money Mantra',
        'Nenu Mee Srikanth (Aadhan)',
    ],
    Hindi: ['Aadhan हिन्दी'],
    Tamil: ['Aadhan Tamil', 'Aadhan News', 'Aadhan Aanmeegam', 'Aadhan Cinema'],
}

const Revenue = () => {
    const [form] = Form.useForm()
    const [advertisers, setAdvertisers] = useState([])
    const [adType, setAdType] = useState('')
    const languageData = useSelector((state) => state.app.languageData)
    const [targets, setTargets] = useState([])
    const [youtubeUrls, setYoutubeUrls] = useState([])
    const [instaUrls, setInstaUrls] = useState([])
    const [token, setToken] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [selectedLanguages, setSelectedLanguages] = useState([])
    const navigate = useNavigate()
    const location = useLocation()
    const isEdit = location.state?.isEdit || false
    const editData = location.state?.revenueData
    const [salesEmployees, setSalesEmployees] = useState([])
    const [influencerEmployees, setInfluencerEmployees] = useState([])
    const [facebookUrls, setFacebookUrls] = useState([])

    const currentTableType = 'revenue'

    // Function to get available channels based on selected languages
    const getChannelsForLanguages = (languageIds = []) => {
        const channels = new Set()

        languageIds.forEach((langId) => {
            const language = languageData.find(
                (lang) => lang.language_id === langId
            )
            const languageName = language?.language_name

            if (languageName && languageToChannelsMap[languageName]) {
                languageToChannelsMap[languageName].forEach((channel) =>
                    channels.add(channel)
                )
            }
        })

        return Array.from(channels)
    }
    const getLanguageNames = (languageIds = [], languageData) => {
        if (!Array.isArray(languageIds)) {
            return []
        }
        return languageIds.map((id) => {
            const language = languageData.find(
                (lang) => lang.language_id === id
            )
            return language ? language.language_name : id
        })
    }
    useEffect(() => {
        const authToken = localStorage.getItem('ACCESS_TOKEN')
        if (!authToken) {
            message.error('Authentication token not found')
            navigate('/login')
            return
        }
        setToken(authToken)
    }, [navigate])

    useEffect(() => {
        if (token) {
            fetchAdvertisers()
            fetchEmployees()
        }
    }, [token])

    useEffect(() => {
        if (isEdit && editData && advertisers.length > 0 && languageData) {
            const languages = editData.language || []

            // Convert language names to IDs
            const languageIds = languages
                .map((langName) => {
                    const lang = languageData.find(
                        (l) => l.language_name === langName
                    )
                    return lang ? lang.language_id : null
                })
                .filter((id) => id !== null)

            setSelectedLanguages(languageIds)
            setAdType(editData.Ad_type)
            setTargets(editData.Target || [])

            const youtubeUrlsData = editData.youtube_urls || ['']
            setYoutubeUrls(youtubeUrlsData)

            const instaUrlsData = editData.insta_urls || ['']
            setInstaUrls(instaUrlsData)

            const facebookUrlsData = editData.facebook_urls || ['']
            setFacebookUrls(facebookUrls)
            const formValues = {
                language: languageIds,
                Channel: editData.Channel || [],
                Target: editData.Target || [],
                Ad_type: editData.Ad_type,
                Advertiser: editData.Advertiser,
                agreed_amount: editData.agreed_amount,
                received_amount: editData.received_amount,
                pending_amount: editData.pending_amount,
                influencer: editData.influencer,
                num_of_days: editData.num_of_days,
                num_of_vedios: editData.num_of_vedios,
                youtube_urls: youtubeUrlsData,
                insta_urls: instaUrlsData,
                facebook_urls: facebookUrlsData,
                received_amount_date: editData.received_amount_date
                    ? dayjs(editData.received_amount_date)
                    : null,
                deal_closed_date: editData.deal_closed_date
                    ? dayjs(editData.deal_closed_date)
                    : null,
                closed_by: editData.closed_by,
            }

            form.setFieldsValue(formValues)
        }
    }, [isEdit, editData, advertisers, form, languageData])

    // useEffect(() => {
    //     if (isEdit && editData && adType === 'Display Ad') {
    //         form.setFieldsValue({
    //             views_committed: editData.views_committed || 0,
    //             views_delivered: editData.views_delivered || 0,
    //         });
    //     } else if (adType !== 'Display Ad') {
    //         form.setFieldsValue({
    //             views_committed: undefined,
    //             views_delivered: undefined,
    //         });
    //     }
    // }, [adType, isEdit, editData, form]);

    const handleFormValuesChange = (changedValues, allValues) => {
        if ('Target' in changedValues) {
            const newTargets = changedValues.Target || []
            setTargets(newTargets)

            if (newTargets.includes('Youtube') && youtubeUrls.length === 0) {
                setYoutubeUrls([''])
                form.setFieldsValue({ youtube_urls: [''] })
            }

            if (newTargets.includes('Insta') && instaUrls.length === 0) {
                setInstaUrls([''])
                form.setFieldsValue({ insta_urls: [''] })
            }

            // Reset Ad Type if YouTube is removed and current Ad Type is L band or Strip Ad
            if (
                !newTargets.includes('Youtube') &&
                (adType === 'L band' || adType === 'Strip Ad')
            ) {
                setAdType('')
                form.setFieldsValue({ Ad_type: undefined })
            }

            if (!newTargets.includes('Insta')) {
                setInstaUrls([])
                form.setFieldsValue({ insta_urls: [] })
            }
        }

        if (
            'agreed_amount' in changedValues ||
            'received_amount' in changedValues
        ) {
            const agreedAmount = parseFloat(allValues.agreed_amount) || 0
            const receivedAmount = parseFloat(allValues.received_amount) || 0
            form.setFieldsValue({
                pending_amount: Math.max(0, agreedAmount - receivedAmount),
            })
        }
    }
    const fetchAdvertisers = async () => {
        try {
            const response = await getAdvertisers(token)
            if (response?.data?.data) {
                setAdvertisers(response.data.data)
            }
        } catch (error) {
            console.error('Error fetching advertisers:', error)
            message.error('Failed to fetch advertisers')
        }
    }

    const fetchEmployees = async () => {
        try {
            // Fetch sales employees
            const salesResponse = await getEmployeesByRoles(token, 'sales')
            if (salesResponse?.data?.data) {
                setSalesEmployees(salesResponse.data.data)
            }

            // Fetch influencer employees
            const influencerResponse = await getEmployeesByRoles(
                token,
                'influencer'
            )
            if (influencerResponse?.data?.data) {
                setInfluencerEmployees(influencerResponse.data.data)
            }
        } catch (error) {
            console.error('Error fetching employees:', error)
            message.error('Failed to fetch employees')
        }
    }
    const handleLanguageChange = (selectedLanguageIds) => {
        if (!selectedLanguageIds) {
            selectedLanguageIds = []
        }

        setSelectedLanguages(selectedLanguageIds)

        if (selectedLanguageIds.length === 0) {
            form.setFieldsValue({
                Channel: [],
            })
            return
        }

        // Only set channels if YouTube is selected as target
        if (targets.includes('Youtube')) {
            const availableChannels =
                getChannelsForLanguages(selectedLanguageIds)
            form.setFieldsValue({
                Channel: availableChannels,
            })
        }
    }

    const handleTargetChange = (selectedTargets) => {
        setTargets(selectedTargets)

        // Don't reset language and channel selections when YouTube is selected/deselected
        const currentLanguages = form.getFieldValue('language') || []

        // If YouTube is selected and we have languages selected
        if (
            selectedTargets.includes('Youtube') &&
            currentLanguages.length > 0
        ) {
            // Get available channels for current languages
            const availableChannels = getChannelsForLanguages(currentLanguages)
            form.setFieldsValue({
                Channel: availableChannels,
            })
        }

        // Handle YouTube URLs
        if (selectedTargets.includes('Youtube') && youtubeUrls.length === 0) {
            setYoutubeUrls([''])
        }

        // Handle Instagram URLs
        if (selectedTargets.includes('Insta') && instaUrls.length === 0) {
            setInstaUrls([''])
        }

        // If YouTube is not selected, just clear the channels
        if (!selectedTargets.includes('Youtube')) {
            form.setFieldsValue({
                Channel: undefined,
            })
        }
        if (selectedTargets.includes('Facebook') && facebookUrls.length === 0) {
            setFacebookUrls([''])
        }

        if (!selectedTargets.includes('Facebook')) {
            setFacebookUrls([])
            form.setFieldsValue({ facebook_urls: [] })
        }
    }
    const handleFacebookUrlChange = (index, value) => {
        const newFacebookUrls = [...facebookUrls]
        newFacebookUrls[index] = value
        setFacebookUrls(newFacebookUrls)
        form.setFieldsValue({ facebook_urls: newFacebookUrls })
    }
    const handleAddFacebookUrl = () => {
        setFacebookUrls([...facebookUrls, ''])
    }
    const handleRemoveFacebookUrl = (index) => {
        const newFacebookUrls = facebookUrls.filter((_, i) => i !== index)
        setFacebookUrls(newFacebookUrls)
        form.setFieldsValue({ facebook_urls: newFacebookUrls })
    }
    const handleYoutubeUrlChange = (index, value) => {
        const newYoutubeUrls = [...youtubeUrls]
        newYoutubeUrls[index] = value
        setYoutubeUrls(newYoutubeUrls)
        form.setFieldsValue({ youtube_urls: newYoutubeUrls })
    }

    const handleAddYoutubeUrl = () => {
        const newYoutubeUrls = [...youtubeUrls, '']
        setYoutubeUrls(newYoutubeUrls)
        form.setFieldsValue({ youtube_urls: newYoutubeUrls })
    }

    const handleRemoveYoutubeUrl = (index) => {
        const newYoutubeUrls = youtubeUrls.filter((_, i) => i !== index)
        setYoutubeUrls(newYoutubeUrls)
        form.setFieldsValue({ youtube_urls: newYoutubeUrls })
    }

    const handleInstaUrlChange = (index, value) => {
        const newInstaUrls = [...instaUrls]
        newInstaUrls[index] = value
        setInstaUrls(newInstaUrls)
        form.setFieldsValue({ insta_urls: newInstaUrls })
    }

    const handleAddInstaUrl = () => {
        setInstaUrls([...instaUrls, ''])
    }

    const handleRemoveInstaUrl = (index) => {
        const newInstaUrls = instaUrls.filter((_, i) => i !== index)
        setInstaUrls(newInstaUrls)
        form.setFieldsValue({ insta_urls: newInstaUrls })
    }

    const handleSubmit = async (values) => {
        try {
            setIsSubmitting(true)

            // Ensure languageData is available before proceeding
            if (!languageData || !Array.isArray(languageData)) {
                message.error('Language data is not available')
                return
            }

            // Ensure values.language is always an array
            const languageIds = values.language || []
            const languageNames = getLanguageNames(languageIds, languageData)

            const formData = {
                ...editData,
                language: languageNames,
                Channel: values.Channel,
                Target: values.Target,
                Ad_type: values.Ad_type,
                Advertiser: values.Advertiser,
                agreed_amount: parseFloat(values.agreed_amount),
                received_amount: parseFloat(values.received_amount) || 0,
                pending_amount: parseFloat(values.pending_amount),
                influencer: values.influencer,
                youtube_urls:
                    values.youtube_urls?.filter(
                        (url) => url && url.trim() !== ''
                    ) || [],
                insta_urls:
                    values.insta_urls?.filter(
                        (url) => url && url.trim() !== ''
                    ) || [],
                facebook_urls:
                    values.facebook_urls?.filter(
                        (url) => url && url.trim() !== ''
                    ) || [],
                received_amount_date: values.received_amount_date
                    ? values.received_amount_date.format('YYYY-MM-DD')
                    : undefined,
                deal_closed_date: values.deal_closed_date
                    ? values.deal_closed_date.format('YYYY-MM-DD')
                    : undefined,
                closed_by: values.closed_by,
                num_of_days: parseInt(values.num_of_days) || 0,
                num_of_vedios: parseInt(values.num_of_vedios) || 0,
                revenue_id: isEdit ? editData.revenue_id : undefined,
            }

            // Remove undefined values to prevent validation errors
            Object.keys(formData).forEach((key) => {
                if (formData[key] === undefined) {
                    delete formData[key]
                }
            })

            let response
            if (isEdit && editData?.revenue_id) {
                response = await updateRevenue(
                    formData,
                    editData.revenue_id,
                    token
                )
            } else {
                response = await createRevenue(formData, token)
            }

            if (response?.data) {
                message.success(
                    isEdit
                        ? 'Revenue updated successfully'
                        : 'Revenue created successfully'
                )
                form.resetFields()
                navigate(-1)
            }
        } finally {
            setIsSubmitting(false)
        }
    }
    return (
        <div className="revenue-container" style={{ padding: '1rem' }}>
            <div className="main-content">
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <h2 className="revenue-heading">Revenue Module</h2>
                    <Button
                        onClick={() => navigate(-1)}
                        ghost
                        style={{ border: 'none' }}
                        type="primary"
                        icon={
                            <CloseSquareFilled
                                style={{
                                    color: 'LightCoral',
                                    fontSize: '1.8rem',
                                }}
                            />
                        }
                    />
                </div>
                <hr />
                <Form
                    {...formItemLayout}
                    form={form}
                    onFinish={handleSubmit}
                    onValuesChange={handleFormValuesChange}
                    style={{ maxWidth: 600, margin: '0 auto' }}
                >
                    <Form.Item
                        label="Language"
                        name="language"
                        rules={[
                            {
                                required: true,
                                message: 'Please select a language!',
                            },
                        ]}
                    >
                        <Select
                            mode="multiple"
                            placeholder="Select Language"
                            style={{ width: '100%' }}
                            onChange={handleLanguageChange}
                        >
                            {languageData.map((lang) => (
                                <Select.Option
                                    key={lang.language_id}
                                    value={lang.language_id}
                                >
                                    {lang.language_name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Target"
                        name="Target"
                        rules={[
                            {
                                required: true,
                                message: 'Please select targets!',
                            },
                        ]}
                    >
                        <Select
                            mode="multiple"
                            placeholder="Select Target"
                            style={{ width: '100%' }}
                            onChange={handleTargetChange}
                        >
                            <Select.Option value="App">App</Select.Option>
                            <Select.Option value="Youtube">
                                YouTube
                            </Select.Option>
                            <Select.Option value="Insta">
                                Instagram
                            </Select.Option>
                            <Select.Option value="LiveTv">
                                Live TV
                            </Select.Option>
                            <Select.Option value="Facebook">
                                Facebook
                            </Select.Option>
                        </Select>
                    </Form.Item>

                    {targets.includes('Youtube') &&
                        selectedLanguages.length > 0 && (
                            <Form.Item
                                label="Aadhan Channels"
                                name="Channel"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select channels!',
                                    },
                                ]}
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Select Aadhan Channels"
                                    style={{ width: '100%' }}
                                >
                                    {getChannelsForLanguages(
                                        selectedLanguages
                                    ).map((channel) => (
                                        <Select.Option
                                            key={channel}
                                            value={channel}
                                        >
                                            {channel}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}
                    {targets.includes('Youtube') && (
                        <Form.Item
                            label="YouTube URLs"
                            validateTrigger={['onChange', 'onBlur']}
                            wrapperCol={{ span: 16 }}
                        >
                            {youtubeUrls.map((url, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        marginBottom: 8,
                                        width: '100%',
                                        gap: '8px',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div style={{ flex: 1, minWidth: 0 }}>
                                        <Form.Item
                                            name={['youtube_urls', index]}
                                            rules={[
                                                {
                                                    message:
                                                        'Please input a YouTube URL!',
                                                },
                                            ]}
                                            style={{ marginBottom: 0 }}
                                        >
                                            <Input
                                                placeholder="Enter YouTube URL"
                                                style={{
                                                    width: '100%',
                                                    minWidth: '350px', // Ensure minimum width
                                                }}
                                                value={url}
                                                onChange={(e) =>
                                                    handleYoutubeUrlChange(
                                                        index,
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </Form.Item>
                                    </div>
                                    {youtubeUrls.length > 1 && (
                                        <MinusCircleOutlined
                                            onClick={() =>
                                                handleRemoveYoutubeUrl(index)
                                            }
                                            style={{
                                                padding: '8px',
                                                cursor: 'pointer',
                                                flexShrink: 0, // Prevent icon from shrinking
                                            }}
                                        />
                                    )}
                                </div>
                            ))}
                            <Button
                                type="dashed"
                                onClick={handleAddYoutubeUrl}
                                icon={<PlusCircleOutlined />}
                                style={{ marginTop: 8 }}
                            >
                                Add YouTube URL
                            </Button>
                        </Form.Item>
                    )}

                    {targets.includes('Insta') && (
                        <Form.Item
                            label="Instagram URLs"
                            validateTrigger={['onChange', 'onBlur']}
                            wrapperCol={{ span: 16 }}
                        >
                            {instaUrls.map((url, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        marginBottom: 8,
                                        width: '100%',
                                        gap: '8px',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div style={{ flex: 1, minWidth: 0 }}>
                                        <Form.Item
                                            name={['insta_urls', index]}
                                            rules={[
                                                {
                                                    message:
                                                        'Please input an Instagram URL!',
                                                },
                                            ]}
                                            style={{ marginBottom: 0 }}
                                        >
                                            <Input
                                                placeholder="Enter Instagram URL"
                                                style={{
                                                    width: '100%',
                                                    minWidth: '350px',
                                                }}
                                                value={url}
                                                onChange={(e) =>
                                                    handleInstaUrlChange(
                                                        index,
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </Form.Item>
                                    </div>
                                    {instaUrls.length > 1 && (
                                        <MinusCircleOutlined
                                            onClick={() =>
                                                handleRemoveInstaUrl(index)
                                            }
                                            style={{
                                                padding: '8px',
                                                cursor: 'pointer',
                                                flexShrink: 0,
                                            }}
                                        />
                                    )}
                                </div>
                            ))}
                            <Button
                                type="dashed"
                                onClick={handleAddInstaUrl}
                                icon={<PlusCircleOutlined />}
                                style={{ marginTop: 8 }}
                            >
                                Add Instagram URL
                            </Button>
                        </Form.Item>
                    )}

                    {targets.includes('Facebook') && (
                        <Form.Item
                            label="Facebook URLs"
                            validateTrigger={['onChange', 'onBlur']}
                            wrapperCol={{ span: 16 }}
                        >
                            {facebookUrls.map((url, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        marginBottom: 8,
                                        width: '100%',
                                        gap: '8px',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div style={{ flex: 1, minWidth: 0 }}>
                                        <Form.Item
                                            name={['facebook_urls', index]}
                                            rules={[
                                                {
                                                    message:
                                                        'Please input a Facebook URL!',
                                                },
                                            ]}
                                            style={{ marginBottom: 0 }}
                                        >
                                            <Input
                                                placeholder="Enter Facebook URL"
                                                style={{
                                                    width: '100%',
                                                    minWidth: '350px',
                                                }}
                                                value={url}
                                                onChange={(e) =>
                                                    handleFacebookUrlChange(
                                                        index,
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </Form.Item>
                                    </div>
                                    {facebookUrls.length > 1 && (
                                        <MinusCircleOutlined
                                            onClick={() =>
                                                handleRemoveFacebookUrl(index)
                                            }
                                            style={{
                                                padding: '8px',
                                                cursor: 'pointer',
                                                flexShrink: 0,
                                            }}
                                        />
                                    )}
                                </div>
                            ))}
                            <Button
                                type="dashed"
                                onClick={handleAddFacebookUrl}
                                icon={<PlusCircleOutlined />}
                                style={{ marginTop: 8 }}
                            >
                                Add Facebook URL
                            </Button>
                        </Form.Item>
                    )}
                    <Form.Item
                        label="Ad Type"
                        name="Ad_type"
                        rules={[
                            {
                                required: true,
                                message: 'Please select Ad Type!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select Ad Type"
                            onChange={(value) => setAdType(value)}
                            style={{ width: '100%' }}
                        >
                            {targets.includes('Youtube') && (
                                <>
                                    <Select.Option value="L band">
                                        L band
                                    </Select.Option>
                                    <Select.Option value="Strip Ad">
                                        Strip Ad
                                    </Select.Option>
                                </>
                            )}
                            <Select.Option value="Pre Roll Ad">
                                Pre Roll Ad
                            </Select.Option>
                            <Select.Option value="Sponsorship">
                                Sponsorship
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    {targets.includes('App') && (
                        <Form.Item
                            label="Number of Days"
                            name="num_of_days"
                            // rules={[{ required: true, message: "Please enter number of days!" }]}
                        >
                            <Input
                                type="number"
                                placeholder="Enter number of days" //min={1}
                            />
                        </Form.Item>
                    )}
                    {targets.includes('Insta') && (
                        <Form.Item
                            label="Number of Videos"
                            name="num_of_vedios"
                            // rules={[{ required: true, message: "Please enter number of videos!" }]}
                        >
                            <Input
                                type="number"
                                placeholder="Enter number of videos"
                            />
                        </Form.Item>
                    )}
                    {(adType === 'L band' || adType === 'Strip Ad') && (
                        <>
                            {/* <Form.Item 
                                    label="Number of Days" 
                                    name="number_of_days" 
                                    // rules={[{ required: true, message: "Please enter number of days!" }]}
                                >
                                    <Input type="number" placeholder="Enter number of days" min={1} />
                                </Form.Item> */}

                            {/* <Form.Item 
                                    label="Number of Videos" 
                                    name="number_of_videos" 
                                    // rules={[{ required: true, message: "Please enter number of videos!" }]}
                                >
                                    <Input type="number" placeholder="Enter number of videos" min={1} />
                                </Form.Item> */}
                        </>
                    )}
                    {adType === 'Sponsorship' && (
                        <Form.Item
                            label="Influencer"
                            name="influencer"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select an influencer!',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select Influencer"
                                style={{ width: '100%' }}
                                filterOption={(input, option) =>
                                    (option?.children ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                optionFilterProp="children"
                            >
                                {influencerEmployees.map((employee) => (
                                    <Select.Option
                                        key={employee.employee_id}
                                        value={`${employee.first_name} ${employee.last_name}`}
                                    >
                                        {`${employee.first_name} ${employee.last_name}`}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                    <Form.Item
                        label="Advertiser"
                        name="Advertiser"
                        rules={[
                            {
                                required: true,
                                message: 'Please select an Advertiser!',
                            },
                        ]}
                    >
                        <Select
                            placeholder="Select Advertiser"
                            style={{ width: '100%' }}
                        >
                            {advertisers.map((advertiser) => (
                                <Option
                                    key={advertiser.advertiser_id}
                                    value={advertiser.advertiser_name}
                                >
                                    {advertiser.advertiser_name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Button
                        icon={<PlusCircleOutlined />}
                        onClick={() => setIsModalOpen(true)}
                        style={{ margin: '0 0 10px 150px' }}
                    >
                        Add New Advertiser
                    </Button>

                    <Form.Item
                        label="Agreed Amount"
                        name="agreed_amount"
                        rules={[
                            {
                                required: true,
                                message: 'Please input Agreed Amount!',
                            },
                        ]}
                    >
                        <Input
                            type="number"
                            placeholder="Enter Agreed Amount"
                            style={{ width: '100%' }}
                        />
                    </Form.Item>

                    <Form.Item label="Received Amount" name="received_amount">
                        <Input
                            type="number"
                            placeholder="Enter Received Amount"
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                    <Form.Item label="Pending Amount" name="pending_amount">
                        <Input
                            type="number"
                            placeholder="Calculated automatically"
                            disabled
                            style={{ width: '100%' }}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Received Date"
                        name="received_amount_date"
                    >
                        <DatePicker
                            style={{ width: '100%' }}
                            format="YYYY-MM-DD"
                        />
                    </Form.Item>

                    <Form.Item
                        label="Deal Close Date"
                        name="deal_closed_date"
                        rules={[
                            {
                                required: true,
                                message: 'Please select Received Date!',
                            },
                        ]}
                    >
                        <DatePicker
                            style={{ width: '100%' }}
                            format="YYYY-MM-DD"
                        />
                    </Form.Item>

                    <Form.Item
                        label="Closed By"
                        name="closed_by"
                        rules={[
                            {
                                required: true,
                                message: 'Please select an Employee!',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select Employee"
                            style={{ width: '100%' }}
                            filterOption={(input, option) =>
                                (option?.children ?? '')
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                            optionFilterProp="children"
                        >
                            {salesEmployees.map((em) => (
                                <Option
                                    key={em.employee_id}
                                    value={`${em.first_name} ${em.last_name}`}
                                >
                                    {`${em.first_name} ${em.last_name}`}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            xs: { span: 24 },
                            sm: { offset: 8, span: 16 },
                        }}
                    >
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={isSubmitting}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Submitting...' : 'Submit'}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <AddAdvertiserModal
                isModalOpen={isModalOpen}
                handleCancel={() => setIsModalOpen(false)}
                token={token}
                onSuccess={fetchAdvertisers}
            />
        </div>
    )
}

export default Revenue

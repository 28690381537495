import React, { useEffect, useRef, useState } from 'react'
import { Modal, Button, Form, message, Tooltip } from 'antd'
import {
    CheckCircleFilled,
    CloseCircleFilled,
    EditFilled,
    RightOutlined,
    TagsFilled,
} from '@ant-design/icons'
import BasicInfo from './BasicInfo'
import ArtWork from './ArtWork'
import MetadataForm from './MetadataForm'
import PlayableAssets from './PlayableAssets'
import { createSongs, updateSongs } from '../../../AadhanApi'
import { useSelector } from 'react-redux'
import '../devotional.scss'
import { duration } from 'moment'
const DynamicFormModal = ({
    languageData,
    getAllSongsfuction,
    mode,
    record,
    language,
}) => {
    const accessToken = useSelector((state) => state.app.accessToken)
    const [songData, setSongData] = useState([])
    const employeeId = useSelector((state) => state.app.employeeId)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [validate, setValidate] = useState([])
    const [currentForm, setCurrentForm] = useState(null) // Track the form being edited
    const slidingDivRef = useRef(null)
    const [error, setError] = useState({
        form1: false,
        form2: false,
        form3: false,
        form4: false,
    })
    const [formsData, setFormsData] = useState({
        form1: { title: '', category: 'Devotional' },
    })
    const [form] = Form.useForm()
    const handleAddForm = (formKey) => {
        setError({ form1: false, form2: false, form3: false, form4: false })
        form.setFieldsValue(formsData[formKey?.key])
        setCurrentForm(formKey)
    }
    const handleOutsideClick = (e) => {
        const temp = [
            'Tamil',
            'Telugu',
            'Hindi',
            'English',
            'play audio',
            'upload audio',
            'Yes',
            'No',
        ]
        if (
            slidingDivRef.current &&
            !temp?.includes(e.target.innerText) &&
            e?.target?.className !==
                'ant-select-dropdown css-dev-only-do-not-override-98ntnt ant-select-dropdown-placement-bottomLeft' &&
            e.target.className !== 'ant-modal-wrap' &&
            e.target.tagName !== 'svg' &&
            e.target.tagName !== 'path' &&
            !slidingDivRef.current.contains(e.target)
        ) {
            setCurrentForm(null)
        }
    }
    useEffect(()=>{
        if(mode!=='edit'){
            setError({ form1: false,
                form2: false,
                form3: false,
                form4: false,})
            setValidate([])  
        }
    },[isModalVisible])
    useEffect(() => {
        if (
            !validate?.includes(currentForm?.key) &&
            currentForm !== undefined
        ) {
            setValidate([...validate, currentForm?.key])
        }

        if (currentForm) {
            document.addEventListener('mousedown', handleOutsideClick)
        } else {
            document.removeEventListener('mousedown', handleOutsideClick)
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [currentForm])

    useEffect(() => {
        setError({ form1: false, form2: false, form3: false, form4: false })
        if (mode === 'edit') {
            setValidate(['form1', 'form2', 'form3', 'form4'])
            setFormsData({
                form1: { title: record?.title, category: record?.category },
            })
            const result = Object.entries(record?.metadata).map(
                ([key, value]) => {
                    const cast = value?.cast?.names
                        ?.split(',')
                        .map((name, index) => ({
                            name,
                            role: value?.cast?.roles?.split(',')[index],
                        }))
                    return {
                        language_id: parseInt(key, 10),
                        ...value,
                        cast,
                    }
                }
            )
            setSongData(result)
        }
        else{
            setError({ form1: false,
                form2: false,
                form3: false,
                form4: false,})
            setValidate([])    
        }
    }, [record])
    const handleSaveForm = () => {
        form.validateFields()
            .then((values) => {
                setFormsData((prev) => ({
                    ...prev,
                    [currentForm?.key]: values,
                }))
                setCurrentForm(null)
                form.resetFields()
            })
            .catch((info) => {
                console.log('Validation Failed:', info)
            })
    }
    const handleSubmitAll = async (type) => {
        let iserror = false
        setError((prev) => ({
            ...prev,
            form1: false,
            form2: false,
            form3: false,
            form4: false,
        }))

        if (!formsData?.form1?.title?.trim()) {
            message.error('The title is required to save or publish the song.')
            setError((prev) => ({
                ...prev,
                form1: true,
            }))
            iserror = true
            return
        }
        if (!formsData?.form1?.category?.trim()) {
            message.error(
                'The category is required to save or publish the song.'
            )
            setError((prev) => ({
                ...prev,
                form1: true,
            }))
            iserror = true
            return
        }
        if (!songData || songData.length === 0) {
            setError((prev) => ({
                ...prev,
                form2: true,
            }))
            iserror = true
            message.error(
                'Metadata is missing and required to save or publish the song.'
            )
            return
        }

        try {
            const output = {
                metadata: songData.reduce((acc, curr, index) => {
                    const castNames = curr?.cast?.map((c) => c.name).join(',')
                    const castRoles = curr?.cast?.map((c) => c.role).join(',')
                    if (
                        curr.language_id &&
                        !iserror &&
                        curr.language_id === undefined &&
                        type === 'approved'
                    ) {
                        setError((prev) => ({
                            ...prev,
                            form2: true,
                        }))
                        iserror = true
                        message.error('Metadata is missing required to publish')
                        return
                    } else if (
                        !curr?.image_url &&
                        !iserror &&
                        type === 'approved'
                    ) {
                        setError((prev) => ({
                            ...prev,
                            form3: true,
                        }))
                        iserror = true
                        message.error(
                            'Artwork is missing and required to publish the song.'
                        )
                        return
                    } else if (
                        !iserror &&
                        (!curr?.lyrics || !curr?.audio_url) &&
                        type === 'approved'
                    ) {
                        setError((prev) => ({
                            ...prev,
                            form4: true,
                        }))
                        iserror = true
                        message.error(
                            'Playable assets (lyrics or audio) are missing and required to publish the song.'
                        )
                        return
                    }
                    acc[curr?.language_id] = {
                        title: curr?.title,
                        image_url: curr?.image_url,
                        lyrics: curr?.lyrics,
                        duration:curr?.duration,
                        audio_url: curr?.audio_url,
                    }
                    if (castNames && castRoles) {
                        acc[curr?.language_id].cast = {
                            names: castNames,
                            roles: castRoles,
                        }
                    }

                    return acc
                }, {}),
            }
            output['title'] = formsData?.form1?.title
            output['category'] = formsData?.form1?.category
            output['uploaded_by'] = employeeId
            output['content_status'] = type
            if (mode === 'edit') {
                if (
                    type === 'approved' &&
                    (error.form1 ||
                        iserror ||
                        error.form2 ||
                        error.form3 ||
                        error.form4)
                ) {
                    return
                }
                const res = await updateSongs(
                    accessToken,
                    output,
                    record?.song_id
                )
                if (res?.status === 200) {
                    message.success(
                        type === 'draft'
                            ? 'The song has been saved successfully.'
                            : 'The song has been published successfully.'
                    )
                    setSongData([])
                    setFormsData({
                        form1: { title: '', category: 'Devotional' },
                    })
                    getAllSongsfuction()
                }
            } else {
                if (
                    type === 'approved' &&
                    (error.form1 ||
                        error.form2 ||
                        iserror ||
                        error.form3 ||
                        error.form4)
                ) {
                    return
                }
                const res = await createSongs(accessToken, output)
                if (res?.status) {
                    message.success(
                        type === 'draft'
                            ? 'The song has been saved successfully.'
                            : 'The song has been published successfully.'
                    )
                }
                setSongData([])
                setFormsData({
                    form1: { title: '', category: 'Devotional' },
                })
                getAllSongsfuction()
            }
            setIsModalVisible(false)
        } catch (error) {
            console.error('An error occurred while submitting:', error)
            if (!iserror) {
                message.error('unable to add/update song')
            }
        }
    }
    return (
        <>
            <Button
                type="primary"
                icon={mode === 'edit' ? <EditFilled /> : ''}
                onClick={() => setIsModalVisible(true)}
            >
                {mode === 'edit' ? '' : 'Add Song'}
            </Button>
            <Modal
                title={'Add Song'}
                open={isModalVisible}
                onCancel={() =>
                    currentForm
                        ? setCurrentForm(null)
                        : setIsModalVisible(false)
                }
                width={'70%'}
                footer={[
                    <div
                        style={{
                            display: 'flex',
                            gap: '20px',
                            justifyContent: 'center',
                            padding: '10px 0',
                        }}
                    >
                        <Button
                            key="close"
                            type="primary"
                            danger
                            style={{
                                padding: '8px 20px',
                                fontWeight: 500,
                                fontSize: 14,
                                borderRadius: '4px',
                                border: 'none',
                                boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
                                backgroundColor: '#ff4d4f',
                                width: '120px',
                            }}
                            onClick={() => setIsModalVisible(false)}
                        >
                            Close
                        </Button>
                        <Button
                            disabled={record?.content_status === 'approved'}
                            key="submit"
                            type="primary"
                            style={{
                                padding: '8px 20px',
                                fontWeight: 500,
                                fontSize: 14,
                                borderColor:
                                    record?.content_status === 'approved'
                                        ? 'gray'
                                        : '#52c41a',
                                backgroundColor:
                                    record?.content_status === 'approved'
                                        ? 'lightgray'
                                        : '#52c41a',
                                width: '120px',
                            }}
                            onClick={() => handleSubmitAll('draft')}
                        >
                            Save
                        </Button>
                        <Button
                            key="publish"
                            type="primary"
                            style={{
                                padding: '8px 20px',
                                fontWeight: 500,
                                fontSize: 14,
                                borderRadius: '4px',
                                border: 'none',
                                boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
                                backgroundColor: '#1890ff',
                                width: '120px',
                            }}
                            onClick={() => handleSubmitAll('approved')}
                        >
                            Publish
                        </Button>
                    </div>,
                ]}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0px',
                        transition: 'transform .3s ease-in-out',
                    }}
                >
                    {[
                        {
                            label: 'Basic Info',
                            icon: (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    color="gray"
                                    width={40}
                                    height={40}
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    class="size-6"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            ),
                            key: 'form1',
                            desc: 'Title, Category',
                            data: formsData?.form1?.title ? true : false,
                            disabled:false
                        },
                        {
                            label: 'Metadata',
                            icon: (
                                <TagsFilled
                                    style={{ fontSize: 40, color: '#52c41a' }}
                                />
                            ),
                            key: 'form2',
                            desc: 'Language, Artist',
                            disabled: false,
                            data:songData?.length!==0 ? true : false

                        },
                        {
                            label: 'Artwork',
                            icon: (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={40}
                                    height={40}
                                    color={'#faad14'}
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    class="size-6"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M1.5 6a2.25 2.25 0 0 1 2.25-2.25h16.5A2.25 2.25 0 0 1 22.5 6v12a2.25 2.25 0 0 1-2.25 2.25H3.75A2.25 2.25 0 0 1 1.5 18V6ZM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0 0 21 18v-1.94l-2.69-2.689a1.5 1.5 0 0 0-2.12 0l-.88.879.97.97a.75.75 0 1 1-1.06 1.06l-5.16-5.159a1.5 1.5 0 0 0-2.12 0L3 16.061Zm10.125-7.81a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            ),
                            key: 'form3',
                            desc: 'Image',
                            data:
                                songData?.length !== 0 &&
                                songData?.every(
                                    (e) =>
                                        e?.image_url !== undefined &&
                                        e?.image_url !== '' &&
                                        e?.image_url !== null
                                ),
                            disabled:songData?.length===0 ? true :false,
                        },
                        {
                            label: 'Playable Assets',
                            icon: (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={40}
                                    height={40}
                                    color={'#ff4d4f'}
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    class="size-6"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M19.952 1.651a.75.75 0 0 1 .298.599V16.303a3 3 0 0 1-2.176 2.884l-1.32.377a2.553 2.553 0 1 1-1.403-4.909l2.311-.66a1.5 1.5 0 0 0 1.088-1.442V6.994l-9 2.572v9.737a3 3 0 0 1-2.176 2.884l-1.32.377a2.553 2.553 0 1 1-1.402-4.909l2.31-.66a1.5 1.5 0 0 0 1.088-1.442V5.25a.75.75 0 0 1 .544-.721l10.5-3a.75.75 0 0 1 .658.122Z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            ),
                            key: 'form4',
                            desc: 'Audio, Lyrics',
                            data:
                                songData?.length !== 0 &&
                                songData?.every(
                                    (e) =>
                                        e?.audio_url !== undefined &&
                                        e?.audio_url !== '' &&
                                        e?.audio_url !== null
                                ) &&
                                songData?.every(
                                    (e) =>
                                        e?.lyrics !== undefined &&
                                        e?.lyrics !== '' &&
                                        e?.lyrics !== null
                                ),
                                disabled:songData?.length===0 ? true :false
                        },
                    ].map((e) => (
                        <div
                            className="info-tabs"
                            key={e.key}
                            style={{
                                marginBottom: 0,
                                alignItems: 'center',
                                padding: '20px 10px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                borderBottom: 'solid 1px lightgray',
                                background:
                                    currentForm?.key === e?.key
                                        ? '#40A9FF'
                                        : 'transparent',
                                transition: 'background 0.3s ease-in-out',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    gap: 10,
                                    alignItems: 'center',
                                }}
                            >
                                <div>{e.icon}</div>
                                <div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div
                                            style={{
                                                color:
                                                    currentForm?.key === e?.key
                                                        ? 'white'
                                                        : 'black',
                                                fontWeight: 600,
                                                fontSize: 16,
                                            }}
                                        >
                                            {e.label}
                                        </div>
                                        <div>
                                            {validate?.includes(e?.key) && (
                                                <span>
                                                    {' '}
                                                    {e?.data ? (
                                                        <CheckCircleFilled
                                                            style={{
                                                                color: 'green',
                                                                fontSize: 20,
                                                                marginLeft:
                                                                    '.3rem',
                                                            }}
                                                        />
                                                    ) : (
                                                        <CloseCircleFilled
                                                            style={{
                                                                color: 'red',
                                                                fontSize: 20,
                                                                marginLeft:
                                                                    '.3rem',
                                                            }}
                                                        />
                                                    )}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            color:
                                                currentForm?.key === e?.key
                                                    ? 'white'
                                                    : 'gray',
                                            fontSize: 14,
                                        }}
                                    >
                                        {e.desc}
                                    </div>
                                </div>
                            </div>
                            {error[e.key] ? (
                                <Tooltip title="Some fields are incomplete. Please fill them out to proceed with publishing.">
                                    <Button
                                        className="animated-button"
                                        type="danger"
                                        disabled={e?.disabled}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontWeight: 500,
                                            fontSize: 14,
                                            padding: '5px 15px',
                                            borderRadius: '4px',
                                            boxShadow:
                                                '0px 2px 5px rgba(0,0,0,0.1)',
                                            backgroundColor: '#ff4d4f',
                                            color: '#fff',
                                        }}
                                        iconPosition="end"
                                        icon={<RightOutlined />}
                                        onClick={() => handleAddForm(e)}
                                    >
                                        {e?.data ? 'View' : 'Add'}
                                    </Button>
                                </Tooltip>
                            ) : (
                                <Tooltip title={e?.disabled ? "Please fill metadata to enable this option":''}>
                                <Button
                                    type="primary"
                                    ghost
                                    disabled={e?.disabled}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontWeight: 500,
                                        fontSize: 14,
                                        padding: '5px 15px',
                                        borderRadius: '4px',
                                        boxShadow:
                                            '0px 2px 5px rgba(0,0,0,0.1)',
                                    }}
                                    iconPosition="end"
                                    icon={<RightOutlined />}
                                    onClick={() => handleAddForm(e)}
                                >
                                    {e?.data ? 'View' : 'Add'}
                                </Button>
                                </Tooltip>
                            )}
                        </div>
                    ))}
                </div>

                {currentForm?.key && (
                    <div
                        ref={slidingDivRef}
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            width: '75%',
                            height: '100%',
                            backgroundColor: '#fff',
                            boxShadow: '-2px 0 5px rgba(0,0,0,0.2)',
                            padding: '16px',
                            zIndex: 1000,
                            overflowY: 'auto',
                            transition: 'transform 0.3s ease-in-out',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                gap: 10,
                                alignItems: 'center',
                                paddingBottom: 10,
                                marginBottom: 10,
                                borderBottom: 'solid 1px lightgray',
                            }}
                        >
                            <div>{currentForm.icon}</div>
                            <div>
                                <div
                                    style={{
                                        color: 'black',
                                        fontWeight: 500,
                                        fontSize: 15,
                                    }}
                                >
                                    {currentForm.label}
                                </div>
                            </div>
                        </div>
                        {currentForm?.key === 'form1' ? (
                            <BasicInfo
                                form={form}
                                handleSaveForm={handleSaveForm}
                                setCurrentForm={setCurrentForm}
                            />
                        ) : currentForm?.key === 'form3' ? (
                            <ArtWork
                                songData={songData}
                                setSongData={setSongData}
                                handleSaveForm={handleSaveForm}
                                languageData={languageData}
                                setCurrentForm={setCurrentForm}
                                currentForm={currentForm}
                            />
                        ) : currentForm?.key === 'form2' ? (
                            <MetadataForm
                                setSongData={setSongData}
                                songData={songData}
                                handleSaveForm={handleSaveForm}
                                languageData={languageData}
                                currentForm={currentForm}
                                setCurrentForm={setCurrentForm}
                            />
                        ) : (
                            <PlayableAssets
                                language={language}
                                setSongData={setSongData}
                                songData={songData}
                                currentForm={currentForm}
                                handleSaveForm={handleSaveForm}
                                languageData={languageData}
                                setCurrentForm={setCurrentForm}
                            />
                        )}
                    </div>
                )}
            </Modal>
        </>
    )
}

export default DynamicFormModal

import React, { useEffect, useState } from 'react'
import { Button, Image, Table } from 'antd'
import {
    EditOutlined,
    EnvironmentOutlined,
    ReloadOutlined,
} from '@ant-design/icons'
import { useNavigate } from 'react-router'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { getUGContent } from '../../AadhanApi'
const SponsoredContent = () => {
    const Navigate = useNavigate()
    let optionsString = localStorage.getItem('options')
    let options = JSON.parse(optionsString)
    const accessToken = useSelector((state) => state.app.accessToken)
    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [unassignedCount, setUnassignedCount] = useState([
        { title: 'Unassigned Content Count', count: 0 },
    ])
    const [dates, setDates] = useState({
        startDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD'),
    })
    async function getData() {
        const ugcDataResponse = await getUGContent(
            accessToken,
            options?.language,
            undefined,
            dates.startDate,
            dates.endDate
        )
        setUnassignedCount([
            {
                title: 'Unassigned Content Count',
                count:
                    ugcDataResponse?.data?.unassigned_count !== undefined
                        ? ugcDataResponse?.data?.unassigned_count
                        : 0,
            },
        ])
        setData(ugcDataResponse?.data?.data)
    }
    useEffect(() => {
        getData()
    }, [dates])
    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: '50%',
            render: (_, record) => (
                <div style={{ display: 'flex', alignItems: 'center', gap: 15 }}>
                    <Image
                        src={record?.image_url?.[0]}
                        style={{
                            width: '55px',
                            height: '55px',
                            borderRadius: '5px',
                            objectFit: 'cover',
                            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
                            transition: 'transform 0.2s',
                        }}
                        onMouseOver={(e) =>
                            (e.currentTarget.style.transform = 'scale(1.05)')
                        }
                        onMouseOut={(e) =>
                            (e.currentTarget.style.transform = 'scale(1)')
                        }
                    />
                    <p>{record?.title}</p>
                </div>
            ),
        },
        {
            title: 'Stringer Name',
            dataIndex: 'uploaded_by',
            key: 'uploaded_by',
        },
        {
            title: 'Location',
            key: 'location',
            render: (_, record) => (
                <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                    <EnvironmentOutlined
                        style={{ color: '#1890ff', fontSize: '16px' }}
                    />
                    <div>
                        <div>
                            {record?.state_name ? `${record.state_name}` : ''}
                        </div>
                        <div>
                            {' '}
                            {record?.district_name
                                ? ` ${record.district_name}`
                                : ''}
                        </div>
                    </div>
                </div>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Button
                    type="primary"
                    onClick={() =>
                        Navigate(
                            `/home/editorDashboard/sponsored-content/${record?.content_id}`
                        )
                    }
                    icon={<EditOutlined />}
                ></Button>
            ),
        },
    ]

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '1.1rem',
                        fontWeight: 500,
                    }}
                >
                    <label
                        style={{
                            marginRight: '0.5rem',
                            color: 'orange',
                            fontSize: '1rem',
                        }}
                    >
                        Pending:
                    </label>
                    <span
                        style={{
                            color: 'red',
                            fontSize: '1.3rem',
                            fontWeight: 'bold',
                        }}
                    >
                        {unassignedCount?.[0]?.count}
                    </span>
                </div>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '1.1rem',
                        fontWeight: 500,
                    }}
                >
                    <label
                        style={{
                            marginRight: '0.5rem',
                            color: 'green',
                            fontSize: '1rem',
                        }}
                    >
                        In Review:
                    </label>
                    <span
                        style={{
                            color: 'red',
                            fontSize: '1.3rem',
                            fontWeight: 'bold',
                        }}
                    >
                        {data?.length}
                    </span>
                </div>

                <Button
                    type="primary"
                    iconPosition="end"
                    style={{
                        height: 30,
                        fontSize: '0.9rem',
                        marginLeft: 'auto',
                        padding: '0 20px',
                    }}
                    onClick={() =>
                        navigate('/home/editorDashboard/sponsored-report')
                    }
                >
                    Report
                </Button>
                <Button
                    onClick={() => getData()}
                    icon={<ReloadOutlined />}
                    type="primary"
                    ghost
                    style={{
                        marginLeft: '1rem',
                        height: 30,
                        fontSize: '0.9rem',
                    }}
                >
                    Refresh
                </Button>
            </div>

            <hr style={{ margin: '5px 0' }}></hr>
            <Table
                columns={columns}
                dataSource={data}
                dataLoading={false}
                disablePagination={true}
                onRow={() => {}}
            />
        </div>
    )
}
export default SponsoredContent

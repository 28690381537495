import { Button, Col, Form, Input, message, Radio, Row, Select } from 'antd'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useEffect, useState } from 'react'
import {
    createEmployee,
    getEmployeeDepartments,
    getEmployeeShifts,
    getRoleList,
    getRolesByDepartment,
} from '../../../AadhanApi'
import '../Employee.scss'
import ReactSelect from 'react-select'
import { Option } from 'antd/es/mentions'
const AddEmployee = () => {
    const [form] = Form.useForm()
    const [allRoles, setAllRoles] = useState([])
    const navigate = useNavigate()

    const getAllRoleList = async () => {
        const response = await getRoleList(accessToken)
        setAllRoles(response?.data)
    }
    const allStates = useSelector((state) => state.app.locations).map((e) => ({
        value: e.state_id,
        label: e.name,
    }))
    const allDistricts = useSelector((state) => state.app.districts)
    const allConstituencies = useSelector((state) => state.app.constituencies)
    const [state, setState] = useState('')
    const [selectedConstituencies, setSelectedConstituncies] = useState([])
    const [selectedDistricts, setSelectedDistricts] = useState([])
    const [shifts, setShifts] = useState([])
    const [departmentsData, setDepartmentsData] = useState()
    const [departmentRoles, setDepartmentsRoles] = useState([])

    async function getShiftData() {
        const data = await getEmployeeShifts(accessToken)
        setShifts(data?.data?.data)
    }
    useEffect(() => {
        getShiftData()
        getAllRoleList()
        fetchDepartments()

        form.setFieldValue('is_available', false)
    }, [])

    const accessToken = useSelector((state) => state.app.accessToken)
    const languageData = [
        {
            language_id: 1,
            language_name: 'English',
        },
        {
            language_id: 2,
            language_name: 'Telugu',
        },
        {
            language_id: 3,
            language_name: 'Hindi',
        },
        {
            language_id: 4,
            language_name: 'Tamil',
        },
    ]
    const allEmployees = useSelector((state) => state.app.employees)
    const employeeId = useSelector((state) => state.app.employeeId)

    const fetchDepartments = async () => {
        try {
            const response = await getEmployeeDepartments(accessToken)
            setDepartmentsData(response.data.data)
        } catch (error) {
            message.error('Failed to fetch departments data')
        }
    }

    const fetchRolesByDepartment = async (departmentId) => {
        try {
            const roleListResponse = await getRolesByDepartment(
                accessToken,
                departmentId
            )
            const mappedRoles = roleListResponse.data
                ?.filter((role) => role.status !== 'deleted')
                .map((role) => ({
                    value: role.role_id,
                    label: role.role_name,
                }))
            setDepartmentsRoles(mappedRoles)
        } catch (error) {
            message.error('Failed to fetch roles for selected department')
        }
    }

    const onFinish = async (values) => {
        const obj = {
            first_name: values.firstName,
            last_name: values.lastName,
            email_id: values.email,
            password: values.password,
            mobile_number: values.mobile,
            employee_department_id: values.department,
            role_id: values.role,
            languages: values.languages,
            reports_to: values.reportsTo,
            image: '',
            created_by: employeeId,
            created_date: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            typeof_employee: 'internal',
            last_login_time: '2023-07-19T14:34:40.573Z',
            updated_by: employeeId,
            updated_date: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            status: values.status,
            district: values?.district?.map(({ label }) => label).join(','),
            district_id: values?.district?.map(({ value }) => value).join(','),
            state: values?.state?.map(({ label }) => label).join(','),
            state_id: values?.state?.map(({ value }) => value).join(','),
            constituencies: values?.constituency?.map(({ label }) => label),
            constituency_ids: values?.constituency?.map(({ value }) => value),
            is_availdable: values?.is_available,
            shift_id:
                values?.shift_name !== '' &&
                values?.shift_name !== undefined &&
                values?.shift_name !== null
                    ? parseInt(values?.shift_name)
                    : 0,
            shift_name: shifts.filter(
                (e) => e.shift_id === values?.shift_name
            )?.[0]?.shift_name,
        }
        try {
            const employeeResponse = await createEmployee(obj, accessToken)

            if (employeeResponse?.data === 'Employee inserted successfully') {
                message.success('Employee added successfully.')
                navigate('/home/employees', {
                    state: {
                        employeeStatus: values.status,
                        employeeLanguage: Array.isArray(values.languages)
                            ? values.languages[0]
                            : values.languages,
                        employeeDepartment: values.department,
                    },
                })
                form.resetFields()
            } else {
                message.error('Failed to insert employee. Please try again.')
            }
        } catch (error) {
            if (error.response?.status === 409) {
                message.error('Conflict: Email already exits')
            } else {
                console.error('Error creating employee:', error)
                message.error(
                    'An error occurred while creating the employee. Please try again.'
                )
            }
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }
    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: '100%',
        }),
        control: (provided) => ({
            ...provided,
            minHeight: '32px',
            height: '32px',
        }),
        valueContainer: (provided) => ({
            ...provided,
            height: '32px',
            padding: '0 6px',
        }),
        input: (provided) => ({
            ...provided,
            margin: '0px',
            fontSize: '12px',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none',
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            height: '32px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '12px',
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '12px',
        }),
    }
    function handleStateChange(e) {
        setState(e) // Assuming `setState` updates the state with selected options

        let dist = []
        e.forEach((stateOption) => {
            if (allDistricts[stateOption.value]) {
                // Check if districts exist for selected state
                dist.push(
                    ...allDistricts[stateOption.value].map((district) => ({
                        label: district.name,
                        value: district.district_id,
                    }))
                )
            }
        })
        setSelectedDistricts(dist)
        let consti = []
        e.forEach((stateOption) => {
            if (allDistricts[stateOption.value]) {
                // Check if districts exist for selected state
                consti.push(
                    ...allConstituencies[stateOption.value].map((cons) => ({
                        label: cons.name,
                        value: cons.assembly_constituency_id,
                    }))
                )
            }
        })
        setSelectedConstituncies(consti)
    }

    return (
        <div
            style={{
                marginLeft: '1vw',
                marginTop: '1.2vh',
                marginRight: '1vw',
            }}
        >
            <h3
            // className="heading"
            >
                Add Employee
            </h3>
            <hr></hr>
            <div>
                <Form
                    id="form"
                    form={form}
                    name="employeeForm"
                    scrollToFirstError
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 18 }}
                    initialValues={{
                        status: 'active',
                        is_available: 'true',
                        firstName: '',
                        lastName: '',
                        email: '',
                        mobile: '',
                        role: '',
                        department: '',
                        password: '',
                        reportsTo: '',
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                label="First Name"
                                name="firstName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter value',
                                    },
                                ]}
                            >
                                <Input
                                    style={{ width: '100%', minWidth: '100%' }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="Last Name"
                                name="lastName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter Last name',
                                    },
                                ]}
                            >
                                <Input
                                    style={{ width: '100%', minWidth: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        type: 'email',
                                        message: 'Please enter valid email',
                                    },
                                ]}
                                validateTrigger="onBlur"
                            >
                                <Input
                                    style={{ width: '100%', minWidth: '100%' }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="Mobile"
                                name="mobile"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter mobile',
                                    },
                                    {
                                        pattern: /^[\d]{10}$/,
                                        message:
                                            'Mobile Number should be 10 digits',
                                    },
                                ]}
                                validateTrigger="onBlur"
                            >
                                <Input
                                    type="string"
                                    style={{ width: '100%', minWidth: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                label="Department"
                                name="department"
                                placeholder="Please select department"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select department',
                                    },
                                ]}
                            >
                                <Select
                                    onChange={(value) => {
                                        fetchRolesByDepartment(value)
                                        form.setFieldValue('role', null)
                                    }}
                                >
                                    {departmentsData?.map((department) => (
                                        <Select.Option
                                            key={
                                                department.employee_department_id
                                            }
                                            value={
                                                department.employee_department_id
                                            }
                                        >
                                            {
                                                department.employee_department_name
                                            }
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="Role"
                                name="role"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select role',
                                    },
                                ]}
                            >
                                <Select>
                                    {departmentRoles?.map((role) => (
                                        <Select.Option
                                            key={role.value}
                                            value={role.value}
                                        >
                                            {role.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                label="Language(s)"
                                name="languages"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select language',
                                    },
                                ]}
                            >
                                <Select mode="multiple">
                                    {languageData.map((option, index) => (
                                        <Select.Option
                                            key={index}
                                            value={option.language_id}
                                        >
                                            {option.language_name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter password',
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                label="Reports To"
                                name="reportsTo"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter value',
                                    },
                                ]}
                            >
                                <Select>
                                    {allEmployees?.map((employee, index) => (
                                        <Select.Option
                                            key={index}
                                            value={employee.employee_id}
                                        >
                                            {employee.employee_name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label="Is Available" name="is_available">
                                <Radio.Group>
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Shift Name" name="shift_name">
                                <Select placeholder="select employee shift">
                                    {shifts?.map((e) => (
                                        <Option
                                            value={e.shift_id}
                                            key={e.shift_id}
                                        >
                                            {e.shift_name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Status" name="status">
                                <Radio.Group>
                                    <Radio value="active"> Active </Radio>
                                    <Radio value="inactive"> Inactive </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        {/* <Col span={12}>
                            <Form.Item label="Shift Id" name="shift_id">
                                <Input type="number"></Input>
                            </Form.Item>
                        </Col> */}
                    </Row>
                    {/* <Row>
                        <Col span={12}>
                            <Form.Item label="Status" name="status">
                                <Radio.Group>
                                    <Radio value="active"> Active </Radio>
                                    <Radio value="inactive"> Inactive </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col span={12}>
                            <Form.Item label="State" name="state">
                                <ReactSelect
                                    onChange={(e) => handleStateChange(e)}
                                    options={allStates}
                                    placeholder="Select state"
                                    isClearable
                                    isMulti
                                    styles={customStyles}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label="District" name="district">
                                <ReactSelect
                                    options={selectedDistricts}
                                    placeholder="Select District"
                                    isClearable
                                    isMulti
                                    styles={customStyles}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={20}>
                            <Form.Item
                                label="Assembly Constituency"
                                name="constituency"
                            >
                                <ReactSelect
                                    options={selectedConstituencies}
                                    placeholder="Select constituency"
                                    isClearable
                                    isMulti
                                    styles={{
                                        ...customStyles,
                                        container: (provided) => ({
                                            ...provided,
                                            width: 333,
                                        }),
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <Button
                            className="bg-color width-150 submit-buttons"
                            type="primary"
                            onClick={() => {
                                form.resetFields()
                            }}
                        >
                            Reset
                        </Button>
                        <Button
                            className="bg-color width-150"
                            type="primary"
                            htmlType="submit"
                        >
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default AddEmployee

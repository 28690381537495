import { message, Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import './Reporter.scss'
import { getReporterTableData } from '../../AadhanApi'
import ExcelSheetUI from './ExcelSheetUI'
import { SelectComponentWithSearch } from '../AntdComponent/SelectComponentWithSearch'
const EditStringers = () => {
    const { Search } = Input
    const [filterData, setFilterData] = useState([])
    const locations = useSelector((state) => state.app.locations)
    const [bankFilter, setBankFilter] = useState(undefined)
    const allDistricts = useSelector((state) => state.app.districts)
    const allMandals = useSelector((state) => state.app.mandals)
    const allConstituencies = useSelector((state) => state.app.constituencies)
    const [typeOption, setTypeOption] = useState('paid')
    const [state, setState] = useState(undefined)
    const [district, setDistrict] = useState(undefined)
    const [mobile, setMobile] = useState('')
    const [name, setName] = useState('')
    const accessToken = useSelector((state) => state.app.accessToken)
    const languageData = useSelector((state) => state.app.languageData)
    const [dataLoading, setDataLoading] = useState(false)
    const [data, setData] = useState([])
    const [messageApi, contextHolder] = message.useMessage()
    const displayError = (errMessage) => {
        messageApi.open({
            type: 'error',
            content: errMessage,
            duration: 5,
        })
    }
    const handleStateChange = (event) => {
        setDistrict(undefined)
        setState(event)
    }
    const handleDistrictChange = (event) => {
        setDistrict(event)
    }
    const handleBankToggle = (value) => {
        setBankFilter(value)
    }
    useEffect(() => {
        setDataLoading(true)
        const filteredData = filterData?.filter((item) => {
            const { account_number, pan_number } = item?.reporter_information
                ?.payment_info
                ? item?.reporter_information?.payment_info
                : {}
            if (bankFilter === 'bank') {
                if (account_number) {
                    return item
                }
            } else if (bankFilter === 'no bank') {
                if (!account_number) {
                    return item
                }
            } else if (bankFilter === 'pan') {
                if (pan_number) {
                    return item
                }
            } else if (bankFilter === 'no pan') {
                if (!pan_number) {
                    return item
                }
            } else {
                return item
            }
        })

        setData(filteredData)
        setDataLoading(false)
    }, [bankFilter])

    const getReporterTable = async () => {
        setDataLoading(true)

        try {
            const getReporterTableDataResponse = await getReporterTableData(
                typeOption,
                mobile,
                name,
                accessToken,
                state,
                district,
                1
            )
            if (getReporterTableDataResponse?.data) {
                const filteredData = getReporterTableDataResponse?.data?.filter((item) => {
                    const { account_number, pan_number } = item?.reporter_information
                        ?.payment_info
                        ? item?.reporter_information?.payment_info
                        : {}
                    if (bankFilter === 'bank') {
                        if (account_number) {
                            return item
                        }
                    } else if (bankFilter === 'no bank') {
                        if (!account_number) {
                            return item
                        }
                    } else if (bankFilter === 'pan') {
                        if (pan_number) {
                            return item
                        }
                    } else if (bankFilter === 'no pan') {
                        if (!pan_number) {
                            return item
                        }
                    } else {
                        return item
                    }
                })
            
                setData(filteredData)
                setFilterData(getReporterTableDataResponse?.data)
            }
            setDataLoading(false)
        } catch (err) {
            console.log('error is ::::', err)
            setDataLoading(false)
            if (err?.response?.status === 401) {
                displayError('You are unauthorised, Please login again')
            } else {
                displayError('Failed to get Stringers data')
            }
        }
    }
    useEffect(() => {
        getReporterTable()
    }, [state, district, typeOption])
    return (
        <div className="stringers_Container">
                <h4>Manage App Stringers</h4>
            <hr></hr>
            <div style={{ display: 'flex',gap:10 }}>
                <SelectComponentWithSearch
                    data={locations}
                    value={state}
                    onChange={handleStateChange}
                    key_value={'state_id'}
                    display={'name'}
                    style={{ width: 170 }}
                    placeholder={'Select State'}
                />
                <SelectComponentWithSearch
                    data={allDistricts?.[state]}
                    value={district}
                    onChange={handleDistrictChange}
                    key_value={'district_id'}
                    display={'name'}
                    className={''}
                    style={{width: 180 }}
                    placeholder={'Select District'}
                />
                 <SelectComponentWithSearch
                        data={[
                            { label: 'With Bank Account', value: 'bank' },
                            { label: 'Without Bank Account', value: 'no bank' },
                            { label: 'With Pan', value: 'pan' },
                            { label: 'Without Pan', value: 'no pan' },
                        ]}
                        value={bankFilter}
                        onChange={handleBankToggle}
                        key_value={'value'}
                        display={'label'}
                        style={{ width: 180 }}
                        placeholder={'Select Custom Filter'}
                    />
                <Search
                    className=""
                    placeholder="search by name"
                    enterButton="Search"
                    size="middle"
                    allowClear
                    onChange={(e) => setName(e.target.value)}
                    onSearch={() => getReporterTable()}
                    value={name}
                    style={{ width: 250 }}
                />

                <Search
                    placeholder="search by mobile number"
                    enterButton="Search"
                    size="middle"
                    allowClear
                    onChange={(e) => {
                        const value = e.target.value.slice(0, 10) // Ensure only 10 digits

                        setMobile(value)
                    }}
                    onSearch={() => getReporterTable()}
                    value={mobile}
                    style={{ width: 250 }}
                    type="number"
                />
                
            </div>
            <ExcelSheetUI
                allMandals={allMandals}
                dataLoading={dataLoading}
                data={data}
                accessToken={accessToken}
                languageData={languageData}
                locations={locations}
                allDistricts={allDistricts}
                allConstituencies={allConstituencies}
            />
        </div>
    )
}

export default EditStringers

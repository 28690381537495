import { useLocation, useNavigate } from 'react-router'
import { Tabs } from 'antd'
import '../WriterDashboard/writerDashboard.scss'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import AddShortNews from '../AddShortNews/AddShortNews'
import { Activity } from '../WriterDashboard/Activity'
const { TabPane } = Tabs

export const ReporterDashboard = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const mode = searchParams.get('mode')
    const [loading, setLoading] = useState(false)
    const [dates, setDates] = useState({
        startDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'), 
        endDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'), 
    });
    

    const handleTabChange = (key) => {
        switch (key) {
            case '1':
                navigate('/home/reporter-dashboard?mode=add')
                break
            case '2':
                navigate('/home/reporter-dashboard?mode=activity')
                break
            case '3':
                navigate('/home/reporter-dashboard?mode=edit')
                break
            default:
                break
        }
    }

    useEffect(() => {
        if (!mode) {
            navigate('/home/reporter-dashboard?mode=add')
        }
    }, [location])

    const activeKey = mode === 'activity' ? '2' : '1'

    function resetWriterDashboard() {
        navigate('/home/reporter-dashboard?mode=loading')
    }
    
    useEffect(() => {
        if (mode === 'loading') {
            setLoading(true)
            const timer = setTimeout(() => {
                setLoading(false)
                navigate('/home/reporter-dashboard?mode=add')
            }, 100)
            return () => clearTimeout(timer)
        }
    }, [mode, navigate])

    return (
        <div style={{ position: 'relative' }}>
            <Tabs
                activeKey={activeKey}
                onChange={handleTabChange}
                style={{
                    height: '2rem',
                    padding: '0 1px',
                    margin: '-5px 1.5rem 0 1rem',
                }}
                className="writer_tab"
            >
                <TabPane
                    tab={
                        <span style={{ color: 'gray', fontWeight: 'bold' }}>
                            {mode === 'edit' ? 'Edit Content' : ' Add Content'}
                        </span>
                    }
                    key="1"
                />
                <TabPane
                    tab={
                        <span style={{ fontWeight: 'bold', color: 'gray' }}>
                            Today's Activity
                        </span>
                    }
                    key="2"
                />
            </Tabs>
            {!loading && mode === 'activity' && <Activity dates={dates} />}
            {!loading && (mode === 'add' || mode === 'edit') && (
                <AddShortNews
                    mode={mode}
                    resetWriterDashboard={resetWriterDashboard}
                />
            )}
        </div>
    )
}

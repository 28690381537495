import React from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import App from './App'
import { BrowserRouter, useNavigate } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css'
import { Provider } from 'react-redux'
import Store from './store/Store'
import { setupNetworkMonitor } from './setupNetworkMonitor'

function AppWithMonitoring() {
    const navigate = useNavigate()
    React.useEffect(() => {
        setupNetworkMonitor(navigate)
    }, [])
    return <App />
}
const root = ReactDOM.createRoot(document.getElementById('root'))
const queryClient = new QueryClient()
root.render(
    <QueryClientProvider client={queryClient}>
        <Provider store={Store}>
            <BrowserRouter>
                <AppWithMonitoring />
            </BrowserRouter>
        </Provider>
    </QueryClientProvider>
)

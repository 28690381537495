import React, { useEffect, useState } from 'react'
import { Button, message, Typography, Col, Card, Row, Select } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import CommonTable from '../CommonTable/CommonTable'
import PreviewCorousel from '../PreviewCorousel/PreviewCorousel'
import { changePopup } from '../../store/slice/AppSlice'
import { getRevenues, getEmployeesByRoles } from '../../AadhanApi'
import { EditOutlined, CheckCircleOutlined } from '@ant-design/icons'
import '../AddAd/ManageAd.scss'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import ManageContentDateRange from '../ManageContent/ManageContentDateRange'
import { IndianRupee, Handshake } from 'lucide-react'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
const { Text } = Typography
const { Option } = Select
const All_AD_TYPES = ['L band', 'Strip Ad', 'Pre Roll Ad', 'Sponsorship']

const RevenueTable = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [dataLoading, setDataLoading] = useState(false)
    const [revenueData, setRevenueData] = useState([])
    const [filteredRevenueData, setFilteredRevenueData] = useState([])
    const [contentId, setContentId] = useState('')
    const [selectedClosedBy, setSelectedClosedBy] = useState(null)
    const [adType, setAdType] = useState(null)
    const [salesEmployees, setSalesEmployees] = useState([])
    const [employeesLoading, setEmployeesLoading] = useState(false)
    const [dates, setDates] = useState({
        startDate: dayjs().startOf('day'), // Initialize with today's date
        endDate: dayjs().endOf('day'), // Initialize with today's date
    })

    const [summary, setSummary] = useState({
        totalDeals: 0,
        amountAgreed: 0,
        amountReceived: 0,
    })

    const popup = useSelector((state) => state.app.popup)
    const accessToken = useSelector((state) => state.app.accessToken)

    const onEditRevenue = (record) => {
        navigate('/home/revenue', {
            state: { isEdit: true, revenueData: record },
        })
    }

    const handleRowClick = (record) => {
        onEditRevenue(record)
    }

    const updateLocalStorageData = (type, value) => {
        const storedJsonString = localStorage.getItem('revenueTable')
        if (storedJsonString !== null) {
            const storedObj = JSON.parse(storedJsonString)
            storedObj[type] = value
            const updatedJsonString = JSON.stringify(storedObj)
            localStorage.setItem('revenueTable', updatedJsonString)
        }
    }
    // Function to fetch all possible closed by options from revenue data
    const fetchEmployees = async () => {
        if (!accessToken) return

        setEmployeesLoading(true)
        try {
            const salesResponse = await getEmployeesByRoles(
                accessToken,
                'sales'
            )
            if (salesResponse?.data?.data) {
                setSalesEmployees(salesResponse.data.data)

                // Load stored filter values from localStorage
                const storedData = localStorage.getItem('revenueTable')
                if (storedData) {
                    const parsedData = JSON.parse(storedData)
                    if (parsedData.closedBy) {
                        setSelectedClosedBy(parsedData.closedBy)
                    }
                    if (parsedData.adType) {
                        setAdType(parsedData.adType)
                    }
                }
            }
        } catch (error) {
            console.error('Error fetching employees:', error)
            message.error('Failed to fetch employees')
        } finally {
            setEmployeesLoading(false)
        }
    }
    // Add this useEffect to handle initial date loading from localStorage
    useEffect(() => {
        const storedData = localStorage.getItem('revenueTable')
        if (storedData) {
            const parsedData = JSON.parse(storedData)
            if (parsedData.dates) {
                const { startDate, endDate } = parsedData.dates
                addDates(
                    startDate ? dayjs(startDate) : null,
                    endDate ? dayjs(endDate) : null
                )
            }
        }
    }, [])
    const addDates = (startDate, endDate) => {
        // Ensure we're working with dayjs objects
        let updatedDates = {
            startDate: startDate ? dayjs(startDate) : null,
            endDate: endDate ? dayjs(endDate) : null,
        }

        // Validate dates before setting
        if (updatedDates.startDate && updatedDates.startDate.isValid()) {
            updatedDates.startDate = updatedDates.startDate.startOf('day')
        }
        if (updatedDates.endDate && updatedDates.endDate.isValid()) {
            updatedDates.endDate = updatedDates.endDate.endOf('day')
        }

        setDates(updatedDates)

        // Store valid dates in localStorage
        if (
            updatedDates.startDate?.isValid() &&
            updatedDates.endDate?.isValid()
        ) {
            updateLocalStorageData('dates', {
                startDate: updatedDates.startDate.format('YYYY-MM-DD'),
                endDate: updatedDates.endDate.format('YYYY-MM-DD'),
            })
        }
    }
    const clearDates = () => {
        const updatedDates = {
            startDate: null,
            endDate: null,
        }
        setDates(updatedDates)
        updateLocalStorageData('dates', updatedDates)
        setSelectedClosedBy(null)
        setAdType(null)
        fetchRevenues()
    }

    const RevenueColumns = [
        {
            title: 'Deal Close Date',
            dataIndex: 'deal_closed_date',
            key: 'deal_closed_date',
            width: 150,
        },
        {
            title: 'Advertiser Name',
            dataIndex: 'Advertiser',
            key: 'advertiser',
            width: 150,
        },
        {
            title: 'Agreed Amount',
            dataIndex: 'agreed_amount',
            key: 'agreed_amount',
            width: 150,
        },
        {
            title: 'Pending Amount',
            dataIndex: 'pending_amount',
            key: 'pending_amount',
            width: 150,
        },
        {
            title: 'Deal Closed By',
            dataIndex: 'closed_by',
            key: 'closed_by',
            width: 150,
        },
        { title: 'Type', dataIndex: 'Ad_type', key: 'ad_type', width: 150 },
        {
            title: 'Details',
            key: 'details',
            align: 'center',
            width: 50,
            render: (_, record) => (
                <div size="middle" style={{ fontSize: '1.2rem' }}>
                    <EditOutlined
                        onClick={(e) => {
                            e.stopPropagation()
                            onEditRevenue(record)
                        }}
                    />
                </div>
            ),
        },
    ]

    const calculateSummary = (data) => {
        const totalDeals = data.length
        const amountAgreed = data.reduce(
            (sum, record) => sum + record.agreed_amount,
            0
        )
        const amountReceived = data.reduce(
            (sum, record) => sum + record.received_amount,
            0
        )

        return {
            totalDeals,
            amountAgreed,
            amountReceived,
        }
    }

    useEffect(() => {
        if (accessToken) {
            // First fetch employees
            fetchEmployees().then(() => {
                // Then fetch revenues
                fetchRevenues()
            })
        }
        return () => {
            dispatch(changePopup(false))
        }
    }, [accessToken])

    useEffect(() => {
        fetchRevenues()
    }, [dates, selectedClosedBy, adType])
    const filterData = (data) => {
        let filtered = [...data]

        if (selectedClosedBy) {
            filtered = filtered.filter(
                (item) => item.closed_by === selectedClosedBy
            )
        }

        if (adType) {
            filtered = filtered.filter((item) => item.Ad_type === adType)
        }

        if (dates.startDate && dates.endDate) {
            filtered = filtered.filter((item) => {
                const itemDate = dayjs(item.deal_closed_date)
                return (
                    itemDate.isSameOrAfter(dates.startDate) &&
                    itemDate.isSameOrBefore(dates.endDate)
                )
            })
        }

        return filtered
    }

    const fetchRevenues = async () => {
        if (!accessToken) return

        setDataLoading(true)
        try {
            const queryParams = {
                closedBy: selectedClosedBy || null,
                adType: adType || null,
                ...(dates.startDate?.isValid() && {
                    startDate: dates.startDate.format('YYYY-MM-DD'),
                }),
                ...(dates.endDate?.isValid() && {
                    endDate: dates.endDate.format('YYYY-MM-DD'),
                }),
            }

            const response = await getRevenues(accessToken, queryParams)
            if (response?.data?.data) {
                const revenueItems = response.data.data
                setRevenueData(revenueItems)
                setFilteredRevenueData(revenueItems)
                setSummary(calculateSummary(revenueItems))
            } else {
                setRevenueData([])
                setFilteredRevenueData([])
                setSummary(calculateSummary([]))
            }
        } catch (error) {
            console.error('Error while fetching revenues:', error)
            if (accessToken) {
                message.error('Failed to fetch revenue data')
            }
            setRevenueData([])
            setFilteredRevenueData([])
            setSummary(calculateSummary([]))
        } finally {
            setDataLoading(false)
        }
    }
    useEffect(() => {
        if (revenueData.length > 0) {
            const filtered = filterData(revenueData)
            setFilteredRevenueData(filtered)
            setSummary(calculateSummary(filtered))
        }
    }, [selectedClosedBy, adType, dates, revenueData])

    const handleClosedByChange = (value) => {
        setSelectedClosedBy(value)
        updateLocalStorageData('closedBy', value)
    }
    return (
        <div className="manage-ad">
            <div className="manage-content-header">
                <h2 className="revenue-heading">Revenue Details</h2>
                <div className="date-picker-container">
                    <span style={{ marginRight: '5px' }}>Date Range:</span>
                    <ManageContentDateRange
                        dates={dates}
                        addDates={addDates}
                        clearDates={clearDates}
                    />
                </div>
            </div>
            <div className="summary-data">
                <Row gutter={[16, 16]} justify="center">
                    <Col span={8}>
                        <Card
                            className="summary-card"
                            bordered={false}
                            style={{ textAlign: 'center' }}
                        >
                            <Handshake
                                size={36}
                                strokeWidth={1.5}
                                style={{ fontSize: '2rem', color: '#1890ff' }}
                            />
                            <Text
                                strong
                                style={{ display: 'block', marginTop: '8px' }}
                            >
                                Total Deals
                            </Text>
                            <Text style={{ fontSize: '1.5rem', color: '#333' }}>
                                {summary.totalDeals}
                            </Text>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card
                            className="summary-card"
                            bordered={false}
                            style={{ textAlign: 'center' }}
                        >
                            <IndianRupee
                                size={28}
                                strokeWidth={2.25}
                                style={{ fontSize: '2rem', color: '#faad14' }}
                            />
                            <Text
                                strong
                                style={{ display: 'block', marginTop: '8px' }}
                            >
                                Amount Agreed
                            </Text>
                            <Text
                                style={{ fontSize: '1.5rem', color: '#faad14' }}
                            >
                                ₹{summary.amountAgreed.toLocaleString()}
                            </Text>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card
                            className="summary-card"
                            bordered={false}
                            style={{ textAlign: 'center' }}
                        >
                            <CheckCircleOutlined
                                style={{ fontSize: '2rem', color: '#52c41a' }}
                            />
                            <Text
                                strong
                                style={{ display: 'block', marginTop: '8px' }}
                            >
                                Amount Received
                            </Text>
                            <Text
                                style={{ fontSize: '1.5rem', color: '#52c41a' }}
                            >
                                ₹{summary.amountReceived.toLocaleString()}
                            </Text>
                        </Card>
                    </Col>
                </Row>
            </div>
            <div style={{ marginBottom: '16px', display: 'flex', gap: '16px' }}>
                <Select
                    allowClear
                    style={{ width: 200 }}
                    placeholder="Filter by Closed By"
                    onChange={handleClosedByChange}
                    value={selectedClosedBy}
                    showSearch
                    loading={employeesLoading}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        (option?.children ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                    }
                >
                    {salesEmployees.map((em) => (
                        <Option
                            key={em.employee_id}
                            value={`${em.first_name} ${em.last_name}`}
                        >
                            {`${em.first_name} ${em.last_name}`}
                        </Option>
                    ))}
                </Select>
                <Select
                    allowClear
                    style={{ width: 200 }}
                    placeholder="Ad Type"
                    onChange={setAdType}
                    value={adType}
                >
                    {All_AD_TYPES.map((value) => (
                        <Option key={value} value={value}>
                            {value}
                        </Option>
                    ))}
                </Select>
                {/* <Button className="refresh-button" onClick={refreshData}>
                    <RedoOutlined />
                    Reset
                </Button> */}
                <Button
                    type="primary"
                    className="manage-button"
                    onClick={() => navigate('/home/revenue')}
                >
                    Add Revenue
                </Button>
            </div>
            <div className="table-data">
                <CommonTable
                    data={filteredRevenueData}
                    columns={RevenueColumns}
                    dataLoading={dataLoading}
                    onRow={(record) => ({
                        onClick: () => handleRowClick(record),
                        style: { cursor: 'pointer' },
                    })}
                />
                {popup && (
                    <PreviewCorousel
                        data={revenueData}
                        contentId={contentId}
                        tableType="revenue"
                    />
                )}
            </div>
        </div>
    )
}

export default RevenueTable

import { Outlet, useLocation, useNavigate } from 'react-router'
import SideNavbar from '../SideNavbar/SideNavbar'
import { useEffect, useState } from 'react'
import { getAllEmployees, getLanguage, getStates } from '../../AadhanApi'
import { useDispatch, useSelector } from 'react-redux'
import {
    addEmployeeId,
    addEmployeeLanguages,
    addEmployeeName,
    addEmployees,
    addLocations,
    addPermissions,
    addToken,
    addlanguage,
} from '../../store/slice/AppSlice'
import { LoadingOutlined } from '@ant-design/icons'
import { message } from 'antd'
import { Link } from 'react-router-dom'
import { userLogin } from '../../store/slice/AppSlice'

const Home = () => {
    const [isLoadingData, setIsLoadingData] = useState(false)
    const dispatch = useDispatch()
    const location = useLocation()
    const accessToken = useSelector((state) => state.app.accessToken)
    const userPermissions = useSelector((state) => state.app.userPermissions)
    const navigate = useNavigate()
    const [closable, setClosable] = useState(false)
    const getToken = () => {
        if (accessToken && accessToken !== '') {
            return accessToken
        } else {
            const token = localStorage.getItem('ACCESS_TOKEN')
            if (token && token !== '') {
                return token
            }
        }
    }

    const [messageApi, contextHolder] = message.useMessage()
    const displayMessage = (messageType, messageToDisplay) => {
        localStorage.removeItem('ACCESS_TOKEN')
        if (messageType === 'error') {
            messageApi.open({
                type: 'error',
                content: (
                    <div style={{ display: 'flex', gap: '5px' }}>
                        {messageToDisplay}
                        <Link to="/">
                            Click here to login or will be redirected to Login
                            page in 5 seconds
                        </Link>
                    </div>
                ),
                duration: 10,
            })
            setTimeout(() => {
                dispatch(
                    userLogin({
                        isUserLoggedIn: false})
                )
                navigate('/')
            }, 5000)
        }
    }

    const fetchInitialData = async () => {
        setIsLoadingData(true)
        try {
            const languageResponse = await getLanguage('Active', getToken())
            dispatch(addlanguage(languageResponse?.data))
            const allLocationResponse = await getStates(getToken())
            dispatch(addLocations(allLocationResponse?.data))

            const allEmployeeResponse = await getAllEmployees(getToken())
            dispatch(addEmployees(allEmployeeResponse?.data))
            setIsLoadingData(false)
        } catch (err) {
            console.log('error is :::', err)
            setIsLoadingData(false)
            if (err?.response?.status === 401) {
                displayMessage(
                    'error',
                    'You are unauthorised, Please login again'
                )
            } else {
                if (
                    err.response.data.detail.error === 'Signature has expired'
                ) {
                    localStorage.removeItem('ACCESS_TOKEN')
                    localStorage.removeItem('USER_PERMISSIONS')
                    localStorage.removeItem('EMPLOYEE_ID')
                    localStorage.removeItem('EMPLOYEE_NAME')
                    dispatch(userLogin({}))
                    navigate('/')
                } else {
                    messageApi.open({
                        type: 'error',
                        content:
                            'Something Failed! Please refresh and try again',
                        duration: 5,
                    })
                }
            }
        }
    }

    if (!accessToken || accessToken === '') {
        const token = localStorage.getItem('ACCESS_TOKEN')
        const employeeId = localStorage.getItem('EMPLOYEE_ID')
        const employeeName = localStorage.getItem('EMPLOYEE_NAME')
        if (employeeId) {
            dispatch(addEmployeeId(employeeId))
        }
        if (employeeName) {
            dispatch(addEmployeeName(employeeName))
        }
        if (token && token !== '') {
            dispatch(addToken(token))
        } else {
            navigate('/')
        }
    }
    async function getAllLocations() {
        try {
            const token = localStorage.getItem('ACCESS_TOKEN')
            const perms = localStorage.getItem('USER_PERMISSIONS')
            const userName = localStorage.getItem('USER_NAME')
            // Dispatch permissions
            if (perms) {
                dispatch(addPermissions(JSON.parse(perms)))
            }
            dispatch(
                userLogin({
                    isUserLoggedIn: true,
                    accessToken: token,
                    permissions: {
                        'Reporter Dashboard': {
                            'Reporter Dashboard': [
                                'Can Add',
                                'Can Edit',
                                'Can View',
                                'Can Delete',
                                'Can Approve',
                            ],
                        },
                    },
                    employeeId: 0,
                    employeeName: userName,
                    role_id: 0,
                })
            )
            const languageResponse = await getLanguage('Active', getToken())
            // const languageResponse={data:[{language_id:2,name:"Telugu"}]}
            dispatch(addlanguage(languageResponse?.data))
            const allLocationResponse = await getStates(getToken())
            dispatch(addLocations(allLocationResponse?.data))
            const language = JSON.parse(localStorage.getItem('LANGUAGE'))
            const languageData = languageResponse?.data?.filter(
                (e) => e.language_id === language
            )
            dispatch(addlanguage(languageData))
            dispatch(addEmployeeLanguages(languageData))
        } catch (err) {
            console.log('error is :::', err)
            setIsLoadingData(false)
            if (err?.response?.status === 401) {
                displayMessage(
                    'error',
                    'You are unauthorised, Please login again'
                )
            } else {
                if (
                    err.response.data.detail.error === 'Signature has expired'
                ) {
                    dispatch(
                        userLogin({
                            isUserLoggedIn: false})
                    )
                    navigate('/')
                } else {
                    messageApi.open({
                        type: 'error',
                        content:
                            'Something Failed! Please refresh and try again',
                        duration: 5,
                    })
                }

            }
            localStorage.removeItem('ACCESS_TOKEN')
            localStorage.removeItem('USER_PERMISSIONS')
            localStorage.removeItem('USER_ID')
            localStorage.removeItem('USER_NAME')
            localStorage.removeItem('LANGUAGE')
            localStorage.removeItem('STRINGER_TYPE')
        }
    }

    useEffect(() => {
        if (!location.pathname.includes('home/reporter-dashboard')) {
            fetchInitialData()
            if (userPermissions && Object.keys(userPermissions)?.length === 0) {
                const perms = localStorage.getItem('USER_PERMISSIONS')
                dispatch(addPermissions(JSON.parse(perms)))
            }
        } else {
            getAllLocations()
        }    
    }, [])
    return (
        <div style={{ height: '100%' }}>
            {contextHolder}
            {isLoadingData ? (
                <div
                    style={{
                        position: 'absolute',
                        top: '40%',
                        left: '45%',
                    }}
                >
                    <LoadingOutlined style={{ fontSize: '5rem' }} />
                </div>
            ) : (
                <div>
                    <SideNavbar closable={closable} setClosable={setClosable} />
                    <div
                        className="component-container"
                        style={{ marginLeft: closable ? '80px' : '' }}
                    >
                        <Outlet />
                    </div>
                </div>
            )}
        </div>
    )
}
export default Home

import { Button, message, Popconfirm, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import './Snippets.scss'
import CommonTable from '../../CommonTable/CommonTable'
import {
    DeleteOutlined,
    EditOutlined,
    PlayCircleOutlined,
    YoutubeFilled,
} from '@ant-design/icons'
import { useLocation, useNavigate } from 'react-router'
import moment from 'moment'
import {
    deleteSnippet,
    getCities,
    getSnippetCategories,
    getSnippets,
} from '../../../AadhanApi'
import { WEEKDAY_OPTIONS } from '../Categories/SnippetCategoryConstants'
import PreviewCorousel from '../../PreviewCorousel/PreviewCorousel'
import SnippetsPreviewCorousel from './SnippetsPreviewCorousel'
function SnippetsMain() {
    const navigate = useNavigate()
    const location = useLocation()
    const languageData = useSelector((state) => state.app.languageData)
    const token = useSelector((state) => state.app.accessToken)
    const { Option } = Select

    // const [selectedLanguage, setSelectedLanguage] = useState(() => {
    //     const lastSelectedLanguage = localStorage.getItem(
    //         'lastSelectedLanguage'
    //     )
    //     return lastSelectedLanguage
    //         ? parseInt(lastSelectedLanguage)
    //         : location.state?.selectedLanguage || languageData?.[0]?.language_id
    // })

    const [selectedLanguage, setSelectedLanguage] = useState(() => {
        const stateLanguage = location.state?.selectedLanguage
        if (stateLanguage) {
            return parseInt(stateLanguage)
        }
        const lastSelectedLanguage = localStorage.getItem(
            'lastSelectedLanguage'
        )
        return lastSelectedLanguage
            ? parseInt(lastSelectedLanguage)
            : languageData?.[0]?.language_id
    })
    // const [selectedCategory, setSelectedCategory] = useState(
    //     location.state?.selectedCategory || null
    // )

    const [selectedCategory, setSelectedCategory] = useState(() => {
        const stateCategory = location.state?.selectedCategory
        return stateCategory ? stateCategory : null
    })

    const [categories, setCategories] = useState([])
    const [availableStates, setAvailableStates] = useState([])
    const [selectedState, setSelectedState] = useState(() => {
        return location.state?.selectedState || undefined
    })
    const [cities, setCities] = useState([])
    const [selectedCity, setSelectedCity] =
        // useState(null)
        useState(() => {
            return location.state?.selectedCity || null
        })
    const [tableData, setTableData] = useState([])
    const [loading, setLoading] = useState(false)
    const [viewType, setViewType] = useState(() => {
        return location.state?.selectedViewType || 'category'
    })
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [contentId, setContentId] = useState('')
    const [snippetsData, setSnippetsData] = useState([])

    useEffect(() => {
        const fetchCategories = async () => {
            if (!selectedLanguage) return
            try {
                const response = await getSnippetCategories(
                    selectedLanguage,
                    'all',
                    token
                )
                if (response.data.success) {
                    setCategories(response.data.data)

                    if (
                        viewType === 'category' &&
                        !selectedCategory &&
                        response.data.data.length > 0
                    ) {
                        setSelectedCategory(
                            response.data.data[0].snippet_category_id
                        )
                    }
                }
            } catch (error) {
                console.error('Error fetching categories:', error)
                message.error('Failed to fetch categories')
                setCategories([])
                // setSelectedCategory(null)
            }
        }

        if (selectedLanguage) {
            fetchCategories()
        }
    }, [selectedLanguage, token])

    useEffect(() => {
        if (
            viewType === 'state' &&
            availableStates.length > 0 &&
            !selectedState
        ) {
            setSelectedState(availableStates[0].state_id)
        }

        if (
            viewType === 'specialContent' &&
            availableStates.length > 0 &&
            !selectedState
        ) {
            setSelectedState('all')
        }
    }, [viewType, availableStates])

    // Fetch snippets when language or category changes
    useEffect(() => {
        const fetchSnippets = async () => {
            if (!selectedLanguage) return
            if (viewType === 'category' && !selectedCategory) return
            if (viewType === 'state' && !selectedState) return
            if (viewType === 'state' && !selectedCity) return

            try {
                setLoading(true)
                const response = await getSnippets(
                    selectedLanguage,
                    viewType === 'category' ? selectedCategory : null,
                    // viewType === 'state' ? selectedState : null,
                    viewType === 'state'
                        ? selectedState
                        : viewType === 'specialContent'
                        ? selectedState === 'all'
                            ? ''
                            : selectedState
                        : null,
                    viewType === 'state' ? selectedCity : null,
                    viewType === 'specialContent',
                    token
                )
                if (response.data.data) {
                    // setTableData(response.data.data || [])
                    const sortedData = response.data.data.sort((a, b) =>
                        moment(b.uploaded_date).isAfter(moment(a.uploaded_date))
                            ? 1
                            : -1
                    )
                    setTableData(sortedData || [])
                    setSnippetsData(sortedData || [])
                } else {
                    message.error(
                        response.data.message || 'Failed to fetch snippets'
                    )
                    setTableData([])
                    setSnippetsData([])
                }
            } catch (error) {
                console.error('Error fetching snippets:', error)
                message.error('Failed to fetch snippets')
                setSnippetsData([])
            } finally {
                setLoading(false)
            }
        }

        fetchSnippets()
    }, [
        selectedLanguage,
        selectedCategory,
        selectedState,
        selectedCity,
        viewType,
        token,
    ])

    useEffect(() => {
        if (selectedLanguage && languageData) {
            const selectedLanguageData = languageData.find(
                (lang) => lang.language_id === selectedLanguage
            )
            if (selectedLanguageData && selectedLanguageData.states) {
                setAvailableStates(selectedLanguageData.states)
                if (viewType === 'state') {
                    setSelectedState(selectedLanguageData.states[0]?.state_id)
                }
            }
        }
    }, [selectedLanguage, languageData, viewType])

    useEffect(() => {
        const fetchCities = async () => {
            if (viewType === 'state' && selectedState) {
                try {
                    const response = await getCities(token, selectedState)
                    setCities(response.data)

                    if (response.data.length > 0) {
                        setSelectedCity(response.data[0].city_id)
                    }

                    if (
                        selectedCity &&
                        !response.data.some(
                            (city) => city.city_id === selectedCity
                        )
                    ) {
                        setSelectedCity(response.data[0]?.city_id || null)
                    }
                } catch (error) {
                    console.error('Error fetching cities:', error)
                    setCities([])
                    setSelectedCity(null)
                }
            }
        }

        fetchCities()
    }, [selectedState, viewType, token])

    useEffect(() => {
        if (viewType !== 'state') {
            setSelectedCity(null)
            setCities([])
        }
    }, [viewType, selectedState])

    const viewPopup = (id) => {
        setContentId(id)
        setIsModalOpen(true)
    }

    const handleLanguageChange = (value) => {
        setSelectedLanguage(parseInt(value))
        localStorage.setItem('lastSelectedLanguage', parseInt(value).toString())
        setSelectedCategory(null)
        // setCategories([])
        setTableData([])
        // setSelectedState(undefined)

        if (viewType === 'state') {
            setSelectedState(null)
        }

        if (viewType === 'specialContent') {
            setSelectedState('all')
        }

        const selectedLanguageData = languageData.find(
            (lang) => lang.language_id === parseInt(value)
        )
        if (selectedLanguageData && selectedLanguageData.states) {
            setAvailableStates(selectedLanguageData.states)

            if (viewType === 'state') {
                setSelectedState(selectedLanguageData.states[0]?.state_id)
            }
        }
    }

    const handleViewTypeChange = (value) => {
        setViewType(value)
        setSelectedCategory(null)
        setSelectedState(null)
        setTableData([])

        if (value === 'category' && categories.length > 0) {
            setSelectedCategory(categories[0].snippet_category_id)
        } else if (value === 'state' && availableStates.length > 0) {
            setSelectedState(availableStates[0].state_id)
        } else if (value === 'specialContent') {
            setSelectedState('all')
        }
    }

    const handleCategoryChange = (value) => {
        setSelectedCategory(value)
    }

    const handleStateChange = (value) => {
        setSelectedState(value)
    }

    const handleCityChange = (value) => {
        setSelectedCity(value)
    }

    const handleEdit = (record) => {
        navigate('/home/addsnippet-form', {
            state: {
                editingSnippet: record,
                selectedLanguage: selectedLanguage,
                selectedCategory: selectedCategory,
                selectedViewType: viewType,
            },
        })
    }

    const handleDelete = async (contentId) => {
        try {
            await deleteSnippet(contentId, token)
            message.success('Snippet deleted successfully')

            const response = await getSnippets(
                selectedLanguage,
                viewType === 'category' ? selectedCategory : null,
                // viewType === 'state' ? selectedState : null,
                viewType === 'state'
                    ? selectedState
                    : viewType === 'specialContent'
                    ? selectedState === 'all'
                        ? ''
                        : selectedState
                    : null,
                viewType === 'state' ? selectedCity : null,
                viewType === 'specialContent',
                token
            )
            // if (response.data.success) {
            setTableData(response.data.data || [])
            // }
        } catch (error) {
            console.error('Error deleting snippet:', error)
            message.error('Failed to delete snippet')
        }
    }

    const columns = [
        {
            title: 'Snippet Title',
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => {
                // Checking if image_url is empty or null
                const aHasNoImage = !a.image_url
                const bHasNoImage = !b.image_url

                // If one record has no image and the other does, prioritizing the one without image
                if (aHasNoImage && !bHasNoImage) return -1
                if (!aHasNoImage && bHasNoImage) return 1

                // If both have images or both don't have images, sorting by title
                return a.title.localeCompare(b.title)
            },
            sortDirections: ['ascend', 'descend'],

            width: '33%',
            render: (text, record) => (
                <div
                    style={{
                        display: 'flex',
                        gap: '1vw',
                        alignItems: 'center',
                    }}
                >
                    {record.image_url && (
                        <span
                            className="img-container"
                            style={{ flexShrink: 0 }}
                        >
                            <img
                                src={record.image_url}
                                alt={record.title}
                                style={{
                                    width: '35px',
                                    height: '35px',
                                    objectFit: 'cover',
                                    borderRadius: '4px',
                                }}
                            />
                        </span>
                    )}
                    <span
                        onClick={() => viewPopup(record.content_id)}
                        style={{
                            color: '#0000ff',
                            cursor: 'pointer',
                            textDecoration: 'none',
                        }}
                        onMouseOver={(e) =>
                            (e.target.style.textDecoration = 'underline')
                        }
                        onMouseOut={(e) =>
                            (e.target.style.textDecoration = 'none')
                        }
                    >
                        {text}
                    </span>
                    <span style={{ fontSize: '1.5rem', marginLeft: 'auto' }}>
                        {record?.stream_video_url ? (
                            <PlayCircleOutlined style={{ color: 'blue' }} />
                        ) : record?.yt_video_url ? (
                            <YoutubeFilled style={{ color: 'red' }} />
                        ) : null}
                    </span>
                </div>
            ),
        },
        {
            title:
                viewType === 'category'
                    ? 'Category Name'
                    : viewType === 'specialContent'
                    ? 'State'
                    : 'City',
            dataIndex:
                viewType === 'category'
                    ? 'snippet_category_name'
                    : viewType === 'specialContent'
                    ? 'state_name'
                    : 'city_name',
            key:
                viewType === 'category'
                    ? 'snippet_category_name'
                    : viewType === 'specialContent'
                    ? 'state_name'
                    : 'city_name',
            width: viewType === 'category' ? '22%' : '20%',
            align: 'center',
            render: (text, record) => (
                <div>
                    <div>{text}</div>
                    <div
                        style={{
                            color: 'gray',
                            fontSize: '0.8rem',
                            marginTop: '2px',
                        }}
                    >
                        {record.local_name}
                    </div>
                </div>
            ),
        },
        ...(viewType === 'category'
            ? [
                  {
                      title: 'Recurring Type',
                      key: 'recurring_type',
                      width: '24%',
                      align: 'center',
                      render: (_, record) => {
                          const category = categories.find(
                              (cat) =>
                                  cat.snippet_category_id ===
                                  record.snippet_category_id
                          )

                          if (!category) return 'No Recurring'

                          let type = 'No Recurring'
                          let timeInfo = ''

                          if (category.is_daily) {
                              type = 'Daily'
                              timeInfo = `${moment(category.start_time).format(
                                  'h:mm A'
                              )} - ${moment(category.end_time).format(
                                  'h:mm A'
                              )}`
                          } else if (category.is_weekly) {
                              type = 'Weekly'
                              timeInfo = `${
                                  WEEKDAY_OPTIONS.find(
                                      (day) =>
                                          day.value === category.day_of_week
                                  )?.label
                              }, ${moment(category.start_time).format(
                                  'h:mm A'
                              )} - ${moment(category.end_time).format(
                                  'h:mm A'
                              )}`
                          } else if (category.is_monthly) {
                              type = 'Monthly'
                              timeInfo = `Day ${
                                  category.day_of_month
                              }, ${moment(category.start_time).format(
                                  'h:mm A'
                              )} - ${moment(category.end_time).format(
                                  'h:mm A'
                              )}`
                          } else if (category.is_date_specific) {
                              type = 'Date Specific'
                              timeInfo = `${moment(category.start_time).format(
                                  'DD/MM/YYYY, h:mm A'
                              )} - ${moment(category.end_time).format(
                                  'h:mm A'
                              )}`
                          } else if (category.is_date_range) {
                              type = 'Date Range'
                              timeInfo = `${moment(
                                  category.start_date_range
                              ).format('DD/MM/YYYY')} - ${moment(
                                  category.end_date_range
                              ).format('DD/MM/YYYY')}`
                          }

                          return (
                              <div>
                                  <div>{type}</div>
                                  <div
                                      style={{
                                          fontSize: '0.8em',
                                          color: '#888',
                                      }}
                                  >
                                      {timeInfo}
                                  </div>
                              </div>
                          )
                      },
                  },
              ]
            : []),
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            width: '22%',
            render: (_, record) => (
                <div>
                    <EditOutlined
                        onClick={() => handleEdit(record)}
                        style={{ cursor: 'pointer' }}
                    />
                    <Popconfirm
                        title="Delete Snippet"
                        description="Are you sure to delete this snippet?"
                        onConfirm={() => handleDelete(record.content_id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <DeleteOutlined
                            style={{ paddingLeft: '5px', cursor: 'pointer' }}
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ]

    return (
        <div className="snippet_container">
            <h2 className="snippetsHeading">Manage Snippets</h2>
            <hr></hr>
            <div className="dropdownsAndBtn">
                <div>
                    <Select
                        className="snippets-dropdown1"
                        onChange={handleLanguageChange}
                        value={selectedLanguage}
                        placeholder="Select Language"
                    >
                        {languageData.map((arr) => (
                            <Option
                                key={arr.language_id}
                                value={arr.language_id}
                            >
                                {arr.language_name}
                            </Option>
                        ))}
                    </Select>
                    <Select
                        className="snippets-dropdown1"
                        onChange={handleViewTypeChange}
                        value={viewType}
                        placeholder="Select Sort Type"
                    >
                        <Option value="category">By Category</Option>
                        <Option value="state">By State</Option>
                        <Option value="specialContent">Special Contents</Option>
                    </Select>
                    {viewType === 'category' && (
                        <Select
                            className="snippets-dropdown1"
                            placeholder="Choose category"
                            value={selectedCategory}
                            onChange={handleCategoryChange}
                        >
                            {categories.map((category) => (
                                <Option
                                    key={category.snippet_category_id}
                                    value={category.snippet_category_id}
                                >
                                    {category.snippet_category_name}
                                </Option>
                            ))}
                        </Select>
                    )}
                    {viewType === 'state' && (
                        <>
                            <Select
                                className="snippets-dropdown1"
                                placeholder="Select State"
                                value={selectedState}
                                onChange={handleStateChange}
                            >
                                {availableStates.map((state) => (
                                    <Option
                                        key={state.state_id}
                                        value={state.state_id}
                                    >
                                        {state.name}
                                    </Option>
                                ))}
                            </Select>
                            <Select
                                className="snippets-dropdown1"
                                placeholder="Select city"
                                value={selectedCity}
                                onChange={handleCityChange}
                                disabled={!selectedState}
                            >
                                {cities?.map((city) => (
                                    <Select.Option
                                        key={city.city_id}
                                        value={city.city_id}
                                    >
                                        {city.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </>
                    )}
                    {viewType === 'specialContent' && (
                        <Select
                            className="snippets-dropdown1"
                            placeholder="Select State"
                            value={selectedState}
                            onChange={handleStateChange}
                        >
                            <Option value="all">--All--</Option>
                            {availableStates.map((state) => (
                                <Option
                                    key={state.state_id}
                                    value={state.state_id}
                                >
                                    {state.name}
                                </Option>
                            ))}
                        </Select>
                    )}
                </div>
                <div>
                    <Button
                        type="primary"
                        onClick={() =>
                            navigate('/home/addsnippet-form', {
                                state: {
                                    selectedLanguage: selectedLanguage,
                                    selectedCategory: selectedCategory,
                                    selectedState: selectedState,
                                    selectedViewType: viewType,
                                },
                            })
                        }
                        style={{ marginRight: '10px' }}
                    >
                        Add Snippet
                    </Button>
                    {/* <Button
                        type="primary"
                        className="viewCategoriesBtn"
                        onClick={() =>
                            navigate('/home/category-snippet', {
                                state: {
                                    selectedLanguage: selectedLanguage,
                                },
                            })
                        }
                    >
                        View Categories
                    </Button> */}
                </div>
            </div>
            <div className="table">
                <CommonTable
                    columns={columns}
                    data={tableData}
                    loading={loading}
                />
            </div>
            {isModalOpen && (
                <SnippetsPreviewCorousel
                    key={contentId}
                    contentId={contentId}
                    data={snippetsData}
                />
            )}
        </div>
    )
}

export default SnippetsMain

import React, { useState, useEffect } from 'react'
import {
    getReporterConfigs,
    updateReporterConfigs,
    getFaq,
    addFaq,
    editFaq,
    deleteFaq,
} from '../../AadhanApi'
import {
    Switch,
    message,
    Collapse,
    Button,
    Modal,
    Input,
    Select,
    Form,
    Row,
    Col,
    TimePicker,
} from 'antd'
import {
    EditOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons'
import { useSelector } from 'react-redux'
import './rconfig.scss'
import dayjs from 'dayjs'
const { Panel } = Collapse

//const format = "HH:mm";
const ReporterConfig = () => {
    const [language, setLanguage] = useState(2)
    const [form] = Form.useForm()
    const [publisherDesignations, setPublisherDesignations] = useState([
        { key: '0', value: '' },
        { key: '1', value: '' },
        { key: '2', value: '' },
        { key: '3', value: '' },
        { key: '4', value: '' },
        { key: '5', value: '' },
        { key: '6', value: '' },
        { key: '7', value: '' },
        { key: '8', value: '' },
        { key: '9', value: '' },
        { key: '10', value: '' },
    ])
    const [selectedDesignationKey, setSelectedDesignationKey] = useState('1')
    const [panels, setPanels] = useState([])
    const [showRestrictedTimings, setShowRestrictedTimings] = useState(false)
    const [maximumContents, setamaximumContents] = useState(false)
    const [Draft, setisDraft] = useState(false)
    const accessToken = useSelector((state) => state.app.accessToken)
    const languageData = useSelector((state) => state.app.languageData)

    const fetchReporterConfigs = async () => {
        if (!language) {
            message.error('Please select a language')
            return
        }

        try {
            const response = await getReporterConfigs(accessToken, language)
            if (response && response.publisher_designation) {
                const data = response
                const faqsData = data.reporter_faq

                if (faqsData && Array.isArray(faqsData)) {
                    setPanels(faqsData)
                } else {
                    setPanels([])
                }

                setShowRestrictedTimings(data.should_show_restricted_timings)
                setamaximumContents(
                    data.should_show_max_contents_per_hour_limit
                )

                setisDraft(data.is_draft_enabled)

                const publisherDesignation = response.publisher_designation

                let restrictedTimings = null
                if (data.restricted_timings) {
                    const [start, end] = data.restricted_timings.split('-')
                    restrictedTimings = [
                        start ? dayjs(start, 'HH:mm') : null,
                        end ? dayjs(end, 'HH:mm') : null,
                    ]
                }

                form.setFieldsValue({
                    restricted_timings: restrictedTimings,
                    restricted_timings_message: data.restricted_timings_message,
                    max_contents_message:
                        data.should_show_max_contents_per_hour_limit
                            ? data.max_contents_message
                            : '',
                    max_videos_limit: data.max_videos_limit,
                    max_contents: data.should_show_max_contents_per_hour_limit
                        ? data.max_contents
                        : '',
                    should_show_restricted_timings:
                        data.should_show_restricted_timings,
                    should_show_max_contents_per_hour_limit:
                        data.should_show_max_contents_per_hour_limit,
                    show_mandatory_reporter_update_alert:
                        data.show_mandatory_reporter_update_alert,
                    is_draft_enabled: data.is_draft_enabled,
                    reporter_draft_limit: data.reporter_draft_limit,
                    sponsored_image_price: data.sponsored_image_price,
                    sponsored_video_price: data.sponsored_video_price
                })

                // Populate publisher designations
                // Directly map publisher designations
                if (publisherDesignation) {
                    const updatedDesignations = publisherDesignations.map(
                        (designation) => ({
                            ...designation,
                            value: publisherDesignation[designation.key] || '',
                        })
                    )
                    setPublisherDesignations(updatedDesignations)
                }
            }
        } catch (error) {
            console.error('Error fetching reporter configs:', error)
            message.error('Failed to fetch configuration data')
        }
    }

    // const fetchQAPanels = async () => {
    //     try {
    //         const fetchedUsers = await getFaq(accessToken, language)

    //         if (fetchedUsers && Array.isArray(fetchedUsers)) {
    //             setPanels(fetchedUsers)
    //         } else {
    //             setPanels([])
    //         }
    //     } catch (error) {
    //         console.error('Failed to fetch Q&A items:', error)
    //     }
    // }
    useEffect(() => {
        fetchReporterConfigs()
    }, [language])

    const handleLanguageChange = (value) => {
        setLanguage(value)
        form.setFieldsValue({ language_id: value })
    }

    const handleDesignationKeyChange = (key) => {
        setSelectedDesignationKey(key)
    }

    const handleDesignationValueChange = (e) => {
        const newValue = e.target.value
        setPublisherDesignations((prevDesignations) =>
            prevDesignations.map((designation) =>
                designation.key === selectedDesignationKey
                    ? { ...designation, value: newValue }
                    : designation
            )
        )
    }

    const handleRestrictedTimingsToggle = (checked) => {
        setShowRestrictedTimings(checked)
        if (!checked) {
            form.setFieldsValue({
                restricted_timings: '',
                restricted_timings_message: '',
            })
        }
    }
    const handleContentMessageToggle = (checked) => {
        setamaximumContents(checked)

        if (!checked) {
            form.setFieldsValue({
                max_contents_message: '',
                max_contents: '',
            })
        }
    }

    const handleDraftToggle = (checked) => {
        setisDraft(checked)

        // Clear the draft limit when disabled
        if (!checked) {
            form.setFieldsValue({ reporter_draft_limit: '' })
        }
    }
    const handleSubmit = async (values) => {
        try {
            // Convert publisherDesignations to an object for the API
            const publisherDesignationObj = publisherDesignations.reduce(
                (acc, designation) => {
                    acc[designation.key] = designation.value
                    return acc
                },
                {}
            )

            const draftLimit =
                values.is_draft_enabled && values.reporter_draft_limit
                    ? values.reporter_draft_limit
                    : null

            const restrictedTimings =
                values.should_show_restricted_timings &&
                values.restricted_timings
                    ? `${values.restricted_timings[0]?.format('HH:mm') || ''}-${
                          values.restricted_timings[1]?.format('HH:mm') || ''
                      }`
                    : ''

            const restrictedTimingsMessage =
                values.should_show_restricted_timings &&
                values.restricted_timings_message &&
                values.restricted_timings
                    ? values.restricted_timings_message
                    : ''

            const maxContents =
                values.should_show_max_contents_per_hour_limit &&
                values.max_contents &&
                values.max_contents_message
                    ? values.max_contents
                    : null

            const maxContentMessage =
                values.should_show_max_contents_per_hour_limit &&
                values.max_contents &&
                values.max_contents_message
                    ? values.max_contents_message
                    : ''

            const requestData = {
                restricted_timings: restrictedTimings,
                max_contents_message: maxContentMessage,
                max_videos_limit: values.max_videos_limit,
                max_contents: maxContents,
                should_show_restricted_timings:
                    values.should_show_restricted_timings,
                should_show_max_contents_per_hour_limit:
                    values.should_show_max_contents_per_hour_limit,
                show_mandatory_reporter_update_alert:
                    values.show_mandatory_reporter_update_alert,

                language_id: language,
                publisher_designation: publisherDesignationObj,
                is_draft_enabled: values.is_draft_enabled,
                reporter_draft_limit: draftLimit,
                restricted_timings_message: restrictedTimingsMessage,
                sponsored_image_price: values.sponsored_image_price || null,
                sponsored_video_price: values.sponsored_video_price || null
            }

            await updateReporterConfigs(accessToken, requestData)
            message.success('Configuration updated successfully')
        } catch (error) {
            console.error('Error updating config:', error)
            message.error('Failed to update configuration')
        }
    }

    const handleAddPanel = () => {
        let newPanelData = {
            question: '',
            answer: '',
            language_id: language,
        }

        Modal.confirm({
            title: 'Add New Question & Answer',
            style: { minWidth: '800px' },
            className: 'wide-modal',
            content: (
                <div style={{ width: '100%' }}>
                    <div>
                        <Input
                            style={{
                                minWidth: '700px', // Force a minimum width
                                width: '100%',
                                padding: '10px',
                                margin: '10px',
                                display: 'block', // Ensures block-level display
                            }}
                            placeholder="Question"
                            onChange={(e) =>
                                (newPanelData.question = e.target.value.trim())
                            }
                        />
                    </div>
                    <Input.TextArea
                        placeholder="Answer"
                        rows={4}
                        onChange={(e) =>
                            (newPanelData.answer = e.target.value.trim())
                        }
                        autoSize={{ minRows: 4, maxRows: 10 }}
                        style={{
                            resize: 'vertical',
                            width: '100%',
                            wordWrap: 'break-word',
                            overflowWrap: 'break-word',
                        }}
                    />
                </div>
            ),
            async onOk() {
                if (
                    newPanelData.question &&
                    newPanelData.answer &&
                    newPanelData.language_id
                ) {
                    try {
                        const newUser = await addFaq(accessToken, {
                            language_id: newPanelData.language_id,
                            question: newPanelData.question,
                            answer: newPanelData.answer,
                        })

                        setPanels((prevPanels) => [
                            ...prevPanels,
                            { ...newUser?.new_faq, ...newPanelData },
                        ])
                        message.success('New Q&A added successfully.')
                    } catch (error) {
                        console.error(
                            'Failed to create Q&A:',
                            error.response?.data || error
                        )
                        message.error('Failed to add Q&A.')
                    }
                } else {
                    message.error(
                        'Please fill in both question and answer, and select a language.'
                    )
                    return false
                }
            },
        })
    }

    const handleEditPanel = (panel) => {
        if (!panel.faq_id) {
            message.error('Cannot edit: user_id is missing.')
            console.error('Panel data missing user_id:', panel)

            return
        }

        let editedPanelData = {
            original: { ...panel },
            updated: {
                question: panel.question,
                answer: panel.answer,
                language_id: language,
                faq_id: panel.faq_id, // Ensure user_id is included
            },
        }

        Modal.confirm({
            title: 'Edit Question & Answer',
            style: { minWidth: '800px' },
            className: 'wide-modal',
            content: (
                <div style={{ width: '100%' }}>
                    <Input
                        style={{
                            minWidth: '700px', // Force a minimum width
                            width: '100%',
                            padding: '10px',
                            margin: '10px',
                            display: 'block', // Ensures block-level display
                        }}
                        defaultValue={panel.question}
                        onChange={(e) => {
                            editedPanelData.updated.question =
                                e.target.value.trim()
                        }}
                        placeholder="Enter new question"
                    />
                    <Input.TextArea
                        defaultValue={panel.answer}
                        rows={4}
                        onChange={(e) => {
                            editedPanelData.updated.answer =
                                e.target.value.trim()
                        }}
                        placeholder="Enter new answer"
                    />
                </div>
            ),
            async onOk() {
                if (
                    !editedPanelData.updated.question ||
                    !editedPanelData.updated.answer
                ) {
                    message.error('Please fill in both question and answer.')
                    return false
                }

                try {
                    const updatedUser = await editFaq(
                        accessToken,
                        editedPanelData.updated.language_id,
                        editedPanelData.updated.faq_id,
                        editedPanelData.updated.question,
                        editedPanelData.updated.answer
                    )

                    setPanels((prevPanels) =>
                        prevPanels.map((p) =>
                            p.faq_id === updatedUser.faq_id ? updatedUser : p
                        )
                    )

                    message.success('Q&A updated successfully.')
                } catch (error) {
                    console.error(
                        'Error updating Q&A:',
                        error.response?.message || error
                    )
                    message.error(
                        error.response?.data?.message || 'Failed to update Q&A.'
                    )
                }
            },
        })
    }

    const handleDeletePanel = (panel) => {
        // Log the panel being deleted

        Modal.confirm({
            title: 'Are you sure you want to delete this question?',
            icon: <ExclamationCircleOutlined />,
            content: 'This action cannot be undone.',
            async onOk() {
                try {
                    // Call delete API with complete parameters
                    await deleteFaq(panel.faq_id, accessToken, {
                        language_id: panel.language_id || language,
                    })

                    // Explicit state update with logging
                    setPanels((prevPanels) => {
                        const updatedPanels = prevPanels.filter(
                            (p) => p.faq_id !== panel.faq_id
                        )

                        return updatedPanels
                    })

                    message.success('Q&A deleted successfully.')
                } catch (error) {
                    console.error(
                        'Failed to delete Q&A:',
                        error.response?.data || error
                    )
                    message.error('Failed to delete Q&A.')
                }
            },
        })
    }

    const renderPanelHeader = (panel) => (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <span style={{ marginBottom: '9px' }}>{panel.question}</span>
            <div>
                <Button
                    type="text"
                    icon={<EditOutlined />}
                    onClick={(e) => {
                        e.stopPropagation()
                        handleEditPanel(panel)
                    }}
                    style={{ marginRight: 8 }}
                />
                <Button
                    type="text"
                    icon={<DeleteOutlined />}
                    onClick={(e) => {
                        e.stopPropagation()
                        handleDeletePanel(panel)
                    }}
                    danger
                />
            </div>
        </div>
    )

    return (
        <div className="container" style={{ width: '100%' }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <h1 className="headline1">Reporter Configuration</h1>
                <div className="headline">
                    <Select
                        value={language}
                        onChange={handleLanguageChange}
                        placeholder="Select Language"
                        style={{ width: '160px' }}
                    >
                        {languageData?.map((option) => (
                            <Select.Option
                                key={option.language_id}
                                value={option.language_id}
                            >
                                {option.language_name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            </div>
            <hr></hr>
            <div style={{ marginTop: '30px' }}>
                <div>
                    <Form
                        form={form}
                        onFinish={handleSubmit}
                        layout="horizontal"
                    >
                        <Row gutter={16}>
                            {/* Left Column */}
                            <Col span={12}>
                                <Form.Item name="language_id" hidden>
                                    <Input type="hidden" />
                                </Form.Item>
                                <div>
                                    <Form.Item label="Publisher Designation">
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                            }}
                                        >
                                            <Select
                                                value={selectedDesignationKey}
                                                onChange={
                                                    handleDesignationKeyChange
                                                }
                                                style={{
                                                    width: '15%',
                                                    marginLeft: '3px',
                                                }}
                                            >
                                                {publisherDesignations.map(
                                                    (designation) => (
                                                        <Select.Option
                                                            key={
                                                                designation.key
                                                            }
                                                            value={
                                                                designation.key
                                                            }
                                                        >
                                                            {designation.key}
                                                        </Select.Option>
                                                    )
                                                )}
                                            </Select>
                                            <Input
                                                value={
                                                    publisherDesignations.find(
                                                        (designation) =>
                                                            designation.key ===
                                                            selectedDesignationKey
                                                    )?.value || ''
                                                }
                                                onChange={
                                                    handleDesignationValueChange
                                                }
                                                placeholder="Enter designation value"
                                                style={{
                                                    width: '37%',
                                                    marginLeft: '3px',
                                                }}
                                            />
                                        </div>
                                    </Form.Item>
                                </div>

                                <Form.Item
                                    name="max_videos_limit"
                                    label="Maximum Videos Limit"
                                >
                                    <Input
                                        style={{ marginLeft: '-2px' }}
                                        placeholder="Maximum videos Limit"
                                        className="input-width"
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="should_show_restricted_timings"
                                    label="Should Show Restricted Timings"
                                    valuePropName="checked"
                                >
                                    <Switch
                                        style={{ marginLeft: '-2px' }}
                                        onChange={handleRestrictedTimingsToggle}
                                    />
                                </Form.Item>

                                <div style={{ marginLeft: '-1px' }}>
                                    {showRestrictedTimings && (
                                        <>
                                            <Form.Item
                                                name="restricted_timings"
                                                label="Restricted Timings"
                                                required={false}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Please select a valid time range!',
                                                    },
                                                ]}
                                            >
                                                <TimePicker.RangePicker
                                                    format="HH:mm"
                                                    style={{
                                                        marginLeft: 6,
                                                        width: 200,
                                                    }}
                                                    placeholder={[
                                                        'Start Time',
                                                        'End Time',
                                                    ]}
                                                    allowClear
                                                    order={false}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="restricted_timings_message"
                                                label="Restricted Timings Message"
                                                required={false}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Please enter the restricted timings message',
                                                    },
                                                ]}
                                            >
                                                <Input.TextArea
                                                    placeholder="restricted timings message"
                                                    autoSize={{
                                                        minRows: 1,
                                                        maxRows: 6,
                                                    }} // Automatically adjust height
                                                    style={{
                                                        width: '200px',
                                                        resize: 'vertical', // Allows manual resizing if needed
                                                        minHeight: '10px', // Minimum height same as regular Input
                                                        wordWrap: 'break-word',
                                                        overflowWrap:
                                                            'break-word',
                                                        marginLeft: '-5px',
                                                    }}
                                                />
                                            </Form.Item>
                                        </>
                                    )}
                                </div>

                                <Form.Item
                                    name="is_draft_enabled"
                                    label="Is Draft Enable"
                                    valuePropName="checked"
                                >
                                    <Switch
                                        checked={Draft}
                                        onChange={handleDraftToggle}
                                    />
                                </Form.Item>

                                {Draft && (
                                    <Form.Item
                                        name="reporter_draft_limit"
                                        label="Reporter Draft Limit"
                                        required={false}
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please enter the reporter draft limit!',
                                            },
                                            {
                                                validator: (_, value) => {
                                                    if (!Draft)
                                                        return Promise.resolve()
                                                    if (!value)
                                                        return Promise.reject(
                                                            new Error('')
                                                        )
                                                    if (!/^\d+$/.test(value))
                                                        return Promise.reject(
                                                            new Error(
                                                                'Please enter only numbers'
                                                            )
                                                        )
                                                    const numValue = parseInt(
                                                        value,
                                                        10
                                                    )
                                                    if (numValue <= 0)
                                                        return Promise.reject(
                                                            new Error(
                                                                'Please enter a number greater than 0'
                                                            )
                                                        )
                                                    return Promise.resolve()
                                                },
                                            },
                                        ]}
                                    >
                                        <Input
                                            style={{ marginLeft: '-2px' }}
                                            placeholder="Reporter Draft Limit"
                                             className="input-width"
                                        />
                                    </Form.Item>
                                )}

                                <Form.Item
                                    name="show_mandatory_reporter_update_alert"
                                    label="Update Alert For Mandate Reporters"
                                    valuePropName="checked"
                                >
                                    <Switch />
                                </Form.Item>

                                <Form.Item
        name="sponsored_image_price"
        label="Sponsored image price"
        rules={[{ required: false }]} // Optional field
      >
        <Input
          style={{ marginLeft: '-4px' }}
          placeholder="Enter image price"
           className="input-width"
        />
      </Form.Item>

      <Form.Item
        name="sponsored_video_price"
        label="Sponsored video price"
        rules={[{ required: false }]} // Optional field
      >
        <Input
          style={{ marginLeft: '-1px' }}
          placeholder="Enter video price"
           className="input-width"
        />
      </Form.Item>

                            </Col>

                            <Col span={12}>
                                <h1 style={{ fontSize: '25px' }}>
                                    Maximum Contents
                                </h1>
                                <hr style={{ width: '235px' }}></hr>

                                <Form.Item
                                    name="should_show_max_contents_per_hour_limit"
                                    label="Ensure a maximum content limit is displayed per hour"
                                    valuePropName="checked"
                                >
                                    <Switch
                                        onChange={handleContentMessageToggle}
                                    />
                                </Form.Item>

                                {maximumContents && (
                                    <>
                                        <Form.Item
                                            name="max_contents_message"
                                            label="Maximum Contents Message"
                                            required={false}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please enter the message',
                                                },
                                            ]}
                                        >
                                            <Input.TextArea
                                                placeholder="Maximum Contents Message"
                                                autoSize={{
                                                    minRows: 1,
                                                    maxRows: 6,
                                                }}
                                                style={{
                                                    width: '204px',
                                                    resize: 'vertical',
                                                    minHeight: '10px',
                                                    wordWrap: 'break-word',
                                                    overflowWrap: 'break-word',
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="max_contents"
                                            label="Maximum Content Per Hour"
                                            required={false}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please enter the maximum content count',
                                                },
                                                {
                                                    validator: (_, value) => {
                                                        if (
                                                            !value ||
                                                            (/^\d+$/.test(
                                                                value
                                                            ) &&
                                                                parseInt(
                                                                    value,
                                                                    10
                                                                ) >= 0 &&
                                                                parseInt(
                                                                    value,
                                                                    10
                                                                ) <= 10)
                                                        ) {
                                                            return Promise.resolve()
                                                        }
                                                        return Promise.reject(
                                                            new Error(
                                                                'Please enter a number between 0 and 10'
                                                            )
                                                        )
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                style={{ marginLeft: '10px' }}
                                                placeholder="Maximum Content"
                                                 className="input-width"
                                            />
                                        </Form.Item>
                                    </>
                                )}
                            </Col>
                        </Row>

                        <Row justify="center">
                            <Row>
                                <Col>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            style={{
                                                width: '120px',
                                                marginTop: '15px',
                                                marginRight: '168px',
                                            }}
                                        >
                                            Update
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Row>
                    </Form>
                </div>
                <h1 className="reporter">Reporter Panel</h1>
                <hr />
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                        type="primary"
                        onClick={handleAddPanel}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            width: '145px',
                        }}
                    >
                        Add Question
                    </Button>
                </div>
            </div>

            <div
                style={{
                    width: '100%',
                    marginTop: '.5rem',
                    display: 'flex',
                    justifyContent: 'Start',
                }}
            >
                <div
                    style={{
                        whiteSpace: 'pre-wrap', // Ensures line breaks are displayed
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                        width: '100%',
                    }}
                >
                    <Collapse>
                        {panels.map((panel) => (
                            <Panel
                                key={panel.faq_id}
                                header={renderPanelHeader(panel)}
                            >
                                {panel.answer}
                            </Panel>
                        ))}
                    </Collapse>
                </div>
            </div>
        </div>
    )
}

export default ReporterConfig

import React, { useEffect, useRef, useState } from 'react'
import {
    Modal,
    Form,
    Input,
    Button,
    Select,
    Space,
    Upload,
    message,
    Spin,
    Image,
    Card,
    Tooltip,
} from 'antd'
import {
    CheckCircleFilled,
    CloseCircleFilled,
    EditOutlined,
    MinusOutlined,
    PlusOutlined,
    RightOutlined,
} from '@ant-design/icons'
import { getImageUrl, getVideoUrl, updatePodcast } from '../../../AadhanApi'
import { Audio } from '../Audio'
import { duration } from 'moment/moment'
const { Option } = Select
const CreateEpisode = ({
    languageData,
    ui,
    language,
    accessToken,
    mode,
    record,
    data,
    getdata,
}) => {
    const [form, setForm] = useState({})
    const [tempForm] = Form.useForm()
    const [error, setError] = useState({
        form1: false,
        form2: false,
        form3: false,
        form4: false,
    })
    const [currentForm, setCurrentForm] = useState(null)
    const slidingDivRef = useRef(null)
    const [duration,setDuration] = useState()
    const [validate, setValidate] = useState([])
    const [visible, setVisible] = useState(false)
    const [imageUrl, setImageUrl] = useState(null)
    const [AudioUrl, setAudioUrl] = useState(null)
    const [uploading, setUploading] = useState(false)
    const employeeId = localStorage.getItem('EMPLOYEE_ID')
    useEffect(() => {
        if (mode === 'edit') {
            const cast = record?.cast?.names?.split(',').map((name, index) => ({
                name,
                role: record?.cast?.roles?.split(',')[index],
            }))

            tempForm.setFieldsValue(record)
            tempForm.setFieldValue('cast', cast)
            if (!record?.cast?.names) {
                tempForm.setFieldValue('cast', [])
            }
            tempForm.setFieldValue('image_url', record?.image_url)
            tempForm.setFieldValue('audio_url', record?.audio_url)
            setImageUrl(record?.image_url)
            setAudioUrl(record?.audio_url)
            setDuration(record?.duration)
            setValidate(['form1', 'form2', 'form3'])
            setForm(record)
        } else {
            setValidate([])
        }
        tempForm.setFieldValue('language_id', language)
    }, [visible])
    const handleSubmit = async (values, type) => {
        if (!values?.title || !values?.description) {
            setError({ form1: true, form2: false })
            return
        } else if (!imageUrl && type === 'approved') {
            setError({ form1: false, form2: true })
            return
        }
        if (!AudioUrl && type === 'approved') {
            setError({ form1: false, form2: false, form3: true })
            return
        }
        const formData = { ...values, image_url: imageUrl, audio_url: AudioUrl,duration:duration }
        const result = {
            names: values?.cast?.map((item) => item.name).join(','),
            roles: values?.cast?.map((item) => item.role).join(','),
        }
        formData['cast'] = result
        formData['content_status'] = type === 'draft' ? 'draft' : 'approved'
        formData['uploaded_by'] = employeeId
        try {
            if (mode === 'edit') {
                formData['updated_by'] = employeeId
                const temp = data?.episodes?.map((e) => {
                    if (record?.sequence === e?.sequence) {
                        return { ...formData, uploaded_by: e?.uploaded_by }
                    } else {
                        return e
                    }
                })
                data['episodes'] = temp
                const res = await updatePodcast(
                    accessToken,
                    data,
                    data?.podcast_id
                )
                if (res?.status === 200) {
                    message.success('Episode updated successfully!')
                }
            } else {
                data['episodes'] = [...data?.episodes, formData]
                const res = await updatePodcast(
                    accessToken,
                    data,
                    data?.podcast_id
                )
                if (res?.status === 200) {
                    message.success('Episode added successfully!')
                }
            }
            tempForm.resetFields()
            setForm({})
            getdata()
            setAudioUrl()
            setImageUrl()

            handleClose()
        } catch (error) {
            message.error('Failed to create or update podcast!')
        }
    }
    function getVideoDuration(file) {
        return new Promise((resolve, reject) => {
            const video = document.createElement('audio')
            video.preload = 'metadata' // Load only metadata, not the entire video

            video.onloadedmetadata = () => {
                resolve(video.duration) // Duration in seconds
            }

            video.onerror = (error) => {
                reject('Error loading audio')
            }

            const fileURL = URL.createObjectURL(file)
            video.src = fileURL
        })
    }

    function formatDuration(seconds) {
        const hours = Math.floor(seconds / 3600)
        const minutes = Math.floor((seconds % 3600) / 60)
        const remainingSeconds = Math.floor(seconds % 60)
        const formattedTime =
            hours > 0
                ? `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
                      2,
                      '0'
                  )}:${String(remainingSeconds).padStart(2, '0')}`
                : `${String(minutes).padStart(2, '0')}:${String(
                      remainingSeconds
                  ).padStart(2, '0')}`

        return formattedTime
    }
    const handleUpload = async (file) => {
        setUploading(true)

        try {
            const pathUri = await getImageUrl(
                file,
                'devotions/podcasts',
                accessToken,
                0
            )
            const imageUrlFromResponse = pathUri.data
            tempForm.setFieldValue('image_url', imageUrlFromResponse)
            setImageUrl(imageUrlFromResponse)
            message.success('Image uploaded successfully!')
        } catch (error) {
            message.error('Image upload failed!')
        } finally {
            setUploading(false)
        }

        return false
    }
    const handleUploadAudio = async (file) => {
        console.log(file)
        setUploading(true)

        try {
            const pathUri = await getVideoUrl(file,true, accessToken)
            const imageUrlFromResponse = pathUri.data
            const du = await getVideoDuration(file)
            setTimeout(() => {
                setDuration(formatDuration(du))
                setAudioUrl(imageUrlFromResponse)
                tempForm.setFieldValue('audio_url', imageUrlFromResponse)
                setUploading(false)
            }, 5000);
            message.success('Audio uploaded successfully!')
        } catch (error) {
            setUploading(false)
            message.error('Audio upload failed!')
        } finally {
        }

        return false
    }
    const showModal = () => {
        setVisible(true)
    }
    const handleClose = () => {
        setVisible(false)
    }
    const handleAddForm = (formKey) => {
        setError({ form1: false, form2: false })
        tempForm.setFieldsValue(form)

        setCurrentForm(formKey)
    }
    const handleSaveForm = (id, value) => {
        setForm(value)
        setCurrentForm(null)
    }

    const handleOutsideClick = (e) => {
        const temp = [
            'Tamil',
            'Telugu',
            'Hindi',
            'English',
            'play audio',
            'upload audio',
            'Yes',
            'No',
        ]
        if (
            slidingDivRef.current &&
            !temp?.includes(e.target.innerText) &&
            e?.target?.className !==
                'ant-select-dropdown css-dev-only-do-not-override-98ntnt ant-select-dropdown-placement-bottomLeft' &&
            e.target.className !== 'ant-modal-wrap' &&
            e.target.tagName !== 'svg' &&
            e.target.tagName !== 'path' &&
            !slidingDivRef.current.contains(e.target)
        ) {
            setCurrentForm(null)
        }
    }

    useEffect(() => {
        if (
            !validate?.includes(currentForm?.key) &&
            currentForm !== undefined
        ) {
            setValidate([...validate, currentForm?.key])
        }

        if (currentForm) {
            document.addEventListener('mousedown', handleOutsideClick)
        } else {
            document.removeEventListener('mousedown', handleOutsideClick)
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [currentForm])

    return (
        <>
            <Button
                icon={
                    mode === 'edit' ? (
                        <EditOutlined />
                    ) : ui ? (
                        <PlusOutlined style={{ fontSize: 10 }} />
                    ) : (
                        ''
                    )
                }
                type="primary"
                style={
                    mode === 'edit' || ui
                        ? {
                              backgroundColor: ui ? 'green' : '#0056b3',
                              borderColor: ui ? '' : '#0056b3',
                              color: '#fff',
                              height: ui ? 20 : '',
                              width: ui ? 20 : '',
                              borderRadius: ui ? '50%' : '20px',
                              padding: ui ? '' : '0 20px',
                              fontWeight: 'bold',
                              boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
                          }
                        : {}
                }
                onClick={showModal}
            >
                {mode === 'edit' || ui ? '' : 'Create Episode'}
            </Button>
            <Modal
                title="Create Episode"
                open={visible}
                onCancel={() =>
                    currentForm ? setCurrentForm(null) : handleClose()
                }
                footer={[
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 20,
                        }}
                    >
                        <Button
                            type="primary"
                            style={{ width: 100 }}
                            danger
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={() =>
                                handleSubmit(tempForm.getFieldValue(), 'draft')
                            }
                            disabled={record?.content_status === 'approved'}
                            style={{
                                width: 100,
                                backgroundColor:
                                    record?.content_status === 'approved'
                                        ? 'lightgray'
                                        : 'green',
                            }}
                        >
                            Save
                        </Button>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ backgroundColor: 'blue', width: 100 }}
                            onClick={() =>
                                handleSubmit(
                                    tempForm.getFieldValue(),
                                    'approved'
                                )
                            }
                        >
                            Publish
                        </Button>
                    </div>,
                ]}
                width={770}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0px',
                        paddingBottom: 100,
                        transition: 'transform .3s ease-in-out',
                    }}
                >
                    {[
                        {
                            label: 'Basic Info',
                            icon: (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    color="gray"
                                    width={40}
                                    height={40}
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    class="size-6"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            ),
                            key: 'form1',
                            desc: 'Title, Category',
                            data:
                                form?.title && form?.description ? true : false,
                        },

                        {
                            label: 'Artwork',
                            icon: (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={40}
                                    height={40}
                                    color={'#faad14'}
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    class="size-6"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M1.5 6a2.25 2.25 0 0 1 2.25-2.25h16.5A2.25 2.25 0 0 1 22.5 6v12a2.25 2.25 0 0 1-2.25 2.25H3.75A2.25 2.25 0 0 1 1.5 18V6ZM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0 0 21 18v-1.94l-2.69-2.689a1.5 1.5 0 0 0-2.12 0l-.88.879.97.97a.75.75 0 1 1-1.06 1.06l-5.16-5.159a1.5 1.5 0 0 0-2.12 0L3 16.061Zm10.125-7.81a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            ),
                            key: 'form2',
                            desc: 'Image',
                            data: imageUrl ? true : false,
                        },
                        {
                            label: 'Playable Assets',
                            icon: (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={40}
                                    height={40}
                                    color={'#ff4d4f'}
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    class="size-6"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M19.952 1.651a.75.75 0 0 1 .298.599V16.303a3 3 0 0 1-2.176 2.884l-1.32.377a2.553 2.553 0 1 1-1.403-4.909l2.311-.66a1.5 1.5 0 0 0 1.088-1.442V6.994l-9 2.572v9.737a3 3 0 0 1-2.176 2.884l-1.32.377a2.553 2.553 0 1 1-1.402-4.909l2.31-.66a1.5 1.5 0 0 0 1.088-1.442V5.25a.75.75 0 0 1 .544-.721l10.5-3a.75.75 0 0 1 .658.122Z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            ),
                            key: 'form3',
                            desc: 'Audio, Lyrics',
                            data: AudioUrl ? true : false,
                        },
                    ].map((e) => (
                        <div
                            className="info-tabs"
                            key={e.key}
                            style={{
                                marginBottom: 0,
                                alignItems: 'center',
                                padding: '20px 10px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                borderBottom: 'solid 1px lightgray',
                                background:
                                    currentForm?.key === e?.key
                                        ? '#40A9FF'
                                        : 'transparent',
                                transition: 'background 0.3s ease-in-out',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    gap: 10,
                                    alignItems: 'center',
                                }}
                            >
                                <div>{e.icon}</div>
                                <div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div
                                            style={{
                                                color:
                                                    currentForm?.key === e?.key
                                                        ? 'white'
                                                        : 'black',
                                                fontWeight: 600,
                                                fontSize: 16,
                                            }}
                                        >
                                            {e.label}
                                        </div>
                                        <div>
                                            {validate?.includes(e?.key) && (
                                                <span>
                                                    {' '}
                                                    {e?.data ? (
                                                        <CheckCircleFilled
                                                            style={{
                                                                color: 'green',
                                                                fontSize: 20,
                                                                marginLeft:
                                                                    '.3rem',
                                                            }}
                                                        />
                                                    ) : (
                                                        <CloseCircleFilled
                                                            style={{
                                                                color: 'red',
                                                                fontSize: 20,
                                                                marginLeft:
                                                                    '.3rem',
                                                            }}
                                                        />
                                                    )}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div
                                        style={{
                                            color:
                                                currentForm?.key === e?.key
                                                    ? 'white'
                                                    : 'gray',
                                            fontSize: 14,
                                        }}
                                    >
                                        {e.desc}
                                    </div>
                                </div>
                            </div>
                            {error[e.key] ? (
                                <Tooltip title="Some fields are incomplete. Please fill them out to proceed with publishing.">
                                    <Button
                                        className="animated-button"
                                        type="danger"
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontWeight: 500,
                                            fontSize: 14,
                                            padding: '5px 15px',
                                            borderRadius: '4px',
                                            boxShadow:
                                                '0px 2px 5px rgba(0,0,0,0.1)',
                                            backgroundColor: '#ff4d4f',
                                            color: '#fff',
                                        }}
                                        iconPosition="end"
                                        icon={<RightOutlined />}
                                        onClick={() => handleAddForm(e)}
                                    >
                                        {e?.data ? 'View' : 'Add'}
                                    </Button>
                                </Tooltip>
                            ) : (
                                <Button
                                    type="primary"
                                    ghost
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontWeight: 500,
                                        fontSize: 14,
                                        padding: '5px 15px',
                                        borderRadius: '4px',
                                        boxShadow:
                                            '0px 2px 5px rgba(0,0,0,0.1)',
                                    }}
                                    iconPosition="end"
                                    icon={<RightOutlined />}
                                    onClick={() => handleAddForm(e)}
                                >
                                    {e?.data ? 'View' : 'Add'}
                                </Button>
                            )}
                        </div>
                    ))}
                </div>

                {currentForm?.key && (
                    <div
                        ref={slidingDivRef}
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            width: '75%',
                            height: '100%',
                            backgroundColor: '#fff',
                            boxShadow: '-2px 0 5px rgba(0,0,0,0.2)',
                            padding: '16px',
                            zIndex: 1000,
                            // overflowY: 'auto',
                            transition: 'transform 0.3s ease-in-out',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                gap: 10,
                                alignItems: 'center',
                                paddingBottom: 10,
                                marginBottom: 10,
                                borderBottom: 'solid 1px lightgray',
                            }}
                        >
                            <div>{currentForm.icon}</div>
                            <div>
                                <div
                                    style={{
                                        color: 'black',
                                        fontWeight: 500,
                                        fontSize: 15,
                                    }}
                                >
                                    {currentForm.label}
                                </div>
                            </div>
                        </div>
                        {currentForm?.key === 'form1' ? (
                            <Form
                                style={{ height: 390, overflowY: 'auto' }}
                                onFinish={(values) => handleSaveForm(1, values)}
                                form={tempForm}
                                layout="vertical"
                            >
                                <Form.Item
                                    name="language_id"
                                    label="Language"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please select a language!',
                                        },
                                    ]}
                                >
                                    <Select placeholder="Select a language">
                                        {languageData?.map((e) => (
                                            <Option
                                                key={e?.language_id}
                                                value={e?.language_id}
                                            >
                                                {e?.language_name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="title"
                                    label="Title"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the title!',
                                        },
                                    ]}
                                >
                                    <Input
                                        style={{ minWidth: '100%' }}
                                        placeholder="Enter Title"
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="description"
                                    label="Description"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Please enter the description!',
                                        },
                                    ]}
                                >
                                    <Input.TextArea
                                        placeholder="Enter Description"
                                        rows={2}
                                    />
                                </Form.Item>
                                <Card title="Cast List" bordered={false}>
                                    <Form.List name="cast" initialValue={[]}>
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map(
                                                    (
                                                        {
                                                            key,
                                                            name,
                                                            ...restField
                                                        },
                                                        index
                                                    ) => (
                                                        <Space
                                                            key={key}
                                                            style={{
                                                                display: 'flex',
                                                                marginBottom: 8,
                                                            }}
                                                            align="baseline"
                                                        >
                                                            <Form.Item
                                                                {...restField}
                                                                name={[
                                                                    name,
                                                                    'name',
                                                                ]}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message:
                                                                            'Please provide artist name!',
                                                                    },
                                                                ]}
                                                            >
                                                                <Input
                                                                    placeholder="Artist Name"
                                                                    style={{
                                                                        width: '220px',
                                                                    }}
                                                                />
                                                            </Form.Item>
                                                            <Form.Item
                                                                {...restField}
                                                                name={[
                                                                    name,
                                                                    'role',
                                                                ]}
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message:
                                                                            'Please provide cast role!',
                                                                    },
                                                                ]}
                                                            >
                                                                <Input
                                                                    placeholder="Role"
                                                                    style={{
                                                                        width: '220px',
                                                                    }}
                                                                />
                                                            </Form.Item>
                                                            <Button
                                                                type="dashed"
                                                                icon={
                                                                    <MinusOutlined />
                                                                }
                                                                onClick={() =>
                                                                    remove(name)
                                                                }
                                                            />
                                                            {index ===
                                                                fields.length -
                                                                    1 && (
                                                                <Button
                                                                    type="dashed"
                                                                    icon={
                                                                        <PlusOutlined />
                                                                    }
                                                                    onClick={() =>
                                                                        add()
                                                                    }
                                                                />
                                                            )}
                                                        </Space>
                                                    )
                                                )}
                                                <Form.Item
                                                    style={{
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    {fields.length === 0 && (
                                                        <Button
                                                            type="dashed"
                                                            onClick={() =>
                                                                add()
                                                            }
                                                            icon={
                                                                <PlusOutlined />
                                                            }
                                                        >
                                                            Add Casting
                                                        </Button>
                                                    )}
                                                </Form.Item>
                                            </>
                                        )}
                                    </Form.List>
                                </Card>

                                <Space
                                    style={{
                                        margin: 'auto',
                                        justifyContent: 'center',
                                        gap: '20px',
                                    }}
                                >
                                    <Button
                                        type="primary"
                                        onClick={() => setCurrentForm(null)}
                                        danger
                                        style={{
                                            padding: '8px 16px',
                                            borderRadius: '4px',
                                            fontSize: '14px',
                                            width: 120,
                                        }}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        style={{
                                            width: 120,
                                            padding: '8px 16px',
                                            borderRadius: '4px',
                                            fontSize: '14px',
                                            border: 'none',
                                        }}
                                    >
                                        Save
                                    </Button>
                                </Space>
                            </Form>
                        ) : currentForm?.key === 'form2' ? (
                            <Form form={tempForm}>
                                <Card title="Artworks" bordered={false}>
                                    <Form.Item
                                        label=""
                                        required
                                        name="image"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please upload an image!',
                                            },
                                        ]}
                                    >
                                        <Upload
                                            accept="image/*"
                                            showUploadList={false}
                                            beforeUpload={handleUpload}
                                            disabled={uploading}
                                        >
                                            {uploading ? (
                                                <Spin />
                                            ) : (
                                                <Button type="primary">
                                                    Upload Image
                                                </Button>
                                            )}
                                        </Upload>
                                    </Form.Item>
                                    {imageUrl && (
                                        <Image
                                            src={imageUrl}
                                            style={{
                                                width: 120,
                                                height: 120,
                                                marginBottom: 10,
                                            }}
                                        />
                                    )}
                                </Card>
                                <Space
                                    style={{
                                        margin: 'auto',
                                        justifyContent: 'center', // Centers the buttons horizontally
                                        gap: '20px', // Adds space between the buttons
                                    }}
                                >
                                    <Button
                                        type="primary"
                                        onClick={() => setCurrentForm(null)}
                                        danger
                                        style={{
                                            padding: '8px 16px',
                                            borderRadius: '4px',
                                            fontSize: '14px',
                                            width: 120,
                                        }}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        onClick={() =>
                                            handleSaveForm(
                                                2,
                                                tempForm.getFieldValue()
                                            )
                                        }
                                        style={{
                                            width: 120,
                                            padding: '8px 16px',
                                            borderRadius: '4px',
                                            fontSize: '14px',
                                            border: 'none',
                                        }}
                                    >
                                        Save
                                    </Button>
                                </Space>
                            </Form>
                        ) : currentForm?.key === 'form3' ? (
                            <Form
                                style={{ height: '100%' }}
                                layout="vertical"
                                form={tempForm}
                            >
                                <Form.Item
                                    label="Audio Upload"
                                    required
                                    name="audio_url"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please upload audio!',
                                        },
                                    ]}
                                >
                                    <Upload
                                        accept="audio/*"
                                        showUploadList={false}
                                        beforeUpload={handleUploadAudio}
                                        disabled={uploading}
                                    >
                                        {uploading ? (
                                            <Spin size='large' />
                                        ) : (
                                            <Button type="primary">
                                                Upload Audio
                                            </Button>
                                        )}
                                    </Upload>
                                </Form.Item>
                                {AudioUrl && !uploading && (
                                   <Audio url={AudioUrl.replace('/audio.m4a','.m3u8')} />
                                )}
                                <Space
                                    style={{
                                        marginTop: 200,
                                        justifyContent: 'center', // Centers the buttons horizontally
                                        gap: '20px', // Adds space between the buttons
                                    }}
                                >
                                    <Button
                                        type="primary"
                                        onClick={() => setCurrentForm(null)}
                                        danger
                                        style={{
                                            padding: '8px 16px',
                                            borderRadius: '4px',
                                            fontSize: '14px',
                                            width: 120,
                                        }}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        onClick={() =>
                                            handleSaveForm(
                                                2,
                                                tempForm.getFieldValue()
                                            )
                                        }
                                        style={{
                                            width: 120,
                                            padding: '8px 16px',
                                            borderRadius: '4px',
                                            fontSize: '14px',
                                            border: 'none',
                                        }}
                                    >
                                        Save
                                    </Button>
                                </Space>
                            </Form>
                        ) : (
                            ''
                        )}
                    </div>
                )}
            </Modal>
        </>
    )
}

export default CreateEpisode

import React, { useState } from 'react'
import {
    Button,
    Card,
    Table,
    Select,
    DatePicker,
    Row,
    Col,
    Divider,
    Typography,
    Progress,
} from 'antd'
import {
    CheckCircleFilled,
    SendOutlined,
} from '@ant-design/icons'
import "./NotificationDashboard.scss"

const { RangePicker } = DatePicker
const { Option } = Select
const { Title } = Typography

const NotificationDashboard = () => {
    const [language, setLanguage] = useState('English')
    const [dateRange, setDateRange] = useState(null)

    const audienceData = [
        
    ]

    const metrics = {
        notificationsSent: 400,
        notificationsDelivered: 350,
        openRate: 75,
        deliveryRate: 87.5,
    }

    const handleSendNotification = () => {
        console.log('Notification Sent!')
    }

    const handleLanguageChange = (value) => {
        setLanguage(value)
    }

    const handleDateChange = (dates) => {
        setDateRange(dates)
    }

    const columns = [
        {
            title: 'Content Type',
            dataIndex: 'audienceType',
            key: 'audienceType',
        },
        {
            title: 'Score',
            dataIndex: 'Score',
            key: 'Score',
        },
    ]

    const conicColors = {
        '0%': '#87d068',
        '50%': '#ffe58f',
        '100%': '#ffccc7',
    }

    return (
        <div style={{ marginTop: '-2%' }}>
            <Card
                bordered={false}
                style={{
                    borderRadius: 0,
                    backgroundColor: '#ffffff',
                }}
            >
                {/* Header Row */}
                <Row
                    style={{
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: 10,
                    }}
                >
                    <Col>
                        <Title level={3} style={{ margin: 0 }}>
                            Notification Dashboard
                        </Title>
                       
                    </Col>
                    
                </Row>

                {/* Filters Row */}
                <Row
                    style={{
                        justifyContent: 'flex-end',
                        gap: '20px',
                        marginBottom: '10px',
                    }}
                >
                    <Col style={{marginRight:"auto"}}>
                    <Button
                            type="primary"
                            icon={<SendOutlined />}
                            onClick={handleSendNotification}
                            style={{
                                backgroundColor: '#1890ff',
                                borderColor: '#1890ff',
                                borderRadius: 8,
                                
                            }}
                        >
                            Send Notification
                        </Button>
                    </Col>
                    <Col>
                        <Select
                            defaultValue={language}
                            style={{ width: 200 }}
                            onChange={handleLanguageChange}
                            placeholder="Select Language"
                        >
                            <Option value="English">English</Option>
                            <Option value="Hindi">Hindi</Option>
                            <Option value="Tamil">Tamil</Option>
                            <Option value="Telugu">Telugu</Option>
                        </Select>
                    </Col>
                    <Col>
                        <RangePicker
                            onChange={handleDateChange}
                            style={{ width: 250 }}
                            placeholder={['Start Date', 'End Date']}
                        />
                    </Col>
                </Row>

                <Divider style={{ margin: '12px 0' }} />

                {/* Metrics */}
                <div
                    style={{
                        display: 'flex',
                        gap: '20px',
                        marginBottom: '10px',
                        width: '100%',
                    }}
                >
                    <div
                        style={{
                            textAlign: 'center',
                            width: 'calc(25% - 15px)', // Equal width for all items (adjust for gap)
                            gap:25,
                            padding:"20px 0",
                            borderRadius: '8px',
                            backgroundColor: '#fff',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'start',
                            alignItems: 'center',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            
                            }}
                        >
                            {/* Displaying the notification count */}
                            <span
                                style={{
                                    fontSize: '24px',
                                    fontWeight: 'bold',
                                    color: '#4a90e2',
                                }}
                            >
                                {metrics?.notificationsSent}
                            </span>
                        </div>
                        <Typography.Text strong style={{ fontSize: 16, color: '#333' }}>
                            Notifications Sent
                        </Typography.Text>

                        
                    </div>

                    <div
                        style={{
                            textAlign: 'center',
                            width: 'calc(25% - 15px)', // Equal width for all items (adjust for gap)
                            gap:25,
                            padding:"20px 0",
                            borderRadius: '8px',
                            backgroundColor: '#fff',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'start',
                            alignItems: 'center',
                        }}
                    >
                       
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            
                            }}
                        >
                            
                            <span
                                style={{
                                    fontSize: '24px',
                                    fontWeight: 'bold',
                                    color: '#52c41a',
                                }}
                            >
                                {metrics?.notificationsDelivered}
                            </span>
                        </div>
                        <Typography.Text strong style={{ fontSize: 16, color: '#333' }}>
                            Notifications Delivered
                        </Typography.Text>
                    </div>

                    <div
        style={{
             textAlign: 'center',
                            width: 'calc(25% - 15px)', // Equal width for all items (adjust for gap)
                            gap:25,
                            padding:"20px 0",
                            borderRadius: '8px',
                            backgroundColor: '#fff',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'start',
                            alignItems: 'center',
        }}
    >
        
        {/* Displaying open rate percentage */}
        <span
            style={{
                fontSize: '24px',
                fontWeight: 'bold',
                color: '#ff6f61',
            }}
        >
            {metrics?.openRate}%
        </span>
        <Typography.Text strong style={{ fontSize: 16, color: '#333' }}>
            Open Rate
        </Typography.Text>
    </div>

    {/* Delivery Rate */}
    <div
        style={{
            textAlign: 'center',
                            width: 'calc(25% - 15px)', // Equal width for all items (adjust for gap)
                            gap:25,
                            padding:"20px 0",
                            borderRadius: '8px',
                            backgroundColor: '#fff',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'start',
                            alignItems: 'center',
        }}
    >
        <span
            style={{
                fontSize: '24px',
                fontWeight: 'bold',
                color: '#73d13d',
            }}
        >
            {metrics?.deliveryRate}%
        </span>
        <Typography.Text strong style={{ fontSize: 16, color: '#333' }}>
            Delivery Rate
        </Typography.Text>
    </div>
                </div>

                <Divider style={{ margin: '12px 0' }} />
                {/* Audience Data Table */}
                {/* <Table
                    dataSource={audienceData}
                    columns={columns}
                    pagination={false}
                    bordered
                    title={() => (
                        <Typography.Title level={4} style={{ marginBottom: 0 }}>
                            Audience Data
                        </Typography.Title>
                    )}
                /> */}
                 <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent:"flex-start",
        gap:"2%"
      }}
    >
      {[audienceData,audienceData,audienceData,audienceData].map((data, index) => (

        <div
          key={index}
          style={{
            width: '32%', // Fixed width of each table
            backgroundColor: '#fff',
            borderRadius: '8px',
            boxShadow: '0 2px 2px rgba(0, 0, 0, 0.1)',
            padding: '5px',
            marginBottom:"2%"

          }}
        >
          <Typography.Text strong style={{ fontSize: 16, color: '#333',paddingLeft:5, }}>
            Audience Type
        </Typography.Text>  
          <Table
            columns={columns}
            dataSource={data} // Assuming each table has its own data array
            pagination={false} // Disable pagination for better layout
            bordered
          />
        </div>
      ))}
    </div>
            </Card>
        </div>
    )
}

export default NotificationDashboard

export const RoutesHeaders = {
    '/home/ads': 'Add Ad',
    '/home/shortNews': 'Add Short',
    '/home/html': 'Add HTML',
    '/home/shortVideo': 'Add Short Video',
    '/home/devotionalVideo': 'Add Devotional Video',
    '/home/poll': 'Add Poll',
    '/home/card': 'Add Card',
    '/home/quiz': 'Add Quiz',
    '/home/languages': 'Add Language',
    '/home/hashtags': 'Add Hashtag',
    '/home/categories': 'Add Category',
    '/home/subcategories': 'Add SubCategory',
    '/home/feedback': 'FeedBack',
    '/home/sources': 'Add Source',
    '/home/employees': 'Add Employee',
    '/home/features': 'Add Features',
    
}

export const RoutesPageHeaders = {
    '/home/horoscope' :'Manage Horoscope',
    '/home/panchangam':'Manage Panchangam',
    '/home/createElections':'Create Elections',
    '/home/ads': 'Manage Ad',
    '/home/shortNews': 'Manage Short News',
    '/home/html': 'Manage HTML',
    '/home/shortVideo': 'Manage Short Video',
    '/home/devotionalVideo': 'Manage Devotional Video',
    '/home/poll': 'Manage Poll',
    '/home/card': 'Manage Card',
    '/home/quiz': 'Manage Quiz',
    '/home/languages': 'Manage Languages',
    '/home/hashtags': 'Manage Hashtag',
    '/home/categories': 'Manage Categories',
    '/home/subcategories': 'Manage SubCategories',
    '/home/feedback': 'Manage FeedBack',
    '/home/sources': 'Manage Source',
    '/home/employees': 'Manage Employees',
    '/home/stringers': 'Manage Stringers',
    '/home/features': 'Manage Features',
    '/home/ug': 'Manage UG Content',
    '/home/tv': 'Manage TV Content',
    '/home/editorDashboard/tv': 'Manage TV Content'
    
}

export const TableTypeFromRoutes = {
    '/home/ads': 'ads',
    '/home/shortNews': 'shortnews',
    '/home/shortVideo': 'shortvideo',
    '/home/devotionalVideo': 'devotionalvideo',
    '/home/poll': 'poll',
    '/home/card': 'card',
    '/home/quiz': 'quiz',
    '/home/hashtags': 'hashtag',
    '/home/categories': 'categories',
    '/home/subcategories': 'subcategories',
    '/home/employees': 'employees',
    '/home/stringers': 'stringers',
    '/home/feedback': 'feedback',
    '/home/sources': 'source',
    '/home/languages': 'language',
    '/home/features': 'features',
    '/home/html': 'html',
    '/home/ug': 'ug',
    '/home/tv': 'tv',
    '/home/editorDashboard/tv': 'tv'
}

export const TableTypeFromRoutesContent = {
    '/home/shortNewsContent': 'shortnews',
    '/home/shortVideoContent': 'shortvideo',
    '/home/devotionalVideoContent': 'devotionalvideo',
    '/home/pollContent': 'poll',
    '/home/cardContent': 'card',
    '/home/quizContent': 'quiz',
    '/home/htmlContent': 'html',
}

export const expiryHoursOptions = ['Never', 1, 4, 6, 12, 24, 48]

export const isPublish = (userPermissions, parent, child) => {
    return (
        userPermissions &&
        userPermissions?.[parent]?.[child]?.includes('Can Approve')
    )
}
export const isSubmitAndDraft = (userPermissions, parent, child) => {
    return (
        userPermissions &&
        userPermissions?.[parent]?.[child]?.includes('Can Add')
    )
}
export const isReview = (userPermissions, parent, child) => {
    return (
        userPermissions &&
        userPermissions?.[parent]?.[child]?.includes('Can Edit')
    )
}

export const NotificationType = [
    {
        label: 'Short',
        value: 'shortnews',
    },
    {
        label: 'Card',
        value: 'card',
    },
]

import React, { useState, useMemo } from 'react'
import './ViewPageVisuals.scss'
import { DeleteFilled, EditFilled } from '@ant-design/icons'
import { Popconfirm, Pagination } from 'antd'
import PreviewModal from './PreviewModal'
import { useLocation } from 'react-router'
import AddMagazineModal from './Magazines/AddMagazineModal'

const ViewPageVisuals = ({ visuals, editVisuals, deleteVisuals }) => {
    const location = useLocation()
    const [hover, setHover] = useState({})
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [img, setImg] = useState([])
    const [editModalOpen, setEditModalOpen] = useState(false)
    const [editingMagazine, setEditingMagazine] = useState(null)

    const [currentPage, setCurrentPage] = useState(1)
    const pageSize = 16

    const handleMouseOver = (id) => {
        setHover((prev) => ({ ...prev, [id]: true }))
    }

    const handleMouseLeave = (id) => {
        setHover((prev) => ({ ...prev, [id]: false }))
    }

    function handleClick(data) {
        setImg(data)
        setIsModalOpen(true)
    }

    const handleEditClick = (magazine) => {
        const transformedMagazine = {
            ...magazine,
            image_urls: magazine.images,
            images: undefined,
        }
        setEditingMagazine(transformedMagazine)
        setEditModalOpen(true)
    }

    const handleModalClose = (language, category) => {
        setEditModalOpen(false)
        setEditingMagazine(null)
    }

    const paginatedVisuals = useMemo(() => {
        const startIndex = (currentPage - 1) * pageSize
        return visuals?.slice(startIndex, startIndex + pageSize) || []
    }, [visuals, currentPage, pageSize])

    const handlePageChange = (page) => {
        setCurrentPage(page)
    }

    const isMagazineView = location.pathname.includes('/magazines')

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="outer-container">
                {paginatedVisuals.map((e) => (
                    <div
                        onMouseOver={() => handleMouseOver(e.id)}
                        onMouseLeave={() => handleMouseLeave(e.id)}
                        key={e.id}
                        className="visual_container"
                    >
                        <img
                            onClick={() => handleClick(e.images)}
                            loading="lazy"
                            className="visuals_img"
                            src={`${e?.images?.[0]}`}
                            alt="visual"
                        />
                        {hover[e.id] && (
                            <div className="visual_button">
                                <div
                                    className="action_btn"
                                    onClick={() =>
                                        isMagazineView
                                            ? handleEditClick(e)
                                            : editVisuals(e)
                                    }
                                >
                                    <EditFilled />
                                </div>
                                <div className="action_btn">
                                    <Popconfirm
                                        title="Delete Visual"
                                        description="Are you sure to delete this visual?"
                                        onConfirm={() => deleteVisuals(e)}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <DeleteFilled
                                            style={{ paddingLeft: '5px' }}
                                        />
                                    </Popconfirm>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
            {isMagazineView && (
                <AddMagazineModal
                    isModalOpen={editModalOpen}
                    handleCancel={handleModalClose}
                    setIsModalOpen={setEditModalOpen}
                    editingMagazine={editingMagazine}
                />
            )}

            <div
                style={{
                    textAlign: 'center',
                    marginTop: '20px',
                    alignSelf: 'flex-end',
                }}
            >
                <Pagination
                    current={currentPage}
                    total={visuals?.length || 0}
                    pageSize={pageSize}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                />
            </div>

            <PreviewModal
                data={img}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
            />
        </div>
    )
}

export default ViewPageVisuals

import React, { useEffect, useState } from 'react'
import { Form, Input, Select, Button, message, Radio } from 'antd'
import {
    CloseSquareFilled,
    DeleteFilled,
    PictureFilled,
    PlusOutlined,
    VideoCameraFilled,
} from '@ant-design/icons'
import SmartphonePreview from '../../SmartphonePreview/SmartphonePreview'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import './AddSnippetForm.scss'
import MultimediaModal from './MultimediaModal'
import {
    createSnippet,
    getSnippetCategories,
    updateSnippet,
    getCities,
} from '../../../AadhanApi'
import moment from 'moment'
import generateKeyWord from '../../AIResponseFilter/KeywordsResponse'
import { SelectComponentWithSearch } from '../../AntdComponent/SelectComponentWithSearch'
import EditCropper from '../../AddShortNews/EditCropper'

const { Option } = Select
const { TextArea } = Input

function AddSnippetsForm() {
    const navigate = useNavigate()
    const location = useLocation()
    const {
        editingSnippet,
        selectedLanguage: initialLanguage,
        selectedCategory: initialCategory,
        selectedState: initialState,
        selectedViewType: initialViewType,
    } = location.state || {}
    const isEditing = Boolean(editingSnippet)

    const [radioVal, setRadioVal] = useState(() => {
        if (
            initialViewType === 'specialContent' ||
            editingSnippet?.is_special_content
        ) {
            return 3
        }
        if (initialViewType === 'state' || editingSnippet?.state_id) {
            return 2
        }
        return 1
    })

    const [form] = Form.useForm()
    const languageData = useSelector((state) => state.app.languageData)
    const accessToken = useSelector((state) => state.app.accessToken)

    const [selectedLanguage, setSelectedLanguage] = useState(
        initialLanguage || languageData?.[0]?.language_id || null
    )

    const [categories, setCategories] = useState([])
    const [availableStates, setAvailableStates] = useState([])
    const [title, setTitle] = useState('')
    const [shortDescription, setShortDescription] = useState('')
    const [remainingCharCount, setRemainingCharCount] = useState(400)
    const [isImageAdding, setIsImageAdding] = useState(false)
    const [isVideoAdding, setIsVideoAdding] = useState(false)
    const [imagePath, setImagePath] = useState('')
    const [videoPath, setVideoPath] = useState({ stream_url: '', yt_url: '' })
    const [images, setImages] = useState([])
    const [isOriginal, setIsOriginal] = useState(true)
    const [isSensitive, setIsSensitive] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const [category, setCategory] = useState(initialCategory || '')
    const [state, setState] = useState(undefined)
    const [cities, setCities] = useState([])

    const [selectedCity, setSelectedCity] = useState(undefined)
    const [stateName, setStateName] = useState('')
    const [cityName, setCityName] = useState('')

    useEffect(() => {
        if (
            imagePath &&
            imagePath !== '' &&
            imagePath &&
            !images.includes(imagePath)
        ) {
            setImages((pre) => [...pre, imagePath])
        }
    }, [imagePath])

    const handleLanguageChange = async (value) => {
        setSelectedLanguage(value)
        setCategory('')
        form.setFieldsValue({
            category: undefined,
            state: undefined,
            city: undefined,
        })

        setState(undefined)
        setSelectedCity(undefined)
        setStateName('')
        setCityName('')

        const selectedLanguageData = languageData.find(
            (lang) => lang.language_id === value
        )
        if (selectedLanguageData && selectedLanguageData.states) {
            setAvailableStates(selectedLanguageData.states)
        }

        try {
            const response = await getSnippetCategories(
                value,
                'all',
                accessToken
            )
            if (response.data.success) {
                setCategories(response.data.data)

                if (radioVal === 1 && response.data.data.length > 0) {
                    const categoryToSet =
                        response.data.data[0].snippet_category_id
                    setCategory(categoryToSet)
                    form.setFieldValue('category', categoryToSet)
                }
            }
        } catch (error) {
            console.error('Error fetching categories:', error)
        }
    }

    useEffect(() => {
        if (languageData?.length > 0) {
            const languageToSet =
                initialLanguage ||
                selectedLanguage ||
                languageData[0].language_id

            // setting the language
            setSelectedLanguage(languageToSet)

            const selectedLanguageData = languageData.find(
                (lang) => lang.language_id === languageToSet
            )
            if (selectedLanguageData && selectedLanguageData.states) {
                setAvailableStates(selectedLanguageData.states)
            }

            //  form values only after categories are loaded
            const loadInitialData = async () => {
                try {
                    const response = await getSnippetCategories(
                        languageToSet,
                        'all',
                        accessToken
                    )
                    if (response.data.success) {
                        setCategories(response.data.data)
                        // setting both language and category in form
                        form.setFieldsValue({
                            language: languageToSet,
                            category: initialCategory || undefined,
                        })
                    }
                } catch (error) {
                    console.error('Error loading initial categories:', error)
                }
            }

            loadInitialData()
        }
    }, [languageData, initialLanguage, form, initialCategory, accessToken])

    useEffect(() => {
        if (selectedLanguage && radioVal === 1) {
            const fetchCategories = async () => {
                try {
                    const response = await getSnippetCategories(
                        selectedLanguage,
                        'all',
                        accessToken
                    )
                    if (response.data.success) {
                        setCategories(response.data.data)
                        if (
                            !category &&
                            response.data.data.length > 0 &&
                            !initialCategory
                        ) {
                            const categoryToSet =
                                response.data.data[0].snippet_category_id
                            setCategory(categoryToSet)
                            form.setFieldValue('category', categoryToSet)
                        }
                    }
                } catch (error) {
                    console.error('Error fetching categories:', error)
                }
            }
            fetchCategories()
        }
    }, [selectedLanguage, radioVal, accessToken, initialCategory])

    const handleTitleChange = (value) => {
        setTitle(value)
        setSearchTerm(value.replaceAll(' ', ','))
        form.setFieldValue('title', value)
    }

    const changeDescriptionValue = (value) => {
        setShortDescription(value)
        setRemainingCharCount(400 - value.length)
        form.setFieldValue('shortDescription', value)
    }

    const handleStateChange = async (value) => {
        setState(value)

        if (radioVal === 3 && value === 'all') {
            setSelectedCity(undefined)
            setCityName('')
            form.setFieldsValue({ city: undefined })
            return
        }

        setSelectedCity(undefined)
        setCityName('')
        form.setFieldsValue({ city: undefined })

        const selectedState = availableStates.find((s) => s.state_id === value)
        setStateName(selectedState?.name || '')

        try {
            const response = await getCities(accessToken, value)
            setCities(response.data)
        } catch (error) {
            console.error('Error fetching cities:', error)
            message.error('Failed to fetch cities')
        }
    }

    const handleCityChange = (value) => {
        setSelectedCity(value)
        const selectedCity = cities.find((c) => c.city_id === value)
        setCityName(selectedCity?.name || '')
    }

    const handleImageClick = () => {
        setIsImageAdding(true)
    }

    const handleVideoClick = () => {
        setIsVideoAdding(true)
    }

    const handleModalCancel = () => {
        setIsImageAdding(false)
        setIsVideoAdding(false)
    }

    const handleOk = () => {
        setIsVideoAdding(false)
        setIsImageAdding(false)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Form validation failed:', errorInfo)
    }

    const handleInputChange = (e) => {
        const value = e.target.value
        setVideoPath({ yt_url: value, stream_url: '' })
    }

    const onChangeRadio = (e) => {
        setRadioVal(e.target.value)
        if (e.target.value === 1 && categories.length > 0) {
            const categoryToSet = categories[0].snippet_category_id
            setCategory(categoryToSet)
            // form.setFieldValue('category', categoryToSet)
        } else if (e.target.value === 2 && availableStates.length > 0) {
            setState('')
            form.setFieldValue('state', undefined)
            form.setFieldValue('city', undefined)
            form.setFieldValue('category', undefined)
            setSelectedCity(undefined)
            setCities([])
        } else if (e.target.value === 3 && availableStates.length > 0) {
            setState('')
            form.setFieldValue('state', undefined)
            form.setFieldValue('category', undefined)
        }
    }

    useEffect(() => {
        if (radioVal === 2 && availableStates.length > 0) {
            const stateToSet = initialState || availableStates[0].state_id
            setState(stateToSet)
            handleStateChange(stateToSet)
        }
    }, [radioVal, availableStates, initialState])

    useEffect(() => {
        if (editingSnippet) {
            const setupEditForm = async () => {
                try {
                    const languageToSet = editingSnippet.language_id
                    setSelectedLanguage(languageToSet)
                    setTitle(editingSnippet.title || '')
                    setShortDescription(editingSnippet.description || '')

                    const selectedLanguageData = languageData.find(
                        (lang) => lang.language_id === languageToSet
                    )

                    if (selectedLanguageData && selectedLanguageData.states) {
                        setAvailableStates(selectedLanguageData.states)

                        const categoriesResponse = await getSnippetCategories(
                            languageToSet,
                            'all',
                            accessToken
                        )
                        if (categoriesResponse.data.success) {
                            setCategories(categoriesResponse.data.data)
                        }

                        let radioValToSet = 1

                        if (editingSnippet.is_special_content) {
                            radioValToSet = 3
                            setRadioVal(3)
                            setState(editingSnippet.state_id || 'all')
                        }

                        else if (editingSnippet.state_id) {
                            radioValToSet = 2
                            setRadioVal(2)

                            setState(editingSnippet.state_id)
                            setStateName(editingSnippet.state_name)

                            try {
                                const citiesResponse = await getCities(
                                    accessToken,
                                    editingSnippet.state_id
                                )
                                setCities(citiesResponse.data)
                                setSelectedCity(editingSnippet.city_id)
                                setCityName(editingSnippet.city_name)
                            } catch (error) {
                                console.error('Error fetching cities:', error)
                            }
                        }
                    }

                    setCategory(editingSnippet.snippet_category_id)

                    form.setFieldsValue({
                        language: editingSnippet.language_id,
                        category: editingSnippet.snippet_category_id,
                        // state: editingSnippet.state_id,
                        state:
                            radioVal === 3
                                ? editingSnippet.state_id || 'all'
                                : editingSnippet.state_id,
                        city: editingSnippet.city_id,
                        title: editingSnippet.title,
                        shortDescription: editingSnippet.description,
                    })

                    if (editingSnippet.image_url) {
                        setImagePath(editingSnippet.image_url)
                    }
                    if (editingSnippet.image_urls) {
                        setImages(editingSnippet.image_urls)
                    }
                    if (
                        editingSnippet.yt_video_url ||
                        editingSnippet.mp4_video_url
                    ) {
                        setVideoPath({
                            yt_url: editingSnippet.yt_video_url || '',
                            stream_url: editingSnippet.mp4_video_url || '',
                        })
                    }

                    const descLength = (editingSnippet.description || '').length
                    setRemainingCharCount(400 - descLength)
                } catch (error) {
                    console.error('Error setting up edit form:', error)
                }
            }
            setupEditForm()
        }
    }, [editingSnippet, form, accessToken, languageData])

    const prepareRequestData = (status, keywords) => {
        const employeeId = localStorage.getItem('EMPLOYEE_ID')
        const employeeName = localStorage.getItem('EMPLOYEE_NAME')

        const selectedCategory =
            radioVal === 1
                ? categories.find((cat) => cat.snippet_category_id === category)
                : ''

        const selectedCategory_id = radioVal === 1 ? category : ''

        const requestData = {
            snippet_category_name:
                selectedCategory?.snippet_category_name || '',
            content_status: status,
            content_type: 'shortnews',
            created_type: '',
            description: shortDescription,
            // image_url: imagePath,
            image_urls: images,
            image_url_16_9: '',
            image_url_3_4: '',
            keywords: keywords,
            language_id: selectedLanguage,
            left_color: '#000000',
            right_color: '#000000',
            mp4_video_url: videoPath.stream_url || '',
            thumbnailimage_url: 'string',
            title: title,
            uploaded_by: parseInt(employeeId) || 0,
            uploaded_by_name: employeeName || 'string',
            uploaded_date: moment().toISOString(),
            yt_video_url: videoPath.yt_url || '',
            content_id: editingSnippet?.content_id || '',
            snippet_category_id: selectedCategory_id || '',
            code: '',
            state_id:
                radioVal === 1
                    ? ''
                    : radioVal === 3 && state === 'all'
                    ? ''
                    : state || '',
            state_name:
                radioVal === 1
                    ? ''
                    : radioVal === 3 && state === 'all'
                    ? ''
                    : stateName || '',
            city_id:
                radioVal === 3 ? '' : radioVal === 1 ? '' : selectedCity || '',
            city_name:
                radioVal === 3 ? '' : radioVal === 1 ? '' : cityName || '',
            is_special_content: radioVal === 3,

            ...(editingSnippet && {
                content_id: editingSnippet.content_id,
                code: editingSnippet.code,
                dynamic_url: editingSnippet.dynamic_url || '',
                stream_video_url: editingSnippet.stream_video_url || '',
            }),
        }
        return requestData
    }

    const handleUpdateImage = (updatedUrls) => {
        setImages(updatedUrls)
    }

    const getImage = (url, index) => {
        return (
            <div
                style={{
                    position: 'relative',
                    display: 'inline-block',
                    margin: '4px',
                    boxShadow: '2px 2px 2px lightgray',
                    borderRadius: '5px',
                }}
            >
                <img
                    alt="news"
                    src={url}
                    style={{
                        objectFit: 'cover',
                        height: 100,
                        width: 100,
                        borderRadius: 5,
                        padding: 0,
                    }}
                />
                <DeleteFilled
                    onClick={() => handleDeleteImage(url)}
                    className="icon-hover"
                    style={{
                        position: 'absolute',
                        cursor: 'pointer',
                        top: 0,
                        right: 0,
                        border: 'none',
                        color: 'white',
                        fontSize: '16px',
                        padding: 4,
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        cursor: 'pointer',
                        top: '75%',
                        right: 0,
                        border: 'none',
                        color: 'white',
                        opacity: 0.9,
                        textShadow: '2px 2px 2px red',
                        fontSize: '16px',
                        padding: 4,
                    }}
                >
                    <EditCropper
                        imageUrls={images}
                        index={index}
                        accessToken={accessToken}
                        languageOption={selectedLanguage}
                        onUpdate={handleUpdateImage}
                    />
                </div>
            </div>
        )
    }

    const handleSubmit = async (status) => {
        try {
            const keywordsResponse = await generateKeyWord(
                title,
                shortDescription,
                selectedLanguage
            )
            const keywords = keywordsResponse?.keywords || ''

            const requestData = prepareRequestData('approved', keywords)

            let response

            if (editingSnippet) {
                response = await updateSnippet(
                    editingSnippet.content_id,
                    requestData,
                    accessToken
                )
            } else {
                response = await createSnippet(requestData, accessToken)
            }

            if (response.data.success) {
                localStorage.removeItem('lastSelectedLanguage')
                message.success(
                    `Snippet ${
                        editingSnippet ? 'updated' : 'saved'
                    } successfully`
                )

                let selectedViewType
                if (radioVal === 2) {
                    selectedViewType = 'state'
                } else if (radioVal === 1) {
                    selectedViewType = 'category'
                } else {
                    selectedViewType = 'specialContent'
                }
                navigate('/home/snippet', {
                    state: {
                        selectedLanguage,
                        selectedCategory: category,
                        selectedState: state,
                        // selectedViewType: radioVal === 2 ? 'state' : 'category',
                        selectedViewType: selectedViewType,
                        selectedCity: selectedCity,
                        selectedCityName: cityName,
                    },
                    replace: true,
                })
            }
        } catch (error) {
            if (error.errorFields) {
                const missingFields = error.errorFields
                    .map((field) => field.name[0])
                    .join(', ')
                message.error(
                    `Please fill in all required fields: ${missingFields}`
                )
            } else {
                console.error('Error saving snippet:', error)
                message.error(
                    `Failed to ${editingSnippet ? 'update' : 'create'} snippet`
                )
            }
        }
    }

    const onFinish = async (values) => {
        if (!imagePath) {
            message.warning('Please add an image')
            return
        }
        try {
            await form.validateFields()
            handleSubmit('approved')
        } catch (errorInfo) {
            console.log('Validation failed:', errorInfo)
        }
    }

    const handleDeleteImage = (url) => {
        setImages(images?.filter((e) => e !== url))
    }

    const handleCancel = () => {
        navigate('/home/snippet', {
            state: {
                selectedLanguage: selectedLanguage,
                selectedCategory: initialCategory,
                selectedState: initialState,
                selectedViewType: initialViewType,
            },
            replace: true,
        })
    }

    return (
        <div className="total-form-snippets">
            <div className="headingAndIcon">
                <div className="arrowAndTitle">
                    <h2 className="addFormHeading">
                        {isEditing ? 'Edit Snippet' : 'Add Snippet'}
                    </h2>
                </div>
                <Button
                    onClick={handleCancel}
                    ghost={true}
                    style={{ border: 'none' }}
                    type="primary"
                    icon={
                        <CloseSquareFilled
                            style={{
                                color: 'LightCoral',
                                fontSize: '1.8rem',
                            }}
                        />
                    }
                ></Button>
            </div>
            <hr></hr>

            <div className="formAndPreview">
                <div id="form" style={{ width: '100%', position: 'relative' }}>
                    <Form
                        form={form}
                        name="basic"
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 18 }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        initialValues={{
                            language: languageData?.[0]?.language_id,
                        }}
                    >
                        <Form.Item
                            label="Language"
                            name="language"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a language',
                                },
                            ]}
                            style={{ fontWeight: 550 }}
                        >
                            <Select
                                className="dropdown-1"
                                onChange={handleLanguageChange}
                                // value={selectedLanguage}
                                placeholder="Select Language"
                            >
                                {languageData.map((arr) => (
                                    <Option
                                        key={arr.language_id}
                                        value={arr.language_id}
                                    >
                                        {arr.language_name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Snippet based on"
                            rules={[
                                {
                                    required: true,
                                    // message: 'Please select One',
                                },
                            ]}
                            style={{
                                marginTop: '2vh',
                                marginBottom: '2vh',
                                fontWeight: 550,
                            }}
                        >
                            <Radio.Group
                                onChange={onChangeRadio}
                                value={radioVal}
                            >
                                <Radio
                                    value={1}
                                    style={{
                                        marginRight: '4vw',
                                        fontWeight: 450,
                                    }}
                                >
                                    Category
                                </Radio>
                                <Radio
                                    value={2}
                                    style={{
                                        marginRight: '4vw',
                                        fontWeight: 450,
                                    }}
                                >
                                    City
                                </Radio>
                                <Radio value={3} style={{ fontWeight: 450 }}>
                                    Special Content
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                        {radioVal === 1 && (
                            <Form.Item
                                label="Category"
                                name="category"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a category',
                                    },
                                ]}
                                style={{ fontWeight: 550 }}
                            >
                                <Select
                                    placeholder="--Select Category--"
                                    onChange={(value) => setCategory(value)}
                                >
                                    {categories.map((category) => (
                                        <Option
                                            key={category.snippet_category_id}
                                            value={category.snippet_category_id}
                                        >
                                            {category.snippet_category_name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}
                        {radioVal === 2 && (
                            <div>
                                <Form.Item
                                    label="State"
                                    name="state"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a state',
                                        },
                                    ]}
                                    style={{ fontWeight: 550 }}
                                >
                                    <SelectComponentWithSearch
                                        data={availableStates}
                                        value={state}
                                        onChange={handleStateChange}
                                        key_value={'state_id'}
                                        display={'name'}
                                        placeholder={'Select State'}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="City"
                                    name="city"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a city',
                                        },
                                    ]}
                                    style={{ fontWeight: 550 }}
                                >
                                    <Select
                                        placeholder="Select city"
                                        disabled={!state}
                                        onChange={handleCityChange}
                                    >
                                        {cities?.map((city) => (
                                            <Select.Option
                                                key={city.city_id}
                                                value={city.city_id}
                                            >
                                                {city.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                        )}
                        {radioVal === 3 && (
                            <Form.Item
                                label="State"
                                name="state"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a state',
                                    },
                                ]}
                                style={{ fontWeight: 550 }}
                            >
                                <Select
                                    placeholder="Select State"
                                    value={state}
                                    onChange={handleStateChange}
                                >
                                    <Option value="all">--All--</Option>
                                    {availableStates.map((stateItem) => (
                                        <Option
                                            key={stateItem.state_id}
                                            value={stateItem.state_id}
                                        >
                                            {stateItem.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}

                        <Form.Item
                            label="Headline"
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a headline',
                                    whitespace: true,
                                },
                            ]}
                            style={{ fontWeight: 550 }}
                        >
                            <TextArea
                                maxLength={75}
                                value={title}
                                onChange={(e) =>
                                    handleTitleChange(e.target.value)
                                }
                                placeholder="Title of the article (max 75 chars) in regional language (ex:tamil,telugu)"
                            />
                            <span className="description-subtext">
                                <span
                                    style={{
                                        color:
                                            title.length > 75
                                                ? 'red'
                                                : '#555555',
                                    }}
                                >
                                    {75 - title.length} characters
                                </span>
                            </span>
                        </Form.Item>

                        <Form.Item
                            label="Story Details"
                            name="shortDescription"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter story details',
                                    whitespace: true,
                                },
                            ]}
                            style={{ fontWeight: 550 }}
                        >
                            <TextArea
                                value={shortDescription}
                                rows={2}
                                maxLength={400}
                                autoSize
                                style={{ minHeight: '10rem' }}
                                onChange={(e) =>
                                    changeDescriptionValue(e.target.value)
                                }
                                placeholder="Upto 60 words"
                            />
                            <span className="description-subtext">
                                <span
                                    style={{
                                        color:
                                            remainingCharCount === 0
                                                ? 'red'
                                                : '#555555',
                                    }}
                                >
                                    {remainingCharCount} characters
                                </span>
                            </span>
                        </Form.Item>

                        <Form.Item
                            label="Multimedia"
                            name="multimedia"
                            style={{ fontWeight: 550 }}
                        >
                            <div style={{ display: 'flex' }}>
                                <span
                                    style={{ marginRight: '3rem' }}
                                    className="multimedia"
                                    onClick={handleImageClick}
                                >
                                    <PictureFilled style={{ color: 'gray' }} />{' '}
                                    Image
                                </span>
                                <span
                                    className="multimedia"
                                    onClick={handleVideoClick}
                                >
                                    <VideoCameraFilled
                                        style={{ color: 'gray' }}
                                    />{' '}
                                    Video
                                </span>
                            </div>
                        </Form.Item>
                        <Form.Item label="">
                            {images?.length > 0 && (
                                <div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                            marginTop: '-1.5%',
                                        }}
                                    >
                                        {images?.map((e, index) => {
                                            return (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                    key={index}
                                                >
                                                    {getImage(e, index)}{' '}
                                                    <div>
                                                        {index ===
                                                            images.length -
                                                                1 && (
                                                            <div>
                                                                <Button
                                                                    type="primary"
                                                                    onClick={() =>
                                                                        setIsImageAdding(
                                                                            true
                                                                        )
                                                                    }
                                                                    shape="circle"
                                                                    style={{
                                                                        marginLeft: 5,
                                                                    }}
                                                                    icon={
                                                                        <PlusOutlined />
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )}
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 5, span: 17 }}>
                            <div className="buttons_shortnews">
                                <Button
                                    className="width-100"
                                    type="primary"
                                    htmlType="submit"
                                    // onClick={handleSubmit}
                                >
                                    Save
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
                <div>
                    <SmartphonePreview
                        // image={imagePath}
                        image={images}
                        title={title}
                        videoPath={
                            videoPath?.stream_url
                                ? videoPath?.stream_url
                                : videoPath?.yt_url
                        }
                        description={shortDescription}
                    />
                </div>
            </div>

            <MultimediaModal
                isImageAdding={isImageAdding}
                isVideoAdding={isVideoAdding}
                handleModalCancel={handleModalCancel}
                handleOk={handleOk}
                setIsImageAdding={setIsImageAdding}
                accessToken={accessToken}
                selectedLanguage={selectedLanguage}
                title={title}
                setImagePath={setImagePath}
                imagePath={imagePath}
                images={images}
                isOriginal={isOriginal}
                setIsOriginal={setIsOriginal}
                isSensitive={isSensitive}
                setIsSensitive={setIsSensitive}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                videoPath={videoPath}
                setVideoPath={setVideoPath}
                handleInputChange={handleInputChange}
                handleDeleteImage={handleDeleteImage}
            />
        </div>
    )
}

export default AddSnippetsForm

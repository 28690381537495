import React, { useEffect, useState } from 'react'
import './writerDashboard.scss'
import { ApprovalOptions, ApprovalOptionsReporter } from '../ManageContent/ManageContent.constant'
import dayjs from 'dayjs'
import CommonTable from '../CommonTable/CommonTable'
import Viewicon from '../../assets/view_icon.svg'
import {
    DeleteOutlined,
    EditOutlined,
    PlayCircleOutlined,
    YoutubeFilled,
} from '@ant-design/icons'
import { message, Popconfirm } from 'antd'
import {
    deleteShortNewsRow,
    getUgcContentReporter,
    searchDataByTitleAndType,
} from '../../AadhanApi'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Search from 'antd/es/input/Search'
import { SelectComponent } from '../AntdComponent/SelectComponent'
export const Activity = ({ dates }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const languageData = useSelector((state) => state.app.languageData)
    const languageOption = languageData?.[0]?.language_id
    const [messageApi, contextHolder] = message.useMessage()
    const accessToken = useSelector((state) => state.app.accessToken)
    const contentStatus = location?.pathname?.includes('home/reporter-dashboard') ? ApprovalOptionsReporter : ApprovalOptions
    const [status, setStatus] = useState('approved')
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)

    const onChangeStatus = (value) => {
        setStatus(value)
    }

    const displayError = (errMessage) => {
        messageApi.open({
            type: 'error',
            content: errMessage,
            duration: 5,
        })
    }
    useEffect(() => {
        getShortNewsTableData()
    }, [status, dates])
    const displayAuthenticationError = (errMessage) => {
        messageApi.open({
            type: 'error',
            content: (
                <div style={{ display: 'flex', gap: '5px' }}>
                    {errMessage}
                    <Link to="/">
                        Click here to login or will be redirected to Login page
                        in 5 seconds
                    </Link>
                </div>
            ),
            duration: 10,
        })
        setTimeout(() => {
            // navigate('/')
        }, 5000)
    }

    const [shortNewsData, setShortNewsData] = useState([])
    const getShortNewsTableData = async () => {
        const startDateFormat = dayjs(dates?.startDate).format('YYYY-MM-DD')
        const endDateFormat = dayjs(dates?.endDate).format('YYYY-MM-DD')
        setLoading(true)
        try {
            if (!location?.pathname?.includes('home/reporter-dashboard')) {
                const shortNewsDataResponse = await searchDataByTitleAndType(
                    search,
                    languageOption,
                    'shortnews',
                    99999,
                    status,
                    startDateFormat,
                    endDateFormat,
                    1,
                    2000,
                    accessToken,
                    undefined,
                    true
                )
                setShortNewsData(
                    shortNewsDataResponse?.data?.sort((a, b) =>
                        dayjs(b?.uploaded_date).isAfter(dayjs(a?.uploaded_date))
                            ? 1
                            : -1
                    )
                )
            } else {
                const startDateFormat = dayjs(dates?.startDate)
                    .startOf('day')
                    .format('YYYY-MM-DD')
                const endDateFormat = dayjs(dates?.endDate)
                    .endOf('day')
                    .format('YYYY-MM-DD')

                const user_id = localStorage?.getItem('USER_ID')
                const shortNewsDataResponse = await getUgcContentReporter(
                    languageOption,
                    status,
                    startDateFormat,
                    endDateFormat,
                    user_id,
                    accessToken
                )
                setShortNewsData(
                    shortNewsDataResponse?.data?.sort((a, b) =>
                        dayjs(b?.uploaded_date).isAfter(dayjs(a?.uploaded_date))
                            ? 1
                            : -1
                    )
                )
            }
            setLoading(false)
        } catch (err) {
            console.log('error is ::::', err)
            // setDataLoading(false)
            if (err?.response?.status === 401) {
                displayAuthenticationError(
                    'You are unauthorised, Please login again'
                )
            } else {
                displayError('Failed to get short news data')
            }
        }
    }
    const deleteShortNews = async (record) => {
        setLoading(true)
        const deleteResponse = await deleteShortNewsRow(
            record?.content_id,
            accessToken
        )

        if (deleteResponse?.data === 'Short news deleted successfully') {
            getShortNewsTableData()
        }
    }
    const ShortNewsColumns = [
        {
            title: 'Uploaded Date',
            dataIndex: 'uploaded_date',
            key: 'uploaded_date',
            width: 120,
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                    }}
                >
                    {dayjs(record?.uploaded_date).format(
                        'DD MMM YYYY, hh:mm a'
                    )}
                    {dayjs(record?.published_date).diff(dayjs(), 'min') > 0 && (
                        <div
                            style={{
                                backgroundColor: '#a4c9a4',
                                border: '0.5px solid black',
                                borderRadius: '5px',
                                paddingLeft: '4px',
                                width: '80%',
                            }}
                        >
                            Scheduled :{' '}
                            {dayjs(record?.published_date).format(
                                'DD MMM YYYY, hh:mm a'
                            )}
                        </div>
                    )}
                </div>
            ),
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => a.title.localeCompare(b.title),
            sortDirections: ['ascend', 'descend'],
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                        wordBreak: 'break-all',
                    }}
                >
                    <p
                        style={{ color: 'blue', margin: 0, cursor: 'pointer' }}
                        href="/"
                        // onClick={() => viewPopup(record.content_id)}
                    >
                        {record?.title}
                    </p>
                    <span style={{ fontSize: '1.5rem' }}>
                        { (record?.stream_video_url && !Array.isArray(record?.stream_video_url)) || (!record?.stream_video_url?.length===0) ? (
                            <PlayCircleOutlined style={{ color: 'blue' }} />
                        ) : record?.yt_video_url ? (
                            <YoutubeFilled />
                        ) : (
                            ''
                        )}
                    </span>
                </div>
            ),
            width: 100,
        },
        {
            title: 'Category',
            dataIndex: 'category_name',
            key: 'category_name',
            width: 100,
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            width: 80,
            render: (_, record) => (
                <div size="middle" style={{ fontSize: '1.2rem' }}>
                   {!location?.pathname?.includes('home/reporter-dashboard') && 
                    <a
                    href={record.dynamic_url}
                    target="blank"
                    className="view-icon"
                >
                    <img
                        style={{ width: '1rem', marginRight: 10 }}
                        alt="ViewIcon"
                        src={Viewicon}
                    />
                </a>
                   }
                    <EditOutlined onClick={() => onEditClicked(record)} />
                        {!location?.pathname?.includes('home/reporter-dashboard') && 
                    <Popconfirm
                    title="Delete Row"
                    description="Are you sure to delete this row?"
                    onConfirm={() => deleteShortNews(record)}
                    okText="Yes"
                    cancelText="No"
                    >
                        {' '}
                        <DeleteOutlined style={{ paddingLeft: '5px' }} />
                    </Popconfirm>
                    }
                </div>
            ),
        },
    ]

    const onEditClicked = (record) => {
        navigate(
            `${location.pathname}?mode=edit&contentId=${record?.content_id}`
        )
    }
    return (
        <div className="activity_container">
            <div className="activity-filter">
                <SelectComponent
                    className={'activity-status'}
                    placeholder={''}
                    data={contentStatus}
                    value={status}
                    key_value={'value'}
                    display={'label'}
                    onChange={onChangeStatus}
                />
                {/* <Search
                    style={{ width: '270px', marginLeft: 'auto' }}
                    placeholder="Search by title or keywords..."
                    enterButton="Search"
                    size="medium"
                    onSearch={getShortNewsTableData}
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                ></Search> */}
            </div>
            {contextHolder}
            <div>
                <CommonTable
                    data={shortNewsData}
                    columns={ShortNewsColumns}
                    dataLoading={loading}
                ></CommonTable>
            </div>
        </div>
    )
}

import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import CommonTable from '../CommonTable/CommonTable'
import {
    Button,
    message,
    Typography,
    Col,
    Card,
    Row,
    Select,
    Modal,
} from 'antd'
import { UserRoundSearch, FileBadge, FileX } from 'lucide-react'
import { getLeads, updateLeadStatus, getAdvertisers } from '../../AadhanApi'
import { EditOutlined } from '@ant-design/icons'
import '../AddAd/ManageAd.scss'
import AddAdvertiserModal from '../Master/Advertisers/AddAdvertiserModal'

const { Text } = Typography
const { Option } = Select

const ALL_LEAD_TYPES = ['Inbound', 'Outbound', 'Referral']
const LEAD_STATUS_OPTIONS = [
    'fresh',
    'contacted',
    'followup',
    'negotiation',
    'lost',
    'won',
]

const Stagetable = () => {
    const navigate = useNavigate()
    const [leadData, setLeadData] = useState([])
    const [dataLoading, setDataLoading] = useState(false)
    const [selectedLeadOwner, setSelectedLeadOwner] = useState(null)
    const [leadType, setLeadType] = useState(null)
    const [isStatusModalVisible, setIsStatusModalVisible] = useState(false)
    const [selectedRecord, setSelectedRecord] = useState(null)
    const [newStatus, setNewStatus] = useState(null)
    const [isAdvertiserModalOpen, setIsAdvertiserModalOpen] = useState(false)
    const [advertiserToEdit, setAdvertiserToEdit] = useState(null)
    const accessToken = localStorage.getItem('ACCESS_TOKEN')

    // Handle lead status change
    const handleStatusChange = (value, record) => {
        setNewStatus(value)
        setSelectedRecord(record)
        setIsStatusModalVisible(true)
    }

    // Check if advertiser exists and handle conversion
    const handleLeadToAdvertiserConversion = async (record) => {
        try {
            const advertisersResponse = await getAdvertisers(accessToken)
            const advertisers = advertisersResponse.data.data || []

            const existingAdvertiser = advertisers.find(
                (advertiser) =>
                    advertiser.advertiser_name === record.name &&
                    advertiser.contact_person_name === record.contact_person &&
                    advertiser.phone_number === record.mobile_num
            )

            if (existingAdvertiser) {
                Modal.info({
                    title: 'Advertiser Already Exists',
                    content: (
                        <div>
                            <p>
                                An advertiser with these details already exists:
                            </p>
                            <p>
                                <strong>Name:</strong>{' '}
                                {existingAdvertiser.advertiser_name}
                            </p>
                            <p>
                                <strong>Contact:</strong>{' '}
                                {existingAdvertiser.contact_person_name}
                            </p>
                            <p>
                                <strong>Phone:</strong>{' '}
                                {existingAdvertiser.phone_number}
                            </p>
                        </div>
                    ),
                    okText: 'Okay',
                })
            } else {
                const advertiserData = {
                    advertiser_name: record.name,
                    contact_person_name: record.contact_person,
                    phone_number: record.mobile_num,
                    email_id: record.email,
                    address: record.address,
                    advertiser_type: '',
                    gst_number: '',
                    created_at: new Date().toISOString(),
                }

                setAdvertiserToEdit(advertiserData)
                setIsAdvertiserModalOpen(true)
            }
        } catch (error) {
            console.error('Error checking advertiser:', error)
            message.error('Failed to check advertiser status')
        }
    }

    // Handle status confirmation
    const handleStatusConfirm = async () => {
        if (!selectedRecord || !newStatus) return

        setDataLoading(true)
        try {
            const response = await updateLeadStatus(
                selectedRecord.lead_id,
                newStatus,
                accessToken
            )

            if (response?.data?.success) {
                const updatedData = leadData.map((item) =>
                    item.lead_id === selectedRecord.lead_id
                        ? { ...item, lead_status: newStatus }
                        : item
                )
                setLeadData(updatedData)

                if (newStatus === 'won') {
                    await handleLeadToAdvertiserConversion(selectedRecord)
                }

                message.success('Lead status updated successfully')
            } else {
                throw new Error(
                    response?.data?.message || 'Failed to update status'
                )
            }
        } catch (error) {
            console.error('Error updating lead status:', error)
            message.error('Failed to update lead status')
        } finally {
            setDataLoading(false)
            setIsStatusModalVisible(false)
            setSelectedRecord(null)
            setNewStatus(null)
        }
    }

    // Fetch leads data
    const fetchLeads = async () => {
        if (!accessToken) return

        setDataLoading(true)
        try {
            const response = await getLeads(accessToken, {
                leadOwner: selectedLeadOwner,
                leadType: leadType,
            })

            if (response?.data?.data) {
                setLeadData(response.data.data)
            } else {
                setLeadData([])
            }
        } catch (error) {
            console.error('Error fetching leads:', error)
            message.error('Failed to fetch lead data')
            setLeadData([])
        } finally {
            setDataLoading(false)
        }
    }

    useEffect(() => {
        fetchLeads()
    }, [selectedLeadOwner, leadType])

    const StageColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 150,
        },
        {
            title: 'Contact Person',
            dataIndex: 'contact_person',
            key: 'contact_person',
            width: 150,
        },
        {
            title: 'Contact Details',
            key: 'contact_details',
            width: 200,
            render: (_, record) => (
                <div>
                    <div>
                        <strong>Phone:</strong> {record.mobile_num}
                    </div>
                    <div>
                        <strong>Email:</strong> {record.email}
                    </div>
                    <div>
                        <strong>Address:</strong> {record.address}
                    </div>
                </div>
            ),
        },
        {
            title: 'Lead Type',
            dataIndex: 'lead_type',
            key: 'lead_type',
            width: 120,
        },
        {
            title: 'Lead Status',
            key: 'lead_status',
            width: 150,
            render: (_, record) => (
                <Select
                    value={record.lead_status}
                    style={{ width: '100%' }}
                    onChange={(value) => handleStatusChange(value, record)}
                >
                    {LEAD_STATUS_OPTIONS.map((status) => (
                        <Option key={status} value={status}>
                            {status.charAt(0).toUpperCase() + status.slice(1)}
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 100,
            render: (_, record) => (
                <EditOutlined
                    onClick={() =>
                        navigate('/home/lead', {
                            state: { isEdit: true, leadData: record },
                        })
                    }
                />
            ),
        },
    ]

    const summary = {
        totalDeals: leadData.length,
        totalOwn: leadData.filter((lead) => lead.lead_status === 'won').length,
        totalLost: leadData.filter((lead) => lead.lead_status === 'lost')
            .length,
    }

    return (
        <div className="manage-ad">
            <div className="manage-content-header">
                <h2 className="revenue-heading">Stage Table</h2>
            </div>

            <div className="summary-data">
                <Row gutter={[16, 16]} justify="center">
                    <Col span={8}>
                        <Card
                            className="summary-card"
                            bordered={false}
                            style={{ textAlign: 'center' }}
                        >
                            <UserRoundSearch
                                size={36}
                                strokeWidth={1.5}
                                style={{ fontSize: '2rem', color: '#1890ff' }}
                            />
                            <Text
                                strong
                                style={{ display: 'block', marginTop: '8px' }}
                            >
                                Total Leads
                            </Text>
                            <Text style={{ fontSize: '1.5rem', color: '#333' }}>
                                {summary.totalDeals}
                            </Text>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card
                            className="summary-card"
                            bordered={false}
                            style={{ textAlign: 'center' }}
                        >
                            <FileBadge
                                size={28}
                                strokeWidth={2.25}
                                style={{ fontSize: '2rem', color: '#52c41a' }}
                            />
                            <Text
                                strong
                                style={{ display: 'block', marginTop: '8px' }}
                            >
                                Total Leads Won
                            </Text>
                            <Text
                                style={{ fontSize: '1.5rem', color: '#52c41a' }}
                            >
                                {summary.totalOwn}
                            </Text>
                        </Card>
                    </Col>
                    <Col span={8}>
                        <Card
                            className="summary-card"
                            bordered={false}
                            style={{ textAlign: 'center' }}
                        >
                            <FileX
                                size={35}
                                strokeWidth={2.25}
                                style={{ fontSize: '2rem', color: 'red' }}
                            />
                            <Text
                                strong
                                style={{ display: 'block', marginTop: '8px' }}
                            >
                                Total Leads Lost
                            </Text>
                            <Text style={{ fontSize: '1.5rem', color: 'red' }}>
                                {summary.totalLost}
                            </Text>
                        </Card>
                    </Col>
                </Row>
            </div>

            {/* Filters and Add Button */}
            <div style={{ marginBottom: '16px', display: 'flex', gap: '16px' }}>
                <Select
                    allowClear
                    style={{ width: 200 }}
                    placeholder="Filter by Lead Owner"
                    onChange={setSelectedLeadOwner}
                    value={selectedLeadOwner}
                />
                <Select
                    allowClear
                    style={{ width: 200 }}
                    placeholder="Lead Type"
                    onChange={setLeadType}
                    value={leadType}
                >
                    {ALL_LEAD_TYPES.map((value) => (
                        <Option
                            key={value.toLowerCase()}
                            value={value.toLowerCase()}
                        >
                            {value}
                        </Option>
                    ))}
                </Select>
                <Button
                    className="manage-stage"
                    type="primary"
                    onClick={() => navigate('/home/lead')}
                >
                    Add Lead
                </Button>
            </div>

            {/* Lead Table */}
            <CommonTable
                columns={StageColumns}
                data={leadData}
                rowKey="lead_id"
                loading={dataLoading}
                scroll={{ x: 1200 }}
            />

            {/* Status Change Modal */}
            <Modal
                title="Confirm Status Change"
                open={isStatusModalVisible}
                onOk={handleStatusConfirm}
                onCancel={() => {
                    setIsStatusModalVisible(false)
                    setSelectedRecord(null)
                    setNewStatus(null)
                }}
                confirmLoading={dataLoading}
            >
                <p>
                    Are you sure you want to change the lead status to{' '}
                    {newStatus}?
                </p>
            </Modal>

            {/* Advertiser Modal */}
            <AddAdvertiserModal
                isModalOpen={isAdvertiserModalOpen}
                handleCancel={() => {
                    setIsAdvertiserModalOpen(false)
                    setAdvertiserToEdit(null)
                }}
                token={accessToken}
                editingAdvertiser={advertiserToEdit}
                onSuccess={() => {
                    setIsAdvertiserModalOpen(false)
                    setAdvertiserToEdit(null)
                    fetchLeads()
                }}
                fromstagetable={true}
            />
        </div>
    )
}

export default Stagetable

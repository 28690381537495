import { CloudUploadOutlined } from '@ant-design/icons'
import { Button, DatePicker, Form, message, Modal, Select, Upload } from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    createMagazine,
    getVisualsCategory,
    updateMagazine,
} from '../../../AadhanApi'
import TextArea from 'antd/es/input/TextArea'
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core'
import {
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import SmartphonePreview from '../../SmartphonePreview/SmartphonePreview'
import moment from 'moment'
import dayjs from 'dayjs'

function AddMagazineModal({
    isModalOpen,
    handleCancel,
    setIsModalOpen,
    editingMagazine,
}) {
    const [form] = Form.useForm()
    const [language, setLanguage] = useState(1)
    const [fileList, setFileList] = useState([])
    const [removedIndex, setRemovedIndex] = useState([])
    const [title, setTitle] = useState('')
    const accessToken = useSelector((state) => state.app.accessToken)
    const languageData = useSelector((state) => state.app.languageData)
    const [selectedCategory, setSelectedCategory] = useState()
    const [categoryOptions, setCategoryOptions] = useState([])
    const [loading, setIsLoading] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const [selectedCategoryDetails, setSelectedCategoryDetails] = useState(null)
    const { Option } = Select

    useEffect(() => {
        if (accessToken && language) {
            fetchCategories(language)
        }
    }, [accessToken, language])

    useEffect(() => {
        if (isModalOpen && editingMagazine) {
            fetchCategories(editingMagazine.language_id)
        }
    }, [isModalOpen, editingMagazine])

    useEffect(() => {
        if (editingMagazine) {
            setIsEditing(true)

            let publishDate = editingMagazine.unix_published_date
                ? dayjs.unix(editingMagazine.unix_published_date)
                : null

            form.setFieldsValue({
                language: editingMagazine.language_id,
                category: editingMagazine.visuals_category_id,
                title: editingMagazine.title,
                date: publishDate,
            })
            setLanguage(editingMagazine.language_id)
            setTitle(editingMagazine.title)

            setSelectedCategory(editingMagazine.visuals_category_id)

            const categoryDetail = categoryOptions.find(
                (cat) => cat.value === editingMagazine.visuals_category_id
            )

            // Handling image
            if (editingMagazine.image_urls?.length > 0) {
                const imageList = editingMagazine.image_urls.map(
                    (url, index) => ({
                        uid: `-${index}`,
                        name: `image-${index}`,
                        status: 'done',
                        url: url,
                        response: url,
                    })
                )
                setFileList(imageList)
            }
        } else {
            setIsEditing(false)
            form.resetFields()
            setFileList([])
            setTitle('')
        }
    }, [editingMagazine, form])

    const onFinish = async (values) => {
        try {
            setIsLoading(true)
            const istDate = values.date.unix()
            // const formattedDate = values.date.format('YYYY-MM-DD') + 'T00:00:00'
            if (isEditing) {
                const response = await updateMagazine(
                    {
                        ...values,
                        unix_published_date: istDate,
                    },
                    accessToken,
                    fileList,
                    categoryOptions,
                    removedIndex,
                    editingMagazine.magazine_id
                )
                if (response.data.success) {
                    message.success('Magazine updated successfully')
                    form.resetFields()
                    setIsModalOpen(false)
                    handleCancel(values.language, values.category)
                } else {
                    message.error('Failed to update Magazine')
                }
            } else {
                await createMagazine(
                    {
                        title: values.title,
                        category: values.category,
                        language: values.language,
                        images: values.cardImage,
                        unix_published_date: istDate,
                    },
                    accessToken,
                    fileList,
                    categoryOptions
                )
                message.success('Magazine created successfully')
                form.resetFields()
                setIsModalOpen(false)
                handleCancel(values.language, values.category)
            }

            setFileList([])
            setTitle('')
        } catch (error) {
            console.error('Failed to create magazine:', error)
            message.error('Failed to create magazine')
        } finally {
            setIsLoading(false)
        }
    }

    const handleLanguageChange = (value) => {
        setLanguage(value)
        setSelectedCategory(undefined)
        form.setFieldValue('category', undefined)
        form.setFieldValue('date', undefined)
    }

    const handleCategoryChange = (value) => {
        setSelectedCategory(value)
        const categoryDetail = categoryOptions.find(
            (cat) => cat.value === value
        )
        setSelectedCategoryDetails(categoryDetail)
        form.setFieldValue('date', undefined)
    }

    const fetchCategories = async (languageId) => {
        try {
            const response = await getVisualsCategory(
                accessToken,
                languageId,
                'magazine'
            )
            const categories = response.data.data.map((category) => ({
                value: category.category_id,
                label: category.category_name,
                is_weekly: category.is_weekly,
                day_of_week: category.day_of_week,
            }))
            setCategoryOptions([{ value: '', label: 'All' }, ...categories])

            if (isEditing && editingMagazine) {
                const categoryDetail = categories.find(
                    (cat) => cat.value === editingMagazine.visuals_category_id
                )
                setSelectedCategoryDetails(categoryDetail)
            }
        } catch (error) {
            console.error('Failed to fetch categories:', error)
            message.error('Failed to fetch categories')
        }
    }

    const handleChange = ({ fileList: newFileList }) => {
        const updatedFileList = newFileList
        setFileList(updatedFileList)
        if (
            updatedFileList.length > 0 &&
            updatedFileList[0].status === 'done'
        ) {
            const coverPageUrl =
                `${updatedFileList[0].url}?${new Date().getTime()}` ||
                `${updatedFileList[0].response.url}?${new Date().getTime()}` ||
                `${updatedFileList[0].thumb.url}?${new Date().getTime()}`

            form.setFieldsValue({
                cover_page: coverPageUrl,
            })
        } else {
            form.setFieldsValue({ cover_page: undefined })
        }
    }

    const handleTitleChange = (value) => {
        if (value) {
            let cleanedValue = value
            setTitle(cleanedValue)
        } else {
            setTitle('')
            form.setFieldValue('title', '')
        }
    }

    const sensor = useSensor(PointerSensor, {
        activationConstraint: {
            distance: 10,
        },
    })

    const DraggableUploadListItem = ({ originNode, file, fileList }) => {
        const { attributes, listeners, setNodeRef, transition, isDragging } =
            useSortable({
                id: file.uid,
            })
        const style = {
            transition,
            cursor: 'move',
        }
        return (
            <div
                ref={setNodeRef}
                style={style}
                className={isDragging ? 'is-dragging' : ''}
                {...attributes}
                {...listeners}
            >
                {originNode}
            </div>
        )
    }

    const draggerProps = {
        name: 'file',
        multiple: true,
        onRemove: (file) => {
            if (
                !file.originFileObj &&
                !removedIndex?.includes(file?.response)
            ) {
                setRemovedIndex([
                    ...removedIndex,
                    file?.response?.replace(/(\?.*)$/, ''),
                ])
            }
            const index = fileList?.indexOf(file)
            const newFileList = fileList?.slice()
            if (index > -1) {
                newFileList?.splice(index, 1)
            }
            setFileList(newFileList)
        },
        beforeUpload: (file) => {
            const isLt2M = file.size / 1024 / 1024 < 2
            if (!isLt2M) {
                message.error('Image must be smaller than 2MB!')
                return Upload.LIST_IGNORE
            }
            return true
        },
    }

    const disabledDate = (current) => {
        if (!selectedCategoryDetails || !selectedCategoryDetails.is_weekly) {
            return false
        }
        const dayOfWeek = current.day()
        const targetDay = selectedCategoryDetails.day_of_week

        return dayOfWeek !== targetDay || current < moment().startOf('day')
    }

    const handleModalClose = () => {
        setIsModalOpen(false)
        setIsEditing(null)
        handleCancel()
    }

    return (
        <div>
            <Modal
                open={isModalOpen}
                footer={null}
                onCancel={handleModalClose}
                width={950}
                centered
            >
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <div
                        style={{
                            width: '60%',
                            paddingRight: '20px',
                            paddingLeft: '2px',
                        }}
                    >
                        <h2>{isEditing ? 'Edit Magazine' : 'Add Magazine'}</h2>
                        <hr></hr>
                        <Form form={form} onFinish={onFinish}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '0.8vh',
                                }}
                            >
                                <div>
                                    <Form.Item
                                        name="language"
                                        label="Language"
                                        style={{ fontWeight: 550 }}
                                        wrapperCol={{ span: 25 }}
                                        labelCol={{ span: 24 }}
                                    >
                                        <Select
                                            onChange={handleLanguageChange}
                                            placeholder="Select Language"
                                        >
                                            {languageData &&
                                                languageData.map((option) => (
                                                    <Select.Option
                                                        key={option.language_id}
                                                        value={
                                                            option.language_id
                                                        }
                                                    >
                                                        {option.language_name}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item
                                        label={'Category'}
                                        name="category"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please select a category',
                                            },
                                        ]}
                                        style={{ fontWeight: 550 }}
                                        className="form-item"
                                        value={selectedCategory}
                                        wrapperCol={{ span: 25 }}
                                        labelCol={{ span: 24 }}
                                    >
                                        <Select
                                            onChange={handleCategoryChange}
                                            placeholder="Select Category"
                                            className="form-item"
                                            style={{ width: '100%' }}
                                        >
                                            {categoryOptions.map((cat) => {
                                                if (cat?.label !== 'All') {
                                                    return (
                                                        <Option
                                                            key={cat.value}
                                                            value={cat.value}
                                                        >
                                                            {cat.label}
                                                        </Option>
                                                    )
                                                }
                                            })}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item
                                        name="date"
                                        label="Select Date"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Date required',
                                            },
                                        ]}
                                        style={{ fontWeight: 550 }}
                                        wrapperCol={{ span: 25 }}
                                        labelCol={{ span: 24 }}
                                    >
                                        <DatePicker
                                            placeholder="Select date"
                                            style={{ minWidth: '100%' }}
                                            disabledDate={disabledDate}
                                        />
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item
                                        name="title"
                                        label=" Edition No. / Name"
                                        rules={[
                                            {
                                                required:
                                                    title.length === 0
                                                        ? true
                                                        : false,
                                                message: 'Title required',
                                            },
                                        ]}
                                        style={{ fontWeight: 550 }}
                                        wrapperCol={{ span: 25 }}
                                        labelCol={{ span: 24 }}
                                    >
                                        <TextArea
                                            value={title}
                                            maxLength={75}
                                            onChange={(e) =>
                                                handleTitleChange(
                                                    e.target.value
                                                )
                                            }
                                            placeholder="Enter name "
                                        />
                                    </Form.Item>
                                </div>

                                <div>
                                    <Form.Item
                                        name="cardImage"
                                        label="Card Image"
                                        rules={[
                                            {
                                                required:
                                                    fileList.length === 0
                                                        ? true
                                                        : false,
                                                message:
                                                    'Please upload your card image',
                                            },
                                        ]}
                                        style={{ fontWeight: 550 }}
                                        wrapperCol={{ span: 25 }}
                                        labelCol={{ span: 24 }}
                                    >
                                        <DndContext
                                            sensors={[sensor]}
                                            // onDragEnd={onDragEnd}
                                        >
                                            <SortableContext
                                                items={fileList.map(
                                                    (f) => f.uid
                                                )}
                                                strategy={
                                                    verticalListSortingStrategy
                                                }
                                            >
                                                <Dragger
                                                    {...draggerProps}
                                                    listType="picture"
                                                    accept="image/jpeg,image/jpg"
                                                    fileList={fileList}
                                                    onChange={handleChange}
                                                    itemRender={(
                                                        originNode,
                                                        file,
                                                        fileList
                                                    ) => (
                                                        <DraggableUploadListItem
                                                            originNode={
                                                                originNode
                                                            }
                                                            file={file}
                                                            fileList={fileList}
                                                        />
                                                    )}
                                                >
                                                    <p className="ant-upload-drag-icon">
                                                        <CloudUploadOutlined />
                                                    </p>
                                                    <Button>
                                                        Select Your Card
                                                    </Button>
                                                    <div
                                                        style={{
                                                            color: '#199be9',
                                                            marginTop: '5px',
                                                        }}
                                                    >
                                                        JPEG, JPG format only
                                                    </div>
                                                </Dragger>
                                            </SortableContext>
                                        </DndContext>
                                    </Form.Item>
                                </div>
                                <div
                                    style={{
                                        alignSelf: 'center',
                                        marginTop: '5vh',
                                    }}
                                >
                                    <Form.Item>
                                        <Button
                                            disabled={loading}
                                            type="primary"
                                            htmlType="submit"
                                            style={{
                                                paddingLeft: '25px',
                                                paddingRight: '25px',
                                            }}
                                        >
                                            {isEditing ? 'Update' : 'Save'}
                                        </Button>
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                    </div>
                    <div
                        className="preview-column"
                        style={{
                            marginLeft: '10px',
                            marginRight: '20px',
                        }}
                    >
                        <div className="preview-section">
                            <SmartphonePreview
                                title="hello"
                                cardImages={fileList}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default AddMagazineModal

import { message, Input, Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import './Reporter.scss'
import { getReporterTableData } from '../../AadhanApi'
import ExcelSheetUI from './ExcelSheetUI'
import { SelectComponentWithSearch } from '../AntdComponent/SelectComponentWithSearch'
const SrCitizenStringer = () => {
    const { Search } = Input
    const locations = useSelector((state) => state.app.locations)
    const allDistricts = useSelector((state) => state.app.districts)
    const allMandals = useSelector((state) => state.app.mandals)
    const allConstituencies = useSelector((state) => state.app.constituencies)
    const [typeOption, setTypeOption] = useState('all')
    const [state, setState] = useState(undefined)
    const [district, setDistrict] = useState(undefined)
    const [mobile, setMobile] = useState('')
    const [name, setName] = useState('')
    const accessToken = useSelector((state) => state.app.accessToken)
    const languageData = useSelector((state) => state.app.languageData)
    const [dataLoading, setDataLoading] = useState(false)
    const [data, setData] = useState([])
    const [messageApi, contextHolder] = message.useMessage()
    const displayError = (errMessage) => {
        messageApi.open({
            type: 'error',
            content: errMessage,
            duration: 5,
        })
    }
    const handleStateChange = (event) => {
        setDistrict(undefined)
        setState(event)
    }
    const handleDistrictChange =(event)=> {
        setDistrict(event)
    }

    const getReporterTable = async () => {
        setDataLoading(true)

        try {
            const getReporterTableDataResponse = await getReporterTableData(
                typeOption,
                mobile,
                name,
                accessToken,
                state,
                district,
                3
            )
            if (getReporterTableDataResponse?.data) {
                setData(getReporterTableDataResponse?.data)
            }
            setDataLoading(false)
        } catch (err) {
            console.log('error is ::::', err)
            setDataLoading(false)
            if (err?.response?.status === 401) {
                displayError('You are unauthorised, Please login again')
            } else {
                displayError('Failed to get Stringers data')
            }
        }
    }
    // useEffect(() => {
    //     getReporterTable()
    // }, [state, district, typeOption])
    return (
        <div className="stringers_Container">
            <h4>Manage Sr Citizen Stringers</h4>
            <hr></hr>
            <div style={{ display: 'flex' }}>
                <SelectComponentWithSearch
                    data={locations}
                    value={state}
                    onChange={handleStateChange}
                    key_value={'state_id'}
                    display={'name'}
                    style={{marginRight:"1rem",width:170}}
                    placeholder={'Select State'}
                />
                <SelectComponentWithSearch
                    data={allDistricts?.[state]}
                    value={district}
                    onChange={handleDistrictChange}
                    key_value={'district_id'}
                    display={'name'}
                    className={'language_filter'}
                    style={{marginRight:"1rem",width:180}}
                    placeholder={'Select District'}
                />
                <Button type='primary' onClick={()=>getReporterTable()}>Apply</Button>
                <Search
                    className="custom-search"
                    placeholder="search by name"
                    enterButton="Search"
                    size="middle"
                    allowClear
                    onChange={(e) => setName(e.target.value)}
                    onSearch={() => getReporterTable()}
                    value={name}
                    style={{ width: 250 }}
                />

                <Search
                    placeholder="search by mobile number"
                    enterButton="Search"
                    size="middle"
                    allowClear
                    onChange={(e) => {
                        const value = e.target.value.slice(0, 10) // Ensure only 10 digits

                        setMobile(value)
                    }}
                    onSearch={() => getReporterTable()}
                    value={mobile}
                    style={{ width: 250 }}
                    type="number"
                />
            </div>
            <ExcelSheetUI
                allMandals={allMandals}
                dataLoading={dataLoading}
                data={data}
                accessToken={accessToken}
                languageData={languageData}
                locations={locations}
                allDistricts={allDistricts}
                allConstituencies={allConstituencies}
            />
        </div>
    )
}

export default SrCitizenStringer;

import React, { useEffect, useRef, useState } from 'react'
import CommonTable from '../CommonTable/CommonTable'
import './NotificationsNew.scss'
import { Button, Input, Modal, Select, message } from 'antd'
import { NotificationType } from '../App.constants'
import { employeeTypeOptions } from './NotificationsNew.constant'
import { useSelector } from 'react-redux'
import { getNotifications, sendNotificationData } from '../../AadhanApi'
import dayjs from 'dayjs'
import CustomNotificationNew from './CustomNotificationNew/CustomNotificationNew'
import SmartphonePreview from '../SmartphonePreview/SmartphonePreview'

const Notifications = () => {
    const languageData = useSelector((state) => state.app.languageData)
    const { Search } = Input
    const accessToken = useSelector((state) => state.app.accessToken)
    const allStates = useSelector((state) => state.app.locations)
    const allDistricts = useSelector((state) => state.app.districts)
    const [languageOption, setLanguageOption] = useState(
        languageData?.[0]?.language_id
    )
    const [selectedStateId, setSelectedStateId] = useState()
    const [selectedDistrictId, setSelectedDistrictId] = useState()
    const [districts, setDistricts] = useState()

    const [cardOption, setCardOption] = useState(NotificationType?.[0].value)
    const [employeeType, setEmployeeType] = useState(employeeTypeOptions?.[0].value)
    const [notificationData, setNotificationData] = useState([])
    const [dataLoading, setDataLoading] = useState(false)
    const [searchData, setSearchData] = useState('')
    const [messageApi, contextHolder] = message.useMessage()
    const updatedNotificationDataRef = useRef()
    const cardOptionRef = useRef(NotificationType?.[0].value)

    const [showCustomNotificationNewModal, setShowCustomNotificationNewModal] = useState(false)

    const [selectedNotification, setSelectedNotification] = useState()

    const [title, setTitle] = useState(selectedNotification?.title)

    const handleCheckboxChange = (e, record) => {
        const updatedNotificationData =
            updatedNotificationDataRef?.current?.map((data) => {
                if (data?.content_id === record?.content_id) {
                    return { ...data, imageSelected: e?.target.checked }
                }
                return data
            })
        setNotificationData([...updatedNotificationData])
    }

    useEffect(() => {
        // Only update title from selectedNotification when it changes
        if (selectedNotification) {
            setTitle(selectedNotification.title);
        }
    }, [selectedNotification]);

    const childRef = useRef();

    const handleReset = () => {
        childRef.current.resetFieldsValue();
    };

    const onSearch = async (event) => {
        setSearchData(event)
        try {
            const response = await getNotifications(
                event,
                cardOption,
                languageOption,
                1,
                500,
                employeeType,
                accessToken
            )
            if (response?.status === 200 && response?.data !== 'Content not found') {
                const data = response?.data?.map((d) => {
                    return { ...d, imageSelected: true }
                })
                setNotificationData(data)
                updatedNotificationDataRef.current = data
            }
            else {
                messageApi.open({
                    type: 'error',
                    content: response?.data,
                    duration: 5,
                })
                setNotificationData([])
                updatedNotificationDataRef.current = []
            }
            setDataLoading(false)
        } catch (err) {
            console.log(err)
            setNotificationData([])
            setDataLoading(false)
            messageApi.open({
                type: 'error',
                content: err?.response?.data?.detail,
                duration: 5,
            })
        }
    }

    const NotificationColumns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Posted Date',
            dataIndex: 'uploaded_date',
            render: (_, record) =>
                dayjs(record?.uploaded_date).format('DD MMM YYYY, hh:mm A'),
            key: 'uploadedDate',
        },
        {
            title: 'Send',
            render: (_, record) => (
                <Button
                    type="primary"
                    onClick={() => {
                        setShowCustomNotificationNewModal(true)
                        setSelectedNotification(record)
                    }}
                >
                    Send
                </Button>
            ),
            key: 'custom',
        },
    ]

    const onChangeCard = (val) => {
        cardOptionRef.current = val
        setCardOption(val)
    }

    const getTableData = () => {
        getNotificationDetails(cardOption, languageOption, 1, 200)
    }

    const getNotificationDetails = async (
        cardOption,
        languageOption,
        page,
        limit
    ) => {
        setDataLoading(true)
        try {
            const response = await getNotifications(
                searchData,
                cardOption,
                languageOption,
                page,
                limit,
                employeeType,
                accessToken,
                selectedStateId,
                selectedDistrictId,
            )
            if (response?.status === 200 && response?.data !== 'Content not found') {
                const data = response?.data?.map((d) => {
                    return { ...d, imageSelected: true }
                })
                setNotificationData(data)
                updatedNotificationDataRef.current = data
            }
            else {
                messageApi.open({
                    type: 'error',
                    content: response?.data,
                    duration: 5,
                })
                setNotificationData([])
                updatedNotificationDataRef.current = []
            }
            setDataLoading(false)

        } catch (err) {
            console.log(err)
            setNotificationData([])
            setDataLoading(false)
            messageApi.open({
                type: 'error',
                content: err?.response?.data?.detail,
                duration: 5,
            })
        }
    }

    // useEffect(() => {
    //     setTitle(selectedNotification?.title)
    // }, [selectedNotification])

    useEffect(() => {
        if ((cardOption, languageOption)) {
            getNotificationDetails(cardOption, languageOption, 1, 200, selectedStateId, selectedDistrictId)
        }
    }, [cardOption, languageOption, employeeType, selectedStateId, selectedDistrictId])

    const customNotificationNewModal = (
        <Modal
            open={showCustomNotificationNewModal}
            title={'Send Custom Notification'}
            onCancel={() => {
                setShowCustomNotificationNewModal(false)
                // setTitle(selectedNotification?.title)
            }}
            // onOk={() => setShowCustomNotificationNewModal(false)}
            footer={null}
            width={1050}
        >
            <hr />
            <div className='notification-modal'>
                <CustomNotificationNew
                    selectedNotification={selectedNotification}
                    accessToken={accessToken}
                    setShowModal={setShowCustomNotificationNewModal}
                    cardOptionRef={cardOptionRef}
                    getTableData={getTableData}
                    messageApi={messageApi}
                    setDataLoading={setDataLoading}
                    languageData={languageData}
                    allStates={allStates}
                    allDistricts={allDistricts}
                    setShowCustomNotificationNewModal={setShowCustomNotificationNewModal}
                    setTitle={setTitle}
                    title={title}
                    showCustomNotificationNewModal={showCustomNotificationNewModal}
                    ref={childRef}
                />
                <SmartphonePreview
                    image={selectedNotification?.image_url}
                    title={title}
                    description={selectedNotification?.description}
                    cardImages={cardOption==='card' ? selectedNotification?.image_urls : ''}
                />
            </div>
        </Modal>
    )

    const onStateChange = (stateId) => {
        setSelectedStateId(stateId)
        setDistricts(allDistricts[stateId])
        setSelectedDistrictId(undefined)
    }


    return (
        <div className='notification-page-container' style={{padding:"0 1rem"}}>
            <div className="notification-content-container">
                {contextHolder}
                <h3>Manage Notifications</h3>
                <div className="notification-input-container" style={{justifyContent:"space-between"}}>
                    <Search
                        placeholder="Search By Title..."
                        allowClear
                        enterButton="Search"
                        size="middle"
                        onSearch={onSearch}
                        style={{ width: 300 }}
                    />

                    <Select
                        style={{ width: '8rem' }}
                        value={languageOption}
                        onChange={(val) => {
                            setLanguageOption(val)
                        }}
                    >
                        {languageData?.map((option) => (
                            <Select.Option
                                key={option?.language_id}
                                value={option?.language_id}
                            >
                                {option?.language_name}
                            </Select.Option>
                        ))}
                    </Select>

                    <Select
                        value={employeeType}
                        onChange={setEmployeeType}
                    >
                        {employeeTypeOptions.map(option => (
                            <Select.Option value={option.value} key={option.value}>{option.label}</Select.Option>
                        ))}
                    </Select>

                    <Select
                        style={{ width: '10rem' }}
                        onChange={onStateChange}
                        value={selectedStateId}
                        placeholder="Select State"
                        allowClear
                    >
                        {allStates?.map((state) => (
                            <Select.Option value={state?.state_id} key={state.state_id}>
                                {state?.name}
                            </Select.Option>
                        ))}
                    </Select>

                    <Select
                        style={{ width: '10rem' }}
                        onChange={(val) => setSelectedDistrictId(val)}
                        value={selectedDistrictId}
                        placeholder="Select District"
                        name="district"
                        allowClear
                    >
                        {districts?.map((district) => (
                            <Select.Option value={district?.local_name} key={district.district_id}>
                                {district?.name}
                            </Select.Option>
                        ))}
                    </Select>

                    <Select
                        style={{ width: '6rem' }}
                        value={cardOption}
                        onChange={onChangeCard}
                    >
                        {NotificationType.map((option, index) => (
                            <Select.Option key={index} value={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>

                </div >
                <hr style={{ marginBottom: 0 }} />
                <CommonTable
                    data={notificationData}
                    columns={NotificationColumns}
                    dataLoading={dataLoading}
                ></CommonTable>
                {customNotificationNewModal}
            </div>
        </div>
    )
}
export default Notifications

import React, { useEffect, useRef, useState } from 'react'
import '../devotional.scss'
import { Button } from 'antd'
import { CloseOutlined, PlayCircleFilled } from '@ant-design/icons'
import { ArrowLeftIcon } from 'lucide-react'
import { useLocation, useNavigate } from 'react-router'
import EpisodesList from './EpisodesList'
import { getPodcastById } from '../../../AadhanApi'
import { useSelector } from 'react-redux'
import Player from './Player'
import CreateEpisode from './CreateEpisode'

export const PodcastView = ({ languageData, language }) => {
    const navigate = useNavigate()
    const audioRefs = useRef({})
    const [showPlayer, setShowPlayer] = useState(false)
    const accessToken = useSelector((state) => state.app.accessToken)
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get('id')
    const childRef = useRef()
    const [current, setCurrent] = useState(0)
    const onPlay = (index) => {
        setCurrent(null)
    }
    const [pod, setPod] = useState({})
    async function getdata() {
        const res = await getPodcastById(accessToken, id)
        setPod(res?.data)
    }
    useEffect(() => {
        getdata()
    }, [id])
    return (
        <div className="podcast-container">
            <div
                className="podcast-label"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '16px',
                    background: 'rgba(243,243,234,1)',
                }}
            >
                <div
                    style={{
                        width: '60%',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 10,
                    }}
                >
                    <div style={{ position: 'relative' }}>
                        <img
                            src={pod?.image_url}
                            alt={pod?.title}
                            style={{
                                width: '100px',
                                height: '100px',
                                objectFit: 'cover',
                                borderRadius: '8px',
                            }}
                        />
                        <div
                            onClick={() => {
                                Object.values(audioRefs.current).forEach(
                                    (audio) => {
                                        if (audio) audio.pause()
                                    }
                                )
                                setCurrent(0)
                                setShowPlayer(true)
                            }}
                            style={{
                                opacity: 0.5,
                                cursor: 'pointer',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                        >
                            <PlayCircleFilled
                                style={{ color: 'lightgray', fontSize: 35 }}
                            />
                        </div>
                    </div>
                    <div>
                        <h5 style={{ margin: 0 }}>{pod?.title}</h5>
                        <p
                            style={{
                                margin: '4px 0',
                                color: 'gray',
                                fontSize: 14,
                                fontWeight: 500,
                            }}
                        >
                            {pod?.description}
                        </p>
                    </div>
                </div>
                <div style={{ flex: 1, marginRight: '2.2rem' }}>
                    {' '}
                    {pod?.episodes?.length > 0 &&
                        current !== null &&
                        showPlayer && (
                            <Player
                                currentIndex={current}
                                podcast={pod?.episodes}
                                index={0}
                            />
                        )}
                </div>
                <div
                    style={{
                        position: 'absolute',
                        top: '.5rem',
                        right: '.5rem',
                    }}
                >
                    <Button
                        onClick={() => navigate(-1)}
                        type="primary"
                        style={{borderRadius:"10%"}}
                        danger
                        icon={<CloseOutlined style={{ color: 'white' }} />}
                    />
                </div>
                <div
                    style={{
                        position: 'absolute',
                        bottom: '.5rem',
                        right: '.5rem',
                    }}
                >
                    {' '}
                    <CreateEpisode
                        getdata={getdata}
                        data={pod}
                        languageData={languageData}
                        language={language}
                        accessToken={accessToken}
                    />
                </div>
            </div>
            <EpisodesList
                audioRefs={audioRefs}
                getdata={getdata}
                onPlay={onPlay}
                languageData={languageData}
                language={language}
                accessToken={accessToken}
                ref={childRef}
                data={pod}
            />
        </div>
    )
}

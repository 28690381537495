import {
    LoadingOutlined,
    DownloadOutlined,
    LeftOutlined,
} from '@ant-design/icons'
import {
    AutoComplete,
    Button,
    Col,
    DatePicker,
    Form,
    Image,
    Input,
    message,
    Modal,
    Radio,
    Row,
    Select,
    Spin,
    Tag,
} from 'antd'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import {
    getHashtagTableData,
    getImageUrl,
    updateUgcContent,
    getSubCategories,
    getSimilarContent,
    createTransaction,
    getNewsSections,
} from '../../../AadhanApi'
import { expiryHoursOptions } from './ReviewContentConstant'
import './ReviewContentForm.scss'
import { useSelector } from 'react-redux'
import AddSource from '../../Source/AddSource'
import CropperModel from '../CropperModal'
import MuxPlayer from '@mux/mux-player-react'
import ImageUploader from '../../image-uploader-crop/ImageUploader'
import Speaker from '../../Speaker'
import RejectModal from './RejectModal'
import Spellcheck from '../../Spellcheck'
import spellcheckFunction from '../../AIResponseFilter/Title&DescriptionResponseFilter'
import generateKeyWord from '../../AIResponseFilter/KeywordsResponse'
import SmartphonePreview from '../../SmartphonePreview/SmartphonePreview'
import { checkContentForDay } from '../../CheckContentForDay/checkContentForDay'
import {
    addHashtags,
    addNewsSectionDataTemp,
} from '../../../store/slice/AppSlice'
import { useDispatch } from 'react-redux'
import SpellCheckBox from '../../SpellCheckBox'
const ReviewContentForm = ({
    data,
    paymentInfo,
    categoriesData,
    titleChange,
    descriptionChange,
    setImagePath,
    initialFormData,
    languageData,
    categories,
    accessToken,
    contentId,
    setVideoPath,
    hashTagId,
    setHashTagId,
    sourceId,
    setFileList,
    fileList,
    videoList,
    code,
    setSelectedVideoUrl,
    setSelectedImageUrl,
    selectedImageUrl,
    selectedVideoUrl,
    setRejectedVideoUrl,
    setRejectedImageUrl,
    rejectedVideoUrl,
    rejectedImageUrl,
    languageOption,
    setLanguageOption,
}) => {
    const location = useLocation()
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const [amount, setAmount] = useState()
    const allHashTags = useSelector((state) => state.app.hashtags)
    const [subCategory, setSubCategory] = useState([])
    const [showSourceModal, setShowSourceModal] = useState()
    const employeeId = useSelector((state) => state.app.employeeId)
    const roleId = useSelector((state) => state.app.role_id)
    const employeeName = useSelector((state) => state.app.employeeName)
    const [shortDescription, setShortDescription] = useState(
        initialFormData?.shortDescription
    )
    const [selectedCategory, setSelectedCategory] = useState(
        initialFormData?.category_id
    )
    const [rejectionReason, setRejectionReason] = useState('')
    const [showCustomReasonInput, setShowCustomReasonInput] = useState(false)
    const [remainingWordsCount, setRemaingWordsCount] = useState(59)
    const [remainingCharCount, setRemaingCharCount] = useState(400)
    const [imageUrl, setImageUrl] = useState(null)
    const [hashTagOptions, setHashtagOptions] = useState([])
    const rejectionReasonData = useSelector(
        (state) => state.app.rejectedReasonData
    )
    const [allHashTagOptions, setAllHashtagOptions] = useState([])
    const [keywords, setKeywords] = useState([])
    const [keywordInput, setKeywordInput] = useState('')
    const [isImageUploading, setIsImageUploading] = useState(false)
    const [videoPathUrl, setVideoPathUrl] = useState(
        initialFormData?.stream_video_url
    )
    const [reviewTime, setReviewTime] = useState(undefined)
    const [hashData, setHashData] = useState([])
    const [hashTagValue, setHashTagValue] = useState('')
    const navigate = useNavigate()
    const [titleError, setTitleError] = useState()
    const [title, setTitle] = useState('')
    const [fileListError, setFileListError] = useState(false)
    const [maxImageError, setMaxImageError] = useState(false)
    const [englishTitle, setEnglishTitle] = useState('')
    const [selectedImageRadio, setSelectedImageRadio] = useState()
    const [selectedImageRadio1, setSelectedImageRadio1] = useState()
    const [selectedVideoRadio, setSelectedVideoRadio] = useState(null)
    const [imageError, setImageError] = useState(false)
    const [isImageSelected, setIsImageSelected] = useState(false)
    const [selectedFile, setSelectedFile] = useState('')
    const [isDisabled, setIsDisabled] = useState(false)
    const locationsData = useSelector((state) => state.app.locations)
    const allDistricts = useSelector((state) => state.app.districts)
    const allConstituencies = useSelector((state) => state.app.constituencies)
    const allMandals = useSelector((state) => state.app.mandals)
    const [districtsData, setDistrictsData] = useState([])
    const [mandalsData, setMandalsData] = useState([])
    const [isCompensated, setIsCompensated] = useState(false)
    const [isCompModal, setIsCompModal] = useState(false)
    const [selectedMandalId, setSelectedMandalId] = useState(
        initialFormData?.mandal_id
    )
    const [mandalids, setMandalids] = useState([])
    const [selectDistricIds, setSelectedDistricIds] = useState(
        initialFormData?.district_id
    )
    const [selectedAssemblyId, setSelectedAssemblyId] = useState(
        initialFormData?.constituency_ids
    )
    const tokenRegex = /token=([^&]*)/
    const playbackIdRegex = /\/([^\.]*)\.m3u8/
    const [reason, setReason] = useState('')
    const [selectStateId, setSelectStateId] = useState('')
    const [change, setChange] = useState(0)
    const [assemblyConstituenciesData, setAssemblyConstituenciesData] =
        useState([])
    const [generate, setGenerate] = useState(false)
    const [loading, setLoading] = useState(false)
    const [similarContents, setSimilarContents] = useState({
        data: [],
        api: false,
    })
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [spellTitle, setSpellTitle] = useState(false)
    const [spellDescription, setSpellDescription] = useState(false)
    const [AIData, setAiData] = useState({})
    const [keyword_api, setKeyword_api] = useState(false)
    const [amountError, setAmountError] = useState(false)
    useEffect(() => {
        // Get the current UTC timestamp in milliseconds
        const currentTimestampMilliseconds = Date.now()
        const istTimestampMilliseconds = currentTimestampMilliseconds
        const istTimestamp = Math.floor(istTimestampMilliseconds / 1000)
        setReviewTime(istTimestamp)
    }, [location?.pathname])
    useEffect(() => {
        setSelectedAssemblyId(initialFormData?.constituency_ids)
        setSelectedDistricIds(initialFormData?.district_id)
        setSelectedMandalId(initialFormData?.mandal_id)
        setSelectStateId(initialFormData?.state_id)
        setLanguageOption(
            initialFormData?.language_id !== '' ? initialFormData?.language : 1
        )
        onRadioChange({ target: { name: 'image1' } }, 0)
        // onRadioChange({ target: { name: 'video1' } }, 0)
    }, [locationsData, initialFormData, videoPathUrl])
    useEffect(() => {
        setDistrictsData(allDistricts?.[selectStateId])
    }, [selectStateId])
    const handleRejectionChanges = (value) => {
        setShowCustomReasonInput(value === 'Custom Reason')
        setRejectionReason(value)
    }
    const onStateChange = (id) => {
        setIsrun(1)
        form.setFieldsValue({ district: '' })
        form.setFieldValue({ assemblyconstituencies: '' })
        setMandalsData([])
        let ids

        locationsData.map((e) => {
            if (e.name === id) {
                setSelectStateId(e.state_id)
                ids = e.state_id
            }
        })
        if (ids) {
            getAssembly_Constituencies(ids)
        }
    }
    const onDistrictChange = (id) => {
        let temp = []
        districtsData.map((e) => {
            if (id.includes(e.name) && !temp.includes(e.district_id)) {
                temp.push(e.district_id)
            }
        })

        setSelectedDistricIds(temp)
        if (temp.length !== 0) {
            getMandalsList(temp[temp.length - 1])
        }
    }

    const onMandalChange = (id) => {
        let temp = []
        mandalsData.map((e) => {
            if (id.includes(e.name) && !temp.includes(e.mandal_id)) {
                temp.push(e.mandal_id)
            }
        })
        setSelectedMandalId(temp)
    }

    useEffect(() => {
        if (initialFormData?.language === languageOption) {
            handleCategory(initialFormData?.category)
        }
    }, [initialFormData?.language === languageOption, initialFormData])
    const getMandalsList = async (id) => {
        const arr = allDistricts?.[selectStateId]?.filter((e) => {
            if (form.getFieldValue('district').includes(e.name)) {
                return e.district_id
            }
        })

        let temp = []
        arr?.map((e) => {
            temp.push(...allMandals?.[e.district_id])
        })

        setMandalsData(temp)
    }

    const getAssembly_Constituencies = async (id) => {
        setAssemblyConstituenciesData(allConstituencies?.[id])
    }
    const [paymentType, setPaymentType] = useState(undefined)
    const handlePaymentTypeChange = (e) => {
        setPaymentType(e.target.value)
    }
    const handleValidationFailure = ({ errorFields }) => {
        console.error('Validation failed:', errorFields)
    }

    const handleApprove = async (values) => {
        setIsDisabled(true)
        try {
            values['transaction_date'] = dayjs(values?.transaction_date).format(
                'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
            )
            let res = paymentInfo
            if (paymentType === 'debit') {
                res['transaction_details'] = [
                    {
                        content_id: contentId,
                        ...values,
                        approved_by: employeeId,
                        is_image_content:
                            setSelectedVideoUrl.length !== 0 ? false : true,
                    },
                ]
                const response = await createTransaction(res, accessToken)
                if (response?.data?.success) {
                    message.success('Transaction Updated successfully.')
                    setIsDisabled(false)
                    handleSave('approved')
                    setIsCompModal(false)
                } else {
                    message.error('Failed to updated transaction.')
                    setIsDisabled(false)
                }
            } else if (paymentType === 'credit') {
                res['transaction_details'] = [
                    {
                        content_id: contentId,
                        approved_by: employeeId,
                        payment_type: 'credit',
                        transaction_type: 'credit',
                        is_image_content:
                            setSelectedVideoUrl.length !== 0 ? false : true,
                    },
                ]
                const response = await createTransaction(res, accessToken)
                if (response?.data?.success) {
                    message.success('Credit is sufficient for the content.')
                    setIsDisabled(false)
                    handleSave('approved')
                    setIsCompModal(false)
                } else {
                    message.error('Credit limit exceeded. Approval denied.')
                    setIsDisabled(false)
                }
            }
        } catch (error) {
            console.error('Error occurred:', error)
            message.error('An error occurred while processing the transaction.')
        }
    }
    function handleAssemblyConstituencies(event) {
        let temp = []
        assemblyConstituenciesData.map((e) => {
            if (
                event.includes(e.name) &&
                !temp.includes(e.assembly_constituency_id)
            ) {
                temp.push(e.assembly_constituency_id)
            }
        })

        setSelectedAssemblyId(temp)
    }
    const [isrun, setIsrun] = useState(0)
    useEffect(() => {
        if (isrun === 0) {
            locationsData.map((e) => {
                if (initialFormData.state) {
                    if (initialFormData.state.includes(e.name)) {
                        getAssembly_Constituencies(e.state_id).then(() => {})
                    }
                }
            })

            if (initialFormData.district_id) {
                initialFormData.district_id.map((e) => {
                    getMandalsList(e)
                })
            }
        }
    }, [initialFormData, selectDistricIds])

    useEffect(() => {
        locationsData.map((e) => {
            if (initialFormData.state_name) {
                if (initialFormData.state_name.includes(e.name)) {
                    getAssembly_Constituencies(e.state_id).then(() => {
                        handleAssemblyConstituencies([e.state_id])
                    })
                }
            }
        })
    }, [change, locationsData])
    useEffect(() => {
        changeDescriptionValue(initialFormData?.shortDescription ?? '')
        handleTitleChange(initialFormData?.title)
        handleRejectionChange(initialFormData?.rejectionReason)
        handleFindSimilar(
            initialFormData?.title,
            initialFormData?.shortDescription
        )
    }, [initialFormData])

    useEffect(() => {
        form.setFieldsValue(initialFormData)
        setVideoPathUrl(form.getFieldValue('video'))
        if (
            initialFormData?.keywords !== '' &&
            initialFormData?.keywords?.split(',')?.length > 0
        ) {
            setKeywords(initialFormData?.keywords?.split(','))
        }
        if (initialFormData?.state) {
            let filteredState = locationsData
                ?.map((ele) => {
                    if (initialFormData?.state.includes(ele.state_id)) {
                        return ele
                    }
                })
                .filter((Undefined) => Undefined !== undefined)

            let filteredDistrict = []
            filteredState?.forEach((ele) => {
                filteredDistrict.push(ele.districts)
            })
            let splitDistricts = []
            filteredDistrict.forEach((ele, i) => {
                splitDistricts = [...splitDistricts, ...ele]
            })
            setDistrictsData(splitDistricts)
            if (initialFormData?.district) {
                let filteredMandals = []
                splitDistricts?.forEach((ele) => {
                    filteredMandals.push(ele.mandals)
                })

                let splitMandals = []
                filteredMandals.forEach((ele, i) => {
                    splitMandals = [...splitMandals, ...ele]
                })
                setMandalsData(splitMandals)
            }
        }
    }, [form, initialFormData, locationsData])

    useEffect(() => {
        if (initialFormData?.language || languageData?.[0]?.language_id) {
            updateHashtagSource(
                initialFormData?.language !== '' ? languageOption : 1
            )
        }
        setReason('')
        setRejectionReason('')
    }, [languageOption])

    useEffect(() => {
        if (languageOption && categoriesData?.[languageOption] === undefined) {
            getNewsSection()
        }
    }, [languageOption])
    const getNewsSection = async () => {
        const data = await getNewsSections(languageOption, 'all', accessToken)
        dispatch(
            addNewsSectionDataTemp({
                id: languageOption,
                data: data?.data?.data,
            })
        )
    }
    const downloadImage = (imageUrl) => {
        fetch(imageUrl)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = 'image.jpg'
                const event = new MouseEvent('click')
                a.dispatchEvent(event)
                window.URL.revokeObjectURL(url)
            })
            .catch((error) => {
                console.error('Error downloading image:', error)
            })
    }
    const handleDownload = (url) => {
        const link = document.createElement('a')
        link.href = url // Make sure `url` is the actual video file URL
        link.download = 'video.mp4' // Set the file name for the downloaded video
        link.click()
    }
    const createRequest = (values, type) => {
        let categoryName = categories
            ?.map((ele, i) => {
                if (ele.category_id === values.category) {
                    return ele.category_name
                }
            })
            .filter((Undefined) => Undefined !== undefined)?.[0]
        let mandal_ids = []
        mandalids.map((e) => {
            mandal_ids.push(e.id)
        })
        if (mandal_ids === undefined) {
            mandal_ids = []
        }
        let district_ids = []
        for (let i = 0; i < values?.district?.length; i++) {
            for (let j = 0; j < districtsData?.length; j++) {
                if (values?.district[i] === districtsData[j].name) {
                    district_ids.push(districtsData[j].district_id)
                }
            }
        }
        let state_Name = values?.state
        if (!Array.isArray(values?.state)) {
            state_Name = [values?.state]
        }
        const checkforday = checkContentForDay(title, languageOption)
        const resposeObject = {
            title: values?.title
                ?.replace(/\s+/g, ' ') // Replace multiple spaces with a single space
                .replace(
                    /(?<!['"ââ])\s*([,.!?;:])(?!\d|['"ââ])\s*(?=\S)/g,
                    '$1 '
                ) // Add space after punctuation if not preceded by quote and not followed by a digit or quote
                .replace(/(?<!['"ââ])\s*([,.!?;:])(?!\d|['"ââ])$/, '$1') // Remove space before punctuation at the end if not preceded by quote and not followed by a digit or quote
                .replace(/([â¹$])\s*(\d)/g, '$1$2') // Remove space between currency symbol and number
                .replace(/(\d)\s+(\d)/g, '$1$2') // Remove space between digits
                .replace(/(?<!\.)\.\s*(?=\.)/g, '.') // Ensure continuous dots remain intact
                .replace(/(\.{2,})(?=\S)/g, '$1') // Leave continuous dots intact
                .trim(), // Trim leading and trailing spaces
            uploaded_by: initialFormData?.uploaded_by,
            source_id: sourceId,
            source_name: initialFormData?.source_name,
            source_url: values?.sourceLink ?? '',
            category_id: location?.pathname?.includes('tv')
                ? 10
                : values?.category,
            expiry_hours:
                (values?.category === 8) |
                (values?.category === 20) |
                (values?.category === 32)
                    ? 6
                    : 0,
            uploaded_date: initialFormData?.uploaded_date,
            published_date: dayjs(values?.publish).format(
                'YYYY-MM-DDTHH:mm:ss.SSSZ'
            ),
            updated_by: employeeId,
            review_start_time: reviewTime,
            relevancy: values?.relevancies,
            dynamic_url: '',
            is_active: true,
            content_status: type,
            language_id: languageOption,
            description:
                !values?.shortDescription.includes('*') &&
                !values?.shortDescription.includes('**')
                    ? values?.shortDescription
                          ?.replace(/\s+/g, ' ') // Replace multiple spaces with a single space
                          .replace(
                              /(?<!['"ââ])\s*([,.!?;:])(?!\d|['"ââ])\s*(?=\S)/g,
                              '$1 '
                          ) // Add space after punctuation if not preceded by quote and not followed by a digit or quote
                          .replace(
                              /(?<!['"ââ])\s*([,.!?;:])(?!\d|['"ââ])$/,
                              '$1'
                          ) // Remove space before punctuation at the end if not preceded by quote and not followed by a digit or quote
                          .replace(/([â¹$])\s*(\d)/g, '$1$2') // Remove space between currency symbol and number
                          .replace(/(\d)\s+(\d)/g, '$1$2') // Remove space between digits
                          .replace(/(?<!\.)\.\s*(?=\.)/g, '.') // Ensure continuous dots remain intact
                          .replace(/(\.{2,})(?=\S)/g, '$1') // Leave continuous dots intact
                          .trim() // Trim leading and trailing spaces
                    : values?.shortDescription,
            image_url:
                type === 'approved'
                    ? [...selectedImageUrl]
                    : fileList.length > 0
                      ? [fileList[0]]
                      : [],

            rejected_image_urls: [...rejectedImageUrl],
            code,
            yt_video_url: values?.youtubeLink,
            updated_date: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            breaking_news: values?.breakingNews,
            state_name:
                values?.locationSpecific !== 'no' && state_Name[0] !== undefined
                    ? state_Name
                    : [''],

            district:
                values?.locationSpecific !== 'no'
                    ? selectDistricIds?.join(',')
                    : '',
            mandal_name:
                values?.locationSpecific !== 'no' ? values?.mandal : [''],
            district_name:
                values?.locationSpecific !== 'no' ? values?.district : [''],
            mandal:
                values?.locationSpecific !== 'no'
                    ? selectedMandalId?.join(',')
                    : '',
            state: values?.locationSpecific !== 'no' ? selectStateId : '',
            constituency_ids:
                values?.locationSpecific !== 'no' ? selectedAssemblyId : [''],
            constituency_names:
                values?.locationSpecific !== 'no'
                    ? values?.assemblyconstituencies
                    : [''],
            city: '',
            stream_video_url: selectedVideoUrl,
            stringer_type: initialFormData?.stringer_type,
            rejected_stream_video_urls: rejectedVideoUrl,
            stringer_nickname: initialFormData?.nickname,
            mp4_video_url: '',
            image_url_16_9: '',
            image_url_3_4: '',
            thumbnailimage_url: '',
            hashtag_id: hashTagId,
            keywords: keywords?.toString(),
            entities: {},
            iab_category: '',
            // disturbing_visuals: values?.disturbingVisuals,
            left_color: '',
            right_color: '',
            upvote: 0,
            linked_content_id: '',
            rejected_reason: showCustomReasonInput ? reason : rejectionReason,
            is_proof_read: true,
            proof_read_date: dayjs(values?.publish).format(
                'YYYY-MM-DDTHH:mm:ss.SSSZ'
            ),
            proof_read_by: employeeId,
            uploaded_by_name: initialFormData?.uploadedByName || employeeName,
            proof_read_by_name: employeeName,
            category_name: location?.pathname?.includes('tv')
                ? 'à°ªà±à°°à°¾à°à°¤à±à°¯à°'
                : categoryName,
            unix_uploaded_date: initialFormData?.unix_uploaded_date,
            unix_expiry_date: initialFormData?.unix_expiry_date,
        }
        if (checkforday?.today || checkforday?.tomorrow) {
            resposeObject['expiry_hours'] = checkforday?.expiry
        }
        if (
            location?.pathname?.includes('home/reviewContent') ||
            location?.pathname?.includes('/home/ugContent')
        ) {
            resposeObject['assigned_at'] = initialFormData?.assigned_at
            resposeObject['assigned_to'] = initialFormData?.assigned_to
        }
        if (
            location?.pathname?.includes(
                '/home/editorDashboard/sponsored-content'
            )
        ) {
            resposeObject['is_sponsored'] = true
        }
        if (location?.pathname?.includes('tv') && amount) {
            resposeObject['compensation_amount'] = parseInt(amount)
        }
        if (values?.gender) {
            resposeObject['gender'] = values?.gender
        }
        if (!subCategory.find((item) => item?.pro === true)) {
            resposeObject['sub_category_id'] = null

            resposeObject['sub_category_name'] = null
        } else {
            if (!subCategory.find((item) => item?.pro === true)) {
                resposeObject['sub_category_id'] = null

                resposeObject['sub_category_name'] = null
            } else {
                const selectedSubcategory = subCategory.find(
                    (item) => item?.pro === true
                )
                resposeObject['sub_category_id'] = selectedSubcategory
                    ? selectedSubcategory.sub_category_id
                    : ''

                resposeObject['sub_category_name'] =
                    selectedSubcategory.sub_category_name
                        ? selectedSubcategory?.sub_category_name
                        : ''
            }
        }
        if (!subCategory.find((item) => item?.anti === true)) {
            resposeObject['anti_sub_category_id'] = null

            resposeObject['anti_sub_category_name'] = null
            return resposeObject
        } else {
            if (!subCategory.find((item) => item?.anti === true)) {
                resposeObject['anti_sub_category_id'] = null

                resposeObject['anti_sub_category_name'] = null
                return resposeObject
            } else {
                const selectedSubcategory = subCategory.find(
                    (item) => item?.anti === true
                )
                resposeObject['anti_sub_category_id'] = selectedSubcategory
                    ? selectedSubcategory.sub_category_id
                    : ''

                resposeObject['anti_sub_category_name'] =
                    selectedSubcategory.sub_category_name
                        ? selectedSubcategory?.sub_category_name
                        : ''

                return resposeObject
            }
        }
    }
    useEffect(() => {
        let arr = []
        const temp1 = initialFormData?.mandal_id

        const name = initialFormData?.mandal

        temp1?.map((e, index) => {
            arr.push({ name: name[index], id: e })
        })
        setMandalids(arr)

        let arr1 = []
        const temp2 = initialFormData?.district_id

        const name_ = initialFormData?.district

        temp2?.map((e, index) => {
            arr1.push({ name: name_[index], id: e })
        })
    }, [initialFormData])
    const shouldRenderSubcategory =
        selectedCategory &&
        roleId !== 5 &&
        roleId !== 3 &&
        subCategory?.length > 0 &&
        form.getFieldValue('category') !== undefined

    const handleSave = async (type) => {
        try {
            if (type !== 'rejected') {
                const validation = await form.validateFields([
                    'title',
                    'video',
                    'language',
                    'category',
                    'expiryHours',
                    'thumbnailImage',
                    'proofRead',
                    'state',
                ])
                if (validation?.errorFields?.length > 0) {
                    return
                }
            }
        } catch (e) {
            e?.errorFields?.length &&
                e.errorFields.forEach((ele) => {
                    if (
                        ele?.name === 'title' ||
                        ele?.name === 'category' ||
                        ele?.name === 'video' ||
                        ele?.name === 'title'
                    ) {
                        window.scrollTo(0, 0)
                    }
                })
            return
        }
        if (type === 'approved' && !selectedImageUrl.length) {
            setImageError(true)
            if (languageOption !== 1 && !englishTitle.length) {
            }
            return
        } else if (
            type === 'rejected' &&
            !rejectionReason?.length &&
            !reason?.length
        ) {
            setImageError(false)

            return
        }

        setIsDisabled(true)

        const request = createRequest({ ...form.getFieldsValue() }, type)
        if (keywords.length === 0 && type !== 'rejected') {
            // const res = await generateKeyWords(title, shortDescription)
            // if (res.length === 0) {
            //     setIsDisabled(false)
            //     return
            // }
            // request.keywords = res?.join(',')
        }

        if (initialFormData?.created_type === 'reporter') {
            request.state = data?.state
            request.state_name = data?.state_name
            request.district = data?.district
            request.district_name = data?.district_name
            request.mandal = data?.mandal
            request.mandal_name = data?.mandal_name
            request.constituency_ids = data?.constituency_ids
            request.constituency_names = data?.constituency_names
            request.hashtag_id = data?.hashTagId
        }
        if (type === 'rejected') {
            request.category_id = 0
        }

        const ugcContentResponse = await updateUgcContent(
            request,
            contentId,
            accessToken
        )
        if (ugcContentResponse?.status === 200) {
            if (location?.pathname?.includes('home/reviewContent')) {
                if (type === 'rejected' || type === 'approved') {
                    setTimeout(() => {
                        navigate('/home/review')
                    }, 1500) // Delay is 2000 milliseconds (2 seconds)
                    message.success(
                        type === 'rejected'
                            ? 'Content Rejected'
                            : 'UG content Approved successfully'
                    )
                    return
                }
            }
            if (location?.pathname.includes('/home/tv')) {
                message.success('TV content updated successfully')
                navigate('/home/tv')
            }
            if (location?.pathname.includes('/home/ug')) {
                message.success('UG content updated successfully')
                navigate('/home/ug')
            }
            if (
                location?.pathname.includes(
                    '/home/editorDashboard/sponsored-content'
                )
            ) {
                message.success('Sponsored content updated successfully')
                navigate('/home/editorDashboard/sponsored-content')
            }
        } else if (
            ugcContentResponse?.data ===
            'UG Content found but no changes were made'
        ) {
        }
    }

    useEffect(() => {
        if (hashTagId) {
            setHashTagValue(
                hashData?.filter((h) => h.hashtag_id === hashTagId)?.[0]
                    ?.hashtag_name ?? ''
            )
        }
    }, [hashData])

    const onFinish = async (values) => {
        const request = createRequest(values)
        request.content_status = 'approved'

        if (true) {
            request.proof_read_by = employeeId
            request.proof_read_date = dayjs(values?.publish).format(
                'YYYY-MM-DDTHH:mm:ss.SSSZ'
            )
            request.is_proof_read = true
        }

        const ugcContentReponse = await updateUgcContent(
            request,
            contentId,
            accessToken
        )
        if (ugcContentReponse?.status === 200) {
            navigate('/home/ug')
        }
    }

    const updateHashtagSource = async () => {
        if (languageOption) {
            if (allHashTags?.[languageOption] === undefined) {
                const hashtagDataResponse = await getHashtagTableData(
                    languageOption,
                    '',
                    accessToken
                )
                dispatch(
                    addHashtags({
                        id: languageOption,
                        data: hashtagDataResponse?.data,
                    })
                )
                setHashData(hashtagDataResponse?.data)

                setHashtagOptions(
                    hashtagDataResponse?.data?.map((option) => ({
                        value: option.hashtag_name,
                    })) ?? []
                )
                setAllHashtagOptions(
                    hashtagDataResponse?.data?.map((option) => ({
                        value: option.hashtag_name,
                    })) ?? []
                )
            } else {
                setHashData(allHashTags?.[languageOption])
                setHashtagOptions(
                    allHashTags?.[languageOption]?.map((option) => ({
                        value: option.hashtag_name,
                    })) ?? []
                )
                setAllHashtagOptions(
                    allHashTags?.[languageOption]?.map((option) => ({
                        value: option.hashtag_name,
                    })) ?? []
                )
            }
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    const onHashTagSelect = (val) => {
        setHashTagValue(val)
        setHashTagId(
            hashData?.filter((h) => h.hashtag_name === val)?.[0]?.hashtag_id
        )
    }
    const getRemainingWords = (value, type, max) => {
        if (type === 'words') {
            const remaingWords = max - value?.split(' ')?.length
            return remaingWords
        } else {
            const remainingChar = max - value.length
            return remainingChar
        }
    }

    const changeDescriptionValue = (descriptionValue) => {
        // Clean up the description value
        let cleanedValue = descriptionValue
        if (cleanedValue?.length && languageOption === 2) {
            // document.getElementById("desc-checker").innerHTML = cleanedValue
        }
        if (cleanedValue?.split('\n')?.length <= 9) {
            form.setFieldValue('shortDescription', cleanedValue)
            const remainingWords = getRemainingWords(
                cleanedValue,
                'words',
                languageOption === 3 ? 70 : 60
            )
            const remainingChars = getRemainingWords(
                cleanedValue,
                'char',
                languageOption === 3 ? 500 : 400
            )
            if (remainingWords > -1 && remainingChars > -1) {
                form.setFieldValue('shortDescription', cleanedValue)
                setShortDescription(cleanedValue)
                setRemaingCharCount(remainingChars)
                setRemaingWordsCount(remainingWords)
            } else if (cleanedValue === '') {
                setRemaingCharCount(languageOption === 3 ? 500 : 400)
                setRemaingWordsCount(languageOption === 3 ? 70 : 60)
            } else {
                if (remainingWords < 0) {
                    const newDescription = cleanedValue
                        .split(' ')
                        .slice(0, remainingWords)
                        .join(' ')
                    changeDescriptionValue(newDescription)
                } else if (remainingChars < 0) {
                    const newDescription = cleanedValue
                        .split('')
                        .slice(0, remainingChars)
                        .join('')
                    changeDescriptionValue(newDescription)
                }
            }
        }
    }

    const handleClose = (index) => {
        const key = [...keywords]
        key.splice(index, 1)
        setKeywords(key)
    }

    const addKeyword = (keyword) => {
        setKeywords([...keywords, keyword])
    }
    const getImagePath_ = async (path) => {
        setIsImageUploading(true)
        setSelectedImageRadio1(selectedImageRadio)
        const pathUri = await getImageUrl(
            path,
            'shortnews',
            accessToken,
            languageOption
        )
        if (pathUri?.data) {
            setMaxImageError(false)
            let url = pathUri?.data
            // const logoadded =await addLogo(url,'https://static.aadhan.in/mainImages/aadhan_white_logo.png' ,accessToken ,languageOption)
            const updatedFileList = [...fileList, url]

            setFileList(updatedFileList)
            setIsImageUploading(false)
        }
    }
    const handleYesClick = () => {
        setIsCompensated(true)
    }

    const handleNoClick = () => {
        handleSave('approved')
        setIsCompModal(false)
    }
    const handleFindSimilar = async (title, shortDescription) => {
        setLoading(true)
        if (!initialFormData?.title || initialFormData.title.trim() === '') {
            setLoading(false)
            return
        }

        try {
            const response = await getSimilarContent(
                languageOption,
                `${title}`,
                accessToken
            )
            setSimilarContents({ data: response?.data?.data, api: true })
        } catch (error) {
            console.error('Error fetching similar content:', error)
        } finally {
            setLoading(false)
        }
    }
    const getImagePath = async (path) => {
        setIsImageUploading(true)
        setSelectedImageRadio1(selectedImageRadio)
        const pathUri = await getImageUrl(
            path,
            'shortnews',
            accessToken,
            languageOption
        )

        if (pathUri?.data) {
            setMaxImageError(false)
            let url = pathUri?.data

            const updatedFileList = [...fileList]
            updatedFileList[selectedImageRadio] = url
            let imageList = updatedFileList.slice(0)
            const removedElement = imageList.splice(selectedImageRadio, 1)
            setSelectedImageUrl(removedElement)
            setRejectedImageUrl(imageList)
            setImagePath(url)
            setFileList(updatedFileList)
            setIsImageUploading(false)
            setIsImageSelected(false)
        }
    }

    // const getVideoPath = async (path) => {
    //     if (path && path?.size / (1024 * 1024) <= 50) {
    //         setIsVideoUploading(true)
    //         const pathUri = await getVideoUrl(path, accessToken)

    //         setMaxVideoError(false)
    //         if (pathUri?.data) {
    //             setVideoPath(pathUri?.data)
    //             setVideoPathUrl(pathUri?.data)

    //             setVideoList([...videoList, pathUri?.data])

    //             setIsVideoUploading(false)
    //         }
    //     } else {
    //         alert('Video Size should be less than or Equal to 50Mb')
    //         inputVideoRef.current.value = null
    //     }
    // }

    const handleTitleChange = (value) => {
        if (value) {
            let cleanedValue = value
            setTitle(cleanedValue)
            form.setFieldValue('title', cleanedValue)
            setTitleError(cleanedValue.length > 75)
        } else {
            setTitle('')
            form.setFieldValue('title', '')
            setTitleError(false)
        }
    }

    const handleRejectionChange = (value) => {
        form.setFieldValue('rejectionReason', value)
        setRejectionReason(value)
    }
    async function handleCategory(e) {
        setSelectedCategory(null)
        if (e !== null && e !== undefined) {
            setSelectedCategory(e)
            await getSubCategories(accessToken, languageOption, e).then(
                (resp) => {
                    let updatedSubCategories = resp?.data?.data
                    if (
                        e === 11 &&
                        initialFormData?.state_id !== undefined &&
                        initialFormData?.state_id
                    ) {
                        updatedSubCategories = updatedSubCategories?.filter(
                            (e) => e.state_id === initialFormData?.state_id
                        )
                    }
                    const data = updatedSubCategories?.map((subCat) => ({
                        ...subCat,
                        anti: false,
                        pro: false,
                    }))
                    setSubCategory(data)
                }
            )
        } else {
            setSelectedCategory(e)
        }
    }
    const updateProStatus = (id) => {
        setSubCategory((prevSubCategory) =>
            prevSubCategory.map((subCat) =>
                subCat.sub_category_id === parseInt(id)
                    ? { ...subCat, pro: !subCat?.pro, anti: false }
                    : { ...subCat, pro: false }
            )
        )
    }

    const updateAntiStatus = (id) => {
        setSubCategory((prevSubCategory) =>
            prevSubCategory.map((subCat) =>
                subCat.sub_category_id === parseInt(id)
                    ? { ...subCat, anti: !subCat?.anti, pro: false }
                    : { ...subCat, anti: false }
            )
        )
    }

    const updateSources = () => {
        updateHashtagSource(languageOption)
    }

    const sourceModal = (
        <Modal
            open={showSourceModal}
            title={'Add Source'}
            onCancel={() => setShowSourceModal(false)}
            footer={[
                <Button
                    form="sourceForm"
                    key="submit"
                    htmlType="submit"
                    type="primary"
                >
                    Submit
                </Button>,
            ]}
        >
            <AddSource
                getSourceTable={updateSources}
                accessToken={accessToken}
                selectedLanguage={languageOption}
                setShowModal={setShowSourceModal}
            />
        </Modal>
    )
    const onRadioChangeVideo = (event, index, url) => {
        if (selectedVideoRadio === index) {
            setSelectedVideoRadio(null)
            setSelectedVideoUrl([])
            setRejectedVideoUrl(videoList)
        } else {
            setSelectedVideoUrl([url])

            setSelectedVideoRadio(index)
            setVideoPath([url])
            const removedElement = videoList.filter((e, ind) => ind !== index)
            setRejectedVideoUrl(removedElement)
        }
    }

    const onRadioChange = (event, index) => {
        if (event.target.name === 'image1') {
            setSelectedImageRadio1(index)
            setSelectedImageRadio(index)
            let updatedFileList = [...fileList]
            let imageList = updatedFileList.slice(0)
            let selectedEle = imageList.splice(index, 1)
            setSelectedFile(selectedEle)
            setSelectedImageUrl(selectedEle)
        }
    }
    const [spellCheckLoader, setSpellCheckLoader] = useState(false)
    async function spellCheck(title, description) {
        if (title !== '' || description !== '') {
            setSpellCheckLoader(true)
            const data = await spellcheckFunction(title, description)
            setSpellCheckLoader(false)
            setAiData(data)
        }
    }
    async function generateKeyWords(title, description) {
        if (title !== '' || description !== '') {
            setGenerate(true)
            const data = await generateKeyWord(
                title,
                description,
                languageOption
            )
            const keywordsArray = initialFormData?.keywords
                ? initialFormData?.keywords?.split(',')
                : []
            const newKeywords = data?.keywords?.split(',') ?? []
            const uniqueArray = [
                ...new Set([
                    ...keywordsArray,
                    ...newKeywords?.map((keyword) => keyword?.trim()), // Trim each keyword
                ]),
            ]
            const cleanKeywords = uniqueArray?.filter(
                (keyword) => keyword !== '' && !keyword?.includes('\n')
            )
            setKeywords(cleanKeywords)
            if (cleanKeywords.length === 0) {
                message.error('unable generate keywords try manually')
            }
            setGenerate(false)
            setAiData(cleanKeywords.length === 0 ? false : true)
            setKeyword_api(true)
            return cleanKeywords
        }
    }
    return (
        <div
            style={{
                position: 'relative',
                minHeight: '75vh',
                marginTop: location?.pathname?.includes('home/tvContent')
                    ? 20
                    : '',
            }}
        >
            {generate || isDisabled ? (
                <Spin className="ug_loading" size="large"></Spin>
            ) : (
                <></>
            )}
            <div
                id="form"
                style={{
                    width: '100%',
                    opacity: generate || isDisabled ? '.6' : '',
                }}
            >
                <Form
                    form={form}
                    name="basic"
                    colon={false}
                    scrollToFirstError
                    labelWrap
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 18 }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Row gutter={16}>
                        <Col
                            xs={24}
                            md={location?.pathname?.includes('tv') ? '' : 14}
                        >
                            {!location?.pathname?.includes('tv') && (
                                <Form.Item
                                    label="Category"
                                    name="category"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter value',
                                        },
                                    ]}
                                >
                                    <Select
                                        onChange={(e) => handleCategory(e)}
                                        placeholder="--Select Category--"
                                    >
                                        {categories?.map((option) => {
                                            return (
                                                <Select.Option
                                                    key={option?.category_id}
                                                    value={option?.category_id}
                                                >
                                                    <div>
                                                        {option?.category_name}
                                                        <span
                                                            style={{
                                                                color: 'gray',
                                                            }}
                                                        >{` ( ${option?.display_name} )`}</span>
                                                    </div>
                                                </Select.Option>
                                            )
                                        })}
                                    </Select>
                                </Form.Item>
                            )}
                            {shouldRenderSubcategory ? (
                                <Form.Item name="subcategory" label=" ">
                                    {subCategory?.map((subcat) => {
                                        return (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    alignItems: 'center',
                                                    margin: '1% 0 1% 0',
                                                }}
                                                key={subcat.sub_category_id}
                                            >
                                                <span
                                                    style={{
                                                        marginRight: '1rem',
                                                        maxWidth: '10rem',
                                                    }}
                                                >
                                                    {subcat.sub_category_name}
                                                </span>
                                                <div
                                                    style={{
                                                        diplay: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Radio
                                                        onClick={(e) =>
                                                            updateProStatus(
                                                                e.target.value
                                                            )
                                                        }
                                                        checked={subcat.pro}
                                                        type="radio"
                                                        name="pro"
                                                        value={
                                                            subcat.sub_category_id
                                                        }
                                                    />
                                                    <label
                                                        style={{
                                                            margin: '0 .5rem 0 0',
                                                            color: 'gray',
                                                        }}
                                                    >
                                                        pro
                                                    </label>

                                                    <Radio
                                                        onClick={(e) =>
                                                            updateAntiStatus(
                                                                e.target.value
                                                            )
                                                        }
                                                        checked={subcat.anti}
                                                        type="radio"
                                                        name="anti"
                                                        value={
                                                            subcat.sub_category_id
                                                        }
                                                    />
                                                    <label
                                                        style={{
                                                            margin: '0 0 0 0',
                                                            color: 'gray',
                                                        }}
                                                    >
                                                        anti
                                                    </label>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </Form.Item>
                            ) : null}

                            <Form.Item
                                label="Title"
                                name="title"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Value is required and should be less than 75 characters',
                                    },
                                ]}
                            >
                                {!spellTitle ? (
                                    <div
                                        style={{
                                            position: 'relative',
                                            width: location?.pathname?.includes(
                                                'tv'
                                            )
                                                ? '80%'
                                                : '',
                                        }}
                                    >
                                        {location?.pathname?.includes('tv') ? (
                                            <Input.TextArea
                                                className={
                                                    titleError ? 'error' : ''
                                                }
                                                disabled
                                                style={
                                                    location?.pathname?.includes(
                                                        'tv'
                                                    )
                                                        ? {
                                                              backgroundColor:
                                                                  'white',
                                                              color: 'gray',
                                                          }
                                                        : {}
                                                }
                                                maxLength={75}
                                                value={title}
                                                onChange={(e) => {
                                                    titleChange(e.target.value)
                                                    handleTitleChange(
                                                        e.target.value
                                                    )
                                                }}
                                                placeholder="Title of the article (max 75 chars) in regional language (ex:tamil,telugu)"
                                            />
                                        ) : (
                                            <SpellCheckBox
                                                minHeight={'3rem'}
                                                length={75}
                                                onChange={(e) => {
                                                    titleChange(e.target.value)
                                                    handleTitleChange(
                                                        e.target.value
                                                    )
                                                }}
                                                languageOption={languageOption}
                                                text={title}
                                                placeholder={
                                                    'Title of the article (max 75 chars) in regional language (ex:tamil,telugu)'
                                                }
                                            />
                                        )}
                                        <span
                                            style={{
                                                position: 'absolute',
                                                marginLeft: '4px',
                                                bottom: 6,
                                                right: -40,
                                            }}
                                        >
                                            <Speaker
                                                text={title}
                                                language={
                                                    languageOption === 1
                                                        ? 'en-US'
                                                        : languageOption === 2
                                                          ? 'te'
                                                          : languageOption === 4
                                                            ? 'ta-IN'
                                                            : 'hi-IN'
                                                }
                                            />
                                        </span>
                                    </div>
                                ) : (
                                    <Spellcheck
                                        generate={spellCheckLoader}
                                        setSpellDescription={setSpellTitle}
                                        textValue={title}
                                        setTextValue={setTitle}
                                        wordsToHighlight={
                                            AIData?.misspelledTitleWords
                                                ? AIData?.misspelledTitleWords
                                                : []
                                        }
                                        correctedWords={
                                            AIData?.correctedTitleWords
                                                ? AIData?.correctedTitleWords
                                                : []
                                        }
                                    />
                                )}
                                {!location?.pathname?.includes('tv') && (
                                    <span
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                        className="description-subtext"
                                    >
                                        <span
                                            style={{
                                                color:
                                                    title?.length > 75
                                                        ? 'red'
                                                        : '#555555',
                                                fontSize: '14px',
                                                marginLeft: '10px',
                                            }}
                                        >
                                            {75 - (title?.length ?? 0)}{' '}
                                            characters
                                            {title?.length >= 75 && (
                                                <span
                                                    style={{
                                                        color: 'red',
                                                        fontStyle: 'italic',
                                                    }}
                                                >
                                                    {' '}
                                                    - Title is too long
                                                </span>
                                            )}
                                        </span>
                                    </span>
                                )}
                            </Form.Item>

                            <Form.Item
                                label="Short Description"
                                name="shortDescription"
                                rules={[
                                    {
                                        required: true,
                                        message: 'please input description',
                                    },
                                ]}
                            >
                                {!spellDescription ? (
                                    <div
                                        style={{
                                            position: 'relative',
                                            width: location?.pathname?.includes(
                                                'tv'
                                            )
                                                ? '80%'
                                                : '',
                                        }}
                                    >
                                        {location?.pathname?.includes('tv') ? (
                                            <Input.TextArea
                                                value={shortDescription}
                                                rows={2}
                                                autoSize
                                                disabled
                                                style={
                                                    location?.pathname?.includes(
                                                        'tv'
                                                    )
                                                        ? {
                                                              backgroundColor:
                                                                  'white',
                                                              color: 'gray',
                                                              minHeight: '5rem',
                                                          }
                                                        : {}
                                                }
                                                onChange={(e) => {
                                                    descriptionChange(
                                                        e.target.value
                                                    )
                                                    changeDescriptionValue(
                                                        e.target.value
                                                    )
                                                }}
                                                placeholder="Upto 60 words"
                                            />
                                        ) : (
                                            <SpellCheckBox
                                                mode={'review'}
                                                location={location?.pathname}
                                                minHeight={'5rem'}
                                                length={
                                                    languageOption === 3
                                                        ? 500
                                                        : 400
                                                }
                                                onChange={(e) => {
                                                    descriptionChange(
                                                        e.target.value
                                                    )
                                                    changeDescriptionValue(
                                                        e.target.value
                                                    )
                                                }}
                                                languageOption={languageOption}
                                                text={shortDescription}
                                                placeholder={
                                                    languageOption === 3
                                                        ? 'Up to 70 words'
                                                        : 'Upto 60 words'
                                                }
                                            />
                                        )}
                                        <span
                                            style={{
                                                position: 'absolute',
                                                marginLeft: '4px',
                                                bottom: 6,
                                                right: -40,
                                            }}
                                        >
                                            <Speaker
                                                text={shortDescription}
                                                language={
                                                    languageOption === 1
                                                        ? 'en-US'
                                                        : languageOption === 2
                                                          ? 'te'
                                                          : languageOption === 4
                                                            ? 'ta-IN'
                                                            : 'hi-IN'
                                                }
                                            />
                                        </span>
                                    </div>
                                ) : (
                                    <Spellcheck
                                        generate={spellCheckLoader}
                                        mode={'review'}
                                        location={location?.pathname}
                                        setSpellDescription={
                                            setSpellDescription
                                        }
                                        textValue={shortDescription}
                                        setTextValue={setShortDescription}
                                        wordsToHighlight={
                                            AIData?.misspelledDescription
                                                ? AIData?.misspelledDescription
                                                : []
                                        }
                                        correctedWords={
                                            AIData?.correctedDescription
                                                ? AIData?.correctedDescription
                                                : []
                                        }
                                        type={'description'}
                                    />
                                )}
                                {!location?.pathname?.includes('tv') && (
                                    <span className="description-subtext">
                                        <span
                                            style={{
                                                marginLeft: '10px',
                                                color:
                                                    remainingWordsCount === 0
                                                        ? 'red'
                                                        : '#555555',
                                            }}
                                        >
                                            {' '}
                                            {remainingWordsCount} words
                                        </span>
                                        |
                                        <span
                                            style={{
                                                color:
                                                    remainingCharCount === 0
                                                        ? 'red'
                                                        : '#555555',
                                            }}
                                        >
                                            {remainingCharCount} characters
                                        </span>
                                        |
                                        <span
                                            style={{
                                                color:
                                                    remainingCharCount === 0
                                                        ? 'red'
                                                        : '#555555',
                                            }}
                                        >
                                            max 9 line only
                                        </span>
                                        {(remainingWordsCount === 0 ||
                                            remainingCharCount === 0) && (
                                            <div
                                                className="description-subtext"
                                                style={{
                                                    color: 'red',
                                                    marginRight: 'auto',
                                                    fontStyle: 'italic',
                                                }}
                                            >
                                                {' '}
                                                - {'Description is too long'}
                                            </div>
                                        )}
                                    </span>
                                )}
                            </Form.Item>

                            {!location?.pathname?.includes('tv') && (
                                <Form.Item label={'Similar content'}>
                                    <div className="similar-content-wrapper">
                                        <div className="get-similar-btn">
                                            {loading && (
                                                <div className="loading-spinner">
                                                    <Spin size="small" />
                                                </div>
                                            )}
                                        </div>

                                        {similarContents?.data?.length === 0 &&
                                            !loading &&
                                            similarContents?.api && (
                                                <div className="no-content-message">
                                                    No similar content found
                                                </div>
                                            )}
                                        {similarContents?.data?.length > 0 &&
                                            !loading && (
                                                <div>
                                                    {similarContents?.data?.map(
                                                        (e) => (
                                                            <div
                                                                key={
                                                                    e?.dynamic_url
                                                                }
                                                                style={{
                                                                    fontSize:
                                                                        '12px',
                                                                    marginBottom:
                                                                        '.5rem',
                                                                }}
                                                            >
                                                                <a
                                                                    href={
                                                                        e?.dynamic_url
                                                                    }
                                                                    target="blank"
                                                                    style={{
                                                                        fontSize: 12,
                                                                    }}
                                                                >
                                                                    {e?.title}
                                                                </a>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            )}
                                    </div>
                                </Form.Item>
                            )}

                            {initialFormData?.created_type !== 'reporter' && (
                                <Form.Item label="Gender" name="gender">
                                    <Radio.Group>
                                        <Radio value={'male'}> Male </Radio>
                                        <Radio value={'female'}> Female </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            )}
                            <Form.Item
                                noStyle
                                shouldUpdate={(prevValues, currentValues) =>
                                    prevValues.breakingNews !==
                                    currentValues.breakingNews
                                }
                            >
                                {false ? (
                                    <Form.Item
                                        name="expiryHours"
                                        label="Expiry Hours"
                                        rules={[{ required: true }]}
                                    >
                                        <Select style={{ width: 200 }}>
                                            {expiryHoursOptions?.map(
                                                (option, index) => (
                                                    <Select.Option
                                                        key={index}
                                                        value={option}
                                                    >
                                                        {option}
                                                    </Select.Option>
                                                )
                                            )}
                                        </Select>
                                    </Form.Item>
                                ) : null}
                            </Form.Item>
                            {((keyword_api && keywords.length === 0) ||
                                !AIData) && (
                                <Form.Item
                                    label="Keywords"
                                    name="keywords"
                                    required={true}
                                >
                                    <div
                                        className="col-md"
                                        style={{ background: 'none' }}
                                    >
                                        {keywords !== null &&
                                            keywords?.map((keyword, index) => (
                                                <Tag
                                                    key={index}
                                                    closable
                                                    style={{
                                                        userSelect: 'none',
                                                        marginBottom: '5px',
                                                    }}
                                                    onClose={(e) => {
                                                        e.preventDefault()
                                                        handleClose(index)
                                                    }}
                                                >
                                                    <span>{keyword}</span>
                                                </Tag>
                                            ))}
                                        <Input
                                            onChange={(e) =>
                                                setKeywordInput(e.target.value)
                                            }
                                            value={keywordInput}
                                            onKeyPress={(event) => {
                                                if (event.key === 'Enter') {
                                                    event.preventDefault()
                                                    addKeyword(
                                                        event?.target?.value
                                                    )
                                                    setKeywordInput('')
                                                }
                                            }}
                                            placeholder="Type words and press enter after each word"
                                        />

                                        {keywords?.length <= 0 && (
                                            <div>
                                                {generate ? (
                                                    <Button
                                                        style={{
                                                            margin: '.5rem 0',
                                                        }}
                                                        loading
                                                    >
                                                        Generating...
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        type="primary"
                                                        ghost
                                                        style={{
                                                            margin: '.5rem 0',
                                                        }}
                                                        onClick={() => {
                                                            generateKeyWords(
                                                                title,
                                                                shortDescription
                                                            )
                                                        }}
                                                    >
                                                        Generate Keywords
                                                    </Button>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </Form.Item>
                            )}
                            <div
                                style={{
                                    display: 'flex',
                                }}
                            >
                                <div className="image-container">
                                    {fileList?.length === 0 && (
                                        <div className="image-loading">
                                            <LoadingOutlined />
                                        </div>
                                    )}
                                    {fileList?.length > 0 &&
                                        fileList?.map((ele, index) => {
                                            return (
                                                <div
                                                    className="image-block"
                                                    key={ele}
                                                    style={{
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <div className="image-item">
                                                        <Radio
                                                            name="image1"
                                                            style={{
                                                                zIndex: '1',
                                                            }}
                                                            onClick={(event) =>
                                                                onRadioChange(
                                                                    event,
                                                                    index
                                                                )
                                                            }
                                                            checked={
                                                                selectedImageRadio1 ===
                                                                index
                                                            }
                                                        ></Radio>

                                                        <img
                                                            style={{
                                                                width: '100%',
                                                                position:
                                                                    'absolute',
                                                                objectFit:
                                                                    'cover',
                                                            }}
                                                            src={ele}
                                                        />
                                                        {imageUrl && (
                                                            <Image
                                                                src={imageUrl}
                                                                alt="Uploaded Image"
                                                            />
                                                        )}
                                                        <div className="buttons-btn">
                                                            <div
                                                                className="download-btn"
                                                                onClick={() =>
                                                                    downloadImage(
                                                                        ele
                                                                    )
                                                                }
                                                            >
                                                                <DownloadOutlined />
                                                            </div>
                                                            {/* <div className="edit-btn">
                                                                <ImageCropper
                                                                    setSelectedImageUrl={
                                                                        setSelectedImageUrl
                                                                    }
                                                                    onRadioChange={
                                                                        onRadioChange
                                                                    }
                                                                    imageUrl={
                                                                        fileList[
                                                                            index
                                                                        ]
                                                                    }
                                                                    setFileList={
                                                                        setFileList
                                                                    }
                                                                    accessToken={
                                                                        accessToken
                                                                    }
                                                                    languageOption={
                                                                        languageOption
                                                                    }
                                                                    index={
                                                                        index
                                                                    }
                                                                    fileList={
                                                                        fileList
                                                                    }
                                                                />
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                                {videoList?.length > 0 && (
                                    <div className="video-container">
                                        {videoList?.map((ele, index) => {
                                            return (
                                                <div
                                                    className="video-block"
                                                    key={index}
                                                >
                                                    <div className="video-file">
                                                        <Radio
                                                            value={false}
                                                            name="video1"
                                                            onClick={(
                                                                event
                                                            ) => {
                                                                onRadioChangeVideo(
                                                                    event,
                                                                    index,
                                                                    ele
                                                                )
                                                            }}
                                                            checked={
                                                                selectedVideoRadio ===
                                                                index
                                                            }
                                                        ></Radio>

                                                        <MuxPlayer
                                                            style={{
                                                                height: '100%',
                                                                minWidth: 200,
                                                            }}
                                                            playbackId={
                                                                ele?.match(
                                                                    playbackIdRegex
                                                                )[1]
                                                            }
                                                            tokens={{
                                                                playback:
                                                                    ele.match(
                                                                        tokenRegex
                                                                    )[1],
                                                            }}
                                                            className="video-item"
                                                        />
                                                    </div>
                                                    <Button
                                                        className="video_download_btn"
                                                        type="primary"
                                                        icon={
                                                            <DownloadOutlined />
                                                        }
                                                        onClick={() =>
                                                            handleDownload(ele)
                                                        }
                                                    ></Button>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                            </div>

                            {!location?.pathname?.includes('tv') && (
                                <Form.Item
                                    style={{ marginTop: '10px' }}
                                    label=" "
                                    name="image_"
                                >
                                    <div
                                        style={{ display: 'flex', gap: '5px' }}
                                    >
                                        <ImageUploader
                                            getImagePath={getImagePath_}
                                        />
                                        <div>
                                            {isImageUploading && (
                                                <LoadingOutlined />
                                            )}
                                        </div>
                                    </div>
                                </Form.Item>
                            )}
                            {/* {selectedImageUrl.length!==0 && <ImageDownloader imageUrl={selectedImageUrl?.[0]} /> } */}

                            {fileListError ? (
                                <p className="error-card">
                                    Please Upload a Image
                                </p>
                            ) : maxImageError ? (
                                <p className="error-card">
                                    Max files allowed is 5
                                </p>
                            ) : (
                                imageError && (
                                    <p className="error-card">
                                        Please select image
                                    </p>
                                )
                            )}
                            {initialFormData?.created_type !== 'reporter' && (
                                <Form.Item label="Hashtag">
                                    <AutoComplete
                                        options={hashTagOptions}
                                        value={hashTagValue}
                                        onSearch={(text) => {
                                            if (text) {
                                                setHashTagValue(text)
                                                setHashtagOptions(
                                                    allHashTagOptions?.filter(
                                                        ({ value }) =>
                                                            value
                                                                ?.toLowerCase()
                                                                ?.includes(
                                                                    text?.toLowerCase()
                                                                )
                                                    )
                                                )
                                            } else {
                                                setHashtagOptions(
                                                    allHashTagOptions
                                                )
                                            }
                                        }}
                                        onSelect={onHashTagSelect}
                                        placeholder="select hashtag"
                                    />
                                </Form.Item>
                            )}
                            {initialFormData?.created_type !== 'reporter' && (
                                <Form.Item
                                    rules={[{ required: true }]}
                                    label="Publish"
                                    name="publish"
                                >
                                    <DatePicker
                                        showTime
                                        format="DD/MM/YYYY h:mm A"
                                        disabledDate={(currentDate) =>
                                            currentDate < new Date()
                                        }
                                    />
                                </Form.Item>
                            )}
                            {initialFormData?.created_type === 'reporter' ? (
                                <></>
                            ) : (
                                <div>
                                    <Form.Item
                                        label="Location Specific"
                                        name="locationSpecific"
                                    >
                                        <Radio.Group>
                                            <Radio value="yes"> Yes </Radio>
                                            <Radio value="no"> No </Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(
                                            prevValues,
                                            currentValues
                                        ) =>
                                            prevValues?.locationSpecific !==
                                            currentValues?.locationSpecific
                                        }
                                    >
                                        {({ getFieldValue }) =>
                                            getFieldValue(
                                                'locationSpecific'
                                            ) === 'yes' ? (
                                                <Form.Item
                                                    name="state"
                                                    label="State"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                'please select an option',
                                                        },
                                                    ]}
                                                >
                                                    {locationsData && (
                                                        <Select
                                                            onChange={
                                                                onStateChange
                                                            }
                                                            placeholder="Select State"
                                                        >
                                                            {locationsData?.map(
                                                                (option) => (
                                                                    <Select.Option
                                                                        key={
                                                                            option.state_id
                                                                        }
                                                                        value={
                                                                            option.name
                                                                        }
                                                                    >
                                                                        {
                                                                            option.name
                                                                        }
                                                                    </Select.Option>
                                                                )
                                                            )}
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                            ) : (
                                                form.setFieldValue('state', [])
                                            )
                                        }
                                    </Form.Item>
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(
                                            prevValues,
                                            currentValues
                                        ) =>
                                            prevValues.state !==
                                            currentValues.state
                                        }
                                    >
                                        {({ getFieldValue }) =>
                                            getFieldValue('state') &&
                                            getFieldValue('state').length !==
                                                0 ? (
                                                <Form.Item
                                                    name="district"
                                                    label="District"
                                                >
                                                    <Select
                                                        placeholder="Select District"
                                                        onChange={
                                                            onDistrictChange
                                                        }
                                                        mode="multiple"
                                                    >
                                                        {districtsData?.map(
                                                            (option) => (
                                                                <Select.Option
                                                                    key={
                                                                        option.name
                                                                    }
                                                                    value={
                                                                        option.name
                                                                    }
                                                                >
                                                                    {
                                                                        option.name
                                                                    }
                                                                </Select.Option>
                                                            )
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            ) : (
                                                form.setFieldValue(
                                                    'district',
                                                    []
                                                )
                                            )
                                        }
                                    </Form.Item>
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(
                                            prevValues,
                                            currentValues
                                        ) =>
                                            prevValues.state !==
                                            currentValues.state
                                        }
                                    >
                                        {({ getFieldValue }) =>
                                            getFieldValue('state') &&
                                            getFieldValue('state').length !==
                                                0 ? (
                                                <Form.Item
                                                    name="assemblyconstituencies"
                                                    label="Assembly-Constituencies"
                                                >
                                                    <Select
                                                        placeholder="Select Assembly-Constituencies"
                                                        onChange={
                                                            handleAssemblyConstituencies
                                                        }
                                                        onClick={() =>
                                                            setChange(1)
                                                        }
                                                        mode="multiple"
                                                    >
                                                        {assemblyConstituenciesData?.map(
                                                            (option) => (
                                                                <Select.Option
                                                                    key={
                                                                        option?.assembly_constituency_id
                                                                    }
                                                                    value={
                                                                        option?.name
                                                                    }
                                                                >
                                                                    {
                                                                        option?.name
                                                                    }
                                                                </Select.Option>
                                                            )
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            ) : (
                                                form.setFieldValue(
                                                    'assemblyconstituencies',
                                                    []
                                                )
                                            )
                                        }
                                    </Form.Item>

                                    <Form.Item
                                        noStyle
                                        shouldUpdate={(
                                            prevValues,
                                            currentValues
                                        ) =>
                                            prevValues.state !==
                                            currentValues.state
                                        }
                                    >
                                        {({ getFieldValue }) =>
                                            getFieldValue('district') &&
                                            getFieldValue('district')
                                                ?.length !== 0 ? (
                                                <Form.Item
                                                    name="mandal"
                                                    label="Mandal"
                                                >
                                                    <Select
                                                        mode="multiple"
                                                        onClick={() =>
                                                            setChange(5)
                                                        }
                                                        placeholder="Select Mandal"
                                                        onChange={
                                                            onMandalChange
                                                        }
                                                    >
                                                        {mandalsData?.map(
                                                            (option) => (
                                                                <Select.Option
                                                                    key={
                                                                        option.name
                                                                    }
                                                                    value={
                                                                        option.name
                                                                    }
                                                                >
                                                                    {
                                                                        option.name
                                                                    }
                                                                </Select.Option>
                                                            )
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            ) : (
                                                form.setFieldValue(
                                                    'district',
                                                    []
                                                )
                                            )
                                        }
                                    </Form.Item>
                                </div>
                            )}
                        </Col>
                        {location?.pathname?.includes(
                            'home/editorDashboard/sponsored-content'
                        ) && (
                            <Col
                                span={10}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    height: 200,
                                }}
                            >
                                <Form.Item
                                    wrapperCol={{ span: 48 }}
                                    style={{ marginBottom: '1.5rem' }}
                                >
                                    <Button
                                        style={{
                                            backgroundColor: 'gray',
                                            marginRight: 20,
                                        }}
                                        className="bg-color width-150"
                                        type="primary"
                                        onClick={() => setIsModalOpen(true)}
                                        disabled={isDisabled}
                                    >
                                        Reject
                                    </Button>
                                    <Button
                                        className="bg-color width-150"
                                        type="primary"
                                        style={{ backgroundColor: '#1677ff' }}
                                        onClick={() => {
                                            setAmountError(false)
                                            setIsCompensated(false)
                                            setIsCompModal(true)
                                        }}
                                        disabled={
                                            isDisabled ||
                                            generate ||
                                            fileList.length === 0
                                        }
                                    >
                                        Approve
                                    </Button>
                                </Form.Item>

                                <div>
                                    <SmartphonePreview
                                        title={title}
                                        description={shortDescription}
                                        image={selectedImageUrl[0]}
                                        videoPath={selectedVideoUrl[0]}
                                        videoList={videoList}
                                    />
                                </div>
                            </Col>
                        )}
                        {!location?.pathname?.includes('tv') &&
                            !location?.pathname?.includes(
                                'home/editorDashboard/sponsored-content'
                            ) && (
                                <Col
                                    span={10}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        height: 200,
                                    }}
                                >
                                    <Form.Item
                                        wrapperCol={{ span: 48 }}
                                        style={{ marginBottom: '1.5rem' }}
                                    >
                                        <Button
                                            style={{
                                                marginRight: '1.8rem',
                                                backgroundColor: 'gray',
                                            }}
                                            className="bg-color width-150"
                                            type="primary"
                                            onClick={() => setIsModalOpen(true)}
                                            disabled={isDisabled}
                                        >
                                            Reject
                                        </Button>
                                        <Button
                                            className="bg-color width-150"
                                            type="primary"
                                            style={{
                                                backgroundColor: '#1677ff',
                                            }}
                                            onClick={() =>
                                                handleSave('approved')
                                            }
                                            disabled={
                                                isDisabled ||
                                                generate ||
                                                fileList.length === 0
                                            }
                                        >
                                            Approve
                                        </Button>
                                    </Form.Item>

                                    <div>
                                        <SmartphonePreview
                                            title={title}
                                            description={shortDescription}
                                            image={selectedImageUrl[0]}
                                            videoPath={selectedVideoUrl[0]}
                                            videoList={videoList}
                                        />
                                    </div>
                                </Col>
                            )}
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24}>
                            {location?.pathname?.includes('tv') && (
                                <Form.Item
                                    label=" "
                                    wrapperCol={{ span: 48 }}
                                    style={{ marginTop: '1.5rem' }}
                                >
                                    <Button
                                        style={{
                                            backgroundColor: 'gray',
                                            marginRight: 20,
                                        }}
                                        className="bg-color width-150"
                                        type="primary"
                                        onClick={() => setIsModalOpen(true)}
                                        disabled={isDisabled}
                                    >
                                        Reject
                                    </Button>
                                    <Button
                                        className="bg-color width-150"
                                        type="primary"
                                        style={{ backgroundColor: '#1677ff' }}
                                        onClick={() => {
                                            setAmountError(false)
                                            setIsCompensated(false)
                                            setIsCompModal(true)
                                        }}
                                        disabled={
                                            isDisabled ||
                                            generate ||
                                            fileList.length === 0
                                        }
                                    >
                                        Approve
                                    </Button>
                                </Form.Item>
                            )}
                        </Col>
                    </Row>
                </Form>
                <RejectModal
                    setReason={setReason}
                    reason={reason}
                    setShowCustomReasonInput={setShowCustomReasonInput}
                    setRejectionReason={setRejectionReason}
                    handleSave={handleSave}
                    handleRejectionChanges={handleRejectionChanges}
                    rejectionReasonData={rejectionReasonData}
                    showCustomReasonInput={showCustomReasonInput}
                    accessToken={accessToken}
                    setIsDisabled={setIsDisabled}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                />
                {isImageSelected && (
                    <CropperModel
                        file={selectedFile[0]}
                        onConfirm={(croppedFile) => {
                            getImagePath(croppedFile)
                        }}
                        onDiscard={() => {
                            setSelectedFile('')
                        }}
                        onCompleted={() => {
                            setSelectedFile('')
                        }}
                        setIsImageSelected={setIsImageSelected}
                    />
                )}
            </div>
            <Modal
                title=""
                centered
                open={isCompModal}
                onOk={() => setIsCompModal(false)}
                onCancel={() => {
                    setIsCompModal(false)
                    // setPaymentType(undefined)
                }}
                footer={null}
                style={{
                    textAlign: 'center',
                }}
            >
                <div>
                    {location?.pathname?.includes(
                        '/home/editorDashboard/sponsored'
                    ) ? (
                        <div style={{}}>
                            <h5>Payement Info</h5>
                            <hr></hr>
                            <div style={{ position: 'relative' }}>
                                {isDisabled && (
                                    <Spin
                                        indicator={<LoadingOutlined spin />}
                                        style={{
                                            position: 'absolute',
                                            zIndex: 10,
                                            top: '40%',
                                            left: '50%',
                                        }}
                                        size="large"
                                    />
                                )}
                                <Form
                                    style={{ opacity: isDisabled ? 0.3 : 1 }}
                                    labelCol={{ span: 9 }}
                                    onFinish={handleApprove}
                                    onFinishFailed={handleValidationFailure}
                                    wrapperCol={{ span: 10 }}
                                >
                                    <Form.Item
                                        label="Payment Type"
                                        name="payment_type"
                                    >
                                        <Radio.Group
                                            onChange={handlePaymentTypeChange}
                                            value={paymentType}
                                        >
                                            <Radio value="debit">Paid</Radio>
                                            <Radio value="credit">
                                                On Credit
                                            </Radio>
                                        </Radio.Group>
                                    </Form.Item>

                                    {paymentType === 'debit' && (
                                        <>
                                            <Form.Item
                                                label="Transaction Date"
                                                name="transaction_date"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Please select a transaction date and time',
                                                    },
                                                ]}
                                            >
                                                <DatePicker
                                                    showTime
                                                    format="DD/MM/YYYY HH:mm:ss"
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                label="Transaction ID"
                                                name="transaction_id"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Please enter the transaction ID',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Enter transaction ID" />
                                            </Form.Item>
                                            <Form.Item
                                                label="Bank A/C No"
                                                name="bank_account"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Please enter your A/C no',
                                                    },
                                                    {
                                                        pattern:
                                                            /^[0-9]{9,18}$/,
                                                        message:
                                                            'Enter a valid A/C no',
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Enter A/C No" />
                                            </Form.Item>
                                            {/* <Form.Item
                                                label="Amount"
                                                name="amount"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Please enter the amount',
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    type="number"
                                                    placeholder="Enter amount"
                                                />
                                            </Form.Item> */}
                                            <Form.Item
                                                label="Transaction Type"
                                                name="transaction_type"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Please select method',
                                                    },
                                                ]}
                                            >
                                                <Select placeholder="Select transaction method">
                                                    <Select.Option value="bank">
                                                        Bank Transfer
                                                    </Select.Option>
                                                    <Select.Option value="upi">
                                                        UPI
                                                    </Select.Option>
                                                </Select>
                                            </Form.Item>

                                            <Form.Item
                                                label="Remarks"
                                                name="description"
                                            >
                                                <Input.TextArea
                                                    rows={2}
                                                    placeholder="Additional Remarks (optional)"
                                                />
                                            </Form.Item>
                                        </>
                                    )}

                                    {paymentType === 'credit' && (
                                        <div
                                            style={{
                                                padding: '10px 15px',
                                                background: '#fff',
                                                marginBottom: 10,
                                                border: '1px solid #f0f0f0',
                                                borderRadius: '6px',
                                            }}
                                        >
                                            <p>
                                                <strong>Note:</strong> Content
                                                approval will be processed based
                                                on the available credit usage
                                                and set credit limits.
                                            </p>
                                        </div>
                                    )}

                                    {paymentType && (
                                        <Form.Item
                                            style={{
                                                display: 'flex',
                                                width: '100%',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Button
                                                style={{ width: 100 }}
                                                type="primary"
                                                htmlType="submit"
                                                block
                                            >
                                                Proceed
                                            </Button>
                                        </Form.Item>
                                    )}
                                </Form>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {!isCompensated ? (
                                <div
                                    style={{
                                        textAlign: 'center',
                                        padding: '20px',
                                    }}
                                >
                                    <h5
                                        style={{
                                            fontSize: '20px',
                                            fontWeight: '600',
                                            marginBottom: '20px',
                                        }}
                                    >
                                        Do you want to add compensation?
                                    </h5>
                                    <Button
                                        type="primary"
                                        style={{
                                            marginRight: '10px',
                                            backgroundColor: '#4CAF50',
                                            borderColor: '#4CAF50',
                                            borderRadius: '8px',
                                            padding: '12px 24px',
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                        }}
                                        onClick={handleYesClick}
                                    >
                                        Yes
                                    </Button>
                                    <Button
                                        type="default"
                                        style={{
                                            borderRadius: '8px',
                                            padding: '12px 24px',
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                            borderColor: '#ccc',
                                        }}
                                        onClick={handleNoClick}
                                    >
                                        No
                                    </Button>
                                </div>
                            ) : (
                                <div>
                                    <Button
                                        onClick={() => setIsCompensated(false)}
                                        type="primary"
                                        ghost
                                        icon={<LeftOutlined />}
                                        style={{
                                            position: 'absolute',
                                            top: '20px',
                                            left: '20px',
                                            borderRadius: '50%',
                                            height: '40px',
                                            width: '40px',
                                        }}
                                    />
                                    <h5
                                        style={{
                                            fontSize: '18px',
                                            margin: '20px 0',
                                        }}
                                    >
                                        Enter the amount:
                                    </h5>
                                    <Input
                                        value={amount}
                                        onChange={(e) => {
                                            setAmount(e?.target?.value)
                                            setAmountError(false)
                                        }}
                                        type="number"
                                        placeholder="amount"
                                        style={{
                                            width: '250px',

                                            marginBottom: '20px',
                                            borderRadius: '5px',
                                        }}
                                    />
                                    {amountError && (
                                        <div
                                            style={{
                                                color: 'red',
                                                fontSize: '14px',
                                                marginTop: '10px',
                                                textAlign: 'center',
                                            }}
                                        >
                                            Amount is required. Please provide a
                                            valid input.
                                        </div>
                                    )}
                                    <br />
                                    <Button
                                        type="primary"
                                        style={{
                                            backgroundColor: '#28a745',
                                            borderColor: '#28a745',
                                            borderRadius: '5px',
                                            padding: '10px 20px',
                                            fontWeight: 'bold',
                                        }}
                                        onClick={() => {
                                            if (
                                                !amount ||
                                                amount === null ||
                                                amount === 0
                                            ) {
                                                setAmountError(true)
                                                return
                                            }

                                            setIsCompModal(false)
                                            setIsCompensated(false)
                                            handleSave('approved')
                                        }}
                                    >
                                        Confirm
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </Modal>
        </div>
    )
}

export default ReviewContentForm

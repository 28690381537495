import { Input, Modal } from 'antd'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import './AddCities.scss'
import { SelectComponent } from '../../AntdComponent/SelectComponent'
import { SelectComponentWithSearch } from '../../AntdComponent/SelectComponentWithSearch'

function AddCities({ isModalOpen, handleCancel, language }) {
    const languageData = useSelector((state) => state.app.languageData)
    const allStates = useSelector((state) => state.app.locations)
    const [languageOption, setLanguageOption] = useState(
        language || languageData?.[0]?.language_id
    )
    const [state, setState] = useState(undefined)
    const [cityName, setCityName] = useState('')

    const handleLanguageChange = (value) => {
        setLanguageOption(value)
    }

    const handleStateChange = (value) => {
        setState(value)
    }

    const handleCityChange = (e) => {
        setCityName(e.target.value)
    }
    return (
        <div>
            <Modal
                open={isModalOpen}
                onCancel={handleCancel}
                centered
                title={<h2 className="modal-title"> Add City</h2>}
                okText="Save"
                okButtonProps={{
                    style: {
                        backgroundColor: '#1890ff',
                        borderColor: '#1890ff',
                    },
                }}
            >
                <hr></hr>
                <div className="addCities-div">
                    <div style={{ marginBottom: '15px', marginTop: '3vh' }}>
                        <label
                            style={{ fontWeight: 550, marginRight: '3.2vw' }}
                        >
                            Language :
                        </label>
                        <SelectComponent
                            data={languageData}
                            value={languageOption}
                            onChange={handleLanguageChange}
                            key_value={'language_id'}
                            display={'language_name'}
                            style={{ marginRight: '1rem', width: '18vw' }}
                            placeholder={'Select Language'}
                        />
                    </div>
                    <div style={{ marginBottom: '15px', marginTop: '5px' }}>
                        <label
                            style={{ fontWeight: 550, marginRight: '5.2vw' }}
                        >
                            State :
                        </label>
                        <SelectComponentWithSearch
                            data={allStates}
                            value={state}
                            onChange={handleStateChange}
                            key_value={'state_id'}
                            display={'name'}
                            style={{ marginRight: '1rem', width: '18vw' }}
                            placeholder={'Select State'}
                        />
                    </div>
                    <div style={{ marginBottom: '20vh', marginTop: '5px' }}>
                        <label style={{ fontWeight: 550, marginRight: '3vw' }}>
                            City Name :
                        </label>
                        <Input
                            value={cityName}
                            onChange={handleCityChange}
                            style={{ width: '18vw' }}
                            required={true}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default AddCities

import { DatePicker, message, Popconfirm, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import './Comments.scss'
import {
    CloseSquareOutlined,
    SafetyCertificateOutlined,
    UserDeleteOutlined,
} from '@ant-design/icons'
import CommonTable from '../CommonTable/CommonTable'
import { getCommentsByStatus, updateComment } from '../../AadhanApi'
import dayjs from 'dayjs'

function Comments() {
    const { Option } = Select
    const { RangePicker } = DatePicker

    const accessToken = useSelector((state) => state.app.accessToken)
    const languageData = useSelector((state) => state.app.languageData)

    const [language, setLanguage] = useState(languageData?.[0]?.language_id)
    const [selectedType, setSelectedType] = useState()
    const [filteredData, setFilteredData] = useState([])
    const [dateRange, setDateRange] = useState([
        dayjs().startOf('day'),
        dayjs().endOf('day'),
    ])
    const [loading, setLoading] = useState()

    useEffect(() => {
        handleTypeChange('flagged')
    }, [accessToken])

    useEffect(() => {
        if (language && dateRange && selectedType) {
            fetchComments(selectedType, language)
        }
    }, [dateRange, language, selectedType])

    const rangePresets = [
        {
            label: 'Today',
            value: [dayjs(), dayjs()],
        },
        {
            label: 'Yesterday',
            value: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')],
        },
        {
            label: 'Last 7 Days',
            value: [dayjs().add(-7, 'd'), dayjs()],
        },
        {
            label: 'Last 30 Days',
            value: [dayjs().add(-30, 'd'), dayjs()],
        },
        {
            label: 'Last 90 Days',
            value: [dayjs().add(-90, 'd'), dayjs()],
        },
    ]

    let type

    const fetchComments = async (statusValue, languageValue) => {
        if (!dateRange || !dateRange[0] || !dateRange[1]) {
            message.error('Please select a valid date range')
            return
        }
        setLoading(true)
        setFilteredData([])
        try {
            let status
            switch (statusValue) {
                case 'flagged':
                    status = 'pending'
                    break
                case 'approved':
                    status = 'approved'
                    break
                case 'rejected':
                    status = 'rejected'
                    break
                default:
                    status = 'pending'
            }

            const startDate = dateRange[0].format('YYYY-MM-DD')
            const endDate = dateRange[1].format('YYYY-MM-DD')

            const response = await getCommentsByStatus(
                status,
                languageValue,
                startDate,
                endDate,
                accessToken
            )
            const responseData = response?.data?.data || []

            if (Array.isArray(responseData)) {
                setFilteredData(responseData)
            } else {
                setFilteredData([])
                console.error('Response data is not an array:', responseData)
            }
        } catch (error) {
            console.error('Error fetching comments:', error)
            message.error('Failed to fetch comments')
            setFilteredData([])
        } finally {
            setLoading(false)
        }
    }

    const handleLanguageChange = (value) => {
        setLanguage(value)
    }

    const handleTypeChange = async (value) => {
        setSelectedType(value)
    }

    const handleRangeChange = (dates) => {
        setDateRange(dates)
    }

    const handleApproveComment = async (commentId) => {
        setLoading(true)
        try {
            if (selectedType === 'flagged') {
                type = 'pending'
            }
            await updateComment(commentId, 'approved', type, accessToken)
            message.success('Comment approved successfully')
            handleTypeChange('approved')
        } catch (error) {
            console.error('Error approving comment:', error)
            message.error('Failed to approve comment')
        } finally {
            setLoading(false)
        }
    }

    const handleRejectComment = async (commentId) => {
        setLoading(true)
        try {
            if (selectedType === 'flagged') {
                type = 'pending'
            } else if (selectedType === 'approved') {
                type = 'approved'
            }
            await updateComment(commentId, 'rejected', type, accessToken)
            message.success('Comment rejected successfully')
            handleTypeChange('rejected')
        } catch (error) {
            console.error('Error rejecting comment:', error)
            message.error('Failed to reject comment')
        } finally {
            setLoading(false)
        }
    }

    const getColumns = () => {
        const baseColumns = [
            {
                title: 'Comment',
                dataIndex: 'text',
                key: 'text',
                width: '20%',
            },
            {
                title: 'User Name',
                dataIndex: 'username',
                key: 'username',
                width: '16%',
                align: 'center',
            },
            {
                title: 'Comment Type',
                dataIndex: 'comment_type',
                key: 'comment_type',
                width: '16%',
                align: 'center',
            },
            {
                title: 'Content Type',
                dataIndex: 'content_type',
                key: 'content_type',
                width: '16%',
                align: 'center',
            },
            {
                title: 'Date and Time ',
                dataIndex: 'created_at',
                render: (_, record) =>
                    dayjs(record?.created_at).format('DD MMM YYYY, hh:mm a'),
                key: 'created_at',
                width: '16%',
                align: 'center',
            },
        ]

        if (selectedType === 'flagged') {
            baseColumns.push({
                title: 'Actions',
                key: 'actions',
                align: 'center',
                width: '16%',
                render: (_, record) => (
                    <div>
                        <Popconfirm
                            title="Approve Comment"
                            description="Are you sure to approve this comment?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => handleApproveComment(record._id)}
                        >
                            <SafetyCertificateOutlined />
                        </Popconfirm>
                        <Popconfirm
                            title="Delete Comment"
                            description="Are you sure to reject this comment?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => handleRejectComment(record._id)}
                        >
                            <CloseSquareOutlined
                                style={{
                                    paddingLeft: '10px',
                                    cursor: 'pointer',
                                }}
                            />
                        </Popconfirm>
                        <Popconfirm
                            title="Delete comment and ban user"
                            description="Are you sure to delete this comment and ban user?"
                            okText="Yes"
                            cancelText="No"
                        >
                            <UserDeleteOutlined
                                style={{
                                    paddingLeft: '10px',
                                    cursor: 'pointer',
                                }}
                            />
                        </Popconfirm>
                    </div>
                ),
            })
        }

        if (selectedType === 'approved') {
            baseColumns.push({
                title: 'Actions',
                key: 'actions',
                align: 'center',
                width: '16%',
                render: (_, record) => (
                    <div>
                        <Popconfirm
                            title="Delete Comment"
                            description="Are you sure to reject this comment?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => handleRejectComment(record._id)}
                        >
                            <CloseSquareOutlined
                                style={{
                                    paddingLeft: '10px',
                                    cursor: 'pointer',
                                }}
                            />
                        </Popconfirm>
                    </div>
                ),
            })
        }

        return baseColumns
    }

    return (
        <div className="comments-container">
            <h2 className="comments-title">Manage Comments</h2>
            <hr className="line" aria-hidden="true"></hr>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <div>
                    <Select
                        value={language}
                        onChange={handleLanguageChange}
                        placeholder="Select Language"
                        className="comments-language-dropdown"
                    >
                        {languageData?.map((option) => (
                            <Select.Option
                                key={option.language_id}
                                value={option.language_id}
                            >
                                {option.language_name}
                            </Select.Option>
                        ))}
                    </Select>
                    <Select
                        placeholder="select type"
                        className="comments-type-dropdown"
                        value={selectedType}
                        onChange={handleTypeChange}
                    >
                        <Option key="flagged" value="flagged">
                            Flagged
                        </Option>
                        <Option key="approved" value="approved">
                            Approved
                        </Option>
                        <Option key="rejected" value="rejected">
                            Rejected
                        </Option>
                    </Select>
                </div>
                <div>
                    <RangePicker
                        className="comments-datepicker"
                        onChange={handleRangeChange}
                        disabledDate={(currentDate) => currentDate > new Date()}
                        format="DD/MM/YYYY"
                        value={dateRange}
                        allowClear={false}
                        presets={rangePresets}
                    />
                </div>
            </div>
            <div>
                <CommonTable
                    columns={getColumns()}
                    data={filteredData}
                    loading={loading}
                    pageSize={true}
                />
            </div>
        </div>
    )
}

export default Comments

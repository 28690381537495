import React, { useState, useEffect } from 'react'
import * as PropTypes from 'prop-types'
import ReactCropper from 'react-cropper'
import { Button, Modal, Row, Col, Form } from 'react-bootstrap'
import Slider from 'rc-slider'

// Utils
import { getFileInfo } from './utils'

// Styles
import 'cropperjs/dist/cropper.css'
import 'rc-slider/assets/index.css'

/** ImageCompressor functional component */
function ImageCompressor(props) {
    const { labels, file, modal } = props
    const [cropper, setCropper] = useState(null)
    const [image, setImage] = useState(null)
    const [zoom, setZoom] = useState(props.initialZoom)
    const [rotate, setRotate] = useState(props.initialRotate)
    const [quality, setQuality] = useState(0.95) // Initial quality set to medium
    const [kb, setKb] = useState(0)
    const [originalFileSize, setOriginalFileSize] = useState(0)
    const targetSizesKB = {
        low: 50,
        medium: 75,
        high: 99,
    } // Target file sizes in kilobytes

    useEffect(() => {
        if (file !== null) {
            console.log('from imageCompressor')
            const reader = new FileReader()
            reader.addEventListener('load', () => {
                const img = new Image()
                img.src = reader.result
                img.onload = () => {
                    const canvas = document.createElement('canvas')
                    const ctx = canvas.getContext('2d')
                    canvas.width = img.width
                    canvas.height = img.height
                    ctx.drawImage(img, 0, 0)
                    let qualityValue = getQualityValue(quality) // Use the current state of quality

                    setOriginalFileSize((file.size / 1024).toFixed(2) + 'kb')

                    const resizeAndCompress = () => {
                        canvas.toBlob(
                            (blob) => {
                                const fileSizeKB = blob.size / 1024
                                const targetSizeKB =
                                    targetSizesKB[getQualityLabel(quality)]

                                if (
                                    fileSizeKB > targetSizeKB &&
                                    qualityValue > 0.05
                                ) {
                                    // Resize and compress further
                                    const scaleRatio = Math.sqrt(
                                        targetSizeKB / fileSizeKB
                                    )
                                    canvas.width *= scaleRatio
                                    canvas.height *= scaleRatio
                                    ctx.drawImage(
                                        img,
                                        0,
                                        0,
                                        canvas.width,
                                        canvas.height
                                    )
                                    qualityValue -= 0.05 // Adjust compression more dynamically
                                    resizeAndCompress()
                                } else {
                                    const fileInfo = getFileInfo(
                                        file,
                                        props.mime
                                    )
                                    const compressedFile = new File(
                                        [blob],
                                        fileInfo.filename,
                                        {
                                            type: 'image/jpeg',
                                            lastModified: new Date(),
                                        }
                                    )
                                    setKb(
                                        (compressedFile.size / 1024).toFixed(
                                            2
                                        ) + 'kb'
                                    )
                                    const compressedReader = new FileReader()
                                    compressedReader.addEventListener(
                                        'load',
                                        () => {
                                            setImage(compressedReader.result)
                                        }
                                    )
                                    compressedReader.readAsDataURL(
                                        compressedFile
                                    )
                                }
                            },
                            'image/jpeg',
                            qualityValue
                        )
                    }

                    resizeAndCompress()
                }
            })
            reader.readAsDataURL(file)
        } else {
            setImage(null)
            setCropper(null)
        }
    }, [file, quality])

    /**
     * Crop image
     * @returns {void}
     * @event {Props:onConfirm}
     */
    const onConfirm = () => {
        if (quality === 'original') {
            alert('Image Size is Large.')

            return
        }

        if (!cropper) {
            return
        }

        const croppedCanvas = cropper.getCroppedCanvas({
            width: 540,
            height: 525,
            imageSmoothingQuality: 'medium',
            ...props.croppedCanvasProps,
        })
        // const cropData = cropper.getData();
        // const coordinates = {
        //     left: cropData.x,
        //     top: cropData.y,
        //     right: cropData.x + cropData.width,
        //     bottom: cropData.y + cropData.height,
        // };
        const fileInfo = getFileInfo(file, props.mime)

        const compressionQuality = getQualityValue(quality)

        const resizeAndCompress = (canvas, qualityValue, callback) => {
            canvas.toBlob(
                (blob) => {
                    const fileSizeKB = blob.size / 1024
                    const targetSizeKB = targetSizesKB[getQualityLabel(quality)]
                    if (fileSizeKB > targetSizeKB && qualityValue > 0.05) {
                        // Resize and compress further
                        // const scaleRatio = Math.sqrt(targetSizeKB / fileSizeKB)
                        // canvas.width *= scaleRatio
                        // canvas.height *= scaleRatio
                        const ctx = canvas.getContext('2d')
                        ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height)
                        qualityValue -= 0.05 // Adjust compression more dynamically
                        resizeAndCompress(canvas, qualityValue, callback)
                    } else {
                        callback(blob)
                    }
                },
                fileInfo.mime,
                qualityValue
            )
        }
        resizeAndCompress(croppedCanvas, compressionQuality, (blob) => {
            const croppedFile = new File([blob], fileInfo.filename, {
                type: 'image/jpeg',
                lastModified: new Date(),
            })
            
            if (typeof props.onConfirm === 'function') {
                props.onConfirm(croppedFile)
            }
            if (typeof props.onCompleted === 'function') {
                props.onCompleted()
            }
            resetState()
        })
    }

    const handleClose = () => {
        resetState()
        if (typeof props.onDiscard === 'function') {
            props.onDiscard(file)
        }
        if (typeof props.onCompleted === 'function') {
            props.onCompleted()
        }
    }

    const resetState = () => {
        setCropper(null)
        setImage(null)
        setZoom(props.initialZoom)
        setRotate(props.initialRotate)
    }

    const getQualityValue = (quality) => {
        switch (quality) {
            case 0.55:
                return 0.55 // Low quality
            case 0.75:
                return 0.75 // Medium quality
            case 0.95:
                return 0.95 // High quality
            case 'original':
                return 1 // Original file size, no compression
            default:
                return 0.75 // Default to medium quality
        }
    }

    const getQualityLabel = (quality) => {
        switch (quality) {
            case 0.55:
                return 'low'
            case 0.75:
                return 'medium'
            case 0.95:
                return 'high'
            case 'original':
                return 'original'
            default:
                return 'medium'
        }
    }

    return (
        <div>
            {modal === true ? (
                <div {...props.modalProps}>
                    <div style={{ width: 300, marginTop: '.5rem' }}>
                        {image && (
                            <div>
                                <div
                                    style={{
                                        boxShadow: '2px 2px 2px gray',
                                        marginBottom: '.5rem',
                                    }}
                                >
                                    <ReactCropper
                                        src={image}
                                        aspectRatio={540 / 525}
                                        width={320}
                                        height={220}
                                        viewMode={1}
                                        dragMode="move"
                                        cropBoxResizable={false}
                                        cropBoxMovable={false}
                                        center={true}
                                        toggleDragModeOnDblclick={false}
                                        checkOrientation={true}
                                        onInitialized={(instance) =>
                                            setCropper(instance)
                                        }
                                        maxCropBoxWidth={540}
                                        maxCropBoxHeight={525}
                                        minCropBoxWidth={540}
                                        minCropBoxHeight={525}
                                        {...props.cropperProps}
                                    />
                                </div>
                                <div>
                                    <div
                                        style={{
                                            width: '100%',
                                        }}
                                    >
                                        <div>
                                            <Row style={{ width: '100%' }}>
                                                <Col
                                                    xs={12}
                                                    style={{
                                                        display: 'flex',
                                                        gap: '20px',
                                                    }}
                                                >
                                                    <Form.Check
                                                        type="radio"
                                                        label="Original"
                                                        name="compression"
                                                        id="original"
                                                        checked={
                                                            quality ==='original'
                                                        }
                                                        onChange={() => {
                                                            setQuality(
                                                                'original'
                                                            )
                                                            setKb(
                                                                originalFileSize
                                                            ) // Display original file size
                                                        }}
                                                    />
                                                    <Form.Check
                                                        type="radio"
                                                        label="High"
                                                        name="compression"
                                                        id="high"
                                                        checked={
                                                            quality === 0.95
                                                        }
                                                        onChange={() => {
                                                            setQuality(0.95)
                                                        }}
                                                    />
                                                    <Form.Check
                                                        type="radio"
                                                        label="Medium"
                                                        name="compression"
                                                        id="medium"
                                                        checked={
                                                            quality === 0.75
                                                        }
                                                        onChange={() => {
                                                            setQuality(0.75)
                                                        }}
                                                    />
                                                    <Form.Check
                                                        type="radio"
                                                        label="Low"
                                                        name="compression"
                                                        id="low"
                                                        checked={
                                                            quality === 0.55
                                                        }
                                                        onChange={() => {
                                                            setQuality(0.55)
                                                        }}
                                                    />
                                                    <div>
                                                        {quality ===
                                                        'original' ? (
                                                            <div>
                                                                {
                                                                    originalFileSize
                                                                }
                                                            </div>
                                                        ) : (
                                                            <div>{kb}</div>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                    padding: '0 10px',
                                                }}
                                            >
                                                <div>
                                                    <Row>
                                                        <Col xs={6}>
                                                            <div
                                                                className="float-left d-inline-block"
                                                                style={{
                                                                    width: 200,
                                                                }}
                                                            >
                                                                <small>
                                                                    {
                                                                        labels.zoom
                                                                    }
                                                                </small>
                                                                <Slider
                                                                    min={1}
                                                                    max={2}
                                                                    step={0.1}
                                                                    value={zoom}
                                                                    onChange={(
                                                                        value
                                                                    ) => {
                                                                        setZoom(
                                                                            value
                                                                        )
                                                                        cropper.zoomTo(
                                                                            value
                                                                        )
                                                                    }}
                                                                />
                                                            </div>

                                                            <div
                                                                style={{
                                                                    width: 200,
                                                                }}
                                                            >
                                                                <small>
                                                                    {
                                                                        labels.rotate
                                                                    }
                                                                </small>
                                                                <Slider
                                                                    min={-180}
                                                                    max={180}
                                                                    marks={{
                                                                        '-180': '-180°',
                                                                        0: '0°',
                                                                        180: '180°',
                                                                    }}
                                                                    value={
                                                                        rotate
                                                                    }
                                                                    onChange={(
                                                                        value
                                                                    ) => {
                                                                        setRotate(
                                                                            value
                                                                        )
                                                                        cropper.rotateTo(
                                                                            value
                                                                        )
                                                                    }}
                                                                />
                                                            </div>

                                                            <div className="clearfix" />
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                style={{
                                                    gap: '20px',
                                                    display: 'flex',
                                                    marginTop: '1.5rem',
                                                }}
                                            >
                                                <Button
                                                    variant="primary"
                                                    onClick={onConfirm}
                                                >
                                                    {labels.confirm}
                                                </Button>{' '}
                                                <Button
                                                    variant="secondary"
                                                    onClick={handleClose}
                                                >
                                                    {labels.discard}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <Modal
                    show={!!file && !!image}
                    onHide={handleClose}
                    animation={false}
                    size="md"
                    {...props.modalProps}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{labels.heading}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        {image && (
                            <ReactCropper
                                src={image}
                                style={{ height: 500, width: '100%' }}
                                aspectRatio={540 / 525}
                                viewMode={1}
                                dragMode="move"
                                cropBoxResizable={false}
                                cropBoxMovable={false}
                                center={true}
                                toggleDragModeOnDblclick={false}
                                checkOrientation={true}
                                onInitialized={(instance) =>
                                    setCropper(instance)
                                }
                                maxCropBoxWidth={540}
                                maxCropBoxHeight={525}
                                minCropBoxWidth={540}
                                minCropBoxHeight={525}
                                {...props.cropperProps}
                            />
                        )}
                    </Modal.Body>
                    <Modal.Footer
                        style={{ height: '13rem' }}
                        className="d-block"
                    >
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div>
                                <Row style={{ width: '100%' }}>
                                    <Col
                                        xs={12}
                                        style={{
                                            display: 'flex',
                                            gap: '20px',
                                        }}
                                    >
                                        <Form.Check
                                            type="radio"
                                            label="Original"
                                            name="compression"
                                            id="original"
                                            checked={quality === 'original'}
                                            onChange={() => {
                                                setQuality('original')
                                                setKb(originalFileSize) // Display original file size
                                            }}
                                        />
                                        <Form.Check
                                            type="radio"
                                            label="High"
                                            name="compression"
                                            id="high"
                                            checked={quality === 0.95}
                                            onChange={() => {
                                                setQuality(0.95)
                                            }}
                                        />

                                        <Form.Check
                                            type="radio"
                                            label="Medium"
                                            name="compression"
                                            id="medium"
                                            checked={quality === 0.75}
                                            onChange={() => {
                                                setQuality(0.75)
                                            }}
                                        />

                                        <Form.Check
                                            type="radio"
                                            label="Low"
                                            name="compression"
                                            id="low"
                                            checked={quality === 0.55}
                                            onChange={() => {
                                                setQuality(0.55)
                                            }}
                                        />
                                        <div>
                                            {quality === 'original' ? (
                                                <div>{originalFileSize}</div>
                                            ) : (
                                                <div>{kb}</div>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <div>
                                        {' '}
                                        <Row style={{ marginTop: '1rem' }}>
                                            <Col xs={6}>
                                                <div
                                                    className="float-left mb-4 d-inline-block"
                                                    style={{ width: 200 }}
                                                >
                                                    <small>{labels.zoom}</small>
                                                    <Slider
                                                        min={1}
                                                        max={2}
                                                        step={0.1}
                                                        value={zoom}
                                                        onChange={(value) => {
                                                            setZoom(value)
                                                            cropper.zoomTo(
                                                                value
                                                            )
                                                        }}
                                                    />
                                                </div>
                                                <div style={{ width: 200 }}>
                                                    <small>
                                                        {labels.rotate}
                                                    </small>{' '}
                                                    <Slider
                                                        min={-180}
                                                        max={180}
                                                        marks={{
                                                            '-180': '-180°',
                                                            0: '0°',
                                                            180: '180°',
                                                        }}
                                                        value={rotate}
                                                        onChange={(value) => {
                                                            setRotate(value)
                                                            cropper.rotateTo(
                                                                value
                                                            )
                                                        }}
                                                    />
                                                </div>

                                                <div className="clearfix" />
                                            </Col>
                                        </Row>
                                    </div>
                                    <div>
                                        <div
                                            style={{
                                                gap: '5px',
                                                height: '3rem',
                                                marginTop: '3.4rem',
                                            }}
                                        >
                                            <Button
                                                variant="primary"
                                                onClick={onConfirm}
                                            >
                                                {labels.confirm}
                                            </Button>{' '}
                                            <Button
                                                variant="secondary"
                                                onClick={handleClose}
                                            >
                                                {labels.discard}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginBottom: '15px',
                                    marginRight: '15px',
                                }}
                            ></div>
                        </div>
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    )
}

ImageCompressor.propTypes = {
    initialZoom: PropTypes.number,
    initialRotate: PropTypes.number,
    mime: PropTypes.string,
    quality: PropTypes.number,
    file: PropTypes.object,
    labels: PropTypes.shape({
        heading: PropTypes.string,
        confirm: PropTypes.string,
        discard: PropTypes.string,
        zoom: PropTypes.string,
        rotate: PropTypes.string,
    }),
    cropperProps: PropTypes.object,
    modalProps: PropTypes.object,
    croppedCanvasProps: PropTypes.object,
    onDiscard: PropTypes.func,
    onCompleted: PropTypes.func,
    onConfirm: PropTypes.func,
}

ImageCompressor.defaultProps = {
    initialZoom: 1,
    initialRotate: 0,
    mime: null,
    quality: 0.75, // Default quality set to Medium
    labels: {
        heading: 'Compress and Submit',
        confirm: 'Confirm',
        discard: 'Discard',
        zoom: 'Zoom',
        rotate: 'Rotate',
    },
    cropperProps: {},
    modalProps: {},
    croppedCanvasProps: {},
    onDiscard: null,
    onCompleted: null,
    onConfirm: null,
}

export default ImageCompressor

import React, { useState } from 'react'
import ImageUploader from '../image-uploader-crop/ImageUploader'
import { getImageUrl} from '../../AadhanApi'
import { Button, message, Spin } from 'antd'
import { useLocation } from 'react-router'
import SmartphonePreviewUploadLibrary from '../SmartphonePreview/SmartPhonePreviewUploadLibray'

const ShortNewsImageUpload = ({
    accessToken,
    images,
    setFile,
    handleDeleteImage,
    languageOption,
    setImagePath,
    setIsImageAdding,
    imagePath,
    setIsSensitive,
    isSensitive,
    isOriginal,
    setIsOriginal,
    fromGoogleSearch,
    fileFromGoogleSearch,
    videoPath,
    up
}) => {
    const [isImageUploading, setIsImageUploading] = useState(false)
    const [act, setAct] = useState(false)
    const location = useLocation()

    const getImagePath = async (path) => {
        setIsImageUploading(true)
        const file = path

        const pathUriArr = await getImageUrl(
            file,
            'shortnews',
            accessToken,
            languageOption
        )

        if (pathUriArr?.data) {
            setImagePath(pathUriArr?.data)
            // setIsImageAdding(false)
            message.success('Image added')
            setIsImageUploading(false)
            setIsImageAdding(false)
        }
        if(typeof setFile==="function"){

            setFile()
        }
    }
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className="video_upload_shortnews">
                {(images?.length === 0 ||
                    !images ||
                    location.pathname.includes('addsnippet-form') || location.pathname.includes('home/reporter-dashboard')) && (
                    <ImageUploader
                        act={act}
                        isImageUploading={isImageUploading}
                        modal={true}
                        videoPath={videoPath}
                        getImagePath={getImagePath}
                        setAct={setAct}
                        images={images}
                        setFiles={setFile}
                        fileFromGoogleSearch={fileFromGoogleSearch}
                        fromGoogleSearch={fromGoogleSearch}
                        setIsImageAdding={setIsImageAdding}
                    />
                )}
                {videoPath?.stream_url || videoPath?.yt_url
                    ? images?.length >= 1 && (
                          <div
                              style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  color: 'blue',
                                  fontWeight: '500',
                                  fontSize: '1rem',
                                  textAlign: 'center',
                              }}
                          >
                              Image capacity full (1/1). Remove one to upload
                              another.
                          </div>
                      )
                    : images?.length >= 5 &&
                    !location.pathname.includes('addsnippet-form') && (
                              <div
                                  style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      color: 'blue',
                                      fontWeight: '500',
                                      fontSize: '1rem',
                                      textAlign: 'center',
                                  }}
                              >
                                  Image capacity full (5/5). Remove one to upload
                             
                            another.
                              </div>
                          )}

                {isImageUploading && (
                    <div className="upload_image_shortnews">
                        <Spin></Spin>
                    </div>
                )}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {imagePath && !isImageUploading && !act && (
                        <SmartphonePreviewUploadLibrary
                            isOriginal={isOriginal}
                            handleDeleteImage={handleDeleteImage}
                            setIsOriginal={setIsOriginal}
                            isSensitive={isSensitive}
                            setIsSensitive={setIsSensitive}
                            images={images}
                        />
                    )}
                    {images?.length > 0 &&
                        (!location.pathname.includes('addsnippet-form') && !location.pathname.includes('home/reporter-dashboard')) && (!videoPath?.stream_url) && (
                            <div style={{ width: '10%' }}>
                                {' '}
                                <ImageUploader
                                    act={act}
                                    modal={true}
                                    getImagePath={getImagePath}
                                    setAct={setAct}
                                    images={images}
                                    setFiles={setFile}
                                    isImageUploading={isImageUploading}
                                    fileFromGoogleSearch={fileFromGoogleSearch}
                                    fromGoogleSearch={fromGoogleSearch}
                                    setIsImageAdding={setIsImageAdding}
                                />
                            </div>
                        )}
                </div>
            </div>
            {up && !location.pathname.includes('addsnippet-form') && (
                <Button
                    onClick={() => setIsImageAdding(false)}
                    type="primary"
                    style={{ width: 100, alignSelf: 'center' }}
                >
                    OK
                </Button>
            )}
        </div>
    )
}

export default ShortNewsImageUpload

import { Button, Pagination, Select } from 'antd'
import { Option } from 'antd/es/mentions'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import './video.scss'
import { getVideos } from '../../AadhanApi'
import dayjs from 'dayjs'
import LazyLoad from 'react-lazyload'
import { DownloadOutlined } from '@ant-design/icons'
import Search from 'antd/es/input/Search'
import VideoPlayerWithThumbnail from './VideoPlayerWithThumbnail'
import ManageContentDateRange from '../ManageContent/ManageContentDateRange'
const VideoLibrary = () => {
    const token = useSelector((state) => state.app.accessToken)
    const languageData = useSelector((state) => state.app.languageData)
    const [language, setLanguage] = useState(2)
    const [filteredVideo, setFilterVideo] = useState([])
    const [dataLoading, setDataLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [constituency, setConstituency] = useState(undefined)
    const [state, setState] = useState(undefined)
    const [type, setType] = useState(1)
    const locations = useSelector((state) => state.app.locations)
    const allConstituencies = useSelector((state) => state.app.constituencies)
    const [dates, setDates] = useState({
        startDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        endDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    })
    const getVideo = async () => {
        setDataLoading(true)
        const res = await getVideos(
            language,
            type,
            dayjs(dates?.startDate).format('YYYY-MM-DD'),
            dayjs(dates?.endDate).format('YYYY-MM-DD'),
            search,
            token
        )
        if (!state) {
            setFilterVideo(res?.data?.data)
            setDataLoading(false)
            return
        }
        let stateFilter = res?.data?.data?.filter((e) => e.state === state)
        if (constituency) {
            stateFilter = res?.data?.data?.filter((e) => {
                const isValidState = e.state === state
                const isValidConstituency = Array.isArray(e.constituency)
                    ? e.constituency.includes(constituency)
                    : typeof e.constituency === 'string' &&
                      e.constituency.split(',').includes(constituency)

                return isValidState && isValidConstituency
            })
        }
        setFilterVideo(stateFilter)
        setDataLoading(false)
    }

    const [currentPage, setCurrentPage] = useState(1)
    const videosPerPage = 100
    const indexOfLastVideo = currentPage * videosPerPage
    const indexOfFirstVideo = indexOfLastVideo - videosPerPage
    const currentVideos = filteredVideo.slice(
        indexOfFirstVideo,
        indexOfLastVideo
    )

    // Handle page change
    const onPageChange = (page) => {
        setDataLoading(true)
        setCurrentPage(page)
        setDataLoading(false)
    }
    useEffect(() => {
        setState(undefined)
        setConstituency(undefined)
    }, [language, dates, type])

    const handleDownload = (url) => {
        const a = document.createElement('a')
        a.href = url
        a.download = 'video.mp4'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }
    const handleSearch = () => {
        setDataLoading(true)
        // setState(undefined)
        // setConstituency(undefined)
        getVideo()
    }
    const handleStateChange = (event) => {
        setConstituency(undefined)
        // setDataLoading(true)
        // if (event) {
        //     setFilterVideo(video.filter((e) => e.state === event))
        // } else {
        //     setFilterVideo(video)
        // }
        setState(event)
        // setDataLoading(false)
    }
    const handleConstituencyChange = (event) => {
        // setDataLoading(true)
        // if (event) {
        //     setFilterVideo(
        //         video.filter((e) => {
        //             const isValidState = e.state === state
        //             const isValidConstituency = Array.isArray(e.constituency)
        //                 ? e.constituency.includes(event)
        //                 : typeof e.constituency === 'string' &&
        //                   e.constituency.split(',').includes(event)

        //             return isValidState && isValidConstituency
        //         })
        //     )
        // } else {
        //     setFilterVideo(video.filter((e) => e.state === state))
        // }
        setConstituency(event)
        // setDataLoading(false)
    }
    const addDates = (startDate, endDate) => {
        let updatedDates = { startDate: startDate, endDate: endDate }
        setDates(updatedDates)
    }
    const clearDates = () => {
        const updatedDates = {
            startDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            endDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        }
        setDates(updatedDates)
    }
    return (
        <div style={{ padding: '.5rem 1rem 1rem 1rem' }}>
            <div className="filters">
                <Select
                    value={language}
                    onChange={(e) => setLanguage(e)}
                    style={{ width: 100 }}
                    placeholder="Select language"
                >
                    {languageData?.map((option) => {
                        return (
                            <Option
                                key={option?.language_id}
                                value={option?.language_id}
                            >
                                {option?.language_name}
                            </Option>
                        )
                    })}
                </Select>
                <ManageContentDateRange
                    dates={dates}
                    addDates={addDates}
                    clearDates={clearDates}
                />
                <Select
                    style={{ width: 130 }}
                    onChange={(value) => setType(value)}
                    value={type}
                >
                    <Option value={1}>App Stringers</Option>
                    <Option value={2}>TV Stringers</Option>
                </Select>
                <Select
                    allowClear
                    placeholder="Select State"
                    value={state}
                    showSearch
                    onChange={(e) => handleStateChange(e)}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option?.children
                            ?.toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: 120 }}
                >
                    {locations.map((option, index) => {
                        return (
                            <Option
                                key={option.state_id}
                                value={option.state_id}
                            >
                                {option?.name}
                            </Option>
                        )
                    })}
                </Select>
                <Select
                    value={constituency ? constituency : undefined}
                    allowClear
                    placeholder="Select Constituency"
                    onChange={(e) => handleConstituencyChange(e)}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option?.children
                            ?.toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: 130 }}
                >
                    {allConstituencies?.[state]?.map((option, index) => {
                        return (
                            <Option
                                key={option?.assembly_constituency_id}
                                value={option?.assembly_constituency_id}
                            >
                                {option?.name}
                            </Option>
                        )
                    })}
                </Select>
                <Button
                    type="primary"
                    onClick={() => {
                        getVideo()
                    }}
                >
                    Apply
                </Button>
                <Search
                    style={{ width: '200px' }}
                    placeholder="Search by title or keywords..."
                    enterButton="Search"
                    size="medium"
                    onSearch={handleSearch}
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                ></Search>
            </div>
            <hr></hr>
            {currentVideos?.length !== 0 && (
                <Pagination
                    style={{ position: 'absolute', right: 0, top: 145 }}
                    current={currentPage}
                    total={filteredVideo.length}
                    pageSize={videosPerPage}
                    onChange={onPageChange}
                    className="pagination_class"
                    showSizeChanger={false}
                />
            )}
            {dataLoading && (
                <div
                    style={{
                        height: '60vh',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {' '}
                    <div className="sk-cube-grid">
                        <div className="sk-cube sk-cube1"></div>
                        <div className="sk-cube sk-cube2"></div>
                        <div className="sk-cube sk-cube3"></div>
                        <div className="sk-cube sk-cube4"></div>
                        <div className="sk-cube sk-cube5"></div>
                        <div className="sk-cube sk-cube6"></div>
                        <div className="sk-cube sk-cube7"></div>
                        <div className="sk-cube sk-cube8"></div>
                        <div className="sk-cube sk-cube9"></div>
                    </div>
                </div>
            )}
            <div className="video_container">
                {!dataLoading &&
                    filteredVideo.length !== 0 &&
                    currentVideos?.map((e, index) => {
                        return (
                            <div className="video_box" key={index}>
                                <div className="video">
                                    <LazyLoad
                                        key={index}
                                        height={200}
                                        offset={100}
                                    >
                                        <VideoPlayerWithThumbnail
                                            videoUrl={e.r2_video_url}
                                            thumbnailUrl={e.image_url}
                                        />
                                    </LazyLoad>
                                </div>
                                <div className="video_title_container">
                                    <div
                                        style={{
                                            width: '100%',
                                            position: 'relative',
                                        }}
                                    >
                                        <div className="video_title">
                                            <a href={e.dynamic_url} target="_">
                                                {e?.title}
                                            </a>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    fontSize: '15px',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        marginRight: '.3rem',
                                                        marginBottom: '1.2%',
                                                    }}
                                                >
                                                    <svg
                                                        width={20}
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke-width="1.5"
                                                        stroke="currentColor"
                                                        className="size-6"
                                                        color="orange"
                                                    >
                                                        <path
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                                        />
                                                        <path
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                                                        />
                                                    </svg>
                                                </div>
                                                <div>
                                                    {e?.constituency_names?.join(
                                                        ', '
                                                    )}
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        marginRight: '.3rem',
                                                        paddingBottom: '1.5%',
                                                    }}
                                                >
                                                    <svg
                                                        width={20}
                                                        color="rgb(18, 211, 211)"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke-width="1.5"
                                                        stroke="currentColor"
                                                        className="size-6"
                                                    >
                                                        <path
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                                        />
                                                    </svg>
                                                </div>
                                                <div
                                                    style={{ fontSize: '14px' }}
                                                >
                                                    {e?.uploaded_by_name?.slice(
                                                        0,
                                                        17
                                                    )}
                                                    {e?.uploaded_by_name &&
                                                        e?.stringer_mobile_number && (
                                                            <span>, </span>
                                                        )}
                                                    <span
                                                        style={{
                                                            color: 'green',
                                                        }}
                                                    >
                                                        {' '}
                                                        {
                                                            e?.stringer_mobile_number
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <Button
                                            type="primary"
                                            className="download_btn"
                                            onClick={(event) => {
                                                event.preventDefault()
                                                event.stopPropagation()
                                                handleDownload(e?.r2_video_url)
                                            }}
                                            icon={<DownloadOutlined />}
                                        ></Button>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
            </div>
            {filteredVideo.length === 0 && !dataLoading && (
                <div className="no_data">No Videos</div>
            )}
        </div>
    )
}

export default VideoLibrary

import axios from 'axios'

const AADHAN_URL = process.env.REACT_APP_API_URL
//const LOCAL_CMS_URL = 'http://127.0.0.1:8000/'

export const doUserLogin = async (
    userName,
    password,
    grantType,
    clientId,
    clientSecret
) => {
    const credentialUri = `?grant_type=${grantType}&username=${userName}&password=${password}&client_id=${clientId}&client_secret=${clientSecret}`
    return await axios({
        url: `${AADHAN_URL}token/login`,
        method: 'POST',
        data: credentialUri,
    })
}

export const getFeatureData = async (languageId, token) => {
    return await axios({
        url: `${AADHAN_URL}feature`,
        method: 'GET',
        params: {
            language_id: languageId,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const getFeedbackTableData = async (
    platform,
    startDate,
    endDate,
    languageId,
    token
) => {
    return await axios({
        url: `${AADHAN_URL}feedback`,
        method: 'GET',
        params: {
            platform: platform,
            start_date: startDate,
            end_date: endDate,
            language_id: languageId,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const getLanguage = async (status, token) => {
    return await axios({
        url: `${AADHAN_URL}language`,
        method: 'GET',
        params: {
            status: status,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getCategory = async (languageId, contentType, token, status) => {
    return await axios({
        url: `${AADHAN_URL}news-section/`,
        method: 'GET',
        params: {
            language_id: languageId,
            status,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getImageUrl = async (imageFile, contentType, token, language) => {
    if (imageFile) {
        const formData = new FormData()
        formData.append('file', imageFile)
        const config = {
            params: {
                bucket_type: contentType,
                language_id: language,
            },
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
            },
        }

        return await axios.post(
            `${AADHAN_URL}uploadimage/imageupload`,
            formData,
            config
        )
    }
}

export const getImageUrlR2 = async (imageFile, token, bucket_type) => {
    if (imageFile) {
        const formData = new FormData()
        formData.append('file', imageFile)

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
            },
        }

        return await axios.post(
            `${AADHAN_URL}uploadimage/r2imageupload?bucket_type=${bucket_type}`,
            formData,
            config
        )
    }
}

export const getVideoUrl = async (videoFile, type, token) => {
    if (videoFile) {
        const formData = new FormData()
        formData.append('file', videoFile)

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
            },
            params: {
                audio_only: type,
            },
        }

        return await axios.post(`${AADHAN_URL}mux_video`, formData, config)
    }
}

export const getMuxSignedUrl = async (token) => {
    return await axios({
        url: `${AADHAN_URL}mux_video`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const deletePollsRow = async (contentId, token) => {
    return await axios({
        url: `${AADHAN_URL}poll/${contentId}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const deleteRole = async (roleId, token) => {
    return await axios({
        url: `${AADHAN_URL}roles/${roleId}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const deleteshortVideoRow = async (contentId, token) => {
    return await axios({
        url: `${AADHAN_URL}shortvideo/${contentId}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const deleteQuizRow = async (contentId, token) => {
    return await axios({
        url: `${AADHAN_URL}quiz/${contentId}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const deleteLanguageRow = async (contentId, token) => {
    return await axios({
        url: `${AADHAN_URL}language/${contentId}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const deleteHashtagRow = async (contentId, token) => {
    return await axios({
        url: `${AADHAN_URL}hashtag/${contentId}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const deleteShortNewsRow = async (contentId, token) => {
    return await axios({
        url: `${AADHAN_URL}shortnews/${contentId}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const deleteCardRow = async (contentId, token) => {
    return await axios({
        url: `${AADHAN_URL}card/${contentId}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const deleteCategoryRow = async (category_id, languageId, token) => {
    return await axios({
        url: `${AADHAN_URL}category/${category_id}/${languageId}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const deleteSourceRow = async (source_id, token) => {
    return await axios({
        url: `${AADHAN_URL}source/${source_id}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getQuizByContentId = async (contentId, token) => {
    return await axios({
        url: `${AADHAN_URL}quiz/${contentId}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getPermissionWithRoleId = async (roleId, token) => {
    return await axios({
        url: `${AADHAN_URL}permission/${roleId}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const searchDataByTitleAndType = async (
    title,
    languageId,
    contentType,
    categoryOption,
    approvalOption,
    startDate,
    endDate,
    page,
    limit,
    token,
    addTypeOption,
    writer_dashboard = false
) => {
    return await axios({
        url: `${AADHAN_URL}search?content_type=${contentType}&title=${title}&language_id=${languageId}&category_id=${categoryOption}&content_status=${approvalOption}&writer_dashboard=${writer_dashboard}&start_date=${startDate}&end_date=${endDate}&page=${page}&limit=${limit}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            ad_type: addTypeOption,
        },
    })
}

export const getPollByContentId = async (contentId, token) => {
    return await axios({
        url: `${AADHAN_URL}poll/${contentId}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const getPulsePollsData = async (state_id, electiontype) => {
    return await axios({
        url: `${AADHAN_URL}pulse/get-state-poll/${state_id}/${electiontype}`,
        method: 'GET',
    })
}

export const getCardByContentId = async (contentId, token) => {
    return await axios({
        url: `${AADHAN_URL}card/${contentId}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getShortVideoByContentId = async (contentId, token) => {
    return await axios({
        url: `${AADHAN_URL}shortvideo/${contentId}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getDevotionalVideoByContentId = async (contentId, token) => {
    return await axios({
        url: `${AADHAN_URL}devotion/${contentId}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const getAllLocation = async (contentId, token) => {
    return await axios({
        url: `${AADHAN_URL}location/${contentId}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getAllEmployees = async (token) => {
    return await axios({
        url: `${AADHAN_URL}employee/99999`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getShortNewsByContentId = async (contentId, token) => {
    return await axios({
        url: `${AADHAN_URL}shortnews/${contentId}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getHtmlContentByContentId = async (contentId, token) => {
    return await axios({
        url: `${AADHAN_URL}htmlcontent/${contentId}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getLanguageTableData = async (status, token) => {
    return await axios({
        url: `${AADHAN_URL}language?status=${status}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getHashtagTableData = async (
    language_id,
    hashtagSearch,
    token
) => {
    const params = {}
    params.language_id = language_id
    if (hashtagSearch !== '' && hashtagSearch !== undefined) {
        params.hashtag_name = hashtagSearch
    }
    return await axios({
        url: `${AADHAN_URL}hashtag`,
        method: 'GET',
        params,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getSourcesTableData = async (language_id, token) => {
    return await axios({
        url: `${AADHAN_URL}source?language_id=${language_id}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getCategoryTableData = async (
    language_id,
    type,
    status,
    token
) => {
    return await axios({
        url: `${AADHAN_URL}category?language_id=${language_id}&type_content=${type}&status=${status}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getCategoryById = async (category_id, languageId, token) => {
    return await axios({
        url: `${AADHAN_URL}category/${category_id}/${languageId}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getSourceTableData = async (language_id, hashtagSearch, token) => {
    const params = {}
    params.language_id = language_id
    if (hashtagSearch !== '' && hashtagSearch !== undefined) {
        params.source_name = hashtagSearch
    }
    return await axios({
        url: `${AADHAN_URL}source`,
        params,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getEmployeeTableData = async (
    status,
    language_id,
    employee_department_id,
    token,
    searchMobileData,
    searchName
) => {
    const typeOfEmployee = 'internal'
    return await axios({
        url:
            searchMobileData !== '' && searchMobileData !== undefined
                ? `${AADHAN_URL}employee?language_id=${language_id}&typeof_employee=${typeOfEmployee}&status=${status}&employee_department_id=${employee_department_id}&mobile_number=${searchMobileData}`
                : searchName !== '' && searchName !== undefined
                  ? `${AADHAN_URL}employee?language_id=${language_id}&typeof_employee=${typeOfEmployee}&status=${status}&employee_department_id=${employee_department_id}&employee_name=${searchName}`
                  : `${AADHAN_URL}employee?language_id=${language_id}&typeof_employee=${typeOfEmployee}&status=${status}&employee_department_id=${employee_department_id}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getReporterTableData = async (
    type,
    mobileNumber,
    name,
    token,
    stateId,
    districtId,
    stringer_type
) => {
    let params = {}

    if (type !== 'all') {
        params.is_compensated = type === 'paid' ? true : false
    }
    if (mobileNumber.length) {
        params.search_with_mobile = mobileNumber
    }
    if (name && name !== '') {
        params.search_with_name = name
    }
    if (stateId && stateId !== 'Clear Filter') {
        params.state_id = stateId
    }
    if (districtId && districtId !== 'Clear Filter') {
        params.district_id = districtId
    }
    if (stringer_type) {
        params.stringer_type = stringer_type
    }
    return await axios({
        url: `${AADHAN_URL}reporters`,
        method: 'GET',
        params,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getReporterData = async (
    type,
    language_id,
    mobileNumber,
    name,
    token,
    stateId,
    districtId,
    stringer_type
) => {
    let params = {}

    if (type !== 'all') {
        params.is_compensated = type === 'paid' ? true : false
    }
    if (language_id) {
        params.language_id = language_id
    }
    if (mobileNumber.length) {
        params.search_with_mobile = mobileNumber
    }
    if (name && name !== '') {
        params.search_with_name = name
    }
    if (stateId && stateId !== 'Clear Filter') {
        params.state_id = stateId
    }
    if (districtId && districtId !== 'Clear Filter') {
        params.district_id = districtId
    }
    if (stringer_type) {
        params.stringer_type = stringer_type
    }
    return await axios({
        url: `${AADHAN_URL}reporters`,
        method: 'GET',
        params,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getContentEditReport = async (contentType, year, month, token) => {
    let params = {
        year,
        month,
    }
    if (contentType !== 'all') {
        params.content_type = contentType
    }
    return await axios({
        url: `${AADHAN_URL}api/reports/content-edits`,
        method: 'GET',
        params,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getEmployeeDetails = async (employee_id, token) => {
    return await axios({
        url: `${AADHAN_URL}employee/${employee_id}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getReporterDetails = async (employee_id, token) => {
    return await axios({
        url: `${AADHAN_URL}reporters/${employee_id}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getRoleList = async (token, departmentName) => {
    return await axios({
        url: `${AADHAN_URL}roles/99999`,
        method: 'GET',
        params: {
            department_Name: departmentName,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const deleteEmployee = async (employee_id, token) => {
    return await axios({
        url: `${AADHAN_URL}employee/${employee_id}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const deleteFeature = async (featureId, token) => {
    return await axios({
        url: `${AADHAN_URL}feature/${featureId}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const deleteHtml = async (contentId, token) => {
    return await axios({
        url: `${AADHAN_URL}htmlcontent/${contentId}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const deleteDevotionalVideo = async (contentId, token) => {
    return await axios({
        url: `${AADHAN_URL}devotion/${contentId}`,
        method: `DELETE`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const createShortNews = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.post(`${AADHAN_URL}shortnews`, request, config)
}
export const createReporterContent = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.post(`${AADHAN_URL}ugccontent`, request, config)
}

export const createHtmlContent = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.post(`${AADHAN_URL}htmlcontent`, request, config)
}

export const createRole = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.post(`${AADHAN_URL}roles`, request, config)
}

export const createEmployee = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.post(`${AADHAN_URL}employee`, request, config)
}

export const updateEmployee = async (request, employee_id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.put(
        `${AADHAN_URL}employee/${employee_id}`,
        request,
        config
    )
}

export const updateReporter = async (request, employee_id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.put(
        `${AADHAN_URL}reporters/${employee_id}`,
        request,
        config
    )
}
export const createReporter = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.post(`${AADHAN_URL}reporters`, request, config)
}

export const createShortVideo = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.post(`${AADHAN_URL}shortvideo`, request, config)
}

export const createDevotionalVideo = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    return await axios.post(`${AADHAN_URL}devotion`, request, config)
}

export const getDevotionalVideo = async (languageId, token) => {
    return await axios({
        url: `${AADHAN_URL}devotion`,
        method: 'GET',
        params: {
            language_id: languageId,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const createCard = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.post(`${AADHAN_URL}card`, request, config)
}

export const createLanguage = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.post(`${AADHAN_URL}language`, request, config)
}

export const createFeature = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.post(`${AADHAN_URL}feature`, request, config)
}

export const createHashtag = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.post(`${AADHAN_URL}hashtag`, request, config)
}

export const createSource = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.post(`${AADHAN_URL}source`, request, config)
}

export const updateLanguage = async (request, token, languageId) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.put(
        `${AADHAN_URL}language/${languageId}`,
        request,
        config
    )
}

export const updateFeature = async (request, token, featureId) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.put(`${AADHAN_URL}feature/${featureId}`, request, config)
}

export const updateDevotionalVideo = async (request, contentId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.put(
        `${AADHAN_URL}devotion/${contentId}`,
        request,
        config
    )
}

export const updatePermission = async (request, token, permission_id) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.put(
        `${AADHAN_URL}permission/${permission_id}`,
        request,
        config
    )
}

export const updateRole = async (request, token, roleId) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.put(`${AADHAN_URL}roles/${roleId}`, request, config)
}

export const updateCategory = async (request, token, category_id) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.put(
        `${AADHAN_URL}category/${category_id}/${request.language_id}`,
        request,
        config
    )
}

export const createCategory = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.post(`${AADHAN_URL}category`, request, config)
}

export const updateHashtag = async (request, token, hashtagId) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.put(`${AADHAN_URL}hashtag/${hashtagId}`, request, config)
}

export const updateSource = async (request, token, sourceId) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.put(`${AADHAN_URL}source/${sourceId}`, request, config)
}

export const updateShortNews = async (request, contentId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.put(
        `${AADHAN_URL}shortnews/${contentId}`,
        request,
        config
    )
}

export const updateHtmlContent = async (request, contentId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.put(
        `${AADHAN_URL}htmlcontent/${contentId}`,
        request,
        config
    )
}

export const updateShortVideo = async (request, contentId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.put(
        `${AADHAN_URL}shortvideo/${contentId}`,
        request,
        config
    )
}

export const updateCard = async (request, contentId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.put(`${AADHAN_URL}card/${contentId}`, request, config)
}

export const createPolls = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.post(`${AADHAN_URL}poll`, request, config)
}

export const updatePolls = async (request, contentId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.put(`${AADHAN_URL}poll/${contentId}`, request, config)
}

export const createQuiz = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.post(`${AADHAN_URL}quiz`, request, config)
}

export const generateVerificationCode = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    return await axios.post(
        `${AADHAN_URL}employee/send_verification_code`,
        request,
        config
    )
}

export const resetPassword = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    return await axios.put(`${AADHAN_URL}employee`, request, config)
}

export const updatePassword = async (oldPassword, newPassword, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    return await axios.patch(
        `${AADHAN_URL}employee?old_password=${oldPassword}&new_password=${newPassword}`,
        {},
        config
    )
}

export const updateQuiz = async (request, contentId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.put(`${AADHAN_URL}quiz/${contentId}`, request, config)
}

export const getAdvertisements = async (
    contentStatus,
    languageId,
    page,
    limit,
    token
) => {
    return await axios({
        url: `${AADHAN_URL}advertisements`,
        method: 'GET',
        params: {
            content_status: contentStatus,
            language_id: languageId,
            page: page,
            limit: limit,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getNotifications = async (
    searchData,
    contentType,
    languageId,
    page,
    limit,
    type,
    token,
    selectedStateId,
    selectedDistrictId
) => {
    return await axios({
        url: `${AADHAN_URL}notifications`,
        method: 'GET',
        params: {
            query_text: searchData,
            content_type: contentType,
            language_id: languageId,
            page: page,
            limit: limit,
            created_by: type,
            state_id: selectedStateId,
            district_id: selectedDistrictId,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getUgcContent = async (
    languageId,
    contentStatus,
    isCompensated,
    startDate,
    endDate,
    stateVal,
    token,
    districtId,
    text,
    tv_content = false,
    stringer_type
) => {
    let params = {
        language_id: languageId,
        content_status: contentStatus,
        is_compensated: isCompensated,
        start_date: startDate,
        end_date: endDate,
        district_id: districtId,
        tv_content,
        stringer_type,
    }
    if (stateVal !== 'All') {
        params.state_id = stateVal
    }
    if (text !== '' && text !== null && text !== undefined) {
        params.title = text
    }

    return await axios({
        url: `${AADHAN_URL}ugccontent/ugc`,
        method: 'GET',
        params,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const getUgcContentReporter = async (
    language_id,
    content_status,
    start_date,
    end_date,
    user_id,
    token
) => {
    let params = {
        content_status,
        user_id,
        start_date,
        end_date,
        language_id,
        stringer_type: 5,
    }

    return await axios({
        url: `${AADHAN_URL}ugccontent/ugc`,
        method: 'GET',
        params,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getUgcContentByContentId = async (contentId, token) => {
    return await axios({
        url: `${AADHAN_URL}ugccontent/${contentId}`,
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const gettvContentByContentId = async (contentId, token) => {
    return await axios({
        url: `${AADHAN_URL}ugccontent/tv/${contentId}`,
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const sendNotificationData = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.post(`${AADHAN_URL}notifications`, request, config)
}

export const getEmployeeReport = async (languageId, year, month, token) => {
    let params = {
        year,
        month,
    }
    if (languageId != 'ALL') {
        params.language_id = languageId
    }
    return await axios({
        url: `${AADHAN_URL}api/reports/employees-productivity`,
        method: 'GET',
        params,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getUgcReport = async (
    languageId,
    statusOption,
    year,
    month,
    selectedStateKey,
    token
) => {
    return await axios({
        url: `${AADHAN_URL}api/reports/reporters-productivity`,
        method: 'GET',
        params: {
            language_id: languageId,
            content_status: statusOption,
            state_id: selectedStateKey,
            year,
            month,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getContentReport = async (approvalOption, year, month, token) => {
    return await axios({
        url: `${AADHAN_URL}api/reports/content-by-language`,
        method: 'GET',
        params: {
            content_status: approvalOption,
            year,
            month,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getNotificationReport = async (
    languageId,
    page,
    limit,
    startDate,
    endDate,
    token
) => {
    return await axios({
        url: `${AADHAN_URL}api/reports/notifications`,
        method: 'GET',
        params: {
            language_id: languageId,
            page,
            limit,
            start_date: startDate,
            end_date: endDate,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getSourceReport = async (
    languageId,
    startDate,
    endDate,
    employeeId,
    token
) => {
    let params = {
        language_id: languageId,
        start_date: startDate,
        end_date: endDate,
    }
    if (employeeId !== 'All') {
        params.employee_id = employeeId
    }
    return await axios({
        url: `${AADHAN_URL}api/reports/content-by-source`,
        method: 'GET',
        params,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getWriters = async (languageId, status, typeOfEmployee, token) => {
    return await axios({
        url: `${AADHAN_URL}employee`,
        method: 'GET',
        params: {
            language_id: languageId,
            status,
            typeof_employee: typeOfEmployee,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getDailyContentReport = async (day, token) => {
    return await axios({
        url: `${AADHAN_URL}api/reports/daily-content`,
        method: 'GET',
        params: {
            day,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getProofReaderReport = async (languageId, year, month, token) => {
    return await axios({
        url: `${AADHAN_URL}api/reports/proof-readers`,
        method: 'GET',
        params: {
            language_id: languageId,
            year,
            month,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getUgContentProofReaderReport = async (
    languageId,
    year,
    month,
    token
) => {
    return await axios({
        url: `${AADHAN_URL}api/reports/ug-content-proof-readers`,
        method: 'GET',
        params: {
            language_id: languageId,
            year,
            month,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getCategoryReport = async (
    languageId,
    contentType,
    startHour,
    endHour,
    startDate,
    endDate,
    employeeId,
    userTypeOption,
    token
) => {
    let params = {
        language_id: languageId,
        start_date: startDate,
        end_date: endDate,
        created_by: userTypeOption,
    }
    if (employeeId !== 'All') {
        params.employee_id = employeeId
    }
    if (contentType !== 'all') {
        params.content_type = contentType
    }
    if (startHour !== 'all' && endHour !== undefined) {
        params.start_hour = startHour
        params.end_hour = endHour
    }
    return await axios({
        url: `${AADHAN_URL}api/reports/category-time-slot`,
        method: 'GET',
        params,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getTimeSlotReport = async (
    languageId,
    contentType,
    startDate,
    endDate,
    token
) => {
    let params = {
        language_id: languageId,
        start_date: startDate,
        end_date: endDate,
    }
    if (contentType !== 'all') {
        params.content_type = contentType
    }
    return await axios({
        url: `${AADHAN_URL}api/reports/time-slot`,
        method: 'GET',
        params,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const updateUgcContent = async (request, contentId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.put(
        `${AADHAN_URL}ugccontent/${contentId}`,
        request,
        config
    )
}

export const getAssemblyConstituencies = async (stateId, token) => {
    let url =
        stateId !== 'All'
            ? `${AADHAN_URL}location/assembly-constituencies/${stateId}`
            : `${AADHAN_URL}location/assembly-constituencies/`

    return await axios({
        url,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getAssemblyConstituenciesReporter = async (stateId, token) => {
    let url = `${AADHAN_URL}assembly-constituencies`
    return await axios({
        url,
        method: 'GET',
        params: {
            state_id: stateId,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getStateContentReport = async (
    languageId,
    day,
    contentStatus,
    employeeId,
    token
) => {
    let params = {
        language_id: languageId,
        day: day,
    }
    if (contentStatus !== 'all') {
        params.content_status = contentStatus
    }
    if (employeeId !== 'All') {
        params.employee_id = employeeId
    }
    return await axios({
        url: `${AADHAN_URL}api/reports/state-content`,
        method: 'GET',
        params,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getVideoReport = async (
    languageId,
    startDate,
    endDate,
    contentType,
    token
) => {
    let params = {
        language_id: languageId,
        start_date: startDate,
        end_date: endDate,
        category_id: 99999,
        content_type: contentType,
    }
    return await axios({
        url: `${AADHAN_URL}api/reports/mux-video-data`,
        method: 'GET',
        params,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getStates = async (token) => {
    return await axios({
        url: `${AADHAN_URL}states`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getDistricts = async (stateId, token) => {
    return await axios({
        url: `${AADHAN_URL}districts`,
        method: 'GET',
        params: {
            state_id: stateId,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getMandals = async (districtId, token) => {
    return await axios({
        url: `${AADHAN_URL}mandals`,
        method: 'GET',
        params: {
            district_id: districtId,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getCSVData = async (
    languageId,
    startDate,
    endDate,
    contentType,
    token
) => {
    return await axios({
        url: `${AADHAN_URL}api/reports/mux-video-report-csv`,
        method: 'POST',
        params: {
            language_id: languageId,
            start_date: startDate,
            end_date: endDate,
            category_id: 99999,
            content_type: contentType,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const createState = async (name, local_name, token) => {
    return await axios({
        url: `${AADHAN_URL}states`,
        method: 'POST',
        data: {
            name,
            local_name: local_name,
            is_union_territory: false,
            daily_content_target: 0,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const createAssemblyConstituency = async (names, stateId, token) => {
    return await axios({
        url: `${AADHAN_URL}assembly-constituencies`,
        method: 'POST',
        data: {
            names,
            state_id: stateId,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const updateAssemblyConstituencies = async (
    names,
    local_name,
    assembly_constituency_id,
    token,
    district_id
) => {
    return await axios({
        url: `${AADHAN_URL}assembly-constituencies/${assembly_constituency_id}`,
        method: 'PUT',
        data: {
            name: names,
            local_name: local_name,
            district_id: district_id,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const updateState = async (names, local_name, state_id, token) => {
    return await axios({
        url: `${AADHAN_URL}states/${state_id}`,
        method: 'PUT',
        data: {
            name: names,
            local_name: local_name,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const updateDistrict = async (names, local_name, district_id, token) => {
    return await axios({
        url: `${AADHAN_URL}districts/${district_id}`,
        method: 'PUT',
        data: {
            name: names,
            local_name: local_name,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const updateMandal = async (
    names,
    local_name,
    assembly_constituency_id,
    mandal_id,
    token
) => {
    return await axios({
        url: `${AADHAN_URL}mandals/${mandal_id}`,
        method: 'PUT',
        data: {
            name: names,
            assembly_constituency_id,
            local_name: local_name,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const createDistrict = async (names, stateId, token) => {
    return await axios({
        url: `${AADHAN_URL}districts`,
        method: 'POST',
        data: {
            names,
            state_id: stateId,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const createMandal = async (names, stateId, districtId, token) => {
    return await axios({
        url: `${AADHAN_URL}mandals`,
        method: 'POST',
        data: {
            names,
            state_id: stateId,
            district_id: districtId,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const createArea = async (areas, mandalId, token) => {
    return await axios({
        url: `${AADHAN_URL}mandals/${mandalId}`,
        method: 'PUT',
        data: {
            areas,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const stateDelete = async (stateId, token) => {
    return await axios({
        url: `${AADHAN_URL}states/${stateId}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const districtDelete = async (districtId, token) => {
    return await axios({
        url: `${AADHAN_URL}districts/${districtId}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const deleteAssemblyConstituencies = async (
    assembly_constituency_id,
    token
) => {
    return await axios({
        url: `${AADHAN_URL}assembly-constituencies/${assembly_constituency_id}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const deleteMandal = async (mandalId, token) => {
    return await axios({
        url: `${AADHAN_URL}mandals/${mandalId}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getElectionData = async (electionType, token) => {
    return await axios({
        url: `${AADHAN_URL}election-results`,
        method: 'GET',
        params: {
            election_type: electionType,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const postElectionData = async (data, token) => {
    return await axios({
        url: `${AADHAN_URL}election-results`,
        method: 'POST',
        data: data,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getAssemblyConstituency = async (assembly, state, token) => {
    return await axios({
        url: `${AADHAN_URL}election-results/constituencies`,
        method: 'GET',
        params: {
            election_type: assembly,
            state,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const postAssemblyConstituency = async (
    data,
    create,
    assembly,
    token
) => {
    return await axios({
        url: `${AADHAN_URL}election-results/constituencies`,
        method: 'POST',
        params: {
            election_type: assembly,
            action: create,
        },
        data: {
            state: data.state,
            name: data.name,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const deleteAssemblyConstituency = async (
    assemblyId,
    assembly,
    state,
    token
) => {
    return await axios({
        url: `${AADHAN_URL}election-results/constituencies/${assemblyId}`,
        method: 'DELETE',
        params: {
            election_type: assembly,
            state,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const updateAssemblyConstituency = async (
    data,
    create,
    assembly,
    token
) => {
    return await axios({
        url: `${AADHAN_URL}election-results/constituencies`,
        method: 'POST',
        params: {
            election_type: assembly,
            action: create,
        },
        data: {
            state: data.state,
            name: data.name,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const createCandidate = async (id, state, assembly, data, token) => {
    return await axios({
        url: `${AADHAN_URL}election-results/candidates`,
        method: 'POST',
        params: {
            election_type: assembly,
            state,
            action: 'create',
        },
        data: {
            state: data.state,
            name: data.name,
            party: data.party,
            party_logo: data.party_logo,
            constituency_id: id,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const deleteCandidate = async (
    id,
    assemblyId,
    state,
    assembly,
    token
) => {
    return await axios({
        url: `${AADHAN_URL}election-results/candidates/${id}`,
        method: 'DELETE',
        params: {
            candidate_id: id,
            election_type: assembly,
            state,
            constituency_id: assemblyId,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const updateCandidate = async (assembly, state, data, token) => {
    return await axios({
        url: `${AADHAN_URL}election-results/candidates`,
        method: 'POST',
        params: {
            election_type: assembly,
            state,
            action: 'update',
        },
        data,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const updateAssembly = async (assembly, data, token) => {
    return await axios({
        url: `${AADHAN_URL}election-results/constituencies`,
        method: 'POST',
        params: {
            election_type: assembly,
            action: 'update',
        },
        data,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const postVotes = async (data, type, state, assemblyId, token) => {
    return await axios({
        url: `${AADHAN_URL}election-results/cadidate-data-entry`,
        method: 'POST',
        params: {
            election_type: 'assembly',
            state,
            constituency_id: assemblyId,
        },
        data,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const postPartyDataEntry = async (
    electionType,
    state,
    mediaName,
    data,
    token
) => {
    return await axios({
        url: `${AADHAN_URL}election-results/party-data-entry`,
        method: 'POST',
        params: {
            election_type: electionType,
            state_name: state,
            media_name: mediaName,
        },
        data,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const contentWordChecker = async (paragraph, languageId, token) => {
    return await axios({
        url: `${AADHAN_URL}wordchecker`,
        method: 'POST',
        data: {
            language_id: languageId,
            paragraph: paragraph,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getReporterInvoices = async (
    month,
    year,
    regenerate,
    state,
    token
) => {
    const params = {
        month,
        year,
        regenerate,
    }
    if (state !== 'all') {
        params.state_id = state
    }
    return await axios({
        url: `${AADHAN_URL}reporters/invoices`,
        method: 'GET',
        params,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const generateInvoices = async (month, year, regenerate, token) => {
    return await axios({
        url: `${AADHAN_URL}reporters/invoices`,
        method: 'POST',
        params: {
            month,
            year,
            regenerate,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getDistrictsReport = async (state, startDate, endDate, token) => {
    return await axios({
        url: `${AADHAN_URL}api/reports/districts-content-report`,
        method: 'GET',
        params: {
            state_id: state,
            start_date: startDate,
            end_date: endDate,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getReportersReport = async (
    district,
    startDate,
    endDate,
    token
) => {
    return await axios({
        url: `${AADHAN_URL}api/reports/reporters-content-report`,
        method: 'GET',
        params: {
            district_name: district,
            start_date: startDate,
            end_date: endDate,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const updateReporterBonus = async (
    user_id,
    month,
    year,
    bonus,
    token
) => {
    return await axios({
        url: `${AADHAN_URL}reporters/invoices/bonus/${user_id}`,
        method: 'PUT',
        params: {
            user_id,
            month,
            year,
            bonus,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const updateInvoiceStatus = async (month, year, status, user, token) => {
    return await axios({
        url: `${AADHAN_URL}reporters/invoices/invoice-status`,
        method: 'PUT',
        data: user,
        params: {
            month: month,
            year: year,
            invoice_status: status,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const updatePayRollStatus = async (month, year, status, token) => {
    return await axios({
        url: `${AADHAN_URL}reporters/invoices/payroll-status`,
        method: 'PUT',
        params: {
            month: month,
            year: year,
            payroll_status: status,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const downloadStatement = async (month, year, state, token) => {
    return await axios({
        url: `${AADHAN_URL}reporters/invoices/generate-invoices-csv`,
        method: 'GET',
        params: {
            month: month,
            year: year,
            state_id: state,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getAstrologyMasterData = async (token, dataType) => {
    return await axios({
        url: `${AADHAN_URL}astrology/master-data`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            data_type: dataType,
        },
    })
}

export const getDailyHoroscope = async (token, year, month) => {
    return await axios({
        url: `${AADHAN_URL}astrology/daily-horoscopes`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            year,
            month,
        },
    })
}

export const getWeeklyHoroscope = async (token, year, month) => {
    return await axios({
        url: `${AADHAN_URL}astrology/weekly-horoscopes`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            year,
            month,
        },
    })
}

export const getMonthlyHoroscope = async (token, year) => {
    return await axios({
        url: `${AADHAN_URL}astrology/monthly-horoscopes`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            year,
        },
    })
}
export const getYearlyHoroscope = async (token) => {
    return await axios({
        url: `${AADHAN_URL}astrology/yearly-horoscopes`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const updateDailyHoroscope = async (
    id,
    language,
    zodiac,
    token,
    general,
    health,
    finance,
    career,
    family
) => {
    return await axios({
        url: `${AADHAN_URL}astrology/horoscopes/${id}`,
        method: 'PUT',
        data: {
            language_id: language,
            zodiac: zodiac,
            general: general,
            health: health,
            finance: finance,
            career: career,
            family: family,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const getReportersPaymentReport = async (
    token,
    year,
    month,
    state_id
) => {
    return await axios({
        url: `${AADHAN_URL}api/reports/reporters-payement-report`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            year,
            month,
            state_id,
        },
    })
}
export const getSubCategories = async (token, laguage_id, category_id) => {
    return await axios({
        url: `${AADHAN_URL}sub-categories`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            language_id: laguage_id,
            category_id: category_id,
        },
    })
}
export const SubCategories = async (token, request) => {
    return await axios({
        url: `${AADHAN_URL}sub-categories`,
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: request,
    })
}
export const updateSubCategories = async (token, request, sub_category_id) => {
    return await axios({
        url: `${AADHAN_URL}sub-categories/${sub_category_id}`,
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: request,
    })
}
export const getSubCategoryById = async (token, sub_category_id) => {
    return await axios({
        url: `${AADHAN_URL}sub-categories/${sub_category_id}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const deleteSubCategories = async (token, sub_category_id) => {
    return await axios({
        url: `${AADHAN_URL}sub-categories/${sub_category_id}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getUgContentReport = async (
    stateId,
    status,
    start,
    end,
    token,
    content_status
) => {
    return await axios({
        url: `${AADHAN_URL}api/reports/ug-content-report`,
        method: 'GET',
        params: {
            is_compensated: status,
            end_date: end,
            start_date: start,
            state_id: stateId,
            content_status: content_status,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getPanchangamDate = async (year, month, token) => {
    return await axios({
        url: `${AADHAN_URL}astrology/panchangam`,
        method: 'GET',
        params: {
            year,
            month,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const updatePanchangam = async (panchangam_id, request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.put(
        `${AADHAN_URL}astrology/panchangam/${panchangam_id}`,
        request,
        config
    )
}
export const getKeyWordSearch = async (token, languageOption) => {
    return await axios({
        url: `${AADHAN_URL}api/reports/search_by_keywords_report`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            language_id: languageOption,
        },
    })
}

//Elections Api

export const getElectionStates = async (token) => {
    return await axios({
        url: `${AADHAN_URL}elections/states`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getElectionParties = async (token) => {
    return await axios({
        url: `${AADHAN_URL}elections/parties`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getEelectionImageUrl = async (imageFile, token) => {
    if (imageFile) {
        const formData = new FormData()
        formData.append('file', imageFile)

        const config = {
            params: {},
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
            },
        }

        return await axios.post(
            `${AADHAN_URL}elections/img-upload`,
            formData,
            config
        )
    }
}

export const createElectionParties = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.post(`${AADHAN_URL}elections/parties`, request, config)
}

export const getElectionConstituencies = async (type, state_id, token) => {
    return await axios({
        url: `${AADHAN_URL}elections/constituencies`,
        method: 'GET',
        params: {
            state_id: state_id,
            election_type: type,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const createElection = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.post(`${AADHAN_URL}elections/records`, request, config)
}
export const deleteParties = async (id, token) => {
    return await axios({
        url: `${AADHAN_URL}elections/parties/${id}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const updateParties = async (request, id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    return await axios.put(
        `${AADHAN_URL}elections/parties/${id}`,
        request,
        config
    )
}

export const getMedia = async (token) => {
    return await axios({
        url: `${AADHAN_URL}elections/media`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const deleteMedia = async (id, token) => {
    return await axios({
        url: `${AADHAN_URL}elections/media/${id}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const createMedia = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.post(`${AADHAN_URL}elections/media`, request, config)
}
export const updateMedia = async (request, id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    return await axios.put(
        `${AADHAN_URL}elections/media/${id}`,
        request,
        config
    )
}
export const deleteCandidates = async (id, token) => {
    return await axios({
        url: `${AADHAN_URL}elections/candidates/${id}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const createCandidateElection = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    return await axios.post(
        `${AADHAN_URL}elections/candidates`,
        request,
        config
    )
}
export const updateCandidateElection = async (request, id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    return await axios.put(
        `${AADHAN_URL}elections/candidates/${id}`,
        request,
        config
    )
}
export const getElections = async (token) => {
    return await axios({
        url: `${AADHAN_URL}elections/records`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const getSelectedStateForElection = async (id, token) => {
    return await axios({
        url: `${AADHAN_URL}elections/records/${id}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const getCandidates = async (token) => {
    return await axios({
        url: `${AADHAN_URL}elections/candidates`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const updateElection = async (request, id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    return await axios.patch(
        `${AADHAN_URL}elections/records/${id}`,
        request,
        config
    )
}
export const createPulse = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.post(`${AADHAN_URL}pulse/insert-data`, request, config)
}
export const getStateParties = async (token, id) => {
    if (id !== undefined && id !== '') {
        return await axios({
            url: `${AADHAN_URL}pulse/get-config/${id}`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
    }
    return { data: [] }
}
export const deletePulse = async (token, Event_name) => {
    return await axios({
        url: `${AADHAN_URL}pulse/${Event_name}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const getPulse = async (token, id) => {
    return await axios({
        url: `${AADHAN_URL}pulse/get-pulse/${id}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const updatePulse = async (req, token) => {
    return await axios({
        url: `${AADHAN_URL}pulse/update-data`,
        method: 'PUT',
        data: req,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const createPulsePolls = async (req, token) => {
    return await axios({
        url: `${AADHAN_URL}pulse/insert-poll`,
        method: 'POST',
        data: req,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getElectionDistricts = async (stateId, token) => {
    return await axios({
        url: `${AADHAN_URL}elections/districts`,
        method: 'GET',
        params: {
            state_id: stateId,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const getElectionDistrictsAssembly = async (district_cd, token) => {
    return await axios({
        url: `${AADHAN_URL}elections/district-assembly-constituencies?district_cd=${district_cd}`,
        method: 'GET',
        params: {
            district_cd_id: district_cd,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const getRejectedReasons = async (language_id, token) => {
    return await axios({
        url: `${AADHAN_URL}ugccontent/rejected-reasons`,
        method: 'GET',
        params: {
            language_id: language_id,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const getExitPolls = async (token) => {
    return await axios({
        url: `${AADHAN_URL}elections/exit-polls`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const createExitPolls = async (req, token) => {
    return await axios({
        url: `${AADHAN_URL}elections/exit-polls`,
        method: 'POST',
        data: req,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const editExitPolls = async (id, req, token) => {
    return await axios({
        url: `${AADHAN_URL}elections/exit-polls/${id}`,
        method: 'PUT',
        data: req,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const deleteExitPolls = async (id, token) => {
    return await axios({
        url: `${AADHAN_URL}elections/exit-polls/${id}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const addRejectedReasons = async (language_id, reason, token) => {
    return await axios({
        url: `${AADHAN_URL}ugccontent/rejected-reasons`,
        method: 'POST',
        data: { language_id: language_id, rejected_reason: reason },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getloksabhaData = async (stateId, electiontype) => {
    return await axios({
        url: `${AADHAN_URL}elections/state-constituencies/${stateId}/${electiontype}`,
        method: 'GET',
    })
}

export const getResults = async (token) => {
    return await axios({
        url: `${AADHAN_URL}elections/election-results`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const createResults = async (req, token) => {
    return await axios({
        url: `${AADHAN_URL}elections/election-results`,
        method: 'POST',
        data: req,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const editResults = async (id, req, token) => {
    return await axios({
        url: `${AADHAN_URL}elections/election-results/${id}`,
        method: 'PUT',
        data: req,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const deleteMediaSource = async (id, token) => {
    return await axios({
        url: `${AADHAN_URL}elections/election-results/${id}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const createConstituencyResult = async (req, token) => {
    return await axios({
        url: `${AADHAN_URL}elections/constituency-results`,
        method: 'POST',
        data: req,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const getConstituencyResult = async (
    token,
    type,
    year,
    state,
    district
) => {
    let params = {}
    if (type !== '' && type !== undefined) {
        params.election_type = type
    }
    if (state !== '' && state !== undefined) {
        params.state_id = state
    }
    if (district !== '' && district !== undefined) {
        params.district_cd = district
    }
    if (year !== '' && year !== undefined) {
        params.election_year = year
    }
    return await axios({
        url: `${AADHAN_URL}elections/constituency-results`,
        method: 'GET',
        params,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const deleteConstituencyResult = async (id, token) => {
    return await axios({
        url: `${AADHAN_URL}elections/constituency-results/${id}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const updateConstituencyResult = async (id, req, token) => {
    return await axios({
        url: `${AADHAN_URL}elections/constituency-results/${id}`,
        method: 'PUT',
        data: req,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const updateRoundByRoundConstituencyResult = async (id, req, token) => {
    return await axios({
        url: `${AADHAN_URL}elections/constituency-results/${id}/round-wise-votes`,
        method: 'PUT',
        data: req,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getAllianceForParties = async (token) => {
    return await axios({
        url: `${AADHAN_URL}elections/alliances`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getInHouseAdsData = async (languageId, token) => {
    return await axios({
        url: `${AADHAN_URL}inhouse-ads-v2/`,
        method: 'GET',
        params: {
            language_id: languageId,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getInHouseAdsDataByAdId = async (ad_id, token) => {
    return await axios({
        url: `${AADHAN_URL}inhouse-ads-v2/${ad_id}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const deleteInhouseAdsRow = async (content_id, token) => {
    return await axios({
        url: `${AADHAN_URL}inhouse-ads-v2/${content_id}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const createInHouseAds = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.post(`${AADHAN_URL}inhouse-ads-v2/`, request, config)
}

export const updateInHouseAds = async (request, contentId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.put(
        `${AADHAN_URL}inhouse-ads-v2/${contentId}`,
        request,
        config
    )
}

export const updateContentLock = async (
    content_id,
    content_type,
    is_reviewing,
    content_status,
    token
) => {
    return await axios({
        url: `${AADHAN_URL}ugccontent/content-lock/${content_id}`,
        method: 'PUT',
        params: {
            content_type,
            is_reviewing,
            content_status,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getEmployeeShifts = async (token) => {
    return await axios({
        url: `${AADHAN_URL}employee/shifts`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const updateEmployeeShifts = async (token, shift_id, request) => {
    return await axios({
        url: `${AADHAN_URL}employee/shifts/${shift_id}`,
        method: 'PUT',
        data: request,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const AddEmployeeShifts = async (token, request) => {
    return await axios({
        url: `${AADHAN_URL}employee/shifts`,
        method: 'POST',
        data: request,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const deleteEmployeeShifts = async (token, shift_id) => {
    return await axios({
        url: `${AADHAN_URL}employee/shift/${shift_id}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getVisualsPlaylists = async (token, language_id) => {
    const params = { language_id: language_id }
    return await axios({
        url: `${AADHAN_URL}visuals/playlists/`,
        method: 'GET',
        params,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const createVisualsPlaylists = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.post(`${AADHAN_URL}visuals/playlists/`, request, config)
}

export const getVisualsByPlaylist = async (playlistId, token) => {
    return await axios({
        url: `${AADHAN_URL}visuals/playlists/${playlistId}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const updateVisualsByPlaylist = async (
    playlistId,
    requestData,
    token
) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.put(
        `${AADHAN_URL}visuals/playlists/${playlistId}`,
        requestData,
        config
    )
}

export const deleteVisualsByPlaylist = async (playlistId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.delete(
        `${AADHAN_URL}visuals/playlists/${playlistId}`,
        config
    )
}

export const getVisualsCategory = async (token, language_id, type = null) => {
    const params = {
        language_id: language_id,
        ...(type && type !== 'All' && { type }),
    }
    return await axios({
        url: `${AADHAN_URL}visuals/categories/`,
        method: 'GET',
        params,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const createVisualsCategories = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    return await axios.post(`${AADHAN_URL}visuals/categories/`, request, config)
}

export const getVisualsByCategory = async (categoryId, token) => {
    return await axios({
        url: `${AADHAN_URL}visuals/categories/${categoryId}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const updateVisualsByCategory = async (
    categoryId,
    requestData,
    token
) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.put(
        `${AADHAN_URL}visuals/categories/${categoryId}`,
        requestData,
        config
    )
}

export const deleteVisualsByCategory = async (categoryId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.delete(
        `${AADHAN_URL}visuals/categories/${categoryId}`,
        config
    )
}

export const getVisuals = async (token, language_id, id) => {
    let params = { language_id: language_id }
    if (id !== '' && id !== null) {
        params['category_id'] = id
    }
    return await axios({
        url: `${AADHAN_URL}visuals`,
        params,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const createVisuals = async (request, token, imgfile, categories) => {
    let files = imgfile.map((e) => {
        return e?.originFileObj
    })
    const category_name = categories.filter(
        (e) => e.value === request?.category
    )

    const params = {
        title: request?.title,
        visuals_category_id: request?.category,
        language_id: request?.language,
        is_popular:
            request?.popularCard === undefined ? false : request?.popularCard,
        visuals_category_name: category_name?.[0]?.label,
    }

    const queryString = new URLSearchParams(params).toString()

    const formData = new FormData()
    if (request?.images && request?.images.length > 0) {
        files.forEach((image, index) => {
            formData.append('files', image)
        })
    }

    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        },
    }

    return await axios.post(
        `${AADHAN_URL}visuals?${queryString}`,
        formData,
        config
    )
}

export const updateVisuals = async (
    requestData,
    accessToken,
    fileList,
    categoryOptions,
    removedurl
) => {
    let files = fileList
        .map((e) => e?.originFileObj)
        .filter((e) => e !== undefined)

    const category_name = categoryOptions.find(
        (e) => e.value === requestData?.category
    )

    const params = {
        title: requestData?.title,
        visuals_category_id: requestData?.category,
        language_id: requestData?.language,
        is_popular: requestData?.popularCard,
        visuals_category_name: category_name?.label,
        deleting_urls: removedurl,
    }

    const formData = new FormData()

    const queryString = new URLSearchParams(params).toString()

    if (files.length > 0) {
        files.forEach((image) => {
            formData.append('files', image)
        })
    }

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type':
                files.length > 0 ? 'multipart/form-data' : 'application/json',
        },
    }

    const url = `${AADHAN_URL}visuals/${requestData?.visual_id}?${queryString}`

    return await axios.put(url, files.length > 0 ? formData : {}, config)
}

export const getVisualsById = async (visualId, token) => {
    return await axios({
        url: `${AADHAN_URL}visuals/${visualId}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const deleteVisualsById = async (visualId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    return await axios.delete(`${AADHAN_URL}visuals/${visualId}`, config)
}

export const getMagazines = async (token, language_id, category_id) => {
    let params = { language_id: language_id }
    if (category_id !== '' && category_id !== null) {
        params['category_id'] = category_id
    }

    return await axios({
        url: `${AADHAN_URL}visuals/magazines/`,
        params,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getMagazineById = async (magazineId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    return await axios.get(
        `${AADHAN_URL}visuals/magazines/${magazineId}`,
        config
    )
}

export const createMagazine = async (request, token, imgfile, categories) => {
    let files = imgfile.map((e) => {
        return e?.originFileObj
    })

    const category_name = categories.filter(
        (e) => e.value === request?.category
    )

    const params = {
        title: request?.title,
        visuals_category_id: request?.category,
        visuals_category_name: category_name?.[0]?.label,
        language_id: request?.language,
        unix_published_date: request?.unix_published_date,
    }

    const queryString = new URLSearchParams(params).toString()
    const formData = new FormData()

    // if (request?.images && request?.images.length > 0) {
    //     files.forEach((image) => {
    //         formData.append('files', image)
    //     })
    // }

    if (files.length > 0) {
        files.forEach((file) => {
            formData.append('files', file)
        })
    }

    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        },
    }

    return await axios.post(
        `${AADHAN_URL}visuals/magazines/?${queryString}`,
        formData,
        config
    )
}

export const updateMagazine = async (
    requestData,
    accessToken,
    fileList,
    categoryOptions,
    removedUrls,
    magazineId
) => {
    let files = fileList
        .map((e) => e?.originFileObj)
        .filter((e) => e !== undefined)

    const category = categoryOptions.find(
        (e) => e.value === requestData?.category
    )

    const params = {
        title: requestData?.title,
        visuals_category_id: requestData?.category,
        language_id: requestData?.language,
        // visuals_category_name: requestData.category_name,
        visuals_category_name: category?.label,
        deleting_urls: removedUrls?.join(','),
        unix_published_date: requestData?.unix_published_date,
    }

    const formData = new FormData()
    if (files.length > 0) {
        files.forEach((image) => {
            formData.append('files', image)
        })
    }

    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type':
                files.length > 0 ? 'multipart/form-data' : 'application/json',
        },
    }

    const queryString = new URLSearchParams(params).toString()
    const url = `${AADHAN_URL}visuals/magazines/${magazineId}?${queryString}`

    return await axios.put(url, files.length > 0 ? formData : {}, config)
}

export const deleteMagazineById = async (magazineId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    return await axios.delete(
        `${AADHAN_URL}visuals/magazines/${magazineId}`,
        config
    )
}

export const getCoverPageImageUrl = async (
    imageFile,
    category,
    token,
    language
) => {
    if (imageFile) {
        const formData = new FormData()
        formData.append('file', imageFile)

        const config = {
            params: {
                category: category,
                language_id: language,
            },
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
            },
        }

        return await axios.post(
            `${AADHAN_URL}visuals/categories/coverpage`,
            formData,
            config
        )
    }
}

//ug content junior editor reporters
export const getUGContent = async (
    token,
    language_id,
    employee_id,
    start_date,
    end_date
) => {
    let params = {
        language_id,
    }
    if (employee_id !== undefined && employee_id !== null) {
        params['employee_id'] = employee_id
    }
    if (start_date !== undefined && end_date !== undefined) {
        params['start_date'] = start_date
        params['end_date'] = end_date
    }

    return await axios({
        url: `${AADHAN_URL}ugc-dashboard/contents`,
        params,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getEditorContent = async (
    token,
    language_id,
    start_date,
    end_date
) => {
    const params = {
        language_id,
        state_id: '',
        start_date,
        end_date,
    }
    return await axios({
        url: `${AADHAN_URL}ugc-dashboard/employee-details`,
        params,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const getConstituencyContent = async (
    token,
    language_id,
    state_id,
    district_id,
    start_date,
    end_date
) => {
    const params = {
        language_id,
        state_id,
        district_id: district_id === 'all' ? '' : district_id,
        start_date,
        end_date,
    }
    return await axios({
        url: `${AADHAN_URL}ugc-dashboard/ugc-constituencies-reports`,
        params,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const reassignContent = async (
    token,
    source_employee_id,
    language_id,
    day_off
) => {
    const params = {
        source_employee_id,
        day_off,
        language_id,
    }

    return await axios({
        url: `${AADHAN_URL}ugc-dashboard/reassigning-ugcontent`,
        params,
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const isAvailableApi = async (
    token,
    is_availdable,
    day_off,
    day_status
) => {
    const params = {
        is_availdable,
        day_off,
        day_status,
    }
    return await axios({
        url: `${AADHAN_URL}ugc-dashboard/availdability`,
        params,
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const uploadImageToTest = async (imageFile, token) => {
    if (imageFile) {
        const formData = new FormData()
        formData.append('file', imageFile)

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
                Accept: 'application/json',
            },
        }

        return await axios.post(
            `${AADHAN_URL}uploadimage/r2imageupload-testing`,
            formData,
            config
        )
    }
}

export const getHoroscopeCards = async (token, language_id, date) => {
    const params = { language_id: language_id, date: date }
    return await axios({
        url: `${AADHAN_URL}visuals/horoscope-card/horoscope`,
        method: 'GET',
        params,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const createHoroscopeCard = async (request) => {
    try {
        const response = await axios.post(
            `${AADHAN_URL}visuals/horoscope-card/horoscope`,
            request
            // config
        )
        return response
    } catch (error) {
        console.error('Error in createHoroscopeCard:', error)
        throw error
    }
}

export const updateHoroscopeCard = async (horoscope_id, request) => {
    const config = {}
    try {
        const response = await axios.put(
            `${AADHAN_URL}visuals/horoscope-card/horoscope/${horoscope_id}`,
            request
            // config
        )
        return response
    } catch (error) {
        console.error('Error in updateHoroscopeCard:', error)
        throw error
    }
}
export const deleteHoroscopeCard = async (horoscope_id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.delete(
        `${AADHAN_URL}visuals/horoscope-card/horoscope/${horoscope_id}`,
        config
    )
}
export const getVideos = async (
    language_id,
    stringer_type,
    start_date,
    end_date,
    filter_text,
    token
) => {
    const params = {
        language_id,
        start_date,
        stringer_type,
        end_date,
        filter_text,
    }
    return await axios({
        url: `${AADHAN_URL}video-library`,
        params,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const getReporterStatus = async (id, start_date, end_date, token) => {
    const params = {
        start_date,
        end_date,
    }
    return await axios({
        url: `${AADHAN_URL}user-stats/${id}`,
        params,
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
    })
}
export const getRules = async (language_id, token) => {
    const params = {
        language_id,
    }
    return await axios({
        url: `${AADHAN_URL}dashboard-rules`,
        params,
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
    })
}
export const createRules = async (token, request) => {
    return await axios({
        url: `${AADHAN_URL}dashboard-rules`,
        method: 'POST',
        data: request,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const deleteRule = async (token, rule_id) => {
    return await axios({
        url: `${AADHAN_URL}dashboard-rules/${rule_id}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const updateRule = async (token, id, request) => {
    return await axios({
        url: `${AADHAN_URL}dashboard-rules/${id}`,
        method: 'PUT',
        data: request,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getImages = async (language_id, filter_text, token) => {
    const params = {
        language_id,
        filter_text,
    }
    return await axios({
        url: `${AADHAN_URL}image-library`,
        params,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getPanchangamCard = async (token, language_id, date) => {
    const params = { language_id: language_id, date: date }
    return await axios({
        url: `${AADHAN_URL}visuals/daily-panchangam/panchangam`,
        method: 'GET',
        params,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const createPanchangamCard = async (request, token) => {
    try {
        const response = await axios.post(
            `${AADHAN_URL}visuals/daily-panchangam/panchangam`,
            request
            // {
            //     headers: {
            //         'Authorization': `Bearer ${token}`
            //     }
            // }
        )
        return response
    } catch (error) {
        console.error('Error in createPanchangam:', error)
        throw error
    }
}

export const updatePanchangamCard = async (panchangam_id, request, token) => {
    try {
        const response = await axios.put(
            `${AADHAN_URL}visuals/daily-panchangam/panchangam/${panchangam_id}`,
            request,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
        return response
    } catch (error) {
        console.error('Error in updatePanchangamCard:', error)
        throw error
    }
}

export const deletePanchangamCard = async (panchangam_id, token) => {
    return await axios.delete(
        `${AADHAN_URL}visuals/daily-panchangam/panchangam/${panchangam_id}`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    )
}
export const getSimilarContent = async (language_id, text, token) => {
    const params = {
        language_id,
        text,
    }
    return await axios({
        url: `${AADHAN_URL}similarity-search`,
        params,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getPermissions = async (token) => {
    return await axios({
        url: `${AADHAN_URL}manage_permission`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const createPermissions = async (token, parent, child) => {
    return await axios({
        url: `${AADHAN_URL}manage_permission?parent=${parent}&child=${child}`,
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const deletePermissions = async (token, parent, child = null) => {
    if (parent === 'Permission Menu') {
        parent = 'permission_menu'
    }
    const url = child
        ? `${AADHAN_URL}manage_permission?parent=${parent}&child=${child}`
        : `${AADHAN_URL}manage_permission?parent=${parent}`

    return await axios({
        url: url,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

//news sections
export const getNewsSections = async (language_id, status, token) => {
    const params = {
        language_id,
        status,
    }
    return await axios({
        url: `${AADHAN_URL}news-section`,
        params,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const addNewsSection = async (token, request) => {
    return await axios({
        url: `${AADHAN_URL}news-section`,
        method: 'POST',
        data: request,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const updateNewsSection = async (token, request, id) => {
    return await axios({
        url: `${AADHAN_URL}news-section/${id}`,
        method: 'PUT',
        data: request,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const deleteNewsSection = async (token, id) => {
    return await axios({
        url: `${AADHAN_URL}news-section/${id}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getSnippetCategories = async (
    languageId,
    recurrenceType,
    token
) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            language_id: languageId,
            reccurence_type: recurrenceType,
        },
    }
    return await axios.get(`${AADHAN_URL}snippet-category`, config)
}

export const createSnippetCategory = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    return await axios.post(`${AADHAN_URL}snippet-category`, request, config)
}

export const updateSnippetCategory = async (categoryId, requestData, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            snippet_category_id: categoryId,
        },
    }

    return await axios.put(`${AADHAN_URL}snippet-category`, requestData, config)
}

export const deleteSnippetCategory = async (categoryId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            snippet_category_id: categoryId,
        },
    }

    return await axios.delete(`${AADHAN_URL}snippet-category`, config)
}

export const getSnippets = async (
    languageId,
    snippetCategoryId,
    stateId,
    cityId,
    isSpecialContent = false,
    token
) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            language_id: languageId,
            snippet_category_id: snippetCategoryId || '',
            state_id: stateId || '',
            city_id: cityId,
            is_special_content: isSpecialContent,
        },
    }
    return await axios.get(`${AADHAN_URL}snippet`, config)
}

export const createSnippet = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    return await axios.post(`${AADHAN_URL}snippet`, request, config)
}

export const updateSnippet = async (contentId, requestData, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            content_id: contentId,
        },
    }

    return await axios.put(`${AADHAN_URL}snippet`, requestData, config)
}

export const deleteSnippet = async (contentId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            content_id: contentId,
        },
    }

    return await axios.delete(`${AADHAN_URL}snippet`, config)
}
export const logoutEmployee = async (token, id, status) => {
    return await axios({
        url: `${AADHAN_URL}logout?employee_id=${id}&status=${status}`,
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getImagesFromGoogleApi = async (search_query, start, token) => {
    return await axios({
        url: `${AADHAN_URL}image-search`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            search_query,
            start,
        },
    })
}
export const googleImageSearch = async (search) => {
    return await axios({
        url: `${AADHAN_URL}image-search?search_query=${search}&start=0`,
        method: 'GET',
    })
}
export const createFileObject = async (url, token) => {
    return await axios({
        url: `${AADHAN_URL}create-file-obj`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            url,
        },
        responseType: 'blob',
    })
}
export const getOTP = async (mobile_number) => {
    return await axios({
        url: `${AADHAN_URL}otp/send`,
        method: 'GET',
        params: {
            mobile_number,
        },
    })
}
export const verifyOTP = async (mobile_number, otp) => {
    return await axios({
        url: `${AADHAN_URL}otp/verify`,
        method: 'GET',
        params: {
            mobile_number,
            otp,
        },
    })
}

export const getFlaggedComments = async (token) => {
    return await axios({
        url: `${AADHAN_URL}flagged-comments`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const convertImageUrl = async (originalUrl, language, token) => {
    try {
        const encodedUrl = encodeURIComponent(originalUrl)
        const path = `${language.toLowerCase()}/shortnews/`
        const url = `${AADHAN_URL}url-to-url?url=${encodedUrl}&bucket_name=snippets&path=${path}`

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json',
            },
        }

        const response = await axios.get(url, config)
        return response.data
    } catch (error) {
        console.error('Error converting URL:', error)
        throw error
    }
}

export const getUnsyncedShortNews = async (
    languageId,
    categoryId,
    startDate,
    endDate,
    page,
    limit,
    token,
    created_type
) => {
    return await axios({
        url: `${AADHAN_URL}shortnews/unsynced`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            language_id: languageId,
            category_id: categoryId,
            start_date: startDate,
            end_date: endDate,
            page: page,
            limit: limit,
            created_type: created_type,
        },
    })
}

export const updateConfigs = async (token, request, config_for) => {
    return await axios({
        url: `${AADHAN_URL}app-config?config_for=${config_for}`,
        method: 'POST',
        data: request,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const updateReporterConfigs = async (token, request) => {
    try {
        const response = await axios({
            url: `${AADHAN_URL}reporter-config`, // Ensure this matches your backend route
            method: 'POST',
            data: request, // Payload for the API
            headers: {
                Authorization: `Bearer ${token}`, // Token for API access
                'Content-Type': 'application/json', // Ensure the content type is specified
            },
        })

        return response.data
    } catch (error) {
        console.error(
            'Error updating reporter configs:',
            error.response?.data || error.message
        )
        throw error // Re-throw the error for the caller to handle
    }
}

export const getConfigs = async (token, configType) => {
    try {
        const response = await axios({
            url: `${AADHAN_URL}app-config?config_for=${configType}`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        return response.data
    } catch (error) {
        console.error('Error fetching configs:', error)
        throw error
    }
}

export const getReporterConfigs = async (token, languageId) => {
    try {
        const response = await axios({
            url: `${AADHAN_URL}reporter-config?language_id=${languageId}`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
        return response.data
    } catch (error) {
        console.error('Error fetching configs:', error)
        throw error
    }
}
export const getFaq = async (token, languageId) => {
    try {
        const response = await axios.get(`${AADHAN_URL}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: { language_id: languageId },
        })
        return response.data.reporter_faq
    } catch (error) {
        console.error('Error fetching users:', error)
        throw error
    }
}

export const addFaq = async (token, { language_id, question, answer }) => {
    try {
        const response = await axios.post(
            `${AADHAN_URL}faq`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    language_id,
                    question,
                    answer,
                },
            }
        )

        return response.data
    } catch (error) {
        console.error(
            'Error adding FAQ:',
            error.response?.data || error.message
        )
        throw error
    }
}

export const editFaq = async (token, languageId, userId, question, answer) => {
    try {
        const response = await axios.put(
            `${AADHAN_URL}faq`,
            { question, answer },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    language_id: languageId,
                    faq_id: userId,
                    question,
                    answer,
                },
            }
        )

        if (!response.data.updated_faq) {
            throw new Error('Updated FAQ data missing in the response.')
        }

        return response.data.updated_faq
    } catch (error) {
        console.error(
            'Error editing FAQ:',
            error.response?.data || error.message
        )
        throw error
    }
}
export const deleteFaq = async (userId, token, { language_id }) => {
    try {
        const response = await axios.delete(`${AADHAN_URL}faq`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                faq_id: userId,
                language_id,
            },
        })

        return response.data
    } catch (error) {
        console.error(
            'Error deleting FAQ:',
            error.response?.data || error.message
        )
        throw error
    }
}

export const getCities = async (token, state_id) => {
    return await axios({
        url: `${AADHAN_URL}cities`,
        params: {
            state_id,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const CreateCity = async (token, state_id, names) => {
    return await axios({
        url: `${AADHAN_URL}cities`,
        method: 'POST',
        data: {
            names,
            state_id,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const updateCity = async (token, city_id, name) => {
    return await axios({
        url: `${AADHAN_URL}cities/${city_id}`,
        method: 'PUT',
        data: {
            name,
        },
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const deleteCity = async (token, city_id) => {
    return await axios({
        url: `${AADHAN_URL}cities/${city_id}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getAdvertisers = async (token) => {
    return await axios({
        url: `${AADHAN_URL}advertisers`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const createAdvertiser = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.post(`${AADHAN_URL}advertisers`, request, config)
}

export const updateAdvertiser = async (request, advertiser_id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.put(
        `${AADHAN_URL}advertisers/${advertiser_id}`,
        request,
        config
    )
}

export const deleteAdvertiser = async (advertiser_id, token) => {
    return await axios({
        url: `${AADHAN_URL}advertisers/${advertiser_id}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const createSongs = async (token, request) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    return await axios.post(`${AADHAN_URL}devotions/songs`, request, config)
}
export const getAllSongs = async (
    token,
    language_id,
    content_status,
    playlist_id
) => {
    const params = {
        language_id,
        content_status,
        ...(playlist_id !== undefined && { playlist_id }), // Add playlist_id only if it's defined
    }

    return await axios({
        url: `${AADHAN_URL}devotions/songs`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params, // Axios handles query string construction
    })
}
export const getAllPodcasts = async (token, language_id, content_status) => {
    const params = {
        language_id,
        content_status,
    }

    return await axios({
        url: `${AADHAN_URL}devotions/podcasts`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params,
    })
}
export const createPodcast = async (token, request) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    return await axios.post(`${AADHAN_URL}devotions/podcasts`, request, config)
}
export const deletePodcast = async (token, id) => {
    return await axios({
        url: `${AADHAN_URL}devotions/podcasts/${id}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const updatePodcast = async (token, request, id) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    return await axios.put(
        `${AADHAN_URL}devotions/podcasts/${id}`,
        request,
        config
    )
}
export const getPodcastById = async (token, id) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    return await axios.get(`${AADHAN_URL}devotions/podcasts/${id}`, config)
}

export const updateSongs = async (token, request, id) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    return await axios.put(
        `${AADHAN_URL}devotions/songs/${id}`,
        request,
        config
    )
}
export const deleteSong = async (token, id) => {
    return await axios({
        url: `${AADHAN_URL}devotions/songs/${id}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const createPlaylist = async (token, request) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    return await axios.post(`${AADHAN_URL}devotions/playlists`, request, config)
}
export const getAllPlaylists = async (token, language_id, content_status) => {
    return await axios({
        url: `${AADHAN_URL}devotions/playlists?language_id=${language_id}&content_status=${content_status}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const updateplaylist = async (token, request, id) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    return await axios.put(
        `${AADHAN_URL}devotions/playlists/${id}`,
        request,
        config
    )
}
export const deletePlaylist = async (token, id) => {
    return await axios({
        url: `${AADHAN_URL}devotions/playlists/${id}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
export const addSongsToPlaylist = async (token, request, id) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    return await axios.patch(
        `${AADHAN_URL}devotions/playlists/${id}`,
        request,
        config
    )
}
export const getEmployeeDepartments = async (token) => {
    return await axios({
        url: `${AADHAN_URL}employee-department`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const createEmployeeDepartments = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.post(`${AADHAN_URL}employee-department`, request, config)
}

export const updateEmployeeDepartments = async (
    request,
    employee_department_id,
    token
) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    return await axios.put(
        `${AADHAN_URL}employee-department/${employee_department_id}`,
        request,
        config
    )
}

export const deleteEmployeeDepartments = async (
    employee_department_id,
    token
) => {
    return await axios({
        url: `${AADHAN_URL}employee-department/${employee_department_id}`,
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const checkSpelling = async (paragraph, language_id, token) => {
    const request = {
        language_id,
        paragraph,
    }
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    try {
        const response = await axios.post(
            `${AADHAN_URL}wrong-words`,
            request,
            config
        )
        return response
    } catch (error) {
        console.error('Error during API call:', error.message)
    }
}

export const getRolesByDepartment = async (token, employee_department_id) => {
    return await axios({
        url: `${AADHAN_URL}roles/employee_department_id/${employee_department_id}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}
//Create revenue
export const createRevenue = async (formData, token) => {
    const url = `${AADHAN_URL}revenue`
    try {
        return await axios.post(url, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
    } catch (error) {
        console.error('API URL:', url)
        console.error('Form Data:', formData)
        console.error('Axios Error:', error.response?.data || error.message)
        throw error
    }
}

export const createLead = async (formData, token) => {
    const url = `${AADHAN_URL}lead`
    try {
        return await axios.post(url, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        })
    } catch (error) {
        console.error('API URL:', url)
        console.error('Form Data:', formData)
        console.error('Axios Error:', error.response?.data || error.message)
        throw error
    }
}

export const getLeads = async (token, queryParams = {}) => {
    return await axios.get(`${AADHAN_URL}lead`, {
        params: {
            lead_status: queryParams.leadStatus || null,
            lead_generatedby: queryParams.leadGeneratedBy || null,
            lead_owner: queryParams.leadOwner || null,
            lead_type: queryParams.leadType || null,
        },
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    })
}
export const updateLeadStatus = async (leadId, status, token) => {
    const url = `${AADHAN_URL}lead/${leadId}/status`
    try {
        return await axios.put(
            url,
            null, // no request body needed since status is in query params
            {
                params: { lead_status: status },
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            }
        )
    } catch (error) {
        console.error('API URL:', url)
        console.error('Status:', status)
        console.error('Axios Error:', error.response?.data || error.message)
        throw error
    }
}

// Fetch revenues
export const getRevenues = async (token, queryParams = {}) => {
    return await axios.get(`${AADHAN_URL}revenue`, {
        params: {
            closed_by: queryParams.closedBy || null,
            Ad_type: queryParams.adType || null,
            start_date: queryParams.startDate,
            end_date: queryParams.endDate,
        },
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    })
}

// Update an existing revenue entry (if needed)
export const updateRevenue = async (request, revenue_id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    }

    try {
        return await axios.put(
            `${AADHAN_URL}revenue/${revenue_id}`,
            request,
            config
        )
    } catch (error) {
        console.error(
            'Update Revenue Error:',
            error.response?.data || error.message
        )
        throw error
    }
}
export const getEmployeesByRoles = async (token, roleType) => {
    return await axios({
        url: `${AADHAN_URL}revenue/by-roles/employees`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        params: {
            role_type: roleType,
        },
    })
}

export const getCommentsByStatus = async (
    status,
    language,
    start_date,
    end_date,
    token
) => {
    return await axios({
        url: `${AADHAN_URL}comments?status=${status}&language_id=${language}&start_date=${start_date}&end_date=${end_date}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const updateComment = async (commentId, action, status, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    return await axios.patch(
        `${AADHAN_URL}comments/${commentId}?action=${action}&status=${status}`,
        {},
        config
    )
}
export const createTransaction = async (request, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    return await axios.post(
        `${AADHAN_URL}reporters/transactions`,
        request,
        config
    )
}
export const getStringerCounts = async (language_id, state_id, token) => {
    let url = `${AADHAN_URL}reporters/counts/stringers`

    const params = new URLSearchParams()
    if (language_id) params.append('language_id', language_id)
    if (state_id) params.append('state_id', state_id)

    if (params.toString()) {
        url += `?${params.toString()}`
    }

    return await axios({
        url: url,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getReporterStatusChanges = async (language, state_id, token) => {
    return await axios({
        url: `${AADHAN_URL}reporters/counts/status-changes?language_id=${language}&state_id=${state_id}`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    })
}

export const getInactiveStringers = async (language, state_id, token) => {
    return await axios({
        url: `${AADHAN_URL}reporters/inactive-stringers/`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        params: {
            language: language,
            state_id: state_id,
        },
    })
}

export const getAppAndCitizenStringers = async (
    language_id,
    mobileNumber,
    token
) => {
    let params = {}

    if (language_id) {
        params.language_id = language_id
    }
    if (mobileNumber.length) {
        params.search_with_mobile = mobileNumber
    }
    return await axios({
        url: `${AADHAN_URL}reporters/stringers/appandstringers`,
        method: 'GET',
        params,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const updateStringerType = async (
    stringer_type,
    user_id,
    updated_by,
    token
) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    return await axios.put(
        `${AADHAN_URL}reporters/stringers/update-type?user_id=${user_id}&stringer_type=${stringer_type}&updated_by=${updated_by}`,
        {},
        config
    )
}

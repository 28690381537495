import { Form, Input, message, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import {
    createEmployeeDepartments,
    updateEmployeeDepartments,
} from '../../../AadhanApi'

function AddEmployeeDepartments({
    isModalOpen,
    handleCancel,
    editingDepartments,
    token,
    onSuccess,
}) {
    const [form] = Form.useForm()
    const [isEditing, setIsEditing] = useState(false)

    const employee_name = localStorage.getItem('EMPLOYEE_NAME')
    const employee_id = localStorage.getItem('EMPLOYEE_ID')

    useEffect(() => {
        if (editingDepartments) {
            form.setFieldsValue({
                department: editingDepartments.employee_department_name,
            })
            setIsEditing(true)
        } else {
            form.resetFields()
            setIsEditing(false)
        }
    }, [editingDepartments, isModalOpen])

    const handleSave = async () => {
        try {
            await form.validateFields()

            const values = form.getFieldValue()

            const request = {
                employee_department_name: values.department,
                created_by: employee_id,
                created_by_name: employee_name,
            }

            if (isEditing && editingDepartments) {
                const updateRequest = {
                    ...request,
                    employee_department_id:
                        editingDepartments.employee_department_id,
                    created_at: editingDepartments.created_at,
                }
                await updateEmployeeDepartments(
                    updateRequest,
                    editingDepartments.employee_department_id,
                    token
                )
                message.success('Department updated successfully')
            } else {
                await createEmployeeDepartments(request, token)
                message.success('Department added successfully')
            }

            form.resetFields()
            onSuccess()
            handleCancel()
        } catch (error) {
            if (error.errorFields) {
                return
            }
            message.error(
                error.response?.data?.message ||
                    (isEditing
                        ? 'Failed to update department'
                        : 'Failed to add department')
            )
        }
    }

    return (
        <div>
            <Modal
                title={
                    <h3 style={{ fontWeight: '500' }}>
                        {isEditing ? 'Edit department' : 'Add department'}
                    </h3>
                }
                style={{ fontWeight: 550 }}
                open={isModalOpen}
                onCancel={handleCancel}
                width={500}
                okText={isEditing ? 'Update' : 'Save'}
                onOk={handleSave}
                okButtonProps={{
                    style: {
                        backgroundColor: '#1890ff',
                        borderColor: '#1890ff',
                    },
                }}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="add_department_form"
                    scrollToFirstError
                    autoComplete="off"
                >
                    <hr></hr>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1.7vh',
                            marginBottom: '10vh',
                        }}
                    >
                        <div>
                            <Form.Item
                                name="department"
                                label="Department"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input department name',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Enter department name"
                                    style={{ width: '100%', minWidth: '100%' }}
                                />
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </Modal>
        </div>
    )
}

export default AddEmployeeDepartments

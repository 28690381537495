import React, { useState } from 'react'
import { Button, Modal, Input, List, message, Checkbox } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

const AddToPlaylist = ({ playlists, songId, onAddSongToPlaylist }) => {
    const [isPlaylistModalVisible, setIsPlaylistModalVisible] = useState(false)
    const [searchValue, setSearchValue] = useState('')
    const [selectedPlaylist, setSelectedPlaylist] = useState(null)
    const filteredPlaylists = playlists.filter((playlist) =>
        playlist.title.toLowerCase().includes(searchValue.toLowerCase())
    )

    const handleAdd = () => {
        if (!selectedPlaylist) {
            message.error('Please select a playlist.')
            return
        }
        onAddSongToPlaylist(selectedPlaylist, songId)
        setIsPlaylistModalVisible(false)
    }

    return (
        <>
            <Button
                style={{
                    backgroundColor: '#52c41a',
                    height: 20,
                    width: 20,
                    borderRadius: '50%',
                }}
                icon={<PlusOutlined style={{ color: 'white' }} />}
                onClick={() => setIsPlaylistModalVisible(true)}
            />
            <Modal
                title="Select Playlist"
                open={isPlaylistModalVisible}
                onCancel={() => setIsPlaylistModalVisible(false)}
                onOk={handleAdd}
                okText="Add"
                cancelText="Cancel"
            >
                <Input
                    placeholder="Search playlists"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    style={{
                        marginBottom: 8,
                        display: 'flex',
                        minWidth: '100%',
                        backgroundColor: 'white',
                    }}
                />
                <List
                    style={{ maxHeight: 370, overflowY: 'auto' }}
                    dataSource={[...filteredPlaylists]}
                    renderItem={(playlist) => {
                        if (playlist?.content_status === 'approved') {
                            return (
                                <List.Item
                                    style={{
                                        cursor: 'pointer',
                                        color: 'gray',
                                        fontWeight: 500,
                                        display: 'flex',
                                        justifyContent: 'start',
                                        gap: 10,
                                        backgroundColor:
                                            selectedPlaylist?.playlist_id ===
                                            playlist?.playlist_id
                                                ? '#f0f0f0'
                                                : 'white',
                                        padding: '2px 10px',
                                        fontFamily: 'cursive',
                                        fontSize: 14,
                                        margin: '5px 0px',
                                        border: '1px solid lightgray',
                                        boxShadow: '1px 1px 2px lightgray',
                                        borderRadius: '5px',
                                    }}
                                >
                                    <Checkbox
                                        checked={
                                            selectedPlaylist?.playlist_id ===
                                            playlist?.playlist_id
                                        }
                                        onChange={() =>
                                            setSelectedPlaylist(playlist)
                                        }
                                    />
                                    <span style={{ marginRight: '10px' }}>
                                        {playlist.title}
                                    </span>
                                </List.Item>
                            )
                        }
                    }}
                />
            </Modal>
        </>
    )
}

export default AddToPlaylist

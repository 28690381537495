import React, { useEffect, useState } from 'react'
import {
    Form,
    Input,
    Button,
    Select,
    DatePicker,
    Tag,
    Row,
    Col,
    message,
    Popconfirm,
    Modal,
    Tooltip,
} from 'antd'
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import {
    createRules,
    deleteRule,
    getRejectedReasons,
    getRules,
    updateRule,
} from '../../AadhanApi'
import CommonTableWithBorder from '../CommonTable/CommonTableWithBorder'
import { render } from '@testing-library/react'
const { Option } = Select
const RuleForDay = () => {
    const languages = useSelector((state) => state.app.languageData)
    const accessToken = useSelector((state) => state.app.accessToken)
    const [keywords, setKeywords] = useState([])
    const [currentKeyword1, setCurrentKeyword1] = useState('')
    const [WhitelistedKeyword, setWhitelistedKeyword] = useState([])
    const [currentKeyword2, setCurrentKeyword2] = useState('')
    const [rules, setRules] = useState([])
    const [editingRule, setEditingRule] = useState(null)
    const [errorMessage, setErrorMessage] = useState('')
    const [errorMessage2, setErrorMessage2] = useState('')
    const [createdDate, setCreatedDate] = useState(null)
    const [isEditing, setIsEditing] = useState(false)
    const [rejectedData, setRejectedData] = useState([])
    const [loading, setLoading] = useState(false)
    const [priority, setPriority] = useState(1)
    const [reason, setReason] = useState('')
    const [iserror, setIsError] = useState({
        language: false,
        keywords: false,
        ruleName: false,
        createdDate: false,
        whilisted: false,
    })
    const [language_, setLanguage_] = useState(languages?.[0]?.language_id)
    const [date, setDate] = useState(dayjs(new Date()))
    async function getData() {
        setLoading(true)
        const data = await getRules(language_, accessToken)
        setRules(data?.data?.data)
        setLoading(false)
    }
    useEffect(() => {
        async function getData() {
            const rejectedDataResponse = await getRejectedReasons(
                language_,
                accessToken
            )
            setRejectedData(rejectedDataResponse?.data?.data?.rejected_reasons)
        }
        getData()
    }, [language_])
    useEffect(() => {
        setRules([])
        getData()
        setIsEditing(false)
        setRuleName('')
        setKeywords([])
    }, [language_, date])
    const [ruleName, setRuleName] = useState('')
    const handleAddKeyword = () => {
        if (currentKeyword1) {
            if (
                keywords.includes(currentKeyword1) ||
                WhitelistedKeyword.includes(currentKeyword1)
            ) {
                setErrorMessage('This keyword already exists!')
            } else {
                setKeywords([...keywords, currentKeyword1])
                setCurrentKeyword1('')
                setErrorMessage('')
            }
        }
    }
    const handleAddKeyword2 = () => {
        if (currentKeyword2) {
            if (
                WhitelistedKeyword.includes(currentKeyword2) ||
                keywords.includes(currentKeyword2)
            ) {
                setErrorMessage2('This keyword already exists!')
            } else {
                setWhitelistedKeyword([...WhitelistedKeyword, currentKeyword2])
                setCurrentKeyword2('')
                setErrorMessage2('')
            }
        }
    }
    const column = [
        {
            title: 'Rule Name',
            render: (_, record) => (
                <div>
                    <div className="rule_name">{record?.rule_name}</div>
                    <div className="rule_type">
                        {record?.rule_handling_type === 2
                            ? 'Auto Reject'
                            : 'Low Priority'}
                    </div>
                </div>
            ),
        },
        {
            title: 'Date',
            dataIndex: 'created_date',
            render: (_, record) => (
                <div>{dayjs(record?.created_date).format('DD MMM YYYY')}</div>
            ),
            defaultSortOrder: 'ascend',
            sorter: (a, b) =>
                dayjs(a.created_date).unix() - dayjs(b.created_date).unix(),
        },
        {
            title: 'Keywords',
            dataIndex: 'key_words',
            render: (_, record) => (
                <div>
                    {record?.key_words?.split(',')?.map((keyword) => (
                        <Tag
                            key={keyword}
                            color="blue"
                            style={{
                                marginBottom: '5px',
                            }}
                        >
                            {keyword}
                        </Tag>
                    ))}
                </div>
            ),
        },
        {
            title: 'Actions',
            render: (text, record, index) => (
                <div>
                    <Button
                        type="primary"
                        onClick={() => handleEditRule(record?.rule_id)}
                        style={{ marginRight: '1rem' }}
                        icon={<EditOutlined />}
                    ></Button>
                    <Popconfirm
                        title="Are you sure you want to delete this rule?"
                        onConfirm={() => handleDeleteRule(record?.rule_id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Tooltip>
                            <Button icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>
                </div>
            ),
        },
    ]
    const handleDeleteKeyword = (keyword) => {
        setKeywords(keywords.filter((k) => k !== keyword))
    }
    const handleDeleteKeyword2 = (keyword) => {
        setWhitelistedKeyword(WhitelistedKeyword.filter((k) => k !== keyword))
    }
    const handleSubmit = async () => {
        const newRule = {
            language_id: language_,
            key_words: keywords.join(','),
            whitelisted_keywords: priority === 2 ? WhitelistedKeyword : [],
            rule_name: ruleName,
            rejected_reason: priority === 2 ? reason : '',
            rule_handling_type: priority,
            created_date: dayjs(createdDate)
                .startOf('day')
                .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        }
        let validation = {
            language: false,
            keywords: false,
            ruleName: false,
            createdDate: false,
            whilisted: false,
        }
        let hasError = false
        if (keywords.length === 0) {
            validation.keywords = true
            hasError = true
        }
        if (WhitelistedKeyword.length === 0 && priority === 2) {
            validation.whilisted = true
            hasError = true
        }
        if (!ruleName || ruleName.trim() === '') {
            validation.ruleName = true
            hasError = true
        }
        if (!createdDate) {
            validation.createdDate = true
            hasError = true
        }
        setIsError(validation)
        if (hasError) {
            return
        }

        if (editingRule !== null) {
            const rule = rules.filter((rule) => rule?.rule_id === editingRule)
            const res = await updateRule(
                accessToken,
                rule?.[0]?.rule_id,
                newRule
            )
            if (res?.data?.success === true) {
                message.success('Rule updated successfully')
                setEditingRule(null)
                setRules([])
                getData()
            } else {
                message.error('Error while updating rule')
            }
        } else {
            const res = await createRules(accessToken, newRule)
            if (res?.data?.success === true) {
                message.success('Rule created successfully')
                setRules([])
                getData()
            } else {
                message.error('Error while creating rule')
            }
        }
        setIsEditing(false)
        resetForm()
        setIsModalOpen(false)
    }
    const handleEditRule = (index) => {
        setIsError({
            language: false,
            keywords: false,
            ruleName: false,
            createdDate: false,
            whilisted: false,
        })
        setIsEditing(true)
        const rule = rules?.filter((e) => e.rule_id === index)?.[0]
        setKeywords(rule.key_words.split(','))
        setRuleName(rule.rule_name)
        setWhitelistedKeyword(rule.whitelisted_keywords ?? [])
        setCreatedDate(dayjs(rule?.created_date))
        setReason(
            rule?.rejected_reason === '' ? undefined : rule?.rejected_reason
        )
        setEditingRule(index)
        setPriority(rule.rule_handling_type ?? 1)
        setIsModalOpen(true)
    }

    const handleDeleteRule = async (index) => {
        const rule = rules.filter((e, i) => e?.rule_id === index)
        const res = await deleteRule(accessToken, rule?.[0]?.rule_id)
        if (res?.data?.success === true) {
            message.success('Rule deleted succes    sfully')
            setRules(rules.filter((e) => e?.rule_id !== index))
        }
    }

    const resetForm = () => {
        setKeywords([])
        setPriority(1)
        setEditingRule(null)
        setWhitelistedKeyword([])
    }
    const [isModalOpen, setIsModalOpen] = useState(false)
    const showModal = () => {
        setRuleName('')
        setPriority(1)
        setKeywords([])
        setWhitelistedKeyword([])
        setCreatedDate(null)
        setIsModalOpen(true)
        setEditingRule(null)
        setReason(undefined)
    }
    const handleOk = () => {
        handleSubmit()
    }
    const handleCancel = () => {
        setIsEditing(false)
        resetForm()
        setIsModalOpen(false)
        setIsError({
            language: false,
            keywords: false,
            ruleName: false,
            createdDate: false,
            whilisted: false,
        })
    }
    return (
        <div>
            <div style={{ display: 'flex', marginBottom: '1rem' }}>
                <Select
                    onChange={(value) => setLanguage_(value)}
                    value={language_}
                    placeholder="Select language"
                    className="language-select"
                >
                    {languages?.map((e) => (
                        <Option value={e.language_id} key={e.language_id}>
                            {e.language_name}
                        </Option>
                    ))}
                </Select>

                <Button
                    type="primary"
                    style={{ marginLeft: 'auto' }}
                    icon={<PlusOutlined />}
                    onClick={showModal}
                >
                    Add Rule
                </Button>
            </div>
            <CommonTableWithBorder
                className={'no-padding-table'}
                data={rules}
                columns={column}
                disablePagination={true}
                dataLoading={loading}
            />

            <Modal
                title={
                    <div>
                        {isEditing ? 'Edit Rule' : 'Add Rule'}{' '}
                        <span
                            style={{ color: 'gray' }}
                        >{`( ${languages?.find((e) => e.language_id === language_)?.language_name} )`}</span>
                    </div>
                }
                open={isModalOpen}
                okText={isEditing ? 'Update Rule' : 'Submit Rule'}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <div>
                    {
                        <Form layout="vertical" onFinish={handleSubmit}>
                            <Form.Item required label="Date">
                                <DatePicker
                                    style={{ width: '60%' }}
                                    value={createdDate}
                                    onChange={(e) => setCreatedDate(e)}
                                />
                            </Form.Item>
                            {iserror?.createdDate && (
                                <div
                                    style={{
                                        color: 'red',
                                        marginTop: '5px',
                                    }}
                                >
                                    {'Date is required'}
                                </div>
                            )}

                            <Form.Item required label="Rule Name">
                                <Input
                                    value={ruleName}
                                    placeholder="Enter Rule Name"
                                    onChange={(e) =>
                                        setRuleName(e.target.value)
                                    }
                                />
                            </Form.Item>
                            {iserror?.ruleName && (
                                <div
                                    style={{
                                        color: 'red',
                                        marginTop: '5px',
                                    }}
                                >
                                    {'Rule name required!'}
                                </div>
                            )}
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item required label="Add Keywords">
                                        <Input
                                            placeholder="Enter a keyword and press enter"
                                            value={currentKeyword1}
                                            onChange={(e) =>
                                                setCurrentKeyword1(
                                                    e.target.value
                                                )
                                            }
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    e.preventDefault() // Prevent form submission
                                                    handleAddKeyword() // Add keyword when Enter is pressed
                                                }
                                            }}
                                            suffix={
                                                <Button
                                                    type="primary"
                                                    icon={<PlusOutlined />}
                                                    onClick={handleAddKeyword}
                                                    disabled={!currentKeyword1}
                                                    className="add-btn"
                                                >
                                                    Add
                                                </Button>
                                            }
                                            className="keyword-input"
                                        />

                                        {errorMessage && (
                                            <div
                                                style={{
                                                    color: 'red',
                                                    marginTop: '5px',
                                                }}
                                            >
                                                {errorMessage}
                                            </div>
                                        )}
                                        {iserror?.keywords && (
                                            <div
                                                style={{
                                                    color: 'red',
                                                    marginTop: '5px',
                                                }}
                                            >
                                                {'keywords is required'}
                                            </div>
                                        )}
                                        <div className="keyword-tags">
                                            {keywords.map((keyword) => (
                                                <Tag
                                                    style={{
                                                        marginBottom: '5px',
                                                    }}
                                                    closable
                                                    onClose={() =>
                                                        handleDeleteKeyword(
                                                            keyword
                                                        )
                                                    }
                                                    key={keyword}
                                                    color="blue"
                                                    className="keyword-tag"
                                                >
                                                    {keyword}
                                                </Tag>
                                            ))}
                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item label="Rule Handling Type ">
                                        <Select
                                            onChange={(value) =>
                                                setPriority(value)
                                            }
                                            value={priority}
                                            style={{ width: 250 }}
                                        >
                                            <Option value={1} key={1}>
                                                Low Priority
                                            </Option>
                                            <Option value={2} key={2}>
                                                {' '}
                                                Auto Reject
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            {priority === 2 && (
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item
                                            required
                                            label="Add Whitelisted Keywords "
                                        >
                                            <Input
                                                placeholder="Enter a keyword and press enter"
                                                value={currentKeyword2}
                                                onChange={(e) =>
                                                    setCurrentKeyword2(
                                                        e.target.value
                                                    )
                                                }
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault() // Prevent form submission
                                                        handleAddKeyword2() // Add keyword when Enter is pressed
                                                    }
                                                }}
                                                suffix={
                                                    <Button
                                                        type="primary"
                                                        icon={<PlusOutlined />}
                                                        onClick={
                                                            handleAddKeyword2
                                                        }
                                                        disabled={
                                                            !currentKeyword2
                                                        }
                                                        className="add-btn"
                                                    >
                                                        Add
                                                    </Button>
                                                }
                                                className="keyword-input"
                                            />

                                            {errorMessage2 && (
                                                <div
                                                    style={{
                                                        color: 'red',
                                                        marginTop: '5px',
                                                    }}
                                                >
                                                    {errorMessage2}
                                                </div>
                                            )}
                                            {iserror?.whilisted && (
                                                <div
                                                    style={{
                                                        color: 'red',
                                                        marginTop: '5px',
                                                    }}
                                                >
                                                    {
                                                        'keywords is required for auto reject'
                                                    }
                                                </div>
                                            )}
                                            <div className="keyword-tags">
                                                {WhitelistedKeyword?.map(
                                                    (keyword) => (
                                                        <Tag
                                                            style={{
                                                                marginBottom:
                                                                    '5px',
                                                            }}
                                                            closable
                                                            onClose={() =>
                                                                handleDeleteKeyword2(
                                                                    keyword
                                                                )
                                                            }
                                                            key={keyword}
                                                            color="blue"
                                                            className="keyword-tag"
                                                        >
                                                            {keyword}
                                                        </Tag>
                                                    )
                                                )}
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}
                            {priority === 2 && (
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item>
                                            <Select
                                                value={reason}
                                                onChange={(value) =>
                                                    setReason(value)
                                                }
                                                placeholder="select rejected reason"
                                            >
                                                {rejectedData?.map((e) => (
                                                    <Option key={e} value={e}>
                                                        {e}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )}
                        </Form>
                    }
                </div>
            </Modal>
        </div>
    )
}
export default RuleForDay

import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, List, Checkbox, Typography } from "antd";
import { PlusOutlined } from "@ant-design/icons";
const { Text } = Typography;

const SongModal = ({ language, data = [], setSongsList, exitSong = [] }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedSongs, setSelectedSongs] = useState([]);
  const [songs, setSongs] = useState([]);
  const audioRefs = useRef({});

  useEffect(() => {
    const filteredSongs = data.filter(
      (song) => !exitSong.some((existing) => existing.song_id === song.song_id)
    );
    setSongs(filteredSongs);
    setSelectedSongs([]);
  }, [data, exitSong]);

  const handleCheckboxChange = (checked, song) => {
    if (checked) {
      setSelectedSongs((prev) => [...prev, song]);
    } else {
      setSelectedSongs((prev) => prev.filter((s) => s.song_id !== song.song_id));
    }
  };

  const handleAddToPlaylist = () => {
    setIsModalVisible(false);
    setSongsList([...exitSong, ...selectedSongs]);
  };

  const tokenRegex = /token=([^&]*)/;
  const playbackIdRegex = /\/([^\.]*).m3u8/;
  const youtubeRegex = /^(http(s)?:\/\/)?((w){3}\.)?youtu(be|.be)?(\.com)?\/.+/;

  const validateURL = (videoPath) => {
    const isYoutube = youtubeRegex.test(videoPath);
    if (
      videoPath &&
      !isYoutube &&
      videoPath.includes("token") &&
      videoPath.includes(".m3u8")
    ) {
      const tokenMatch = videoPath.match(tokenRegex);
      const playbackIdMatch = videoPath.match(playbackIdRegex);

      if (tokenMatch && playbackIdMatch) {
        return {
          token: tokenMatch[1],
          playbackId: playbackIdMatch[1],
        };
      }
    }
    return null;
  };

  const handleAudioPlay = (index) => {
    Object.values(audioRefs.current).forEach((audio, idx) => {
      if (idx !== index && audio) audio.pause();
    });

    if (audioRefs.current[index]) {
      audioRefs.current[index].play();
    }
  };

  const handleOpenModal = () => setIsModalVisible(true);
  const handleCancel = () => {
    Object.values(audioRefs.current).forEach((audio, idx) => {
      if (audio) audio.pause();
    });
    setIsModalVisible(false);}

  return (
    <div>
      <Button
        icon={<PlusOutlined />}
        type="primary"
        onClick={handleOpenModal}
        style={{ marginBottom: 16 }}
      >
        Add Songs to Playlist
      </Button>
      <Modal
        title="Select Songs"
        open={isModalVisible}
        onOk={handleAddToPlaylist}
        onCancel={handleCancel}
        okText="Add to Playlist"
        cancelText="Cancel"
        style={{ backgroundColor: "white" }}
      >
        <List
          dataSource={songs}
          style={{height:400,overflowY:"auto"}}
          renderItem={(song, index) => {
            const validUrl = validateURL(song?.metadata?.[language]?.audio_url);

            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "rgb(243, 243, 234)",
                  padding: "2px 10px",
                  borderRadius: "8px",
                  marginBottom: "10px",
                }}
              >
                <Checkbox
                  onChange={(e) => handleCheckboxChange(e.target.checked, song)}
                  checked={selectedSongs.some(
                    (s) => s.song_id === song.song_id
                  )}
                  style={{ marginRight: 16 }}
                />
                <div style={{ flex: 1 }}>
                  <Text strong style={{ fontSize: "14px" }}>
                    {song.title}
                  </Text>
                  {validUrl && (
                    <audio
                      src={song?.metadata?.[language]?.audio_url?.replace(
                        ".m3u8",
                        "/audio.m4a"
                      )}
                      ref={(el) => (audioRefs.current[index] = el)}
                      controls
                      style={{
                        marginTop: "2px",
                        width: "100%",
                        height:"1.5rem",
                        borderRadius: "4px",
                        
                      }}
                      onPlay={() =>
                        handleAudioPlay(
                          index,
                          songs?.metadata?.[language]?.audio_url
                        )
                      }
                    />
                  )}
                </div>
              </div>
            );
          }}
        />
      </Modal>
    </div>
  );
};

export default SongModal;

import React, { useEffect, useState } from 'react'
import { Button, message, Spin } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { useSelector } from 'react-redux'
import './Panchangam.scss'
import PanchangamCanvas from './PanchangamCanvas'
import {
    languageContent,
    textStartX,
    TEXT_END_X,
    LINE_HEIGHT,
    TIME_BASED_INDICES,
    TEXT_BASED_INDICES,
    FIXED_GAP_BETWEEN_ENTRIES,
} from './Constants'
import {
    createPanchangamCard,
    getPanchangamCard,
    updatePanchangamCard,
    uploadImageToTest,
} from '../../AadhanApi'
import { dayNamesData } from '../Daily Horoscope/LanguageSpecificDays'
import { monthsData } from '../Daily Horoscope/LanguageSpecificMonths.js'
import moment from 'moment'
import PanchangamTemplate from '../../assets/Panchangam/Astrology Thumb Empty (1).jpg'
import PanchangamTemplateTelugu from '../../assets/Panchangam/Astrology Thumb New.jpg'
import PropTypes from 'prop-types'

function Panchangam({
    language,
    month,
    day,
    setIsCoverPageModalVisible,
    setHasPanchangam,
    currentPanchangamId,
    isEditMode,
    existingPanchangamData,
    onEditSuccess,
    uploadedDate,
}) {
    const accessToken = useSelector((state) => state.app.accessToken)
    const [inputValues, setInputValues] = useState({})
    const [isOverlayVisible, setIsOverlayVisible] = useState(false)
    const [overlayMessage, setOverlayMessage] = useState('')

    const TEXT_START_X = textStartX[language] || textStartX.default

    useEffect(() => {
        if (isEditMode && existingPanchangamData) {
            const fetchPanchangamData = async () => {
                try {
                    const response = await getPanchangamCard(
                        accessToken,
                        language,
                        existingPanchangamData.date.split('T')[0]
                    )
                    const panchangamText = response.data.data[0].panchangam_text

                    // Pre-fill input values
                    const newInputValues = {}
                    languageContent[language]?.labels.forEach(
                        (label, index) => {
                            newInputValues[index] = panchangamText[label] || ''
                        }
                    )
                    setInputValues(newInputValues)
                } catch (error) {
                    console.error('Error fetching panchangam data:', error)
                }
            }
            fetchPanchangamData()
        }
    }, [isEditMode, existingPanchangamData])

    const LoadingOverlay = ({ message }) => (
        <div className="loading-overlay">
            <div className="loading-content">
                <Spin size="large" />
                <p>{message}</p>
            </div>
        </div>
    )

    const handleInputChange = (index, value) => {
        const newInputValues = {
            ...inputValues,
            [index]: value,
        }
        setInputValues(newInputValues)
    }

    const validateInputs = () => {
        const content = languageContent[language]
        let isValid = true

        const emptyFields = content.labels.filter(
            (_, index) =>
                !inputValues[index] || inputValues[index].trim() === ''
        )

        if (emptyFields.length > 0) {
            message.error(`Please fill all fields`)
            isValid = false
        }

        return isValid
    }

    const saveImage = async () => {
        if (!validateInputs()) {
            return
        }
        setIsOverlayVisible(true)
        setOverlayMessage('Saving Panchangam Card...')

        try {
            const canvas = document.querySelector('canvas')
            const exportCanvas = document.createElement('canvas')
            const exportCtx = exportCanvas.getContext('2d')

            const scaleFactor = 3.75

            exportCanvas.width = canvas.width * scaleFactor
            exportCanvas.height = canvas.height * scaleFactor

            exportCtx.imageSmoothingEnabled = true
            exportCtx.imageSmoothingQuality = 'high'
            exportCtx.textRendering = 'geometricPrecision'

            const templateImg = new Image()
            templateImg.src =
                language === 2 ? PanchangamTemplateTelugu : PanchangamTemplate

            templateImg.onload = async () => {
                setOverlayMessage('Saving Panchangam Card...')
                exportCtx.drawImage(
                    templateImg,
                    0,
                    0,
                    exportCanvas.width,
                    exportCanvas.height
                )
                const content = languageContent[language]

                const convertRemToPixels = (rem) => {
                    const fontSize = parseFloat(rem)

                    return Math.round(fontSize * 16 * scaleFactor)
                }

                const scaledFontProperties = {
                    heading: {
                        fontSize:
                            content.fontProperties.heading.fontSize.includes(
                                'rem'
                            )
                                ? convertRemToPixels(
                                      content.fontProperties.heading.fontSize
                                  )
                                : parseFloat(
                                      content.fontProperties.heading.fontSize
                                  ) * scaleFactor,
                        fontFamily: content.fontProperties.heading.fontFamily,
                    },
                    labels: {
                        fontSize:
                            content.fontProperties.labels.fontSize.includes(
                                'rem'
                            )
                                ? convertRemToPixels(
                                      content.fontProperties.labels.fontSize
                                  )
                                : parseFloat(
                                      content.fontProperties.labels.fontSize
                                  ) * scaleFactor,
                        fontFamily: content.fontProperties.labels.fontFamily,
                    },

                    timeBasedValues: {
                        fontSize:
                            content.fontProperties.values.timeBasedFont.fontSize.includes(
                                'rem'
                            )
                                ? convertRemToPixels(
                                      content.fontProperties.values
                                          .timeBasedFont.fontSize
                                  )
                                : parseFloat(
                                      content.fontProperties.values
                                          .timeBasedFont.fontSize
                                  ) * scaleFactor,
                        fontFamily:
                            content.fontProperties.values.timeBasedFont
                                .fontFamily,
                    },
                    textBasedValues: {
                        fontSize:
                            content.fontProperties.values.textBasedFont.fontSize.includes(
                                'rem'
                            )
                                ? convertRemToPixels(
                                      content.fontProperties.values
                                          .textBasedFont.fontSize
                                  )
                                : parseFloat(
                                      content.fontProperties.values
                                          .textBasedFont.fontSize
                                  ) * scaleFactor,
                        fontFamily:
                            content.fontProperties.values.textBasedFont
                                .fontFamily,
                    },
                }

                // heading
                // exportCtx.fillStyle = '#F7AB4B'
                exportCtx.fillStyle = '#FEBB58'

                exportCtx.font = `bold ${scaledFontProperties.heading.fontSize}px ${scaledFontProperties.heading.fontFamily}`

                const combinedHeading = `${content.heading} - ${content.heading2}`

                // Center position for the combined heading

                if (language !== 2) {
                    const combinedHeadingWidth =
                        exportCtx.measureText(combinedHeading).width
                    const combinedHeadingX =
                        (exportCanvas.width - combinedHeadingWidth) / 2
                    const combinedHeadingY = 76 * scaleFactor // Y position

                    language === 3
                        ? exportCtx.fillText(
                              combinedHeading,
                              15 * scaleFactor,
                              74 * scaleFactor
                          )
                        : exportCtx.fillText(
                              combinedHeading,
                              combinedHeadingX,
                              combinedHeadingY
                          )
                }

                // date

                // language string from number
                const getLanguageString = (langNumber) => {
                    switch (langNumber) {
                        case 1:
                            return 'english'
                        case 2:
                            return 'telugu'
                        case 3:
                            return 'hindi'
                        case 4:
                            return 'tamil'
                        default:
                            return 'english'
                    }
                }

                const formatDate = (day, month, languageNumber) => {
                    const language = getLanguageString(languageNumber)
                    const currentYear = new Date().getFullYear()
                    const monthName = monthsData[language][month]
                    const dayName =
                        dayNamesData[language][
                            new Date(currentYear, month, day).getDay()
                        ]

                    return `${monthName} ${day}, ${dayName}`
                }

                const formattedDate = formatDate(day, month, language)
                let x
                if (language === 2) {
                    x = 15
                } else if (language === 4) {
                    x = 9
                } else {
                    x = 17
                }
                exportCtx.font = `${13.5 * scaleFactor}px Sree Krushnadevaraya`
                exportCtx.fillStyle = '#FFFFFF'
                exportCtx.fillText(
                    formattedDate,
                    x * scaleFactor,
                    105 * scaleFactor
                )

                // starting Y position for labels and values:
                let currentY = 147 * scaleFactor

                // labels and values
                const lineHeight =
                    (LINE_HEIGHT[language] || LINE_HEIGHT.default) * scaleFactor

                let scaledTextStartX
                if (language === 3) {
                    scaledTextStartX = (TEXT_START_X - 10) * scaleFactor
                } else {
                    scaledTextStartX = TEXT_START_X * scaleFactor
                }
                const scaledTextEndX = TEXT_END_X * scaleFactor

                Object.entries(inputValues).forEach(([index, value]) => {
                    const numericIndex = parseInt(index)
                    const isTeluguNinthLabel =
                        language === 2 && numericIndex === 8

                    // special handling for Telugu 9th label with proper scaling
                    if (isTeluguNinthLabel) {
                        currentY += lineHeight // extra line gap before 9th label
                    }

                    let useTextBasedFont = false
                    if (language === 2) {
                        useTextBasedFont =
                            TEXT_BASED_INDICES[2].includes(numericIndex)
                    } else {
                        useTextBasedFont =
                            numericIndex > TIME_BASED_INDICES.default
                    }

                    // label
                    exportCtx.fillStyle = '#FEBB58'
                    exportCtx.font = `bold ${scaledFontProperties.labels.fontSize}px ${scaledFontProperties.labels.fontFamily}`

                    if (language === 3) {
                        exportCtx.fillText(
                            content.labels[index],
                            15 * scaleFactor,
                            currentY
                        )
                    } else if (language === 2) {
                        exportCtx.fillText(
                            content.labels[index],
                            15 * scaleFactor,
                            currentY
                        )

                        // For the 9th label in Telugu, extra space before the value
                        if (isTeluguNinthLabel) {
                            currentY += lineHeight
                        }
                    } else {
                        exportCtx.fillText(
                            content.labels[index],
                            11 * scaleFactor,
                            currentY
                        )
                    }

                    // font based on index
                    const fontProperties = useTextBasedFont
                        ? scaledFontProperties.timeBasedValues
                        : scaledFontProperties.textBasedValues

                    // value
                    exportCtx.fillStyle = '#FFFFFF'
                    exportCtx.font = `${fontProperties.fontSize}px ${fontProperties.fontFamily}`

                    const manualLines = value.split('\\')
                    let lastLineY = currentY

                    manualLines.forEach((manualLine, lineIndex) => {
                        const words = manualLine.trim().split(' ')
                        let line = ''
                        let testLine = ''

                        words.forEach((word) => {
                            testLine = line + word + ' '
                            const metrics = exportCtx.measureText(testLine)
                            const effectiveTextEndX = isTeluguNinthLabel
                                ? scaledTextEndX - scaleFactor * 20
                                : scaledTextEndX - scaledTextStartX

                            if (
                                metrics.width > effectiveTextEndX &&
                                line !== ''
                            ) {
                                // different x-position for 9th Telugu label's value
                                const xPosition = isTeluguNinthLabel
                                    ? 15 * scaleFactor
                                    : language === 3
                                    ? scaledTextStartX - 10 * scaleFactor
                                    : scaledTextStartX

                                exportCtx.fillText(line, xPosition, currentY)
                                currentY += lineHeight
                                line = word + ' '
                            } else {
                                line = testLine
                            }
                        })

                        if (line !== '') {
                            // different x-position for 9th Telugu label's value
                            const xPosition = isTeluguNinthLabel
                                ? 15 * scaleFactor
                                : language === 3
                                ? scaledTextStartX - 10 * scaleFactor
                                : scaledTextStartX

                            exportCtx.fillText(line, xPosition, currentY)
                            currentY += lineHeight
                        }

                        if (lineIndex < manualLines.length - 1) {
                            currentY += lineHeight * 0.01
                        }
                    })

                    lastLineY = currentY - lineHeight
                    currentY =
                        lastLineY + FIXED_GAP_BETWEEN_ENTRIES * scaleFactor
                })

                const dataUrl = exportCanvas.toDataURL('image/jpeg', 1.0)
                const blob = await (await fetch(dataUrl)).blob()
                const imageFile = new File([blob], 'panchangam.jpg', {
                    type: 'image/jpeg',
                })
                const imageUploadResponse = await uploadImageToTest(
                    imageFile,
                    accessToken
                )
                const imageUrl = imageUploadResponse.data

                // panchangam_text object
                const panchangamText = {}
                languageContent[language].labels.forEach((label, index) => {
                    panchangamText[label] = inputValues[index] || ''
                })
                const now = moment()
                const currentYear = new Date().getFullYear()
                const currentMonth = new Date().getMonth()
                const year = month < currentMonth ? currentYear + 1 : currentYear
                const date = new Date(
                    year,
                    month,
                    day,
                    now.hour(),
                    now.minute(),
                    now.second()
                ).toISOString()

                const panchangamPayload = {
                    language_id: language,
                    image_url: imageUrl,
                    panchangam_text: panchangamText,
                    date: date,
                }
                if (isEditMode && currentPanchangamId) {
                    panchangamPayload.code = currentPanchangamId
                    panchangamPayload.uploaded_date = uploadedDate
                    const panchangamResponse = await updatePanchangamCard(
                        currentPanchangamId,
                        panchangamPayload,
                        accessToken
                    )
                    if (panchangamResponse.data.success) {
                        message.success('Panchangam card updated successfully')
                        if (typeof onEditSuccess === 'function') {
                            onEditSuccess()
                        }
                    } else {
                        message.error('Failed to update Panchangam card')
                    }
                } else {
                    const panchangamResponse = await createPanchangamCard(
                        panchangamPayload,
                        accessToken
                    )

                    if (panchangamResponse.data.success) {
                        message.success('Panchangam card created successfully')
                        setHasPanchangam(true)
                        setIsCoverPageModalVisible(false)
                    } else {
                        message.error('Failed to create Panchangam card')
                    }
                }
                setIsOverlayVisible(false)
            }
        } catch (error) {
            console.error('Error creating/updating Panchangam:', error)
            message.error(
                'An error occurred while creating/updating Panchangam card'
            )
            setIsOverlayVisible(false)
        }
    }

    return (
        <div className="panchangam-container">
            {isOverlayVisible && <LoadingOverlay message={overlayMessage} />}
            <div className="heading-buttons">
                <h2>{isEditMode ? 'Edit Panchangam' : 'Add Panchangam'}</h2>
                <div className="btns"></div>
            </div>
            <hr />
            <div className="both-sections">
                <div className="left-section">
                    <div className="inputsAndSaveBtn">
                        <div className="input-fields-panch">
                            {languageContent[language]?.labels.map(
                                (label, index) => (
                                    <div key={index} className="input-group">
                                        <label
                                            style={{
                                                fontFamily:
                                                    'Sree Krushnadevaraya',
                                                marginBottom: '0.125rem',
                                                fontWeight: '630px',
                                                fontSize:
                                                    language === 4
                                                        ? '1rem'
                                                        : '1.25rem',
                                            }}
                                        >
                                            {label}:
                                        </label>
                                        <TextArea
                                            value={inputValues[index] || ''}
                                            autoSize={{
                                                minRows: 2,
                                                maxRows: 6,
                                            }}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    index,
                                                    e.target.value
                                                )
                                            }
                                            style={
                                                languageContent[language]
                                                    .fontProperties.values
                                            }
                                            placeholder="Use '\' for manual line breaks"
                                        />
                                    </div>
                                )
                            )}
                        </div>
                        <Button
                            type="primary"
                            onClick={saveImage}
                            className="saveBtn"
                        >
                            {isEditMode
                                ? 'Update Cover Page'
                                : 'Save as Cover Page'}
                        </Button>
                    </div>
                </div>

                <div className="right-section">
                    <div className="canvas-preview">
                        <PanchangamCanvas
                            selectedLanguage={language}
                            selectedMonth={month}
                            selectedDay={day}
                            inputValues={inputValues}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

Panchangam.propTypes = {
    language: PropTypes.oneOf([1, 2, 3, 4]),
    month: PropTypes.number,
    day: PropTypes.number,
    setIsCoverPageModalVisible: PropTypes.func,
    setHasPanchangam: PropTypes.func,
    currentPanchangamId: PropTypes.string,
    isEditMode: PropTypes.bool,
    existingPanchangamData: PropTypes.shape({
        date: PropTypes.string,
        panchangam_text: PropTypes.object,
    }),
    onEditSuccess: PropTypes.func,
    uploadedDate: PropTypes.string,
}

export default Panchangam

import React, { useState } from 'react'
import './AddCities.scss'
import { useSelector } from 'react-redux'
import { Button, Popconfirm } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import AddCities from './AddCities'
import CommonTable from '../../CommonTable/CommonTable'
import { SelectComponentWithSearch } from '../../AntdComponent/SelectComponentWithSearch'
import { SelectComponent } from '../../AntdComponent/SelectComponent'
function ManageCities() {
    const languageData = useSelector((state) => state.app.languageData)
    const allStates = useSelector((state) => state.app.locations)
    const token = useSelector((state) => state.app.accessToken)
    const [languageOption, setLanguageOption] = useState(
        languageData?.[0]?.language_id
    )
    const [state, setState] = useState(undefined)
    const [isModalOpen, setIsModalOpen] = useState(false)

    const handleLanguageChange = (value) => {
        setLanguageOption(value)
    }

    const handleStateChange = (value) => {
        setState(value)
    }

    const showModal = () => {
        // setEditingCategory(category)
        setIsModalOpen(true)
    }

    const columns = [
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city',
            width: '33%',
        },

        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            width: '33%',
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            width: '33%',
            render: (_, record) => (
                <div>
                    <EditOutlined
                        // onClick={() => handleEdit(record)}
                        style={{ cursor: 'pointer' }}
                    />
                    <Popconfirm
                        title="Delete Snippet"
                        description="Are you sure to delete this snippet?"
                        // onConfirm={() => handleDelete(record.content_id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <DeleteOutlined
                            style={{ paddingLeft: '5px', cursor: 'pointer' }}
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ]

    return (
        <div className="cities-container">
            <h2>Manage Cities</h2>
            <hr></hr>
            <div className="cities-div">
                <div>
                    <SelectComponent
                        data={languageData}
                        value={languageOption}
                        onChange={handleLanguageChange}
                        key_value={'language_id'}
                        display={'language_name'}
                        style={{ marginRight: '1rem', width: '14vw' }}
                        placeholder={''}
                    />
                    <SelectComponentWithSearch
                        data={allStates}
                        value={state}
                        onChange={handleStateChange}
                        key_value={'state_id'}
                        display={'name'}
                        style={{ marginRight: '1rem', width: '13vw' }}
                        placeholder={'Select State'}
                    />
                </div>

                <div>
                    <Button type="primary" onClick={() => showModal()}>
                        Add Cities
                    </Button>
                </div>
            </div>
            <div>
                <CommonTable columns={columns} />
            </div>
            <div>
                <AddCities
                    isModalOpen={isModalOpen}
                    handleCancel={() => {
                        setIsModalOpen(false)
                    }}
                    token={token}
                    language={languageOption}
                />
            </div>
        </div>
    )
}
export default ManageCities

import React, { useEffect, useRef, useState, useMemo } from 'react'
import { Button } from 'antd'
import { checkSpelling } from '../AadhanApi'
import './HyperlinkTextArea.scss'
import { useSelector } from 'react-redux'

const SpellCheckBox = ({
    onChange,
    mode,
    location,
    text,
    minHeight,
    length,
    placeholder,
    languageOption,
}) => {
    const token = useSelector(state => state.app.accessToken)
    const [target, setTarget] = useState([])
    const [loading, setLoading] = useState(false)
    const [flag,setFlag] = useState(true)
    const textareaRef = useRef(null)
    const [debounceTimer, setDebounceTimer] = useState(null)
    const adjustHeight = () => {
        const textarea = textareaRef.current
        if (textarea) {
            textarea.style.height = 'auto' // Reset height to auto
            textarea.style.height = `${textarea.scrollHeight}px` // Adjust height based on content
        }
    }
    // Auto-resize logic on text change and window resize (to handle zoom)
    useEffect(() => {
        adjustHeight()
    }, [text])
    useEffect(()=>{
      if((mode==='review' || mode==='edit') && text && flag){
      
        fetchHindiSpellCheck()
        setFlag(false)
      }
    },[text])
    useEffect(() => {
        const handleResize = () => {
            adjustHeight()
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])
    const fetchHindiSpellCheck = async () => {
        try {
            setLoading(true)
            const res = await checkSpelling(text, languageOption, token)
            setTarget(res?.data || [])
        } catch (error) {
            console.error('Error fetching Hindi spell check:', error)
        } finally {
            setLoading(false)
        }
    }

    const highlightedText = useMemo(() => {
        const lines = text?.split(/\n/) || []
        return lines.map((line, lineIndex) => {
            const wordsAndSpaces =
                line === '' ? ['\n'] : line.match(/(\S+|\s+)/g) || []
            return (
                <div key={lineIndex}>
                    {wordsAndSpaces.map((chunk, index) => {
                        const isWord = /\S/.test(chunk)
                        const isTarget =
                            isWord &&
                            target.some(
                                (targetWord) =>
                                    targetWord?.normalize('NFC') ===
                                    chunk?.trim().normalize('NFC')
                            )
                        return (
                            <span key={index}>
                                <span
                                    style={{
                                        borderBottom: isTarget
                                            ? '2px solid rgb(255, 120, 142)'
                                            : 'none',
                                        whiteSpace: 'pre-wrap',
                                        transition:
                                            'border-bottom 0.1s ease-in-out',
                                    }}
                                >
                                    {chunk}
                                </span>
                            </span>
                        )
                    })}
                </div>
            )
        })
    }, [text, target])
    const handleInputChange = (e) => {
        onChange(e)
        adjustHeight()

        if (debounceTimer) {
            clearTimeout(debounceTimer)
        }
        const newDebounceTimer = setTimeout(() => {
            if (text) {
                fetchHindiSpellCheck()
            }
        }, 500)

        setDebounceTimer(newDebounceTimer)
    }
    const commonTextStyle = {
        fontFamily: 'inherit',
        fontSize: '14px',
        lineHeight: '1.5',  // Match this to your exact textarea lineHeight
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
        padding: '0',
        margin: '0',
        boxSizing: 'border-box', // Ensures padding/border doesn't affect size
      };

    return (
        <div style={{
            borderRadius: '8px',
            padding:2,
            border: 'solid 1px lightgray',
            backgroundColor: '#fff',
        }}
>
            <div
            style={{
                ...commonTextStyle,
                position: 'relative',
                fontFamily: 'inherit',
                fontSize: 14,
                display:"flex",
                width:"100%",
                backgroundColor: '#fff',
            }}
        >
            {/* Highlighted Text Overlay */}
            <div
                style={{
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    padding: 0,
                    pointerEvents: 'none',
                    color: 'transparent',
                    background: 'transparent',
                    // border: '1px solid #ccc',
                    overflowWrap: 'break-word',
                }}
            >
                {highlightedText}
            </div>

            {/* Auto-resizing TextArea */}
            <textarea
                ref={textareaRef}
                value={text}
                maxLength={length}
                spellCheck={false}
                onChange={handleInputChange}
                data-gramm="false"
                data-gramm_editor="false"
                style={{
                    position: 'relative',
                    fontFamily: 'inherit',
                    background: 'transparent',
                    border: 'none',
                    zIndex: 2,
                    display:"flex",
                    padding: "0",
                    overflow: 'hidden', 
                    width: '99.98%',
                    outlineColor: 'lightblue',
                    outline:"none",
                    minHeight: minHeight,
                    // color: 'transparent',
                    caretColor: 'black', // Ensure the caret is visible
                    resize: 'none', 
                }}
                placeholder={placeholder}
            />
            <div
                className="spell-check-button"
                style={{
                    backgroundColor: loading ? 'lightgray' : 'green',
                    position: 'absolute',
                    bottom: 10,
                    right: 5,
                }}
                onClick={fetchHindiSpellCheck}
            >
                {loading ? (
                    <Button
                        loading
                        style={{
                            border: 'none',
                            color: 'blue',
                            fontWeight: 600,
                            fontSize: 18,
                            backgroundColor: 'transparent',
                        }}
                    />
                ) : (
                    <span className="spell-check-icon">✓</span>
                )}
            </div>
        </div>
        </div>
    )
}

export default SpellCheckBox

import React from "react";
import MuxPlayer from "@mux/mux-player-react";
export const Audio = ({ url }) => {
    const tokenRegex = /token=([^&]*)/
    const playbackIdRegex = /\/([^\.]*)\.m3u8/
    const youtubeRegex = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/
    let token
    let playbackId
    const validateURL = (videoPath) => {
        const isYoutube = youtubeRegex.test(videoPath)
        if (
            videoPath &&
            !isYoutube &&
            videoPath.includes('token') &&
            videoPath.includes('.m3u8')
        ) {
            token = videoPath.match(tokenRegex)[1]
            playbackId = videoPath.match(playbackIdRegex)[1]
        }
        return playbackId && token
    }


    return (
        <div>
            {validateURL(url) &&
                        playbackId !== '' &&
                        token !== undefined ? (
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        backgroundColor: 'black',
                                        borderRadius: 5,
                                        justifyContent: 'space-between',
                                    }}
                                >
                                   
                                    <MuxPlayer
                                        audio
                                        style={{ height: '100%' ,backgroundColor:"red"}}
                                        playbackId={playbackId}
                                        tokens={{ playback: token }}
                                    />
                                    
                                </div>
                            </div>
                        ) : (
                            <div>
                                {/* You can add an alternative content here if the URL is not valid */}
                                <span>Invalid video URL</span>
                            </div>
                        )}
        </div>
    );
};

import React, { useEffect, useRef, useState } from 'react';
import { Button, message } from 'antd';
import { SoundOutlined, MutedOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router';
import axios from 'axios';

const Speaker = ({ text, language }) => {
  const [loading, setLoading] = useState(false);
  const voicesRef = useRef([]);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [voicesLoaded, setVoicesLoaded] = useState(false);
  const [isMuted, setIsMuted] = useState(true); // Initially show the mute icon
  const location = useLocation();
  const audioRef = useRef(null); // Store the audio instance
  const cachedAudioRef = useRef(null); // Cache the audio content for Telugu TTS

  // Cleanup on text change or navigation
  useEffect(() => {
    if (audioRef.current) {
      stopAudio();
    }
    if (language === 'te') {
      cachedAudioRef.current = null; // Clear cached audio for Telugu if text changes
    }
  }, [text, location.pathname]);

  useEffect(() => {
    const loadVoices = () => {
      const voices = window.speechSynthesis.getVoices();
      if (voices.length > 0) {
        voicesRef.current = voices;
        setVoicesLoaded(true);
      } else {
        setTimeout(loadVoices, 100); // Retry loading voices
      }
    };

    if ('speechSynthesis' in window) {
      window.speechSynthesis.onvoiceschanged = loadVoices;
      loadVoices();
    }

    return () => {
      window.speechSynthesis.onvoiceschanged = null;
      stopAudio(); // Cleanup on component unmount
    };
  }, []);

  const stopAudio = () => {
    // Stop TTS and reset states
    window.speechSynthesis.cancel();
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0; // Reset the audio playback
    }
    setIsSpeaking(false);
    setIsMuted(true);
  };

  const speak = () => {
    if (language === 'te') {
      if (cachedAudioRef.current) {
        // Resume playback for cached audio
        resumeTeluguAudio();
      } else {
        handleTeluguTTS(); // Fetch new audio if not cached
      }
    } else {
      handleBrowserTTS();
    }
  };

  const handleBrowserTTS = () => {
    if (!('speechSynthesis' in window)) {
      message.error('Browser does not support speech synthesis.');
      return;
    }

    if (!voicesLoaded) {
      message.warning('Loading voices, please try again.');
      return;
    }

    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = language;

    const voice = voicesRef.current.find((voice) => voice.lang === language);

    if (voice) {
      utterance.voice = voice;
      setIsSpeaking(true);
      setIsMuted(false);
      utterance.onend = () => stopAudio();
      utterance.onerror = () => stopAudio();
      window.speechSynthesis.speak(utterance);
    } else {
      message.error(`The language (${language}) is not supported by your browser.`);
    }
  };

  const handleTeluguTTS = async () => {
    setLoading(true);
    const apiUrl = 'https://admin.models.ai4bharat.org/inference/convert';

    try {
      const response = await axios.post(apiUrl, {
        input: text,
        gender: 'male',
        samplingRate: 16000,
        serviceId: 'ai4bharat/indic-tts-dravidian--gpu-t4',
        sourceLanguage: 'te',
        task: 'tts',
        track: true,
      });

      const audioContent = response.data.audio?.[0]?.audioContent;

      if (audioContent) {
        cachedAudioRef.current = audioContent; // Cache the audio content
        playTeluguAudio(audioContent);
      } else {
        message.error('No audio content received.');
        stopAudio();
      }
    } catch (error) {
      message.error('Error generating speech. Please try again.');
      stopAudio();
    }
    setLoading(false);
  };

  const playTeluguAudio = (audioContent) => {
    const audio = new Audio(`data:audio/wav;base64,${audioContent}`);
    audioRef.current = audio;
    audio.play();
    audio.onended = stopAudio;
    setIsSpeaking(true);
    setIsMuted(false);
  };

  const resumeTeluguAudio = () => {
    if (audioRef.current) {
      audioRef.current.play();
      setIsSpeaking(true);
      setIsMuted(false);
    }
  };

  const handleClick = () => {
    if (isSpeaking) {
      if (audioRef.current) {
        audioRef.current.pause(); // Pause playback
      }
      setIsSpeaking(false);
      setIsMuted(true);
    } else {
      speak();
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      {loading ? (
        <Button disabled loading />
      ) : (
        <Button
          ghost={true}
          type="primary"
          icon={isMuted ? <MutedOutlined /> : <SoundOutlined />}
          onClick={handleClick}
        />
      )}
    </div>
  );
};

export default Speaker;

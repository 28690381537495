import React, { useState, useRef, useEffect } from 'react'
import { Button, Carousel } from 'antd'
import {
    CaretRightOutlined,
    LeftOutlined,
    RightOutlined,
} from '@ant-design/icons'
import MuxPlayer from '@mux/mux-player-react'

const VideoReview = ({ videoUrls, onAllVideosWatched, data }) => {
    const [watchedVideos, setWatchedVideos] = useState(
        Array(videoUrls.length).fill(false)
    )
    const [activeVideos, setActiveVideos] = useState(
        Array(videoUrls.length).fill(false)
    )
    const carouselRef = useRef()
    const handlePrev = () => carouselRef.current?.prev()
    const handleNext = () => carouselRef.current?.next()
    const handlePlay = (index) => {
        setActiveVideos((prev) => {
            const updated = [...prev]
            updated[index] = true
            return updated
        })
    }
    useEffect(() => {
        setActiveVideos(Array(videoUrls?.length).fill(false))
        setWatchedVideos(Array(videoUrls?.length).fill(false))
    }, [videoUrls, data])
    const handleVideoEnded = (index) => {
        setWatchedVideos((prev) => {
            const updated = [...prev]
            updated[index] = true
            if (updated.every(Boolean)) {
                onAllVideosWatched()
            }
            return updated
        })
    }

    return (
        <div style={{ width: '100%' }}>
            <Carousel
                autoplay
                ref={carouselRef}
                dots={false}
                style={{ width: '100%' }}
            >
                {videoUrls?.map((src, index) => (
                    <div
                        key={index}
                        style={{ position: 'relative', width: '100%' }}
                    >
                        {!activeVideos[index] ? (
                            <div
                                style={{
                                    height: 170,
                                    width: '100%',
                                    borderRadius: 5,
                                    backgroundColor: 'black',
                                    overflow: 'hidden',
                                    border: watchedVideos[index]
                                        ? '1px solid green'
                                        : '1px solid red',
                                    position: 'relative',
                                }}
                            >
                                {/* <img style={{width:"100%",opacity:.7,objectFit:"cover"}} src='https://images.aadhan.in/stage/telugu/shortnews/21012025T0901789538.jpeg'/> */}
                                <Button
                                    onClick={() => handlePlay(index)}
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        backgroundColor: 'transparent',
                                        opacity: 0.9,
                                        color: 'white',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                    icon={
                                        <CaretRightOutlined
                                            style={{ fontSize: 20 }}
                                        />
                                    }
                                ></Button>
                            </div>
                        ) : (
                            <MuxPlayer
                                style={{
                                    height: 170,
                                    width: '100%',
                                    borderRadius: 5,
                                    overflow: 'hidden',
                                    border: watchedVideos[index]
                                        ? '1px solid green'
                                        : '1px solid red',
                                }}
                                src={src}
                                onEnded={() => handleVideoEnded(index)}
                                autoPlay
                            />
                        )}
                    </div>
                ))}
            </Carousel>
            {videoUrls?.length > 1 && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: 10,
                    }}
                >
                    <Button
                        icon={<LeftOutlined />}
                        onClick={handlePrev}
                        disabled={videoUrls?.length <= 1}
                        style={{ marginRight: 5 }}
                    />
                    <Button
                        icon={<RightOutlined />}
                        onClick={handleNext}
                        disabled={videoUrls?.length <= 1}
                        style={{ marginLeft: 5 }}
                    />
                </div>
            )}
        </div>
    )
}
export default VideoReview

import { Form, Input, Radio, Select, Row, Col, Switch, Button } from 'antd'
import { useEffect, useState } from 'react'
import { createLanguage, updateLanguage, getStates } from '../../AadhanApi'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { Option } from 'antd/es/mentions'
import { DeleteOutlined } from '@ant-design/icons'

const AddLanguage = ({
    setShowModal,
    selectedLanguageRecord,
    accessToken,
    showLanguageModal,
    getLanguageData,
}) => {
    const statesData = useSelector((state) => state.app.locations)
    const [statesList, setStatesList] = useState([])
    const [district, setDistrict] = useState(false)
    const [form] = Form.useForm()
    const employeeId = useSelector((state) => state.app.employeeId)
    const [selectedStates, setSelectedStates] = useState([])
    useEffect(() => {
        setDistrict(selectedLanguageRecord?.districts_available || false)
        form.setFieldsValue({
            regionalName: selectedLanguageRecord?.regional_name,
            status: selectedLanguageRecord?.status === 'active' ? true : false,
            languageName: selectedLanguageRecord?.language_name,
            show_in_app: selectedLanguageRecord?.show_in_app,
            languageCode: selectedLanguageRecord?.language_code,
            state_selection:selectedLanguageRecord?.state_selection,
            constituency_selection:selectedLanguageRecord?.constituency_selection
            
        })
        setSelectedStates(selectedLanguageRecord?.states ? selectedLanguageRecord?.states  : [] )
    }, [selectedLanguageRecord, getLanguageData])

    

    const onFinish = async (values) => {
        const obj = {
            updated_by: employeeId,
            updated_date: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            language_name: values.languageName,
            status: values.status ? 'active' : 'inactive',
            language_code: values.languageCode,
            regional_name: values.regionalName,
            created_by: employeeId,
            show_in_app: values?.show_in_app,
            state_selection:values?.state_selection,
            constituency_selection:values?.constituency_selection,
            states: selectedStates,
            districts_available: district,
            created_date: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        }
        if (selectedLanguageRecord) {
            await updateLanguage(
                obj,
                accessToken,
                selectedLanguageRecord.language_id
            )
        } else {
            await createLanguage(obj, accessToken)
        }
        getLanguageData()
        setShowModal(false)
        form.resetFields()
    }    
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }
    const addState = (stateId) => {
        form.setFieldValue('addState',undefined)
        if(stateId){

            
            if (!selectedStates?.find((state) => state?.state_id === stateId)) {
                const temp = statesData?.find((state) => state?.state_id === stateId);
                
                setSelectedStates([
                ...selectedStates,
                { state_id: stateId,local_name:temp?.local_name,name:temp?.name ? temp?.name : "", constituency_available: false },
            ])
        }
    }
    }

    const removeState = (stateId) => {
        setSelectedStates((prevStates) =>
            prevStates.filter((state) => state.state_id !== stateId)
        )
    }
    const toggleConstituency = (stateId) => {
        setSelectedStates((prevStates) =>
            prevStates.map((state) =>
                state.state_id === stateId
                    ? { ...state, constituency_available: !state?.constituency_available }
                    : state
            )
        )
    }

    return (
        <Form
            id="languageForm"
            form={form}
            name="languageForm"
            scrollToFirstError
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <div style={{ display: 'flex' }}>
                <div>
                    <Form.Item
                        labelCol={{ span: 24 }}
                        label="Regional Name"
                        name="regionalName"
                        rules={[
                            { required: true, message: 'Please enter value' },
                        ]}
                    >
                        <Input placeholder='regional name' />
                    </Form.Item>

                    <Form.Item
                        labelCol={{ span: 24 }}
                        label="Language Code"
                        name="languageCode"
                        rules={[
                            { required: true, message: 'Please enter value' },
                        ]}
                    >
                        <Input placeholder='language code' />
                    </Form.Item>

                    <Form.Item label="District" name="district">
                        <Switch
                            style={{ marginLeft: 10 }}
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            className="status"
                            checked={district}
                            onChange={() => setDistrict(!district)}
                        />
                    </Form.Item>
                    <Form.Item label="Active" name="status">
                        {/* <Radio.Group>
                            <Radio value="active"> Active </Radio>
                            <Radio value="inactive"> Inactive </Radio>
                        </Radio.Group> */}
                        <Switch
                            style={{ marginLeft: 10 }}
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                        />
                    </Form.Item>
                    <Form.Item label="Show In App" name="show_in_app">
                        <Switch
                            style={{ marginLeft: 10 }}
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                        />
                    </Form.Item>
                    <Form.Item labelCol={{ span: 8 }} label="State Selection" name="state_selection">
                        <Switch
                            style={{ marginLeft: 10 }}
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                        />
                    </Form.Item>
                    <Form.Item labelCol={{ span: 11.3 }} label="Contituency Selection" name="constituency_selection">
                        <Switch
                            style={{ marginLeft: 10 }}
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                        />
                    </Form.Item>
                </div>
                <div>
                    <Form.Item
                        labelCol={{ span: 24 }}
                        label="Language Name"
                        name="languageName"
                        rules={[
                            { required: true, message: 'Please enter value' },
                        ]}
                    >
                        <Input placeholder='language name' />
                    </Form.Item>
                    <Form.Item
                        labelCol={{ span: 24 }}
                        label="Add State"
                        name="addState"
                    >
                        <Select
                            allowClear
                            showSearch
                            filterOption={(input, option) => 
                                option.children.toLowerCase().includes(input.toLowerCase()) 
                              }
                            onChange={(e) => addState(e)}
                            value={<span style={{color:"gray"}}>Select State</span>}
                            style={{ width: '100%' }}
                            placeholder="select state"
                        >
                            {statesData
                                ?.filter(
                                    (state) =>
                                        !selectedStates.find(
                                            (selectedState) =>
                                                selectedState.state_id ===
                                                state.state_id
                                        )
                                )
                                .map((option) => (
                                    <Option
                                        key={option.state_id}
                                        value={option.state_id}
                                    >
                                        {option.name}
                                    </Option>
                                ))}
                        </Select>
                        {selectedStates.length > 0 && (
                            <div>
                                {selectedStates.map((state) => (
                                    <div
                                        key={state.state_id}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            margin:"4px 0",
                                        }}
                                    >
                                        <span style={{ flex: 1 }}>
                                            {
                                                statesData.find(
                                                    (s) =>
                                                        s.state_id ===
                                                        state.state_id
                                                )?.name
                                            }
                                        </span>
                                        <span style={{marginRight:5}}>constituencies: </span>
                                        <Switch
                                            checked={state.constituency_available}
                                            onChange={() =>
                                                toggleConstituency(
                                                    state.state_id
                                                )
                                            }
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                            style={{ marginRight: '10px' }}
                                        />
                                        <div
                                            
                                            onClick={() =>
                                                removeState(state.state_id)
                                            }
                                        >
                                            <DeleteOutlined size={'small'} style={{color:"red",cursor:"pointer"}}/>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </Form.Item>
                </div>
            </div>
        </Form>
    )
}

export default AddLanguage

import React, { useState, useEffect } from 'react'
import { updateConfigs, getConfigs } from '../../AadhanApi'
import { Switch, message, Select } from 'antd'
import './config.scss'
import { useSelector } from 'react-redux'
import { Button, Form, Input, Row, Col } from 'antd'

const AppConfig = () => {
    const [form] = Form.useForm()
    const [configType, setConfigType] = useState('ios')
    const accessToken = useSelector((state) => state.app.accessToken)

    // Fetch configuration data based on selected config type
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getConfigs(accessToken, configType)
                if (response.data && response.data.config) {
                    const configData = response.data.config

                    //const config_for = response.data.config_for;

                    // Populate form fields based on config type
                    if (configType === 'android') {
                        form.setFieldsValue({
                            android_latest_version:
                                configData.android_latest_version,
                            android_min_version: configData.android_min_version,
                            latest_version_in_settings:
                                configData.latest_version_in_settings,
                            ad_pub_id: configData.ad_pub_id,
                            native_ad_id: configData.native_ad_id,
                            google_ad_position: configData.google_ad_position,
                            html_position: configData.html_position,
                            google_ads_enabled: configData.google_ads_enabled,
                            show_refer_earn: configData.show_refer_earn,
                            show_comments: configData.show_comments,
                            aadhan_tv_link_telugu:
                                configData.aadhan_tv_link_telugu,
                            aadhan_tv_link_tamil:
                                configData.aadhan_tv_link_tamil,
                            aadhan_tv_link_hindi:
                                configData.aadhan_tv_link_hindi,
                            aadhan_cricket_link: configData.aadhan_cricket_link,
                            aadhan_games_link: configData.aadhan_games_link,
                            bookmark_db_size: configData.bookmark_db_size,
                        })
                        
                    } else if (configType === 'ios') {
                        // Assuming iOS config might have different fields
                        form.setFieldsValue({
                            android_latest_version:
                                configData.android_latest_version,
                            android_min_version: configData.android_min_version,
                            latest_version_in_settings:
                                configData.latest_version_in_settings,
                            ad_pub_id: configData.ad_pub_id,
                            native_ad_id: configData.native_ad_id,
                            google_ad_position: configData.google_ad_position,
                            html_position: configData.html_position,
                            google_ads_enabled: configData.google_ads_enabled,
                            show_refer_earn: configData.show_refer_earn,
                            show_comments: configData.show_comments,
                            aadhan_tv_link_telugu:
                                configData.aadhan_tv_link_telugu,
                            aadhan_tv_link_tamil:
                                configData.aadhan_tv_link_tamil,
                            aadhan_tv_link_hindi:
                                configData.aadhan_tv_link_hindi,
                            aadhan_cricket_link: configData.aadhan_cricket_link,
                            aadhan_games_link: configData.aadhan_games_link,
                            bookmark_db_size: configData.bookmark_db_size,
                        })
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error)
                message.error('Failed to fetch configuration data')
            }
        }

        fetchData()
    }, [accessToken, form, configType])

    const handleSubmit = async (values) => {
        try {
            // Prepare request data based on config type
            const requestData =
                configType === 'android'
                    ? {
                          android_latest_version: values.android_latest_version,
                          android_min_version: values.android_min_version,
                          latest_version_in_settings:
                              values.latest_version_in_settings,
                          ad_pub_id: values.ad_pub_id,
                          native_ad_id: values.native_ad_id,
                          google_ad_position: values.google_ad_position,
                          html_position: values.html_position,
                          google_ads_enabled: values.google_ads_enabled,
                          show_refer_earn: values.show_refer_earn,
                          show_comments: values.show_comments,
                          aadhan_tv_link_telugu: values.aadhan_tv_link_telugu,
                          aadhan_tv_link_tamil: values.aadhan_tv_link_tamil,
                          aadhan_tv_link_hindi: values.aadhan_tv_link_hindi,
                          aadhan_cricket_link: values.aadhan_cricket_link,
                          aadhan_games_link: values.aadhan_games_link,
                          bookmark_db_size: values.bookmark_db_size,
                      }
                    : {
                          android_latest_version: values.android_latest_version,
                          android_min_version: values.android_min_version,
                          latest_version_in_settings:
                              values.latest_version_in_settings,
                          ad_pub_id: values.ad_pub_id,
                          native_ad_id: values.native_ad_id,
                          google_ad_position: values.google_ad_position,
                          html_position: values.html_position,
                          google_ads_enabled: values.google_ads_enabled,
                          show_refer_earn: values.show_refer_earn,
                          show_comments: values.show_comments,
                          aadhan_tv_link_telugu: values.aadhan_tv_link_telugu,
                          aadhan_tv_link_tamil: values.aadhan_tv_link_tamil,
                          aadhan_tv_link_hindi: values.aadhan_tv_link_hindi,
                          aadhan_cricket_link: values.aadhan_cricket_link,
                          aadhan_games_link: values.aadhan_games_link,
                          bookmark_db_size: values.bookmark_db_size,
                      }

            await updateConfigs(accessToken, requestData, configType)
            message.success('Configuration updated successfully')
        } catch (error) {
            console.error('Error updating config:', error)
            message.error('Failed to update configuration')
        }
    }

    return (
        <div className="bg-container">
            <div>
                <div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            margin: '10px',
                        }}
                    >
                        <h1 className="App">App Configuration</h1>
                        <div style={{ marginRight: '0px' }}>
                            <Select
                                style={{
                                    width: '200px', // Set a consistent width for both platforms
                                    maxWidth: '100%', // Ensures it doesn't overflow the container
                                }}
                                value={configType}
                                onChange={(value) => setConfigType(value)}
                            >
                                <Select.Option value="ios">iOS</Select.Option>
                                <Select.Option value="android">
                                    Android
                                </Select.Option>
                            </Select>
                        </div>
                    </div>
                    <hr></hr>
                    <div>
                        <Form
                            form={form}
                            requiredMark={false}
                            name="config-form"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            onFinish={handleSubmit}
                            layout="horizontal"
                        >
                            {configType === 'android' ? (
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name="android_latest_version"
                                            label="Android Latest Version"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please enter latest version',
                                                },
                                            ]}
                                        >
                                            <Input className="ant-input-config" />
                                        </Form.Item>

                                        <Form.Item
                                            name="android_min_version"
                                            label="Android Min Version"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please enter minimum version',
                                                },
                                            ]}
                                        >
                                            <Input className="ant-input-config" />
                                        </Form.Item>

                                        <Form.Item
                                            name="latest_version_in_settings"
                                            label="Latest Version in Settings"
                                            rules={[
                                                {
                                                    required: false,
                                                    message:
                                                        'Please enter settings version',
                                                },
                                            ]}
                                        >
                                            <Input className="ant-input-config" />
                                        </Form.Item>

                                        <Form.Item
                                            name="ad_pub_id"
                                            label="Ad Publish ID"
                                            rules={[
                                                {
                                                    required: false,
                                                    message:
                                                        'Please enter ad publish ID',
                                                },
                                            ]}
                                        >
                                            <Input className="ant-input-config" />
                                        </Form.Item>

                                        <Form.Item
                                            name="native_ad_id"
                                            label="Native Ad ID"
                                            rules={[
                                                {
                                                    required: false,
                                                    message:
                                                        'Please enter ad ID',
                                                },
                                            ]}
                                        >
                                            <Input className="ant-input-config" />
                                        </Form.Item>
                                        <Form.Item
                                            name="google_ad_position"
                                            label="Google Ad Position"
                                            rules={[
                                                {
                                                    required: false,
                                                    message:
                                                        'Please enter ad position',
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="ant-input-config"
                                                
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            name="html_position"
                                            label="HTML Position"
                                        >
                                            <Input className="ant-input-config"/>
                                        </Form.Item>

                                        <Form.Item
                                                name="bookmark_db_size"
                                                label="Bookmark Limit"
                                            >
                                                <Input  className="ant-input-config"/>
                                            </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <div style={{ marginLeft: '54px' }}>
                                            <Form.Item
                                                name="aadhan_tv_link_telugu"
                                                label="Aadhan TV Link Telugu"
                                            >
                                                <Input className="ant-input-config"/>
                                            </Form.Item>
                                            <Form.Item
                                                name="aadhan_tv_link_tamil"
                                                label="Aadhan TV Link Tamil"
                                            >
                                                <Input className="ant-input-config"/>
                                            </Form.Item>

                                            <Form.Item
                                                name="aadhan_tv_link_hindi"
                                                label="Aadhan TV Link Hindi"
                                            >
                                                <Input className="ant-input-config"/>
                                            </Form.Item>
                                            <Form.Item
                                                name="aadhan_cricket_link"
                                                label="Aadhan Cricket Link"
                                            >
                                                <Input className="ant-input-config"/>
                                            </Form.Item>

                                            <Form.Item
                                                name="aadhan_games_link"
                                                label="Aadhan Games Link"
                                            >
                                                <Input className="ant-input-config"/>
                                            </Form.Item>
                                        </div>

                                        <Form.Item
                                            style={{ marginLeft: '58px' }}
                                            name="google_ads_enabled"
                                            label="Enable Google Ads"
                                            valuePropName="checked"
                                        >
                                            <Switch
                                                style={{ marginLeft: '0px' }}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            style={{ marginLeft: '57px' }}
                                            name="show_refer_earn"
                                            label="Show Refer & Earn"
                                            valuePropName="checked"
                                        >
                                            <Switch
                                                style={{ marginLeft: '-2px' }}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            style={{ marginLeft: '56px' }}
                                            name="show_comments"
                                            label="Show Comments"
                                            valuePropName="checked"
                                        >
                                            <Switch
                                                style={{ marginLeft: '-2px' }}
                                            />
                                        </Form.Item>

                                       
                                    </Col>
                                </Row>
                            ) : (
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name="android_latest_version"
                                            label="iOS Latest Version"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please enter latest version',
                                                },
                                            ]}
                                        >
                                            <Input className="ant-input-config" />
                                        </Form.Item>
                                        <Form.Item
                                            name="latest_version_in_settings"
                                            label="latest version in settings"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please enter latest version',
                                                },
                                            ]}
                                        >
                                            <Input className="ant-input-config" />
                                        </Form.Item>

                                        <Form.Item
                                            name="android_min_version"
                                            label="iOS Min Version"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Please enter minimum version',
                                                },
                                            ]}
                                        >
                                            <Input className="ant-input-config" />
                                        </Form.Item>

                                        <Form.Item
                                            name="ad_pub_id"
                                            label="iOS Ad Publish ID"
                                            rules={[
                                                {
                                                    required: false,
                                                    message:
                                                        'Please enter ad publish ID',
                                                },
                                            ]}
                                        >
                                            <Input className="ant-input-config" />
                                        </Form.Item>

                                        <Form.Item
                                            name="native_ad_id"
                                            label="iOS Native Ad ID"
                                            rules={[
                                                {
                                                    required: false,
                                                    message:
                                                        'Please enter ad ID',
                                                },
                                            ]}
                                        >
                                            <Input className="ant-input-config" />
                                        </Form.Item>
                                        <Form.Item
                                            name="google_ad_position"
                                            label="iOS Google Ad Position"
                                            rules={[
                                                {
                                                    required: false,
                                                    message:
                                                        'Please enter ad position',
                                                },
                                            ]}
                                        >
                                            <Input className="ant-input-config"/>
                                        </Form.Item>

                                        <Form.Item
                                            name="html_position"
                                            label="iOS HTML Position"
                                            rules={[
                                                {
                                                    required: false,
                                                    message:
                                                        'Please enter HTML position',
                                                },
                                            ]}
                                        >
                                            <Input className="ant-input-config" />
                                        </Form.Item>

                                        <Form.Item
                                            name="aadhan_cricket_link"
                                            label="Aadhan Cricket Link"
                                        >
                                            <Input className="ant-input-config"/>
                                        </Form.Item>

                                        <Form.Item
                                            name="aadhan_games_link"
                                            label="Aadhan Games Link"
                                        >
                                            <Input className="ant-input-config"/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <div style={{ marginLeft: '-70px' }}>
                                            <Form.Item
                                                name="aadhan_tv_link_telugu"
                                                label="Aadhan TV Link Telugu"
                                            >
                                                <Input className="ant-input-config"/>
                                            </Form.Item>
                                            <Form.Item
                                                name="aadhan_tv_link_tamil"
                                                label="Aadhan TV Link Tamil"
                                            >
                                                <Input className="ant-input-config" />
                                            </Form.Item>

                                            <Form.Item
                                                name="aadhan_tv_link_hindi"
                                                label="Aadhan TV Link Hindi"
                                            >
                                                <Input className="ant-input-config"/>
                                            </Form.Item>

                                            <Form.Item
                                                name="bookmark_db_size"
                                                label="Bookmark Limit"
                                            >
                                                <Input className="ant-input-config"/>
                                            </Form.Item>
                                        </div>

                                        <div style={{ marginLeft: '-60px' }}>
                                            <Form.Item
                                                name="show_refer_earn"
                                                label="Show iOS Refer & Earn"
                                                valuePropName="checked"
                                            >
                                                <Switch />
                                            </Form.Item>
                                        </div>
                                        <div style={{ marginLeft: '-55px' }}>
                                            <Form.Item
                                                name="show_comments"
                                                label="Show iOS Comments"
                                                valuePropName="checked"
                                            >
                                                <Switch
                                                    style={{
                                                        marginLeft: '-2px',
                                                    }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="google_ads_enabled"
                                                label="Enable iOS Google Ads"
                                                valuePropName="checked"
                                            >
                                                <Switch />
                                            </Form.Item>
                                           
                                             
                                        </div>
                                    </Col>
                                </Row>
                            )}

                            <Row>
                                <Form.Item
                                    style={{ margin: '1rem auto' }}
                                    wrapperCol={{ offset: 8, span: 16 }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            marginRight: '185px',
                                        }}
                                    >
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            Update {configType.toUpperCase()}
                                        </Button>
                                    </div>
                                </Form.Item>
                            </Row>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppConfig

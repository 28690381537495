import React, { useEffect, useRef, useState } from 'react'
import {
    Button,
    Upload,
    Input,
    message,
    Form,
    Radio,
    Card,
    Space,
    Spin,
} from 'antd'
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons'
import { getVideoUrl } from '../../../AadhanApi'
import { useSelector } from 'react-redux'
import MusicPlayer from './MusicPlayer'
import { Audio } from '../Audio'

const PlayableAssets = ({
    songData,
    setSongData,
    language,
    currentForm,
    setCurrentForm,
    languageData,
}) => {
    let temp = []
    songData?.map((song) => {
        if (song.audio_url && !temp.includes(song.audio_url)) {
            temp.push(song.audio_url)
        }
    })
    const [playlist, setPlayList] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    function areURLsUniform(array) {
        if (array.length === 0) return true // Return true for an empty array

        const firstUrl = array[0]?.audio_url || null

        const allHaveSameUrl = array.every(
            (item) => item.audio_url === firstUrl
        )
        const allEmpty = array.every((item) => !item.audio_url)

        return allHaveSameUrl || allEmpty
    }
    const [uploadOption, setUploadOption] = useState(
        areURLsUniform(songData) ? 'same' : 'specific'
    )
    const [art, setArt] = useState(songData)
    const [loading, setLoading] = useState(false) // Loading state
    const accessToken = useSelector((state) => state.app.accessToken)

    useEffect(() => {
        if (areURLsUniform(songData)) {
            setArt(songData?.map((e) => ({ ...e, audio_url: temp[0] })))
        } else {
            setArt(songData)
        }
        setUploadOption(areURLsUniform(songData) ? 'same' : 'specific')
    }, [songData, currentForm])
    function getVideoDuration(file) {
        return new Promise((resolve, reject) => {
            const video = document.createElement('audio')
            video.preload = 'metadata' // Load only metadata, not the entire video

            video.onloadedmetadata = () => {
                resolve(video.duration) // Duration in seconds
            }

            video.onerror = (error) => {
                reject('Error loading audio')
            }

            const fileURL = URL.createObjectURL(file)
            video.src = fileURL
        })
    }

    function formatDuration(seconds) {
        const hours = Math.floor(seconds / 3600)
        const minutes = Math.floor((seconds % 3600) / 60)
        const remainingSeconds = Math.floor(seconds % 60)
        const formattedTime =
            hours > 0
                ? `${String(hours).padStart(2, '0')}:${String(minutes).padStart(
                      2,
                      '0'
                  )}:${String(remainingSeconds).padStart(2, '0')}`
                : `${String(minutes).padStart(2, '0')}:${String(
                      remainingSeconds
                  ).padStart(2, '0')}`

        return formattedTime
    }
    const handleUpload = async (file, languageOption) => {
        setLoading(true);
        try {
            const pathUri = await getVideoUrl(file, true, accessToken);
            const duration = await getVideoDuration(file);
            
            // Delay state update by 5 seconds
            setTimeout(() => {
                setLoading(true)
                if (languageOption === 'all') {
                    setArt((pre) =>
                        pre?.map((e) => {
                            return { ...e, duration: formatDuration(duration), audio_url: pathUri?.data };
                        })
                    );
                } else {
                    setArt((pre) =>
                        pre?.map((e) => {
                            if (e.language_id === languageOption) {
                                return { ...e, duration: formatDuration(duration), audio_url: pathUri?.data };
                            } else {
                                return e;
                            }
                        })
                    );
                }
                setLoading(false)
            }, 5000);
    
            message.success('Audio uploaded successfully!');
        } catch (error) {
            message.error('Failed to upload audio.');
            setLoading(false);
        } finally {
        }
        return false;
    };
    const handleChange = (text, languageOption) => {
        if (languageOption === 'all') {
            setArt((pre) =>
                pre?.map((e) => {
                    return { ...e, lyrics: text }
                })
            )
        } else {
            setArt((pre) =>
                pre?.map((e) => {
                    if (e.language_id === languageOption) {
                        return { ...e, lyrics: text }
                    } else {
                        return e
                    }
                })
            )
        }
    }
    const audioRef = useRef(null)
    useEffect(() => {
        setTimeout(() => {
            if (audioRef.current) {
                audioRef.current.load()
            }
        }, 300)
    }, [art, loading])
    return (
        <div style={{ height: '85%', overflowY: 'auto' }}>
            <Card
                title="Add Audio for the Song"
                style={{
                    maxWidth: 800,
                    margin: 'auto',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    padding: '20px',
                }}
            >
                <Form layout="vertical">
                    <Form.Item
                        name="uploadOption"
                        label="Select Upload Option"
                        initialValue={uploadOption}
                    >
                        <Radio.Group
                            value={uploadOption}
                            onChange={(e) => {
                                setUploadOption(e.target.value)
                                setArt((pre) =>
                                    pre?.map((e) => {
                                        return {
                                            ...e,
                                            audio_url: '',
                                            lyrics: '',
                                        }
                                    })
                                )
                            }}
                        >
                            <Radio value="same">
                                Same audio for all languages
                            </Radio>
                            <Radio value="specific">
                                Language-Specific Audio
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                </Form>

                {uploadOption === 'specific' && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px',
                        }}
                    >
                        {art?.map((e) => {
                            return (
                                <div
                                    key={e.language_id}
                                    style={{
                                        border: '1px dashed lightgray',
                                        padding: '10px',
                                        borderRadius: '8px',
                                        background: '#f9f9f9',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <div
                                        style={{
                                            fontWeight: 'bold',
                                            marginBottom: '10px',
                                            fontSize: '14px',
                                        }}
                                    >
                                        {
                                            languageData?.filter(
                                                (l) =>
                                                    l.language_id ===
                                                    e?.language_id
                                            )?.[0]?.language_name
                                        }
                                    </div>
                                    <Upload
                                        accept="audio/*"
                                        beforeUpload={(event) =>
                                            handleUpload(event, e.language_id)
                                        }
                                        showUploadList={false}
                                    >
                                        {!e?.audio_url && (
                                            <Button
                                                icon={<UploadOutlined />}
                                                style={{
                                                    backgroundColor: '#1890ff',
                                                    color: '#fff',
                                                    borderColor: '#1890ff',
                                                }}
                                            >
                                                Upload Audio
                                            </Button>
                                        )}
                                    </Upload>
                                    {loading ? (
                                        <div
                                            style={{
                                                marginTop: '20px',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <Spin size="large" />
                                        </div>
                                    ) : (
                                        e?.audio_url && (
                                            <div
                                                style={{
                                                    marginTop: 10,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 10,
                                                }}
                                            >
                                                <Audio
                                                    url={e?.audio_url?.replace(
                                                        '/audio.m4a',
                                                        '.m3u8'
                                                    )}
                                                    loading={loading}
                                                />
                                                <Button
                                                    danger
                                                    onClick={() => {
                                                        setArt((pre) =>
                                                            pre?.map(
                                                                (event) => {
                                                                    if (
                                                                        e?.language_id ===
                                                                        event?.language_id
                                                                    ) {
                                                                        return {
                                                                            ...event,
                                                                            audio_url:
                                                                                undefined,
                                                                        }
                                                                    } else {
                                                                        return event
                                                                    }
                                                                }
                                                            )
                                                        )
                                                    }}
                                                    icon={<DeleteOutlined />}
                                                />
                                            </div>
                                        )
                                    )}
                                    <Input.TextArea
                                        style={{
                                            margin: '10px 0',
                                            height: '8rem',
                                        }}
                                        placeholder="Lyrics..."
                                        value={e?.lyrics}
                                        onChange={(text) =>
                                            handleChange(
                                                text?.target?.value,
                                                e.language_id
                                            )
                                        }
                                    />
                                </div>
                            )
                        })}
                    </div>
                )}

                {uploadOption === 'same' && (
                    <div style={{ diplsy: 'flex', flexDirection: 'column' }}>
                        <Upload
                            beforeUpload={(e) => handleUpload(e, 'all')}
                            showUploadList={false}
                            accept="audio/*"
                        >
                            {!art?.[0]?.audio_url && (
                                <Button
                                    icon={<UploadOutlined />}
                                    style={{
                                        backgroundColor: '#1890ff',
                                        color: '#fff',
                                        borderColor: '#1890ff',
                                    }}
                                >
                                    Upload Audio
                                </Button>
                            )}
                        </Upload>
                        {loading ? (
                            <div
                                style={{
                                    marginTop: '20px',
                                    textAlign: 'center',
                                }}
                            >
                                <Spin size="large" />
                            </div>
                        ) : (
                            art?.[0]?.audio_url &&
                            !loading && (
                                <div
                                    style={{
                                        marginTop: 10,
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 10,
                                    }}
                                >
                                    <Audio
                                        url={art?.[0]?.audio_url?.replace(
                                            '/audio.m4a',
                                            '.m3u8'
                                        )}
                                        loading={loading}
                                    />

                                    <Button
                                        danger
                                        onClick={() => {
                                            setArt((pre) =>
                                                pre?.map((e) => {
                                                    return {
                                                        ...e,
                                                        audio_url: undefined,
                                                    }
                                                })
                                            )
                                        }}
                                        icon={<DeleteOutlined />}
                                    />
                                </div>
                            )
                        )}
                        <div>
                            <Input.TextArea
                                placeholder="Lyrics..."
                                style={{ margin: '10px 0', height: '8rem' }}
                                value={art?.[0]?.lyrics}
                                onChange={(e) =>
                                    handleChange(e?.target?.value, 'all')
                                }
                            />
                        </div>
                    </div>
                )}
            </Card>

            <Space
                style={{
                    marginTop: '30px',
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '20px',
                }}
            >
                <Button
                    onClick={() => setCurrentForm(null)}
                    type="primary"
                    danger
                    style={{ width: 120 }}
                >
                    Cancel
                </Button>
                <Button
                    type="primary"
                    onClick={() => {
                        setSongData(art)
                        setCurrentForm(null)
                    }}
                    style={{
                        width: 120,
                    }}
                >
                    Save
                </Button>
            </Space>
            <MusicPlayer
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                language={language}
                playList={playlist}
                setPlayList={setPlayList}
                currentIndex={0}
            />
        </div>
    )
}

export default PlayableAssets

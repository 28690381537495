import {
    ContainerOutlined,
    DashboardOutlined,
    LineChartOutlined,
    PlaySquareOutlined,
    ScheduleOutlined,
    UsergroupAddOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons'

import Feedback from '../../assets/Feedback.svg'
import Master from '../../assets/Master.svg'
import astrology from '../../assets/sun 1.svg'
import RolesAndPermisions from '../../assets/Roles_Permissions.svg'
export const RootNavbarMenuKeys = [
    'content',
    'master',
    'feedback',
    'categories',
    'employees',
    'rolesPermissions',
    'reports',
    'astrology',
    'review',
    'stories',
    'library',
    'manage snippets',
    'moderation',
    'writer-dashboard',
    'editorDashboard',
    'tv-content',
    'devotional',
    'Reporter Dashboard',
]

export const NavBarItems = [
    {
        key: 'reporter-dashboard',
        label: 'Reporter Dashboard',
        icon: <DashboardOutlined />,
    },
    {
        key: 'editorDashboard/employee',
        label: 'Editor Dashboard',
        icon: <DashboardOutlined />,
    },
    {
        key: 'review',
        label: 'SubEditor Dashboard',
        icon: <DashboardOutlined />,
    },
    {
        key: 'writer-dashboard',
        label: 'Writer Dashboard',
        icon: <DashboardOutlined />,
    },
    {
        key: 'reporters-lead-dashboard',
        label: 'Reporter Lead Dashboard',
        icon: <DashboardOutlined />,
    },
    {
        key: 'notification-dashboard',
        label: 'Notification Dashboard',
        icon: <DashboardOutlined />,
    },
    {
        key: 'tv',
        label: 'TV Content Dashboard',
        icon: <DashboardOutlined />,
        // children: [
        //     { key: 'tv', label: 'TV Content Dashboard' },
        //     // { key: 'approved-content', label: 'Approved TV Content' },
        // ],
    },
    {
        key: 'stories',
        label: 'Stories',
        icon: <VideoCameraOutlined />,
        children: [
            { key: 'list-of-stories', label: 'List of Stories' },
            { key: 'stages', label: 'Stages' },
            { key: 'central-team-ops', label: 'Central Team Ops' },
            { key: 'media-team', label: 'Media Team' },
            { key: 'reporter', label: 'Reporter' },
        ],
    },
    {
        key: 'ads',
        label: 'Ads',
        icon: <VideoCameraOutlined />,
        children: [
            { key: 'ads', label: 'Ads' },
            { key: 'revenuetable', label: 'Revenue' },
            { key: 'stagetable', label: 'Lead' },
            { key: 'package', label: 'Package' },
        ],
    },
    {
        key: 'content',
        label: 'Content',
        icon: <ContainerOutlined />,
        children: [
            // { key: 'ads', label: 'Ads' },
            { key: 'card', label: 'Card' },
            { key: 'html', label: 'HTML' },
            { key: 'poll', label: 'Poll' },
            { key: 'quiz', label: 'Quiz' },
            { key: 'shortNews', label: 'Short News' },
            { key: 'shortVideo', label: 'Short Video' },
            { key: 'devotionalVideo', label: 'Devotional Video' },
            { key: 'notifications', label: 'Notifications' },
            { key: 'ug', label: 'UG Content' },
        ],
    },
    {
        key: 'devotional',
        label: 'Devotional',
        icon: (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={16}
                height={16}
                fill="currentColor"
                class="size-6"
            >
                <path
                    fill-rule="evenodd"
                    d="M19.952 1.651a.75.75 0 0 1 .298.599V16.303a3 3 0 0 1-2.176 2.884l-1.32.377a2.553 2.553 0 1 1-1.403-4.909l2.311-.66a1.5 1.5 0 0 0 1.088-1.442V6.994l-9 2.572v9.737a3 3 0 0 1-2.176 2.884l-1.32.377a2.553 2.553 0 1 1-1.402-4.909l2.31-.66a1.5 1.5 0 0 0 1.088-1.442V5.25a.75.75 0 0 1 .544-.721l10.5-3a.75.75 0 0 1 .658.122Z"
                    clip-rule="evenodd"
                />
            </svg>
        ),
        children: [
            { key: 'songs', label: 'Songs' },
            { key: 'playlists', label: 'Playlists' },
            { key: 'podcasts', label: 'Podcasts' },
        ],
    },

    {
        key: 'library',
        label: 'Library',
        icon: <ScheduleOutlined />,
        children: [
            {
                key: 'playlist',
                label: 'Playlist',
            },
            {
                key: 'category',
                label: 'Category',
            },
            {
                key: 'visuals',
                label: 'Visuals',
            },
            {
                key: 'magazines',
                label: 'Magazines',
            },
            {
                key: 'daily-horoscope',
                label: 'Daily Horoscope',
            },
            {
                key: 'videos',
                label: 'Videos',
                icon: <PlaySquareOutlined />,
            },
            // {
            //     key: 'devotional',
            //     label: 'Devotional',
            //     icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} fill="currentColor" class="size-6" >
            //     <path fill-rule="evenodd" d="M19.952 1.651a.75.75 0 0 1 .298.599V16.303a3 3 0 0 1-2.176 2.884l-1.32.377a2.553 2.553 0 1 1-1.403-4.909l2.311-.66a1.5 1.5 0 0 0 1.088-1.442V6.994l-9 2.572v9.737a3 3 0 0 1-2.176 2.884l-1.32.377a2.553 2.553 0 1 1-1.402-4.909l2.31-.66a1.5 1.5 0 0 0 1.088-1.442V5.25a.75.75 0 0 1 .544-.721l10.5-3a.75.75 0 0 1 .658.122Z" clip-rule="evenodd" />
            //   </svg>
            //   ,
            // },
        ],
    },
    {
        key: 'manage snippets',
        label: 'Manage Snippets',
        icon: <ScheduleOutlined />,
        children: [
            {
                key: 'category-snippet',
                label: 'Category',
            },
            {
                key: 'snippet',
                label: 'Snippets',
            },
            {
                key: 'moveShortNews',
                label: 'Move ShortNews',
            },
        ],
    },
    {
        key: 'master',
        label: 'Master',
        icon: <img style={{ width: '1rem' }} alt="Master" src={Master} />,
        children: [
            { key: 'languages', label: 'Languages' },
            { key: 'hashtags', label: 'Hashtags' },
            { key: 'categories', label: 'Categories' },
            { key: 'news-sections', label: 'News Sections' },
            { key: 'subcategories', label: 'Sub Categories' },
            { key: 'locations', label: 'Locations' },
            { key: 'sources', label: 'Sources' },
            { key: 'features', label: 'Features' },
            { key: 'app-configuration', label: 'App Configuration' },
            { key: 'reporter-configuration', label: 'Reporter Configuration' },
        ],
    },
    {
        key: 'employees',
        label: 'Employees',
        icon: <UsergroupAddOutlined />,
        children: [
            { key: 'createEmployee', label: 'Create New Employee' },
            { key: 'employeeShifts', label: 'Employee Shifts' },
            { key: 'employees', label: 'List All Employee' },
            { key: 'stringers', label: 'List All Stringers' },
            { key: 'manage-tv-stringers', label: 'Manage TV Stringers' },
            { key: 'manage-app-stringers', label: 'Manage App Stringers' },
            { key: 'manage-staff-stringer', label: 'Manage Staff Stringers' },
            {
                key: 'manage-citizen-stringers',
                label: 'Manage Citizen Stringer',
            },
            { key: 'billing', label: 'Billing' },
        ],
    },
    {
        key: 'moderation',
        label: 'Moderation',
        icon: <ScheduleOutlined />,
        children: [{ key: 'comments', label: 'Comments' }],
    },
    {
        key: 'feedback',
        label: 'Feedback',
        icon: <img style={{ width: '1rem' }} alt="Feedback" src={Feedback} />,
    },
    {
        key: 'astrology',
        label: 'Astrology',
        icon: <img style={{ width: '1rem' }} alt="Astrology" src={astrology} />,
        children: [
            { key: 'horoscope', label: 'Horoscope' },
            { key: 'panchangam', label: 'Panchangam' },
        ],
    },
    {
        key: 'rolesPermissions',
        label: 'Roles and Permissions',
        icon: (
            <img
                style={{ width: '1rem' }}
                alt="Roles and Permissions"
                src={RolesAndPermisions}
            />
        ),
        children: [
            {
                key: 'assignRolesAndPermissions',
                label: 'Assign Roles & Permissions',
            },
            { key: 'departments', label: 'Manage Departments' },
            { key: 'manageRoles', label: 'Manage Roles' },
            { key: 'managePermissions', label: 'Manage Permissions' },
        ],
    },
    {
        key: 'reports',
        label: 'Reports',
        icon: <LineChartOutlined />,
        children: [
            { key: 'notificationReport', label: 'Notification Report' },
            { key: 'topicsReport', label: 'Topics Report' },
            { key: 'reporterPaymentReport', label: 'Reporter Payment Report' },
        ],
    },
]

export const findParentKeyByChildKey = (
    childKeyToFind,
    navItems,
    parentKey = null
) => {
    for (const item of navItems) {
        if (item?.key === childKeyToFind) {
            return parentKey
        } else if (item?.children && item?.children.length > 0) {
            const foundKey = findParentKeyByChildKey(
                childKeyToFind,
                item.children,
                item.key
            )
            if (foundKey) {
                return foundKey
            }
        }
    }
    return null
}

export const userPermissions = {
    Content: {},
    Reports: {
        'State Content Report': ['Can View', 'Can Edit', 'Can Delete'],
    },
    Master: {
        Hashtags: [
            'Can Add',
            'Can View',
            'Can Delete',
            'Can Approve',
            'Can Edit',
        ],
        Sources: [
            'Can Add',
            'Can Approve',
            'Can Edit',
            'Can Delete',
            'Can View',
        ],
        Locations: [
            'Can Add',
            'Can Approve',
            'Can Delete',
            'Can View',
            'Can Edit',
        ],
        Categories: [
            'Can Add',
            'Can Approve',
            'Can View',
            'Can Edit',
            'Can Delete',
        ],
        Languages: [
            'Can Add',
            'Can View',
            'Can Edit',
            'Can Delete',
            'Can Approve',
        ],
    },
    Feedback: { Feedback: [] },
    'Roles and Permissions': {
        'Assign Roles & Permissions': [
            'Can Add',
            'Can View',
            'Can Edit',
            'Can Delete',
            'Can Approve',
        ],
        'Manage Roles': [
            'Can Add',
            'Can View',
            'Can Edit',
            'Can Delete',
            'Can Approve',
        ],
    },
    Employees: {
        'Create New Employee': ['Can Add', 'Can Edit', 'Can View'],
        'List All Employee': ['Can View'],
    },
}

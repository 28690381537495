import { Button, Modal, Popconfirm, Select, Space, Table, message } from 'antd'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    deleteRole,
    getEmployeeDepartments,
    getEmployeeDetails,
    getRolesByDepartment,
} from '../../AadhanApi'
import AddRole from './AddRole'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import './Permissions.scss'
import dayjs from 'dayjs'

const ManageRoles = () => {
    const [showRoleModal, setShowRoleModal] = useState(false)
    const [selectedRole, setSelectedRole] = useState()
    const [messageApi, contextHolder] = message.useMessage()
    const [isLoading, setIsLoading] = useState(false)
    const [departmentsData, setDepartmentsData] = useState()
    const [selectedDepartment, setSelectedDepartment] = useState()
    const [dataSource, setDataSource] = useState([])
    const [employeeName, setEmployeeName] = useState()
    const accessToken = useSelector((state) => state.app.accessToken)

    // const employee_name = localStorage.getItem('EMPLOYEE_NAME')
    const employee_name = localStorage.getItem('EMPLOYEE_ID')
    // console.log(typeof employee_name)

    const { Option } = Select

    useEffect(() => {
        fetchDepartments()
        getEmployee()
    }, [])

    const getEmployee = async () => {
        const response = await getEmployeeDetails(employee_name, accessToken)
        // console.log(response.data[0].employee_name)
        setEmployeeName(response.data[0].employee_name)
    }

    useEffect(() => {
        if (departmentsData && departmentsData.length > 0) {
            const firstDepartmentId = departmentsData[0].employee_department_id
            setSelectedDepartment('all' || firstDepartmentId)
            getTableData('all' || firstDepartmentId)
        }
    }, [departmentsData])

    useEffect(() => {
        getTableData(selectedDepartment)
    }, [selectedDepartment])

    const handleDepartmentChange = (value) => {
        setSelectedDepartment(value)
    }

    const handleDepartmentChangeFromModal = (departmentId) => {
        setSelectedDepartment(departmentId)
    }

    const fetchDepartments = async () => {
        try {
            setIsLoading(true)
            const response = await getEmployeeDepartments(accessToken)
            setDepartmentsData(response.data.data)
            setIsLoading(false)
        } catch (error) {
            message.error('Failed to fetch departments data')
            setIsLoading(false)
        }
    }

    const getTableData = async () => {
        if (!selectedDepartment) {
            return
        }
        try {
            const roleListResponse = await getRolesByDepartment(
                accessToken,
                selectedDepartment
            )

            const mappedData = roleListResponse.data
                ?.filter((role) => role.status !== 'deleted')
                .map((role) => {
                    return {
                        id: role.role_id,
                        role: role.role_name,
                        createdDate: role.created_date,
                        createdBy: role.created_by_employee,
                        departmentsId: role.employee_department_id,
                    }
                })
            setDataSource(mappedData)
        } catch (err) {
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to get Roles',
                duration: 5,
            })
        }
    }

    const onEditClicked = (record) => {
        setSelectedRole(record)
        setSelectedDepartment(record.departmentsId)
        setShowRoleModal(true)
    }

    const onAddClicked = () => {
        setSelectedRole(null)
        setShowRoleModal(true)
    }

    const deleteRow = async (record) => {
        try {
            const response = await deleteRole(record.id, accessToken)
            if (response.data === 'Employee was assigned to this role') {
                message.warning('Employee is assigned to this role')
            } else if (response.data === 'role  deleted successfully') {
                // getTableData(selectedDepartment)
                message.success('Role deleted succesfully')
            } else {
                message.error('failed to delete this role')
            }
        } catch (err) {
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to delete Roles',
                duration: 5,
            })
        }
        getTableData(selectedDepartment)
    }

    const defaultColumns = [
        {
            title: 'Role',
            dataIndex: 'role',
            // width: '21%',
            editable: true,
        },
        {
            title: 'Department',
            render: (_, record) => {
                const department = departmentsData?.find(
                    (dept) =>
                        dept.employee_department_id === record.departmentsId
                )?.employee_department_name
                return department || record.departmentsId
            },
            width: '21%',
            editable: true,
        },
        {
            title: 'Created by',
            dataIndex: 'createdBy',
            width: '20%',
        },
        {
            title: 'Created date',
            dataIndex: 'createdDate',
            width: '20%',
            render: (_, record) =>
                dayjs(record?.createdDate).format('DD MMM YYYY, hh:mm a'),
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            width: '18%',
            render: (_, record) => (
                <Space size="middle" style={{ fontSize: '1.2rem' }}>
                    <EditOutlined onClick={() => onEditClicked(record)} />
                    <Popconfirm
                        title="Delete Role"
                        description="Are you sure to delete this role?"
                        onConfirm={() => deleteRow(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        {' '}
                        |
                        <DeleteOutlined style={{ paddingLeft: '16px' }} />
                    </Popconfirm>
                </Space>
            ),
        },
    ]

    const roleModal = (
        <Modal
            open={showRoleModal}
            title="ROLE INFORMATION"
            onCancel={() => {
                setShowRoleModal(false)
            }}
            style={{ fontWeight: 550 }}
            footer={[
                <Button key="back" onClick={() => setShowRoleModal(false)}>
                    Cancel
                </Button>,
                <Button
                    form="roleForm"
                    key="submit"
                    htmlType="submit"
                    type="primary"
                    style={{
                        backgroundColor: '#1890ff',
                        borderColor: '#1890ff',
                    }}
                >
                    {selectedRole ? 'Update Role' : 'Save Role'}
                </Button>,
            ]}
        >
            <hr></hr>
            <AddRole
                selectedRole={selectedRole}
                getTableData={getTableData}
                setShowModal={setShowRoleModal}
                accessToken={accessToken}
                // handleDepartmentChange={handleDepartmentChange}
                selectedDepartment={selectedDepartment}
                departmentsData={departmentsData}
                onDepartmentChange={handleDepartmentChangeFromModal}
                setSelectedDepartment={setSelectedDepartment}
            />
        </Modal>
    )
    return (
        <div className="manage-permissions-container">
            {contextHolder}
            <h2
                className="heading"
                style={{ marginTop: '2vh', marginLeft: '-1vw' }}
            >
                Manage Roles
            </h2>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '3vh',
                }}
            >
                <Select
                    style={{ width: '14vw', marginBottom: '10px' }}
                    onChange={handleDepartmentChange}
                    value={selectedDepartment}
                    placeholder="Select department"
                >
                    <Option value="all">--All--</Option>
                    {departmentsData
                        ? departmentsData.map((department) => (
                              <Option
                                  key={department.employee_department_id}
                                  value={department.employee_department_id}
                              >
                                  {department.employee_department_name}
                              </Option>
                          ))
                        : undefined}
                </Select>
                <Button
                    onClick={onAddClicked}
                    type="primary"
                    style={{ width: '8rem', marginBottom: '1rem' }}
                >
                    Add Role
                </Button>
            </div>
            <Table bordered dataSource={dataSource} columns={defaultColumns} />
            {roleModal}
        </div>
    )
}
export default ManageRoles
